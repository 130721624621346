import React, {useRef, useState} from 'react';
import {Button, Card, Carousel, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {UserRoles} from '../../../../../enum/UserRoles';
import {IPicture} from '../../../../../model/shared/IPicture';
import {ConfirmationModal} from '../../../../../shared/components/alert/ConfirmationModal';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import EmptyState from '../../../../../shared/components/placeholder/EmptyState';
import {RatingStar} from '../../../../../shared/components/RatingStar';
import {truncateString} from '../../../../../shared/functions/Functions';
import {IAllStates} from '../../../../../store/rootReducer';
import {ErrorToast} from '../../../../../utils/toasters';
import {ICampaignGoals, IInfo} from '../../../../brand/campaign/briefing/dto/ICreateCampaign';
import {InfluencerService} from '../../../service';
import CreateHistoryTab from './CreateHistoryTab';


export interface ICollaborationHistory {
    data: ICollaborationData[];
    page: number;
    perPage: number;
    count: number;
}

export interface ICollaborationData {
    brandName: string;
    campaignCollaborationHistoryImages: IPicture[];
    campaignName: string;
    id: number;
    campaignGoal: ICampaignGoals | number;
}

interface IHistoryTabProps {
    state: IInfo[];
    collaborationHistory: ICollaborationHistory;
    triggerUpdate: () => void;
}

const HistoryTab = ({state, collaborationHistory, triggerUpdate}: IHistoryTabProps) => {
        const {t} = useTranslation();
        const {userType} = useSelector((state: IAllStates) => state.auth.user);
        const shouldViewActions = ![UserRoles.BRAND_MASTER, UserRoles.BRAND].includes(userType as unknown as UserRoles);
        const [selectedCollaboration, setSelectedCollaboration] = useState<ICollaborationData | undefined>(undefined);
        const collaborationFormRef = useRef<null | HTMLDivElement>(null);
        const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

        const scrollIntoCardView = () => {
            collaborationFormRef.current?.scrollIntoView({
                behavior: 'smooth'
            });
        }
        const handleDeleteCollaboration = async () => {
            await InfluencerService.deleteCollaborationPost(selectedCollaboration?.id ?? 0).then(response => {
                if (response) {
                    handleCancel();
                }
            }).catch(error => ErrorToast(error));
        }

        const handleDeleteSelectedImage = async (imageId: number) => {
            await InfluencerService.deleteCollaborationImage(imageId).then(response => {
                if (response) {
                    setSelectedCollaboration((prev: any) => ({
                        ...prev,
                        campaignCollaborationHistoryImages: prev?.campaignCollaborationHistoryImages?.filter((image: IPicture) => image.id !== imageId)
                    }));
                    scrollIntoCardView();
                }
            }).catch(error => ErrorToast(error));
        }

        const handleCancel = () => {
            triggerUpdate();
            setSelectedCollaboration(undefined);
        }
        // console.log(, '[UserRoles.BRAND_MASTER, UserRoles.BRAND].includes(userType as unknown as UserRoles)')
        return (
            <>
                <ConfirmationModal title={t('brand.campaign.book.offer.modal.are_you_sure')}
                                   description=""
                                   action={() => handleDeleteCollaboration()}
                                   show={isDeleteModalOpened} closeModal={() => setIsDeleteModalOpened(false)}/>
                <Row className="align-items-start">
                    {!!state?.length ? state?.map(item => (
                        <Col md={6} lg={4} className="pl-md-0 mb-3" key={item?.id}>
                            <Card>
                                <Carousel className="dashboard-slider">
                                    {item?.campaignImages?.length > 0 ? (
                                        item?.campaignImages?.map((data: IPicture) => (
                                            <Carousel.Item key={data?.id}>
                                                <CustomImageHandler classes="history-profile-img" altTag={data.name}
                                                                    thumbnailPath={data?.thumbnail}
                                                                    photoPath={data.path}/>
                                            </Carousel.Item>
                                        ))
                                    ) : (
                                        <Carousel.Item>
                                            <img src="/icons/imagePlaceholder.png" alt="Placeholder icon "
                                                 className="history-profile-img"/>
                                        </Carousel.Item>
                                    )}
                                </Carousel>
                                <Card.Body>
                                    <Card.Title>
                                        {item?.title ?? '-'}
                                    </Card.Title>
                                    <div className="d-flex align-items-center">
                                        <CustomImageHandler
                                            photoPath={item?.company?.profilePhoto?.path as string}
                                            thumbnailPath={item?.company?.profilePhoto?.thumbnail}
                                            classes="campaign-icon img-cover rounded-circle box-shadow"
                                            altTag="User Profile"/>
                                        <span
                                            className="font-weight-bold ml-2 ">
                                                {item?.company?.displayName ?? '-'}
                                        </span>
                                    </div>
                                    <div className="mt-2">
                                        <div className="d-flex justify-content-between">
                                            <span>{t('influencer.profile.campaignType')}:</span>
                                            <span className="text-primary font-weight-semi-bold">
                                            {item?.campaignGoal?.title ?? '-'}
                                        </span>
                                        </div>
                                        <div className="d-flex justify-content-between mt-2">
                                            <span>
                                                {t('influencer.profile.reviewRating')}:
                                            </span>
                                            <span
                                                className="text-primary font-weight-semi-bold">
                                                {item?.userReviewRating ?
                                                    <RatingStar rating={item?.userReviewRating}/> : ''}
                                            </span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    )) : <div className="card">
                        <div className="card-body">
                            <EmptyState/>
                        </div>
                    </div>}
                </Row>
                <Row>
                    <Col xs={12} className="pl-0 mt-3 mb-2">
                        <h4>Collaboration history</h4>
                    </Col>
                    {!!collaborationHistory?.data?.length ? collaborationHistory?.data?.map(collaboration => {
                        return (
                            <Col md={6} lg={4} className="pl-md-0 mb-3" key={collaboration?.id}>
                                <Card>
                                    <Carousel className="dashboard-slider" indicators={true}>
                                        {collaboration?.campaignCollaborationHistoryImages?.length > 0 ? (
                                            collaboration?.campaignCollaborationHistoryImages?.map((data: IPicture) => (
                                                <Carousel.Item key={data?.id}>
                                                    <CustomImageHandler classes="history-profile-img" altTag={data.name}
                                                                        photoPath={data.path}/>
                                                </Carousel.Item>
                                            ))
                                        ) : (
                                            <Carousel.Item>
                                                <img src="/icons/imagePlaceholder.png" alt="Placeholder icon"
                                                     className="history-profile-img"/>
                                            </Carousel.Item>
                                        )}
                                    </Carousel>
                                    <Card.Body>
                                        <Card.Title>
                                            {collaboration?.campaignName ?? '-'}
                                        </Card.Title>
                                        <div className="mt-2">
                                            <div className="d-flex justify-content-between">
                                                <span>{t('influencer.profile.campaignType')}:</span>
                                                <span className="text-primary font-weight-semi-bold">
                                                    {truncateString((collaboration?.campaignGoal as ICampaignGoals)?.title ?? '-', 30)}
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-between my-2">
                                                <span>{t('auth.register.brand.step1.brandName')}:</span>
                                                <span className="text-primary font-weight-semi-bold">
                                                    {collaboration?.brandName ?? '-'}
                                                </span>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span className="text-muted">
                                                    Campaign done out of platform
                                                </span>
                                                {shouldViewActions && <Button variant="primary"
                                                                              onClick={() => {
                                                                                  setSelectedCollaboration(collaboration);
                                                                                  scrollIntoCardView();
                                                                              }}>
                                                    {t('general.button.edit')}
                                                </Button>}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    }) : <div className="col-12 mb-5 px-0">
                        <div className="card">
                            <div className="card-body">
                                <EmptyState/>
                            </div>
                        </div>
                    </div>}
                    {shouldViewActions && <>
                        {!selectedCollaboration?.id ?
                            <CreateHistoryTab collaborationFormRef={collaborationFormRef} handleCancel={handleCancel}/> :
                            <CreateHistoryTab isEdit={!!selectedCollaboration} selectedCollaboration={selectedCollaboration}
                                              handleDeleteSelectedImage={(id) => handleDeleteSelectedImage(Number(id))}
                                              handleDelete={() => setIsDeleteModalOpened(true)}
                                              collaborationFormRef={collaborationFormRef}
                                              handleCancel={handleCancel}/>}
                    </>}

                </Row>
            </>
        );
    }
;

export default HistoryTab;
