import {createSlice} from '@reduxjs/toolkit';
import {IPackages} from '../../model/shared/IPackages';

export interface IPackagesSlice {
  activeTabModal?: string;
  allPackages?: IPackages[];
  selectedPackage?: IPackages;
  comparedPackage?: IPackages;
  isComparisonActive?: boolean;
}

const initialPackagesState: IPackagesSlice = {
  activeTabModal: undefined,
  allPackages: undefined,
  selectedPackage: undefined,
  comparedPackage: undefined,
  isComparisonActive: undefined,
};

export const packagesSlice = createSlice({
  name: 'packages',
  initialState: initialPackagesState,
  reducers: {
    setActiveTab: (state, action) => {
      const activeTabModal = action.payload;
      if (state.allPackages) {
        const selectedPackage = state.allPackages.find(item => item.name === activeTabModal);
        if (state.isComparisonActive && selectedPackage?.name === state?.comparedPackage?.name) {
          return {...state, selectedPackage, activeTabModal, isComparisonActive: false, comparedPackage: undefined};

        }
        return {...state, selectedPackage, activeTabModal};
      }
      return {...state, activeTabModal};
    },
    setAllPackages: (state, action) => {
      const allPackages: IPackages[] = action.payload;
      const selectedPackage = allPackages.find(item => item.name === state.activeTabModal);
      const subscribedPackage = allPackages.findIndex(obj => obj.isSelected);
      allPackages.slice(0, subscribedPackage).forEach(obj => obj.isSelected = true);

      if (state.activeTabModal) {
        return {
          ...state, allPackages, selectedPackage,
        };
      }
      return {...state, allPackages: action.payload};
    },
    setComperedPackage: (state, action) => {
      const comperedPackage = action.payload;
      if (state.allPackages) {
        const comparedPackage = state.allPackages.find(item => item.name === comperedPackage);
        return {...state, comparedPackage, isComparisonActive: true};
      }
    },
    deleteAll: (state, action) => {
      return {state, selectedPackage: undefined, allPackages: undefined, activeTabModal: undefined};
    },
  },
});
