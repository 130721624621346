import React, {useState} from 'react';
import {ITotalPlatforms} from '../../../../../../../../model/platform/ITotalPlatforms';
import {CustomImageHandler} from '../../../../../../../../shared/components/CustomImageHandler';
import {Image, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {numberFormatter} from '../../../../../../../../utils/helpers/numberFormater';
import Flag from 'react-world-flags';
import {priceSplitter} from '../../../../../../../../utils/helpers/priceSplitter';
import {useTranslation} from 'react-i18next';

interface IPlatformAccordionProps {
    platform: ITotalPlatforms;
}

export function HistoryPlatformAccordion({platform}: IPlatformAccordionProps) {
    const [expended, setExpended] = useState(false);
    const {t} = useTranslation();

    return (
        <>
            <tr className="cursor-pointer" onClick={() => setExpended(!expended)}>
                <td>
                    <div className="d-flex align-items-center">
                        <CustomImageHandler
                            altTag={platform?.name ?? ''}
                            photoPath={platform?.path ?? null}
                            thumbnailPath={platform?.thumbnail ?? null}
                        />
                        <span className="text-primary font-weight-bold pl-1">x{platform?.nbPostsTotal}</span>
                        {expended ?
                            <Image className="ml-2" alt="image-down" src="/icons/img_arrow_up.svg"/>
                            :
                            <Image className="ml-2" alt="image-down" src="/icons/img_arrow_down.svg"/>
                        }
                    </div>
                </td>
                <td>
                    <p>{numberFormatter(platform.followers)}</p>
                </td>
                <td>
                    <p>{numberFormatter(platform.reach)}</p>
                </td>
                <td>
                    <div className="d-flex">
                        {platform?.audiences.map((country) => (
                            <div key={country.id} className="d-flex pl-2 align-items-center">
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="country-tooltip">
                                            {t(`general.countries.${country?.metric}`)}
                                        </Tooltip>
                                    }
                                >
                                    <div className="country-flag">
                                        <Flag code={country?.metric}/>
                                    </div>
                                </OverlayTrigger>
                                <div className="d-flex flex-column pl-2">
                                        <span className="text-primary font-weight-bold mb-0">
                                            {country.value.toFixed(2)}
                                        </span>
                                    <span className="text-secondary mb-0">
                                            {numberFormatter(country.followers)}
                                        </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </td>
                <td>
                    <p className="euro-symbol">{priceSplitter(Math.round(platform.followers ?? 0))} </p>
                </td>
                <td className="text-lg-right">
                    <p className="euro-symbol">{priceSplitter(Math.round(platform?.priceTotal))} </p>
                </td>
            </tr>
            {platform?.offerItems?.map((item, index) => (
                <tr className={`${expended ? '' : 'd-none'} `} key={index}>
                    <td colSpan={2}>
                        <div className="d-flex align-items-center pl-2">
                            <CustomImageHandler
                                altTag={item?.socialPlatform?.name ?? ''}
                                photoPath={item?.socialPlatform?.path ?? ''}
                            />
                            <span className="px-2">
                                 {t(`general.platforms.${item?.socialPlatform?.name}`)}
                    </span>
                        </div>
                    </td>
                    <td></td>
                    <td>
                        <span className="text-muted font-weight-bold mr-3">
                            x {item?.nbPosts}
                        </span>
                    </td>
                    <td></td>
                    <td className="d-flex justify-content-end">
                  <span
                      className="text-muted euro-symbol"
                  >
                    {priceSplitter(Math.round(item?.price))}
                  </span>
                    </td>
                </tr>
            ))}
        </>
    );
};
