import React from 'react';
import {Button} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {FaArrowLeft} from 'react-icons/fa';

export function BackButton() {
    const history = useHistory();
    return (
        <Button variant="outline-secondary"
                onClick={() => history.goBack()}
                className="mr-3">
            <FaArrowLeft/>
        </Button>
    );
}
