import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai';
import {useTranslation} from 'react-i18next';

interface iCalendarSwithcerProps {
    increaseStep: () => void;
    reduceStep: () => void;
    isReduceDisabled: boolean;
    isIncreaseDisabled: boolean;
    classes?: {
        holder?: string;
        'btn-l'?: string;
    };
}

export const CalendarSwitcher = ({
                                     reduceStep,
                                     increaseStep,
                                     isReduceDisabled,
                                     isIncreaseDisabled,
                                     classes
                                 }: iCalendarSwithcerProps) => {
    const {t} = useTranslation();
    return (
        <Box
            className={` my-3 my-md-0 ${classes?.holder ?? ''}`}
            sx={{
                color: 'action.active',
                display: 'flex',
                flexDirection: 'column',
                '& > *': {
                    marginBottom: 2,
                },
                '& .MuiBadge-root': {
                    marginRight: 4,
                },
            }}
        >
            <div>
                <ButtonGroup className="d-flex align-items-center">
                    <Button
                        className={`${classes?.['btn-l'] ?? ''}`}
                        aria-label="reduce"
                        disabled={isReduceDisabled}
                        onClick={() => {
                            reduceStep()
                        }}
                    >
                        <AiOutlineLeft className="font-12 text-primary"/>
                    </Button>
                    <div className="d-flex align-items-center">
                        <span className="text-uppercase text-dark">
                           {t('general.button.previous')}
                        </span>
                        <span className="text-uppercase text-dark ml-3">
                            {t('general.button.next')}
                        </span>
                    </div>
                    <Button
                        aria-label="increase"
                        disabled={isIncreaseDisabled}
                        onClick={() => {
                            increaseStep()
                        }}
                    >
                        <AiOutlineRight className="font-12 text-primary"/>
                    </Button>
                </ButtonGroup>
            </div>
        </Box>
    );
};
