import React, {SetStateAction, useEffect, useState} from 'react';
import {MainChatBody} from './MainChatBody';
import {MainChatHeader} from './MainChatHeader';
import {MainChatInputFields} from './MainChatInputFields';
import {IMessageOpenedChat, IMessageThread, IMessageThreadMetadata, IMessageThreadQuery} from '../../dto/IMessage';

interface iMainChatProps {
    selectedChatContent: IMessageOpenedChat[];
    getMessagesPolling?: (selectedChatId?: number) => void;
    selectedChatId: number;
    chatParams: IMessageThreadQuery;
    setChatParams: React.Dispatch<SetStateAction<IMessageThreadQuery>>;
    selectedThread: IMessageThread;
    setSelectedChatId: React.Dispatch<SetStateAction<number | undefined>>;
    count?: number;
}

export const MainChat = ({
                             selectedChatContent,
                             selectedChatId,
                             selectedThread,
                             setSelectedChatId,
                             chatParams,
                             setChatParams,
                             count,
                             getMessagesPolling,
                         }: iMainChatProps) => {
    const [headerDisplayUsers, setHeaderDisplayUsers] = useState<IMessageThreadMetadata[]>([]);
    const [isSendClicked, setIsSendClicked] = useState(false);
    const isMyLastMessage = selectedChatContent?.[0]?.isCurrentUserMessage;
    useEffect(() => {
        if (selectedThread) {
            const {threadMetaData} = selectedThread;
            setHeaderDisplayUsers(threadMetaData);
        }
        // eslint-disable-next-line
    }, [JSON.stringify(selectedThread)]);
    useEffect(() => {
        if (isMyLastMessage) {
            const viewDiv = document.querySelector(`#scrollableDiv-${selectedChatId}`);
            viewDiv?.scrollTo({
                top: 0,
            });
        }
        // eslint-disable-next-line
    }, [isMyLastMessage, isSendClicked]);

    return (
        <>
            {headerDisplayUsers && <MainChatHeader threadMetaData={headerDisplayUsers} selectedThread={selectedThread}
                                                   setSelectedChatId={setSelectedChatId}/>}
            {selectedChatId && <>
                <div className="position-relative w-100 d-flex flex-column"
                >
                    <MainChatBody selectedChatContent={selectedChatContent} chatParams={{...chatParams, count}}
                                  setChatParams={setChatParams} selectedChatId={selectedChatId}/>
                </div>
                <div className="intoView"/>
                <MainChatInputFields setTriggerUpdate={setIsSendClicked} selectedChatId={selectedChatId}
                                     getMessagesPolling={getMessagesPolling}/>
            </>}
        </>
    );
};
