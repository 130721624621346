import {useSelector} from 'react-redux';
import {IAllStates} from '../../../store/rootReducer';
import {useTranslation} from 'react-i18next';
import {Card} from 'react-bootstrap';
import {FindAgencyCreators} from './FindAgencyCreators';
import React from 'react';

interface IProps {
    children?: JSX.Element;
}

export function SelectContentCreator({children}: IProps) {
    const {selectedInfluencer} = useSelector((state: IAllStates) => state.agency);
    const {t} = useTranslation();
    return (
        <>
            {selectedInfluencer?.id ?
                <div>
                    {children}
                </div> :
                <div className="w-md-70 text-center m-auto">
                    <div>
                        <h4>{t('agency.dashboard.empty_state_title')}</h4>
                        <p className="text-muted mb-4"
                           dangerouslySetInnerHTML={{__html: t('agency.dashboard.empty_state_description')}}/>
                    </div>
                    <Card>
                        <Card.Body>
                            <FindAgencyCreators/>
                        </Card.Body>
                    </Card>
                </div>
            }
        </>
    );
};