import React, {ChangeEvent, useEffect} from 'react';
import {InfluencerAdditionalItemsTable} from './InfluencerAdditionalItemsTable';
import {InfluencerOfferPlatformTable} from './InfluencerOfferPlatformTable';
import {Form} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {InfluencerOptionalItemsTable} from './InfluencerOptionalItemsTable';
import _debounce from 'lodash/debounce';
import {useDispatch, useSelector} from 'react-redux';
import {setPersonalOfferDescription} from '../../../../../../store/influencer/offerInfluencerSlice';
import {IAllStates} from '../../../../../../store/rootReducer';
import {InfluencerOfferHeader} from './InfluencerOfferHeader';
import {DeclineOffer} from './DeclineOffer';

export function InfluencerOfferTab() {
    const {t} = useTranslation();
    const {
        initialOffer,
        isEditable,
        campaign,
        offerModalStatus,
        isCharity
    } = useSelector((state: IAllStates) => state.offerInfluencer);

    const dispatch = useDispatch();
    const changeHandler = _debounce(
        (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const value = event?.target?.value;
            dispatch(setPersonalOfferDescription(value))
        },
        300,
    );

    useEffect(() => {
        if(campaign?.offers[0]?.personalOffer) {
            dispatch(setPersonalOfferDescription(campaign?.offers[0]?.personalOffer))
        }
    }, [campaign?.offers[0]?.personalOffer]);
    
    return (
        <>
            <DeclineOffer declineComment={campaign?.offers[0]?.declineComment}
                          declineReason={campaign?.offers[0]?.declineReason}
                          status={campaign?.offers[0]?.status}/>
            <InfluencerOfferHeader createdAt={campaign?.offers[0]?.createdAt ?? ''}
                                   offerModalStatus={offerModalStatus}
                                   isEditable={isEditable} isRequested={campaign?.offers[0]?.isRequested}/>
            <div className="p-md-3">
                <InfluencerOfferPlatformTable/>
                <InfluencerAdditionalItemsTable/>
                {(offerModalStatus === 'negotiate' && (campaign?.offerPlaceholder?.distribution && campaign?.offerPlaceholder?.distribution?.length > 0) && !isCharity) &&
                    <InfluencerOptionalItemsTable/>}
                {offerModalStatus === 'negotiate' &&
                    <>
                        <h6 className="mt-3">{t('influencer.offer_management.modal.add_personal_offer')}</h6>
                        <Form.Control
                            id="basic-textarea"
                            onChange={changeHandler}
                            defaultValue={initialOffer.personalOffer}
                            as="textarea"
                            name="personalOffer"
                            rows={3}
                            disabled={campaign?.isCharity}
                            placeholder={t('influencer.offer_management.modal.add_personal_offer_placeholder')}/>
                    </>

                }
                {(offerModalStatus !== 'negotiate' && campaign?.offers[0]?.personalOffer) &&
                    <div className="mt-4">
                        <div>
                            <h5>{t('influencer.offer_management.modal.add_personal_offer')}</h5>
                        </div>
                        <h5 className="text-muted mb-0">{campaign?.offers[0]?.personalOffer}</h5>
                    </div>
                }
            </div>
        </>
    );
}
