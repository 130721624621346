import {createSlice} from '@reduxjs/toolkit';
import {IPersonalInfo} from '../../../app/shared/user/dto/IPersonalInfo';
import {User} from '../../../model/user/User';
import {IRegisterCompany, IRegisterPreference} from '../../../authentication/register/brand/dto/IRegister';

export interface IRegister extends User {
    brandCompany: IRegisterCompany;
    userNotifications: IRegisterPreference;
}

const initialAuthState: IPersonalInfo = {
    id: 0,
    about: '',
    cityOfResidence: '',
    gender: '',
    birthdate: '',
    contact: {
        phone: '',
    },
    displayName: '',
    email: '',
    firstName: '',
    lastName: '',
};

const registrationSlice = createSlice({

    name: 'brandRegistration',
    initialState: initialAuthState,

    reducers: {
        setRegisterUser: (state, action) => {
            state = action.payload;
            return state;
        },
        clearRegisterUser: (state,) => {
            state = initialAuthState;
            return state;
        }
    },
});

export default registrationSlice;
