import {VscChromeClose} from 'react-icons/vsc';
import {CustomImageHandler} from '../../../../../../shared/components/CustomImageHandler';
import {
    capitalizeFirstLetter,
    checkSocialIcon,
} from '../../../../../../shared/functions/Functions';
import {useTranslation} from 'react-i18next';

interface iSelectedUserCardProps {
    profilePhoto: string;
    displayName: string;
    totalScheduledPosts: number;
    totalPosts: number;
    closeHandler?: () => void;
    isSocialNetwork?: boolean;
}

export const SelectedUserCard = ({
                                     profilePhoto,
                                     closeHandler,
                                     totalScheduledPosts,
                                     totalPosts,
                                     displayName,
                                     isSocialNetwork = false,
                                 }: iSelectedUserCardProps) => {
    const {t} = useTranslation();
    return (
        <div className="row align-items-center ">
            {isSocialNetwork ? (
                <div className="col-10">
                    <div className="d-flex align-items-center">
                        <div className="icon-round">{checkSocialIcon(profilePhoto)}</div>
                        <h6 className="ml-2 mb-0 font-weight-semi-bold">
                            {capitalizeFirstLetter(displayName)}
                        </h6>
                    </div>
                </div>
            ) : (
                <div className="col-10 d-flex align-items-center">
                    <CustomImageHandler
                        altTag="User Profile"
                        classes="middle-rounded-logo mr-3"
                        photoPath={profilePhoto ?? null}
                    />
                    <div>
                        <h6 className="mb-0">{displayName}</h6>
                        <div className="text-info">
                            {totalScheduledPosts} / {totalPosts} {t('general.postScheduled')}
                        </div>
                    </div>
                </div>
            )}

            <div
                className={`col-2 ${
                    !isSocialNetwork ? 'd-flex ' : 'p-0 '
                } align-items-center justify-content-end`}
            >
                <VscChromeClose
                    className="text-muted cursor-pointer"
                    onClick={closeHandler}
                />
            </div>
        </div>
    );
};
