import moment from 'moment';
import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {FiInfo} from 'react-icons/fi';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {Campaign} from '../../../../model/campaign/Campaign';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import EmptyState from '../../../../shared/components/placeholder/EmptyState';
import {formatDate} from '../../../../shared/functions/Functions';
import {priceSplitter} from '../../../../utils/helpers/priceSplitter';
import {ErrorToast} from '../../../../utils/toasters';
import {iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {IMessageUnreadQuery} from '../../../brand/campaign/messages/dto/IMessage';
import CreateEventModal, {INewEvent} from '../../planner/components/CreateEventModal';
import PreviewEventModal from '../../planner/components/PreviewEventModal';
import ProposalModal from '../../planner/components/ProposalModal';
import {InfluencerReportingService} from '../service';
import AddReportingView from './AddReportingView';
import AdditionalReportingModal from './modals/AdditionalReportingModal';
import FeedbackModal from './modals/FeedbackModal';
import ReportingModal from './modals/ReportingModal';
import ReportingAdditionalCard from './ReportingAdditionalCard';
import ReportingAlert from './ReportingAlert';
import ReportingCampaignCard, {ISelectedCampaign, ISelectedEvent} from './ReportingCampaignCard';

interface ILocation {
    campaignId?: number;
    offerId?: number;
}

interface Props {
    updateReports: boolean;
    setUpdateReports: React.Dispatch<SetStateAction<boolean>>;
    handleShowFields: (key: 'showReporting' | 'showPerformance') => void;
    filterParams: any;
    setFilterParams: React.Dispatch<SetStateAction<any>>;
}


const ReportingPerCampaign = ({
                                  updateReports,
                                  setUpdateReports,
                                  filterParams,
                                  setFilterParams,
                              }: Props) => {
    const {state} = useLocation();
    const {t} = useTranslation();
    const history = useHistory();
    const [count, setCount] = useState(0)
    const [selectedEvent, setSelectedEvent] = useState<iManageList>();
    const [isPreviewEventOpened, setIsPreviewEventOpened] = useState(false);
    const [isProposalOpened, setIsProposalOpened] = useState(false)
    const [selectedReviewId, setSelectedReviewId] = useState(0);
    const [selectedOfferId, setSelectedOfferId] = useState(0);
    const [reportingCampaigns, setReportingCampaigns] = useState<Campaign[]>([]);
    const [isFeedbackOpened, setIsFeedbackOpened] = useState(false);
    const [isNewReporting, setIsNewReporting] = useState(false);
    const [isAdditional, setIsAdditional] = useState(false);
    const [isAdditionalModalOpened, setIsAdditionalModalOpened] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState<ISelectedCampaign>();
    const [isReportOpened, setIsReportOpened] = useState(false);
    const [selectedOfferItem, setSelectedOfferItem] = useState<ISelectedEvent | undefined>();
    const [selectedIdOffer, setSelectedIdOffer] = useState<number>();
    const [createEvent, setCreateEvent] = useState<INewEvent>({
        postingDate: null,
        caption: null,
        offerItemId: 0,
    });
    const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);
    const handleCloseReportingModal = () => {
        setIsReportOpened(false);
        setSelectedIdOffer(0);
        setSelectedOfferItem(undefined);
    };

    const handleCloseFeedbackModal = () => {
        setIsFeedbackOpened(false);
        setSelectedReviewId(0);
        setUpdateReports(prev => !prev);
    };

    const loadMoreData = () => {
        const valueToUpdate = filterParams?.perPage + 10;
        setFilterParams((prev: any) => ({...prev, perPage: valueToUpdate}));
    };

    useEffect(() => {
        InfluencerReportingService.getReports({
            ...filterParams,
            campaignId: (state as ILocation)?.campaignId ?? (filterParams as unknown as IMessageUnreadQuery)?.campaignId ?? '',
        }).then(response => {
            setCount(response.count);
            setReportingCampaigns(response?.data);
            if ((state as ILocation)?.campaignId) {
                history.replace({});
            }
        }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, [JSON.stringify(filterParams), updateReports]);

    useEffect(() => {
        if (!!selectedIdOffer) {
            InfluencerReportingService.getCampaignReports(selectedIdOffer)
                .then(response => {
                    setSelectedOfferItem(response);
                    setIsReportOpened(true);
                })
                .catch(error => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, [selectedIdOffer]);

    useEffect(() => {
        if (!!(state as ILocation)?.offerId) {
            setSelectedIdOffer((state as ILocation)?.offerId);
            history.replace({});
        }
        // eslint-disable-next-line
    }, [(state as ILocation)?.offerId])
    return (
        <>
            <CreateEventModal createEvent={createEvent} isDisabled
                              setCreateEvent={setCreateEvent}
                              handleCloseModal={() => {
                                  setCreateEvent({
                                      postingDate: null,
                                      caption: null,
                                      offerItemId: 0,
                                  })
                                  setIsCreateModalOpened(false);
                                  setUpdateReports(prev => !prev);
                              }}
                              isCreateModalOpened={isCreateModalOpened}/>
            <ProposalModal setState={setSelectedEvent as React.Dispatch<SetStateAction<iManageList>>}
                           state={selectedEvent as iManageList} isModalOpened={isProposalOpened}
                           handleCloseModal={() => setIsProposalOpened(false)}/>
            <AdditionalReportingModal selectedOfferItem={selectedOfferItem as ISelectedEvent}
                                      isAdditional={isAdditional}
                                      setUpdateReports={setUpdateReports}
                                      setSelectedOfferItem={setSelectedOfferItem}
                                      isModalOpened={isAdditionalModalOpened} handleCloseModal={() => {
                setIsAdditionalModalOpened(false);
            }}/>
            <ReportingModal handleCloseModal={handleCloseReportingModal} isAdditional={isAdditional}
                            setSelectedOfferItem={setSelectedOfferItem as React.Dispatch<SetStateAction<ISelectedEvent | undefined>>}
                            setUpdateReports={setUpdateReports as React.Dispatch<SetStateAction<boolean>>}
                            selectedOfferItem={selectedOfferItem as ISelectedEvent}
                            isModalOpened={isReportOpened}/>
            <AddReportingView setUpdateReports={setUpdateReports} isAdditional={isAdditional}
                              offerId={selectedOfferId ?? 0}
                              selectedCampaign={selectedCampaign as ISelectedCampaign}
                              clearAdditional={() => {
                                  setIsAdditional(false);
                                  setSelectedOfferId(0);
                              }}
                              setSelectedCampaign={setSelectedCampaign}
                              setIsNewReporting={setIsNewReporting}
                              isNewReporting={isNewReporting}/>
            <FeedbackModal id={selectedReviewId as number} handleCloseModal={handleCloseFeedbackModal}
                           isModalOpened={isFeedbackOpened}/>
            <PreviewEventModal state={selectedEvent as iManageList} isModalOpened={isPreviewEventOpened}
                               setUpdateReports={setUpdateReports}
                               selectedYearMonth={{
                                   currentYear: '2023',
                                   currentMonth: '4'
                               }}
                               setState={setSelectedEvent as React.Dispatch<SetStateAction<iManageList>>}
                               handleCloseModal={() => setIsPreviewEventOpened(false)}/>
            <InfiniteScroll
                dataLength={reportingCampaigns?.length}
                next={() => {
                    loadMoreData();
                }}

                endMessage={
                    <p style={{textAlign: 'center'}}>
                        <b>{t('influencer.reporting.haveSeenAllReportings')}</b>
                    </p>
                }
                hasMore={reportingCampaigns?.length < count}
                loader={<h4>Loading...</h4>}
            >
                {!!reportingCampaigns?.length ? reportingCampaigns?.map((reporting) => {
                    const {
                        id,
                        title,
                        campaignImages,
                        postingDeadline,
                        company,
                        isReviewed,
                        offers,
                        totalReporting: {totalCalendar, totalPosts, reportStats},
                    } = reporting;
                    const statusName = offers?.[0]?.status === 'accepted' ? 'ongoing' : offers?.[0]?.status;
                    const isOverdue = new Date(postingDeadline) < new Date() && statusName !== 'completed';
                    const overdueStatus = isOverdue ? t(`general.campaign_status.overdue`) : t(`general.campaign_status.${statusName}`);
                    const statusClass = isOverdue ? 'overdue' : offers?.[0]?.status;
                    return (
                        <React.Fragment key={id}>
                            {((totalCalendar < totalPosts && ((moment().diff(postingDeadline) >= 0) && (!offers?.[0]?.reviews?.length)))
                                    && offers?.[0]?.status === 'accepted') &&
                                <ReportingAlert name={company?.displayName ?? ''}/>}
                            <Card className="my-3">
                                <Card.Body>
                                    <div className="row">
                                        <div
                                            className="col-12 px-md-0 d-flex align-items-center justify-content-between flex-wrap">
                                            <Link to={`/influencer/campaign/${id}/overview`}
                                                  className="d-flex align-items-center">
                                                <CustomImageHandler
                                                    classes="campaign-icon img-cover"
                                                    photoPath={campaignImages?.[0]?.thumbnail as string}
                                                    thumbnailPath={campaignImages?.[0]?.thumbnail as string}
                                                    altTag="Company logo"/>
                                                <div className="d-flex flex-column ml-2">
                                            <span className="font-weight-semi-bold text-dark">
                                                {title ?? '-'}
                                            </span>
                                                    <span className="text-muted">
                                                {company?.displayName ?? '-'}
                                            </span>
                                                </div>
                                            </Link>
                                            <div className="d-flex flex-column align-items-end">
                                                <div className="text-muted">
                                                    Offer ID: #{offers?.[0]?.id}
                                                </div>
                                                <span className={`status ${statusClass} mt-1`}>{overdueStatus}</span>
                                            </div>
                                        </div>
                                        <div className="d-flex col-12 p-0 flex-wrap mt-3" style={{rowGap: '8px'}}>
                                            <div className="col-sm-3 col-4 m-md-0">
                                                <div className="pr-2 text-primary font-weight-semi-bold">
                                                    {formatDate(postingDeadline)}
                                                </div>
                                                <div>
                                                    {t('influencer.reporting.dueDate')}
                                                    <OverlayTrigger placement="top"
                                                                    overlay={<Tooltip
                                                                        id="impressions-tooltip">
                                                                        {t(`influencer.reporting.dueDateTooltip`)}
                                                                    </Tooltip>}>
                                                        <FiInfo size={14} className="cursor-pointer ml-1"/>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-4 m-md-0">
                                                <div className="pr-2 text-primary font-weight-semi-bold">
                                                    {totalCalendar ?? 0} / {totalPosts ?? 0}
                                                </div>
                                                <div className="pr-2">{t('influencer.reporting.reported')}</div>
                                            </div>
                                            <div className="col-sm-3 col-4 m-md-0">
                                                <div className="pr-2 text-primary font-weight-semi-bold">
                                                    {priceSplitter(reportStats?.[0]?.impressions)}
                                                </div>
                                                <div className="pr-2">
                                                    {t('influencer.reporting.impressions')}
                                                    <OverlayTrigger placement="top"
                                                                    overlay={<Tooltip
                                                                        id="impressions-tooltip">
                                                                        {t(`influencer.reporting.impressionsTooltip`)}
                                                                    </Tooltip>}>
                                                        <FiInfo size={14} className="cursor-pointer ml-1"/>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-4 m-md-0">
                                                <div className="pr-2 text-primary font-weight-semi-bold">
                                                    {priceSplitter(+reportStats?.[0]?.reach)}
                                                </div>
                                                <div className="pr-2">
                                                    {t('influencer.reporting.reach')}
                                                    <OverlayTrigger placement="top"
                                                                    overlay={<Tooltip
                                                                        id="impressions-tooltip">
                                                                        {t(`influencer.reporting.reachTooltip`)}
                                                                    </Tooltip>}>
                                                        <FiInfo size={14} className="cursor-pointer ml-1"/>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-4 m-md-0">
                                                <div className="pr-2 text-primary font-weight-semi-bold">
                                                    {priceSplitter(+reportStats?.[0]?.engagements)}
                                                </div>
                                                <div className="pr-2">
                                                    {t('influencer.reporting.engagements')}
                                                    <OverlayTrigger placement="top"
                                                                    overlay={<Tooltip
                                                                        id="impressions-tooltip">
                                                                        {t(`influencer.reporting.engagementsTooltip`)}
                                                                    </Tooltip>}>
                                                        <FiInfo size={14} className="cursor-pointer ml-1"/>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                            <div className="col-sm-3 col-4 m-md-0">
                                                <div className="pr-2 text-primary font-weight-semi-bold">
                                                    {priceSplitter(+reportStats?.[0]?.comments ?? 0)}
                                                </div>
                                                <div className="pr-2">{t('influencer.reporting.answers')}</div>
                                            </div>
                                            <div className="col-sm-3 col-4 m-md-0">
                                                <div className="pr-2 text-primary font-weight-semi-bold">
                                                    {priceSplitter(+reportStats?.[0]?.shares ?? 0)}
                                                </div>
                                                <div className="pr-2">{t('influencer.reporting.shares')}</div>
                                            </div>
                                            <div className="col-sm-3 col-4 m-md-0">
                                                <div className="pr-2 text-primary font-weight-semi-bold">
                                                    {priceSplitter(+reportStats?.[0]?.totalClicks ?? 0)}
                                                </div>
                                                <div className="pr-2">{t('influencer.reporting.swipeUps')}</div>
                                            </div>
                                        </div>
                                        {offers?.[0]?.offerItems?.map((offerItem) => (
                                            <ReportingCampaignCard offerItem={offerItem} isReviewed={isReviewed}
                                                                   setCreateEvent={setCreateEvent} campaign={reporting}
                                                                   setIsCreateModalOpened={setIsCreateModalOpened}
                                                                   setSelectedCampaign={setSelectedCampaign}
                                                                   offerStatus={offers?.[0]?.status}
                                                                   setIsNewReporting={setIsNewReporting}
                                                                   setIsPreviewEventOpened={setIsPreviewEventOpened}
                                                                   setIsProposalOpened={setIsProposalOpened}
                                                                   setSelectedEvent={setSelectedEvent}
                                                                   setSelectedIdOffer={setSelectedIdOffer as React.Dispatch<SetStateAction<number>>}
                                                                   key={offerItem?.id}/>
                                        ))}
                                        {offers?.[0]?.additionalReportItems?.map((additionalItem) =>
                                            <ReportingAdditionalCard offerItem={additionalItem}
                                                                     setIsAdditional={setIsAdditional}
                                                                     setSelectedOfferItem={setSelectedOfferItem as React.Dispatch<SetStateAction<ISelectedEvent | undefined>>}
                                                                     setIsReportOpened={setIsAdditionalModalOpened}
                                                                     key={additionalItem?.id}/>
                                        )}
                                        <div className="col-md-4 mt-3 pl-0">
                                            <div
                                                className="row box-shadow default-radius h-100">
                                                <div
                                                    className="col-4 px-0 bg-light d-flex align-items-center justify-content-center">
                                                    <div
                                                        className="d-flex align-items-center justify-content-center">
                                                        <img src="/icons/upload-icon.png" alt="upload-icon"/>
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-8 d-flex align-items-center justify-content-center flex-column">
                                                    <div className="py-5 d-flex flex-column">
                                                    <span className="text-muted text-center">
                                                        {t('influencer.reporting.placeholder')}
                                                    </span>
                                                        <div className="d-flex justify-content-center mt-3">
                                                            {!(Number(totalCalendar) < Number(totalPosts)) &&
                                                                <Button variant="outline-primary"
                                                                        onClick={() => {
                                                                            setSelectedCampaign({
                                                                                campaign: reporting,
                                                                                offerItemId: 0,
                                                                            });
                                                                            setSelectedOfferId(offers?.[0]?.id);
                                                                            setIsNewReporting(true);
                                                                            setIsAdditional(true);
                                                                        }}>
                                                                    {t('general.button.addMore')}
                                                                </Button>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col mt-3">
                                            <div className="d-flex justify-content-end align-items-end h-100">
                                                <Button variant="outline-primary"
                                                        onClick={() => {
                                                            history.push('/influencer/invoices', {campaignId: +id})
                                                        }}>
                                                    {t('influencer.invoice.invoice')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-12 px-md-0 d-flex justify-content-end mt-3">
                                    <Button variant="outline-primary" disabled={!hasFeedback}
                                            onClick={() => {
                                                setIsFeedbackOpened(true);
                                                setSelectedReviewId(offers?.[0]?.reviews?.[0]?.id as number);
                                            }}>
                                        {t('general.button.feedback')}
                                    </Button>
                                </div> */}
                                </Card.Body>
                            </Card>
                        </React.Fragment>
                    );
                }) : <div className="card">
                    <div className="card-body">
                        <EmptyState navigateTo="/influencer/planner" btnText={t('influencer.reporting.placeholder')}/>
                    </div>
                </div>}
            </InfiniteScroll>
        </>
    );
};

export default ReportingPerCampaign;
