import React, {SetStateAction} from 'react';
import {FaSortAmountDown, FaSortAmountUp} from 'react-icons/fa';
import {Button, Col, Dropdown, DropdownButton} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {IExploreFilterParams} from '../dto/Explore';
import {useHistory} from 'react-router-dom';

export interface IExploreFilterProps {
    exploreFilters: IExploreFilterParams;
    setExploreFilters: React.Dispatch<SetStateAction<IExploreFilterParams>>;
    nbPickedOffers?: number;
    nbReverseOffers?: number;
    isAgencyPage?: boolean;
}

const ExploreSort = ({exploreFilters, setExploreFilters, isAgencyPage}: IExploreFilterProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const handleSortType = (type: 'ASC' | 'DESC') => {
        setExploreFilters(prev => ({...prev, sortType: type}));
    };
    const handleSortBy = (by: 'date' | 'publishFrom' | 'publishTo' | 'nbPosts') => {
        setExploreFilters(prev => ({...prev, sortBy: by}));
    };
    const goToOfferManagement = () => {
        isAgencyPage ? history.push(`/agency/offers`) :
            history.push(`/influencer/offers`);
    };

    return (
        <Col
            className="d-flex align-items-center justify-content-end pr-0 mt-md-3 mt-lg-0">
            <div className="d-flex align-items-center">
                {exploreFilters?.sortType === 'ASC' ?
                    <FaSortAmountDown className="cursor-pointer" onClick={() => handleSortType('DESC')}/> :
                    <FaSortAmountUp className="cursor-pointer" onClick={() => handleSortType('ASC')}/>}
                <DropdownButton
                    className="mr-2 pl-1 font-weight-normal"
                    variant="transparent"
                    title={exploreFilters?.sortBy ? t(`influencer.explore.${exploreFilters?.sortBy}`) : t('general.button.sort_by')}
                >
                    <Dropdown.Item className={`text-${exploreFilters?.sortBy === 'date' ? 'primary' : ''}`}
                                   onClick={() => handleSortBy('date')}>
                        {t('influencer.explore.date')}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleSortBy('nbPosts')}
                                   className={`text-${exploreFilters?.sortBy === 'nbPosts' ? 'primary' : ''}`}>
                        {t('influencer.explore.nbPosts')}
                    </Dropdown.Item>
                </DropdownButton>
                <Button onClick={goToOfferManagement} variant="outline-primary" className="d-none d-md-block">
                    &gt;&gt; {t('influencer.explore.offerManagement')}
                </Button>
            </div>
        </Col>
    );
};

export default ExploreSort;
