import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {User} from '../../model/user/User';

export interface IAgencySlice {
    selectedInfluencer?: Partial<User>;
    updateInfluencers: boolean;
}

const initialState: IAgencySlice = {
    selectedInfluencer: undefined,
    updateInfluencers: false,
};
export const agencySlice = createSlice({
    name: 'agencySlice',
    initialState,
    reducers: {
        setSelectetInfluencer: (state: IAgencySlice, {payload: user}: PayloadAction<Partial<User | undefined>>) => {
            return {...state, selectedInfluencer: user};
        },
        setUpdateSelectedInfluencer: (state: IAgencySlice, {payload: user}: PayloadAction<Partial<User | undefined>>) => {
            return {...state, selectedInfluencer: {...state.selectedInfluencer, profilePhoto: user?.profilePhoto, displayName: user?.displayName}};
        },
        setUpdateInfluencerList: (state, action) => {
            return {...state, updateInfluencers: !state.updateInfluencers};
        },
        setDeselectInfluencer: (state, action) => {
            return {...state, selectedInfluencer: undefined};
        },
    },
});

export const {
    setDeselectInfluencer,
    setSelectetInfluencer,
    setUpdateInfluencerList,
    setUpdateSelectedInfluencer,
} = agencySlice.actions;
