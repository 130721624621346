import axios, {AxiosResponse} from 'axios';
import {loadingSlice} from '../store/general/loadingSlice';
import {StoreKeeper} from '../store/store';

import {ErrorToast} from './toasters';
import {actions} from '../store/authentication/authRedux';
import {BASE_URL} from './endpoints';
import {appVersionSlice} from '../store/general/appVersionSlice';
import history from '../history';

const defaultOptions = {
    headers: {
        'Content-Type': 'application/json',
        'accept': 'text/html',
    },
};
export const instance = axios.create(defaultOptions);
let loadingTimeout: NodeJS.Timeout;

interface IApiCalls {
    [key: number]: boolean
}

const requestToDisableLoading = ['/admin/agency/planner/attachment/', '/messages/total/unread', '/brand/booking/offers/selection', '/influencer/planner/comment/send', '/brand/manage/comment/send', '/brand/booking/invites/', '/messages/inbox', '/messages/thread/', '/messages/send', '/influencer/planner/attachment', '/influencer/reporting']


const ApiCalls: IApiCalls = {};

const shouldRemoveLoading = (apiList: IApiCalls, timeout: number) => {
    if (apiList[timeout as unknown as number] || timeout === -1) { // if true, remove that api call || if -1 request from requestToDisableLoading array
        apiList[timeout as unknown as number] = false;
        clearTimeout(timeout);
    }
    const isEveryApiFinished = Object.values(apiList).every(call => !call);

    if (isEveryApiFinished) {
        StoreKeeper.store.dispatch(loadingSlice.actions.setIsLoading(false))
    }
}

export const setupInterceptors = () => {

    instance.interceptors.response.use(res => {
        // success
        shouldRemoveLoading(ApiCalls, (res.config as any)?.keyReq as number);
        return res;
    }, async (err) => {
        const {status, data} = err.response ?? {};
        const refreshToken = localStorage.getItem('refreshToken');
        const originalConfig = err.config;
        const isLoginScreen = originalConfig?.url.includes('/auth/login');
        shouldRemoveLoading(ApiCalls, originalConfig?.keyReq as number)
        if (status === 403) {
            ErrorToast(err);
            history.push('/403');
            return;
        }
        if (['jwt expired', 'Your token is invalid'].includes(data?.message)) { // if this is true, that means that our token is not valid
            ErrorToast(err);
            StoreKeeper.store.dispatch(actions.logout());
            localStorage.clear();
            return;
        }
        if (status === 505) {
            StoreKeeper.store.dispatch(appVersionSlice.actions.setReloadModal(true));
            localStorage.setItem('appVersion', data?.appVersion ?? '');
        }
        if (status === 401 && !originalConfig._retry && !isLoginScreen) {
            originalConfig._retry = true;
            try {
                const {data} = await instance.post(`${BASE_URL}auth/refresh`, {
                    refreshToken,
                });
                localStorage.setItem('token', JSON.stringify(data?.token));
                return instance(originalConfig);
            } catch (_error) {
                return Promise.reject(_error);
            }
        }
        return Promise.reject(err);
    });
};

instance.interceptors.request.use((config) => {
    const token = JSON.parse(localStorage.getItem('token') ?? '{}');
    const appVersion = localStorage.getItem('appVersion');
    config!.headers!['app-version'] = appVersion ?? '';
    config!.headers!.Authorization = token ? `Bearer ${token}` : '';
    loadingTimeout = setTimeout(() => {
        StoreKeeper.store.dispatch(loadingSlice.actions.setIsLoading(true));
    }, 500);
    const shouldDisableLoading = requestToDisableLoading.some((text) => config.url?.includes(text ?? ''));
    if (!ApiCalls[loadingTimeout as unknown as number] && !shouldDisableLoading) { // if this is false (we don't have call with this key), create value inside ApiCalls map
        ApiCalls[loadingTimeout as unknown as number] = true;
    } else {
        clearTimeout(loadingTimeout);
        ApiCalls[!shouldDisableLoading ? loadingTimeout as unknown as number : -1] = false;
    }
    return {
        ...config,
        keyReq: !shouldDisableLoading ? loadingTimeout : -1
    };
}, (error) => {
    shouldRemoveLoading(ApiCalls, error.config.keyReq as unknown as number)
    return Promise.reject(error);
});

const responseBody = (response: AxiosResponse) => response.data;

export const requests = {
    get: (url: string, params?: any) => instance.get(url, {params}).then(responseBody).catch(e => {
        throw e;
    }),
    post: (url: string, body: {}, customHeaders?: any) => instance.post(url, body, customHeaders).then(responseBody).catch(e => {
        throw e;
    }),
    put: (url: string, body: {}, customHeaders?: any) => instance.put(url, body, customHeaders).then(responseBody).catch(e => {
        throw e;
    }),
    delete: (url: string) => instance.delete(url).then(responseBody).catch(e => {
        throw e;
    }),
};

export function multipartFormData(image: any, url: string, body?: any, method = 'put') {
    const formData = new FormData();
    formData.append('file', image);
    body && formData.append('body', body);
    if (method === 'put') {
        return instance.put(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    } else {
        return instance.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
}

export const downloadFileAPI = (url: string, format?: string, fileName?: string) => {
    const token = JSON.parse(localStorage.getItem('token') ?? '{}');
    const appVersion = localStorage.getItem('appVersion');

    const method = 'GET';
    axios.request({
        url,
        method,
        responseType: 'blob',
        headers: {
            'app-version': appVersion || '1.0.0',
            Authorization: token ? `Bearer ${token}` : '',
        },
    })
        .then((response) => {
            fileName = response.headers['content-disposition'].slice(22, response.headers['content-disposition'].length - 1);
            const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', format ? `${fileName}.${format}` : `${fileName}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch(error => ErrorToast(error, true));
};
