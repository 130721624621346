import React, {SetStateAction, useEffect, useState} from 'react';
import {MdPhotoCamera} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../../../../store/authentication/authRedux';
import {IAllStates} from '../../../../../store/rootReducer';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import {RatingStar} from '../../../../../shared/components/RatingStar';
import {Link, useLocation, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Card} from 'react-bootstrap';
import {User} from '../../../../../model/user/User';
import {multipartFormData} from '../../../../../utils';
import {PERSONAL_INFO_URL} from '../../../../../utils/endpoints';
import {ErrorToast, SuccessToast} from '../../../../../utils/toasters';
import {IParams} from '../../../../../model/shared/IParams';
import {UserRoles} from '../../../../../enum/UserRoles';
import {InternalRoutes} from '../../../../../enum/InternalRoutes';

interface IProfileInfoProps {
    isEdit?: boolean;
    defaultState?: User;
    setUpdatePlatforms?: React.Dispatch<SetStateAction<boolean>>;
}

interface ILogo {
    file: Blob[] | MediaSource[] | null
}

const ProfileInfo = ({isEdit = false, defaultState, setUpdatePlatforms}: IProfileInfoProps) => {
    const [logo, setLogo] = useState<ILogo>({
        file: null
    });
    const {user: {userType}} = useSelector((state: IAllStates) => state.auth);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const isPublicProfile = location.pathname.includes('/brand/');
    const {id} = useParams<IParams>();
    const isAgency = [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(userType as UserRoles);
    const changeFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, files} = event.target;
        setLogo(prev => ({...prev, [name]: files}))
    }
    useEffect(() => {
        if (!!logo?.file?.length && setUpdatePlatforms) {
            multipartFormData(logo.file?.[0], `${PERSONAL_INFO_URL}/profile-photo/${id}`)
                .then((res) => {
                    dispatch(actions.updateUser(res.data.data));
                    setUpdatePlatforms(prev => !prev);
                    setLogo({
                        file: null
                    })
                    SuccessToast(t('general.toasts.successUpdatedPersonalInfo'));
                }).catch(error => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, [logo?.file])
    return (
        <Card>
            <Card.Body className="d-flex flex-column align-items-center">
                <div className="d-flex align-items-center justify-content-center flex-column">
                    <div className="position-relative">
                        <CustomImageHandler
                            thumbnailPath={defaultState?.profilePhoto?.thumbnail as string}
                            photoPath={defaultState?.profilePhoto?.path as string}
                            classes="profile-image mb-3"
                            altTag="User Profile large"/>
                        {isEdit &&
                            <div className="input-icon">
                                <div
                                    className="upload-image-icon"
                                >
                                    <MdPhotoCamera size={20} className="text-primary"/>
                                    <input className="input-field px-1"
                                           type="file"
                                           name="file"
                                           onChange={changeFileHandler}
                                           placeholder="Username"
                                           accept="image/*"
                                    />
                                </div>
                            </div>}

                    </div>
                    <h6>{defaultState?.firstName} {defaultState?.lastName}</h6>
                    <p className="text-muted">{defaultState?.displayName}</p>
                    {defaultState?.totalRating ? <RatingStar rating={defaultState?.totalRating}/> : ''}
                    <div className="mt-3 d-none d-md-flex flex-wrap">
                        {defaultState?.categoriesArray.map((category) => <span className="light-badge m-1"
                                                                               key={category?.id}>
                            {t(`general.category.${category?.name}`)}
                        </span>)}
                    </div>
                </div>
                <div className="d-md-flex align-items-start flex-column pt-3 d-none">
                    {defaultState?.totalPlatforms?.map((platform) => <div key={platform?.id} className="my-2">
                        <img src={platform?.path} alt={platform?.icon} height={33}/>
                        <span className="text-muted ml-1">@{platform?.username}</span>
                    </div>)}
                </div>
                {isPublicProfile ? null :
                    <Link to={isEdit ? (isAgency ? `${InternalRoutes.AGENCY_PROFILE}/${id}` : `${InternalRoutes.INFLUENCER_PROFILE}/${id}`)
                        : `${InternalRoutes.INFLUENCER_PROFILE_EDIT}/${id}`}
                          className="d-none d-md-flex justify-content-end">
                        {t(isEdit ? 'general.seeFullProfile' : 'general.editProfile')}
                    </Link>
                }
            </Card.Body>
        </Card>
    );
};

export default ProfileInfo;
