import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React, {useState} from 'react';
import Offer from '../tabs/offer/Offer';
import IdeaContent from '../tabs/idea/IdeaContent';
import EnhancedAudienceData from '../tabs/audience/EnhancedAudienceData';
import {Reviews} from '../tabs/reviews/Reviews';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../store/rootReducer';
import TabPanel from '@mui/lab/TabPanel';
import {setActiveTab} from '../../../../../../../store/brand/offerBrandSlice';
import {History} from '../tabs/history/History';
import {OfferComments} from '../../../../../../../shared/components/offer-comments/OfferComments';

function ModalTabs() {
    const {
        activeTabModal,
        campaign,
        canWriteComment,
        user,
        hasUnreadComments,
    } = useSelector((state: IAllStates) => state.offerBrand);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const [unreadMessages, setUnreadMessages] = useState(0);
    const isInvited = location.pathname.split('/').includes('invite');
    const isMobile = window.innerWidth <= 700;

    const handleTab = (event: React.SyntheticEvent, newValue: string) => {
        dispatch(setActiveTab(newValue));
    };

    return (
        <Box sx={{width: '100%'}}>
            <TabContext value={activeTabModal}>
                <Box className="mb-md-3 position-sticky">
                    <TabList allowScrollButtonsMobile={isMobile} variant={isMobile ? 'scrollable' : 'fullWidth'}
                             onChange={handleTab} centered>
                        <Tab label={t('brand.campaign.book.offer.modal.offer')}
                             value="0"/>
                        <Tab label={t('brand.campaign.book.offer.modal.content')}
                             value="1"/>
                        <Tab label={t('brand.campaign.book.offer.modal.audience')}
                             value="2"/>
                        <Tab label={t('brand.campaign.book.offer.modal.reviews')}
                             value="3"/>
                        {(!isInvited) &&
                            <Tab label={t('brand.campaign.book.offer.modal.comments')}
                                 icon={<> {hasUnreadComments && <span className="notification-comment-badge">
                            </span>}</>}
                                 value="4"/>
                        }
                        {(campaign?.offerPlaceholder?.flags?.hasHistory) &&
                            <Tab label={t('brand.campaign.book.offer.modal.history')}
                                 value="5"/>
                        }
                    </TabList>
                </Box>
                <Box className="modal-right-content">
                    <TabPanel value="0">
                        <Offer/>
                    </TabPanel>
                    <TabPanel value="1">
                        <IdeaContent/>
                    </TabPanel>
                    <TabPanel value="2">
                        <EnhancedAudienceData user={user!}/>
                    </TabPanel>
                    <TabPanel value="3">
                        <Reviews/>
                    </TabPanel>
                    {!isInvited &&
                        <TabPanel className="p-0 h-100" value="4">
                            <OfferComments canWriteComment={canWriteComment} offerId={campaign?.offers[0]?.id ?? 0}/>
                        </TabPanel>
                    }
                    {(campaign?.offerPlaceholder?.flags?.hasHistory) &&
                        <TabPanel value="5">
                            <History/>
                        </TabPanel>
                    }
                </Box>
            </TabContext>
        </Box>
    );
}

export default ModalTabs;
