import {requests} from '../../../utils';
import {AUTH_AGENCY_URL} from '../../../utils/endpoints';
import {IRegisterAdmin, IRegisterBillingInfos, IRegisterInvite, IRegisterPreference} from '../brand/dto/IRegister';
import {IRegistrationStepTwoRequest} from '../brand/dto/IRegistrationStepTwoRequest';

export const AgencyService = {
    registerAccount: (params: IRegisterAdmin) => requests.post(`${AUTH_AGENCY_URL}/register/admin-account`, params),
    verifyUserData: (id: string, body: IRegistrationStepTwoRequest) => requests.post(`${AUTH_AGENCY_URL}/verify/${id}`, body),
    resetVerifyCode: (email: string) => requests.post(`${AUTH_AGENCY_URL}/resend/verify`, {
        email,
    }),
    updateUserBillingInfos: (body: IRegisterBillingInfos) => requests.put(`${AUTH_AGENCY_URL}/agency-billing`, body),
    updateGlobalPreference: (body: IRegisterPreference) => requests.put(`${AUTH_AGENCY_URL}/global-preference`, body),
    inviteInfluencers: (body: IRegisterInvite) => requests.post(`${AUTH_AGENCY_URL}/invite-influencers`, body),
};
