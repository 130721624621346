import React from 'react';
import {useTranslation} from 'react-i18next';
import {TextField} from '@material-ui/core';
import {IReportingUpdate} from '../../ReportingCampaignCard';
import {Platforms} from '../../../../../../model/platform/Platforms';

interface Props {
    updateData: IReportingUpdate;
    isAgency?: boolean;
    isButtonDisabled?: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, isNumber: boolean) => void;
    socialPlatform?: Platforms;
}

export const reportingFields = ['views', 'reach', 'impressions', 'likes', 'comments', 'clicks', 'stickerEngagements', 'swipeUpLinks', 'shares', 'saves', 'watchTime', 'dislikes', 'nbPublishedPosts'];

const ReportingBody = ({
                           handleChange,
                           isButtonDisabled = false,
                           updateData,
                           socialPlatform,
                           isAgency = false,
                       }: Props) => {
    const {t} = useTranslation();
    return (
        <div className={isAgency ? 'my-3' : 'col-12 my-3'}>
            <h5>{t('influencer.reporting.automaticReporting')}</h5>
            <div className="d-flex align-items-center flex-wrap">
                {reportingFields?.filter(field => socialPlatform?.[field as unknown as keyof Platforms])?.map((field) =>
                    <TextField
                        key={field}
                        name={field}
                        required
                        value={updateData?.[field as keyof IReportingUpdate]}
                        className="m-1 my-2"
                        onChange={(event) => handleChange(event as React.ChangeEvent<HTMLInputElement>, true)}
                        label={t(`influencer.reporting.${field}`)}
                        type="number"
                        InputProps={{inputProps: {min: 0}}}
                        disabled={isButtonDisabled}
                        variant="outlined"
                        size="small"
                    />)}
            </div>
        </div>
    );
};

export default ReportingBody;
