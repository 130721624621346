import React from 'react';
import {Card, OverlayTrigger, ProgressBar, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {FiInfo} from 'react-icons/fi';
import {IReportingTasksSummary} from '../dto/IReporting';

interface Props {
    reportingTasks: IReportingTasksSummary;
}

const ReportingTasks = ({reportingTasks}: Props) => {
    const {t} = useTranslation();
    return (
        <>
            <h4>{t('influencer.reporting.reportingTasks')}</h4>
            <Card>
                <Card.Body>
                    <div className="row">
                        <div className="col-6 px-md-0">
                            <h5 className="mb-0 text-primary">{reportingTasks?.reportsDue ?? 0}/{reportingTasks?.todoReportsDue ?? 0}</h5>
                            <div className="d-flex align-items-center">
                                {t('influencer.reporting.reportsDue')}
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="reportsDue-tooltip">
                                                    {t(`influencer.reporting.reportsDueTooltip`)}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <div className="mr-2">
                                <h5 className="mb-0 text-danger">
                                    {reportingTasks?.reportsOverdue ?? 0}/{reportingTasks?.todoReportsOverdue ?? 0}
                                </h5>
                                <div className="d-flex align-items-center">
                                    {t('influencer.reporting.reportsOverDue')}
                                    <OverlayTrigger placement="top"
                                                    overlay={<Tooltip
                                                        id="reportsOverDue-tooltip">
                                                        {t(`influencer.reporting.reportsOverDueTooltip`)}
                                                    </Tooltip>}>
                                        <FiInfo size={14} className="cursor-pointer ml-1"/>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <img src="/icons/tick-mark.svg" alt="attention-icon"/>
                        </div>
                        <div className="col-6 px-md-0 mt-3">
                            <h5 className="mb-0 text-primary">{reportingTasks?.totalCampaigns ?? 0}</h5>
                            <span>{t('influencer.reporting.campaigns')}</span>
                        </div>
                        <div className="col-6 mt-3">
                            <h5 className="mb-0 text-primary">{reportingTasks?.placements ?? 0}</h5>
                            <div className="d-flex align-items-center">
                                {t('influencer.reporting.placements')}
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="placements-tooltip">
                                                    {t(`influencer.reporting.placementsTooltip`)}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="col-12 px-md-0 mt-3">
                            <div className="mb-1 d-flex align-items-center justify-content-between">
                                <span>{t('influencer.reporting.completedReports')}</span>
                                <h6 className="mb-0 text-danger">
                                    {reportingTasks?.reportsCompleted ?? 0}/{reportingTasks?.totalReports ?? 0}
                                </h6>
                            </div>
                            <ProgressBar
                                variant="danger"
                                key={reportingTasks?.reportsCompleted}
                                className="progress-sm w-100 bg-danger-lighter"
                                now={reportingTasks?.reportsCompleted ?? 0}
                                max={reportingTasks?.totalReports ?? 0}
                            />
                        </div>
                    </div>

                </Card.Body>
            </Card>
        </>
    );
};

export default ReportingTasks;
