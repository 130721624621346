import React from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

const ForbiddenPage = () => {
    const history = useHistory();
    const {t} = useTranslation();
    return (
        <div className="forbidden-page">
            <h1>403</h1>
            <h6>{t('general.forbidden')}</h6>
            <p>{t('general.forbiddenDesc')}</p>
            <Button variant="outline-primary" onClick={() => history.push('/')}>
                {t('general.goToDashboard')}
            </Button>
        </div>
    );
};

export default ForbiddenPage;
