import {CancelTokenSource} from 'axios';
import {requests} from '../../../utils';
import {AGENCY_PLANNER} from '../../../utils/endpoints';
import {iManageList} from '../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {INewEvent} from '../../influencer/planner/components/CreateEventModal';
import {IPlannerMessage} from '../../influencer/planner/components/preview/PreviewFeedback';
import {
    IPlannerEventsQuery,
    IPlannerOffers,
    IPlannerOffersQuery,
    IPlannerUpdate,
    IPlatformQuery, IRescheduleNotify
} from '../../influencer/planner/dto/IPlanner';

export const AgencyPlannerService = {
    getPlannerOffers: (pagination: IPlannerOffersQuery): Promise<IPlannerOffers> => requests.get(`${AGENCY_PLANNER}/planner/offers`, pagination),
    getPlannerData: (pagination: IPlannerEventsQuery) => requests.get(`${AGENCY_PLANNER}/planner/filter`, pagination),
    createNewEvent: (data: INewEvent, influencerId: number) => requests.post(`${AGENCY_PLANNER}/planner/${influencerId}`, data),
    getEvents: (pagination: IPlannerOffersQuery): Promise<{
        data: iManageList[]
    }> => requests.get(`${AGENCY_PLANNER}/planner`, pagination),
    getPlannerModalData: (id: string) => requests.get(`${AGENCY_PLANNER}/planner/modal/${id}`),
    getPlannerPlatforms: (data: IPlatformQuery) => requests.get(`${AGENCY_PLANNER}/planner/platforms`, data),
    updatePostingLink: (data: IPlannerUpdate) => requests.put(`${AGENCY_PLANNER}/planner`, data),
    uploadAttachment: (data: FormData, id: number, cancelToken?: undefined | CancelTokenSource, onUploadProgress?: any) => requests.post(`${AGENCY_PLANNER}/planner/attachment/${id}`, data, {
        cancelToken: cancelToken,
        onUploadProgress: onUploadProgress
    }),
    acceptEvent: (data: {
        eventId: number,
        postingDate: string
    }, eventId: number) => requests.post(`${AGENCY_PLANNER}/planner/accept/${eventId}`, data),
    updateCaption: (data: {
        eventId: number,
        caption: string,
        storyline: string
    }) => requests.put(`${AGENCY_PLANNER}/planner/caption`, data),
    reschedulePostRequest: (eventId: number, postingDate: string) => requests.put(`${AGENCY_PLANNER}/planner/reschedule`, {
        eventId,
        postingDate,
    }),
    rescheduleRequestPut: (eventId: number, postingDate: string) => requests.put(`${AGENCY_PLANNER}/planner/reschedule/requests`, {
        eventId,
        postingDate,
    }),
    addPlannerComment: (data: IPlannerMessage) => requests.post(`${AGENCY_PLANNER}/planner/comment/send`, data),
    getRescheduleNotify: (): Promise<IRescheduleNotify[]> => requests.get(`${AGENCY_PLANNER}/planner/reschedule/requests`),
    deleteAttachment: (id: number) => requests.delete(`${AGENCY_PLANNER}/planner/attachment/${id}`),
}
