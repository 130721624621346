import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Campaign} from '../../../model/campaign/Campaign';
import usePagination from '../../../shared/hooks/usePagination';
import {IAllStates} from '../../../store/rootReducer';
import {ErrorToast} from '../../../utils/toasters';
import ArchiveInvoice from '../../influencer/invoices/components/ArchiveInvoice';
import CreateInvoice from '../../influencer/invoices/components/CreateInvoice';
import {AgencyInvoicesService} from './service';
import {SelectContentCreator} from '../components/SelectContentCreator';

const AgencyInvoices = () => {
    const {selectedInfluencer} = useSelector((state: IAllStates) => state.agency);
    const [archiveCampaigns, setArchiveCampaigns] = useState<Campaign[]>([]);
    const {filterParams, setFilterParams} = usePagination({});
    const {
        filterParams: archiveFilterParams,
        handlePages: archiveHandlePages,
        totalPages: totalPagesArchive,
        setTotalPages: setTotalPagesArchive,
        setFilterParams: setFilterParamsArchive,
        count, setCount,
    } = usePagination({});
    const [selectedCampaign, setSelectedCampaign] = useState<Campaign | undefined>(undefined);
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    
    useEffect(() => {
        !!selectedInfluencer?.id && AgencyInvoicesService.getDropdownCampaigns(Number(selectedInfluencer?.id), {...filterParams, perPage: 0})
            .then(response => {
            setCampaigns(response?.data);
        }).catch(err => ErrorToast(err));
    }, [filterParams, selectedInfluencer?.id, archiveFilterParams?.updatedAt]);

    useEffect(() => {
        if (!!selectedInfluencer?.id) {
            AgencyInvoicesService.getInvoiceArchive(selectedInfluencer?.id, {...archiveFilterParams}).then(response => {
                setArchiveCampaigns(response?.data);
                setCount(response.count);
                setTotalPagesArchive(Math.ceil(response.count / response.perPage));
            });
        }
    }, [selectedInfluencer?.id, selectedCampaign?.id, archiveFilterParams?.updatedAt, archiveFilterParams]);

    useEffect(() => {
        if (!!selectedInfluencer?.id) { // clear state after selecting campaign card, then selecting new user
            setSelectedCampaign(undefined);
        }
    }, [selectedInfluencer?.id]);
    return (
        <SelectContentCreator key={selectedInfluencer?.id}>
            <>
                <CreateInvoice
                    campaigns={campaigns}
                    selectedCampaign={selectedCampaign}
                    setSelectedCampaign={setSelectedCampaign}
                    userId={selectedInfluencer?.id}
                    setFilterParams={setFilterParams}
                    filterParams={filterParams}
                />
                <ArchiveInvoice
                    campaigns={archiveCampaigns}
                    totalPages={totalPagesArchive}
                    filterParams={archiveFilterParams}
                    setFilterParams={setFilterParamsArchive}
                    handlePages={archiveHandlePages}
                    count={count}
                />
            </>
        </SelectContentCreator>
    );
};

export default AgencyInvoices;
