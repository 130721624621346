import React from 'react';
import {Card} from 'react-bootstrap';
import {IRescheduleNotify} from '../dto/IPlanner';
import {useTranslation} from 'react-i18next';
import {plannerInfluencer} from '../../../../store/influencer/plannerSlice';
import {useDispatch} from 'react-redux';

interface IRescheduleCardProps {
    rescheduleData: IRescheduleNotify;
}
export function RescheduleCard({rescheduleData}: IRescheduleCardProps) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const openModal = () => {
        dispatch(plannerInfluencer.actions.setOpenPreviewModal({
            eventId: rescheduleData?.event?.id,
            isOpenPreviewEventModal: true,
        }));
    };
    return (
        <>
            <Card className="reschedule-card mb-3">
                <div className="d-flex align-items-center" onClick={openModal}>
                    <img className="social-icon mr-2" src={rescheduleData.event.offerItem.socialPlatform.path ?? ''}/>
                    <div>
                        <h6 className="mb-0">{rescheduleData?.event?.offerItem?.offer?.campaign?.title}</h6>
                        <p className="mb-0 text-info">
                            {/* {t(`general.platforms.${rescheduleData?.event?.offerItem?.socialPlatform?.name}`)}
                            {' '} */}
                            {t('influencer.planner.rescheduleRequest')}
                        </p>
                    </div>
                </div>
            </Card>
        </>
    );
}
