import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {Button, Card, Col, Row} from 'react-bootstrap';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {PackageFeaturesTable} from './PackageFeaturesTable';
import {getCommissionFee, getPackageColor, openCalendlyPopup} from '../../../../../shared/functions/Functions';

export function PackageOverviewContent() {
  const {selectedPackage} = useSelector((states: IAllStates) => states.packages);
  const {t} = useTranslation();
  const imgCheck = <i className="fi fi-rr-check"/>;
  const imgClose = <i className="fi fi-rr-cross"/>;
  const textColor = `text-${getPackageColor(selectedPackage?.name)} mb-0`;

  return (
    <>
      <Row className="mt-3">
        <Col md={8} className="px-0">
          <Row className="h-100">
            <Col md={6}>
              <h5 className="text-center mb-3">{t('brand.package.technology')}</h5>
              <div className="d-flex justify-content-between mb-2">
                <p className="text-muted mb-0">{t('brand.package.influencer_contracting')}</p>
                <p className={textColor}>
                  {selectedPackage?.influencerContracts ? imgCheck : imgClose}
                </p>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <p className="text-muted mb-0">{t('brand.package.influencer_payout')}</p>
                <p className={textColor}>{selectedPackage?.influencerPayouts ? imgCheck : imgClose}</p>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <p className="text-muted mb-0">{t('brand.package.manage_module')}</p>
                <p className={textColor}>{t(`brand.package.${selectedPackage?.manageModule}`)}</p>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <p className="text-muted mb-0">{t('brand.package.barter_deal_camp')}</p>
                <p className={textColor}>{selectedPackage?.allowBarterDeals ? imgCheck : imgClose}</p>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <p className="text-muted mb-0">{t('brand.package.team_collab')}</p>
                <p className={textColor}>
                  {selectedPackage?.teamCollaboration ? imgCheck : imgClose}
                </p>
              </div>
            </Col>
            <Col md={6} className="border-left">
              <h5 className="text-center mb-3">{t('brand.package.campaign_success')}</h5>
              <div className="d-flex justify-content-between mb-2">
                <p className="text-muted mb-0">{t('brand.package.success_manager')}</p>
                <p className={textColor}>{t(`brand.package.${selectedPackage?.successManager}`)}</p>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <p className="text-muted mb-0">{t('brand.package.briefing_modules')}</p>
                <p className={textColor}>{t(`brand.package.${selectedPackage?.briefingModule}`)}</p>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <p className="text-muted mb-0">{t('brand.package.briefing_feedback')}</p>
                <p className={textColor}>{t(`brand.package.${selectedPackage?.briefingFeedback}`)}</p>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <p className="text-muted mb-0">{t('brand.package.influencer_recommand')}</p>
                <p className={textColor}>{t(`brand.package.${selectedPackage?.influencerRecommendation}`)}</p>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <p className="text-muted mb-0">{t('brand.package.campaign_performance_review')}</p>
                <p className={textColor}>{t(`brand.package.${selectedPackage?.campaignFeedback}`)}</p>
              </div>
            </Col>
            <Col md={12}>
              <Card className="bg-primary-lighter mt-3">
                <Card.Body className="d-flex justify-content-between align-items-center">
                <div className="d-flex ">
                  <h6 className="text-primary mr-1 mb-0"> {t('brand.package.from')}</h6>
                  <h6 className="text-primary euro-symbol mb-0">{selectedPackage?.monthlyPrice}</h6> <h6 className="mb-0"> / month + {getCommissionFee(selectedPackage?.fee ?? 0)}% {t('brand.package.commission_fee')}</h6>
                </div>
                  <Button onClick={() => openCalendlyPopup('https://calendly.com/iv-user-experience/pro-upgrade-en?hide_gdpr_banner=1&primary_color=05bac9&')}>
                    {selectedPackage?.isSelected ? t('general.button.details') : t('general.button.upgrade')}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <img height={255} className="rounded img-cover w-100" src={selectedPackage?.image?.path} alt={selectedPackage?.image?.name}/>
        </Col>
        <Col>
          <PackageFeaturesTable/>
        </Col>
      </Row>
    </>
  );
};
