import {requests} from '../../../../utils';
import {BRAND_BRIEFING, BRIEFING_URL_MODAL} from '../../../../utils/endpoints';
import {ICreateCampaign} from './dto/ICreateCampaign';
import {ITimeline} from '../../../../model/briefing/step3/ITimeline';
import {IContentCreator} from '../../../../model/briefing/step5/IContentCreator';
import {IStepSeven} from './dto/IStepSeven';
import {IStepFour} from './dto/IStepFour';
import {IBriefingCommentRequest} from './dto/IBriefingCommentRequest';
import {BaseModel} from '../../../../model/shared/BaseModel';
import {iManageMessage} from '../manage/calendar/dto/IManageCalendar';

export const BriefingService = {
    getBriefingModalData: (id?: {}): Promise<ICreateCampaign> => requests.get(`${BRIEFING_URL_MODAL}/${id}`),
    getBriefingStepOne: (id: number) => requests.get(`${BRAND_BRIEFING}/first/${id}`),
    updateStepOne: (id: number, body: any) => requests.put(`${BRAND_BRIEFING}/first/${id}`, body),
    getBriefingByStep: (id: number, step: string) => requests.get(`${BRAND_BRIEFING}/${step}/${id}`),
    updateBriefingByStep: (id: number, body: any, step: string) => requests.put(`${BRAND_BRIEFING}/${step}/${id}`, body),
    getBriefingStepFourth: (id: {}): Promise<IStepFour> => requests.get(`${BRAND_BRIEFING}/fourth/${id}`),
    deleteBriefingImageByStep: (id: number, extraRoute: string) => requests.delete(`${BRAND_BRIEFING}/${extraRoute}/${id}`),
    getBriefingStepThree: (id: number) => requests.get((`${BRAND_BRIEFING}/third/${id}`)),
    updateStepThree: (id: number, body: ITimeline) => requests.put(`${BRAND_BRIEFING}/third/${id}`, body),
    getBriefingStepFive: (id: number) => requests.get((`${BRAND_BRIEFING}/fifth/${id}`)),
    updateStepFive: (id: number, body: IContentCreator) => requests.put(`${BRAND_BRIEFING}/fifth/${id}`, body),
    getStepSeven: (id: number): Promise<IStepSeven> => requests.get((`${BRAND_BRIEFING}/seventh/${id}`)),
    getCustomerSuccessComment: (id: number, props: any): Promise<BaseModel<iManageMessage[]>> => requests.get((`${BRAND_BRIEFING}/comment/get/${id}`), props),
    getBriefingComment: (id: number, props: any):
        Promise<BaseModel<iManageMessage[]>> => requests.get((`${BRAND_BRIEFING}/briefing/comment/get/${id}`), props),
    postCustomerSuccessComment:
        (body: IBriefingCommentRequest): Promise<iManageMessage[]> => requests.post((`${BRAND_BRIEFING}/comment/send`), body),
    postBriefingComment:
        (body: IBriefingCommentRequest): Promise<iManageMessage[]> => requests.post((`${BRAND_BRIEFING}/briefing/comment/send`), body),
};
