import {Col, Dropdown, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {PackagesEnum} from '../../../../../enum/PackageEnum';
import {getCommissionFee, getPackageColor} from '../../../../../shared/functions/Functions';
import {IPackages} from '../../../../../model/shared/IPackages';
import {packagesSlice} from '../../../../../store/brand/packagesSlice';
import { priceSplitter } from '../../../../../utils/helpers/priceSplitter';

export function PackageFeaturesTable() {
  const {selectedPackage, allPackages, isComparisonActive, comparedPackage} = useSelector((states: IAllStates) => states.packages);
  const {t} = useTranslation();
  const {name} = selectedPackage as IPackages ?? {};
  const dispatch = useDispatch();
  const imgCheck = <i className="fi fi-rr-check"/>;
  const imgCross = <i className="fi fi-rr-cross"/>;

  const selectComperedPackage = (packageName: string) => {
    dispatch(packagesSlice.actions.setComperedPackage(packageName));
  };
  return (
    <>
      <div className="mt-4 mb-3 d-flex justify-content-between align-items-center">
        <h6 className="mb-0 text-primary">
          {t('brand.package.all_features', {packageName: t(`brand.package.${selectedPackage?.name}`)})}
        </h6>
        <Dropdown onClick={() => {
        }}>
          <Dropdown.Toggle
            className="mr-2"
            variant="light"
            id="dropdown-basic"
          >
            {t('brand.package.compare_to', {packageName: t(`brand.package.${comparedPackage?.name}`)})}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {allPackages?.map((item) => (
              <Dropdown.Item onClick={() => selectComperedPackage(item.name)} className={`${name === item.name && 'd-none'}`}>
                {t(`brand.package.${item.name}`)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Row className="border-bottom mt-2">
        {isComparisonActive &&
        <>
            <Col xs={6}/>
            <Col xs={3} className={`text-${getPackageColor(name)} text-right`}>
              <h5>
                {t(`brand.package.${selectedPackage?.name}`)}
              </h5>
            </Col>
            <Col xs={3} className={`text-${getPackageColor(comparedPackage?.name)} text-right`}>
              <h5>
                {t(`brand.package.${comparedPackage?.name}`)}
              </h5>
            </Col>
        </>
        }
        <Col xs={3} className="pl-0">
          <h6 dangerouslySetInnerHTML={{__html: t('brand.package.users_types')}}/>
        </Col>
        <Col xs={3}>
          <p className="text-muted">{t('brand.package.admin_account')}</p>
          <p className="text-muted">{t('brand.package.manager_account')}</p>
          <p className="text-muted">{t('brand.package.team_collab')}</p>
          <p className="text-muted">{t('brand.package.external_collab')}</p>
        </Col>
        <Col xs={isComparisonActive ? 3 : 6}>
          <div className={`text-${getPackageColor(name)} text-right`}>
            <p>{selectedPackage?.nbMasters ?? 0 > 1 ? t('brand.package.individual') : selectedPackage?.nbMasters}</p>
            <p>{selectedPackage?.nbEmployees === 99 ? t('brand.package.individual') : (selectedPackage?.nbEmployees === 0 ? imgCross : selectedPackage?.nbEmployees)}</p>
            <p>{selectedPackage?.teamCollaboration ? imgCheck : imgCross}</p>
            <p>{selectedPackage?.externalCollaboration ? imgCheck : imgCross}</p>
          </div>
        </Col>
        {isComparisonActive &&
            <Col xs={3}>
                <div className={`text-${getPackageColor(comparedPackage?.name)} text-right`}>
                    <p>{comparedPackage?.nbMasters ?? 0 > 1 ? t('brand.package.individual') : selectedPackage?.nbMasters}</p>
                    <p>{comparedPackage?.nbEmployees ?? 0 > 1 ? t('brand.package.individual') : (selectedPackage?.nbEmployees === 0 ? imgCross : selectedPackage?.nbEmployees)}</p>
                    <p>{comparedPackage?.teamCollaboration ? imgCheck : imgCross}</p>
                    <p>{comparedPackage?.externalCollaboration ? imgCheck : imgCross}</p>
                </div>
            </Col>
        }
      </Row>
      <Row className="mt-2 border-bottom">
        <Col xs={3} className="pl-0">
          <h6 dangerouslySetInnerHTML={{__html: t('brand.package.contracting_invoice')}}/>
        </Col>
        <Col xs={3}>
          <p className="text-muted">{t('brand.package.influencer_contracting')}</p>
          <p className="text-muted">{t('brand.package.influencer_payout')}</p>
          <p className="text-muted">{t('brand.package.long_term_colab')}</p>
          <p className="text-muted">{t('brand.package.barter_deal_camp')}</p>
          <p className="text-muted">{t('brand.package.private_camp')}</p>
        </Col>
        <Col xs={isComparisonActive ? 3 : 6}>
          <div className={`text-${getPackageColor(name)} text-right`}>
            <p>{selectedPackage?.influencerContracts ? imgCheck : imgCross}</p>
            <p>{selectedPackage?.influencerPayouts ? imgCheck : imgCross}</p>
            <p>{selectedPackage?.longTermCollaborations ? imgCheck : imgCross}</p>
            <p>{selectedPackage?.allowBarterDeals ? imgCheck : imgCross}</p>
            <p>{selectedPackage?.allowPrivateCampaigns ? imgCheck : imgCross}</p>
          </div>
        </Col>
        {isComparisonActive &&
            <Col xs={3}>
                <div className={`text-${getPackageColor(comparedPackage?.name)} text-right`}>
                    <p>{comparedPackage?.influencerContracts ? imgCheck : imgCross}</p>
                    <p>{comparedPackage?.influencerPayouts ? imgCheck : imgCross}</p>
                    <p>{comparedPackage?.longTermCollaborations ? imgCheck : imgCross}</p>
                    <p>{comparedPackage?.allowBarterDeals ? imgCheck : imgCross}</p>
                    <p>{comparedPackage?.allowPrivateCampaigns ? imgCheck : imgCross}</p>
                </div>
            </Col>
        }
      </Row>
      <Row className="mt-2 border-bottom">
        <Col xs={3} className="pl-0">
          <h6 dangerouslySetInnerHTML={{__html: t('brand.package.social_media_analytics')}}/>
        </Col>
        <Col xs={3}>
          <p className="text-muted">{t('brand.package.social_media_platform')}</p>
          <p className="text-muted">{t('brand.package.influencer_analytics')}</p>
          <p className="text-muted">{t('brand.package.influencer_invitation')}</p>
          <p className="text-muted">{t('brand.package.influencer_reviews')}</p>
        </Col>
        <Col xs={isComparisonActive ? 3 : 6}>
          <div className={`text-${getPackageColor(name)} text-right`}>
            <p>{t(`brand.package.${selectedPackage?.socialMediaPlatforms}`)}</p>
            <p>{selectedPackage?.profileAnalytics ? imgCheck : imgCross}</p>
            <p>{t(`brand.package.${selectedPackage?.searchAndInvite}`)}</p>
            <p>{selectedPackage?.reviews ? imgCheck : imgCross}</p>
          </div>
        </Col>
        {isComparisonActive &&
            <Col xs={3}>
                <div className={`text-${getPackageColor(comparedPackage?.name)} text-right`}>
                    <p>{t(`brand.package.${selectedPackage?.socialMediaPlatforms}`)}</p>
                    <p>{comparedPackage?.profileAnalytics ? imgCheck : imgCross}</p>
                    <p>{t(`brand.package.${selectedPackage?.searchAndInvite}`)}</p>
                    <p>{comparedPackage?.reviews ? imgCheck : imgCross}</p>
                </div>
            </Col>
        }
      </Row>
      <Row className="mt-2 border-bottom">
        <Col xs={3} className="pl-0">
          <h6 dangerouslySetInnerHTML={{__html: t('brand.package.advanced_iv_modules')}}/>
        </Col>
        <Col xs={3}>
          <p className="text-muted">{t('brand.package.briefing_modules')}</p>
          <p className="text-muted">{t('brand.package.ai_feature')}</p>
          <p className="text-muted">{t('brand.package.book_module')}</p>
          <p className="text-muted">{t('brand.package.manage_module')}</p>
          <p className="text-muted">{t('brand.package.measure_module')}</p>
          <p className="text-muted">{t('brand.package.message_module')}</p>
        </Col>
        <Col xs={isComparisonActive ? 3 : 6}>
          <div className={`text-${getPackageColor(name)} text-right`}>
            <p>{t(`brand.package.${selectedPackage?.briefingModule}`)}</p>
            <p>{selectedPackage?.allowBriefingAI ? imgCheck : imgCross}</p>
            <p>{t(`brand.package.${selectedPackage?.bookingModule}`)}</p>
            <p>{t(`brand.package.${selectedPackage?.manageModule}`)}</p>
            <p>{t(`brand.package.${selectedPackage?.measureModule}`)}</p>
            <p>{t(`brand.package.${selectedPackage?.messageModule}`)}</p>
          </div>
        </Col>
        {isComparisonActive &&
            <Col xs={3}>
                <div className={`text-${getPackageColor(comparedPackage?.name)} text-right`}>
                    <p>{t(`brand.package.${comparedPackage?.briefingModule}`)}</p>
                    <p>{comparedPackage?.allowBriefingAI ? imgCheck : imgCross}</p>
                    <p>{t(`brand.package.${comparedPackage?.bookingModule}`)}</p>
                    <p>{t(`brand.package.${comparedPackage?.manageModule}`)}</p>
                    <p>{t(`brand.package.${comparedPackage?.measureModule}`)}</p>
                    <p>{t(`brand.package.${comparedPackage?.messageModule}`)}</p>
                </div>
            </Col>
        }
      </Row>
      <Row className="mt-2 border-bottom">
        <Col xs={3} className="pl-0">
          <h6 dangerouslySetInnerHTML={{__html: t('brand.package.customer_success_tech_sup')}}/>
        </Col>
        <Col xs={3}>
          <p className="text-muted">{t('brand.package.success_manager')}</p>
          <p className="text-muted">{t('brand.package.briefing_feedback')}</p>
          <p className="text-muted">{t('brand.package.influencer_recommand')}</p>
          <p className="text-muted">{t('brand.package.creator_sourcing')}</p>
          <p className="text-muted">{t('brand.package.campaign_performance_review')}</p>
          <p className="text-muted">{t('brand.package.platform_training')}</p>
          <p className="text-muted">{t('brand.package.campaign_transfer')}</p>
          <p className="text-muted">{t('brand.package.tech_support')}</p>
        </Col>
        <Col xs={isComparisonActive ? 3 : 6}>
          <div className={`text-${getPackageColor(name)} text-right`}>
            <p>{t(`brand.package.${selectedPackage?.successManager}`)}</p>
            <p>{t(`brand.package.${selectedPackage?.briefingFeedback}`)}</p>
            <p>{t(`brand.package.${selectedPackage?.influencerRecommendation}`)}</p>
            <p>{t(`brand.package.${selectedPackage?.creatorSourcing}`)}</p>
            <p>{t(`brand.package.${selectedPackage?.campaignFeedback}`)}</p>
            <p>{t(`brand.package.${selectedPackage?.platformTraining}`)}</p>
            <p>{selectedPackage?.techSupport ? imgCheck : imgCross}</p>
            <p>{selectedPackage?.allowCampaignTransfers ? imgCheck : imgCross}</p>
          </div>
        </Col>
        {isComparisonActive &&
            <Col xs={3}>
                <div className={`text-${getPackageColor(comparedPackage?.name)} text-right`}>
                    <p>{t(`brand.package.${comparedPackage?.successManager}`)}</p>
                    <p>{t(`brand.package.${comparedPackage?.briefingFeedback}`)}</p>
                    <p>{t(`brand.package.${comparedPackage?.influencerRecommendation}`)}</p>
                    <p>{t(`brand.package.${comparedPackage?.creatorSourcing}`)}</p>
                    <p>{t(`brand.package.${comparedPackage?.campaignFeedback}`)}</p>
                    <p>{t(`brand.package.${comparedPackage?.platformTraining}`)}</p>
                    <p>{comparedPackage?.techSupport ? imgCheck : imgCross}</p>
                    <p>{comparedPackage?.allowCampaignTransfers ? imgCheck : imgCross}</p>
                </div>
            </Col>
        }
      </Row>
      <Row>
        <Col xs={3} className="pl-0">
          <h6 dangerouslySetInnerHTML={{__html: t('brand.package.costs')}}/>
        </Col>
        <Col xs={3}>
          <p className="text-muted">{t('brand.package.monthly_payment')}</p>
          <p className="text-muted">{t('brand.package.annual_payment')}</p>
          <p className="text-muted">{t('brand.package.commission_fee_percent')}</p>
        </Col>
        <Col xs={isComparisonActive ? 3 : 6}>
          <div className={`text-${getPackageColor(name)} text-right`}>
            <p className={`${selectedPackage?.monthlyPrice !== 0 && 'euro-symbol'}`}>
              {selectedPackage?.monthlyPrice === 0 ? t('brand.package.no_fixed_payment') : selectedPackage?.monthlyPrice}
            </p>
            <p className={`${selectedPackage?.annualPrice !== 0 && 'euro-symbol'}`}>
              {selectedPackage?.annualPrice === 0 ? t('brand.package.no_fixed_payment') : selectedPackage?.annualPrice}
            </p>
            <p>
              {selectedPackage?.fee === 0 ? t('brand.package.no_fixed_payment') :  priceSplitter(+getCommissionFee(selectedPackage?.fee ?? 0)) + '%'}
            </p>
          </div>
        </Col>
        {isComparisonActive &&
            <Col xs={3}>
                <div className={`text-${getPackageColor(comparedPackage?.name)} text-right`}>
                    <p className="euro-symbol">{comparedPackage?.monthlyPrice}</p>
                    <p className="euro-symbol">{comparedPackage?.annualPrice}</p>
                    <p>{getCommissionFee(comparedPackage?.fee ?? 0)}%</p>
                </div>
            </Col>
        }
      </Row>
    </>
  );
}
