import {requests} from '../../../utils';
import {IOfferManagement} from './dto/IOfferManagement';
import {
    INFLUENCER_INITIAL_OFFER,
    INFLUENCER_OFFER,
    INFLUENCER_OFFER_MANAGEMENT,
    INFLUENCER_OFFER_MODAL,
} from '../../../utils/endpoints';
import {IOfferManagementFilter} from './dto/IOfferManagementFilter';
import {ICampaignOverview} from '../../../model/briefing/overview/ICampaignOverview';
import {IInviteInfluencer} from '../../../model/book/Invites';
import {IOffer} from '../../../model/offer/IOffer';
import {IDeclineOffer} from './dto/IDeclineOffer';

export const ManagementOfferService = {
    getOfferList: (params: IOfferManagementFilter): Promise<IOfferManagement> => requests.get(INFLUENCER_OFFER_MANAGEMENT, params),
    getOfferModalDetail: (id: string): Promise<ICampaignOverview> => requests.get(`${INFLUENCER_OFFER_MODAL}/${id}`),
    sendInitialOffer: (id: string, body: IInviteInfluencer) => requests.post(`${INFLUENCER_INITIAL_OFFER}/${id}`, body),
    sendCounterOffer: (id: string, body: IInviteInfluencer) => requests.put(`${INFLUENCER_OFFER}/${id}`, body),
    acceptOffer: (id: string) => requests.put(`${INFLUENCER_OFFER}/accept/${id}`, {}),
    withdrawOffer: (id: string) => requests.put(`${INFLUENCER_OFFER}/withdraw/${id}`, {}),
    declineOffer: (id: string, body: IDeclineOffer) => requests.put(`${INFLUENCER_OFFER}/decline/${id}`, body),
    getOfferHistory: (id?: string): Promise<IOffer[]> => requests.get(`${INFLUENCER_OFFER_MODAL}/history/${id}`),
};
