import React from 'react';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {FiInfo} from 'react-icons/fi';

interface Props {
    iconPath?: string;
    desc?: string;
    btnText?: string;
    navigateTo?: string;
    addClass?: string;
    imgClass?: string;
    disableButton?: boolean;
    tooltipDesc?: string;
    title?: string;
    link?: {
        href: string;
        class: string;
        text: string;
        target?: string;
    }
    func?: (...args: any) => void;
}

const EmptyState = ({
                        desc,
                        btnText,
                        iconPath = '/icons/line-graph.png',
                        imgClass = '',
                        navigateTo,
                        addClass,
                        disableButton = false,
                        tooltipDesc,
                        title,
                        link,
                        func
                    }: Props) => {
    const history = useHistory();
    const {t} = useTranslation();
    return (
        <div className={`d-flex align-items-center justify-content-center flex-column w-100 ${addClass ?? ''}`}>
            <img src={iconPath} className={`mt-3 ${imgClass}`} alt="Line graph icon"/>
            {!!title && (
                <h5 className="mt-2">{title}</h5>
            )}
            <span className="text-muted mt-2 mb-4">{desc ?? t('general.emptyList')}</span>
            {(!!navigateTo?.length || !!func) &&
                <div className="d-flex align-items-center">
                    <Button disabled={disableButton} onClick={!!func ? func : () => history.push(navigateTo ?? '/')}
                            variant="outline-primary" size="sm">
                        {btnText}
                    </Button>
                    {disableButton &&
                        <OverlayTrigger placement="top"
                                        overlay={<Tooltip id="touchpoints-tooltip">
                                            {tooltipDesc}
                                        </Tooltip>}>
                            <FiInfo size={14} className="cursor-pointer ml-1"/>
                        </OverlayTrigger>
                    }
                </div>
            }
            {!!link && (
                <a href={link?.href} target={link?.target ?? '_self'} className={link?.class}>{link?.text}</a>
            )}
        </div>
    );
};

export default EmptyState;
