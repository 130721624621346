import * as React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {IParams} from '../../../model/shared/IParams';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../store/rootReducer';
import {Step1} from './components/Step1';
import {Step2} from './components/Step2';
import {Step3} from './components/Step3';
import {Step4} from './components/Step4';
import {Step5} from './components/Step5';
import {Step7} from './components/Step7';
import {useCallback} from 'react';
import {StepperType} from '../brand';
import {
    getCorrectRegisterAgencyText,
    getCorrectRegisterStepper,
} from '../../../shared/functions/Functions';
import {useSearchQuery} from '../../../shared/hooks/useSearchQuery';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {InternalRoutes} from '../../../enum/InternalRoutes';

export const RegisterAgency = () => {
    const history = useHistory();
    const {stepId} = useParams<IParams>();
    const stepIdNumber = Number(stepId);
    const {t} = useTranslation();
    const token = useSearchQuery('token') as string;
    const handleBackStep = () => {
        history.push(`${InternalRoutes.AGENCY_REGISTRATION}/${stepIdNumber - 1}?token=${token}`);
    };

    const savedData = useSelector((state: IAllStates) => state.registration);
    const step: StepperType = {
        1: <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}>
            <Step1 stepIdNumber={stepIdNumber} handleBackStep={handleBackStep}/>
        </GoogleReCaptchaProvider>,
        2: <Step2 stepIdNumber={stepIdNumber} handleBackStep={handleBackStep}/>,
        3: <Step3 stepIdNumber={stepIdNumber} handleBackStep={handleBackStep}/>,
        4: <Step4 stepIdNumber={stepIdNumber} handleBackStep={handleBackStep}/>,
        5: <Step5 stepIdNumber={stepIdNumber} handleBackStep={handleBackStep}/>,
        // 6: <Step6 stepIdNumber={stepIdNumber} handleBackStep={handleBackStep}/>,
        6: <Step7 stepIdNumber={stepIdNumber} handleBackStep={handleBackStep}/>,
        // 8: <Step8 stepIdNumber={stepIdNumber} handleBackStep={handleBackStep}/>,
    };

    const CurrentStep = useCallback(() => {
        return step[stepIdNumber];
        // eslint-disable-next-line
    }, [stepIdNumber]);
    return (
        <>
            <div className="row">
                {getCorrectRegisterStepper('registerAgency', `${stepIdNumber}`)}
                <div className="col-12">
                    <h3>
                        {getCorrectRegisterAgencyText(`${stepIdNumber}`, t)?.title}
                    </h3>
                    <p className="text-black-50 mb-4">
                        {getCorrectRegisterAgencyText(`${stepIdNumber}`, t, savedData?.email)?.description}
                    </p>
                </div>
            </div>
            <div className="row w-100">
                <CurrentStep/>
            </div>
        </>
    );
};
