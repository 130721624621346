import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Campaign} from '../../../../../model/campaign/Campaign';
import EmptyState from '../../../../../shared/components/placeholder/EmptyState';
import CardView from '../desktop/CardView';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {UserRoles} from '../../../../../enum/UserRoles';

interface Props {
    campaigns: Campaign[];
}

const MyCampaigns = ({campaigns}: Props) => {
    const {t} = useTranslation();
    const {user: {userType}} = useSelector((state: IAllStates) => state.auth);
    const history = useHistory();

    const navigateToMyCampaigns = () => {
        [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(userType as UserRoles) ?
            history.push('/agency/campaigns') :
            history.push('/influencer/campaigns');
    };

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <h5 className="mb-0">{t('general.tabs.campaigns')}</h5>
                <p onClick={navigateToMyCampaigns} className="text-uppercase text-muted text-underline mb-0">
                    {t('influencer.header.myCampaigns')}
                </p>
            </div>
            {!!campaigns?.length ? 
                <CardView campaigns={campaigns}/>
                : <div className="card mt-2">
                    <div className="card-body">
                        <EmptyState/>
                    </div>
                </div>
            }
        </>
    );
};

export default MyCampaigns;
