import {requests} from '../../../utils';
import {
    AGENCY_COMPANY_BILLING_URL,
    AGENCY_COMPANY_URL,
    AGENCY_USER_URL,
    CHANGE_PASSWORD_URL, CHECK_DISPLAY_NAME, CHECK_EMAIL, DELETE_USER_PROFILE_URL,
    GLOBAL_PREFERENCE_URL,
    PERSONAL_INFO_URL, PRICE_LIST_URL, PROFILE_BILLING_INFO_URL, PROFILE_COMPANY_BILLING_URL,
    PROFILE_COMPANY_URL, PROFILE_COMPANY_USERS_URL, PROFILE_CREATE_COMPANY_USER_URL, PROFILE_PRIVATE_COMMENT_URL, PROFILE_SEND_COMMENT_URL, RIGHTS_AND_EXCLUSIVITY_URL
} from '../../../utils/endpoints';
import {IChangePassword} from './components/LoginAndSecutiryModal';
import {IPersonalInfo} from './dto/IPersonalInfo';
import {ICompany} from './dto/ICompany';
import {ICompanyUser} from './dto/ICompanyUser';
import {IBaseModal} from './dto/IBaseModal';
import {IPriceList} from '../../../model/shared/IPriceList';
import {IRightsExclusivity} from './dto/IRights&Exclusivity';
import {IGlobalPreference} from './dto/IGlobalPreference';
import {IBillingInfo} from './dto/IBillingInfo';

export const UserService = {
    changePassword: (body: IChangePassword) => requests.put(CHANGE_PASSWORD_URL, body),
    getGlobalPreference: (): Promise<IGlobalPreference> => requests.get(GLOBAL_PREFERENCE_URL),
    updateGlobalPreference: (body: IGlobalPreference): Promise<IGlobalPreference> => requests.put(GLOBAL_PREFERENCE_URL, body),
    getPersonalInfo: (id: number): Promise<IBaseModal<IPersonalInfo>> => requests.get(`${PERSONAL_INFO_URL}/${id}`),
    updatePersonalInfo: (body: IPersonalInfo, id: number) => requests.put(`${PERSONAL_INFO_URL}/${id}`, body),
    getCompanyInfo: (): Promise<IBaseModal<ICompany>> => requests.get(PROFILE_COMPANY_URL),
    updateCompanyBilling: (body: ICompany): Promise<IBaseModal<ICompany>> => requests.put(PROFILE_COMPANY_BILLING_URL, body),
    getInfluencerBilling: (): Promise<IBillingInfo> => requests.get(PROFILE_BILLING_INFO_URL),
    updateInfluencerBilling: (body: ICompany) => requests.put(PROFILE_BILLING_INFO_URL, body),
    getCompanyUsers: (params?: any): Promise<ICompanyUser[]> => requests.get(PROFILE_COMPANY_USERS_URL, params),
    getUsersContracts: (params?: any): Promise<any> => requests.get(PROFILE_COMPANY_USERS_URL+'/contracts', params),
    getUsersContractsDropdown: (params?: any): Promise<any> => requests.get(PROFILE_COMPANY_USERS_URL+'/contracts/dropdown', params),
    createCompanyUser: (body: ICompanyUser) => requests.post(PROFILE_CREATE_COMPANY_USER_URL, body),
    updateCompanyUser: (body: ICompanyUser, id: number | undefined) => requests.put(`${PROFILE_CREATE_COMPANY_USER_URL}/${id}`, body),
    disableCompanyUser: (id: string) => requests.put(`${PROFILE_CREATE_COMPANY_USER_URL}/${id}/disable`, {}),
    deleteCompanyUser: (id: string) => requests.delete(`${PROFILE_CREATE_COMPANY_USER_URL}s/${id}`),
    getPriceList: (id: number): Promise<IPriceList[]> => requests.get(`${PRICE_LIST_URL}/${id}`),
    updatePriceList: (body: IPriceList[]) => requests.put(PRICE_LIST_URL, {priceList: body}),
    getRightsExclusivity: (id: number): Promise<IRightsExclusivity> => requests.get(`${RIGHTS_AND_EXCLUSIVITY_URL}/${id}`),
    updateRightsExclusivity: (id: number, body: IRightsExclusivity): Promise<IRightsExclusivity> => requests.put(`${RIGHTS_AND_EXCLUSIVITY_URL}/${id}`, body),    checkDisplayName: (displayName: string) => requests.put(CHECK_DISPLAY_NAME, {displayName}),
    checkEmail: (email: string) => requests.put(CHECK_EMAIL, {email}),
    getAgencyCompanyUsers: (params?: any): Promise<ICompanyUser[]> => requests.get(`${AGENCY_USER_URL}s`, params),
    createAgencyUser: (body: ICompanyUser) => requests.post(AGENCY_USER_URL, body),
    updateAgencyUser: (body: ICompanyUser, id: number | undefined) => requests.put(`${AGENCY_USER_URL}/${id}`, body),
    disableAgencyUser: (id: string) => requests.put(`${AGENCY_USER_URL}/${id}/disable`, {}),
    getAgencyBilling: (): Promise<IBaseModal<ICompany>> => requests.get(AGENCY_COMPANY_URL),
    updateAgencyBilling: (body: ICompany) => requests.put(AGENCY_COMPANY_BILLING_URL, body),
    deleteProfile: (id: number) => requests.delete(`${DELETE_USER_PROFILE_URL}/${id}`),
    getPrivateComment: (id: number) => requests.get(`${PROFILE_PRIVATE_COMMENT_URL}/${id}`),
    putPrivateComment: (id: number, body:{message: string}) => requests.put(`${PROFILE_SEND_COMMENT_URL}/${id}`, body),
};
