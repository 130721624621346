import React from 'react';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {MessagesIcon} from '../../../../shared/Icons';
import {openCalendlyPopup} from '../../../../shared/functions/Functions';

interface ICreatorSuccessCardProps {
    isOnDashboard?: boolean;
}

const CreatorSuccessCard = ({isOnDashboard = false}: ICreatorSuccessCardProps) => {
    const {t} = useTranslation();
    return (
        <Card className="box-shadow-primary border-dashed border-primary">
            <Card.Body className="p-lg-3 p-2">
                <h6 className="text-muted d-flex align-items-center h-100">
                    <MessagesIcon width="20" height="20" color="#9ea5ab" classes="mr-2"/>
                    <div>{t('agency.dashboard.creator_agency_support')}</div>
                </h6>
                <div className="d-flex justify-content-between align-items-end">
                    <div className="d-flex align-items-center mt-lg-3 mt-2">
                        <img src="/tempAssets/black-iv-icon.png" className="mr-2"
                             width={20}
                             height={20}
                             alt="Support profile"/>
                        <div>
                            <h6 className="mb-0">Creator Success Team</h6>
                            <a href="mailto:creatorsuccess@influencevision.com" className="word-break font-11">
                                Contact
                            </a>
                        </div>
                    </div>
                    {!isOnDashboard &&
                        <div className="d-flex align-items-center justify-content-end">
                            <div className="text-underline text-primary cursor-pointer"
                                 onClick={() => openCalendlyPopup('https://calendly.com/creator-success-team/creator-management-onboarding-call')!}>Book
                                meeting
                            </div>
                        </div>
                    }
                </div>
                {isOnDashboard &&
                    <div className="d-flex align-items-center justify-content-end mt-3">
                        <div className="text-underline text-primary cursor-pointer mt-1"
                             onClick={() => openCalendlyPopup('https://calendly.com/creator-success-team/creator-management-onboarding-call')!}>Book
                            meeting
                        </div>
                    </div>
                }
            </Card.Body>
        </Card>
    );
};

export default CreatorSuccessCard;
