import React, {useEffect, useState} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import {useHistory, useLocation} from 'react-router-dom';
import {InternalRoutes} from '../../../../enum/InternalRoutes';
import {useTranslation} from 'react-i18next';
import {scrollToTop} from '../../../../utils/helpers/ScrollToTop';
import {CustomImageHandler} from '../../../components/CustomImageHandler';
import {MobileMenu} from '../MobileMenu';
import {UserRoles} from '../../../../enum/UserRoles';
import {SupportTutorialIcon} from '../../../components/SupportTutorialIcon';
import SupportModal from './SupportModal';
import {SupportWidget} from '../../../SupportWidget';

const LeftSideMenu = () => {
    const history = useHistory();
    const {pathname} = useLocation();
    const [isSupportOpened, setIsSupportOpened] = useState(false);
    const [isMenuOpen, setMenuOpen] = useState(true);
    const {redDots} = useSelector((state: IAllStates) => state.redDots);
    const [tabs, setTabs] = useState<string[]>([]);
    const {user: {favoriteCampaigns, userType}} = useSelector((state: IAllStates) => state.auth);
    const {t} = useTranslation();
    const {AGENCY, AGENCY_MASTER, INFLUENCER, BRAND, BRAND_MASTER} = UserRoles;
    const [key, setKey] = useState<string>('dashboard');
    const isInfluencerMobileScreen = userType === INFLUENCER && window.innerWidth <= 991;
    const openCampaignDashboard = (id: number) => {
        history.push(`${InternalRoutes.BRAND_CAMPAIGN}/dashboard/${id}`);
        scrollToTop();
    };
    const currentCampaignId = Number(pathname?.split('/')?.find(item => !!+item));
    const brandTabs = [
        `${t('brand.campaign.tabs.dashboard')}-dashboard`,
        `${t('brand.campaign.tabs.book')}-book`,
        `${t('brand.campaign.tabs.manage')}-manage`,
        `${t('brand.campaign.tabs.measure')}-measure`,
    ];

    const influencerTabs = [
        `${t('brand.campaign.tabs.dashboard')}-dashboard`,
        `${t('influencer.campaign.tabs.explore')}-explore`,
        `${t('influencer.campaign.tabs.offers')}-offers`,
        `${t('influencer.campaign.tabs.planner')}-planner`,
        `${t('influencer.campaign.tabs.reporting')}-reporting`,
        `${t('influencer.campaign.tabs.messages')}-messages`,
    ];

    const influencerTabsMobile = [
        `${t('influencer.campaign.tabs.my_campaigns')}-dashboard`,
        `${t('influencer.campaign.tabs.explore')}-explore`,
        `${t('influencer.campaign.tabs.offers')}-offers`,
        `${t('influencer.campaign.tabs.messages')}-messages`,
    ];

    const agencyTabs = [
        `${t('influencer.campaign.tabs.influencers')}-influencers`,
        `${t('influencer.campaign.tabs.dashboard')}-dashboard`,
        `${t('influencer.campaign.tabs.explore')}-explore`,
        `${t('influencer.campaign.tabs.offers')}-offers`,
        `${t('influencer.campaign.tabs.planner')}-planner`,
        `${t('influencer.campaign.tabs.reporting')}-reporting`,
        `${t('influencer.campaign.tabs.messages')}-messages`,
    ];

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setKey(newValue);
        if (userType === BRAND_MASTER) {
            history.push(`${InternalRoutes.BRAND_CAMPAIGN}`);
        } else if (userType === INFLUENCER) {
            history.push(`${InternalRoutes.INFLUENCER_ROUTES}/${newValue}`);
        } else if ([AGENCY, AGENCY_MASTER].includes(userType as UserRoles)) {
            history.push(`${InternalRoutes.AGENCY_ROUTES}/${newValue}`);
        }
    };


    const getNotificationNumber = (tabKey: string) => {
        const dotMap: { [key: string]: number } = {
            'explore': Number(redDots?.explore || 0),
            'offers': Number(redDots?.offers || 0),
            'reporting': Number(redDots?.reporting || 0),
            'planner': Number(redDots?.planner || 0),
        };

        return dotMap[tabKey ?? ''] || 0;
    }
    // const openLeftMenu = () => {
    //     setMenuOpen(!isMenuOpen);
    // };

    useEffect(() => {
        if (userType === BRAND_MASTER) {
            setTabs(brandTabs);
        } else if (userType === INFLUENCER) {
            if (isInfluencerMobileScreen) {
                setTabs(influencerTabsMobile);
            } else {
                setTabs(influencerTabs);
            }
        } else if ([AGENCY, AGENCY_MASTER].includes(userType as UserRoles)) {
            setTabs(agencyTabs);
        }
        // eslint-disable-next-line
    }, [userType]);

    useEffect(() => {
        const currKey = String(pathname)?.split('/').at(-1) ?? 'dashboard';
        setKey(currKey);
    }, [pathname]);

    return (
        <>
            <SupportModal show={isSupportOpened} closeModal={() => setIsSupportOpened(prev => !prev)}/>
            <div className="position-relative">
                <div
                    className={`left-side-bar ${isInfluencerMobileScreen ? 'influencer-mobile' : ''} flex-column align-items-center py-4 ${
                        isMenuOpen ? 'opened' : 'closed'
                    } height-95vh`}
                >
                    {/*<Button onClick={openLeftMenu} variant="outline" className="switch-btn">*/}
                    {/*  {isMenuOpen ? <VscEyeClosed size={15}/> : <VscEye size={15}/>}*/}
                    {/*</Button>*/}
                    <div className="option-bar h-100 d-flex flex-column align-items-center justify-content-between">
                        <div className={`campaign-list ${[AGENCY, AGENCY_MASTER].includes(userType as UserRoles) && 'agency-side'}`}>
                            {(userType === BRAND_MASTER || userType === BRAND) ?
                                (favoriteCampaigns?.map((campaign) => (
                                    <a
                                        href="#1"
                                        key={campaign.id}
                                        onClick={() => openCampaignDashboard(campaign.id)}
                                    >
                                        <OverlayTrigger
                                            placement="right"
                                            overlay={
                                                <Tooltip id="camp-tooltip">{campaign.title}</Tooltip>
                                            }
                                        >
                                            <div
                                                className={`sidebar-image ${campaign?.id === currentCampaignId ? 'active' : ''}`}>
                                                <CustomImageHandler
                                                    thumbnailPath={campaign?.campaignImages?.[0]?.thumbnail}
                                                    photoPath={campaign?.campaignImages?.[0]?.thumbnail ?? 'https://www.pulsecarshalton.co.uk/wp-content/uploads/2016/08/jk-placeholder-image.jpg'}
                                                    classes={`icon ${campaign?.id === currentCampaignId ? 'active' : ''}`}
                                                    altTag={campaign?.campaignImages?.[0]?.name}/>
                                            </div>
                                        </OverlayTrigger>
                                    </a>
                                ))) :
                                (tabs.map((tab, index) => {
                                    const tabKey = tab?.split('-')[1];
                                    return (
                                        <div
                                            className="tab text-center cursor-pointer position-relative"
                                            onClick={(e) => {
                                                handleChange(e, tabKey);
                                            }}
                                            key={index}
                                        >
                                            <span>
                                                {key !== tabKey ? (
                                                    <img
                                                        alt={tab}
                                                        width="30"
                                                        height="30"
                                                        src={`/icons/img-${tabKey}.png`}
                                                    />
                                                ) : (
                                                    <img
                                                        alt={tab}
                                                        width="30"
                                                        height="30"
                                                        src={`/icons/img-${tabKey}-active.png`}
                                                    />
                                                )}
                                                <p className="font-10">{t(tab?.split('-')[0])}</p>
                                                {!!getNotificationNumber(tabKey) && <span className="sidebar-bullet">
                                                    {getNotificationNumber(tabKey)}
                                                </span>}
                                            </span>
                                        </div>
                                    );
                                }))}

                        </div>
                    </div>
                    <div className="wrapper">
                        <SupportWidget/>
                        <div className="slider ">
                            <p>{t('general.button.contact/support')}</p>
                        </div>
                    </div>
                    {![AGENCY, AGENCY_MASTER].includes(userType as UserRoles) &&
                        <div className="wrapper tutorial">
                            <SupportTutorialIcon action={() => setIsSupportOpened(true)}/>
                            <div className="slider ">
                                <p>{t('general.button.help/tutorials')}</p>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {/* INFLUENCER AND AGENCY MENU MOBILE DEVICES*/}
            {(userType === INFLUENCER || userType === AGENCY) &&
                <MobileMenu tabs={tabs} classes={'d-lg-none shadow bg-white'} handleChange={handleChange} tabKey={key}/>
            }
        </>
    );
};

export default LeftSideMenu;
