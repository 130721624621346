import FullCalendar from '@fullcalendar/react';
import * as _ from 'lodash'
import React, {SetStateAction, useCallback, useEffect, useRef, useState} from 'react';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BiRefresh} from 'react-icons/bi';
import {BsExclamationOctagon} from 'react-icons/bs';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {getCorrectColor, getCorrectFilterTitle, getUniqueListBy} from '../../../../shared/functions/Functions';
import usePagination from '../../../../shared/hooks/usePagination';
import {useSearchQuery} from '../../../../shared/hooks/useSearchQuery';
import {plannerAgency} from '../../../../store/agency/plannerSlice';
import {IAllStates} from '../../../../store/rootReducer';
import {ErrorToast} from '../../../../utils/toasters';
import {CustomCalendarStyle} from '../../../brand/campaign/manage/calendar/components/CustomCalendarStyle';
import {iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {IPlannerPlatforms} from '../../../influencer/campaign/dto/IMyCampaigns';
import {ISelectedYearMonth} from '../../../influencer/planner';
import {INewEvent} from '../../../influencer/planner/components/CreateEventModal';
import {CustomCalendarJSX, IDateClick} from '../../../influencer/planner/components/CustomCalendarJSX';
import PlannerCalendarFilters from '../../../influencer/planner/components/PlannerCalendarFilters';
import {AgencyPlannerService} from '../service';
import AgencyCalendarFilters from './AgencyCalendarFilters';
import AgencyListView from './AgencyListView';
import AgencyMobileCalendar from './AgencyMobileCalendar';
import CreateEventModal from './CreateEventModal';
import PreviewEventModal from './PreviewEventModal';
import ProposalModal from './ProposalModal';

interface IProps {
    selectedCalendarView: 'dayGridMonth' | 'dayGridWeek' | 'listWeek';
    setSelectedYearMonth: React.Dispatch<React.SetStateAction<ISelectedYearMonth>>;
    setSelectedCalendarView: React.Dispatch<SetStateAction<'dayGridMonth' | 'dayGridWeek' | 'listWeek'>>;
    selectedYearMonth: ISelectedYearMonth;
    isToDoSelected: boolean;
    setIsToDoSelected: React.Dispatch<SetStateAction<boolean>>;
    isCreateModalOpened: boolean;
    setIsCreateModalOpened: React.Dispatch<SetStateAction<boolean>>;
}

const AgencyCalendarWrapper: React.FC<IProps> = ({
                                                     setSelectedCalendarView,
                                                     selectedCalendarView,
                                                     selectedYearMonth,
                                                     setSelectedYearMonth,
                                                     isToDoSelected,
                                                     setIsToDoSelected,
                                                     isCreateModalOpened,
                                                     setIsCreateModalOpened
                                                 }) => {
    const history = useHistory();
    const queryEventId = useSearchQuery('eventId');
    const {selectedInfluencer} = useSelector((state: IAllStates) => state.agency);
    const {t} = useTranslation();
    const calendarRef = useRef<FullCalendar>(null);
    const {
        events, filterParams,
        previewEventModal: {isOpenPreviewEventModal, eventId}
    } = useSelector((state: IAllStates) => state.plannerAgency);
    const [contentCreators, setContentCreators] = useState([])
    const [selectedEvent, setSelectedEvent] = useState<iManageList>();
    const {
        filterParams: filterData,
        handlePages,
        totalPages,
        setTotalPages,
        setFilterParams
    } = usePagination({perPage: 4});
    const [plannerPlatforms, setPlannerPlatforms] = useState<IPlannerPlatforms[]>([]);
    const [isProposalOpened, setIsProposalOpened] = useState(false);
    const [isEditModalOpened, setIsEditModalOpened] = useState(false);
    const [hasUnscheduled, setHasUnscheduled] = useState(false);
    const {offerItemEventsEnum} = useSelector((state: IAllStates) => state.enums);
    const [updatePlanner, setUpdatePlanner] = useState(false);
    const [createEvent, setCreateEvent] = useState<INewEvent>({
        postingDate: new Date().toISOString(),
        caption: null,
        offerItemId: 0,
    });
    const [selectedMonth, setSelectedMonth] = useState(new Date().getTime());
    const [count, setCount] = useState(4);

    const dispatch = useDispatch();

    const handleEventClick = async (event: any) => {
        const eventData = event?.event?._def;
        if (eventData?.extendedProps?.status === 'proposed') {
            setIsProposalOpened(true);
        } else {
            setIsEditModalOpened(true);
        }
        getPlannerDataRequest(eventData?.publicId);
    };

    const getPlannerDataRequest = async (idEvent?: number) => {
        const data = await AgencyPlannerService.getPlannerModalData(String(idEvent));
        dispatch(plannerAgency.actions.setFilterParams({
            updateCount: (filterParams?.updateCount ?? 0) + 1
        }));
        setSelectedEvent({
            ...data,
            caption: data?.caption?.includes('Sponsored -') ? data?.caption ?? '' : `Sponsored - ${data?.caption ?? ''}`
        });
    };
    const handleChangeMonth = (month: string, year: string) => {
        setSelectedYearMonth(prev => ({...prev, currentMonth: month, currentYear: year}));
    };
    const handleDateClick = (event: IDateClick) => {
        // setIsCreateModalOpened(true);
        setCreateEvent(prev => ({...prev, postingDate: event?.dateStr as string}));
    };
    const handleCloseModal = () => {
        setIsCreateModalOpened(false);
        setUpdatePlanner(prevState => !prevState);
        setCreateEvent({
            postingDate: new Date().toISOString(),
            caption: null,
            contentCreatorId: 0,
            offerItemId: 0,
        });
    };

    useEffect(() => {
        if (!!queryEventId) {
            setIsEditModalOpened(true);
            history.push('/agency/planner')
            getPlannerDataRequest(Number(queryEventId));
        }
    }, [queryEventId])

    useEffect(() => {
        AgencyPlannerService.getPlannerPlatforms({
            ...filterData,
            ...filterParams,
            page: filterData?.page,
            perPage: filterData.perPage,
            onlyTodo: isToDoSelected,
            influencerId: selectedInfluencer?.id,
            campaignId: filterParams?.campaignId,
        }).then(response => {
            setCount(response?.count);
            setPlannerPlatforms(response.data);
            setHasUnscheduled(response?.hasUnscheduled);
            setTotalPages(Math.ceil(response.count / response.perPage));
        }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, [filterParams, filterData?.page, filterData?.perPage, selectedInfluencer?.id, isToDoSelected, updatePlanner]);

    // useEffect(() => {
    //     !!filterParams.campaignId && AgencyPlannerService.getPlannerPlatforms({
    //         ...filterData,
    //         ...filterParams,
    //         page: filterData?.page,
    //         perPage: filterData.perPage,
    //         onlyTodo: isToDoSelected,
    //         influencerId: selectedInfluencer?.id,
    //     }).then(response => {
    //         setCount(response?.count);
    //         setPlannerPlatforms(response.data);
    //         setHasUnscheduled(response?.hasUnscheduled);
    //         setTotalPages(Math.ceil(response.count / response.perPage));
    //     }).catch(error => ErrorToast(error));
    //     // eslint-disable-next-line
    // }, [filterParams, selectedInfluencer?.id, updatePlanner]);


    useEffect(() => {
        AgencyPlannerService.getPlannerPlatforms({
            page: 1,
            perPage: 0
        }).then(response => {
            setContentCreators(response?.data?.map((data: IPlannerPlatforms) => data.acceptedUser))
        }).catch(error => ErrorToast(error));
    }, []);

    useEffect(() => {
        if (isOpenPreviewEventModal) {
            dispatch(plannerAgency.actions.setOpenPreviewModal({
                    eventId: undefined,
                    isOpenPreviewEventModal: false,
                },
            ));
            setIsEditModalOpened(true);
            getPlannerDataRequest(eventId);
        }
        // eslint-disable-next-line
    }, [isOpenPreviewEventModal]);
    const CustomCalendar = useCallback(
        () => (<CustomCalendarJSX handleDateClick={handleDateClick} events={events}
                                  handleChangeMonth={handleChangeMonth}
                                  selectedMonth={selectedMonth}
                                  handleEventClick={handleEventClick}
                                  setSelectedMonth={setSelectedMonth} calendarRef={calendarRef}
                                  selectedCalendarView={selectedCalendarView}/>),
        // eslint-disable-next-line
        [selectedCalendarView, selectedMonth, events],
    );

    const closeProposalModal = () => {
        setIsProposalOpened(false);
        setUpdatePlanner(prevState => !prevState);
    };

    const closePreviewEventModal = () => {
        setIsEditModalOpened(false);
        setUpdatePlanner(prevState => !prevState);
    };
    return (
        <>
            <PreviewEventModal
                state={selectedEvent as iManageList} isModalOpened={isEditModalOpened}
                handleCloseModal={closePreviewEventModal}
                selectedYearMonth={selectedYearMonth}
                setState={setSelectedEvent as React.Dispatch<SetStateAction<iManageList>>}/>
            <CreateEventModal createEvent={createEvent}
                              setSelectedEvent={setSelectedEvent as React.Dispatch<SetStateAction<iManageList>>}
                              setCreateEvent={setCreateEvent}
                              contentCreators={contentCreators}
                              setIsEditModalOpened={setIsEditModalOpened}
                              plannerPlatforms={plannerPlatforms}
                              handleCloseModal={handleCloseModal}
                              isCreateModalOpened={isCreateModalOpened}/>
            <ProposalModal setState={setSelectedEvent as React.Dispatch<SetStateAction<iManageList>>}
                           state={selectedEvent as iManageList} isModalOpened={isProposalOpened}
                           handleCloseModal={closeProposalModal}/>
            <Card>
                <Card.Body>
                    <AgencyCalendarFilters isToDoSelected={isToDoSelected}
                                           setIsToDoSelected={setIsToDoSelected}
                                           selectedCalendarView={selectedCalendarView}
                                           setIsCreateModalOpened={setIsCreateModalOpened}
                                           hasUnscheduled={hasUnscheduled}
                                           campaigns={getUniqueListBy(_.flatten(plannerPlatforms?.map(platform => platform.campaigns)), item => item.id)}
                    />
                    <div className={'position-relative'}>
                        <PlannerCalendarFilters selectedCalendarView={selectedCalendarView}
                                                setSelectedCalendarView={setSelectedCalendarView}/>
                        <CustomCalendarStyle className="mt-3 d-none d-md-block">
                            <div id="influencer-calendar"
                                 className={`${selectedCalendarView === 'dayGridWeek' ? 'mixed-calendar' : ''}`}>
                                {(selectedCalendarView === 'dayGridMonth' || selectedCalendarView === 'dayGridWeek') &&
                                    <CustomCalendar/>
                                }
                            </div>
                        </CustomCalendarStyle>
                        <div className="d-md-none">
                            <AgencyMobileCalendar
                                plannerPlatforms={plannerPlatforms}
                                handlePages={handlePages}
                                totalPages={totalPages}
                                filterData={filterData}
                                setIsEditModalOpened={setIsEditModalOpened}
                                setIsProposalOpened={setIsProposalOpened}
                                setSelectedEvent={setSelectedEvent}/>
                        </div>
                    </div>
                    {(selectedCalendarView === 'listWeek' || selectedCalendarView === 'dayGridWeek') &&
                        <div className="d-none d-md-block">
                            <AgencyListView
                                filterData={filterData}
                                totalPages={totalPages} handlePages={handlePages} plannerPlatforms={plannerPlatforms}
                                setSelectedEvent={setSelectedEvent as React.Dispatch<SetStateAction<iManageList | undefined>>}
                                setIsEditModalOpened={setIsEditModalOpened}
                                isToDoSelected={isToDoSelected}
                                setCreateEvent={setCreateEvent}
                                setIsProposalOpened={setIsProposalOpened}
                                setIsCreateModalOpened={setIsCreateModalOpened}
                                setFilterParams={setFilterParams}
                                count={count}
                            />
                        </div>
                    }
                </Card.Body>
            </Card>
            <div className="d-flex justify-content-md-end flex-wrap my-3">
                {offerItemEventsEnum?.map(offerItem => {
                    const statusesWithIcon = ['proposed'];
                    return (
                        <React.Fragment key={offerItem}>
                            {statusesWithIcon?.includes(offerItem) ?
                                <div className="m-1 d-flex align-items-center">
                                    {offerItem === 'proposed' ? <BsExclamationOctagon className="proposed-icon"/> :
                                        <BiRefresh className="proposed-icon text-dark"/>}
                                    <span className="ml-1">
                                        {getCorrectFilterTitle(offerItem, t)}
                                    </span>
                                </div>
                                : <span key={offerItem}
                                        className={`calendar-button-dot m-1 ${getCorrectColor(offerItem)}`}>
                                      {getCorrectFilterTitle(offerItem, t)}</span>
                            }
                        </React.Fragment>
                    );
                })}
            </div>
        </>
    );
};

export default AgencyCalendarWrapper;
