import React, {useEffect, useState} from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {AiOutlineLeft, AiOutlineRight} from 'react-icons/ai';
import {useLocation} from 'react-router-dom';
import {removeNumFromString} from '../../../functions/Functions';
import {supportTutorials} from '../../../support-tutorials';


interface Props {
    show: boolean;
    closeModal: () => void;
}


export interface ISupportVideos {
    route: string;
    title?: string;
    video: string;
    translation: {
        desc: string, title: string;
    }
}

const SupportModal = ({show, closeModal}: Props) => {
    const {t} = useTranslation();
    const {pathname} = useLocation();
    const [videos, setVideos] = useState<ISupportVideos[]>([]);
    const numOfSlides = videos?.length;
    const [activeSlide, setActiveSlide] = useState(1);

    const handleSwitch = (isNext: boolean) => {
        if (isNext) {
            setActiveSlide(prev => prev < numOfSlides ? prev + 1 : numOfSlides);
        } else {
            setActiveSlide(prev => prev !== 1 ? prev - 1 : 1);
        }
    }

    const handleDotClick = (clicked: number) => {
        setActiveSlide(clicked)
    }


    useEffect(() => {
        if (pathname) {
            setActiveSlide(1);
            setVideos(supportTutorials.filter(video => video.route.includes(removeNumFromString(pathname))));
        }
    }, [pathname])
    return (
        <Modal show={show}
               onHide={closeModal}
               size="lg"
               centered
        >
            <Modal.Body>
                {!!videos?.length ?
                    <Row>
                        <Col xs={12}>
                            <h3 className="text-center">influence.vision Tutorials</h3>
                        </Col>
                        <Col xs={12}>
                            <div
                                style={{position: 'relative', paddingBottom: '62.5%', height: 0}}
                            >
                                <iframe src={videos[activeSlide - 1]?.video}
                                        frameBorder="0"
                                        title={videos[activeSlide - 1]?.video ?? ''}
                                        allowFullScreen
                                        style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                                />
                            </div>
                        </Col>
                        <Col xs={12} className="d-flex align-items-center justify-content-between mt-2">
                            <div className="d-flex align-items-center cursor-pointer"
                                 onClick={() => handleSwitch(false)}>
                                <button className="tutorial-button" disabled={activeSlide === 1}>
                                    <AiOutlineLeft className="font-12 text-primary"/>
                                </button>
                                <span className="ml-2">PREVIOUS</span>
                            </div>
                            <div>
                                {[...Array(numOfSlides)].map((e, i) => (
                                    <span className={`tutorial-dot ${activeSlide === i + 1 ? 'active' : ''}`}
                                          onClick={() => handleDotClick(i + 1)}
                                          key={i}/>
                                ))}
                            </div>
                            <div className="d-flex align-items-center cursor-pointer"
                                 onClick={() => handleSwitch(true)}>
                                <span className="mr-2">NEXT</span>
                                <button className="tutorial-button" disabled={activeSlide === numOfSlides}>
                                    <AiOutlineRight className="font-12 text-primary"/>
                                </button>

                            </div>
                        </Col>
                        <Col xs={12} className="mt-2">
                            <h5>{t(`translation.${videos[activeSlide - 1]?.translation?.title}`)}</h5>
                            <p className="text-muted">
                                {t(`translation.${videos[activeSlide - 1]?.translation?.desc}`)}
                            </p>
                        </Col>
                    </Row> : 'No videos for this screen'}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={closeModal}>
                    {t('general.button.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SupportModal;
