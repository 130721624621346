import React from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {StarProIcon} from '../../../../shared/Icons';
import {InternalRoutes} from '../../../../enum/InternalRoutes';

interface IButtonUpgradeProProps {
    variant?: string;
    size?: 'sm' | 'lg';
    customText?: string;
    isOnlyUpgrade?: boolean;
    className?: string;
}

export function ButtonUpgradePro({
                                     customText,
                                     variant = 'primary',
                                     size = 'lg',
                                     isOnlyUpgrade,
                                     className = ''
                                 }: IButtonUpgradeProProps) {
    const {t} = useTranslation();
    const history = useHistory();
    return (
        <>
            <Button size={size} variant={variant} className={className}
                    onClick={() => history.push(InternalRoutes.BRAND_PACKAGES)}>
                {isOnlyUpgrade ? null :
                    <StarProIcon classes="mr-2" width="30" height="15"/>
                }
                {customText ? <>
                        {t(`general.button.${customText}`)}
                    </> :
                    <>
                        {isOnlyUpgrade ?
                            t('general.button.upgrade') : t('general.button.upgrade_now')
                        }
                    </>}
            </Button>
        </>
    );
}
