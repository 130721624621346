import styled from '@emotion/styled';

export const CustomCalendarStyle = styled.div`
    .fc-h-event {
        background-color: transparent;
        border-color: transparent;
    }

    .fc-day-disabled {
        background: var(--fc-neutral-bg-color, rgba(208, 208, 208, 0.1));
    }

    .fc-toolbar-title {
        font-size: 0.75rem;
    }

    .fc .fc-scroller-liquid-absolute {
        overflow: hidden !important;
    }

    .fc-more-link {
        font-size: 12px;
    }

    .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
        position: absolute;
        top: 5px;
        right: 10rem;
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 0;
    }

    .fc .fc-daygrid-day-frame {
        height: 120px;
    }

    #influencer-calendar .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
        top: 10px;
        right: 84%;
        width: 100%;
    }

    #influencer-calendar .fc-event-main:hover {
        background: #eeeeee;
        border-radius: .25rem;
    }

    #influencer-calendar.mixed-calendar .fc.fc-media-screen {
        max-height: 300px;
        overflow-y: auto;
    }

    .fc-toolbar-chunk {
        margin-left: 0.5rem;
    }

    .fc .fc-daygrid-day-frame {
        border: 1px solid #eeeeee;
    }

    .fc .fc-daygrid-day.fc-day-today {
        background-color: #05bbc952;
    }

    .fc-scroller-harness {
        padding: 0.5rem 0;
        border-top: 1px solid #eeeeee;
        border-left: 1px solid #eeeeee;
        border-right: 1px solid #eeeeee;
    }

    .fc-daygrid-day {
        cursor: pointer;
        height: 110px;
    }

    .fc-scrollgrid-section-header div {
        overflow: hidden !important;
    }

    .btn-group button:not(:first-of-type) {
        margin-left: 1rem;
    }

    .fc-daygrid-day-number {
        font-weight: 600;
        color: #2e3033;
        font-size: 14px;
    }

    .btn {
        box-shadow: 1px 1px 4px #0000001a;
        border-radius: 8px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-color: #80808014;
        color: #2e3033 !important;
    }

    .btn.active,
    .btn-primary:active {
        background: #ffffff 0% 0% no-repeat padding-box !important;
        border-color: #80808014 !important;
        color: #05bbc9 !important;
        font-weight: 600 !important;
    }

    .btn:hover {
        background: #ffffff 0% 0% no-repeat padding-box;
        color: #05bbc9 !important;
    }

    .fc-event-main p {
        color: #2e3033;
    }

    a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-future:hover {
        background: rgba(0, 0, 0, 0.1);
    }

    @media (max-width: 768px) {
        .fc-view-harness-active {
            min-height: 720px;
        }
    }
    @media (max-width: 1200px) {
        .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
            position: static;
        }

        .fc-toolbar-title {
            margin-top: 0.5rem;
        }

        .fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
            flex-direction: column;
            top: 0;
            margin-bottom: 0.5rem;
        }
    }
`;
