import React, {SetStateAction} from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {User} from '../../../../model/user/User';
import ReportingBody from '../../../influencer/reporting/components/modals/components/ReportingBody';
import ReportingScreenshots from '../../../influencer/reporting/components/modals/components/ReportingScreenshots';
import {IReportingUpdate, ISelectedEvent} from '../../../influencer/reporting/components/ReportingCampaignCard';
import {ITempData} from './AddReportingView';
import LeftReportingCard from './LeftReportingCard';
import ReportingHeader from './ReportingHeader';
import YourContent from './YourContent';

interface Props {
    isAdditional: boolean;
    isModalOpened: boolean;
    handleCloseModal: () => void;
    handleSubmitData: () => void;
    createPosting: IReportingUpdate;
    setCreatePosting: React.Dispatch<SetStateAction<IReportingUpdate>>;
    tempData: ITempData;
}

const AddReportingStep2Modal = ({
                                    isAdditional,
                                    isModalOpened,
                                    handleSubmitData,
                                    handleCloseModal,
                                    createPosting,
                                    setCreatePosting,
                                    tempData,
                                }: Props) => {
    const {t} = useTranslation();
    const selectedOfferItem = {
        ...tempData,
        offerItem: isAdditional ? (tempData as any)?.offerItem : tempData?.offers?.[0]?.offerItems?.find(offerItem => offerItem?.id === createPosting?.offerItemId),
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, valueAsNumber} = event.target;
        setCreatePosting(prev => ({...prev, [name]: valueAsNumber}));
    };
    return (
        <>
            <Modal
                show={isModalOpened}
                centered
                size="xl"
                onHide={handleCloseModal}
                keyboard={false}
            >
                <Modal.Header className={'py-0 row'}>
                    <ReportingHeader
                        selectedOfferItem={selectedOfferItem as ISelectedEvent}/>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <LeftReportingCard selectedOfferItem={selectedOfferItem as ISelectedEvent}
                                           user={selectedOfferItem?.offers?.[0]?.user as User}
                                           postingDate={createPosting?.postingDate as string}

                        />
                        <Col md={8}>
                            <YourContent setUpdateData={setCreatePosting}
                                         externalLinks={createPosting?.externalLinks ?? []}
                                         isAdditional setSelectedOfferItem={() => {
                            }} selectedAttachments={[]} isUploadPossible/>
                            <ReportingBody isAgency
                                           socialPlatform={isAdditional ? selectedOfferItem?.offerItem?.socialPlatform : selectedOfferItem?.campaign?.offers?.[0]?.offerItems
                                               ?.find(offer => offer.id === createPosting?.offerItemId)?.socialPlatform}
                                           updateData={createPosting} handleChange={handleChange}/>
                            <ReportingScreenshots
                                status=""
                                isAgency
                                setUpdateData={setCreatePosting}
                                // fileKey="file"
                                reportItems={[]}/>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleCloseModal}>
                        {t('general.button.cancel')}
                    </Button>

                    <Button variant="primary" onClick={handleSubmitData}>
                        {t(`general.button.submit`)}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddReportingStep2Modal;
