import React from 'react';
import {IoStar} from 'react-icons/io5';

interface IRatingStar {
    rating: number;
}

export const RatingStar = ({rating}: IRatingStar) => {
    return (
        <span className="rating">
                    {rating ?? 0}
            <IoStar className="text-primary ml-1"/>
        </span>
    );
}