import {combineReducers} from 'redux';
import {IEmployeeCompany} from '../authentication/register/employee/dto/IEmployeeRegister';
import {IOfferAgency, offerAgency} from './agency/agencyOfferSlice';
import {agencySlice, IAgencySlice} from './agency/agencySlice';
import {plannerAgency} from './agency/plannerSlice';
import * as auth from './authentication/authRedux';
import {IAuthState} from './authentication/authRedux';
import {ISelectedCampaign, selectedCampaignSlice} from './brand/activeCampaignSlice';
import {IManageBrandStore, manageBrand} from './brand/manageBrandSlice';
import {IBrandOfferStore, offerBrand} from './brand/offerBrandSlice';
import {IPackagesSlice, packagesSlice} from './brand/packagesSlice';
import enumsSlice, {IEnums} from './enums/enumsRedux';
import {appVersionSlice, IAppVersion} from './general/appVersionSlice';
import {ILoadingSlice, loadingSlice} from './general/loadingSlice';
import {IMessageSlice, messageSlice} from './general/messageSlice';
import {IOfferInfluencer, offerInfluencer} from './influencer/offerInfluencerSlice';
import {IPlannerStore, plannerInfluencer} from './influencer/plannerSlice';
import {INotificationRedDots, redDotsSlice} from './influencer/redDotsSlice';
import registrationSlice, {IRegister} from './register/brand/registrationSlice';
import employeeSlice from './register/employee/employeeSlice';
import influencerRegistrationSlice, {
    IRegistrationInfluencerState,
} from './register/influencer/influencerRegistrationSlice';

export interface IAllStates {
    auth: IAuthState;
    registration: IRegister;
    redDots: { redDots: INotificationRedDots };
    enums: IEnums;
    employee: IEmployeeCompany;
    influencerRegistration: IRegistrationInfluencerState;
    offerInfluencer: IOfferInfluencer;
    plannerInfluencer: IPlannerStore;
    plannerAgency: IPlannerStore;
    offerBrand: IBrandOfferStore;
    offerAgency: IOfferAgency;
    manageBrand: IManageBrandStore;
    loading: ILoadingSlice;
    appVersion: IAppVersion;
    selectedCampaign: ISelectedCampaign;
    packages: IPackagesSlice;
    agency: IAgencySlice;
    message: IMessageSlice;
}

export const rootReducer = combineReducers({
    auth: auth.reducer,
    enums: enumsSlice.reducer,
    registration: registrationSlice.reducer,
    employee: employeeSlice.reducer,
    influencerRegistration: influencerRegistrationSlice.reducer,
    offerInfluencer: offerInfluencer.reducer,
    plannerInfluencer: plannerInfluencer.reducer,
    plannerAgency: plannerAgency.reducer,
    manageBrand: manageBrand.reducer,
    message: messageSlice.reducer,
    loading: loadingSlice.reducer,
    offerBrand: offerBrand.reducer,
    appVersion: appVersionSlice.reducer,
    selectedCampaign: selectedCampaignSlice.reducer,
    packages: packagesSlice.reducer,
    agency: agencySlice.reducer,
    offerAgency: offerAgency.reducer,
    redDots: redDotsSlice.reducer,
});
