import {MenuItem, TextField} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {InternalRoutes} from '../../../../enum/InternalRoutes';
import ToggleSwitch from '../../../../shared/components/ToggleSwitch';
import {useSearchQuery} from '../../../../shared/hooks/useSearchQuery';
import {setRegisterUser} from '../../../../store/register/influencer/influencerRegistrationSlice';
import {IAllStates} from '../../../../store/rootReducer';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {AuthService} from '../../../service';
import {StepperButtons} from '../../brand/components/StepperButtons';
import {IInfluencerBillingRequest} from '../dto/IInfluencerBillingRequest';
import {IRegisterStepperProps} from '../dto/IRegisterStepperProps';
import {validateVAT} from '../../../../shared/functions/Functions';
import {UserRoles} from "../../../../enum/UserRoles";
import {Contact} from '../../../../model/shared/Contact';
import {Bank} from '../../../../model/shared/Bank';

export function InfluencerStep6({handleBackStep, stepIdNumber}: IRegisterStepperProps) {
    const influencerData = useSelector((state: IAllStates) => state.influencerRegistration.user);
    const [billingInfo, setBillingInfo] = useState<IInfluencerBillingRequest>({
        contact: influencerData?.contact as Contact,
        bank: influencerData?.userBankAccount?.bank as Bank,
        verificationUUID: influencerData?.verificationUUID ?? 0,
    });
    const [vatError, setVatError] = useState(false);
    const {country} = useSelector((states: IAllStates) => states.enums);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const history = useHistory();
    const token = useSearchQuery('token') as string;

    useEffect(() => {
        AuthService.getInfluencerData(token).then(response => {
            dispatch(setRegisterUser(response));
            setBillingInfo(prev => (
                {
                    ...prev, bank: {
                        ...response?.userBankAccount?.bank,
                        accountPhone: response?.userBankAccount?.bank?.accountPhone ?? response?.contact?.phone ?? '',
                        accountCountry: response?.userBankAccount?.bank?.accountCountry ?? ''
                    },
                    contact: {
                        ...response?.contact,
                        invoiceFirstName: response?.contact?.invoiceFirstName ?? response?.firstName,
                        invoiceLastName: response?.contact?.invoiceLastName ?? response?.lastName,
                        email: response?.contact?.email ?? response?.email,
                    }
                }));
        }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, []);

    const handleNextStep = () => {
        AuthService.billingInfoInfluencer(billingInfo).then(response => {
            history.push(`${InternalRoutes.INFLUENCER_REGISTRATION}/${stepIdNumber + 1}?token=${token}`);
            SuccessToast(t('auth.register.brand.step5.success'));
        }).catch(error => ErrorToast(error));
    };
    const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, isBank = false) => {
        const {name, value} = event.target;
        setBillingInfo(prev => (
            isBank ? {
                ...prev, bank: {
                    ...prev.bank,
                    [name]: value,
                },
            } : {
                ...prev, contact: {
                    ...prev.contact,
                    [name]: value,
                },
            }));
        setVatError(false);
    };
    const changePhoneHandler = (value: string) => {
        setBillingInfo(prev => ({
            ...prev, bank: {
                ...prev?.bank,
                accountPhone: value,
            },
        }));
        setVatError(false);
    };

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if ((!billingInfo.contact.smallBusiness) &&
            !validateVAT(billingInfo.contact.uidNumber as unknown as string)) {
            setVatError(true);
            return;
        }
        handleNextStep();
    }

    return (
        <>
            <Form onSubmit={(e) => onSubmit(e)}>
                <Row className="text-left">
                    <Col xs={12}>
                        <h5 className="mt-3">{t('auth.register.influencer.step6.receiver')}</h5>
                    </Col>
                    <Col md={6}>
                        <TextField
                            label={t('auth.register.influencer.step6.firstName')}
                            variant="outlined"
                            name="invoiceFirstName"
                            value={billingInfo?.contact?.invoiceFirstName ?? ''}
                            onChange={changeHandler}
                            size="small"
                            required
                            className="w-100 my-2"
                        />
                    </Col>
                    <Col md={6}>
                        <TextField
                            label={t('auth.register.influencer.step6.lastName')}
                            variant="outlined"
                            size="small"
                            value={billingInfo?.contact?.invoiceLastName ?? ''}
                            onChange={changeHandler}
                            name="invoiceLastName"
                            required
                            className="w-100 my-2"
                        />
                    </Col>
                    <Col md={12}>
                        <TextField
                            label={t('auth.register.influencer.step6.company_name')}
                            variant="outlined"
                            size="small"
                            value={billingInfo?.contact?.companyName ?? ''}
                            onChange={changeHandler}
                            name="companyName"
                            required
                            className="w-100 my-2"
                        />
                    </Col>
                    <Col md={6}>
                        <PhoneInput
                            country="de"
                            placeholder={t('auth.register.brand.step2.phoneNumber')}
                            value={billingInfo?.bank?.accountPhone ?? ''}
                            onChange={changePhoneHandler}
                            inputClass="w-100 my-2"
                        />
                    </Col>
                    <Col md={6}>
                        <TextField
                            label="E-mail"
                            variant="outlined"
                            value={billingInfo?.contact?.email ?? ''}
                            onChange={changeHandler}
                            size="small"
                            name="email"
                            required
                            type="email"
                            className="w-100 my-2"
                        />
                    </Col>
                    <Col xs={12}>
                        <h5 className="mt-3">{t('auth.register.influencer.step6.address')}</h5>
                    </Col>
                    <Col md={12}>
                        <TextField
                            label={t('auth.register.influencer.step6.street')}
                            name="street"
                            variant="outlined"
                            required
                            size="small"
                            value={billingInfo?.contact?.street ?? ''}
                            onChange={changeHandler}
                            className="w-100 my-2"
                        />
                    </Col>
                    <Col md={6}>
                        <TextField
                            label={t('auth.register.influencer.step6.city')}
                            name="city"
                            value={billingInfo?.contact?.city ?? ''}
                            onChange={changeHandler}
                            variant="outlined"
                            required
                            size="small"
                            className="w-100 my-2"
                        />
                    </Col>
                    <Col md={6}>
                        <TextField
                            label={t('auth.register.influencer.step6.postalCode')}
                            variant="outlined"
                            name="zip"
                            value={billingInfo?.contact?.zip ?? ''}
                            onChange={changeHandler}
                            size="small"
                            required
                            className="w-100 my-2"
                        />
                    </Col>
                    <Col md={6}>
                        <TextField
                            label={t('auth.register.influencer.step6.state')}
                            value={billingInfo?.contact?.province ?? ''}
                            onChange={changeHandler}
                            name="province"
                            variant="outlined"
                            required
                            size="small"
                            className="w-100 my-2"
                        />
                    </Col>
                    <Col className="mt-2" md={6}>
                        <TextField
                            value={billingInfo?.bank?.accountCountry}
                            onChange={e => (changeHandler(e, true))}
                            size="small"
                            name="accountCountry"
                            required
                            label={t('general.address.country')}
                            select
                            variant="outlined"
                            fullWidth
                        >
                            {country.map((countryName) => (
                                <MenuItem key={countryName}
                                          value={countryName}>{t(`general.countries.${countryName}`)}</MenuItem>
                            ))
                            }
                        </TextField>
                    </Col>
                    <Col xs={12}>
                        <h5 className="mt-3">{t('auth.register.influencer.step6.payment_info')}</h5>
                    </Col>
                    <Col md={6}>
                        <TextField
                            label={t('auth.register.influencer.step6.bank_name')}
                            onChange={(event) => changeHandler(event, true)}
                            name="accountBankName"
                            value={billingInfo?.bank?.accountBankName ?? ''}
                            variant="outlined"
                            required
                            size="small"
                            className="w-100 my-2"
                        />
                    </Col>
                    <Col md={6}>
                        <TextField
                            label={t('auth.register.influencer.step6.IBAN')}
                            onChange={(event) => changeHandler(event, true)}
                            name="accountIban"
                            value={billingInfo?.bank?.accountIban ?? ''}
                            variant="outlined"
                            required
                            size="small"
                            className="w-100 my-2"
                        />
                    </Col>
                    <Col md={6}>
                        <TextField
                            label={t('auth.register.influencer.step6.SWIFT')}
                            onChange={(event) => changeHandler(event, true)}
                            name="accountSwift"
                            value={billingInfo?.bank?.accountSwift ?? ''}
                            variant="outlined"
                            size="small"
                            className="w-100 my-2"
                        />
                    </Col>
                    <Col md={6}>
                        <TextField
                            label={t('auth.register.influencer.step6.VAT')}
                            onChange={changeHandler}
                            value={billingInfo?.contact?.uidNumber}
                            name="uidNumber"
                            variant="outlined"
                            size="small"
                            className="w-100 my-2"
                            required={!billingInfo?.contact?.smallBusiness}
                            error={vatError}
                            helperText={vatError ? 'Incorrect input' : ''}
                        />
                    </Col>
                            <Col xs={12}>
                                <h5 className="mt-3">{t('auth.register.influencer.step6.value_text')}</h5>
                            </Col>
                            <Col md={5}>
                                <div className="d-flex justify-content-between align-items-center mt-2">
                                    <label className="mb-0" htmlFor="smallBusiness">
                                        {t('auth.register.influencer.step6.smallBusinessOwner')}
                                    </label>
                                    <ToggleSwitch onChange={() => setBillingInfo(prev => ({
                                        ...prev, contact: {
                                            ...prev.contact,
                                            smallBusiness: !prev.contact?.smallBusiness,
                                        },
                                    }))} small id="smallBusiness"
                                                  checked={!!billingInfo?.contact?.smallBusiness}/>
                                </div>
                                <span className="text-muted">
                                    {t('auth.register.brand.step4.smallBusinessOwnerDescription')}
                                </span>
                            </Col>
                    <div className="col-12 mt-4">
                        <StepperButtons currentStep={stepIdNumber - 1} handleNextStep={handleNextStep}
                                        handleBackStep={handleBackStep}/>
                    </div>
                </Row>
            </Form>
        </>
    );
}
