import React from 'react'
import { t } from 'i18next'
import { Button, Card, FormControl, Row } from 'react-bootstrap'
import { SuccessToast } from '../../../../../../utils/toasters';
import { ICampaignOverview } from '../../../../../../model/briefing/overview/ICampaignOverview';

interface ICampaignLinkCard {
    campaign: ICampaignOverview | undefined;
}

export default function CampaignLinkCard({campaign}: ICampaignLinkCard) {
    const copyCampaign = () => {
        SuccessToast(t('general.button.copied'));
        navigator.clipboard.writeText(`${window.location.origin}/public/campaign/briefing/${campaign?.uuid}`);
    };
  return (
    <Card>
        <Card.Body>
            <h5>{t('brand.campaign.briefing.public_briefing_title')}</h5>
            <Row>
                <FormControl className='col mr-3' disabled value={`${window.location.origin}/public/campaign/briefing/${campaign?.uuid}`}/>
                <Button onClick={copyCampaign}>{t('general.button.copy')}</Button>
            </Row>
        </Card.Body>
    </Card>
  )
}
