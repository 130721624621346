import {TextField} from '@material-ui/core';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {BiCopy} from 'react-icons/bi';
import {SuccessToast} from '../../../../../utils/toasters';
import {iManageList} from '../../../../brand/campaign/manage/calendar/dto/IManageCalendar';

interface IPreviewCopyProps {
    state: iManageList;
    changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const PreviewCopy = ({state, changeHandler}: IPreviewCopyProps) => {
    const {t} = useTranslation();    
    const statusToDisplayLink = ['approved', 'published'];
    const isDisabled = !['scheduled', 'feedback_loop'].includes(state?.status); // if not scheduled or feedback loop
    const isCaptionHidden = !['instagram_story'].includes(state?.offerItem?.socialPlatform?.name);
    const copyHandler = (link: string) => {
        navigator.clipboard.writeText(link ?? ' ');
        SuccessToast(t('general.button.copied'));
    };
// console.log(isDisabled, state.isStorylineLocked, state.isCaptionLocked, !(isDisabled || state?.isStorylineLocked));

    return (
        <>
            <div className="col-12 mt-3 mb-2">
                <span className="font-weight-semi-bold">Storyline</span>
            </div>
            <div className="col-12 mb-3 d-flex align-items-center">
                <TextField label="Storyline"
                        //    disabled={isDisabled || state?.isStorylineLocked}
                           variant="outlined"
                           multiline
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => !(isDisabled || state?.isStorylineLocked) && changeHandler(e)}
                           name="storyline"
                           InputProps={{
                                className: state?.isStorylineLocked ? '' : '',
                               endAdornment: (
                                   <BiCopy className="copy-icon mr-2 text-muted"
                                           onClick={() => copyHandler(state?.storyline ?? '')}/>
                               ),
                           }}
                           className={`w-100`}
                           size="small"
                           value={state?.storyline ?? ''}
                           focused={state?.isStorylineLocked}
                        />
            </div>
            {isCaptionHidden && <>
                <div className="col-12 mt-3 mb-2">
                    <span className="font-weight-semi-bold">{t('influencer.planner.caption')} -</span>
                    <span className="text-muted"> {t('influencer.planner.copyDescription')}</span>
                </div>
                <div className="col-12 mb-3 d-flex align-items-center">
                    <TextField label={t('influencer.planner.caption')}
                               variant="outlined"
                               multiline
                               onChange={(e: React.ChangeEvent<HTMLInputElement>) => !(isDisabled || state?.isCaptionLocked) && changeHandler(e)}
                               name="caption"
                            //    disabled={state?.isCaptionLocked}
                               InputProps={{
                                   endAdornment: (
                                       <BiCopy className="copy-icon mr-2 text-muted"
                                               onClick={() => copyHandler(state?.caption ?? '')}/>
                                   ),
                               }}
                               className="w-100"
                               size="small"
                               value={state?.caption ?? ''}
                               focused={state?.isCaptionLocked}/>
                </div>
            </>
            }

            {statusToDisplayLink.includes(state?.status) && <div className="col-12 mb-3 d-flex align-items-center">
                <TextField
                    name="postingLink"
                    label={t('influencer.planner.postLink')}
                    onChange={changeHandler}
                    variant="outlined"
                    value={state?.postingLink ?? ''}
                    size="small"
                    className="w-100"
                />
                <BiCopy className="font-22 text-muted cursor-pointer z-index-2" style={{marginLeft: '-2rem'}}
                        onClick={() => {
                            copyHandler(state?.postingLink);
                        }}/>
            </div>}
        </>
    );
};

export default PreviewCopy;
