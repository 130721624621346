import Lottie from 'lottie-react';
import React from 'react';
import loadingIcon from '../icons/loadingIcon.json';

const LoadingScreen = () => {
    return (
        <div className="loading-screen">
            <Lottie animationData={loadingIcon} loop={true} className="my-2"/>
        </div>
    );
};

export default LoadingScreen;
