import React from 'react';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

interface Props {
    name: string;
}

const ReportingAlert = ({name}: Props) => {
    const {t} = useTranslation();
    return (
        <Card className="border border-danger">
            <Card.Body>
                <div className="d-flex align-items-center">
                    <img src="/icons/tick-mark.svg" alt="attention-icon"/>
                    <div className="ml-2">
                        <h6 className="mb-0">{t('influencer.reporting.missingData')}</h6>
                        <span className="text-muted">
                            {t('influencer.reporting.missingDescription')}
                        </span>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default ReportingAlert;
