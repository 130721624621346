import React from 'react';
import {useTranslation} from 'react-i18next';

const ProfileHeader = () => {
    const {t} = useTranslation();
    return (
        <div className="d-flex align-items-md-center justify-content-between mb-4 mt-3 mt-md-0 flex-column flex-md-row">
            <div className="d-none d-md-flex flex-column w-75">
                <h5>
                    {t('influencer.profile.editPublicProfile')}
                </h5>
                <span className="text-muted">
                     {t('influencer.profile.editPublicProfileDesc')}
                </span>
            </div>
        </div>
    );
};

export default ProfileHeader;
