import moment from 'moment';
import React, {useState} from 'react';
import {Button} from 'react-bootstrap';
import {DateRangePicker, RangeKeyDict} from 'react-date-range';
import {useTranslation} from 'react-i18next';
import {formatDate} from '../functions/Functions';
import ClickOutsideDetector from './ClickOutsideDetector';

interface Props {
    value?: string | null;
    onChange: (newValue: Date | null) => void;
    label: string;
    disabled?: boolean;
    disablePast?: boolean;
    disableTo?: Date | string;
    disableFrom?: string | Date;
    labelClass?: string;
    isRequired?: boolean;
}

export const convertToBelgradeTime = (date: Date) => {
    return new Date(moment(date).add(5, 'hours').toISOString())
};
const CustomDatePicker = ({
                              value,
                              onChange,
                              label,
                              disabled = false,
                              disablePast = false,
                              disableTo,
                              disableFrom,
                              labelClass,
                              isRequired = false,
                          }: Props) => {
    const {t} = useTranslation();
    const [isDatepickerShow, setIsDatepickerShow] = useState(false);
    const defaultMaxDate = new Date(new Date().getFullYear() + 50, 11, 31); // Set a default maxDate value
    const defaultMinDate = new Date(new Date().getFullYear() - 50, 11, 31); // Set a default minDate value
    const disableFromRemoveHours = new Date(moment(disableFrom).add(12, 'hours').toISOString());
    const disableToRemoveHours = new Date(moment(disableTo).add(12, 'hours').toISOString());
    const closeDataPicker = () => {
        setIsDatepickerShow(false);
    };
    const changeDatePickerHandler = (event: RangeKeyDict) => {
        const {range1} = event;
        onChange(convertToBelgradeTime(range1.startDate as unknown as Date));
        closeDataPicker();
    };
    return (
        <ClickOutsideDetector onOutsideClick={closeDataPicker}>
            <div className="position-relative custom-datepicker">
                <div className="d-flex align-items-end">
                    <div className="d-flex flex-column w-100 position-relative">
                        <label
                            className="MuiFormLabel-root MuiInputLabel-formControl  MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-outlined MuiFormLabel-filled datepicker-label"
                            htmlFor={label}>{label}</label>
                        <input type="text" id="date" className="w-100 datepicker-field"
                               style={{minHeight: 37}} disabled={disabled}
                               required={isRequired}
                               onClick={() => setIsDatepickerShow(prev => !prev)}
                               value={`${formatDate(value ?? new Date().toISOString())}`}/>
                    </div>
                    {isDatepickerShow && <Button
                        onClick={closeDataPicker}
                        variant="outline-primary"
                        className="ml-2">{t('general.button.cancel')}</Button>}
                </div>
                {(isDatepickerShow) &&
                    <DateRangePicker
                        onChange={changeDatePickerHandler}
                        weekStartsOn={0}
                        months={1}
                        ranges={[{
                            startDate: new Date(value ?? new Date().toISOString()),
                            endDate: new Date(value ?? new Date().toISOString()),
                        }]}
                        rangeColors={['var(--primary)']}
                        minDate={disableFrom ? new Date(disableFrom) ? disableFromRemoveHours : disablePast ? new Date() : new Date(defaultMinDate) : undefined}
                        maxDate={disableTo ? disableToRemoveHours : defaultMaxDate}
                        className="position-absolute z-index-2 default-radius left-0"
                        direction="horizontal"
                    />}
            </div>
        </ClickOutsideDetector>
    );
};

export default CustomDatePicker;
