import React from 'react';
import {EnvironmentType} from '../../../../enum/EnvironmentType';
import ProfileCard from './ProfileCard';
import {shallowEqual, useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import {useTranslation} from 'react-i18next';
import {BackButton} from '../../../../shared/components/BackButton';

export interface IProfile {
    componentKey: string;
    image?: string;
    isFilled?: boolean;
    title: string;
    description?: string;
    role?: string[];
    currentUserType?: string;
    openFromSideMenu?: boolean;
    userType?: string;
    toggleHandler?: () => void;
}

function ProfileSettings() {
    const {t} = useTranslation();
    const {
        user: {userType, completionInfo, company, agencyCompany, hasSuppliers, hasClients},
    } = useSelector((state: IAllStates) => state.auth, shallowEqual);
    const profiles: IProfile[] = [
        {
            componentKey: 'iv-profile',
            image: '/icons/profile/user.png',
            isFilled: completionInfo?.profileInfo,
            title: `${t('brand.account.ivProfile')}`,
            description: `${t('brand.account.ivProfile_desc')}`,
            role: ['influencer'],
        },
        {
            componentKey: 'personal-info',
            image: '/icons/profile/id-card.png',
            isFilled: completionInfo?.personalInfo,
            title: `${t('brand.account.personal_info')}`,
            description: `${t('brand.account.personal_info_desc')}`,
            role: ['brand', 'influencer', 'brand_master', 'agency', 'agency_master'],
        },
        {
            componentKey: 'company-info',
            image: '/icons/profile/business-and-trade.png',
            isFilled: completionInfo?.companyInfo,
            title: `${t('brand.account.company_info')}`,
            description: `${t('brand.account.company_info_desc')}`,
            role: ['brand_master', 'agency_master'],
        },
        {
            componentKey: 'billing-information',
            image: '/icons/profile/online-shopping.png',
            isFilled: completionInfo?.billingInfo,
            title: `${t('brand.account.billing_information')}`,
            description: `${t('brand.account.billing_information_desc')}`,
            role: ['brand_master', 'influencer', 'agency_master'],
        },
        {
            componentKey: 'login-and-security',
            image: '/icons/profile/user.png',
            isFilled: undefined,
            title: `${t('brand.account.login_and_security')}`,
            description: `${t('brand.account.login_and_security_desc')}`,
            role: ['brand', 'influencer', 'brand_master', 'agency', 'agency_master'],
        },
        {
            componentKey: 'subscription-plan',
            image: '/icons/profile/presentation.png',
            isFilled: undefined,
            title: `${t('brand.account.subscription_plan')}`,
            description: `${t('brand.account.subscription_plan_desc')}`,
        },
        {
            componentKey: 'global-preferences',
            image: '/icons/profile/firewall.png',
            isFilled: undefined,
            title: `${t('brand.account.global_preferences')}`,
            description: `${t('brand.account.global_preferences_desc')}`,
            role: ['brand', 'influencer', 'brand_master', 'agency', 'agency_master'],
        },
        {
            componentKey: 'users-and-roles',
            image: '/icons/profile/user-role.png',
            isFilled: undefined,
            title: `${t('brand.account.users_and_roles')}`,
            description: `${t('brand.account.users_and_roles_desc')}`,
            role: ['brand_master', 'agency_master'],
        },
        {
            componentKey: 'price-list',
            image: '/icons/profile/img_price_list.png',
            isFilled: undefined,
            title: `${t('brand.account.price_list_modal.price_list')}`,
            description: `${t('brand.account.price_list_modal.price_list_desc')}`,
            role: ['influencer'],
        },
        {
            componentKey: 'invoices',
            image: '/icons/profile/invoice.svg',
            isFilled: undefined,
            title: `${t('influencer.profile.invoices')}`,
            description: `${t('influencer.profile.invoices_desc')}`,
            role: ['influencer', 'agency', 'agency_master'],
        },
        {
            componentKey: 'subscription-plan',
            image: '/icons/profile/presentation.png',
            isFilled: undefined,
            title: `${t('brand.account.subscription_plan')}`,
            description: company?.package?.name.toUpperCase() +` (Fee ${Math.round((1- Number(company?.fee ?? 0.0))*100)}%)`,
            role: ['brand', 'brand_master'],
        },
        {
            componentKey: 'contracts',
            image: '/icons/profile/presentation.png',
            isFilled: undefined,
            title: `${t('brand.account.contracts')}`,
            description: `${t('brand.account.contracts_desc')}`,
            role: ['brand', 'brand_master'],
        },
        {
            componentKey: 'my-network',
            image: '/icons/profile/handshake.png',
            isFilled: undefined,
            title: `${t('brand.account.my_network')}`,
            description: `${t('brand.account.my_network_desc')}`,
            role: ['brand', 'brand_master'],
        },
        {
            componentKey: 'content-creators',
            image: '/icons/profile/user-role.png',
            isFilled: undefined,
            title: `${t('brand.account.content_creators')}`,
            description: `${t('brand.account.content_creators_desc')}`,
            role: ['agency', 'agency_master'],
        },
    ];

    const shouldRemoveCard = (componentKey: string) => {
        if (componentKey === 'billing-information') {
            return (
                userType === 'influencer' &&
                agencyCompany?.id &&
                componentKey === 'billing-information'
            );
        } else if (componentKey === 'my-network') {
            if (!hasClients && !hasSuppliers) {
                return true
            } else {
                return false
            }
        } else if (!company?.package?.isShownToUser  && componentKey === 'subscription-plan') {
            return true;
        }
    };

    return (
        <>
            <div className="col-12 d-flex justify-content-between align-items-center">
                <div className="d-md-flex align-items-center">
                    <BackButton/>
                    <h3 className="mb-0 mt-md-0 mt-2">{t('general.my_account')}</h3>
                </div>
                <div className="d-flex align-items-center">
                    <div className="d-flex flex-column align-items-end mr-3">
                        <strong
                            dangerouslySetInnerHTML={{__html: t('general.support')}}
                        ></strong>
                        <p className="font-12 mb-0 text-end">
                            {t('general.support_question')}
                        </p>
                        <a href="mailto:support@influencevision.com">
                            <p className="font-12 text-end mb-0">
                                support@influencevision.com
                            </p>
                        </a>
                    </div>
                    <div className="user-img d-none d-md-block">
                        <img src="/tempAssets/support.jpg" alt="Support profile img"/>
                    </div>
                </div>
            </div>
            <div className="row mt-5 mb-5 pb-5 mb-md-0 pb-md-0">
                {profiles.map(
                    (item) =>
                        item.role?.includes(userType) &&
                        !shouldRemoveCard(item.componentKey) &&
                        (
                            <div
                                key={item.componentKey}
                                className="col-lg-4 col-md-6 d-flex align-items-stretch mb-4"
                            >
                                <ProfileCard
                                    {...item}
                                    currentUserType={userType}
                                    openFromSideMenu={false}
                                />
                            </div>
                        )
                )}
            </div>
        </>
    );
}

export default ProfileSettings;
