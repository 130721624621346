import {Button, Modal} from 'react-bootstrap';
import {FindAgencyCreators} from './FindAgencyCreators';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import React from 'react';

interface IFindCreatorModalProps {
    show: boolean;
    closeModal: () => void;
    action?: any;
}

export function FindAgencyCreatorsModal({show, closeModal}: IFindCreatorModalProps) {
    const {t} = useTranslation();
    const history = useHistory();
    const goToMyCampaigns = () => {
        history.push('/agency/campaigns');
        closeModal();
    };
    return (
        <>
            <Modal show={show}
                   onHide={closeModal}
                   className="modal-dark"
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered={true}
            >
                <Modal.Body>
                    <Button onClick={goToMyCampaigns} className="d-md-none w-100 mb-2 mx-2" variant="outline-primary"
                            size="sm">
                        {t('agency.see_all_camp')}
                    </Button>
                    <div className="max-height-100vh overflow-auto">
                        <FindAgencyCreators closeModal={closeModal} renderPagination/>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-end">
                    <Button onClick={closeModal} variant="outline-primary" className="btn-sm mx-2">
                        {t('general.button.cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
