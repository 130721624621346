import React, {SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import TextEditor from '../../../../../shared/components/TextEditor';
import {User} from '../../../../../model/user/User';
import {IProfileData} from '../../profile-view';

interface IProfileAboutProps {
    state: User;
    setState: React.Dispatch<SetStateAction<IProfileData>>;
}

const ProfileAbout = ({state, setState}: IProfileAboutProps) => {
    const {t} = useTranslation();
    const changeTextFieldHandler = (value: string, name: string) => {
        setState(prev => ({
            ...prev, user: {
                ...prev.user,
                [name]: value,
            },
        }));
    };
    return (
        <div className="mb-4 mt-3 mt-md-0">
            <h6>
                {t('influencer.profile.about')}
            </h6>
            <span className="text-muted">
                {t('influencer.profile.aboutDescription')}
            </span>
            <div className="mt-2">
                {state?.id && <TextEditor classes={'text-editor-height'} state={state?.about ?? '-'}
                                          changeTextFieldHandler={(value) => changeTextFieldHandler(value, 'about')}
                />}
            </div>
        </div>
    );
};

export default ProfileAbout;
