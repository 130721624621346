import React, {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TextField} from '@material-ui/core';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import {UploadImage} from '../../../../shared/components/UploadImage';
import {useHistory} from 'react-router-dom';
import {StepperButtons} from '../../brand/components/StepperButtons';
import {IRegisterStepperProps} from '../../brand/components/Step1';
import {IRegisterAgencyInfo} from '../dto/IRegisterAgency';
import {multipartFormData} from '../../../../utils';
import {AUTH_AGENCY_URL} from '../../../../utils/endpoints';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {Form} from 'react-bootstrap';
import {InternalRoutes} from '../../../../enum/InternalRoutes';

export const Step4 = ({stepIdNumber, handleBackStep}: IRegisterStepperProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [currentCompany, setCurrentCompany] = useState<IRegisterAgencyInfo>({
        contact: {
            country: '',
        },
        nbCreators: '',
        displayName: '',
    });
    const [files, setFiles] = useState<Blob[] | MediaSource[]>();
    const {country, AgencyCreatorsEnum} = useSelector((states: IAllStates) => states.enums);
    const savedUser = useSelector((state: IAllStates) => state.registration);
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setCurrentCompany((prev) => ({...prev, [name]: value}));
    };

    const changeAutoCompleteHandler = (paramKey: string, paramValue: string | number, type?: string) => {
        if (type === 'country') {
            setCurrentCompany((prev: any) => ({
                ...prev, contact: {
                    [type]: paramValue,
                },
            }));
        } else {
            setCurrentCompany((prev: any) => ({...prev, [type as string]: paramValue}));
        }
    };

    const handleNextStep = () => {
        multipartFormData(files, `${AUTH_AGENCY_URL}/agency-info`, JSON.stringify({
            ...currentCompany,
            userId: savedUser?.id,
        })).then(response => {
            history.push(`${InternalRoutes.AGENCY_REGISTRATION}/${stepIdNumber + 1}?token=${response?.data?.verificationUUID}`);

            SuccessToast(t('auth.register.brand.step4.success'));
        }).catch(error => ErrorToast(error));
    };

    useEffect(() => {
        if (savedUser?.agencyCompany) {
            setCurrentCompany(savedUser?.agencyCompany as unknown as IRegisterAgencyInfo);
        }
    }, [savedUser?.agencyCompany]);

    const AutocompleteFields = useCallback(
        () => {
            return (
                <>
                    <div className="col-md-6 my-2">
                        <CustomAutocomplete dataArray={AgencyCreatorsEnum as string[]}
                                            defaultArray={!!currentCompany?.nbCreators.length ?
                                                currentCompany?.nbCreators : undefined}
                                            isWithImage={false} inputLabel="nbCreators"
                                            isMultiple={false}
                                            photoLabel=""
                                            isRequired={true}
                                            queryFilterKey=""
                                            inputLabelTranslation={t('auth.register.agency.step4.creators')}
                                            handleChangeParams={(_, e) => changeAutoCompleteHandler(_, e, 'nbCreators')}/>
                    </div>
                    <div className="col-md-6 my-2">
                        <CustomAutocomplete dataArray={country ?? []}
                                            defaultArray={!!currentCompany?.contact?.country?.length ?
                                                currentCompany?.contact?.country : undefined}
                                            isWithImage={true} inputLabel="country"
                                            isMultiple={false}
                                            isRequired={true}
                                            photoLabel="countryFlag"
                                            queryFilterKey="country"
                                            inputLabelTranslation={t('auth.register.brand.step2.country')}
                                            handleChangeParams={(_, e) => changeAutoCompleteHandler(_, e, 'country')}/>
                    </div>
                </>
            );
        },
        // eslint-disable-next-line
        [currentCompany?.nbCreators, currentCompany?.contact?.country],
    );

    return (
        <div className="row justify-content-center">
            <div className="col-md-7">
                <UploadImage saveFile={setFiles} acceptedFormats="image/jpeg, image/png, image/jpg"
                             previewImage={currentCompany?.profilePhoto?.path}
                             isPreviewArray={false}
                             isMultiple={false}/>
            </div>

            <div className="col-md-6">
                <TextField
                    label={t('auth.register.brand.step4.companyName')}
                    variant="outlined"
                    name="displayName"
                    size="small"
                    value={currentCompany?.displayName ?? ''}
                    onChange={changeHandler}
                    required
                    className="w-100 my-2"
                />
            </div>
            <div className="col-md-6"/>
            <AutocompleteFields/>
            <Form className="w-100" onSubmit={(e) => {
                e.preventDefault();
                handleNextStep();
            }}>
                <div className="col-12 mt-4">
                    <StepperButtons currentStep={stepIdNumber - 1} handleNextStep={handleNextStep}
                                    handleBackStep={handleBackStep}/>
                </div>
            </Form>
        </div>
    );
};
