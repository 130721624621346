import {Button, Col, Modal, Row} from 'react-bootstrap';
import {PackageOverviewTabs} from './PackageOverviewTabs';
import React, {useEffect} from 'react';
import {PackagesService} from '../../service';
import {packagesSlice} from '../../../../../store/brand/packagesSlice';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {openCalendlyPopup} from '../../../../../shared/functions/Functions';
import {IAllStates} from '../../../../../store/rootReducer';

interface IPackageOverviewModalProps {
  show: boolean;
  closeModal: () => void;
}

export function PackageOverviewModal({show, closeModal}: IPackageOverviewModalProps) {
  const dispatch = useDispatch();
  const {selectedPackage} = useSelector((states: IAllStates) => states.packages);
  const {t} = useTranslation();
  useEffect(() => {
    PackagesService.getPackages().then((response) => {
      const data = response.data;
      dispatch(packagesSlice.actions.setAllPackages(data));
    });
  }, []);
  return (
    <>
      <Modal show={show}
             onHide={closeModal}
             size="xl"
             aria-labelledby="contained-modal-title-vcenter"
             centered
             id="packages-modal"
      >
        <div className="border-bottom">
          <h5 className="pt-3 pl-3">Subscription packages</h5>
        </div>
        <Modal.Body className="pt-1">
          <Row>
            <Col className="p-0">
              <PackageOverviewTabs/>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} className="mr-2" variant="outline-secondary">{t('general.button.cancel')}</Button>
          <Button onClick={() => openCalendlyPopup('https://calendly.com/iv-user-experience/pro-upgrade-en?hide_gdpr_banner=1&primary_color=05bac9&')}>
            {selectedPackage?.isSelected ? t('general.button.details') : t('general.button.upgrade')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
