import { ICampaignOverview } from "../../../../../model/briefing/overview/ICampaignOverview";

const briefingCampaignGoal = (t: any, campaignOverview: ICampaignOverview) => {
    return (`
        <div class="avoid-brake mt-10">
            <div>
                <h3 class="text-upper">${t('brand.campaign.briefing.campaign_goal')}</h3>
                <h4>${campaignOverview?.campaignGoal?.title}</h4>
                <p>${campaignOverview?.campaignGoal?.story}</p>
            </div>
        </div>
        `
    );    
}

export default briefingCampaignGoal;