import moment from 'moment';
import React, {SetStateAction} from 'react';
import {Alert, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {TiWarningOutline} from 'react-icons/ti';
import {Campaign} from '../../../../model/campaign/Campaign';
import {IOfferItems} from '../../../../model/offer/IOfferItems';
import {User} from '../../../../model/user/User';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {formatDate} from '../../../../shared/functions/Functions';
import {AgencyPlannerService} from '../../../agency/planner/service';
import {iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {INewEvent} from '../../planner/components/CreateEventModal';
import {InfluencerPlannerService} from '../../planner/service';
import {GoDotFill} from 'react-icons/go';


export interface ISelectedCampaign {
    campaign: Campaign;
    contentCreatorId?: number;
    offerItemId: number;
}

interface IReportingCampaignCardProps {
    offerItem: IOfferItems;
    setSelectedIdOffer: React.Dispatch<SetStateAction<number>>;
    isReviewed: boolean;
    offerStatus?: string;
    agencyUserId?: number;
    acceptedUser?: User;
    campaign: Campaign;
    setCreateEvent: React.Dispatch<SetStateAction<INewEvent>>;
    setSelectedCampaign: React.Dispatch<SetStateAction<ISelectedCampaign | undefined>>;
    setIsNewReporting: React.Dispatch<SetStateAction<boolean>>;
    setIsCreateModalOpened: React.Dispatch<SetStateAction<boolean>>;
    setIsProposalOpened: React.Dispatch<SetStateAction<boolean>>;
    setIsPreviewEventOpened: React.Dispatch<SetStateAction<boolean>>;
    setSelectedEvent: React.Dispatch<SetStateAction<iManageList | undefined>>;
}

const getCorrectReportIcon = (status: string) => {
    switch (status) {
        case 'feedback_loop':
        case 'approved':
        case 'scheduled':
        case 'proposed':
        case null:
            return 'not-due.svg';
        case 'reported':
            return 'check.svg';
        case 'overdue':
            return 'tick-mark.svg';
        default:
            return 'computer-icon.svg';
    }
};

export interface ISelectedEvent {
    event: iManageList;
    campaign: Campaign;
    offerItem: IOfferItems;
}

export interface IReportingUpdate {
    campaignId?: number;
    offerItemId?: number;
    socialPlatformId?: number;
    eventId?: number;
    postingDate?: string;
    externalLinks?: string[];
    postingLink?: string;
    clicks: number;
    comments: number;
    likes: number;
    reach: number;
    saves: number;
    shares: number;
    stickerEngagements: number;
    swipeUpLinks: number;
    views: number;
    watchTime: number;
    file?: Blob[] | MediaSource[];
    attachments?: Blob[] | MediaSource[];
}

export const dueStatuses = ['published', 'approved', 'reported'];
export const plannedStatuses = ['scheduled', 'feedback_loop', 'proposed'];

const ReportingCampaignCard = ({
                                   acceptedUser,
                                   offerItem,
                                   offerStatus,
                                   agencyUserId,
                                   setCreateEvent,
                                   isReviewed,
                                   setIsCreateModalOpened,
                                   setIsProposalOpened,
                                   campaign,
                                   setSelectedIdOffer,
                                   setSelectedEvent,
                                   setIsPreviewEventOpened,
                                   setSelectedCampaign, setIsNewReporting
                               }: IReportingCampaignCardProps) => {
    const {t} = useTranslation();
    const {socialPlatform, offerItemEvents} = offerItem;
    const getCorrectButtonName = (status: string) => {
        if (['published', 'approved'].includes(status)) {
            return t(`influencer.reporting.report`);
        }
        if (status === 'reported') {
            return t(`general.button.edit`);
        }
        if (!status) {
            return t(`general.button.plan`);
        }
        return t(`general.button.open`);
    };

    const handleClickReporting = async (status: string, eventItem: iManageList) => {
        if (offerStatus === 'completed' && eventItem.status !== 'reported') {
            setSelectedCampaign({campaign, offerItemId: offerItem?.id, contentCreatorId: agencyUserId});
            setIsNewReporting(true);
        } else if (!status && isReviewed) { // empty card & feedback enabled
            setIsCreateModalOpened(true);
            setCreateEvent(prevState => ({
                ...prevState,
                acceptedUser,
                postingDate: moment(campaign?.publishFrom).add(1, 'day').toISOString(),
                campaign: campaign,
                userId: agencyUserId,
                offerItemId: offerItem?.id,
            }));
        } else if (!status && !isReviewed) { // empty card & feedback disabled
            setSelectedCampaign({
                campaign,
                offerItemId: offerItem?.id,
                contentCreatorId: agencyUserId
            });
            setIsNewReporting(true);
        } else if (status === 'proposed' && isReviewed) { // proposal event open proposal modal if feedback loop enabled
            const data = !!agencyUserId ? await AgencyPlannerService.getPlannerModalData(String(eventItem?.id)) : await InfluencerPlannerService.getPlannerModalData(String(eventItem?.id));
            setIsProposalOpened(true);
            setSelectedEvent(data);
        } else if (status && !dueStatuses.includes(status) && isReviewed && status !== 'proposed') { // existing card & feedback enabled point to planner
            const data = await InfluencerPlannerService.getPlannerModalData(String(eventItem?.id));
            setIsPreviewEventOpened(true);
            setSelectedEvent(data);
        } else if ((status && !isReviewed) || dueStatuses.includes(status)) { // existing card  & feedback disabled
            setSelectedIdOffer(eventItem?.id);
        }
    }
    return (
        <>
            {offerItemEvents?.map((eventItem, index) => {
                const {status, date, attachments, id} = eventItem;
                return (
                    <div className="col-md-4 mt-3 pl-0" key={`${index}${id}`}>
                        <div className="row box-shadow default-radius">
                            <div className="col-4 px-0">
                                <CustomImageHandler classes="feed-image my-0 h-100 cursor-pointer"
                                                    thumbnailPath={attachments?.[0]?.thumbnail}
                                                    onClick={() => handleClickReporting(status, eventItem)}
                                                    photoPath={!!attachments?.[0]?.thumbnail ? attachments?.[0].thumbPath as string
                                                        : attachments?.[0]?.path as string}
                                                    altTag="Campaign photo"/>
                            </div>
                            <div className="col-8 py-2">
                                <div className="p-2 p-md-0  d-flex flex-column justify-content-between h-100">
                                    <div
                                        className="mb-1 d-flex align-items-center justify-content-between">
                                        <div className="mt-2 mt-md-0">
                                            <img src={socialPlatform?.path}
                                                 alt="Platform icon"/>
                                            <span
                                                className="ml-1">
                                            {t(`general.platforms.${socialPlatform?.name}`)}
                                        </span>
                                        </div>
                                        <img src={`/icons/${getCorrectReportIcon(status)}`} alt="computer-icon"/>
                                    </div>
                                    {date && <p className="text-muted text-center text-md-left">{formatDate(date)}</p>}
                                    {(plannedStatuses.includes(status) && isReviewed) && <Alert variant="danger"
                                                                                                className="box-shadow d-flex align-items-center w-100">
                                        <TiWarningOutline size={20}/>
                                        <span className="ml-1">
                                            {t('general.waitingForApproval')}
                                        </span>
                                    </Alert>
                                    }
                                    {!status &&
                                        <Alert variant="warning"
                                               className="box-shadow my-2 d-flex align-items-center w-100">
                                            <TiWarningOutline size={20}/>
                                            <span className="ml-1">
                                              {t(`influencer.reporting.scheduling_miss`)}
                                          </span>
                                        </Alert>
                                    }
                                    <div
                                        className={`d-flex align-items-center justify-content-${status ? 'between' : 'end'} my-2 my-md-0`}>
                                        {status && <p className={`status ${status} my-1 my-md-0`}>
                                            {t(`brand.campaign.manage.${status}`)}
                                        </p>}
                                        {/*TODO RED BUBBLES*/}
                                        {/*{eventItem?.eventNotifications?.some(notification => notification.isUnread) ?*/}
                                        {/*    <GoDotFill size={15}*/}
                                        {/*               className="text-danger top-right-bullet"*/}
                                        {/*               style={{right: '-10%'}}*/}
                                        {/*    /> : ''}*/}
                                        <Button
                                            variant={(plannedStatuses?.includes(status) || status === 'reported') ? 'outline-primary' : 'primary'}
                                            size="sm"
                                            onClick={() => handleClickReporting(status, eventItem)}
                                        >
                                            {getCorrectButtonName(status)}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default ReportingCampaignCard;
