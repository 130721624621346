import React, {SetStateAction} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {IMessageOpenedChat, IMessageThreadQuery} from '../../../app/brand/campaign/messages/dto/IMessage';
import {MessageBody} from './MessageBody';
import { useTranslation } from 'react-i18next';

interface ILoadMoreMessagesProps {
    messageData: IMessageOpenedChat[];
    keyId: number;
    setFilterParams: React.Dispatch<SetStateAction<IMessageThreadQuery>>;
    filterParams: IMessageThreadQuery;
    isMessageBubble?: boolean;
}

export const LoadMoreMessages = ({
                                     messageData,
                                     isMessageBubble,
                                     keyId,
                                     filterParams,
                                     setFilterParams,
                                 }: ILoadMoreMessagesProps) => {
    const {t} = useTranslation();
    const {count, page} = filterParams;
    const msgData = messageData.filter((message, msgIndex, currArray) => currArray.findIndex(v2 => (v2.id === message.id)) === msgIndex) // remove duplicates;
    const loadMoreData = () => {
        const valueToUpdate = page + 1;
        setFilterParams(prev => ({...prev, page: valueToUpdate}));
    };

    return (
        <div id={`scrollableDiv-${keyId}`}
             className="scrollable-chat-height"
             style={{
                 overflow: 'auto',
                 display: 'flex',
                 flexDirection: 'column-reverse',
                 paddingRight: '1rem',
             }}>
            <InfiniteScroll
                dataLength={msgData?.length}
                next={() => {
                    loadMoreData();
                }}
                style={{
                    display: 'flex',
                    flexDirection: 'column-reverse',
                }} // To put endMessage and loader to the top.
                inverse={true} //
                endMessage={
                    <p style={{textAlign: 'center'}}>
                        <b>{t('influencer.messages.seeAllMessages')}</b>
                    </p>
                }
                hasMore={msgData?.length < Number(count)}
                loader={<h4>Loading...</h4>}
                scrollableTarget={`scrollableDiv-${keyId}`}
            >
                <div id="lastMessage"/>
                {msgData?.map(comment => (
                    <MessageBody comment={comment}
                                 key={comment?.id}
                                 isMessageBubble={isMessageBubble}
                                 isMyMessage={comment?.isCurrentUserMessage}
                                 senderData={comment?.sender}
                                 haveFiles={!!comment?.attachments?.length}
                                 files={comment?.attachments}/>
                ))}
            </InfiniteScroll>
        </div>
    );
};
