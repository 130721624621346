import {Checkbox, TextField} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {AiFillEye, AiOutlineEyeInvisible} from 'react-icons/ai';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useRecaptcha} from '../../../../shared/hooks/useRecaptcha';
import {useSearchQuery} from '../../../../shared/hooks/useSearchQuery';
import {IAllStates} from '../../../../store/rootReducer';
import {ErrorToast, SuccessToast, WarningToast} from '../../../../utils/toasters';
import {AuthService} from '../../../service';
import {StepperButtons} from '../../brand/components/StepperButtons';
import {IEmployeeRegister} from '../dto/IEmployeeRegister';

export interface IRegisterStepperProps {
    stepIdNumber: number;
    handleBackStep: () => void;
}

export const Step1 = ({stepIdNumber, handleBackStep}: IRegisterStepperProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const verificationUUID = useSearchQuery('token');
    const [isLoading, setIsLoading] = useState(false);
    const [registerData, setRegisterData] = useState<IEmployeeRegister>({
        email: '',
        password: '',
        isGeneralTermsAccepted: false,
        repeatPassword: '',
        verificationUUID: verificationUUID ?? '',
        company: {
            id: 0,
        },
    });
    const {setToken, executeRecaptcha} = useRecaptcha();
    const savedData = useSelector((state: IAllStates) => state.employee);
    const [isTypePassword, setIsTypePassword] = useState(true);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>, isCheckbox = false) => {
        const {name, value, checked} = event.target;
        setRegisterData((prev) => ({
            ...prev,
            [name]: isCheckbox ? checked : value,
        }));
    };
    const handleNextStep = async () => {
        if (!executeRecaptcha) {
            return;
        }
        const result = await executeRecaptcha('register');
        setToken(result);
        if (registerData?.isGeneralTermsAccepted) {
            setIsLoading(true);
            AuthService.registerEmployeeAccount(registerData).then(response => {
                if (response) {
                    history.push(`/brand/employee/register/${stepIdNumber + 1}?token=${response?.verificationUUID}`);
                    SuccessToast(t('auth.register.brand.step1.pleaseCheckEmail'));
                }
            }).catch(error => ErrorToast(error)).finally(() => setIsLoading(false));
        } else {
            WarningToast(t('auth.register.brand.step1.termsNotSelected'));
        }
    };

    useEffect(() => {
        if (savedData?.email) {
            setRegisterData(prev => ({
                ...prev,
                email: savedData?.email,
                verificationUUID: savedData?.verificationUUID,
                company: {
                    id: Number(savedData?.company?.id),
                },
            }));
        }
        // eslint-disable-next-line
    }, [JSON.stringify(savedData)]);

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            handleNextStep();
        }}>
            <div className="row">
                <div className="col-md-6">
                    <TextField
                        label="E-mail"
                        variant="outlined"
                        size="small"
                        value={registerData?.email ?? ''}
                        required
                        type="email"
                        name="email"
                        onChange={changeHandler}
                        className="w-100 my-2"
                    />
                </div>
                <div className="col-md-6"/>
                <div className="col-md-6">
                    <div className="d-flex align-items-center">
                        <TextField
                            label={t('auth.register.brand.step1.password')}
                            variant="outlined"
                            size="small"
                            value={registerData?.password ?? ''}
                            name="password"
                            onChange={changeHandler}
                            type={isTypePassword ? 'password' : 'text'}
                            className="w-100 my-2"
                        />
                        <div style={{marginLeft: '-1.8rem', zIndex: 1}} className="cursor-pointer"
                             onClick={() => setIsTypePassword(prev => !prev)}>
                            {isTypePassword ? <AiFillEye size={20}/> :
                                <AiOutlineEyeInvisible size={20}/>}
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <TextField
                        label={t('auth.register.brand.step1.repeatPassword')}
                        variant="outlined"
                        type="password"
                        onChange={changeHandler}
                        value={registerData?.repeatPassword ?? ''}
                        size="small"
                        name="repeatPassword"
                        className="w-100 my-2"
                    />
                </div>
                <div className="col-12 my-2 d-flex align-items-center">
                    <Checkbox defaultChecked={registerData?.isGeneralTermsAccepted ?? false} className="px-0"
                              onChange={(e) => {
                                  changeHandler(e, true);
                              }}
                              size={'small'}
                              id="checkbox"
                              name="isGeneralTermsAccepted"
                              color="primary"/>
                    <label htmlFor="checkbox" className="ml-2 mb-0 text-left cursor-pointer"
                           dangerouslySetInnerHTML={{__html: t('auth.register.brand.step1.terms')}}/>
                </div>
                <div className="col-12 mt-4">
                    <StepperButtons currentStep={stepIdNumber - 1} handleNextStep={handleNextStep} isLoading={isLoading}
                                    handleBackStep={handleBackStep}/>
                </div>
            </div>
        </Form>
    );
};
