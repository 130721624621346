import * as React from 'react';
import {useCallback} from 'react';
import {Row} from 'react-bootstrap';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {IParams} from '../../../model/shared/IParams';
import {getCorrectRegisterInfluencerText, getCorrectRegisterStepper,} from '../../../shared/functions/Functions';
import {IAllStates} from '../../../store/rootReducer';
import InfluencerMetaTags from './components/InfluencerMetaTags';
import {InfluencerStep1} from './components/InfluencerStep1';
import {InfluencerStep2} from './components/InfluencerStep2';
import {InfluencerStep3} from './components/InfluencerStep3';
import {InfluencerStep4} from './components/InfluencerStep4';
import {InfluencerStep5} from './components/InfluencerStep5';
import {InfluencerStep6} from './components/InfluencerStep6';
import {InfluencerStep7} from './components/InfluencerStep7';

type StepperTypeInfluencer = Record<number, JSX.Element>;

export const InfluencerRegisterPage = () => {
    const history = useHistory();
    const {stepId} = useParams<IParams>();
    const stepIdNumber = Number(stepId);
    const {t} = useTranslation();
    const {user, agencyCompany} = useSelector((state: IAllStates) => state.influencerRegistration);
    const isAgencyUser = !!user?.agencyCompany;
    const handleBackStep = () => {
        history.goBack();
    };

    const step: StepperTypeInfluencer = {
        1: <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}>
            <InfluencerStep1 handleBackStep={handleBackStep} stepIdNumber={stepIdNumber}/>
        </GoogleReCaptchaProvider>,
        2: <InfluencerStep2 handleBackStep={handleBackStep} stepIdNumber={stepIdNumber}/>,
        3: <InfluencerStep3 handleBackStep={handleBackStep} stepIdNumber={stepIdNumber}/>,
        4: <InfluencerStep4 handleBackStep={handleBackStep} stepIdNumber={stepIdNumber}/>,
        5: <InfluencerStep5 handleBackStep={handleBackStep} stepIdNumber={stepIdNumber}/>,
        6: !isAgencyUser ? <InfluencerStep6 handleBackStep={handleBackStep} stepIdNumber={stepIdNumber}/> :
            <InfluencerStep7 handleBackStep={handleBackStep} stepIdNumber={stepIdNumber}/>,
        7:  <InfluencerStep7 handleBackStep={handleBackStep} stepIdNumber={stepIdNumber}/>,
    };

    const CurrentStep = useCallback(() => {
        return step[stepIdNumber];
        // eslint-disable-next-line
    }, [stepIdNumber]);

    return (
        <>
            <InfluencerMetaTags/>
            <div className="influencer-register-page">
                <Row className="align-items-center mb-3">
                    {getCorrectRegisterStepper('registerInfluencer', `${stepIdNumber}`, (isAgencyUser || agencyCompany?.agency) ? 6 : 7)}
                    <div className="col-12 text-center">
                        <h3>
                            {getCorrectRegisterInfluencerText(`${(isAgencyUser && stepIdNumber === 6) ? 7 : stepIdNumber}`, t)?.title}
                        </h3>
                        <p className="text-muted mb-0">
                            {getCorrectRegisterInfluencerText(`${(isAgencyUser && stepIdNumber === 6) ? 7 : stepIdNumber}`, t, user?.email)?.description}
                        </p>
                    </div>
                </Row>
                <Row>
                    <CurrentStep/>
                </Row>
            </div>
        </>
    );
};
