import React, { useEffect, useState } from "react";
import { IProfileData } from "..";
import { CooperationItem } from "./CooperationItem";

interface ICooperationTab {
  profileData: IProfileData;
  setIdOffer: (...args: any) => void;
  setTriggerUpdateComment: (...args: any) => void;
  setProfileData: (...args: any) => void;
}

export default function CooperationTab({
  profileData,
  setIdOffer,
  setTriggerUpdateComment,
  setProfileData,
}: ICooperationTab) {
  const [profileDataNew, setProfileDataNew] = useState<IProfileData>();

  useEffect(() => {
    setProfileDataNew(profileData)
  }, [profileData]);

  return (
    <div className="pb-5">
      {profileDataNew?.offers && profileDataNew?.offers?.map((cooperation) => {
        return (
          <React.Fragment key={cooperation?.id}>
            <CooperationItem
              cooperation={cooperation}
              setIdOffer={setIdOffer}
              setProfileData={setProfileData}
              setProfileDataNew={setProfileDataNew}
              profileDataNew={profileDataNew}
              setTriggerUpdateComment={setTriggerUpdateComment}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
}
