import {CancelTokenSource} from 'axios';
import {requests} from '../../../utils';
import {INFLUENCER_PLANNER, MANAGE_URL} from '../../../utils/endpoints';
import {iManageList} from '../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {INewEvent} from './components/CreateEventModal';
import {IPlannerMessage} from './components/preview/PreviewFeedback';
import {
    IPlannerEventsQuery,
    IPlannerOffersQuery,
    IPlannerUpdate,
    IPlatformQuery,
    IRescheduleNotify
} from './dto/IPlanner';

export const InfluencerPlannerService = {
    getPlannerOffers: (pagination: IPlannerOffersQuery) => requests.get(`${INFLUENCER_PLANNER}/offers`, pagination),
    getEvents: (pagination: IPlannerOffersQuery): Promise<{
        data: iManageList[]
    }> => requests.get(INFLUENCER_PLANNER, pagination),
    getMentions: (campaignId: number, payload: IMentionPayload) => requests.get(`${MANAGE_URL}/comment/participants/${campaignId}`, payload),
    getPlannerData: (pagination: IPlannerEventsQuery) => requests.get(`${INFLUENCER_PLANNER}/filter/id`, pagination),
    createNewEvent: (data: INewEvent) => requests.post(INFLUENCER_PLANNER, data),
    getPlannerModalData: (id: string, influencerId?: number | undefined) => requests.get(`${INFLUENCER_PLANNER}/modal/${id}?influencerId=${influencerId}`),
    acceptEvent: (data: {
        eventId: number,
        postingDate: string
    }, eventId: number) => requests.post(`${INFLUENCER_PLANNER}/accept/${eventId}`, data),
    uploadAttachment: (data: FormData, id: number, cancelToken?: undefined | CancelTokenSource, onUploadProgress?: any) => requests.post(`${INFLUENCER_PLANNER}/attachment/${id}`, data, {
        cancelToken: cancelToken,
        onUploadProgress: onUploadProgress
    }),
    deleteAttachment: (id: number) => requests.delete(`${INFLUENCER_PLANNER}/attachment/${id}`),
    addPlannerComment: (data: IPlannerMessage) => requests.post(`${INFLUENCER_PLANNER}/comment/send`, data),
    updatePostingLink: (data: IPlannerUpdate) => requests.put(INFLUENCER_PLANNER, data),
    updateCaption: (data: {
        eventId: number,
        caption: string,
        storyline: string
    }) => requests.put(`${INFLUENCER_PLANNER}/caption`, data),
    getPlannerPlatforms: (data: IPlatformQuery) => requests.get(`${INFLUENCER_PLANNER}/platforms`, data),
    getRescheduleNotify: (): Promise<IRescheduleNotify[]> => requests.get(`${INFLUENCER_PLANNER}/reschedule/requests`),
    rescheduleEvent: (data: {
        eventId: number,
        postingDate: string
    }) => requests.put(`${INFLUENCER_PLANNER}/reschedule`, data),
    rescheduleRequest: (data: {
        eventId: number,
        postingDate: string,
    }) => requests.put(`${INFLUENCER_PLANNER}/reschedule/request`, data),
    getTodoEvents: (pagination: IPlatformQuery) => requests.get(`${INFLUENCER_PLANNER}/todo/platforms/mobile?djole=true`, pagination)
};


interface IMentionPayload {
    isInfluencer?: boolean;
    eventId?: number;
    isAgency?: boolean;
}
