import {useTranslation} from 'react-i18next';
import {Link, useLocation} from 'react-router-dom';
import {getCorrectRegisterImage} from '../../../../shared/functions/Functions';
import {Image} from 'react-bootstrap';
import React from 'react';

export const AgencyInfo = () => {
    const {t} = useTranslation();
    const {pathname} = useLocation();
    const currentStepId = String(pathname)?.split('/').at(-1) ?? '';
    return (
        <div
            className={`d-none max-height-100vh d-md-flex col-md-4 col-lg-6 flex-column justify-content-between auth-bg-image`}
            style={{backgroundImage: `url(${getCorrectRegisterImage(currentStepId, true)})`}}
        >
            <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <Image
                    alt="Logo"
                    className="max-h-70px"
                    src="/tempAssets/img-white-logo.png"
                />
            </Link>
            <div className="flex-column d-flex justify-content-top align-items-center text-lg-center h-50">
                <div className=" w-50 blur-background">
                    <div className="auth-bg-text">
                        <h4 className="mb-2">
                            {t('auth.register.agency.info.title')}
                        </h4>
                        <h6>
                            {t('auth.register.agency.info.subtitle')}
                        </h6>
                        <p className="font-weight-normal">
                            {t('auth.register.agency.info.description')}
                        </p>
                        <div className="hr"></div>
                        <div className="d-flex justify-content-between mt-2">
                            <p className="font-weight-normal w-75">
                                {t('auth.already_register')}
                            </p>
                            <Link to={'/'}>
                                <p className="text-white">{t('auth.signin')}</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
