import * as React from 'react';
import {FiInfo} from 'react-icons/fi';
import PlatformAccordion from './PlatformAccordion';
import {Totals} from './Totals';
import {OverlayTrigger, Table, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import 'bootstrap/js/src/collapse.js';
import {getSubtotalOfferItems} from '../../../../../../../../shared/functions/Functions';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../../store/rootReducer';
import {TotalTypes} from '../../../../../../../../enum/TotalTypes';

export default function OfferPlatformTable() {
    const {t} = useTranslation();
    const {campaign, initialOffer, isCharity} = useSelector((state: IAllStates) => state.offerBrand);

    return (
        <>
            <div className="overflow-auto table-offer-items">
                <Table responsive className="table-head-bg m-0 mt-1">
                    <thead className="table-head-bg">
                    <tr>
                        <th>{t('brand.campaign.book.offer.modal.platform')}</th>
                        <th className="text-center">{t('brand.campaign.book.offer.modal.followers')}</th>
                        <th className="text-center">
                            {t('brand.campaign.book.offer.modal.avg_reach')}
                            <OverlayTrigger placement="top"
                                            overlay={<Tooltip
                                                id="impressions-tooltip">
                                                {t(`brand.campaign.book.offer.modal.avg_reachTooltip`)}
                                            </Tooltip>}>
                                <FiInfo size={14} className="cursor-pointer ml-1"/>
                            </OverlayTrigger>
                        </th>
                        <th className="text-center">{t('brand.campaign.book.offer.modal.audience_per_country')}</th>
                        <th className={`${isCharity ? 'd-none' : 'text-center'}`}>
                            CPM
                            <OverlayTrigger placement="top"
                                            overlay={<Tooltip
                                                id="impressions-tooltip">
                                                {t(`brand.campaign.book.offer.modal.cpmTooltip`)}
                                            </Tooltip>}>
                                <FiInfo size={14} className="cursor-pointer ml-1"/>
                            </OverlayTrigger>
                        </th>
                        <th className={`${isCharity ? 'd-none' : 'text-right'}`} style={{width: 150}}>
                            {t('brand.campaign.book.offer.modal.price')}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {campaign?.offerPlaceholder?.totalPlatforms?.map((platform, index) => (
                        <PlatformAccordion key={index} platform={platform}/>
                    ))}
                    </tbody>
                </Table>
            </div>
            {!isCharity &&
                <div className="w-100 mt-2">
                    <Totals title={TotalTypes.OFFER_ITEMS}
                            price={getSubtotalOfferItems(initialOffer?.offerItems)}
                    />
                </div>
            }
        </>
    );
}
