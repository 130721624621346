import {UsageRights} from '../../../../model/user/UsageRights';
import {Exclusivity} from '../../../../model/user/Exclusivity';

export interface IRightsExclusivity {
    usageRights: UsageRights;
    exclusivity: Exclusivity;
}
export const initialRights: UsageRights = {
    id: 0,
    oneMonthPrice: 0,
    sixMonthsPrice: 0,
    threeMonthsPrice: 0,
    twelveMonthsPrice: 0,
};
export const initialExclusivity: Exclusivity = {
    id: 0,
    oneMonthPrice: 0,
    sixMonthsPrice: 0,
    threeMonthsPrice: 0,
    twelveMonthsPrice: 0,
};