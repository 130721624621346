import React, {useEffect, useState} from 'react';
import {IOffer} from '../../../../../../model/offer/IOffer';
import HistoryDetail from '../../../../../brand/campaign/book/offer/modal/tabs/history/HistoryDetail';
import {ManagementOfferService} from '../../../service';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../store/rootReducer';
import {ErrorToast} from '../../../../../../utils/toasters';

export function HistoryTab() {
    const {campaignId} = useSelector((state: IAllStates) => state.offerInfluencer);
    const [historyOffers, setHistoryOffers] = useState<IOffer[]>([]);
    useEffect(() => {
        ManagementOfferService.getOfferHistory(campaignId)
            .then((response) => {
                setHistoryOffers(response);
            }).catch(err => ErrorToast(err));
        // eslint-disable-next-line
    }, []);
    return (
        <>
            {historyOffers.map(offer => (
                <HistoryDetail offer={offer}/>
            ))
            }
        </>
    );
};