import {BaseModel} from '../../../model/shared/BaseModel';
import {requests} from '../../../utils';
import {FAVORITE_URL, INFLUENCER_EXPLORE_CAMPAIGNS} from '../../../utils/endpoints';
import {IExploreCompanyFilterParams, IExploreFilterParams, IExploreItem} from './dto/Explore';
import {ICompany} from '../../shared/user/dto/ICompany';

export const InfluencerExploreApi = {
    getAllExploreCampaigns: (params: IExploreFilterParams): Promise<BaseModel<IExploreItem[]>> => {
        return requests.get(`${INFLUENCER_EXPLORE_CAMPAIGNS}/campaigns`, params);
    },
    addToFavorites: (id: number, method: 'put' | 'post') => requests[method](`${FAVORITE_URL}/${id}`, {}),
    getAllCompanies: (params: IExploreCompanyFilterParams): Promise<BaseModel<ICompany[]>> => {
        return requests.get(`${INFLUENCER_EXPLORE_CAMPAIGNS}/company`, params);
    },
};
