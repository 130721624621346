import {createSlice} from '@reduxjs/toolkit';
import {Campaign} from '../../model/campaign/Campaign';

export interface ISelectedCampaign {
  campaign?: Partial<Campaign>;
  activeCampaignId?: number;

};

const initialSelectedCampaignSlice: ISelectedCampaign = {
  campaign: undefined,
  activeCampaignId: undefined,
};

export const selectedCampaignSlice = createSlice({
  name: 'selectedCampaign',
  initialState: initialSelectedCampaignSlice,
  reducers: {
    setActiveCampaign: (state, action) => {
      state.campaign = action.payload;
      return state;
    },
    setActiveCampaignId: (state, action) => {
      state.activeCampaignId = action.payload;
      return state;
    },
    setIsReviewed: (state, action) => {
      const isReviewed = action.payload;
      state.campaign = {...state.campaign, isReviewed};
      return state;
    },

  },
});
