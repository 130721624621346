import React, {useState} from 'react';
import {gtmService} from '../../../../utils/gtm.service';
import {IRegisterStepperProps} from '../../brand/components/Step1';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import {IRegisterPreference} from '../../brand/dto/IRegister';
import {AuthService} from '../../../service';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {ConfirmationModal} from '../../../../shared/components/alert/ConfirmationModal';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import ToggleSwitch from '../../../../shared/components/ToggleSwitch';
import {StepperButtons} from '../../brand/components/StepperButtons';
import { FiInfo } from 'react-icons/fi';

export const InfluencerStep7 = ({stepIdNumber, handleBackStep}: IRegisterStepperProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const user = useSelector((state: IAllStates) => state.influencerRegistration.user);
    const [notifications, setNotifications] = useState<IRegisterPreference>({
        userId: 0,
        isNotificationByEmailRequested: true,
        isNotificationInBrowserRequested: true,
        locale: 'en',
        isUnsubscribed: true,
    });

    const changeLocaleHandler = (paramKey: string, paramValue: string | number) => {
        setNotifications(prev => ({...prev, [paramKey]: paramValue}));
    };

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
        setNotifications(prev => ({...prev, [name]: name === 'isUnsubscribed' ? !event : event}));
    };

    const handleNextStep = () => {
        AuthService.updateGlobalPreference({
            ...notifications,
            userId: user?.id ?? 0,
        }).then(response => {
            history.push('/');
            gtmService('influencer_registration');
            SuccessToast(t('general.toasts.successRegisteredUser'));
        }).catch(error => ErrorToast(error));
    };

    return (
        <Form className="w-100" onSubmit={(e) => {
            e.preventDefault();
            handleNextStep();
        }}>

            <div className="row">
                <ConfirmationModal
                    iconUrl="/icons/faq.png"
                    title={'Are you sure you want to proceed?'}
                    description={'If you don\'t enable notifications you could miss out some important updates on your upcoming and already running campaigns.'}
                    action={handleNextStep}
                    show={showConfirmModal} closeModal={() => setShowConfirmModal(false)}
                />
                <div className="col-12">
                    <div className="my-2">
                        <CustomAutocomplete dataArray={['en', 'de']} isWithImage={false} inputLabel="locale"
                                            defaultArray={notifications?.locale as string}
                                            key={user?.id}
                                            isMultiple={false}
                                            photoLabel=""
                                            queryFilterKey="locale"
                                            inputLabelTranslation={t('auth.register.brand.step7.language')}
                                            handleChangeParams={changeLocaleHandler}/>
                    </div>
                    <div className="d-flex flex-column w-100">
                        <h6 className='text-left mt-2'>
                            {t('brand.account.global_preferences_modal.email_notifications')}   
                        </h6>
                        <div className="d-flex justify-content-between align-items-center my-2">
                            <label htmlFor='isNotificationByEmailRequested' className='cursor-pointer'>
                                {t('brand.account.global_preferences_modal.active_campaign_notifications')}      
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                id="active_campaign_notifications-tooltip">
                                                    {t('brand.account.global_preferences_modal.active_campaign_notifications_tooltip')}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </label>
                            <ToggleSwitch
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'isNotificationByEmailRequested')}
                                small
                                id="isNotificationByEmailRequested"
                                name="isNotificationByEmailRequested"
                                defaultChecked={notifications?.isNotificationByEmailRequested}/>
                        </div>
                        <div className="d-flex justify-content-between align-items-center my-2">
                            <label htmlFor='isNotificationBySmsRequested' className='cursor-pointer'>
                                {t('brand.account.global_preferences_modal.campaign_notifications')}
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                id="campaign_notifications-tooltip">
                                                    {t('brand.account.global_preferences_modal.campaign_notifications_tooltip')}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </label>
                            <ToggleSwitch
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'isNotificationInBrowserRequested')}
                                small
                                id="isNotificationBySmsRequested"
                                defaultChecked={notifications?.isNotificationInBrowserRequested}/>
                        </div>
                        <div className="d-flex justify-content-between align-items-center my-2">
                            <label htmlFor="isUnsubscribed" className="cursor-pointer">
                                {t('auth.register.brand.step7.marketing_notifications')}
                            </label>
                            <ToggleSwitch
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'isUnsubscribed')}
                                small
                                id="isUnsubscribed"
                                checked={!notifications?.isUnsubscribed}/>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <StepperButtons currentStep={stepIdNumber - 1} handleNextStep={() => {
                        if ((!notifications.isNotificationByEmailRequested && notifications?.isUnsubscribed
                            && !notifications?.isNotificationInBrowserRequested)) {
                            setShowConfirmModal(true);
                        } else {
                            handleNextStep();
                        }
                    }}
                                    handleBackStep={handleBackStep}/>
                </div>
            </div>
        </Form>
    );
};
