import React, {lazy, Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {useLocation} from 'react-router-dom';
import {ErrorBoundary} from '../../shared/components/ErrorBoundary';
import LoadingScreen from '../../shared/components/LoadingScreen';
import {InfluencerDashboard} from './dashboard';
import {Layout} from '../Layout';
import DeclinedOffers from './declined-offers';
import InfluencerExplore from './explore';
import {OfferManagement} from './offer';
import InfluencerPlanner from './planner';
import InfluencerReporting from './reporting';
import InfluencerMessages from './messages';
import InfluencerProfile from './profile/profile-view';
import ForbiddenPage from '../../authentication/403';
import InfluencerInvoices from './invoices';

const UserAccount = lazy(() => import('../shared/user'));
const BriefingDetail = lazy(() => import('./campaign/briefing'));
const InfluencerCampaigns = lazy(() => import('./campaign/index'));

export function InfluencerRoutes() {
    const {pathname} = useLocation();

    return (
        <Suspense fallback={<LoadingScreen />}>
            <Layout>
                <ErrorBoundary key={pathname}>
                    <Switch>
                        <Route key={1} path="/influencer/profile/:id" component={InfluencerProfile}/>
                        <Route key={2} path="/influencer/dashboard" component={InfluencerDashboard}/>
                        <Route key={3} path="/influencer/offers" component={OfferManagement}/>
                        <Route key={4} path="/influencer/explore" component={InfluencerExplore}/>
                        <Route key={5} path="/influencer/campaigns" component={InfluencerCampaigns}/>
                        <Route key={6} path="/influencer/campaign/:id/overview" component={BriefingDetail}/>
                        <Route key={7} path="/influencer/planner" component={InfluencerPlanner}/>
                        <Route key={8} path="/influencer/reporting" component={InfluencerReporting}/>
                        <Route key={9} path="/influencer/messages/:id?" component={InfluencerMessages}/>
                        <Route key={10} path="/influencer/invoices" component={InfluencerInvoices}/>
                        <Route key={11} path="/influencer/offer/declined" component={DeclinedOffers}/>
                        <Route key={12} path="/settings/" component={UserAccount}/>
                        <Route key={13} path="/403" component={ForbiddenPage}/>
                        <Redirect from="/" to="/influencer/dashboard"/>
                    </Switch>
                </ErrorBoundary>
            </Layout>
        </Suspense>
    );
}
