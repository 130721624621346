import React, {SetStateAction} from 'react';
import {Col} from 'react-bootstrap';
import {IPicture} from '../../../../model/shared/IPicture';
import ReportingBody from '../../../influencer/reporting/components/modals/components/ReportingBody';
import ReportingScreenshots from '../../../influencer/reporting/components/modals/components/ReportingScreenshots';
import {IReportingUpdate, ISelectedEvent} from '../../../influencer/reporting/components/ReportingCampaignCard';
import YourContent from './YourContent';

interface IProps {
    selectedOfferItem: ISelectedEvent;
    setUpdateData?: React.Dispatch<React.SetStateAction<IReportingUpdate>> | undefined;
    setIsAttachmentClicked?: React.Dispatch<React.SetStateAction<boolean>> | undefined;
    setSelectedOfferItem: React.Dispatch<React.SetStateAction<ISelectedEvent | undefined>>;
    setSelectedFile?: React.Dispatch<React.SetStateAction<IPicture>> | undefined;
    isFormDisabled: boolean;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>, isNumber: boolean) => void;
    updateData: IReportingUpdate;
    errorFile?: boolean;
}

const RightReportingCard: React.FC<IProps> = ({
                                                  selectedOfferItem,
                                                  setSelectedOfferItem,
                                                  setSelectedFile,
                                                  isFormDisabled,
                                                  setIsAttachmentClicked,
                                                  setUpdateData,
                                                  updateData,
                                                  handleChange,
                                                  errorFile,
                                              }) => {
    return (
        <Col md={8}>
            {selectedOfferItem?.event?.status !== 'reported' && (
                <YourContent selectedAttachments={selectedOfferItem?.event?.attachments}
                             eventId={selectedOfferItem?.event?.id} isUploadPossible
                             setSelectedOfferItem={setSelectedOfferItem}
                             setUpdateData={setUpdateData} setIsAttachmentClicked={setIsAttachmentClicked}
                             setSelectedFile={setSelectedFile as React.Dispatch<SetStateAction<IPicture>>}
                             isButtonDisabled={isFormDisabled} hideExternalSearch={selectedOfferItem?.offerItem?.additionalPost}/>
            )}
            <ReportingBody
                isAgency
                isButtonDisabled={isFormDisabled}
                socialPlatform={selectedOfferItem?.event?.offerItem?.socialPlatform || selectedOfferItem?.event?.socialPlatform}
                updateData={updateData} handleChange={handleChange}/>
            <ReportingScreenshots
                setSelectedFile={setSelectedFile as React.Dispatch<SetStateAction<IPicture>>}
                status={selectedOfferItem?.event?.status}
                isAgency
                isButtonDisabled={isFormDisabled}
                setUpdateData={setUpdateData as React.Dispatch<React.SetStateAction<IReportingUpdate>>}
                reportItems={selectedOfferItem?.event?.reportItems}
                errorFile={errorFile}
            />
        </Col>
    );
};

export default RightReportingCard;
