import React, {SetStateAction} from 'react';
import {Button} from 'react-bootstrap';
import {calendarViews} from '../../../brand/campaign/manage/calendar';

interface IPlannerCalendarFiltersProps {
    selectedCalendarView: 'dayGridMonth' | 'dayGridWeek' | 'listWeek';
    setSelectedCalendarView: React.Dispatch<SetStateAction<'dayGridMonth' | 'dayGridWeek' | 'listWeek'>>;
}

const PlannerCalendarFilters = ({selectedCalendarView, setSelectedCalendarView}: IPlannerCalendarFiltersProps) => {
    const isListWeek = selectedCalendarView === 'listWeek';

    const changeBtnFormat = (selectedValue: 'dayGridMonth' | 'dayGridWeek' | 'listWeek') => {
        setSelectedCalendarView(selectedValue);
    };

    return (
        <div
            className={`border-top border-bottom mt-3 py-2 d-md-flex justify-content-end d-none`}
            style={{minHeight: 50}}>
            <div
                className={`d-flex align-items-center justify-content-md-${isListWeek ? 'between' : 'end position-absolute'}`}
                style={{top: 5}}>
                {calendarViews?.sort((a, b) => b.id - a.id)?.map((view) => {
                    return (
                        <Button
                            key={view?.view}
                            variant="icon"
                            className="mr-2"
                            onClick={() => changeBtnFormat(view?.view as 'dayGridMonth' | 'dayGridWeek' | 'listWeek')}
                        >
                            <img
                                src={`/icons/${
                                    view?.view === selectedCalendarView
                                        ? `${view?.icon}-active`
                                        : view?.icon
                                }.svg`}
                                style={{width: 20, height: 20}}
                                alt={`${view?.view} view icon`}
                            />
                        </Button>
                    );
                })}
            </div>
        </div>
    );
};

export default PlannerCalendarFilters;
