import {toast} from 'react-toastify';

export async function ErrorToast(e: any, isTypeBlob = false) {
    const blobMessage = isTypeBlob && JSON.parse(await e?.response?.data?.text());
    const errorMessages = blobMessage?.message ?? (e?.response?.data?.message ? e?.response?.data?.message : ['Something went wrong']);
    const isMessageArray = isErrorArray(errorMessages);
    if (isMessageArray) {
        errorMessages?.forEach((errorMessage: string, index: number) => {
            !!errorMessage?.length && toast.error(errorMessage, {toastId: `${errorMessage}-${index}`});
        });
    } else {
        toast.error(errorMessages, {
            toastId: errorMessages,
        });
    }
}

export function SuccessToast(props: string) {
    toast.success(props);
}

export function WarningToast(props: string) {
    toast.warning(props);
}

const isErrorArray = (args: string[]) => {
    return Array.isArray(args);
};
