import React, {useEffect, useState} from 'react';
import {IRegisterStepperProps} from '../dto/IRegisterStepperProps';
import {StepperButtons} from '../../brand/components/StepperButtons';
import {Col, Form, Row} from 'react-bootstrap';
import {InternalRoutes} from '../../../../enum/InternalRoutes';
import {ErrorToast, SuccessToast, WarningToast} from '../../../../utils/toasters';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {BookingService} from '../../../../app/brand/campaign/book/service';
import {CampaignCategory} from '../../../../model/campaign/CampaignCategory';
import {CategoryCard} from './CategoryCard';
import {AuthService} from '../../../service';
import {IAllStates} from '../../../../store/rootReducer';
import {useSearchQuery} from '../../../../shared/hooks/useSearchQuery';

export function InfluencerStep5({handleBackStep, stepIdNumber}: IRegisterStepperProps) {
    const influencerData = useSelector((state: IAllStates) => state.influencerRegistration.user);
    const [allCategories, setAllCategories] = useState<CampaignCategory[]>([]);
    const {t} = useTranslation();
    const history = useHistory();
    const token = useSearchQuery('token') as string;

    useEffect(() => {
        BookingService.getCategory()
            .then((response) => {
                if (!!influencerData?.categoriesArray?.length) {
                    const filteredArr = response.map(item => ({
                        ...item,
                        isSelected: influencerData?.categoriesArray.some(item2 => item.id === item2.id),
                    }));
                    setAllCategories(filteredArr);
                } else {
                    setAllCategories(response);
                }
            }).catch(err => ErrorToast(err));
        // eslint-disable-next-line
    }, []);

    const selectCategoryHandler = (id: number) => {
        const copy = [...allCategories];
        const indexToReplace = copy?.findIndex(item => item?.id === id);
        if (copy.filter(x => x.isSelected === true).length > 2) {
            WarningToast(t('general.toasts.warnSelectCategory'));
            if (!copy[indexToReplace].isSelected) {
                return;
            }
        }

        copy[indexToReplace] = {
            ...copy[indexToReplace],
            isSelected: !copy[indexToReplace]?.isSelected,
        };
        setAllCategories(copy);
    };
    const handleNextStep = () => {
        const categories = allCategories?.filter(category => category.isSelected);
        if (categories.length !== 3) {
            return WarningToast(t('general.maxThreeCategories'));
        }
        AuthService.categoriesInfluencer({
            verificationUUID: token ?? '',
            userCategories: allCategories?.filter(category => category.isSelected),
        }).then(response => {
            history.push(`${InternalRoutes.INFLUENCER_REGISTRATION}/${stepIdNumber + 1}?token=${response?.verificationUUID}`);
            SuccessToast(t('auth.register.influencer.step5.success'));
        }).catch(error => ErrorToast(error));
    };

    return (
        <>
            <Form onSubmit={(e) => {
                e.preventDefault();
                handleNextStep();
            }}>
                <Row>
                    <div className="d-flex flex-wrap my-3 justify-content-center">
                        {allCategories?.map((category) => (
                            <CategoryCard key={category?.id} item={category} clickHandler={selectCategoryHandler}/>
                        ))}
                    </div>

                    <Col xs={12} className="mt-4">
                        <StepperButtons currentStep={stepIdNumber - 1} handleNextStep={handleNextStep}
                                        isBackHidden={false}
                                        handleBackStep={handleBackStep}/>
                    </Col>
                </Row>
            </Form>
        </>
    );
};
