import React, {ChangeEvent, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {TextField} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {getNotificationNumber, getStatusIcon} from '../../../../shared/functions/Functions';
import {OffersFilterModal} from './OffersFilterModal';
import {IOfferManagementFilter} from '../dto/IOfferManagementFilter';
import {OfferFilterSort} from './OfferFilterSort';
import _debounce from 'lodash/debounce';
import {useHistory} from 'react-router-dom';
import {BsSliders} from 'react-icons/bs';
import {InternalRoutes} from '../../../../enum/InternalRoutes';
import {BiGift} from "react-icons/bi";
import {useSelector} from "react-redux";
import {IAllStates} from "../../../../store/rootReducer";

interface IOffersFilterProps {
    offerFilter: IOfferManagementFilter;
    setOfferFilter: React.Dispatch<React.SetStateAction<IOfferManagementFilter>>;
    newDeclined?: boolean;
    isAgency?: boolean;
}

export function OffersFilter({
                                 offerFilter,
                                 setOfferFilter,
                                 isAgency,
                                 newDeclined,
                             }: IOffersFilterProps) {
    const {t} = useTranslation();
    const history = useHistory();
    const [show, setShow] = useState(false);
    const {redDots} = useSelector((state: IAllStates) => state.redDots);

    const closeModal = () => {
        setShow(false);
    };
    const changeHandler = _debounce((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setOfferFilter(prevState => ({...prevState, name: event.target.value}));
    }, 300);
    const changeBarterStatus = () => {
        setOfferFilter(prevState => ({...prevState, isCharity: !offerFilter.isCharity}));
    };

    const goToPlanner = () => {
        if (isAgency) {
            history.push(`/agency/planner`);
        } else {
            history.push(`/influencer/planner`);
        }
    };

    const declinedOfferAction = () => {
        isAgency ?
            history.push(InternalRoutes.AGENCY_DECLINED_OFFER) :
            history.push('/influencer/offer/declined');
    };
    // function checkActiveFilters() {
    //   if (offerFilter.newComments) {
    //    return 'secondary';
    //   } else if (offerFilter.status === 'negotiate') {
    //    return 'info';
    //   } else {
    //    return 'outline-secondary';
    //   }
    // }

    return (
        <>
            <OffersFilterModal  show={show} closeModal={closeModal}
                               statusBarterHandle={changeBarterStatus}
                               offerFilter={offerFilter} changeHandler={changeHandler} isAgency={isAgency}/>
            <Row className="d-none d-md-flex align-items-center mb-4">
                <Col md={8} className="d-flex align-items-center">
                    <TextField
                        name="title"
                        id="outlined-basic"
                        onChange={changeHandler}
                        label={`${t('general.button.search')}`}
                        variant="outlined"
                        className="w-md-25 my-1"
                        size="small"
                    />
                    <div
                        onClick={() => changeBarterStatus()}
                        className={`influencer-tab-filter primary-dark my-3 ml-md-3
                    my-md-0 position-relative
                    ${offerFilter?.isCharity ? 'active' : ''}`}
                    >
                        <BiGift size={18}/>
                        <span className="ml-1">{!offerFilter?.isCharity ? t('influencer.explore.barter')
                            : t('influencer.explore.paid')}</span>
                        {!offerFilter?.isCharity ? !!getNotificationNumber('barterOffers', redDots) &&
                            <span className="sidebar-bullet">
                    {getNotificationNumber('barterOffers', redDots)}</span> : !!getNotificationNumber('paidOffers', redDots)
                            && <span className="sidebar-bullet">
                    {getNotificationNumber('paidOffers', redDots)}</span>}
                    </div>
                    <div
                        onClick={declinedOfferAction}
                        className={`influencer-tab-filter danger my-3 ml-md-3 my-md-0 position-relative`}
                    >
                        {t('brand.campaign.book.offer.filters.declined')}
                        {newDeclined &&
                            <span style={{right: -5}} className="notification-bullet-sm"></span>
                        }
                    </div>
                </Col>
                <Col className="text-right d-flex justify-content-end">
                    <OfferFilterSort offerFilter={offerFilter} setOfferFilter={setOfferFilter}/>
                    <Button onClick={goToPlanner} variant="outline-primary" size="sm">
                        {t('general.button.plan_my_camp')}
                    </Button>
                </Col>
            </Row>
            {/* FOR MOBILE DEVICES*/}
            <Row className="d-md-none align-items-center justify-content-between mb-2">
                <Button variant={!show ? 'outline-secondary' : 'primary'} onClick={() => setShow(true)}>
                    <BsSliders size={20}/>
                </Button>
                <OfferFilterSort offerFilter={offerFilter} setOfferFilter={setOfferFilter}/>
            </Row>
        </>
    );
}
