import {Card, Image} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

export function LockedComments() {
    const {t} = useTranslation();

    return(
        <>
            <Card>
                <Card.Body className="p-3">
                    <div className="d-flex align-items-center">
                        <Image className="mr-2" alt="Locked comments" src="/icons/locked-comments.svg"/>
                        <p className="mb-0 text-muted">
                            {t('brand.campaign.book.offer.modal.negotiation_thread_closed')}
                        </p>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};