import {createSlice} from '@reduxjs/toolkit';

const initialState: { redDots: INotificationRedDots | null } = {
    redDots: null,
};

export interface INotificationRedDots {
    planner: number;
    explore: number;
    exploreBarter?: number;
    explorePaid?: number;
    paidOffers?: number;
    barterOffers?: number;
    notifications?: number;
    offers: number;
    reporting: number;
}

export const redDotsSlice = createSlice({
    name: 'redDotsSlice',
    initialState: initialState,
    reducers: {
        setRedDots: (state, action) => {
            state.redDots = action.payload;
            return state;
        },
    },
});
