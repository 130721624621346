import React, {useEffect, useRef, useState} from 'react';
// import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';
import {Button, Spinner} from 'react-bootstrap';
import {useParams} from 'react-router';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import html2pdf from 'html2pdf.js';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../store/rootReducer';

import {
  convertImageToBase64,
  formatDate,
  getTotals,
} from '../../functions/Functions';
import { BookingService } from '../../../app/brand/campaign/book/service';
import { ErrorToast } from '../../../utils/toasters';
import { ITotalPlatforms } from '../../../model/platform/ITotalPlatforms';
import { numberFormatter } from '../../../utils/helpers/numberFormater';
import { priceSplitter } from '../../../utils/helpers/priceSplitter';
import { BriefingService } from '../../../app/brand/campaign/briefing/service';
import { ICampaignOverview } from '../../../model/briefing/overview/ICampaignOverview';
import { IOffer } from '../../../model/offer/IOffer';
import { UserRoles } from '../../../enum/UserRoles';
import { InfluencerBriefingService } from '../../../app/influencer/campaign/briefing/service';
import { AgencyService } from '../../../app/agency/service';

import headerPdf from './components/headerPdf';
import footerPdf from './components/footerPdf';
import detailsBlock from './components/detailsBlock';
import termsCondition from './components/termsCondition';
import titleContract from './components/titleContract';
import style from './style/style';
import briefingCampaignPrices from './components/briefing/briefingCampaignPrices';
import briefingCampaignCreatorsAudience from './components/briefing/briefingCampaignCreatorsAudience';
import distributionContent from './components/distributionContent';
import briefingCampaignTitle from './components/briefing/briefingCampaignTitle';
import briefingCampaignGoal from './components/briefing/briefingCampaignGoal';

interface IHtmlToPdf {
    passedOffers?: IOffer[] | number;
    styleContent?: string;
    styleBtn?: string;
    isBriefing?: boolean;
    passedCampaignId?: number;
}

const HtmlToPdf = ({passedOffers, styleContent, styleBtn, passedCampaignId, isBriefing = false}: IHtmlToPdf) => {
    const {id}: any = useParams();
    const {t} = useTranslation();
    const {
      auth: { user },
    } = useSelector((states: IAllStates) => states);

    const {company, userType} = user;
    const [offers, setOffers] = useState<any>([]);
    const [htmlContent, setHtmlContent] = useState('');
    const [campaignOverview, setCampaignOverview] = useState<ICampaignOverview>();
    const [showSpinner, setShowSpinner] = useState(false);

    const isGerman = user.locale === 'de';
    const isBrandOrBrandMaster = (userType === UserRoles.BRAND || userType === UserRoles.BRAND_MASTER);
    
    useEffect(() => {
      if (isBrandOrBrandMaster) {
          BookingService.getOffersCheckout(passedCampaignId ?? id)
              .then((response) => {
                  if (!isBriefing) {
                      if (!!passedOffers) {
                          setOffers(
                              response.bookedOffers?.filter((item) => item.id === passedOffers),
                          );
                      } else {
                          setOffers(response?.bookedOffers);
                      }
                  } else {
                      setOffers([{}]);
                  }
              })
              .catch((err) => ErrorToast(err));
      }
    }, [passedOffers]);

    useEffect(() => {
        if (!!+id) {
          const service =
              userType === UserRoles.INFLUENCER
                  ? InfluencerBriefingService.getBriefingSummary(id)
                  : userType === UserRoles.BRAND_MASTER || userType === UserRoles.BRAND
                      ? BriefingService.getBriefingByStep(passedCampaignId ?? +id, 'summary')
                      : AgencyService.getBriefingDetails(id);

          service
            .then((response) => {
              setCampaignOverview(response);

              if (!isBrandOrBrandMaster) {
                if (!isBriefing) {
                  if (!!passedOffers) {
                    setOffers(
                      response.offers?.filter((item: any) => item.id === passedOffers),
                    );
                  } else {
                    setOffers(response.offers);
                  }
                } else {
                  setOffers([{}]);
                }
              }
            })
            .catch((error) => ErrorToast(error));
      }
    }, [id, passedOffers]);

    const personalOfferContent = (offer: IOffer) => {
      return `
        <div>
          <h4 class="text-upper">
            ${t('influencer.offer_management.modal.add_personal_offer')}
          </h4>
          <p>${!!offer?.personalOffer ? offer?.personalOffer : '-'}</p>
        </div>
      `;
    };

    const pricingContent = (offer: IOffer) => {
        return `
          <div class="w-100 avoid-brake">
            <h4 class="pt-10">PRICING</h4>
            ${tableOffer(offer.totalPlatforms, offer.additionalOfferItems, offer?.feeAmount)}
          </div>
        `;
    };

    const tableOffer = (
        totalPlatforms: ITotalPlatforms[],
        additionalOfferItems: any,
        feeAmount: number,
    ) => {
        return `
        <table class="w-100 table-pricing" style="border-collapse: collapse;">
          <thead>
            <tr class="border-bottom border-top text-black-50">
              <th scope="col" class="py-1">${t(
            'brand.campaign.book.offer.modal.platform',
        )}</th>
              <th scope="col">No. of postings</th>
              <th scope="col">${t(
            'brand.campaign.book.offer.modal.followers',
        )}</th>
              <th scope="col">${t(
            'brand.campaign.book.offer.modal.audience_per_country',
        )}</th>
              <th scope="col">${t('brand.campaign.book.offer.modal.price')}</th>
            </tr>
          </thead>
          <tbody>
            ${totalPlatforms
            ?.map((item) => {
                return offerBody(item);
            })
            .join(' ')}

            <tr class="text-center border-top">
              <td class="font-weight-bold py-2">
                ${t(`brand.campaign.book.offer.modal.total_content`)}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td class="font-weight-bold">
              ${
            campaignOverview?.isCharity &&
            Math.round(getTotals([], totalPlatforms) ?? 0) === 0
                ? '-'
                : ` <p class="euro-symbol">
                ${priceSplitter(Math.round(getTotals([], totalPlatforms, isBrandOrBrandMaster ? campaignOverview?.isTransparentPricing : false) ?? 0))}
              </p>`
        }
              </td>
            </tr>

            <thead class="additional-items-table-head">
              <tr class="border-bottom border-top text-black-50">
                <th scope="col" class="py-1">${t(
            'brand.campaign.book.offer.modal.additional_items',
        )}</th>
                <th scope="col">Offer item</th>
                <th scope="col">${t(
            'brand.campaign.book.offer.modal.duration',
        )}</th>
                <th scope="col">${t(
            'brand.campaign.book.offer.modal.location',
        )}</th>
                <th scope="col">${t(
            'brand.campaign.book.offer.modal.price',
        )}</th>
              </tr>
            </thead>

            <tbody class="additional-items-table-body">
            ${
            !!additionalOfferItems?.length
                ? additionalOfferItems
                    ?.map((item: any) => {
                        return `
                <tr class="text-center">
                  <td class="font-weight-bold py-2" style="max-width: 330px">
                    ${
                            item.type === 'additional'
                                ? item.typeDescription
                                : t(`influencer.offer_management.modal.${item?.type}`)
                        }
                  </td>
                  <td>${
                            item.type === 'additional'
                                ? ''
                                : item?.typeDescription
                                    ? item?.typeDescription
                                    : t(`brand.campaign.book.offer.modal.${item?.type}`)
                        }
                  </td>
                  <td>${
                            item?.duration === '' || !item?.duration
                                ? '-'
                                : t(`brand.campaign.briefing.step8.${item?.duration}`)
                        }
                  </td>
                  <td>${item?.location
                            ?.map((location: any) => t(`general.countries.${location}`))
                            .join(', ')}
                  </td>
                  <td class="font-weight-bold">
                    ${campaignOverview?.isCharity &&
                            Number(priceSplitter(Math.round(item?.price))) === 0
                                ? '-'
                                : `
                        <p class="${item?.price > 0 ? 'euro-symbol' : ''}">
                          ${priceSplitter(Math.round(item?.price))}
                        </p>
                        `
                      }
                  </td>
                </tr>`;
                    })
                    .join(' ')
                : `
                  <tr class="text-center border-top">
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>`
            }
            </tbody>
            
            <tr class="text-center border-top">
              <td class="font-weight-bold py-2">
                ${t(`brand.campaign.book.offer.modal.total_items`)}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td class="font-weight-bold">
                  ${
                campaignOverview?.isCharity &&
                getTotals(additionalOfferItems) === 0
                    ? '-'
                    : `
                      <div class="d-flex justify-content-center">
                        <p class="mr-1">+</p>
                        <p class="euro-symbol">
                          ${priceSplitter(
                            Math.round(getTotals(additionalOfferItems) ?? 0),
                        )}
                        </p>
                      </div>`
                }
              </td>
            </tr>

            ${(campaignOverview?.isTransparentPricing && isBrandOrBrandMaster) ? `
              ${!campaignOverview?.isCharity ? `<tr class="text-center border-top">
               <td class="font-weight-bold py-2">
                  ${t(`brand.campaign.book.offer.modal.subtotal`)}
                  (${100 - Math.round((1 - Number(campaignOverview?.feePercentage ?? 0.0)) * 100)}%)
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td class="font-weight-bold">
                  ${campaignOverview?.isCharity &&
                      getTotals(additionalOfferItems) === 0
                          ? '-'
                          : `
                            <div class="d-flex justify-content-center">
                              <p class="euro-symbol">
                                ${priceSplitter(
                                  Math.round(getTotals(additionalOfferItems, totalPlatforms, campaignOverview?.isTransparentPricing) ?? 0),
                              )}
                              </p>
                            </div>`
                    }
                </td>
              </tr>` : '' }
              <tr class="text-center border-top">
                <td class="font-weight-normal py-2">
                  ${t(`brand.campaign.book.offer.modal.platform_fee`)}
                  (${Math.round((1 - Number(campaignOverview?.feePercentage ?? 0.0)) * 100)}%)
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td class="font-weight-normal">
                  <div class="d-flex justify-content-center">
                    <p class="mr-1">+</p>
                    <p class="euro-symbol">
                        ${priceSplitter(
                         feeAmount,
                      )}
                    </p>
                  </div>
                </td>
              </tr>
              ` : ''}

            <tr class="text-center border-top">
              <td class="font-weight-bold py-2">
                <h4>${t(`brand.campaign.book.offer.modal.grand_total`)}${' '} ${isBrandOrBrandMaster ? '(100%)' : ''}</h4>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td class="font-weight-bold">
              ${
            campaignOverview?.isCharity &&
            getTotals(additionalOfferItems, totalPlatforms) === 0
                ? 'Barter Deal'
                : ` <h4 class="euro-symbol">
                  ${priceSplitter(
                    Math.round(
                        getTotals(additionalOfferItems, totalPlatforms,  isBrandOrBrandMaster ? campaignOverview?.isTransparentPricing : false)! + ((isBrandOrBrandMaster && campaignOverview?.isTransparentPricing) ? feeAmount : 0 ) ?? 0,
                    ),
                )}
                </h4>`
        }
              </td>
            </tr>
          </tbody>
        </table>
      `;
    };

    const offerBody = (item: ITotalPlatforms) => {
      return `
      <tr class="text-center font-weight-bold">
        <td class="">
          ${item.name}
        </td>
        <td class="">
          x ${item.nbPostsTotal}
        </td>
        <td>
          ${numberFormatter(item.totalFollowers)}
        </td>
        <td>
          <div class="d-flex justify-content-center" style="gap: 4px;">
          ${item?.audiences
          .map((country) => {
              return `
                <div key=${country.id} class="d-flex">
                  <div class="country-flag">
                    ${country?.metric}
                  </div>
                  <p>
                    ${Math.round(country.value)}%
                  </p>
                </div>
              `;
          })
          .join(' ')}
          </div>
        </td>
        <td>
        ${
          campaignOverview?.isCharity && Math.round(item.priceTotal) === 0
              ? '-'
              : `
            <p class="euro-symbol">
              ${priceSplitter((isBrandOrBrandMaster && campaignOverview?.isTransparentPricing) ? item.priceWithoutFee : Math.round(item.priceWithFee))}
            </p>
        `
      }
        </td>
      </tr>

      ${item.offerItems
          ?.map((offerItem) => {
              return `
          <tr class="text-black-50 text-center">
            <td class="">
             ${t(`general.platforms.${offerItem?.socialPlatform?.name}`)}
            </td>
            <td>
              x ${offerItem?.nbPosts ?? ''}
            </td>
            <td></td>
            <td></td>
            <td>
            ${
                  campaignOverview?.isCharity &&
                  Math.round(offerItem?.price) === 0
                      ? '-'
                      : ` <p class="euro-symbol">
              ${priceSplitter((isBrandOrBrandMaster && campaignOverview?.isTransparentPricing) ? offerItem.priceWithoutFee : Math.round(offerItem?.price) ?? 0)}
            </p>`
              }

            </td>
          </tr>`;
          })
          .join(' ')}
      `;
  };

    const campaignOverviewContent = `
      <div class="avoid-brake">
        <div>
          ${isBriefing ?
          `<h3 class="mt-10">${t('brand.campaign.briefing.timeline')}</h3>` :
          `<h4 class="mt-10">CAMPAIGN OVERVIEW</h4>`
      }
        </div>

        <div class="d-flex" style="gap: 20px">
          <div>
            <h4 class="font-weight-bold mb-1">${t(
          'brand.campaign.briefing.publication_period',
      )}</h4>
            <div>
              ${formatDate(campaignOverview?.publishFrom)} - ${formatDate(
          campaignOverview?.publishTo,
      )}
            </div>
          </div>

          <div>
            <h4 class="font-weight-bold mb-1">${t(
          'brand.campaign.briefing.content_approval',
      )}</h4>
            <div>
              ${formatDate(campaignOverview?.postingDeadline)}
            </div>
          </div>

          <div>
            <h4 class="font-weight-bold mb-1">${t(
          'brand.campaign.briefing.application_deadline',
      )}</h4>
            <div>
              ${formatDate(campaignOverview?.date)}
            </div>
          </div>
        </div>
      </div>
    `;

    const briefingCampaignPlacements = async () => {
      const platformsHtml = await Promise.all(
        campaignOverview?.platforms?.map(async (platform) => {
          const base64Image = await convertImageToBase64(platform.socialPlatform.path!);
          return `
            <div style="display: flex; align-items: center; margin-right: 12px;" class="col-auto px-0">
              <img class="mr-1" src="${base64Image}" alt="${platform.socialPlatform.name}" />
              <span class="mr-1">${t(`general.platforms.${platform.socialPlatform.name}`)}</span>
              <span class="mr-3">X ${platform.nbPosts}</span>
            </div>`;
        }) ?? []
      );
    
      return `
        <div class="avoid-brake pt-10">
          <div>
            <h3 class="text-upper">${t('brand.campaign.briefing.placements')}</h3>
            <div style="gap: 12px; display: flex; align-items: center;" class="flex-wrap">
              ${platformsHtml.join(' ')}
            </div>
          </div>
        </div>
      `;
    };

    const typeManagement = {
      supplier: t('brand.campaign.briefing.supplier_desc'),
      client: t('brand.campaign.briefing.client_desc'),
      own_management: '', // t('brand.campaign.briefing.own_management_desc')
    };

    const briefingCampaignManagement = `
      <div class="avoid-brake mt-10">
        <div>
          <h3 class="text-upper">${t('brand.campaign.briefing.campaign_management')}</h3>
          <h4>${t(`brand.campaign.briefing.${campaignOverview?.sharingType}`)}: ${campaignOverview?.sharingCompany?.displayName}</h4>
          <p>${typeManagement[campaignOverview?.sharingType!] ?? ' '}
          </p>
        </div>
      </div>
    `;

    const briefingCampaignStrategy = async () => `
    <div class="mt-10">
      <div class="avoid-brake">
        <h3 class="text-upper">${t('brand.campaign.briefing.strategy')}</h3>
        <p>${typeManagement[campaignOverview?.sharingType!] ?? ' '}</p>
      </div>
  
      ${isBriefing && (userType === UserRoles.BRAND || userType === UserRoles.BRAND_MASTER) ? `
        <div class="avoid-brake ${campaignOverview?.sharingType! === 'own_management' ? 'mt-4' : 'mt-10'}">
          <div>
            <h4 class="text-upper">${t('brand.campaign.briefing.campaign_goal')}</h4>
            <h4>${campaignOverview?.campaignGoal?.title}</h4>
            <p>${campaignOverview?.campaignGoal?.story}</p>
          </div>
        </div>
  
        <div class="avoid-brake mt-10">
          <div>
            <h4 class="text-upper">${t('brand.campaign.briefing.content_creator_type')}</h4>
            <h4>${campaignOverview?.creatorType?.title}</h4>
            <p>${isGerman ? campaignOverview?.creatorType?.descriptionGerman : campaignOverview?.creatorType?.description}</p>
          </div>
        </div>
  
        <div class="avoid-brake mt-10">
          <div>
            <h4>${t('brand.campaign.briefing.social_media_platform')}</h4>
            <div class="d-flex flex-wrap" style="gap: 28px">
              ${!!campaignOverview?.mainPlatforms?.length ? await Promise.all(
                campaignOverview.mainPlatforms.map(async (socialPlatform) => {
                  const base64Image = await convertImageToBase64(socialPlatform.path!);
                  return `
                    <div class="d-flex align-items-center">
                      <img class="mr-2" src="${base64Image}" alt="${socialPlatform.name}"/>
                      <p>${socialPlatform.name}</p>
                    </div>`;
                })
              ).then((htmlArray) => htmlArray.join(' ')) : '-'}
            </div>
          </div>
        </div>
  
        <div class="avoid-brake mt-10 d-flex" style="gap: 28px">
          <div>
            <h4 class="">
              ${t('brand.campaign.briefing.step1.payment_type')}
            </h4>
            <div class="">
              ${campaignOverview?.isCharity ?
                  `<p class="mb-0 mr-3">${t('brand.account.price_list_modal.barter_deals')}</p>`
                  :
                  `<p class="mb-0 mr-3">${t('brand.account.price_list_modal.paid_campaign')}</p>`
              }
            </div>
          </div>
          <div>
            <h4 class="">${t('brand.campaign.briefing.budget_range')}</h4>
            <p class="euro-symbol font-weight-normal">
                ${priceSplitter(campaignOverview?.creatorType?.budgetRanges?.[0]?.recommendedMinBudget ?? 0)}
                - ${priceSplitter(campaignOverview?.creatorType?.budgetRanges?.[0]?.recommendedMaxBudget ?? 0)}
            </p>
          </div>
        </div>
        ` : ''}
    </div>
  `;
  
    const briefingCampaignContentCreators = `
      <div class="avoid-brake mt-10">
        <div>
          <h3 class="text-upper">${t('brand.campaign.briefing.content_creator')}</h3>
          <div class="d-flex" style="gap: 24px">
            <div>
              <h4>${t('brand.campaign.book.offer.modal.location')}</h4>
              <p>${!!campaignOverview?.countries?.length ? campaignOverview?.countries?.map(country => (`${t(`general.countries.${country?.name}`)}`)).join(', ') : '-'}</p>
            </div>

            <div>
              <h4>${t('brand.campaign.book.offer.modal.gender')}</h4>
              <p>${t(`general.gender.${campaignOverview?.gender ?? 'null'}`)}</p>
            </div>

            <div>
              <h4>${t('brand.campaign.briefing.age_range')}</h4>
              <p>${campaignOverview?.ageMin ? campaignOverview?.ageMin : ''}-${campaignOverview?.ageMax ? campaignOverview?.ageMax : ''}</p>
            </div>

            <div>
              <h4>${t('brand.campaign.briefing.categories')}</h4>
              <p>${campaignOverview?.campaignCategories?.map((item) => t(`general.category.${item.campaignCategory?.name}`)).join(', ')}</p>
            </div>

          </div>
        </div>
      </div>
    `;

    const campaignConceptContent = (offer: IOffer) => {
    return `
      <div>
        <h4 class="mt-10 text-upper">${t(
            'brand.campaign.book.offer.modal.campaign_concept',
        )}</h4>
      </div>

      <div>
        <p class="mb-1">${!!offer.description ? offer.description : '-'}</p>
      </div>
      `;
    };

    const brandedContent = () => {
        return !!campaignOverview?.brandedContent
            ? `
        <div>
          <div class="avoid-brake">
            <h3 class="pt-10 text-upper">${t(
                  'brand.campaign.book.offer.modal.branded_content',
            )}</h3>


            <h4 class="mb-1 mt-0">${t(
                  'brand.campaign.briefing.step7.branded_content_title',
              )}</h4>

            <p>${t('brand.campaign.briefing.step7.branded_content_desc')}</p>
          </div>

          <h4 class="mb-1 mt-4">${t(
                'influencer.offer_management.modal.how_use_content',
            )}</h4>

          <p>${campaignOverview?.brandedContent.description}</p>

          <div class="d-flex mt-4 " style="gap: 20px">
            <div class="d-flex align-items-center">
              <h4 class="m-0 mr-1">${t('brand.campaign.briefing.brandedContent_location')}: </h4>

              <p>${campaignOverview?.brandedContent.countriesArray
                ?.map((location) => t(`general.countries.${location}`))
                .join(', ')}</p>
            </div>
            <div class="d-flex align-items-center">
              <h4 class="m-0 mr-1">${t('brand.campaign.briefing.brandedContent_duration')}: </h4>

              <p>${t(
                `brand.campaign.briefing.step8.${campaignOverview?.brandedContent?.duration}`,
            )}</p>
            </div>
          </div>
        </div>
        `
            : '';
    };

    const usageRightsContent = () => {
        return !!campaignOverview?.usageRights
            ? `        
        <div>
          <div>
            <h3 class="pt-10 text-upper">${t(
                  'influencer.offer_management.modal.usage_rights',
              )}</h3>
          </div>
          <h4 class="mb-1">${t(
                'brand.campaign.briefing.step7.' +
                campaignOverview?.usageRights.mediaType +
                '_title',
            )}</h4>

          <p>${t(
                'brand.campaign.briefing.step7.' +
                campaignOverview?.usageRights.mediaType +
                '_desc',
            )}</p>

          <h4 class="mb-1 mt-4">${t(
                'influencer.offer_management.modal.how_use_content',
            )}</h4>

          <p>${campaignOverview?.usageRights?.description}</p>

          <div class="d-flex mt-4 " style="gap: 20px">
            <div class="d-flex align-items-center">
              <h4 class="m-0 mr-1">${t(
                'influencer.offer_management.modal.rights_location',
            )}: </h4>

              <p>${campaignOverview?.usageRights.countriesArray
                ?.map((location) => t(`general.countries.${location}`))
                .join(', ')}</p>
            </div>
            <div class="d-flex align-items-center">
              <h4 class="m-0 mr-1">${t(
                'influencer.offer_management.modal.rights_duration',
            )}: </h4>

              <p>${t(
                `brand.campaign.briefing.step8.${campaignOverview?.usageRights?.duration}`,
            )}</p>
            </div>
          </div>
        </div>

        ${termsCondition(isBriefing)}
        `
            : '';
    };

    const usageLegalContent = () => {
        return campaignOverview?.exclusivityDescription ||
        campaignOverview?.byLawsExplanation
            ? `
        <div class="avoid-brake">
          <h3 class="pt-10 text-upper">${t(
                'influencer.offer_management.modal.legal',
            )}</h3>

          <h4 class="mb-1">${t(
                'influencer.offer_management.modal.exclusivity',
            )}</h4>

          <p>${campaignOverview?.exclusivityDescription ?? '-'}</p>

          <div class="d-flex mt-4" style="gap: 20px">
            <div class="d-flex align-items-center">
              <h4 class="m-0 mr-1">${t(
                'brand.campaign.briefing.exclusivity_duration',
            )}: </h4>

              <p>${
                campaignOverview?.exclusivityDuration
                    ? t(
                        `brand.campaign.briefing.step8.${campaignOverview?.exclusivityDuration}`,
                    )
                    : '-'
              }
            </p>
            </div>
            <div class="d-flex align-items-center">
              <h4 class="m-0 mr-1">${t(
                'brand.campaign.briefing.competitors',
            )}: </h4>

              <div class="d-flex">
                ${
                campaignOverview?.exclusivityCompetitors?.length
                    ? campaignOverview?.exclusivityCompetitors?.map(
                        (competitor, index) =>
                            `<p key={competitor?.id}>
                          ${competitor?.description}
                      </p>`,
                    )
                    : '-'
            }
              </div>
            </div>
          </div>
        </div>

        ${
                campaignOverview?.byLawsExplanation
                    ? `
          <div class="pt-4 avoid-brake">
            <h4 class="mb-1">${t('brand.campaign.briefing.bylaws')}</h4>
            <p>${campaignOverview?.byLawsExplanation ?? ''}</p>
          </div>
          `
                    : ''
            }

        ${termsCondition(isBriefing)}
      `
            : '';
    };

    const campaignContent = () => {
    return `
        <div class="pt-4 avoid-brake">
          <h4 class="mb-1">${t('brand.campaign.briefing.product_desc')}</h4>

          <p class="description">${campaignOverview?.details ?? '-'}</p>
        </div>

        <div class="pt-5">
          <h4 class="mb-1">${t(
            'influencer.offer_management.modal.campaign_description',
        )}</h4>

          <p>${campaignOverview?.adDescription}</p>

          <div class="pt-5">
            <div class="d-flex align-items-center">
              <h4 class="m-0 mr-1">${t(
            'influencer.offer_management.modal.relevant_hash',
        )}: </h4>

              <p>${campaignOverview?.hashtags ?? '-'}</p>
            </div>

            <div class="d-flex align-items-center mt-1">
              <h4 class="m-0 mr-1">Mentions: </h4>

              <p>${campaignOverview?.profileTags ?? '-'}</p>
            </div>

            <div class="d-flex align-items-center mt-1">
              <h4 class="m-0 mr-1">${t(
            'brand.campaign.briefing.inspiration_links',
        )}: </h4>

              <p>${campaignOverview?.url ?? '-'}</p>
            </div>

            <div class="d-flex align-items-center mt-1">
              <h4 class="m-0 mr-1">${t('brand.campaign.briefing.content_moodboard')}: </h4>

              <p>
              ${!!campaignOverview?.campaignImages?.filter(image => image?.purpose === 'style_mood_image').length ? campaignOverview?.campaignImages
            ?.filter(image => image?.purpose === 'style_mood_image')
            ?.map(moonBoard =>
                moonBoard.title,
            ).join(', ') : '-'}
              </p>
            </div>
          </div>

        </div>
      `;
    };

    const contentTemplate = async () => {
      const offerTemplates = await Promise.all(
        offers?.map(async (offer: IOffer) => {
          return `
            <table class="table-holder w-100">
              <thead>
                <tr>
                  <th>
                    ${ await headerPdf(campaignOverview)}
                  </th>
                </tr>
              </thead>
    
              <tbody>
                <tr>
                  <td>
                    <div class="template-body">
                      ${!isBriefing ? detailsBlock(offer, !!company ? company! : campaignOverview?.company!, t, userType) : ''}
                      ${!isBriefing ? titleContract(offer, campaignOverview!) : ''}
                      ${!isBriefing ? personalOfferContent(offer) : ''}
                      ${!isBriefing ? campaignConceptContent(offer) : ''}
                      ${!isBriefing ? pricingContent(offer) : ''}
    
                      ${isBriefing ? briefingCampaignTitle(campaignOverview!, user) : ''}
                      ${isBriefing && isBrandOrBrandMaster && campaignOverview?.sharingType !== 'own_management' ? briefingCampaignManagement : ''}
                      ${isBriefing && (userType !== UserRoles.BRAND && userType !== UserRoles.BRAND_MASTER) ? briefingCampaignGoal(t, campaignOverview!) : ''}
                      ${isBriefing && isBrandOrBrandMaster ? await briefingCampaignStrategy() : ''}
    
                      ${!isBriefing && (userType !== UserRoles.BRAND && userType !== UserRoles.BRAND_MASTER) ? campaignOverviewContent : ''}
                      ${isBriefing && (userType !== UserRoles.BRAND && userType !== UserRoles.BRAND_MASTER) ? await briefingCampaignPlacements() : ''}
                      ${campaignContent()}
    
                      ${isBriefing ? campaignOverviewContent : ''}
                      ${isBriefing && isBrandOrBrandMaster ? await briefingCampaignPlacements() : ''}
                      ${isBriefing && isBrandOrBrandMaster ? briefingCampaignContentCreators : ''}
                      ${isBriefing && isBrandOrBrandMaster ? briefingCampaignCreatorsAudience(t, campaignOverview!) : ''}
    
                      ${distributionContent(t)}
                      ${brandedContent()}
                      ${usageRightsContent()}
                      ${usageLegalContent()}
    
                      ${isBriefing && isBrandOrBrandMaster ? briefingCampaignPrices(t, campaignOverview!) : ''}
                    </div>
                  </td>
                </tr>
              </tbody>
    
              <tfoot>
                <tr>
                  <td>
                    ${footerPdf}
                  </td>
                </tr>
              </tfoot>
            </table>
          `;
        }) ?? []
      );
    
      return offerTemplates.join(' ');
    };
    
    useEffect(() => {
      const generateHtmlContent = async () => {
        const content = await contentTemplate();
        setHtmlContent(() => {
          return `
            <head>
              <link rel="preconnect" href="https://fonts.googleapis.com">
              <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
              <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet">
            </head>
    
            ${content}
    
            ${style()}
          `;
        });
      };
    
      generateHtmlContent();
    }, [campaignOverview, offers]);
  
    const componentRef = useRef<HTMLDivElement>(null);

    // const handlePrint = useReactToPrint({
    //     content: () => componentRef.current,
    // });
  
    const handleZip = async () => {
      if (componentRef.current) {
        setShowSpinner(true);
        const element = componentRef.current;
        const opt = {
          margin: 0,
          filename: `${campaignOverview?.title}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 3 }, 
          jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' },
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          
        };
  
        try {
          const pdfBlob = await new Promise<Blob>((resolve, reject) => {
            html2pdf()
              .from(element)
              .set(opt)
              .output('blob') 
              .then(resolve)
              .catch(reject);
          });
  
          const zip = new JSZip();
          const additionalFiles = campaignOverview?.campaignImages.filter(file => file.purpose === 'style_mood_image') ?? [];
          
          if (additionalFiles.length) {
            for (let i = 0; i < additionalFiles.length; i++) {
              const file = additionalFiles[i];
              if (file.purpose === 'style_mood_image') {
                try {
                  const response = await fetch(file.path);
                  const blob = await response.blob();
                  zip.file(`Briefing_${campaignOverview?.title}_additional (${i+1}).${file.name.split('.')[1]}`, blob);
                } catch (error) {
                  console.error(`Failed to fetch ${file.title}:`, error);
                }
              }
            }
          }
          if (campaignOverview?.bylaws.length) {
            for (let i = 0; i < campaignOverview?.bylaws.length; i++) {
              const file = campaignOverview?.bylaws[i];
              try {
                const response = await fetch(file.path);
                const blob = await response.blob();
                zip.file(`Briefing_${campaignOverview?.title}_bylaws (${i+1}).${file.name.split('.')[1]}`, blob);
              } catch (error) {
                console.error(`Failed to fetch ${file.title}:`, error);
              }
            }
          }
          zip.file(passedOffers ? `${t('general.contract')}_${campaignOverview?.title}_${offers[0].user?.firstName}_${offers[0].user?.lastName}.pdf` : `Briefing_${campaignOverview?.title}.pdf`, pdfBlob);
  
          zip.generateAsync({ type: 'blob' })
          .then((content) => {
            saveAs(content, `${campaignOverview?.title}.zip`);
            setShowSpinner(false);
          })
          .catch((error) => {
            console.error('Failed to generate ZIP file:', error);
            setShowSpinner(false);
          });

          // FOR TESTING CUSTOM PDF
          // if (pdfBlob) { 
          //   const url = URL.createObjectURL(pdfBlob);
          //   const printWindow = window.open(url);
          //   if (printWindow) {
          //       printWindow.onload = () => {
          //           printWindow.focus();
          //           printWindow.print();
          //       };
          //   }
          // }
        } catch (error) {
          setShowSpinner(false);
        }
      }
    };
    
    return (
        <div className={styleContent ?? ''}>
            <Button className={`w-100 ${styleBtn}`} onClick={handleZip}>
                {isBriefing ? (
                  <>
                    {t('general.download_briefing')}
                    {showSpinner && (
                      <Spinner animation='border' size='sm' className='align-middle ml-2'/>
                    )}
                  </>
                ) : (
                  <>
                    {t('general.download')}
                    {showSpinner && (
                      <Spinner animation='border' size='sm' className='align-middle ml-2'/>
                    )}
                  </>
                )}
            </Button>
            <div className="d-none">
                <div
                    id="pdf"
                    ref={componentRef}
                    dangerouslySetInnerHTML={{__html: htmlContent}}
                ></div>
            </div>
        </div>
    );
};

export default HtmlToPdf;
