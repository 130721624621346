import {CSSProperties, useState} from 'react';

interface iCustomImageHandlerProps {
    photoPath: string | null | undefined;
    thumbnailPath?: string;
    altTag: string;
    classes?: string;
    placeholder?: string;
    style?: CSSProperties;
    onClick?: () => void;
}

export const CustomImageHandler = ({
                                       thumbnailPath,
                                       photoPath,
                                       altTag,
                                       classes = '',
                                       style,
                                       onClick,
                                       placeholder = '/icons/imagePlaceholder.png',
                                   }: iCustomImageHandlerProps) => {
    const [loaded, setLoaded] = useState(!thumbnailPath);

    const handleLoad = () => {
        setLoaded(true);
    };

    const isUserErrorImage = (classes?.includes('user-img-rounded') || altTag?.includes('User Profile'));
    const errorImageCheck = isUserErrorImage ? '/tempAssets/userLargePlaceholder.svg' : placeholder;

    return (
        <>
            {!loaded && <img
                src={thumbnailPath} alt={altTag}
                onClick={onClick}
                style={style}
                onError={(e: any) => (
                    // eslint-disable-next-line
                    (e.target.onerror = null), (e.target.src = isUserErrorImage ? '/tempAssets/userLargePlaceholder.svg' : '/icons/imagePlaceholder.png')
                )}
                className={`thumbnail-image ${classes}`}
            />}
            <img
                src={(photoPath ? photoPath : errorImageCheck ?? '/icons/imagePlaceholder.png')}
                alt={altTag}
                style={style}
                onClick={onClick}
                className={`${!loaded ? 'd-none' : ''} ${classes}`}
                onLoad={handleLoad}
                onError={(e: any) => (
                    // eslint-disable-next-line
                    (e.target.onerror = null), (e.target.src = isUserErrorImage ? '/tempAssets/userLargePlaceholder.svg' : '/icons/imagePlaceholder.png')
                )}
            />
        </>
    );
};
