import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {Tab} from '@material-ui/core';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {UserService} from '../service';
import {IProfileModal} from '../dto/IProfileModal';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {useTranslation} from 'react-i18next';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import TabPanel from '@mui/lab/TabPanel';
import {ChangePasswordTab} from './ChangePasswordTab';
import {IGlobalPreference} from '../dto/IGlobalPreference';
import {TwoFactorAuthTab} from './TwoFactorAuthTab';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import {toast} from 'react-toastify';

export interface IChangePassword {
    currentPassword: string;
    password: string;
    repeatPassword: string;
}

const initialValues: IChangePassword = {
    currentPassword: '',
    password: '',
    repeatPassword: '',
};

function LoginAndSecurityModal({show, closeModal}: IProfileModal) {
    const {userType} = useSelector((reduxState: IAllStates) => reduxState.auth.user);
    const isBrand = userType?.includes('brand');
    const [tabValue, setTabValue] = React.useState(isBrand ? '2fa' : 'password');
    const [state, setState] = useState<IGlobalPreference>({
        isNotificationByEmailRequested: false,
        isNotificationInBrowserRequested: false,
        locale: 'en',
        isUnsubscribed: false,
        is2FARequested: false,
    });
    const {t} = useTranslation();

    useEffect(() => {
        show && UserService.getGlobalPreference()
            .then((data) => {
                setState(data);
            });
    }, [show]);

    const updatePreference = () => {
        UserService.updateGlobalPreference(state)
            .then(data => {
                toast.success('Successfully updated global preference');
                if (closeModal) {
                    closeModal();
                }
            }).catch(error => ErrorToast(error));
    };


    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
        setState(prev => ({...prev, [name]: event}));
    };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };
    const validationPassword = Yup.object().shape({
        currentPassword: Yup.string()
            .min(8, 'Minimum 8 symbols')
            .max(50, 'Maximum 50 symbols')
            .required(`${t('general.validation.required_field')}`),
        password: Yup.string()
            .min(8, 'Minimum 8 symbols')
            .max(50, 'Maximum 50 symbols')
            .required(`${t('general.validation.required_field')}`),
        repeatPassword: Yup.string()
            .min(8, 'Minimum 8 symbols')
            .max(50, 'Maximum 50 symbols')
            .required(`${t('general.validation.required_field')}`)
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });
    const formik = useFormik({
        initialValues,
        validationSchema: validationPassword,
        onSubmit: (values: IChangePassword, {setSubmitting, setStatus, resetForm}) => {
            setTimeout(() => {
                UserService.changePassword(values)
                    .then((data) => {
                        SuccessToast(t('general.toasts.successChangedPassword'));
                        resetForm({});
                        if (closeModal) {
                            closeModal();
                        }
                    }).catch(error => ErrorToast(error));
            }, 500);
        },
    });

    return (
        <>
            <Modal centered show={show} onHide={closeModal}>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header className="pb-0">
                        <Modal.Title>
                            <h4 className="m-0">
                                {t('brand.account.login_and_security')}
                            </h4>
                            <p className="text-muted mb-0">
                                {t('brand.account.login_and_security_desc')}
                            </p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TabContext value={tabValue}>
                            <Box>
                                <TabList centered onChange={handleChange} aria-label="Security tabs">
                                    {isBrand &&
                                        <Tab label="2FA" value="2fa"/>
                                    }
                                    <Tab label={t('general.change_password')} value="password"/>
                                </TabList>
                            </Box>
                            <TabPanel value="2fa">
                                <TwoFactorAuthTab changeHandler={changeHandler} state={state}/>
                            </TabPanel>
                            <TabPanel value="password">
                                <ChangePasswordTab formik={formik}/>
                            </TabPanel>
                        </TabContext>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={closeModal}>
                            {t('general.button.close')}
                        </Button>
                        {tabValue === '2fa' ?
                            <Button variant="primary" onClick={updatePreference}>
                                {t('general.button.save')}
                            </Button>
                            :
                            <Button variant="primary" type="submit" disabled={formik.isSubmitting}>
                                {t('general.button.save')}
                            </Button>
                        }
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default LoginAndSecurityModal;
