import React from "react";
import {createTheme, ThemeProvider} from "@material-ui/core";

export const theme = createTheme(
    /**
     * @see https://material-ui.com/customization/themes/#theme-configuration-variables
     */
    {
        typography: {
            fontFamily: ["Rubik"].join(","),
        },
        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                main: "#05BBC9",
                dark: "#2E3033",
                // dark: will be calculated from palette.primary.main,
                // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
            },
            secondary: {
                // light: will be calculated from palette.primary.main,
                main: "#97A5A8",
                // dark: will be calculated from palette.primary.main,
                // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
            },
            error: {
                // light: will be calculated from palette.primary.main,
                main: "#ED4052",
                // dark: will be calculated from palette.primary.main,
                // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
            },
        },
        overrides: {
            MuiTab: {
                root: {
                    color: "primary",
                    backgroundColor: "primary",
                    borderColor: "primary",
                },
            },
        },
    }
);

theme.overrides = {
    MuiInputLabel: {
        root: {
            lineHeight: "0.8rem",
            fontSize: "14px",
        },
    },
    MuiInput: {
        root: {
            borderRadius: 7,
        },
    },
    MuiOutlinedInput: {
        root: {
            borderRadius: 7,
            padding: "9px",
            paddingRight: "0px",
            backgroundColor: "white",
            minHeight: 37,
            "&&& $input": {
                padding: "0px 5px",
            },
        },
        focused: {
            borderColor: "primary ",
        },
    },
    MuiTabs: {
        root: {
            fontFamily: "inherit",
        },
        scroller: {
            display: "flex",
        },
        indicator: {
            background: "primary",
        },
    },
};

export function MaterialThemeProvider(props: any) {
    const {children} = props;

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
