import {Card, Col, Row} from 'react-bootstrap';
import React from 'react';
import {IoStarSharp} from 'react-icons/io5';
import {ReviewComment} from './ReviewComment';
import {IReviewsReportItems} from '../../../../../../../../model/book/Book';
import {CustomImageHandler} from '../../../../../../../../shared/components/CustomImageHandler';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../../store/rootReducer';
import {formatDate, truncateString} from '../../../../../../../../shared/functions/Functions';
import {IPicture} from '../../../../../../../../model/shared/IPicture';

interface IFeedbackCardProps {
    brandLogo: Partial<IPicture>;
    brandName: string;
    campaignName: string;
    reviewScore: string;
    reviewText: string;
    influencerName: string;
    influencerFeedback: string;
    influencerPhoto: string;
    influencerComment: string;
    createdAt: string;
    hasReply: boolean;
    isContentAttached: boolean;
    campaignId: number;
    reportItems: IReviewsReportItems[];
}

export const FeedbackCard = ({
                                 brandLogo,
                                 brandName,
                                 campaignName,
                                 influencerComment,
                                 reviewScore,
                                 reviewText,
                                 createdAt,
                                 hasReply,
                                 reportItems,
                                 influencerName,
                                 isContentAttached,
                                 influencerFeedback,
                                 influencerPhoto,
                                 campaignId,
                             }: IFeedbackCardProps) => {
    const {user: {userType}} = useSelector((state: IAllStates) => state.auth);
    return (
        <Card className="my-3">
            <Card.Body>
                <Row>
                    <Col xs={3} md={1} className="pl-0">
                        <CustomImageHandler
                            altTag="Brand logo"
                            classes="rounded-img-sm"
                            photoPath={brandLogo?.path ?? null}
                        />
                    </Col>
                    <Col xs={9} md={11}>
                        <h6>{brandName}</h6>
                        <div className="d-flex justify-content-between">
                            <div className="word-break">
                                <span className="text-primary pr-3">{createdAt}</span>
                                <span
                                    className="text-muted font-14 font-weight-bold text-decoration-none"
                                >
                                    {truncateString(campaignName, 30)}
                                </span>
                            </div>
                            <span className="font-weight-bold d-flex align-items-center justify-content-center">
                                {reviewScore}
                                <IoStarSharp className="text-primary rating-icon-bg ml-2"/>
                            </span>
                        </div>
                        <p className="my-3">{reviewText}</p>
                        <Row>
                            {isContentAttached && reportItems?.map((item: IReviewsReportItems) => {
                                return (
                                    <React.Fragment key={item?.id}>
                                        {item?.relatedFeedItem?.postImages?.map((reportImage) => {
                                            return (
                                                <Col sm={4} lg={3} key={reportImage?.id}>
                                                    <CustomImageHandler
                                                        altTag="Review logo"
                                                        classes="review-image"
                                                        thumbnailPath={reportImage?.thumbnail}
                                                        photoPath={reportImage?.path}
                                                    />
                                                </Col>
                                            );
                                        })}
                                    </React.Fragment>
                                );
                            })}
                        </Row>
                    </Col>
                </Row>
                <div className="pl-5">
                    {hasReply && (
                        <ReviewComment
                            influencerPhoto={influencerPhoto}
                            influencerName={influencerName}
                            influencerComment={formatDate(influencerComment)}
                            influencerFeedback={influencerFeedback}
                        />
                    )}
                </div>
            </Card.Body>
        </Card>
    );
};
