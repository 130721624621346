import React from 'react';
import {Card, Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {numberFormatter} from '../../../../../../../../utils/helpers/numberFormater';
import {IPlatformDataArray} from '../../../dto/IAudienceDataModal';

interface IProps {
    getCorrectIconName: (iconKey: string) => string;
    audienceData?: IPlatformDataArray;
}

const AudienceModashData = ({getCorrectIconName, audienceData}: IProps) => {
    const {t} = useTranslation();

    const showCard = (arrayLength: number | undefined) => {
        return !!arrayLength;
    }
    return (
        <>
            {showCard(audienceData?.statistic?.data?.audience?.credibility) &&
                <Col xs={6} md={2} className="pl-md-0 mt-3 custom-col-sm-6">
                    <Card className="h-100">
                        <Card.Body className="text-center">
                            <i className={`${getCorrectIconName('credibility')} mb-1 text-primary font-20 d-flex justify-content-center`}/>
                            <h5 className="mb-0">
                                {((audienceData?.statistic?.data?.audience?.credibility ?? 0) * 100).toFixed(2) ?? 'n.a.'} %
                            </h5>
                            {t(`general.statistics.credibility`)}
                        </Card.Body>
                    </Card>
                </Col>}
            {showCard(audienceData?.statistic?.data?.audienceLikers?.credibility) &&
                <Col xs={6} md={2} className="pl-md-0 mt-3 custom-col-sm-6">
                    <Card className="h-100">
                        <Card.Body className="text-center">
                            <i className={`${getCorrectIconName('likersCredibility')} mb-1 text-primary font-20 d-flex justify-content-center`}/>
                            <h5 className="mb-0">
                                {((audienceData?.statistic?.data?.audienceLikers?.credibility ?? 0) * 100).toFixed(2) ?? 'n.a.'} %
                            </h5>
                            {t(`general.statistics.likersCredibility`)}
                        </Card.Body>
                    </Card>
                </Col>}
            {/* separator  */}
            <Col md={12}/>
            {/* separator end */}
            {showCard(audienceData?.statistic?.data?.audience?.brandAffinity?.length) &&
                <Col md={4} className="pl-md-0 mt-3 custom-col-sm-6">
                    <Card className="h-100">
                        <Card.Body>
                            <h6>
                                {t(`general.statistics.brandAffinity`)}
                            </h6>
                            {audienceData?.statistic?.data?.audience?.brandAffinity?.slice(0, 5)?.map(affinity => {
                                return (
                                    <div key={affinity?.name}
                                         className="d-flex align-items-center justify-content-between">
                                        <div>
                                            {affinity?.name}
                                        </div>
                                        <div>
                                            {(affinity.weight * 100)?.toFixed(2)} %
                                        </div>
                                    </div>
                                )
                            })}

                        </Card.Body>
                    </Card>
                </Col>}
            {showCard(audienceData?.statistic?.data?.audience?.interests?.length) &&
                <Col md={4} className="pl-md-0 mt-3 custom-col-sm-6">
                    <Card className="h-100">
                        <Card.Body>
                            <h6>
                                {t(`general.statistics.followerInterests`)}
                            </h6>
                            {audienceData?.statistic?.data?.audience?.interests?.slice(0, 5)?.map(affinity => {
                                return (
                                    <div key={affinity?.name}
                                         className="d-flex align-items-center justify-content-between">
                                        <div>
                                            {affinity?.name}
                                        </div>
                                        <div>
                                            {(affinity.weight * 100)?.toFixed(2)} %
                                        </div>
                                    </div>
                                )
                            })}

                        </Card.Body>
                    </Card>
                </Col>}
            {showCard(audienceData?.statistic?.data?.audienceLikers?.brandAffinity?.length) &&
                <Col md={4} className="pl-md-0 mt-3 custom-col-sm-6">
                    <Card className="h-100">
                        <Card.Body>
                            <h6>
                                {t(`general.statistics.likersBrandAffinity`)}
                            </h6>
                            {audienceData?.statistic?.data?.audienceLikers?.brandAffinity?.slice(0, 5)?.map(affinity => {
                                return (
                                    <div key={affinity?.name}
                                         className="d-flex align-items-center justify-content-between">
                                        <div>
                                            {affinity?.name}
                                        </div>
                                        <div>
                                            {(affinity.weight * 100)?.toFixed(2)} %
                                        </div>
                                    </div>
                                )
                            })}

                        </Card.Body>
                    </Card>
                </Col>}
            {showCard(audienceData?.statistic?.data?.audienceLikers?.interests?.length) &&
                <Col md={4} className="pl-md-0 mt-3 custom-col-sm-6">
                    <Card className="h-100">
                        <Card.Body>
                            <h6>
                                {t(`general.statistics.likersInterests`)}
                            </h6>
                            {audienceData?.statistic?.data?.audienceLikers?.interests?.slice(0, 5)?.map(affinity => {
                                return (
                                    <div key={affinity?.name}
                                         className="d-flex align-items-center justify-content-between">
                                        <div>
                                            {affinity?.name}
                                        </div>
                                        <div>
                                            {(affinity.weight * 100)?.toFixed(2)} %
                                        </div>
                                    </div>
                                )
                            })}

                        </Card.Body>
                    </Card>
                </Col>}
            {showCard(audienceData?.statistic?.data?.audience?.audienceLookalikes?.length) &&
                <Col md={4} className="pl-md-0 mt-3 custom-col-sm-6">
                    <Card className="h-100">
                        <Card.Body>
                            <h6>
                                {t(`general.statistics.audienceLookalikes`)}
                            </h6>
                            {audienceData?.statistic?.data?.audience?.audienceLookalikes?.slice(0, 5)?.map(follower => {
                                return (
                                    <div key={follower?.userId}
                                         className="d-flex align-items-center justify-content-between">
                                        <div>
                                            <a href={follower.url} className="hover-effect text-dark" target="_blank"
                                               rel="noreferrer">
                                                @{follower?.username}
                                            </a>
                                        </div>
                                        <div>
                                            {numberFormatter(follower?.followers)}
                                        </div>
                                    </div>
                                )
                            })}

                        </Card.Body>
                    </Card>
                </Col>}
            {showCard(audienceData?.statistic?.data?.hashtags?.length) &&
                <Col md={4} className="pl-md-0 mt-3 custom-col-sm-6">
                    <Card className="h-100">
                        <Card.Body>
                            <h6>
                                {t(`general.statistics.hashtags`)}
                            </h6>
                            {audienceData?.statistic?.data?.hashtags?.slice(0, 5)?.map(follower => {
                                return (
                                    <div key={follower?.tag}
                                         className="d-flex align-items-center justify-content-between">
                                        <div>
                                            #{follower.tag}
                                        </div>
                                        <div>
                                            {((follower.weight ?? 0) * 100).toFixed(2)} %
                                        </div>
                                    </div>
                                )
                            })}

                        </Card.Body>
                    </Card>
                </Col>}
            {showCard(audienceData?.statistic?.data?.audienceLikers?.interests?.length) &&
                <Col md={4} className="pl-md-0 mt-3 custom-col-sm-6">
                    <Card className="h-100">
                        <Card.Body>
                            <h6>
                                {t(`general.statistics.audienceInterests`)}
                            </h6>
                            {audienceData?.statistic?.data?.audienceLikers?.interests?.slice(0, 5)?.map(interest => {
                                return (
                                    <div key={interest?.name}
                                         className="d-flex align-items-center justify-content-between">
                                        <div>
                                            {interest.name}
                                        </div>
                                        <div>
                                            {((interest.weight ?? 0) * 100).toFixed(2)} %
                                        </div>
                                    </div>
                                )
                            })}
                        </Card.Body>
                    </Card>
                </Col>}
            {showCard(audienceData?.statistic?.data?.mentions?.length) &&
                <Col md={4} className="pl-md-0 mt-3 custom-col-sm-6">
                    <Card className="h-100">
                        <Card.Body>
                            <h6>
                                @ {t(`general.statistics.popular_mention`)}
                            </h6>
                            {audienceData?.statistic?.data?.mentions?.slice(0, 5)?.map(mention => {
                                return (
                                    <div key={mention?.tag}
                                         className="d-flex align-items-center justify-content-between">
                                        <div>
                                            @{mention.tag}
                                        </div>
                                        <div>
                                            {((mention.weight ?? 0) * 100).toFixed(2)} %
                                        </div>
                                    </div>
                                )
                            })}
                        </Card.Body>
                    </Card>
                </Col>
            }
            {showCard(audienceData?.statistic?.data?.audienceLikers?.geoCities?.length) &&
                <Col md={4} className="pl-md-0 mt-3 custom-col-sm-6">
                    <Card className="h-100">
                        <Card.Body>
                            <h6>
                                {t(`general.statistics.follower_cities`)}
                            </h6>
                            {audienceData?.statistic?.data?.audienceLikers?.geoCities?.slice(0, 5)?.map(mention => {
                                return (
                                    <div key={mention?.weight ?? 0}
                                         className="d-flex align-items-center justify-content-between">
                                        <div>
                                            {mention.name}
                                        </div>
                                        <div>
                                            {((mention.weight ?? 0) * 100).toFixed(2)} %
                                        </div>
                                    </div>
                                )
                            })}
                        </Card.Body>
                    </Card>
                </Col>
            }
        </>
    );
};

export default AudienceModashData;
