const termsCondition = (isBriefing: boolean) => {
    return isBriefing ? '' : `
    <div class="d-flex mt-4 " style="gap: 20px">
      <img width="24" height="24" src="/icons/awesome-check-circle.svg" />

      <div class="d-flex align-items-center">
        <p>Creator read everything and i agreed on Terms & Conditions provided by influence.vision gmbh</p>
      </div>
    </div>
  `;
};

export default termsCondition;