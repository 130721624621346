import React from 'react';
import {Button, Card, Col} from 'react-bootstrap';
import {ICompanyUser} from '../dto/ICompanyUser';
import {useTranslation} from 'react-i18next';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {UserRoles} from '../../../../enum/UserRoles';

interface IUserAndRoles {
    companyUser: ICompanyUser;
    disableUser: (id: string) => void;
    deleteUserAction: (companyUser?: ICompanyUser) => void;
    editUser: (companyUser?: ICompanyUser) => void;
}

export function UserAndRolesCard({
                                     companyUser,
                                     disableUser,
                                     deleteUserAction,
                                     editUser,
                                 }: IUserAndRoles) {
    const {t} = useTranslation();
    const isAdmin = [UserRoles.AGENCY_MASTER, UserRoles.BRAND_MASTER].includes(companyUser?.userType ?? UserRoles.BRAND);

    return (
        <Col lg={4}
                className={`mb-4 `}>
            <Card>
                <Card.Body>
                    {isAdmin &&
                        <p className="status pending top-right-icon">Admin</p>
                    }
                    <div className="d-flex align-items-center mb-3">
                        <CustomImageHandler
                            altTag={companyUser?.profilePhoto?.name as string}
                            classes="user-img-rounded-lg"
                            thumbnailPath={companyUser?.profilePhoto?.thumbnail as string}
                            photoPath={companyUser?.profilePhoto?.thumbnail as string}
                        />
                        <div className="ml-3">
                            <h5 className="word-break">
                                {companyUser?.firstName} {companyUser?.lastName}
                            </h5>
                            {companyUser?.position &&
                                <div className="mb-0">{t(`general.positions.${companyUser?.position}`)}</div>}
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>
                            <Button className={`${companyUser?.canBeDeleted ? '' : 'd-none'}`} variant="danger"
                                    size="sm" onClick={() => deleteUserAction(companyUser)}>
                                {t('general.button.delete')}
                            </Button>
                        </div>
                        <div>
                            <button
                                onClick={() => disableUser(companyUser?.id ?? '')}
                                className={`btn btn-sm text-uppercase mr-2 ${
                                    companyUser?.isEnabled
                                        ? 'btn-outline-danger'
                                        : 'btn-outline-secondary'
                                }`}
                            >
                                {companyUser?.isEnabled
                                    ? `${t('general.button.disable')}`
                                    : `${t('general.button.enable')}`}
                            </button>
                            <button
                                onClick={() => editUser(companyUser)}
                                className="btn btn-sm btn-outline-primary"
                            >
                                {t('general.button.edit')}
                            </button>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
}
