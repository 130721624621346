import {IMessageOpenedChat, IMessageThreadQuery} from '../../dto/IMessage';
import {LoadMoreMessages} from '../../../../../../shared/components/message/LoadMoreMessages';
import React, {SetStateAction} from 'react';

export interface IChatProps {
    selectedChatContent: IMessageOpenedChat[];
    chatParams: IMessageThreadQuery;
    setChatParams: React.Dispatch<SetStateAction<IMessageThreadQuery>>;
    selectedChatId: number;
}

export const MainChatBody = ({
                                 selectedChatContent,
                                 chatParams,
                                 setChatParams,
                                 selectedChatId,
                             }: IChatProps) => {
    return (
        <div
            className="border-left p-3 responsive-chat-body-height"
        >
            <LoadMoreMessages messageData={selectedChatContent} setFilterParams={setChatParams} isMessageBubble={false}
                              filterParams={chatParams} keyId={selectedChatId}/>
        </div>
    );
};
