import {createSlice} from '@reduxjs/toolkit';

const initialLoadingSlice: ILoadingSlice = {
    isLoading: false,
}

export interface ILoadingSlice {
    isLoading: boolean;
}

export const loadingSlice = createSlice({
    name: 'loadingSlice',
    initialState: initialLoadingSlice,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
            return state;
        },
    },
});
