import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

interface Props {
    show: boolean;
    closeModal: () => void;
}

const UpgradePlanModal = ({show, closeModal}: Props) => {
    const {t} = useTranslation();
    return (
        <Modal show={show}
               onHide={closeModal}
               centered
        >
            <Modal.Body className="d-flex align-items-center flex-column pb-4">
                <img src="/icons/shooting-star.gif" alt="Shooting star gif icon" className="shooting-star-icon"/>
                <h3 className="text-center text-uppercase mb-0">{t('brand.package.congratulations')}!</h3>
                <h5 className="text-center text-primary font-weight-bold my-2">{t('brand.package.upgratedPackage')}</h5>
                <div className="w-75">
                    <p className="text-muted text-center">
                        {t('brand.package.upgradePackageDesc')}
                    </p>

                </div>
                <Button onClick={closeModal} variant="primary"
                        className="mt-2">{t('general.goToDashboard')}</Button>
            </Modal.Body>
        </Modal>
    );
};

export default UpgradePlanModal;
