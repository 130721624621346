import {FaHandshake} from 'react-icons/fa';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const FindCreatorsInfo = () => {
    const {t} = useTranslation();
    return (
        <>
            <div className="mb-3 text-center">
                <div>
                    <h5>
                        {t('brand.campaign.briefing.overview_card.book_call')}
                    </h5>
                    <p className="text-muted px-4">
                        {t('brand.campaign.briefing.overview_card.found_influencers_for_camp')}
                    </p>
                    <h5>
                        <a className="ml-2" href="https://www.influencevision.com/en/book-a-demo/" rel="noreferrer"
                           target="_blank">{t('brand.campaign.briefing.overview_card.book_demo')}</a>
                    </h5>
                </div>
            </div>
            <div className="my-3 text-center">
                <p className="text-muted">
                    <span className="text-primary mr-2">
                        <FaHandshake color="text-primary"/>
                    </span>
                    {t('brand.campaign.briefing.overview_card.onboarding_new_influencers')}
                </p>
            </div>
        </>
    )
}