import {useEffect, useState} from 'react';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

export const useRecaptcha = () => {
    const {executeRecaptcha, container} = useGoogleReCaptcha();
    const [token, setToken] = useState('');
    useEffect(() => {
        if (!executeRecaptcha) {
            return;
        }
        const handleReCaptchaVerify = async () => {
            const recaptchaToken = await executeRecaptcha('register');
            setToken(recaptchaToken);
        };
        handleReCaptchaVerify();
    }, [executeRecaptcha]);
    return {
        token,
        container,
        setToken,
        executeRecaptcha,
    };
};
