import { ICampaignOverview } from "../../../../model/briefing/overview/ICampaignOverview";
import { IOffer } from "../../../../model/offer/IOffer";
import { formatDate } from "../../../functions/Functions";

const titleContract = (offer: IOffer, campaignOverview: ICampaignOverview) => {
  return `
      <div class="my-4">
        <h2 class="text-center mb-0">${
          campaignOverview?.isCharity
            ? "Barter Deal Contract"
            : "Paid Deal Contract"
        }</h2>
        <p class="text-center">Offer ID: ${offer?.id} accepted on ${formatDate(
            offer?.createdAt
        )}</p>
      </div>
    `;
};

export default titleContract;
