import React from 'react';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {IMyCampaignItem} from '../dto/IMyCampaigns';
import {priceSplitter} from '../../../../utils/helpers/priceSplitter';

export interface IInfluencerCampaignListProps {
    campaign: IMyCampaignItem;
}

export function InfluencerCampaignListItem({campaign}: IInfluencerCampaignListProps) {
    const {title, id, campaignImages, company, offers} = campaign;
    const {t} = useTranslation();
    const history = useHistory();
    const goToOverview = () => {
        history.push(`/influencer/campaign/${id}/overview`);
    };

    return (
        <tr>
            <td className="text-left">
                <div onClick={goToOverview} className="d-flex align-items-center cursor-pointer pr-3">
                    <CustomImageHandler photoPath={campaignImages?.[0]?.thumbnail as string}
                                        altTag={campaignImages?.[0]?.title as string}
                                        classes="mr-2 default-radius img-cover"/>
                    <h6 className="font-weight-bold">{title}</h6>
                </div>
            </td>
            <td className="text-primary font-weight-semi-bold text-left">{company?.displayName ?? '-'}</td>
            <td className="font-weight-semi-bold">{offers?.[0]?.totalPostings ?? 0}</td>
            <td className="font-weight-semi-bold euro-symbol"> {priceSplitter(Math.round(offers?.[0]?.price ?? 0))}</td>
            <td>
                <span className={`status ${offers?.[0]?.status}`}>
                {t(`general.offerStatus.${offers?.[0]?.status}`)}
                </span>
            </td>
            <td>
                <Button onClick={goToOverview} className="mx-3" size="sm">
                    {t('general.open')}
                </Button>
            </td>
        </tr>
    );
}
