import React, {SetStateAction, useEffect, useState} from 'react';
import {Card} from 'react-bootstrap';
import PlannerFilters from './PlannerFilters';
import PlannerCalendar from './PlannerCaledar';
import {getCorrectColor, getCorrectFilterTitle} from '../../../../shared/functions/Functions';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import {useTranslation} from 'react-i18next';
import {BiRefresh} from 'react-icons/bi';
import {BsExclamationOctagon} from 'react-icons/bs';
import {ISelectedYearMonth} from '../index';

interface IPlannerCalendarCardProps {
    selectedCalendarView: 'dayGridMonth' | 'dayGridWeek' | 'listWeek';
    setSelectedCalendarView: React.Dispatch<SetStateAction<'dayGridMonth' | 'dayGridWeek' | 'listWeek'>>;
    setSelectedYearMonth: React.Dispatch<SetStateAction<ISelectedYearMonth>>;
    selectedYearMonth: ISelectedYearMonth;
    publishFrom?: string;
}

const PlannerCalendarCard = ({
                                 setSelectedYearMonth,
                                 selectedYearMonth,
                                 publishFrom,
                                 selectedCalendarView,
                                 setSelectedCalendarView
                             }: IPlannerCalendarCardProps) => {
    const {t} = useTranslation();
    const {offerItemEventsEnum} = useSelector((state: IAllStates) => state.enums);
    const [isToDoSelected, setIsToDoSelected] = useState(false)
    const [isCreateModalOpened, setIsCreateModalOpened] = useState(false);

    return (
        <>
            <Card>
                <Card.Body>
                    <PlannerFilters selectedCalendarView={selectedCalendarView}
                                    setIsToDoSelected={setIsToDoSelected}
                                    isToDoSelected={isToDoSelected}
                                    setIsCreateModalOpened={setIsCreateModalOpened}/>
                    <PlannerCalendar isCreateModalOpened={isCreateModalOpened}
                                     setSelectedCalendarView={setSelectedCalendarView}
                                     isToDoSelected={isToDoSelected}
                                     selectedYearMonth={selectedYearMonth}
                                     setSelectedYearMonth={setSelectedYearMonth}
                                     selectedCalendarView={selectedCalendarView}
                                     setIsCreateModalOpened={setIsCreateModalOpened}
                                     publishFrom={publishFrom}/>
                </Card.Body>
            </Card>
            <div className="d-flex justify-content-md-end flex-wrap my-3">
                {offerItemEventsEnum?.map(offerItem => {
                    const statusesWithIcon = ['proposed'];
                    return (
                        <React.Fragment key={offerItem}>
                            {statusesWithIcon?.includes(offerItem) ?
                                <div className="m-1 d-flex align-items-center">
                                    {offerItem === 'proposed' ? <BsExclamationOctagon className="proposed-icon"/> :
                                        <BiRefresh className="proposed-icon text-dark"/>}
                                    <span className="ml-1">
                                        {getCorrectFilterTitle(offerItem, t)}
                                    </span>
                                </div>
                                : <span key={offerItem}
                                        className={`calendar-button-dot m-1 ${getCorrectColor(offerItem)}`}>
                                      {getCorrectFilterTitle(offerItem, t)}</span>
                            }
                        </React.Fragment>
                    );
                })}
            </div>
        </>

    );
};

export default PlannerCalendarCard;
