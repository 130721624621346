import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {InternalRoutes} from '../../../../../../enum/InternalRoutes';
import {IParams} from '../../../../../../model/shared/IParams';

interface IStepperRoutingProps {
    submitStepHandler?: () => void;
    status?: string;
    approvalStatus?: string;
    isNextDisabled?: boolean;
}

export function StepperRouting({
                                   submitStepHandler,
                                   status,
                                   isNextDisabled = false,
                                   approvalStatus,
                               }: IStepperRoutingProps) {
    const [stepperStep, setStepperStep] = useState('');
    const {t} = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const route = location.pathname;
    const {id} = useParams<IParams>();

    const navigateTo = () => {
        if (route.includes('overview')) {
            history.push(`${InternalRoutes.BRAND_CAMPAIGN_DASHBOARD}/${id}`);
        } else {
            history.push(`${InternalRoutes.BRAND_CAMPAIGN_CREATE}/${id}/overview`);
        }
    };

    useEffect(() => {
        const lastElement = route.split('/').pop();
        setStepperStep(lastElement ?? '');
        // eslint-disable-next-line
    }, []);
    const prevStep = () => {
        history.push(`${InternalRoutes.BRAND_CAMPAIGN_CREATE}/${id}/step${+stepperStep[stepperStep.length - 1] - 1}`);
    };

    return (
        <>
            <div className="d-flex flex-wrap justify-content-between my-2 ">
                <Button onClick={navigateTo}
                        size="sm"
                        className="mt-2 mt-md-0"
                        variant="outline-primary">
                    {t('general.button.cancel')}
                </Button>
                <div className=" mt-md-0 d-flex mt-2">
                    <Button onClick={prevStep}
                            size="sm"
                            variant="outline-primary"
                            className={`mr-2 ${(stepperStep === 'step1' || stepperStep === 'overview') && 'd-none'}`}
                    >
                        {t('general.button.back')}
                    </Button>
                    {stepperStep === 'overview'
                        ? <Button className="w-100" size="sm"
                            // disabled={status === 'published' && approvalStatus !== 'rejected'}
                                  onClick={() => {
                                      submitStepHandler && submitStepHandler();
                                  }}
                                  variant="primary"
                        >
                            {t('general.publish_campaign')}
                        </Button>
                        :
                        <Button className="w-100" onClick={() => {
                            submitStepHandler && submitStepHandler();
                        }
                        }
                                disabled={isNextDisabled}
                                size="sm"
                                variant="primary"
                        >
                            {t('general.button.continue')}
                        </Button>
                    }
                </div>
            </div>
        </>
    );
}
