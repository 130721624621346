import React, {useState} from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {priceSplitter} from '../../../../../utils/helpers/priceSplitter';
import {Checkbox} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

interface IConfirmationModalProps {
    show: boolean;
    closeModal: () => void;
    grandTotal?: string | 0;
    nbPosts?: number;
    action?: any;
}

export function InfluencerAcceptOfferModal({show, closeModal, grandTotal, nbPosts, action}: IConfirmationModalProps) {
    const [isRequired, setIsRequired] = useState(false);
    const {t} = useTranslation();
    // const totalPlatformSum = offer?.totalPlatforms?.reduce((acc, item) => acc + (item.nbPostsTotal), 0);
    const confirmSending = () => {
        if (isRequired) {
            action();
            closeModal();
        }
    };
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {checked} = event.target;
        setIsRequired(checked);
    };

    return(
        <>
            <Modal show={show}
                   onHide={closeModal}
                   className="modal-dark"
                   size={undefined}
                   centered={true}
            >
                <Modal.Body>
                    <div className="mt-4 d-flex align-items-center justify-content-center flex-column">
                        <h4 className="text-primary text-center">{t('influencer.offer_management.modal.accept_offer_title')}</h4>
                    </div>
                    <div className="text-center">
                        <p className="text-muted">
                            { t('influencer.offer_management.modal.accept_offer_desc')}
                        </p>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="d-flex align-items-center mr-1">
                                    <h6 className="text-primary mr-1"> {nbPosts}</h6>
                                    <h6>{t('brand.campaign.book.offer.content_pieces')}</h6>
                                </div>
                                <h6 className="euro-symbol euro-symbol text-primary">
                                    {grandTotal}
                                </h6>
                            </div>
                        <p className="mt-2">
                            {t('influencer.offer_management.modal.accept_offer_reminder')}
                        </p>
                    </div>
                    <div className="d-flex align-items-center mt-3">
                        <Checkbox className="mb-0" required id="checkbox" onChange={changeHandler} color="primary"
                                  checked={isRequired}/>
                        <label htmlFor="checkbox" className={`text-left cursor-pointer mb-0 text-muted`}
                               dangerouslySetInnerHTML={{__html: t('influencer.offer_management.modal.accept_offer_terms')}}/>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button onClick={closeModal} variant="outline-primary" className="btn-sm mx-2">
                        {t('general.button.cancel')}
                    </Button>
                    <Button disabled={!isRequired} variant="primary" className="btn-sm mx-2" onClick={confirmSending}>
                        {t('general.button.accept')}
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}
