import React, {useEffect, useState} from 'react';
import {ICampaignOverview} from '../../../../model/briefing/overview/ICampaignOverview';
import {useParams} from 'react-router-dom';
import {IParams} from '../../../../model/shared/IParams';
import {useTranslation} from 'react-i18next';
import {Card, Col, Row} from 'react-bootstrap';
import {AgencyService} from '../../service';
import {ErrorToast} from '../../../../utils/toasters';
import {AgencyBriefingDetailCard} from './components/AgencyBriefingDetailCard';
import {BriefingDetailLeftCard} from '../../../influencer/campaign/briefing/components/BriefingDetailLeftCard';
import {rootNavigateWithState} from '../../../../shared/functions/Functions';
import {IconMeasure, MessagesIcon, PlannerIcon, ReportingIcon} from '../../../../shared/Icons';
import {BackButton} from '../../../../shared/components/BackButton';
import {User} from '../../../../model/user/User';
import {setSelectMatchedUser} from '../../../../store/agency/agencyOfferSlice';
import {useDispatch} from 'react-redux';
import {AgencyInfluencerOfferModal} from '../../offer/modal';
import {AcceptedOfferCard} from './components/AcceptedOfferCard';

export function AgencyBriefingDetails() {
    const [currentInformations, setCurrentInformations] = useState<ICampaignOverview>();
    const [updeteState, setUpdateSate] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const {id} = useParams<IParams>();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        AgencyService.getBriefingDetails(id)
            .then((response) => {
                setCurrentInformations(response);
            }).catch((error) => ErrorToast(error));
    }, [updeteState]);

    const openOfferModal = (user?: User) => {
        dispatch(setSelectMatchedUser(user));
        setShowModal(true);
    };

    return (
        <>
            <AgencyInfluencerOfferModal show={showModal}
                                        setShowModal={setShowModal}
                                        updateList={setUpdateSate}
                                        campaignId={id}/>
            {currentInformations?.offers.some(item => item.status === 'accepted' || item.status === 'completed') &&
                <>
                    <Row className="mb-2 mb-md-0 d-md-flex d-none">
                        <Col xl={4} md={2} xs={12} className="mb-3 text-left d-none d-md-block">
                            <BackButton/>
                            <h6 className="mt-2">{t('agency.general_briefing')}</h6>
                        </Col>
                        <Col xl={3} md={3}>
                            <Card className="mb-2 mb-md-4 cursor-pointer"
                                  onClick={() => rootNavigateWithState('/agency/planner', currentInformations?.id)}>
                                <Card.Body>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h6 className="d-flex align-items-center text-muted mb-0">
                                            <PlannerIcon width="20" height="20" color="#9ea5ab" classes="mr-2"/>
                                            <div className="mr-md-3">{t('general.tabs.planner')}</div>
                                        </h6>
                                        <div className="d-flex justify-content-around w-100">
                                            <div>
                                                <h4 className="text-primary mb-0">{currentInformations?.briefingHeader?.plannerTodo}</h4>
                                                <span>
                                            {t('general.todo')}
                                          </span>
                                            </div>
                                            <div>
                                                <h4 className="info-color mb-0">{currentInformations?.briefingHeader?.plannerDone}</h4>
                                                <span>
                                            {t('general.done')}
                                          </span>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col id="padding-right-mobile" xl={3} md={3} xs={6}>
                            <Card className="cursor-pointer"
                                  onClick={() => rootNavigateWithState('/agency/reporting', currentInformations?.id)}>
                                <Card.Body>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h6 className="d-flex align-items-center text-muted mb-0">
                                            <IconMeasure width="20" height="20" color="#9ea5ab" classes="mr-2"/>
                                            <div>{t('general.reports')}</div>
                                        </h6>
                                        <div className="d-flex justify-content-around w-100">
                                            <div>
                                                <h4 className="text-primary mb-0">{currentInformations?.briefingHeader?.reportsTodo}</h4>
                                                <span>
                                            {t('general.todo')}
                                          </span>
                                            </div>
                                            <div>
                                                <h4 className="info-color mb-0">{currentInformations?.briefingHeader?.reportsDone}</h4>
                                                <span>
                                            {t('general.done')}
                                          </span>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col id="padding-left-mobile" xl={2} md={3} xs={6}>
                            <Card className="cursor-pointer"
                                  onClick={() => rootNavigateWithState('/agency/messages', currentInformations?.id)}>
                                <Card.Body>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h6 className="d-flex align-items-center text-muted mb-0">
                                            <MessagesIcon width="20" height="20" color="#9ea5ab" classes="mr-2"/>
                                            <div>{t('influencer.campaign.tabs.messages')}</div>
                                        </h6>
                                        <div>
                                            <h4 className="text-primary mb-0">{currentInformations?.briefingHeader?.messagesCount}</h4>
                                            <span>
                                        {t('general.button.new')}
                                      </span>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {/*MOBILE HEADER CARDS*/}
                    <h6 className="mt-2 d-md-none">{t('agency.general_briefing')}</h6>
                    <div className="d-flex align-items-center justify-content-between mb-3 d-md-none">
                        <Card onClick={() => rootNavigateWithState('/agency/planner', currentInformations?.id)}>
                            <Card.Body>
                                <div className="d-flex align-items-center">
                                    <PlannerIcon width="15" height="15"/>
                                    <span className="font-weight-bold text-muted ml-1">
                                {t('influencer.campaign.tabs.planner')}
                              </span>
                                </div>
                                <div className="d-flex align-items-center mt-2">
                                    <h4 className="text-primary mb-0">{currentInformations?.briefingHeader?.plannerDone ?? 0}</h4>
                                    <span
                                        className="font-weight-bold">/{currentInformations?.briefingHeader?.plannerTodo ?? 0}</span>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card className="mx-2"
                              onClick={() => rootNavigateWithState('/agency/reporting', currentInformations?.id)}>
                            <Card.Body>
                                <div className="d-flex align-items-center">
                                    <ReportingIcon width="15" height="15"/>
                                    <span className="font-weight-bold text-muted ml-1">
                                {t('influencer.campaign.tabs.reporting')}
                              </span>
                                </div>
                                <div className="d-flex align-items-center mt-2">
                                    <h4 className="text-primary mb-0">{currentInformations?.briefingHeader?.reportsTodo ?? 0}</h4>
                                    <span
                                        className="font-weight-bold">/{currentInformations?.briefingHeader?.reportsDone ?? 0}</span>
                                </div>
                            </Card.Body>
                        </Card>

                        <Card onClick={() => rootNavigateWithState('/agency/messages', currentInformations?.id)}>
                            <Card.Body>
                                <div className="d-flex align-items-center">
                                    <MessagesIcon width="15" height="15"/>
                                    <span className="font-weight-bold text-muted ml-1">
                            {t('influencer.campaign.tabs.messages')}
                        </span>
                                </div>
                                <div className="d-flex align-items-center mt-2">
                                    <h4 className="text-primary mb-0">{currentInformations?.briefingHeader?.messagesCount ?? 0}{' '}</h4>
                                    <span className="font-weight-bold"> new</span>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </>
            }
            <Row className="flex-column-reverse flex-md-row">
                <Col md={8}>
                    <BriefingDetailLeftCard currentInformations={currentInformations}/>
                </Col>
                <Col md={4}>
                    <AgencyBriefingDetailCard
                        currentInformations={currentInformations}
                        openInitialOffer={openOfferModal}
                    />
                    {!!currentInformations?.offers.length &&
                        <div style={{maxHeight: '350px'}} className="my-3 overflow-auto">
                            <h6 className="border-bottom pb-2 mb-3">{t('agency.accepted_contracts')}</h6>
                            {currentInformations?.offers?.map(offer => (
                                <AcceptedOfferCard key={offer.id} offer={offer} openOfferAction={openOfferModal}/>
                            ))}
                        </div>
                    }
                    {!!currentInformations?.negativeOffers?.length &&
                        <div style={{maxHeight: '350px'}} className="my-3 overflow-auto">
                            <h6 className="border-bottom pb-2 mb-3">{t('agency.declined_contracts')}</h6>
                            {currentInformations?.negativeOffers?.map(offer => (
                                <AcceptedOfferCard key={offer.id} offer={offer} openOfferAction={openOfferModal}/>
                            ))}
                        </div>
                    }
                </Col>
            </Row>
        </>
    );
}
