import React from 'react';
import {Redirect, Route, Switch} from 'react-router';
import {Login} from './login/Login';
import {RegisterBrand} from './register/brand';
import {RegisterEmployee} from './register/employee';
import {RegisterAgency} from './register/agency';
import {InfluencerRegisterPage} from './register/influencer/InfluencerRegisterPage';
import ForgotPassword from './forgot';
import {shallowEqual, useSelector} from 'react-redux';
import {IAllStates} from '../store/rootReducer';
import {VerificationPage} from './verification/VerificationPage';

export function AuthRoutes() {
    const {isAuthorized} = useSelector((state: IAllStates) => ({
        isAuthorized: !!state.auth.user,
    }), shallowEqual);

    return (
        <Switch>
            <Route path="/brand/register/:stepId" component={RegisterBrand}/>
            <Route path="/brand/employee/register/:stepId" component={RegisterEmployee}/>
            <Route path="/agency/register/step/:stepId" component={RegisterAgency}/>
            <Redirect to="/agency/register/step/1" from="/influencer/agency/register/1"/>
            <Route path="/influencer/register/step/:stepId" component={InfluencerRegisterPage}/>
            <Route path="/auth/password/reset" component={ForgotPassword}/>
            {isAuthorized &&
                <Route path="/verification-page/:id?" component={VerificationPage}/>
            }
            <Route path="/" component={Login}/>
        </Switch>
    );
}
