import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface ITextEditorProps {
    state?: string;
    hasError?: boolean;
    changeTextFieldHandler?: (value: string) => void;
    classes?: string;
    placeholder?: string;
}

export default function TextEditor({placeholder, changeTextFieldHandler, state, hasError = false, classes}: ITextEditorProps) {
    const modules = {
        toolbar: [
          ['bold', 'italic', 'underline'],
          [{'list': 'ordered'}, {'list': 'bullet'}],
          ['link'],
        ],
      }
    return (
        <ReactQuill theme="snow" className={`${classes} ${hasError ? 'border-danger' : ''}`} value={state}
                    modules={modules}
                    placeholder={placeholder}
                    onChange={changeTextFieldHandler}/>
    );
}
