import moment from 'moment';
import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BsWhatsapp} from 'react-icons/bs';

interface ISupportWidgetProps {
    isForMobile?: boolean;
    classes?: string;
}

export function SupportWidget({isForMobile = false, classes}: ISupportWidgetProps) {
    const [showSupportModal, setShowSupportModal] = useState(false);
    const {t} = useTranslation();
    return (
        <>
            <img onClick={() => setShowSupportModal(true)}
                 alt="Messages-messages"
                 width={40}
                 height={40}
                 className={`cursor pointer ${isForMobile ? 'd-md-none box-shadow default-radius' : 'd-md-block d-none'} ${classes}`}
                 src="/icons/support.png"
                 onMouseEnter={e => (e.currentTarget.src = '/icons/support-active.png')}
                 onMouseOut={e => (e.currentTarget.src = '/icons/support.png')}
            />
            <Modal show={showSupportModal}
                   className="support-modal"
                   onHide={() => setShowSupportModal(false)}
                   centered
            >
                <Modal.Body>
                    <div className="d-flex align-items-start">
                        <img src="/tempAssets/Armin.png" className="user-img-rounded mr-2" alt="Support profile"/>
                        <div className="message-bubble">
                            {t('general.contactUsSupport')}
                            <br/>
                            <div className="text-end text-muted">{moment(new Date()).format('HH:mm')}</div>
                        </div>
                    </div>
                    <div className="text-center mt-3">
                        <a href="https://wa.me/436703083371" target="_bank">
                            <button className="wa-button d-flex align-items-center justify-content-center">
                                <BsWhatsapp size={20}/>
                                <span className="ml-1">Start Chat</span>
                            </button>
                        </a>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
