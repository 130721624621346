import ReviewData from './ReviewData';
import {FeedbackCard} from './FeedbackCard';
import {useEffect, useState} from 'react';
import {BookingService} from '../../../../service';
import {ErrorToast} from '../../../../../../../../utils/toasters';
import {Pagination} from '../../../../../../../../shared/components/Pagination';
import {
    IReviewsCount,
    IReviewsData,
} from '../../../../../../../../model/book/Book';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../../store/rootReducer';

export const Reviews = () => {
    const {userId} = useSelector((state: IAllStates) => state.offerBrand);
    const [reviews, setReviews] = useState<IReviewsData>();
    const [totalPages, setTotalPages] = useState(0);
    const [filterParams, setFilterParams] = useState({
        page: 1,
        perPage: 3,
    });

    const handlePages = (updatePage: number) => {
        setFilterParams({...filterParams, page: updatePage});
    };

    useEffect(() => {
        BookingService.getReviewsData(filterParams, userId)
            .then((response) => {
                setReviews(response?.data);
                setTotalPages(Math.ceil(response.count / filterParams.perPage));
            })
            .catch((error) => ErrorToast(error));
        // eslint-disable-next-line
    }, [JSON.stringify(userId), JSON.stringify(filterParams)]);

    return (
        <>
            <ReviewData
                reviewCount={reviews?.info?.reviewCount as IReviewsCount}
                reviewNumber={reviews?.info?.reviewCount?.totalReviews as number}
                overallScore={reviews?.info?.reviewSummary?.avgTotalRating as number}
                communicationScore={reviews?.info?.reviewSummary?.avgCommunication as number}
                deliveryScore={reviews?.info?.reviewSummary?.avgTimeOfDelivery as number}
                qualityScore={reviews?.info?.reviewSummary?.avgContentQuality as number}
            />

            {reviews?.reviews?.map((review) => (
                <FeedbackCard
                    key={review?.id}
                    createdAt={review?.createdAt?.substr(0, 10)}
                    brandLogo={review?.reviewedBy?.profilePhoto}
                    brandName={review?.reviewedBy?.displayName}
                    campaignName={review?.offer?.campaign?.title}
                    reviewScore={review?.totalRating}
                    hasReply={review?.hasReply}
                    reviewText={review?.comment}
                    reportItems={review?.offer?.reportItems}
                    influencerName={review?.user?.displayName}
                    influencerFeedback={review?.reply}
                    influencerPhoto={review?.user?.profilePhoto?.path as string}
                    influencerComment={review?.updatedAt}
                    isContentAttached={review?.isContentAttached}
                    campaignId={review?.offer?.campaign?.id}
                />
            ))}

            {totalPages <= 1 ? (
                ''
            ) : (
                <div className="d-flex justify-content-end">
                    <Pagination
                        page={filterParams.page}
                        totalPages={totalPages}
                        handlePagination={handlePages}
                    />
                </div>
            )}
        </>
    );
};
