import React from 'react';
import {BsExclamationOctagon} from 'react-icons/bs';
import {GoDotFill} from 'react-icons/go';
import {getCorrectStatusBackgroundColor} from '../functions/Functions';

export function RenderEventContent(eventInfo: any) {
    const eventValues = eventInfo?.event?._def?.extendedProps;
    const hasNotifications = !!eventValues?.eventNotifications?.[0]?.isUnread;
    const {status, offerItem: {offer}} = eventValues;
    const isProposed = status === 'proposed';
    const getCorrectIcon = (getStatus: string) => {

        if (getStatus === 'proposed') {
            return <BsExclamationOctagon className="proposed-icon"/>;
        }
        return <span
            className="font-weight-extra-bold">
            <GoDotFill className="display-5" style={{color: getCorrectStatusBackgroundColor(status)}}/>
            </span>;
    };
    return (
        <div className="white-space">
            {getCorrectIcon(status)}
            <span className={(isProposed) ? 'ml-1' : ''}>
                {offer?.campaign?.title ?? '-'}
            </span>
            {hasNotifications && <div className="notification-bullet calendar"/>}
        </div>
    );
}
