import {Card} from 'react-bootstrap';
import React from 'react';
import {IMainPlatforms} from '../../../../../../model/briefing/step1/IMainPlatforms';

interface ICampaignMultiSelectProps {
    item: IMainPlatforms;
    clickHandler?: () => void;
    isActive?: boolean;
    error?: boolean;
    isFilterDisabled?: boolean;
    name?: string;
}

// /icons/categories/${item?.name}.svg
export const CategoryMultiSelect = ({
                                        item,
                                        clickHandler,
                                        error = false,
                                        isActive = false,
                                        isFilterDisabled = false,
                                        name,
                                    }: ICampaignMultiSelectProps) => {
    return (
        <div className={`my-2 mr-2`} onClick={clickHandler}>
            <Card
                className={`py-2 px-4 campaign-multiselect ${isActive ? 'active' : ''} ${isFilterDisabled ? 'contract-1' : ''} ${error ? 'border-danger' : ''}`}>
                <div className="d-flex align-items-center justify-content-center">
                    <img width={25} height={25} src={item?.path} alt={item.name}/>
                    <span
                        className={`ml-2 mb-0 word-break font-weight-semi-bold ${isActive ? 'activeText' : ''}`}>{name ?? item.name}</span>
                </div>
            </Card>
        </div>
    );
};
