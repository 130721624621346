import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../store/rootReducer';
import {appVersionSlice} from '../../store/general/appVersionSlice';

export function AppReloadVersionModal() {
  const {openRefreshModal} = useSelector((state: IAllStates) => state.appVersion);
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const reloadVersion  = () => {
    window.location.reload();
    dispatch(appVersionSlice.actions.setReloadModal(false));
  };
  return (
    <>
      <Modal show={openRefreshModal}
             className="modal-dark"
             size="sm"
             aria-labelledby="contained-modal-title-vcenter"
             centered={true}
      >
        <Modal.Body>
          <p className="text-center">
            {t('general.new_version')}
          </p>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={reloadVersion} variant="primary" className="btn-sm w-100">
              {t(`general.button.refresh_page`)}
            </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
