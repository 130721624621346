import {ProgressBar} from 'react-bootstrap';
import React from 'react';

interface IProgressBarCampaignProps {
    title: string;
    percent: number;
}

export const ProgressBarCampaign = ({title, percent}: IProgressBarCampaignProps) => {
    return (
        <>
            <div className="d-flex align-items-center justify-content-between mb-2">
                <h4 className="font-weight-bold">{title}</h4>
                <h4 className="font-weight-bold text-primary">{percent}%</h4>
            </div>
            <ProgressBar
                variant="primary"
                className="progress-sm w-100 bg-gray"
                now={percent}
                max={100}
            />
        </>
    );
};
