import React from 'react';
import {Col} from 'react-bootstrap';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import {IUsageRightsRequest} from '../../../../../model/user/UsageRights';
import {useTranslation} from 'react-i18next';
import {usageRights} from '../../../../brand/campaign/briefing/components/StepSeven';

interface IUsageRightsProps {
  usageRightsData?: IUsageRightsRequest;
}

export function UsageRights({usageRightsData}: IUsageRightsProps) {
  const {t} = useTranslation();
  return (
    <>
      {usageRightsData?.id ? <>
        <Col md={3}>
          <CustomImageHandler photoPath={usageRights?.find(right => right.key === usageRightsData?.mediaType)?.img!}
                              altTag="Campaign picture"
                              classes="w-100 default-radius img-cover max-h-180px"/>
        </Col>
        <Col md={9}>
          <h5 className="mt-2 mt-md-0">
            {t(usageRights?.find(right => right.key === usageRightsData?.mediaType)?.name ?? '-')}                                        </h5>
          <p>
            {t(usageRights?.find(right => right.key === usageRightsData?.mediaType)?.description ?? '-')}
          </p>
        </Col>
        <Col className="mt-3 " xs={12}>
          <h6 className="text-muted">{t('influencer.offer_management.modal.how_use_content')}</h6>
          <p>
            {usageRightsData?.description ?? '-'}
          </p>
        </Col>
        <Col xs={6}>
          <h6 className="text-muted">{t('influencer.offer_management.modal.rights_duration')}</h6>
          <p>
            {usageRightsData?.duration ? t(`brand.campaign.briefing.step8.${usageRightsData?.duration}`) : '-'}
          </p>
        </Col>
        <Col xs={6}>
          <h6 className="text-muted">{t('influencer.offer_management.modal.rights_location')}</h6>
          <p>
            {!!usageRightsData?.countries?.length ? usageRightsData?.countries
              ?.map((country, index, {length}) => (
                index + 1 === length ?
                  <span key={country?.name}>
                    {`${t(`general.countries.${country?.name}`)}`}
                  </span> :
                  <span key={country?.name}>
                    {`${t(`general.countries.${country?.name}`)}, `}
                  </span>
              )) : '-'}
          </p>
        </Col>
      </> : <Col>-</Col>}

    </>
  );
}
