import React from 'react';
import {Button, Col, Image, Modal, Row} from 'react-bootstrap';
import ExploreHeader from './ExploreHeader';
import {useTranslation} from 'react-i18next';
import {IExploreFilterProps} from './ExploreSort';

interface IExploreFilterModalProps extends IExploreFilterProps {
    showFilterModal: boolean;
    handleClose: () => void;
    isAgencyPage?: boolean;
}

const ExploreFilterModal = ({
                                showFilterModal,
                                handleClose,
                                exploreFilters,
                                setExploreFilters,
                                isAgencyPage,
                                nbPickedOffers,
                            }: IExploreFilterModalProps) => {
    const {t} = useTranslation();
    return (
        <Modal show={showFilterModal} centered onHide={handleClose}>
            <Modal.Body>
                <Row>
                    <Col xs={12}>
                        <div className="d-flex align-items-center mb-3">
                            <Image src="/icons/settings-sliders-gray.svg" alt="filter-icon"/>
                            <h6 className="mb-0 ml-2">{t('influencer.offer_management.filters')}</h6>
                        </div>
                    </Col>
                    <Col xs={12} className="mb-3">
                        <ExploreHeader exploreFilters={exploreFilters} setExploreFilters={setExploreFilters} isAgencyPage={isAgencyPage} nbPickedOffers={nbPickedOffers}/>
                    </Col>
                    <Col xs={12} className="d-flex justify-content-between align-items-center">
                        <Button size="sm" variant="outline-primary" onClick={handleClose}>
                            {t('general.button.close')}
                        </Button>
                        <Button variant="primary" size="sm" onClick={handleClose}>
                            {t('general.button.submit')}
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default ExploreFilterModal;
