import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import {RenderEventContent} from '../../../../shared/components/RenderEventContent';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

export interface IDateClick {
    dateStr: string;
}

export const CustomCalendarJSX = (props: any) => {
    const {t, i18n} = useTranslation();
    const isWeekView = props.selectedCalendarView === 'dayGridWeek';
    const handleNextMonthClick = () => {
        let dateToSend;
        const monthCount = moment(props.selectedMonth).add(1, 'months');
        const weekCount = moment(props.selectedMonth).add(7, 'days');
        dateToSend = isWeekView ? weekCount : monthCount;
        props.handleChangeMonth(moment(dateToSend).month() + 1, moment(dateToSend).year());
        props.setSelectedMonth(moment(dateToSend).valueOf());
    };

    const handlePrevMonthClick = () => {
        let dateToSend;
        const monthCount = moment(props.selectedMonth).subtract('1', 'months');
        const weekCount = moment(props.selectedMonth).subtract('7', 'days');
        dateToSend = isWeekView ? weekCount : monthCount;
        props.handleChangeMonth(moment(dateToSend).month() + 1, moment(dateToSend).year());
        props.setSelectedMonth(moment(dateToSend).valueOf());
    };

    const handleClickToday = () => {
        props.handleChangeMonth(moment(new Date()).month() + 1, moment(new Date()).year());
        props.setSelectedMonth(moment(new Date()).valueOf());
    };
    return (
        <FullCalendar
            locale={i18n.language}
            plugins={[dayGridPlugin, bootstrap5Plugin, listPlugin, interactionPlugin]}
            initialView={props.selectedCalendarView}
            dateClick={props.handleDateClick}
            eventClick={props.handleEventClick}
            themeSystem="bootstrap5"
            initialDate={props.selectedMonth}
            headerToolbar={{
                left: 'prevBtn today nextBtn',
                center: '',
                right: '',
            }}
            customButtons={{
                nextBtn: {
                    text: '',
                    icon: 'chevron-right',
                    click() {
                        handleNextMonthClick();
                    },
                },
                today: {
                    text: t('general.today'),
                    click() {
                        handleClickToday();
                    },
                },
                prevBtn: {
                    text: '',
                    icon: 'chevron-left',
                    click() {
                        handlePrevMonthClick();
                    },
                },
            }}
            firstDay={1}
            timeZone="local"
            initialEvents={props.events}
            dayMaxEvents={2}
            eventContent={RenderEventContent}
        />
    );
};
