import {TextField} from '@material-ui/core';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {BiCopy, BiCopyAlt} from 'react-icons/bi';
import {SuccessToast} from '../../../../../utils/toasters';
import {iManageList} from '../../../../brand/campaign/manage/calendar/dto/IManageCalendar';


interface IPreviewHashTagsProps {
    state: iManageList;
    isBrandSide?: boolean;
}

const PreviewHashTags = ({state, isBrandSide = false}: IPreviewHashTagsProps) => {
    const {t} = useTranslation();
    const [isCopied, setIsCopied] = useState(false);

    const copyHandler = (link: string) => {
        navigator.clipboard.writeText(link ?? ' ');
        SuccessToast(t('general.button.copied'))
    };
    const handleCopyTags = () => {
        navigator.clipboard.writeText(state?.offerItem?.offer?.campaign?.hashtags ?? '');
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 750);
    };
    return (
        <>
            {!isBrandSide && <div className="col-12 mb-4 d-flex align-items-center">
                <TextField
                    name="description"
                    label={t('influencer.planner.trackLink')}
                    value={state?.trackingLink ?? ''}
                    disabled
                    variant="outlined"
                    size="small"
                    className="w-100"
                />
                <BiCopy className="font-22 text-muted cursor-pointer z-index-2" style={{marginLeft: '-2rem'}}
                        onClick={() => {
                            copyHandler(state?.trackingLink);
                        }}/>
            </div>}
            <div className={`col-12 ${!isBrandSide ? 'mb-4' : 'mt-3'}`}>
                <span className="font-weight-semi-bold">{t('influencer.planner.campaignHashtags')} - </span>
                <span className="text-muted">{t('influencer.planner.campaignHashtagsDesc')}</span>
                <div className="mt-2 word-break-word">
                    {(state?.offerItem?.offer?.campaign?.hashtags?.split(' '))?.map((item, i) => <span key={i}
                                                                                                       className={`${i > 0 ?
                                                                                                           'pl-2' : ''}`}>{item}</span>)}
                    {!isCopied ? <BiCopy className="copy-icon ml-1" onClick={handleCopyTags}/> :
                        <span className="text-primary"><BiCopyAlt className="font-22 ml-1"/> Copied</span>}

                </div>
            </div>
            {!!state?.offerItem?.offer?.campaign?.profileTags?.length &&
                <div className={`col-12 ${!isBrandSide ? 'mb-4' : 'mt-3'}`}>
                    <span className="font-weight-semi-bold">Tagging</span>
                    <div className="mt-2 word-break-word">
                        {(state?.offerItem?.offer?.campaign?.profileTags?.split(' '))?.map((item, i) => <span key={i}
                                                                                                              className={`${i > 0 ?
                                                                                                                  'pl-2' : ''}`}>{item}</span>)}
                        <BiCopy className="copy-icon ml-1"
                                onClick={() => copyHandler(state?.offerItem?.offer?.campaign?.profileTags || '')}/>

                    </div>
                </div>}
        </>
    );
};

export default PreviewHashTags;
