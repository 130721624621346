import React from 'react';
import {IBrandedContent} from '../../../../../model/user/IBrandedContent';
import {Col} from 'react-bootstrap';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import {useTranslation} from 'react-i18next';
import {brandedContent} from '../../../../brand/campaign/briefing/components/StepSeven';

interface IBrandedContentProps {
  brandedContentData?: IBrandedContent;
}

export function BrandedContent({brandedContentData}: IBrandedContentProps) {
  const {t} = useTranslation();

  return (
    <>
      {brandedContentData?.id ? <>
        <Col md={3}>
          <CustomImageHandler photoPath={brandedContent?.find(content => content.key === brandedContentData?.goal)?.img!}
                              altTag="Campaign picture"
                              classes="w-100 default-radius img-cover max-h-180px"/>
        </Col>
        <Col md={9}>
          <h5 className="mt-2 mt-md-0">
            {brandedContentData?.goal ?
              t(`brand.campaign.briefing.brandedContent.${brandedContentData?.goal}`) : '-'}
          </h5>
          <p>
            {t(brandedContent?.find(content => content.key === brandedContentData?.goal)?.description ?? '-')}
          </p>
        </Col>
        <Col className="mt-3" xs={12}>
          <h6 className="text-muted">{t('influencer.offer_management.modal.how_use_content')}</h6>
          <p>
            {brandedContentData?.description ?? '-'}
          </p>
        </Col>
        <Col xs={6}>
          <h6 className="text-muted">{t('influencer.offer_management.modal.branded_duration')}</h6>
          <p>
            {brandedContentData?.duration ? t(`brand.campaign.briefing.step8.${brandedContentData?.duration}`) : '-'}
          </p>
        </Col>
        <Col xs={6}>
          <h6 className="text-muted">{t('influencer.offer_management.modal.branded_location')}</h6>
          <p>
            {!!brandedContentData?.countries?.length ? brandedContentData?.countries
              ?.map((country, index, {length}) => (
                index + 1 === length ?
                  <span key={country?.name}>
                    {`${t(`general.countries.${country?.name}`)}`}
                  </span> :
                  <span key={country?.name}>
                    {`${t(`general.countries.${country?.name}`)}, `}
                  </span>
              )) : '-'}
          </p>
        </Col>
      </> : <Col>-</Col>}
    </>
  );
}
