import React from 'react';
import {Button, Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {StarProIcon} from '../../../../shared/Icons';
import {PackagesEnum} from '../../../../enum/PackageEnum';
import {
    getCommissionFee,
    getPackageColor,
    getPackageIcon,
    openCalendlyPopup
} from '../../../../shared/functions/Functions';
import {priceSplitter} from '../../../../utils/helpers/priceSplitter';

export interface IPackageCard {
    id: number;
    packageName: string;
    title: string;
    monthlyPrice?: number;
    annualPrice?: number;
    maxUsers: number;
    teamMembers: number;
    briefing: string;
    fee: number;
    campaignSuccess: string;
    isYearlySubscription?: boolean;
    openOverviewModal: (packageName: string) => void;
    isSelected: boolean;
    isAvailable: boolean;
    keyWord: string;
}

export function CampaignPackageOverviewCard({
                                                packageName,
                                                title,
                                                monthlyPrice,
                                                annualPrice,
                                                maxUsers,
                                                briefing,
                                                fee,
                                                isYearlySubscription,
                                                isAvailable,
                                                isSelected,
                                                openOverviewModal,
                                                campaignSuccess,
                                                teamMembers,
                                                keyWord,
                                            }: IPackageCard) {
    const isProPack = keyWord === PackagesEnum.PRO;
    const isBasicPack = keyWord === PackagesEnum.BASIC;
    const isEnterprise = keyWord === PackagesEnum.ENTERPRISE;
    const {t} = useTranslation();

    return (
        <>
            <Card className='h-100'>
                <Card.Body className="text-left d-flex flex-column">
                    <div className="d-flex justify-content-between align-items-start">
                        {getPackageIcon(keyWord, t)}
                        {isProPack &&
                            <>
                                <p className="status accepted mb-0">{t('brand.package.most_popular')}</p>
                            </>
                        }
                    </div>
                    <h5 className="text-uppercase mb-3 pt-1" dangerouslySetInnerHTML={{__html: t(`${title}`)}}/>
                    <div className={`d-flex text-${getPackageColor(keyWord)} mb-0`}>
                        {!isEnterprise ?
                            <>
                                {annualPrice === 9999 ?
                                    <h5 className="cursor-pointer mb-0"
                                        onClick={() => openCalendlyPopup('https://calendly.com/iv-user-experience/pro-upgrade-en?hide_gdpr_banner=1&primary_color=05bac9&')}>{t('brand.package.contact_us')}</h5> :
                                    <>
                                        <h5 className="mr-1 mb-0">{!isBasicPack && t('brand.package.from')}</h5>
                                        <h5 className={`text-uppercase mb-0 ${!isBasicPack && 'euro-symbol'}`}>
                                            {isBasicPack ? t('brand.package.basic_plan') : priceSplitter(isYearlySubscription ? annualPrice ?? 0 : monthlyPrice ?? 0)}
                                        </h5>
                                    </>
                                }
                            </>
                            : <h5 className="text-uppercase">Let's Chat</h5>
                        }
                    </div>
                    {!isEnterprise &&
                        <p className="text-muted mb-0">
                            {isBasicPack ? t('brand.package.month_fee_basic') : (annualPrice === 9999 ? '-' : (isYearlySubscription ? t('brand.package.year_fee_pro') : t('brand.package.month_fee_pro')))}
                        </p>
                    }
                    <div className='col p-0 d-flex'>
                        <div className="w-100 default-dashed-border my-4 align-self-end"/>
                    </div>
                    <div className="d-flex justify-content-between mb-3">
                        <p className="mb-0 text-uppercase">{t('brand.package.features')}</p>
                        <a onClick={() => openOverviewModal(keyWord)}
                           className="mb-0 text-dark border-bottom cursor-pointer">{t('brand.package.see_all')}</a>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                        <p className="text-muted mb-0">{isBasicPack ? t('brand.package.maximum_users') : t('brand.package.team_members')}</p>
                        <p className="mb-0">{isBasicPack ? maxUsers : (teamMembers === 99 ? t('brand.package.individual') : (teamMembers === 0 ?
                            <i className="fi fi-rr-cross"/> : teamMembers))}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                        <p className="text-muted mb-0">{isBasicPack ? t('brand.package.briefing_no_barter') : t('brand.package.briefing')}</p>
                        <p className="mb-0 text-lowercase">{briefing ? t(`brand.package.${briefing}`) : '-'}</p>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                        <p className="text-muted mb-0">
                            {t(`brand.package.campaign_success`)}
                        </p>
                        <p className="mb-0">
                            {campaignSuccess ? t(`brand.package.${campaignSuccess}`) : '-'}
                        </p>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                        <p className="text-muted mb-0">{t('brand.package.commission_fee')}</p>
                        <p className="mb-0">
                            {fee === 0 ? t('brand.package.no_fixed_payment') : priceSplitter(+getCommissionFee(fee ?? 0)) + '%'}
                        </p>
                    </div>
                    <div className='mt-auto p-0'>
                        <Button
                            onClick={() => openCalendlyPopup('https://calendly.com/iv-user-experience/pro-upgrade-en?hide_gdpr_banner=1&primary_color=05bac9&')}
                            disabled={isSelected || !isAvailable} className="w-100 mt-3 align-self-end"
                            variant={`${(isSelected || !isAvailable) ? 'secondary' : (isProPack ? 'primary' : 'info')}`}>
                            {t(`brand.package.button_${isSelected ? 'subscribed' : (!isAvailable ? 'unavailable' : (isProPack ? 'pro' : 'enterprise'))}`)}
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}
