import {BaseModel} from '../../../../model/shared/BaseModel';
import {Book} from '../../../../model/book/Book';
import {requests} from '../../../../utils';
import {
    BASE_URL,
    BRAND_BOOKING_INVITE_USER,
    BRAND_BOOKING_MODALS,
    BRAND_BOOKING_OFFER_ACCEPT_URL, BRAND_BOOKING_OFFER_CART_URL, BRAND_BOOKING_OFFER_CHECKOUT_URL,
    BRAND_BOOKING_OFFER_DECLINE_URL, BRAND_BOOKING_OFFER_SELECT_URL,
    BRAND_BOOKING_URL, CATEGORY_URL, PLATFORM_URL,
} from '../../../../utils/endpoints';
import {Platforms} from '../../../../model/platform/Platforms';
import {IOfferOverviewCart} from './offer/dto/IOfferOverviewCart';
import {IInviteInfluencer, Invites} from '../../../../model/book/Invites';
import {CampaignCategory} from '../../../../model/campaign/CampaignCategory';
import {IAudienceDataModal} from './offer/dto/IAudienceDataModal';
import {IOffer} from '../../../../model/offer/IOffer';
import {IUpdateOfferRequest} from './offer/dto/IUpdateOffer';
import {IOffersCheckout} from '../../../../model/offer/IOffersCheckout';
import {User} from '../../../../model/user/User';
import {ICampaignOverview} from '../../../../model/briefing/overview/ICampaignOverview';
import {IPastCampaign} from './invite/dto/IPastCampaig';
import {IDeclineOffer} from '../../../influencer/offer/dto/IDeclineOffer';
import {IOfferCommentsRequest} from '../../../../model/offer/IOfferComments';

export const BookingService = {
    getBooking: (campaignId: number, params: any): Promise<BaseModel<Book>> => requests.get(`${BRAND_BOOKING_URL}/${campaignId}`, params),
    declineOffer: (offerId?: number, body?: IDeclineOffer) => requests.put(`${BRAND_BOOKING_OFFER_DECLINE_URL}/${offerId}`, body ?? {}),
    setBestPick: (offerId?: number) => requests.put(`${BRAND_BOOKING_URL}/offers/best-pick/toggle/${offerId}`, {}),
    acceptOffer: (offerId?: number) => requests.put(`${BRAND_BOOKING_OFFER_ACCEPT_URL}/${offerId}`, {}),
    selectOffer: (offerId: number) => requests.put(`${BRAND_BOOKING_OFFER_SELECT_URL}/${offerId}`, {}),
    clearSelectedOffers: (campaignId: string) => requests.delete(`${BRAND_BOOKING_OFFER_SELECT_URL}/clear/${campaignId}`),
    getPlatform: (params: any): Promise<BaseModel<Platforms[]>> => requests.get(PLATFORM_URL, params),
    getOfferCartInfo: (offerId: number): Promise<IOfferOverviewCart> => requests.get(`${BRAND_BOOKING_OFFER_CART_URL}/${offerId}`),
    getInvites: (campaignId: number, params: any): Promise<BaseModel<Invites>> => requests.get(`${BRAND_BOOKING_URL}/invites/${campaignId}`, params),
    getOffersCheckout: (campaignId: string): Promise<IOffersCheckout> => requests.get(`${BRAND_BOOKING_OFFER_CHECKOUT_URL}/${campaignId}`),
    bookSelectedOffer: (campaignId: string): Promise<IOffersCheckout> => requests.put(`${BRAND_BOOKING_OFFER_SELECT_URL}/book/${campaignId}`, {}),
    getCategory: (): Promise<CampaignCategory[]> => requests.get(CATEGORY_URL),
    getAudienceDataModal: (id?: number): Promise<IAudienceDataModal> => requests.get(`${BRAND_BOOKING_MODALS}/audience/${id}`),
    getOffer: (userId?: number, campaignId?: number): Promise<ICampaignOverview> => requests.get(`${BRAND_BOOKING_MODALS}/user/${userId}/campaign/${campaignId}`),
    updateOffer: (id: number, body: IUpdateOfferRequest): Promise<IOffer> => requests.put(`${BRAND_BOOKING_MODALS}/offer/${id}`, body),
    getReviewsData: (body: any, id?: number) => requests.get(`${BRAND_BOOKING_MODALS}/reviews/${id}`, body),
    getInviteContent: (userId?: number): Promise<User> => requests.get(`${BRAND_BOOKING_MODALS}/invite/content/${userId}`),
    inviteUser: (body: IInviteInfluencer, userId?: number, campaignId?: number) => requests.post(`${BRAND_BOOKING_INVITE_USER}/${userId}/campaign/${campaignId}`, body),
    getHistoryOffer: (id?: number): Promise<IOffer[]> => requests.get(`${BRAND_BOOKING_MODALS}/history/${id}`),
    withdrawOffer: (id?: number) => requests.put(`${BRAND_BOOKING_MODALS}/withdraw/${id}`, {}),
    submitOffer: (body: IInviteInfluencer, userId?: number, campaignId?: number) => requests.put(`${BRAND_BOOKING_MODALS}/counter/${userId}/${campaignId}`, body),
    getPastCampaign: (params?: any): Promise<BaseModel<IPastCampaign[]>> => requests.get(`${BRAND_BOOKING_URL}/past/campaigns`),
};
