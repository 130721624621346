import React from 'react';
import {Table} from 'react-bootstrap';
import {Totals} from '../../../../../brand/campaign/book/offer/modal/tabs/offer/Totals';
import {getSubtotalOfferItems} from '../../../../../../shared/functions/Functions';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../store/rootReducer';
import {InitialOfferPlatform} from './InitialOfferPlatform';
import {TotalTypes} from '../../../../../../enum/TotalTypes';

export function InfluencerOfferPlatformTable() {
    const {t} = useTranslation();
    const {initialOffer, campaign, isCharity} = useSelector((state: IAllStates) => state.offerInfluencer);
    return (
        <>
            <div className="overflow-hidden table-offer-items">
                <Table className="table-head-bg m-0 mt-1">
                    <thead className="table-head-bg d-none d-md-table-row_group">
                    <tr>
                        <th>{t('brand.campaign.book.offer.modal.platform')}</th>
                        <th className="text-center">{t('brand.campaign.book.offer.modal.followers')}</th>
                        <th className="text-center">{t('brand.campaign.book.offer.modal.avg_reach')}</th>
                        <th className="text-center">{t('brand.campaign.book.offer.modal.audience_per_country')}</th>
                        <th className={`${isCharity ? 'd-none' : 'text-center'}`}>{t('brand.campaign.book.offer.modal.cpm')}</th>
                        <th className={`${isCharity ? 'd-none' : 'text-right'}`} style={{width: 150}}>
                            {t('brand.campaign.book.offer.modal.price')}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {campaign?.offerPlaceholder?.totalPlatforms?.map((platform, index) => (
                        <InitialOfferPlatform key={index} platform={platform}/>
                    ))}
                    </tbody>
                </Table>
            </div>
            {!isCharity &&
                <div className="w-100 mt-2">
                    <Totals title={TotalTypes.OFFER_ITEMS}
                            price={getSubtotalOfferItems(initialOffer?.offerItems)}
                    />
                </div>
            }
        </>
    );
}
