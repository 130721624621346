import React from 'react';
import {useSelector} from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/de';
import {CustomImageHandler} from '../../../../../../shared/components/CustomImageHandler';
import {truncateString} from '../../../../../../shared/functions/Functions';
import {IAllStates} from '../../../../../../store/rootReducer';
import {IMessageContent} from '../../dto/IMessage';

interface ISideChatBodyProps {
    message: IMessageContent;
}

export const SideChatBody = ({message}: ISideChatBodyProps) => {
    const {messages, threadMetaData, subject, campaignImages} = message;
    const {unreadIDs} = useSelector((state: IAllStates) => state.message);
    const lastMessage = messages[messages?.length - 1];
    const isGroupChat = threadMetaData?.length > 1;
    const isUnread = unreadIDs.some(messageId => messageId === message?.id);
    const {i18n} = useTranslation(); 
    moment.locale(i18n.language);

    return (
        <>
            {isGroupChat ?
                < CustomImageHandler
                    altTag="Influencer logo"
                    classes="user-img-rounded mr-3 box-shadow-angle"
                    thumbnailPath={campaignImages?.[0]?.thumbnail}
                    photoPath={campaignImages?.[0]?.thumbnail}
                    placeholder={'/icons/img-group-chat.svg'}
                />
                :
                < CustomImageHandler
                    altTag="Influencer logo"
                    classes="user-img-rounded mr-3 box-shadow-angle"
                    photoPath={threadMetaData?.[0]?.participant?.profilePhoto?.thumbnail as string}
                    thumbnailPath={threadMetaData?.[0]?.participant?.profilePhoto?.thumbnail}
                />
            }
            <div className={`d-flex flex-column w-100 justify-content-evenly`}>
                <div className="d-flex justify-content-between align-items-center">
                    <div
                        className={`font-weight-semi-bold mb-0 ${message.type === 'customer_success' ? 'text-info bi-pin' : ''}`}>
                        {isGroupChat ? <>
                                {truncateString(subject ?? '-', 30)}
                            </>
                            : <>
                                {truncateString(((threadMetaData?.[0]?.participant?.displayName ?
                                        threadMetaData?.[0]?.participant?.displayName : threadMetaData?.[0]?.participant?.firstName)
                                    + '-' + subject ?? '-'), 30)}
                            </>}
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center ">
                    <div
                        className={`inbox-message white-space font-weight-${
                            lastMessage?.messageMetadata?.[0]?.isRead === false ? 'semi-bold' : 'normal'
                        } ${isGroupChat ? 'd-flex w-100 justify-content-between' : ''}`}
                    >
                        {isGroupChat ? <div>
                            {truncateString(
                                (threadMetaData?.map((item) => item.participant.firstName).join(', ')), 25)}
                        </div> : ''}
                        {moment(lastMessage?.createdAt)?.fromNow()}
                    </div>
                    {isUnread && <div className="notSeenBubble"/>}
                </div>
            </div>
        </>
    );
};
