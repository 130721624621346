import React, {useEffect, useState} from 'react';
import {MainChatInputFields} from '../../../app/brand/campaign/messages/components/chat/MainChatInputFields';
import {MessageService} from '../../../app/brand/campaign/messages/service';
import {IMessageOpenedChat, IMessageThreadQuery} from '../../../app/brand/campaign/messages/dto/IMessage';
import {MessageBody} from '../message/MessageBody';
import {LockedComments} from './LockedComments';
import {useTranslation} from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

interface IOfferCommentsProps {
    offerId: number;
    canWriteComment?: boolean;
}

export function OfferComments({offerId, canWriteComment}: IOfferCommentsProps) {
    const [allMessages, setAllMessages] = useState<IMessageOpenedChat[]>([]);
    const [filterParams, setFilterParams] = useState<IMessageThreadQuery>({
        page: 1,
        perPage: 15,
    });
    const {count, page} = filterParams;
    const [update, setUpdate] = useState(true);
    const {t} = useTranslation();

    useEffect(() => {
        function getComments() {
            MessageService.getOfferComments(offerId, {page: filterParams.page, perPage: filterParams.perPage})
                .then((response) => {
                    setFilterParams(prev => ({...prev, count: response.count}));
                    setAllMessages(response.data);
                });
        }

        getComments();
    }, [offerId, update, filterParams.perPage]);

    const loadMoreData = () => {
        const valueToUpdate = filterParams?.perPage + 15;
        setFilterParams((prev: any) => ({...prev, perPage: valueToUpdate}));
    };


    return (
        <>
            <div className="d-flex flex-column justify-content-between h-100 overflow-hidden">
                {filterParams?.count ?
                    <div className="comment-tab" id={`scrollableDiv-${offerId}`}
                         style={{
                             overflow: 'auto',
                             display: 'flex',
                             flexDirection: 'column-reverse',
                             paddingRight: '1rem',
                         }}>
                        <InfiniteScroll
                            dataLength={allMessages?.length}
                            next={() => {
                                loadMoreData();
                            }}
                            style={{
                                display: 'flex',
                                flexDirection: 'column-reverse',
                            }} // To put endMessage and loader to the top.
                            inverse={true}
                            endMessage={
                                <p style={{textAlign: 'center'}}>
                                    <b>{t('influencer.messages.seeAllMessages')}</b>
                                </p>
                            }
                            hasMore={allMessages?.length < Number(count ?? 0)}
                            loader={<h4>Loading...</h4>}
                            scrollableTarget={`scrollableDiv-${offerId}`}
                        >
                            <div id="lastMessage"/>
                            {allMessages?.map(comment => (
                                <MessageBody comment={comment}
                                             key={comment?.createdAt + comment.id} isOfferComments={true}
                                             isMyMessage={comment?.isCurrentUserMessage}
                                             senderData={comment?.sender}
                                             haveFiles={!!comment?.attachments?.length}
                                             files={comment?.attachments}/>
                            ))}
                        </InfiniteScroll>
                    </div>
                    : <p className="text-muted">{t('influencer.planner.noComments')}</p>
                }
            {canWriteComment ?
                <MainChatInputFields isOfferComment={true}
                                     selectedChatId={offerId}
                                     setTriggerUpdate={setUpdate}
                /> :
                <LockedComments/>
            }
            </div>
        </>
    );
}
