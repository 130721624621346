import {Col, Row} from 'react-bootstrap';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {CustomImageHandler} from '../../../../../../../../shared/components/CustomImageHandler';
import {Fancybox} from '../../../../../../../../shared/components/Fancybox';
import {useSelector} from 'react-redux';
import {getCorrectImageThumbnail} from '../../../../../../../../shared/functions/Functions';
import {IAllStates} from '../../../../../../../../store/rootReducer';
import DOMPurify from 'dompurify';

const IdeaContent = () => {
    const {campaign, user} = useSelector((state: IAllStates) => state.offerBrand);

    const {t} = useTranslation();
    return (

        <div className="p-3">
            <h5>
                {t('brand.campaign.book.offer.modal.campaign_concept')}
            </h5>
            {campaign?.offers[0]?.description ?
                <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(campaign?.offers[0]?.description)}}/> :
                <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(user?.about as string)}}/>
            }
            <Row>
                {user?.feedItems?.map(
                    (item) =>
                        item.postImages.length > 0 && (
                            <Col key={item?.id} sm={4} lg={2}>
                                <Fancybox options={{infinite: false}}>
                                    <div
                                        data-fancybox="content-gallery"
                                        className="cursor-pointer"
                                        data-src={item.postImages[0]?.externalLink ?? item.postImages[0]?.path ?? '/icons/filePlaceholder.png'}
                                    >
                                        <CustomImageHandler
                                            altTag={item.postImages[0]?.name ?? ''}
                                            classes="feed-image box-shadow"
                                            thumbnailPath={item?.postImages?.[0]?.thumbnail}
                                            placeholder={getCorrectImageThumbnail(!!item.postImages[0]?.externalLink, item.postImages[0]?.name ?? '', item.postImages[0]?.thumbPath ?? '', item.postImages[0]?.path)}
                                            photoPath={getCorrectImageThumbnail(!!item.postImages[0]?.externalLink, item.postImages[0]?.name ?? '', item.postImages[0]?.thumbPath ?? '', item.postImages[0]?.path)}
                                        />
                                    </div>
                                </Fancybox>
                            </Col>
                        ),
                )}
            </Row>
        </div>
    );
};

export default IdeaContent;
