import React from 'react';
import {BackButton} from '../../../../shared/components/BackButton';
import {useTranslation} from 'react-i18next';
import ProfileCard from './ProfileCard';
import {IProfile} from './ProfileSettings';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';

export function MyNetwork() {
  const {user: {hasClients, hasSuppliers}} = useSelector((state: IAllStates) => state.auth);
  const {t} = useTranslation();
  const cards: IProfile[] = [
    {
      componentKey: 'clients',
      image: '/icons/profile/business-and-trade.png',
      isFilled: undefined,
      title: `${t('brand.account.clients')}`,
      role: ['brand', 'brand_master'],
    },
    {
      componentKey: 'suppliers',
      image: '/icons/profile/user.png',
      isFilled: undefined,
      title: `${t('brand.account.marketing_agency')}`,
      role: ['brand', 'brand_master'],
    },
  ];
  const displayCards = (componentKey: string) => {
    if (componentKey === 'clients' && !hasClients) {
      return 'd-none';
    } else if (componentKey === 'suppliers' && !hasSuppliers) {
      return 'd-none';
    }
  };

  return (
    <>
      <div className="row">
      <div className="col-12 d-flex align-items-center mb-4">
        <BackButton/>
        <h5 className="mb-0">{t('brand.account.my_network')}</h5>
      </div>
        {cards.map(
          (item) =>
            (
              <div
                key={item.componentKey}
                className={`col-lg-4 col-md-6 mb-4 ${displayCards(item.componentKey)}`}
              >
                <ProfileCard
                  {...item}
                  openFromSideMenu={false}
                />
              </div>
            ))}
      </div>
    </>
  );
};
