import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../store/rootReducer';
import {InfoBox} from '../../../shared/components/alert/InfoBox';
import {useTranslation} from 'react-i18next';
import {AgencyCardItem} from './components/AgencyCardItem';
import {User} from '../../../model/user/User';
import {AgencyService} from '../service';
import {ErrorToast, SuccessToast} from '../../../utils/toasters';
import {Pagination} from '../../../shared/components/Pagination';
import {scrollToTop} from '../../../utils/helpers/ScrollToTop';
import {useHistory} from 'react-router-dom';
import {LightBulbNotify} from '../../../shared/components/LightBulbNotify';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import {MdArrowDropDown, MdClose} from 'react-icons/md';
import {IAgencyDashFilter} from './dto/IAgencyDashFilter';
import {AgencyFilter} from './components/AgencyFilter';
import BillingInfoModal from '../../shared/user/components/BillingInfoModal';
import {ICompanyUser} from '../../shared/user/dto/ICompanyUser';
import {AgencyUserManagementModal} from './modal/AgencyUserManagementModal';
import PersonalInfoModal from '../../shared/user/components/PersonalInfoModal';
import {AgencyCardItemPlaceholder} from './components/AgencyCardItemPlaceholder';
import CreatorSuccessCard from './components/CreatorSuccessCard';
import EmptyState from '../../../shared/components/placeholder/EmptyState';
import {TermsOfServiceAlert} from '../../../shared/components/alert/TermsOfServiceAlert';
import {AddMoreCreatorsModal} from './components/AddMoreCreatorsModal';
import PriceListModal from '../../shared/user/components/PriceListModal';
import {ConfirmationModal} from '../../../shared/components/alert/ConfirmationModal';
import {UserRoles} from '../../../enum/UserRoles';
import {Helmet} from 'react-helmet';
import {setDeselectInfluencer, setUpdateInfluencerList} from '../../../store/agency/agencySlice';
import {NewFeatures} from '../../../shared/components/NewFeatures';
import {AuthService} from '../../../authentication/service';
import {actions} from '../../../store/authentication/authRedux';

export function AgencyInfluencers() {
    const {
        user: {completionInfo, hasSeenBanner, id},
        authToken
    } = useSelector((reduxState: IAllStates) => reduxState.auth);
    const {selectedInfluencer} = useSelector((reduxState: IAllStates) => reduxState.agency);
    const [filterParams, setFilterParams] = useState<IAgencyDashFilter>({
        page: 1,
        perPage: 8,
        sortCriteria: '',
        sortType: 'DESC',
        displayName: '',
        assignedManagerId: '',
        influencerId: selectedInfluencer?.id,
    });
    const [users, setUsers] = useState<User[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [expendedAccordion, setExpendedAccordion] = useState(true);
    const [isBillingModalOpened, setIsBillingModalOpened] = useState(false);
    const [assignedUsers, setAssignedUsers] = useState<ICompanyUser[]>([]);
    const [selectedUserId, setSelectedUserId] = useState(0);
    const [selectedUserType, setSelectedUserType] = useState<UserRoles>(UserRoles.AGENCY);
    const [showManagement, setShowManagement] = useState(false);
    const [showPersonalInfo, setShowPersonalInfo] = useState(false);
    const [showPriceList, setShowPriceList] = useState(false);
    const [change, setChange] = useState(false);
    const [showAddCreatorModal, setShowAddCreatorModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showNewFeature, setShowNewFeature] = useState(hasSeenBanner);
    const {activeCampaignId} = useSelector((state: IAllStates) => state.selectedCampaign, shallowEqual);
    const campaignId = localStorage.getItem('activeCampaignId');
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        AgencyService.getAgencyUsers({...filterParams, influencerId: selectedInfluencer?.id})
            .then(response => {
                setUsers(response.data);
                setTotalPages(Math.ceil(response.count / response.perPage));
            }).catch(err => ErrorToast(err));
    }, [filterParams, change, selectedInfluencer]);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const onRemoveClick = (userId: number) => {
        setShowConfirmModal(true);
        setSelectedUserId(userId);
    };

    const removeInfluencer = () => {
        AgencyService.removeInfluencer(selectedUserId)
            .then((response) => {
                dispatch(setUpdateInfluencerList({}));
                dispatch(setDeselectInfluencer({}));
                SuccessToast(t('general.toasts.successfullyDeletedUser'));
                setChange(prevState => !prevState);
            }).catch((error) => {
            ErrorToast(error);
        });
    };
    const goToImpersonate = (id: number) => {
        history.push(`/impersonate/${authToken}/${id}/false`);
    };

    const handlePages = (updatePage: number) => {
        setFilterParams({...filterParams, page: updatePage});
        scrollToTop();
    };

    const closeManagementModal = () => {
        setChange(!change);
        setShowManagement(false);
    };
    const showManagementModal = () => setShowManagement(true);

    const showModalAndSetId = (id: number, agencyUsers: ICompanyUser[]) => {
        showManagementModal();
        setAssignedUsers(agencyUsers);
        setSelectedUserId(id);
    };

    const showPriceListModal = (id: number) => {
        setShowPriceList(true);
        setSelectedUserId(id);
    };
    const showPersonalInfoModal = (id: number, userType: UserRoles) => {
        setShowPersonalInfo(true);
        setSelectedUserId(id);
        setSelectedUserType(userType);
    };

    const finishNewFeature = () => {
        AgencyService.toggleBannerSeen(id)
            .then((response) => {
                setShowNewFeature(true);
                AuthService.getPersonalInfo({
                    campaignId: activeCampaignId ?? campaignId,
                    token: JSON.parse(localStorage.getItem('token')!) ?? ''
                })
                    .then((data) => {
                        dispatch(actions.setUser(data));
                    });
            }).catch((error) => {
            ErrorToast(error);
        });
    }

    return (
        <>
            <Helmet>
                <title>App | influence.vision</title>
            </Helmet>
            <BillingInfoModal show={isBillingModalOpened} closeModal={() => setIsBillingModalOpened(false)}
                              userType={selectedUserType}/>
            <PersonalInfoModal show={showPersonalInfo} closeModal={() => setShowPersonalInfo(false)}
                               userType={selectedUserType} id={selectedUserId} updateList={setChange}
                               updateLoggedUser={false}/>
            <PriceListModal key={selectedUserId}
                            show={showPriceList}
                            closeModal={() => setShowPriceList(false)}
                            id={selectedUserId}/>
            <AgencyUserManagementModal show={showManagement} closeModal={closeManagementModal}
                                       openModal={showManagementModal} assignedUsers={assignedUsers}
                                       userId={selectedUserId}/>
            <AddMoreCreatorsModal show={showAddCreatorModal} closeModal={() => setShowAddCreatorModal(false)}/>
            <ConfirmationModal title={t('agency.delete_creator_title')}
                               description={t('agency.delete_creator_desc')}
                               show={showConfirmModal}
                               closeModal={() => setShowConfirmModal(false)}
                               action={removeInfluencer}
            />
            <Row className="pt-md-4 pt-xl-0">
                <Col lg={9} className="left-side-dashboard pl-0">
                    {!showNewFeature && (
                        <NewFeatures finishNewFeature={finishNewFeature}/>
                    )}
                    <TermsOfServiceAlert/>
                    <div className="d-lg-none col mb-4">
                        <CreatorSuccessCard/>
                    </div>
                    <AgencyFilter filterParams={filterParams} setFilterParams={setFilterParams}/>
                    {!!users?.length ?
                        <Row>
                            {users.map((item) => (
                                <AgencyCardItem key={item.id}
                                                influencer={item} impersonateUser={goToImpersonate}
                                                showModalAndSetId={showModalAndSetId}
                                                removeUser={onRemoveClick}
                                                showPriceListModal={showPriceListModal}
                                                showPersonalInfoModal={showPersonalInfoModal}
                                />
                            ))}
                            <AgencyCardItemPlaceholder openModal={() => setShowAddCreatorModal(true)}/>
                        </Row> :
                        <EmptyState desc={t('agency.user_empty')}
                                    btnText={t('agency.add_more_creators')}
                                    func={() => setShowAddCreatorModal(true)}
                        />
                    }
                    {totalPages > 1 &&
                        <div className="d-flex justify-content-end mb-5 pb-2 pb-lg-0 mb-lg-0">
                            <Pagination page={filterParams.page} totalPages={totalPages}
                                        handlePagination={handlePages}/>
                        </div>
                    }
                </Col>
                <Col md={3} className="right-side-dashboard d-none d-lg-block">
                    <div className="mb-4">
                        <CreatorSuccessCard/>
                    </div>
                    {!completionInfo?.billingInfo && <InfoBox
                        variant="danger"
                        title={t('brand.infobox.danger_title')}
                        buttonName={t('brand.infobox.danger_button')}
                        text={t('brand.infobox.danger_text')}
                        action={() => {
                            setIsBillingModalOpened(true);
                        }}
                    />}
                    {!completionInfo?.personalInfo && <InfoBox
                        variant="warning"
                        title={t('brand.infobox.warning_title')}
                        text={t('brand.infobox.warning_text')}
                        buttonName={t('brand.infobox.warning_button')}
                        action={() => {
                            setShowPersonalInfo(true);
                        }}
                    />
                    }
                    <Accordion expanded={expendedAccordion} onChange={() => setExpendedAccordion(!expendedAccordion)}
                               id="did-you-know_accordion" defaultExpanded={true}>
                        <AccordionSummary className="p-0 border-0">
                            <div className="d-flex align-items-center justify-content-between">
                                <h3 className="text-uppercase mb-0">{t('brand.did_you_know.title')}</h3>
                                <div>{expendedAccordion ? <MdClose size="20"/> : <MdArrowDropDown size="25"/>}</div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            {[...Array(8)].map((value, index) => (
                                <LightBulbNotify key={index} title={t(`agency.bullet-point-${index}`)}/>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                    <p className="text-muted">{t('agency.did_you_know_title')}</p>
                    <a target="_blank" rel="noreferrer"
                       href="https://www.influencevision.com/en/book-a-demo/">{t('agency.contact_team')}</a>
                </Col>
            </Row>
        </>
    );
}
