import React from 'react';
import {Button, Modal, ProgressBar} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {IProgressEvent} from './preview/PreviewContentFiels';

interface Props {
    show: boolean;
    cancelApiCall: () => void;
    closeModal: () => void;
    uploadData: IProgressEvent
}


const UploadingModal = ({show, cancelApiCall, closeModal, uploadData}: Props) => {
    const {t} = useTranslation();
    return (
        <Modal show={show}
               backdrop="static"
               className="modal-dark"
               keyboard={false}
               onHide={closeModal}
               centered
        >
            <Modal.Body>
                <div className="text-center">
                    <h4 className="text-center">
                        {t('general.uploading')}...
                    </h4>
                    <ProgressBar now={uploadData?.loaded ?? 0} max={uploadData?.from ?? 0} className="progress-sm"
                                 animated/>
                </div>
                <div className="d-flex justify-content-center mt-2">
                    <Button variant="outline-primary" onClick={() => {
                        cancelApiCall()
                        closeModal();
                    }}>
                        {t('general.button.cancel')}
                    </Button>
                </div>
            </Modal.Body>

        </Modal>
    );
};

export default UploadingModal;
