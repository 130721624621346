import React, {useState} from 'react';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import {Button, Card, Col, Image, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {Campaign} from '../../../../model/campaign/Campaign';
import EmptyState from '../../../../shared/components/placeholder/EmptyState';
import {UploadImage} from '../../../../shared/components/UploadImage';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import {multipartFormData} from '../../../../utils';
import {INFLUENCER_INVOICE} from '../../../../utils/endpoints';
import {BiCopy} from 'react-icons/bi';
import {useHistory} from 'react-router';
import CampaignCard from './CampaignCard';
import {InternalRoutes} from '../../../../enum/InternalRoutes';
import {UserRoles} from '../../../../enum/UserRoles';

interface ICreateInvoice {
    campaigns: Campaign[];
    selectedCampaign: Campaign | undefined;
    setSelectedCampaign: (...args: any) => void;
    setFilterParams: (...args: any) => void;
    userId?: number | null;
    filterParams: any;
}

export default function CreateInvoice({
                                          campaigns,
                                          selectedCampaign,
                                          userId = null,
                                          setSelectedCampaign,
                                          setFilterParams,
                                          filterParams,
                                      }: ICreateInvoice) {
    const copyText =
        'influence.vision GmbH Neustiftgasse 94/B5 A-1070 Wien, Österreich office@influencevision.com UID-Nummer: ATU72247712';
    const {t} = useTranslation();
    const {user} = useSelector((state: IAllStates) => state.auth);
    const history = useHistory();
    const [files, setFiles] = useState<Blob[] | MediaSource[]>();
    const isAgency = [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(user.userType as UserRoles);

    const selectCampaign = (params: any) => {
        setSelectedCampaign(() => campaigns.find((e) => e.id === +params));
    };

    const goToReporting = () => {
            history.push(`${ isAgency ? InternalRoutes.AGENCY_ROUTES : InternalRoutes.INFLUENCER_ROUTES}/reporting`, {
                campaignId: selectedCampaign?.id,
                publishFrom: selectedCampaign?.publishFrom,
            });
        };

        const uploadFile = () => {
            multipartFormData(
                files,
                INFLUENCER_INVOICE + '/' + selectedCampaign?.id! + '/' + (userId || user.id),
                null,
                'post'
            )
                .then(() => {
                    setFilterParams({...filterParams, perPage: 12});
                    setSelectedCampaign({});
                    SuccessToast(t('general.toasts.success_upload_file'));
                })
                .catch((error) => ErrorToast(error));
        };

        const copyHandler = (link: string) => {
            navigator.clipboard.writeText(link ?? ' ');
            SuccessToast(t('general.button.copied'));
        };

        return (
            <div>
                <h4>{t('influencer.invoice.create_invoice')}</h4>
                <Col sm={6} md={4} xl={3} className="pl-0">
                    <CustomAutocomplete
                        dataArray={campaigns}
                        key={selectedCampaign?.id}
                        defaultArray={
                            campaigns.find(
                                (campaign) => campaign.id === selectedCampaign?.id
                            ) as any
                        }
                        status={
                            campaigns.find((campaign) => campaign.id === selectedCampaign?.id)
                                ?.offers[0].status
                        }
                        inputLabel="title"
                        isWithImage={false}
                        queryFilterKey="title"
                        disableClearable={false}
                        classes="pr-md-0"
                        inputLabelTranslation={t('general.campaign')}
                        handleChangeParams={(_, e) => selectCampaign(e)}
                        haveDots
                    />
                </Col>
                <Row className={!!selectedCampaign?.id! ? 'mt-4' : 'mt-2'}>
                    {!!selectedCampaign?.id! ? (
                        <>
                            <Col md={4} xl={3} sm={6} className="pl-0">
                                <CampaignCard campaign={selectedCampaign} isAgency={isAgency} isInvoice/>
                            </Col>
                            {!!selectedCampaign &&
                            selectedCampaign?.offers[0]?.status === 'completed' ? (
                                <Col
                                    xl={3}
                                    md={4}
                                    sm={6}
                                    className="d-flex flex-column pl-0 mt-4 m-sm-0"
                                >
                                    <div className="pl-0 pl-md-0 pl-sm-3">
                                        <div className="mb-3 d-flex align-items-center h-50">
                                            <textarea
                                                name="description"
                                                className="h-100 w-100 form-control"
                                                rows={6}
                                                defaultValue={copyText}
                                            ></textarea>
                                            <BiCopy
                                                className="font-22 text-muted cursor-pointer z-index-2 mb-2 mt-auto"
                                                style={{marginLeft: '-2rem'}}
                                                onClick={() => {
                                                    copyHandler(copyText);
                                                }}
                                            />
                                        </div>

                                        <div className="mt-auto">
                                            <h4>{t('influencer.invoice.disclaimer')}</h4>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: t('influencer.invoice.disclaimer_desc'),
                                                }}
                                            ></div>
                                        </div>
                                    </div>
                                </Col>
                            ) : (
                                <Col xl={3} md={4} sm={6} className="pl-0 mt-3 mt-sm-0">
                                    <div className="pl-sm-3 pl-md-0 h-100">
                                        <Card className="card border-danger border h-100 p-3">
                                            <div className="d-flex align-items-center">
                                                <Image
                                                    src="/icons/tick-mark.svg"
                                                    alt="not submitted"
                                                    height={44}
                                                />
                                                <h4 className="ml-4">
                                                    {t('influencer.invoice.invoice_not_submitted_title')}
                                                </h4>
                                            </div>
                                            <div className="text-muted mt-3">
                                                {t('influencer.invoice.invoice_not_submitted_desc')}
                                            </div>
                                            <Button
                                                className="btn-outline-secondary mt-sm-auto mt-4"
                                                onClick={goToReporting}
                                            >
                                                {t('influencer.invoice.go_to_reportings')}
                                            </Button>
                                        </Card>
                                    </div>
                                </Col>
                            )}
                            {!!selectedCampaign &&
                                selectedCampaign?.offers[0]?.status === 'completed' && (
                                    <Col xl={3} md={4} sm={6} className="pl-0 mt-3 mt-md-0">
                                        <UploadImage
                                            saveFile={setFiles}
                                            isDeleteHidden={true}
                                            classes="p-0"
                                            acceptedFormats="application/pdf"
                                            isMultiple={false}
                                            isPreviewArray={false}
                                        />
                                        <div className="d-flex justify-content-end align-items-end">
                                            <Button disabled={!files} onClick={uploadFile}>
                                                {t('general.button.submit')}
                                            </Button>
                                        </div>
                                    </Col>
                                )}
                        </>
                    ) : (
                        <Col sm={6} md={4} xl={3} className="pl-0">
                            <div className="card mt-3">
                                <div className="card-body">
                                    <EmptyState
                                        desc={t('influencer.invoice.empty_desc')}
                                        addClass="col-9 m-auto text-center"
                                        title={t('influencer.invoice.empty_title')}
                                        iconPath="/icons/profile/invoice.svg"
                                    />
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
        );
    }
