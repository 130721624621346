import React, {SetStateAction} from 'react';
import {Alert, Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {TiWarningOutline} from 'react-icons/ti';
import {User} from '../../../../model/user/User';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {ErrorToast} from '../../../../utils/toasters';
import {AgencyReportingService} from '../../../agency/reporting/service';
import {iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {InfluencerReportingService} from '../service';
import {ISelectedEvent} from './ReportingCampaignCard';


interface Props {
    user?: User;
    offerItem: iManageList;
    setIsReportOpened: React.Dispatch<SetStateAction<boolean>>;
    setIsAdditional: React.Dispatch<SetStateAction<boolean>>;
    setSelectedOfferItem: React.Dispatch<SetStateAction<ISelectedEvent | undefined>>
}

const ReportingAdditionalCard = ({
                                     user,
                                     offerItem,
                                     setIsReportOpened,
                                     setIsAdditional,
                                     setSelectedOfferItem,
                                 }: Props) => {
    const {t} = useTranslation();
    const handleClickReporting = async (offerId: number) => {
        await InfluencerReportingService.getAdditionalReporting(offerId).then(response => {
            setIsAdditional(true);
            setIsReportOpened(true);
            setSelectedOfferItem({
                event: {
                    ...response,
                    user,
                    reportItems: [{
                        reportImages: response?.reportImages
                    }]
                },
                campaign: response?.offer?.campaign,
                offerItem: response
            })
        }).catch(error => ErrorToast(error));
    }
    return (
        <div className="col-md-4 mt-3 pl-0">
            <div className="row box-shadow default-radius">
                <div className="col-4 px-0">
                    <CustomImageHandler classes="feed-image my-0 h-100 cursor-pointer"
                                        onClick={() => handleClickReporting(offerItem?.id)}
                                        thumbnailPath={offerItem?.attachments?.[0]?.thumbnail}
                                        photoPath={offerItem?.attachments?.[0]?.thumbnail ? offerItem?.attachments?.[0].thumbPath as string
                                            : offerItem?.attachments?.[0]?.path as string}
                                        altTag="Campaign photo"/>
                </div>
                <div className="col-8 py-2">
                    <div className="p-2 p-md-0  d-flex flex-column justify-content-between h-100">
                        <div
                            className="mb-1 d-flex align-items-center justify-content-between">
                            <div className="mt-2 mt-md-0">
                                <img src={offerItem?.socialPlatform?.path}
                                     alt="Platform icon"/>
                                <span className="ml-1">
                                    {t(`general.platforms.${offerItem?.socialPlatform?.name}`)}
                                </span>
                            </div>
                            <img src={`/icons/check.svg`} alt="computer-icon"/>
                        </div>
                        <Alert variant="dark" className="box-shadow d-flex align-items-center w-100">
                            <TiWarningOutline size={20}/>
                            <span className="ml-1">
                                {t('general.additionalPost')}
                            </span>
                        </Alert>
                        <div
                            className={`d-flex align-items-center justify-content-between my-2 my-md-0`}>
                            <p className={`status additional my-1 my-md-0`}>
                                additional
                            </p>
                            <Button
                                variant="primary"
                                size="sm"
                                onClick={() => handleClickReporting(offerItem?.id)}
                            >
                                {t(`general.button.open`)}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportingAdditionalCard;
