import {User} from '../../../../model/user/User';
import {IBaseModal} from '../../../shared/user/dto/IBaseModal';
import {
    IMessageOpenedChat,
    IMessageQuery,
    IMessageSendQuery,
    IMessageThreadQuery,
    IMessageUnreadQuery,
} from './dto/IMessage';
import {requests} from '../../../../utils';
import {BASE_URL, BRAND_MANAGING_CAMPAIGN, MANAGE_URL, MESSAGES_URL} from '../../../../utils/endpoints';
import {IInfluencerCampaignsFilter} from '../../dashboard/dto/IBrandDashboard';
import {BaseModel} from '../../../../model/shared/BaseModel';
import {Campaign} from '../../../../model/campaign/Campaign';
import {IOfferCommentsRequest} from '../../../../model/offer/IOfferComments';

export const MessageService = {
    getInboxMessages: (params: IMessageQuery) => requests.get(`${MESSAGES_URL}/inbox`, params),
    getInboxMessageById: (params: IMessageThreadQuery) => requests.get(`${MESSAGES_URL}/thread/${params?.id}`, params),
    sendNewInboxMessage: (params: IMessageSendQuery) => requests.put(`${MESSAGES_URL}/send`, params),
    deleteMessageThread: (id: number) => requests.delete(`${MESSAGES_URL}/thread/${id}`),
    changeMessageReadStatus: (id: number) => requests.put(`${MESSAGES_URL}/read/toggle/${id}`, {}),
    getUnreadMessages: (params: IMessageUnreadQuery) => requests.get(`${MESSAGES_URL}/total/unread`, params),
    getInfluencerCampaigns: (params: IInfluencerCampaignsFilter): Promise<BaseModel<Campaign[]>> => {
        return requests.get(`${MESSAGES_URL}/campaigns`, params);
    },
    markAllAsRead: () => requests.put(`${MESSAGES_URL}/mark/all/thread/read`, {}),
    getUnreadMessageList: () => requests.get(`${MESSAGES_URL}/total/unread/array`),
    getMentionParticipants: (id: number, name?: string): Promise<IBaseModal<User[]>> => requests.get(`${MESSAGES_URL}/mentions/participants/${id}`, {
        name,
        page: 1,
        perPage: 0,
    }),
    getCommentsMentionParticipants: (id: number): Promise<IBaseModal<User[]>> => requests.get(`${BASE_URL}offer-comments/participants/${id}`, {
        page: 1,
        perPage: 0,
    }),
    getOfferComments: (id: number, params: IMessageThreadQuery): Promise<BaseModel<IMessageOpenedChat[]>> => requests.get(`${BASE_URL}offer-comments/${id}`, params),
    addOfferComments: (body: IOfferCommentsRequest) => requests.put(`${BASE_URL}offer-comments/send`, body),
};