import React, {useEffect, useState} from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {FaTrashAlt} from 'react-icons/fa';
import {ICompanyUser} from '../../../shared/user/dto/ICompanyUser';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import {IAssignAgencyUser} from '../dto/IAssignAgencyUser';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {AgencyService} from '../../service';
import {ConfirmationModal} from '../../../../shared/components/alert/ConfirmationModal';

interface IManagementModal {
    show: boolean;
    closeModal: () => void;
    openModal: () => void;
    userId?: number;
    assignedUsers: ICompanyUser[];

}

export function AgencyUserManagementModal({show, closeModal, openModal, assignedUsers, userId}: IManagementModal) {
    const {t} = useTranslation();
    const [companyUsers, setCompanyUsers] = useState<ICompanyUser[]>([]);
    const [selectedUser, setSelectedUser] = useState<ICompanyUser>();
    const [assignUser, setAssignUser] = useState<IAssignAgencyUser>({influencerId: 0, userId: 0});
    const [assignUsers, setAssignUsers] = useState<ICompanyUser[]>(assignedUsers);
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);

    useEffect(() => {
        show && AgencyService.getAgencyCompanyUsers({isEnabled: true})
            .then((data) => {
                setCompanyUsers(data);
            }).catch(error => ErrorToast(error));
    }, [show]);

    useEffect(() => setAssignUser({userId: 0, influencerId: userId ?? 0}), [userId]);

    useEffect(() => setAssignUsers(assignedUsers), [assignedUsers]);

    useEffect(() => {
        if (!!selectedUser?.id) {
            if (isDeleteModalOpened) {
                closeModal();
            } else {
                openModal();
            }
        }
        // eslint-disable-next-line
    }, [isDeleteModalOpened]);

    function addUser() {
        AgencyService.assignAgencyUser(assignUser)
            .then((response) => {
                SuccessToast(t('agency.successAssignedUser'));
                setAssignUser(prev => ({...prev, userId: 0}));
                setSelectedUser(undefined);
                setAssignUsers(response?.assignedManagers);
            });
    }

    function deleteUser(id: number) {
        AgencyService.unAssignAgencyUser({...assignUser, userId: id})
            .then((response) => {
                SuccessToast(t('general.toasts.successRemovedUser'));
                setAssignUsers(response?.assignedManagers);
            }).catch(error => ErrorToast(error));
    }

    const clearedData = companyUsers?.filter(coUser => assignUsers?.findIndex(asUser => Number(asUser.id) === Number(coUser.id)) === -1);

    const changeHandler = (paramKey: string, paramValue: number | string) => {
        setAssignUser({...assignUser, [paramKey]: +paramValue});
    };

    return (
        <>
            <Modal size="lg" centered show={show} onHide={closeModal}>
                <Modal.Header closeButton className="border-bottom border-light mt-2">
                    <Modal.Title>
                        <h4 className="mb-0">{t('agency.user_management')}</h4>
                        <p className="light-text font-12 ">{t('agency.user_management_desc')}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            {assignUsers?.map((user) => (
                                <div key={user.id} className="d-flex justify-content-between mb-3">
                                    <div className="d-flex align-items-center">
                                        <CustomImageHandler
                                            altTag="User Profile"
                                            classes="campaign-card-img mr-2"
                                            photoPath={user.profilePhoto?.path ?? ''}
                                        />
                                        <div className="d-flex flex-column">
                                            <p className="mb-0 font-14 font-weight-bold">
                                                {user.firstName} {user.lastName}
                                            </p>
                                            {user?.position && <p className="mb-0 text-primary font-12">
                                                {t(`general.positions.${user.position}`)}
                                            </p>}
                                        </div>
                                    </div>
                                    <div>
                                        <FaTrashAlt
                                            className="cursor-pointer"
                                            onClick={(e) => {
                                                setIsDeleteModalOpened(true);
                                                setSelectedUser(user);
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}

                        </div>
                        <Row>
                            <Col md={12} className=" py-md-4 my-lg-0 p-0">
                                <CustomAutocomplete
                                    dataArray={clearedData}
                                    isWithImage
                                    isManagingModal={true}
                                    inputLabel="firstName" photoLabel="profilePhoto.path"
                                    selectedStateImage={selectedUser?.profilePhoto?.path as string}
                                    queryFilterKey="userId"
                                    disableClearable={false}
                                    key={assignUsers?.length}
                                    inputLabelTranslation={t('general.addUser')}
                                    handleChangeParams={changeHandler}/>
                            </Col>
                        </Row>
                    </div>

                </Modal.Body>
                <Modal.Footer className="mt-3 mt-md-0">
                    <Button variant="outline-primary" onClick={closeModal}>
                        {t('general.button.cancel')}
                    </Button>
                    <Button variant="primary" onClick={addUser} disabled={!assignUser?.userId}>
                        {t('general.button.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <ConfirmationModal
                title={t('general.deleteUser')}
                description={`${t('general.confirmDeleteUser')} ${selectedUser?.displayName ?? selectedUser?.firstName}`}
                action={() => deleteUser(Number(selectedUser?.id))}
                show={isDeleteModalOpened} closeModal={() => setIsDeleteModalOpened(false)}
            />
        </>
    );
};
