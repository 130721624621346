import {Card, Col, Row} from 'react-bootstrap';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {CustomImageHandler} from '../../../../../../../shared/components/CustomImageHandler';
import {RatingStar} from '../../../../../../../shared/components/RatingStar';
import {
    calculateTotalReach,
    clearHtmlFromString, getCorrectSocialUrl,
} from '../../../../../../../shared/functions/Functions';
import {numberFormatter} from '../../../../../../../utils/helpers/numberFormater';
import {StatisticCard} from '../../../../../../../shared/components/StatisticCard';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../store/rootReducer';
import {setActiveTab} from '../../../../../../../store/brand/offerBrandSlice';
import {useHistory} from 'react-router-dom';
import {InternalRoutes} from '../../../../../../../enum/InternalRoutes';
import { priceSplitter } from '../../../../../../../utils/helpers/priceSplitter';

function ModalProfileInfo() {
    const {campaign, user, initialOffer} = useSelector((state: IAllStates) => state.offerBrand);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const goToContentTab = () => {
        dispatch(setActiveTab('1'));
    };
    const goToProfileInfo = () => {
        history.push(`${InternalRoutes.INFLUENCER_PUBLIC_PROFILE}/${user?.id}`);
    };
    const engRate = Number(user?.engagementRate).toFixed(1)
    return (
        <>
            <Card className="d-none d-xl-flex">
                <Card.Body className="d-flex flex-column align-items-center">
                    {campaign?.isCharity &&
                        <p className="position-top-right status pending text-warning">
                            {t('general.barter')}
                        </p>
                    }
                    <CustomImageHandler onClick={goToProfileInfo}
                                        thumbnailPath={user?.profilePhoto?.thumbnail}
                                        photoPath={user?.profilePhoto?.path as string}
                                        altTag="User Profile"
                                        classes="profile-image cursor-pointer"/>
                    <h6 onClick={goToProfileInfo} className="mt-3 mb-1 cursor-pointer">{user?.displayName}</h6>
                    <p onClick={goToProfileInfo}
                       className="mb-3 cursor-pointer text-muted">{user?.firstName} {user?.lastName}</p>
                    {user?.totalRating ? <RatingStar rating={user?.totalRating}/> : ''}
                    <div className="w-100 d-flex flex-wrap justify-content-center mt-2">
                        {user?.userCategories?.map((category) => (
                            <p key={category.id}
                               className="status mx-2 mb-1 ">{`${t(`general.category.${category.campaignCategory.name}`)}`}</p>
                        ))}
                    </div>
                    {campaign?.offers[0]?.description ?
                        <>
                            <div className="text-center mt-2 w-100">
                                <h6 className="mb-1">{t('brand.campaign.book.offer.modal.content_pitch')}</h6>
                                <div className="five-line-text">
                                    {clearHtmlFromString(campaign?.offers[0]?.description ?? '-')}
                                </div>

                            </div>
                            <a className="w-100 text-right text-muted cursor-pointer" href="#1"
                               onClick={goToContentTab}>{t('general.read_more')}</a>
                        </> :
                        (user?.about &&
                            <>
                                <div className="text-center mt-2">
                                    <h6 className="mb-1">{t('brand.campaign.book.offer.modal.content_pitch')}</h6>
                                    <div className="text-left five-line-text"
                                    >
                                        {clearHtmlFromString(user?.about ?? '-')}
                                    </div>
                                </div>
                                <a className="w-100 text-right text-muted cursor-pointer" href="#1"
                                   onClick={goToContentTab}>{t('general.read_more')}</a>
                            </>
                        )
                    }
                </Card.Body>
            </Card>
            <Row className="my-3 d-none d-xl-flex">
                <Col className="pl-0">
                    <StatisticCard
                        number={numberFormatter(calculateTotalReach(campaign, initialOffer) ?? 0)}
                        text={t('brand.campaign.book.offer.modal.total_reach')}
                    />
                </Col>
                <Col className="pr-0">
                    <StatisticCard
                        number={user?.engagementRate !== 0 ? priceSplitter(+engRate) + ' %' : 'n.a.'}
                        text={t('brand.campaign.book.offer.modal.engagement_rate')}
                    />
                </Col>
            </Row>
            <div onClick={goToProfileInfo} className="d-xl-none d-flex align-items-center">
                <div>
                    <CustomImageHandler
                        thumbnailPath={user?.profilePhoto?.thumbnail as string}
                        photoPath={user?.profilePhoto?.path as string}
                        classes="campaign-icon rounded-circle" altTag="User Profile"/>
                </div>
                <h5 className="mb-0 ml-2">{user?.displayName}</h5>
            </div>
            <div className="d-none d-xl-block">
                <p className="text-muted my-3">{t('brand.campaign.book.offer.modal.check_social_networks', {userName: user?.firstName})}</p>
                <div className="d-flex flex-wrap">
                    {campaign?.userHandles?.map(item => (
                        <a target="_blank"
                           key={item.id}
                           className="d-flex align-items-center mb-2 mr-3"
                           href={`${getCorrectSocialUrl(item?.mainPlatform?.platformEnum as string, item?.title, item?.externalLink)}`}
                           rel="noreferrer">
                            <img src={item?.mainPlatform?.path} alt="Platform icon" height={33}/>
                            <span className="ml-1 mb-0">
                                @{item?.title}
                            </span>
                        </a>
                    ))}
                </div>
            </div>
        </>
    );
}

export default ModalProfileInfo;
