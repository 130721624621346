import React, {useEffect, useState} from 'react';
import {Button, Col, Modal, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {TextField} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {
    disablePlatformFormatting,
    platformIcons
} from '../../../app/brand/campaign/book/offer/modal/tabs/audience/AudienceData';
import {IAllStates} from '../../../store/rootReducer';
import {CustomAutocomplete} from '../../../shared/components/Autocomplete';
import {UploadImage} from '../../../shared/components/UploadImage';
import {initialDataManualAuth} from '../dto/InitialDataManualAuth';
import {multipartFormData} from '../../../utils';
import {INFLUENCER_SOCIAL_AUTH_MANUAL, INFLUENCER_UPDATE_SOCIAL_MANUAL} from '../../../utils/endpoints';
import {ErrorToast, SuccessToast, WarningToast} from '../../../utils/toasters';
import {Platforms} from '../../../model/platform/Platforms';
import {SocialService} from '../service';
import {BiErrorCircle} from 'react-icons/bi';
import {formatDate} from '../../../shared/functions/Functions';

interface IAuthModalProps {
    platform?: Platforms;
    enabledPlatformData?: Platforms;
    show: boolean;
    closeModal: () => void;
    id?: number;
}

export function ManualAuthorisationModal({show, closeModal, platform, id, enabledPlatformData}: IAuthModalProps) {
    const {t} = useTranslation();
    const {country} = useSelector((states: IAllStates) => states.enums);
    const [files, setFiles] = useState<Blob[] | MediaSource[]>();
    const {audienceMetricAge} = useSelector((states: IAllStates) => states.enums);
    const [manualAuth, setManualAuth] = useState(initialDataManualAuth);
    const [errorSumAges, setErrorSumAges] = useState(false);
    const [errorSumCountries, setErrorSumCountries] = useState(false);
    const [errorSumGender, setErrorSumGender] = useState(false);
    const isApproved = platform?.status === 'approved';
    const [filesError, setFilesError] = useState(false);
    const [updatedAt, setUpdatedAt] = useState();
    const hasExternalLink = ['blog', 'podcast', 'linkedin'].includes(platform?.platformEnum ?? '');

    useEffect(() => {
        if (show) {
            if (isApproved) {
                SocialService.getCurrentAudienceData(platform?.id, id)
                    .then((data) => {
                        const updateGenderAndAges = initialDataManualAuth.audiences.map((obj) => {
                            const metricChange = data?.audiences?.find((apiObj: any) => (
                                apiObj.metric === obj.metric && apiObj.dimension === obj.dimension
                            ));
                            const countryChange = data?.audiences?.find((apiObj: any) => (
                                apiObj.position === obj.position && apiObj.dimension === obj.dimension
                            ));
                            if (metricChange) {
                                return {...obj, value: metricChange.value};
                            }
                            if (countryChange) {
                                return {
                                    ...obj,
                                    metric: countryChange.metric,
                                    value: countryChange.value,
                                };
                            }
                            return obj;
                        });
                        setManualAuth({
                            ...initialDataManualAuth,
                            ...data,
                            audiences: updateGenderAndAges,
                            userId: id ?? 0,
                            platformId: platform?.id ?? 0,
                        });
                        setUpdatedAt(data?.updatedAt);
                    }).catch((err) => ErrorToast(err));
            } else {
                setManualAuth({...initialDataManualAuth, userId: id ?? 0, platformId: platform?.id ?? 0});
            }
        }
        return () => {
            setManualAuth(initialDataManualAuth);
            setFilesError(false);
            setFiles(undefined);
            setErrorSumCountries(false);
            setErrorSumAges(false);
        };
        // eslint-disable-next-line
    }, [show]);

    function manualAuthRequest() {
        multipartFormData(files, INFLUENCER_SOCIAL_AUTH_MANUAL, JSON.stringify(manualAuth), 'post')
            .then((res) => {
                SuccessToast(t('general.toasts.successAuthorize'));
                if (closeModal) {
                    closeModal();
                }
            }).catch(error => ErrorToast(error));
    }

    function updateManualAuthRequest() {
        multipartFormData(files, INFLUENCER_UPDATE_SOCIAL_MANUAL, JSON.stringify(manualAuth), 'post')
            .then((res) => {
                SuccessToast(t('general.toasts.successAuthorize'));
                if (closeModal) {
                    closeModal();
                }
            }).catch(error => ErrorToast(error));
    }

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, dimension?: string) => {
        const {name, value} = event.target;
        const newValue = +value === 0 ? undefined : Math.min(Math.max(+value, 0), 100);
        const allAudiences: any = manualAuth.audiences;
        let foundItem;
        let itemIndex;
        if (dimension) {
            if (dimension === 'country') {
                foundItem = allAudiences.find((item: any) => item?.position === name);
                itemIndex = allAudiences.indexOf(foundItem as any);
                allAudiences[itemIndex].value = newValue ?? 0;
                setManualAuth({...manualAuth, audiences: allAudiences});
            } else {
                foundItem = allAudiences.find((item: any) => item.metric === name);
                itemIndex = allAudiences.indexOf(foundItem as any);
                if (name === 'male') {
                    allAudiences.find((item: any) => item.metric === 'male').value = newValue ?? 0;
                    allAudiences.find((item: any) => item.metric === 'female').value = (newValue ?? 0) > 0 && 100 - (newValue ?? 0);
                } else if (name === 'female') {
                    allAudiences.find((item: any) => item.metric === 'female').value = newValue ?? 0;
                    allAudiences.find((item: any) => item.metric === 'male').value = (newValue ?? 0) > 0 && 100 - (newValue ?? 0);
                } else {
                    allAudiences[itemIndex].value = newValue ?? 0;
                }
                setManualAuth({...manualAuth, audiences: allAudiences});
            }
        } else {
            setManualAuth({
                ...manualAuth,
                [name]: name === 'title' ? (value.charAt(0) === '@' ? value?.substring(1).replace(/\s/g, '') : value.replace(/\s/g, '')) : value.replaceAll(',', '.')
            });
        }
    };

    const changeCountryHandler = (paramKey: string, paramValue: string | number) => {
        const allAudiences = manualAuth.audiences;
        let foundItem;
        let itemIndex;
        foundItem = allAudiences.find((item) => item?.position === paramKey);
        itemIndex = allAudiences.indexOf(foundItem as any);
        allAudiences[itemIndex].metric = String(paramValue);
        setManualAuth({...manualAuth, audiences: allAudiences});
    };

    function limitAudience() {
        const ageSum = manualAuth.audiences.filter((item) => item.dimension === 'age').reduce((acc, item) => acc + (Math.round(item.value) ?? 0), 0);
        const countrySum = manualAuth.audiences.filter((item) => item.dimension === 'country').reduce((acc, item) => acc + (Math.round(item.value) ?? 0), 0);
        const genderSum = manualAuth.audiences.filter((item) => item.dimension === 'gender').reduce((acc, item) => acc + (Math.round(item.value) ?? 0), 0);
        const countryArr = manualAuth.audiences.filter((item) => item.dimension === 'country');

        const withoutDuplicates = new Set(countryArr.map(v => v.metric));

        if (ageSum > 100) {
            setErrorSumAges(true);
            return WarningToast(t('general.toasts.warn_total_sum_ages'));
        } else if (countrySum > 100) {
            setErrorSumAges(false);
            setErrorSumCountries(true);
            return WarningToast(t('general.toasts.warn_total_sum_countries'));
        } else if (genderSum > 100) {
            setErrorSumCountries(false);
            setErrorSumAges(false);
            setErrorSumGender(true);
            return WarningToast(t('general.toasts.warn_total_sum_genders'));
        } else if (withoutDuplicates.size < countryArr.length) {
            setErrorSumGender(false);
            setErrorSumAges(false);
            setErrorSumCountries(false);
            return WarningToast(t('general.toasts.warn_duplicate_countries'));
        } else {
            setErrorSumGender(false);
            setErrorSumAges(false);
            setErrorSumCountries(false);
            if (files === undefined) {
                setFilesError(true);
            } else {
                isApproved ? updateManualAuthRequest() : manualAuthRequest();
                setFilesError(false);
            }
        }
    }

    const onClose = () => {
        setManualAuth(initialDataManualAuth);
        closeModal();
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        limitAudience();
    };

    function getValue(property: string, data: string) {
        if (property === 'metric') {
            let value = 0;
            value = manualAuth?.audiences.find(item => item.metric === data)?.value ?? 0;
            return value === 0 ? '' : Math.round(value);
        } else if (property === 'countryPercent') {
            let value = 0;
            value = manualAuth?.audiences.find(item => item.position === data)?.value ?? 0;
            return value === 0 ? '' : Math.round(value);
        } else if (property === 'country') {
            return manualAuth?.audiences.find(item => item.position === data)?.metric;
        }
        return '';
    }

    return (
        <>
            <Modal show={show}
                   onHide={onClose}
                   size="lg"
                   centered={true}
            >
                <form onSubmit={onSubmit}>
                    <Modal.Header closeButton className="pt-2 pb-0">
                        <Row>
                            <Col md={3} className="d-flex align-items-center">
                                <div className="d-flex align-items-center">
                                    <img src={platform?.path} alt={platform?.name}/>
                                    <div className="d-flex flex-column ml-1">
                                        <span className="font-weight-semi-bold">{platform?.name}</span>
                                        <p className="text-muted mb-0">{t('auth.register.influencer.manual_auth.manual_auth')}</p>
                                    </div>
                                </div>
                            </Col>
                            <Col md={9}>
                                <div className="card border border-danger mt-2 mt-md-0">
                                    <div className="card-body d-flex align-items-center p-2">
                                        <img src="/icons/errorProfile.svg" alt="Error profile icon"/>
                                        <div className="d-flex flex-column">
                                            <span className="font-weight-semi-bold">
                                                {t('auth.register.influencer.manual_auth.important_notice')}
                                            </span>
                                                                    <span className="text-muted">
                                                {t('auth.register.influencer.manual_auth.important_notice_desc')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xs={12} className="d-flex justify-content-between">
                                <h6>
                                    {t('auth.register.influencer.manual_auth.channel_info')}
                                </h6>
                                <p className="text-muted mb-0">
                                    {updatedAt && t('auth.register.influencer.manual_auth.last_update')} {updatedAt && formatDate(updatedAt ?? '')}
                                </p>
                            </Col>
                            <Col md={6}>
                                <TextField
                                    label={t('auth.register.influencer.manual_auth.handle')}
                                    variant="outlined"
                                    name="title"
                                    required
                                    disabled={isApproved}
                                    value={manualAuth?.title}
                                    InputProps={{inputProps: {min: 0}}}
                                    onChange={(e) => changeHandler(e)}
                                    size="small"
                                    className="w-100 my-2"
                                />
                            </Col>
                            <Col md={6}>
                                {hasExternalLink &&
                                    <TextField
                                        label={t('auth.register.influencer.manual_auth.external_link')}
                                        variant="outlined"
                                        name="externalLink"
                                        value={manualAuth?.externalLink}
                                        onChange={(e) => changeHandler(e)}
                                        size="small"
                                        className="w-100 my-2"
                                    />
                                }
                            </Col>
                            <Col className="mt-2" xs={12}>
                                <h6>
                                    {t('auth.register.influencer.manual_auth.audience_data')}
                                </h6>
                            </Col>
                            <Col md={3} xs={6}>
                                <TextField
                                    label={t('auth.register.influencer.manual_auth.male')}
                                    variant="outlined"
                                    name="male"
                                    required
                                    error={errorSumGender}
                                    value={getValue('metric', 'male')}
                                    InputProps={{inputProps: {min: 0, max: 100}}}
                                    type="number"
                                    onChange={(e) => changeHandler(e, 'gender')}
                                    size="small"
                                    className="w-100 my-2 pr-2 pr-md-0"
                                />
                            </Col>
                            <Col md={3} xs={6}>
                                <TextField
                                    label={t('auth.register.influencer.manual_auth.female')}
                                    variant="outlined"
                                    name="female"
                                    required
                                    error={errorSumGender}
                                    value={getValue('metric', 'female')}
                                    InputProps={{inputProps: {min: 0, max: 100}}}
                                    type="number"
                                    onChange={(e) => changeHandler(e, 'gender')}
                                    size="small"
                                    className="w-100 my-2"
                                />
                            </Col>
                            {Object.keys(platformIcons)?.map(platformName => {
                                if (!enabledPlatformData?.[platformName as keyof Platforms]) {
                                    return null;
                                }
                                return (
                                    <Col md={3} xs={6} key={platformName}>
                                        <TextField
                                            label={t(`general.statistics.${platformName}`)}
                                            variant="outlined"
                                            required
                                            defaultValue={(manualAuth as any)?.[platformName]}
                                            value={(manualAuth as any)?.[platformName] ?? 0}
                                            InputProps={{inputProps: {min: 0}}}
                                            type={disablePlatformFormatting.includes(platformName) ? 'text' : 'number'}
                                            name={platformName}
                                            onChange={(e) => changeHandler(e)}
                                            size="small"
                                            className="w-100 my-2 pr-2 pr-md-0"
                                        />
                                    </Col>
                                );
                            })}
                            {/* go to next line if list above is empty */}
                            <Col md={12}/>
                            {/* ********** */}
                            <Col xs={12} md={6} className="px-0">
                                <div className="d-flex flex-wrap">
                                    <Col md={12} className="px-0">
                                        <h6 className="ml-3 mt-2">
                                            {t('auth.register.influencer.manual_auth.top_countries')}
                                        </h6>
                                    </Col>
                                    <Col md={8} xs={8}>
                                        <CustomAutocomplete dataArray={country} isWithImage={true} inputLabel="country"
                                                            isMultiple={false}
                                                            isRequired={true}
                                                            classes="my-2 w-100 pr-2 pr-md-0"
                                                            photoLabel="countryFlag"
                                                            defaultArray={getValue('country', 'position-1')}
                                                            queryFilterKey="position-1"
                                                            inputLabelTranslation={`${t('auth.register.influencer.manual_auth.country_no')} 1`}
                                                            handleChangeParams={changeCountryHandler}/>
                                    </Col>
                                    <Col md={4} xs={4}>
                                        <TextField
                                            label={'%'}
                                            variant="outlined"
                                            type="number"
                                            name="position-1"
                                            InputProps={{inputProps: {min: 0, max: 100}}}
                                            value={getValue('countryPercent', 'position-1')}
                                            required
                                            error={errorSumCountries}
                                            onChange={(e) => changeHandler(e, 'country')}
                                            size="small"
                                            className="w-100 my-2"
                                        />
                                    </Col>
                                    <Col md={8} xs={8}>
                                        <CustomAutocomplete dataArray={country} isWithImage={true} inputLabel="country"
                                                            isMultiple={false}
                                                            isRequired={true}
                                                            classes="my-2 w-100 pr-2 pr-md-0"
                                                            photoLabel="countryFlag"
                                                            defaultArray={getValue('country', 'position-2')}
                                                            queryFilterKey="position-2"
                                                            inputLabelTranslation={`${t('auth.register.influencer.manual_auth.country_no')} 2`}
                                                            handleChangeParams={changeCountryHandler}/>
                                    </Col>
                                    <Col md={4} xs={4}>
                                        <TextField
                                            label={'%'}
                                            variant="outlined"
                                            type="number"
                                            InputProps={{inputProps: {min: 0, max: 100}}}
                                            name="position-2"
                                            value={getValue('countryPercent', 'position-2')}
                                            required
                                            error={errorSumCountries}
                                            onChange={(e) => changeHandler(e, 'country')}
                                            size="small"
                                            className="w-100 my-2"
                                        />
                                    </Col>

                                    <Col md={8} xs={8}>
                                        <CustomAutocomplete dataArray={country} isWithImage={true} inputLabel="country"
                                                            isMultiple={false}
                                                            isRequired={true}
                                                            classes="my-2 w-100 pr-2 pr-md-0"
                                                            photoLabel="countryFlag"
                                                            defaultArray={getValue('country', 'position-3')}
                                                            queryFilterKey="position-3"
                                                            inputLabelTranslation={`${t('auth.register.influencer.manual_auth.country_no')} 3`}
                                                            handleChangeParams={changeCountryHandler}/>
                                    </Col>
                                    <Col md={4} xs={4}>
                                        <TextField
                                            label={'%'}
                                            variant="outlined"
                                            type="number"
                                            name="position-3"
                                            InputProps={{inputProps: {min: 0, max: 100}}}
                                            required
                                            error={errorSumCountries}
                                            value={getValue('countryPercent', 'position-3')}
                                            onChange={(e) => changeHandler(e, 'country')}
                                            size="small"
                                            className="w-100 my-2"
                                        />
                                    </Col>
                                    <Col md={8} xs={8}>
                                        <CustomAutocomplete dataArray={country} isWithImage={true} inputLabel="country"
                                                            isMultiple={false}
                                                            isRequired={true}
                                                            classes="my-2 w-100 pr-2 pr-md-0"
                                                            photoLabel="countryFlag"
                                                            queryFilterKey="position-4"
                                                            defaultArray={getValue('country', 'position-4')}
                                                            inputLabelTranslation={`${t('auth.register.influencer.manual_auth.country_no')} 4`}
                                                            handleChangeParams={changeCountryHandler}/>
                                    </Col>
                                    <Col md={4} xs={4}>
                                        <TextField
                                            label={'%'}
                                            variant="outlined"
                                            type="number"
                                            InputProps={{inputProps: {min: 0, max: 100}}}
                                            name="position-4"
                                            error={errorSumCountries}
                                            required
                                            value={getValue('countryPercent', 'position-4')}
                                            onChange={(e) => changeHandler(e, 'country')}
                                            size="small"
                                            className="w-100 my-2"
                                        />
                                    </Col>
                                    <Col md={8} xs={8}>
                                        <CustomAutocomplete dataArray={country} isWithImage={true} inputLabel="country"
                                                            isMultiple={false}
                                                            isRequired={true}
                                                            classes="my-2 w-100 pr-2 pr-md-0"
                                                            photoLabel="countryFlag"
                                                            queryFilterKey="position-5"
                                                            defaultArray={getValue('country', 'position-5')}
                                                            inputLabelTranslation={`${t('auth.register.influencer.manual_auth.country_no')} 5`}
                                                            handleChangeParams={changeCountryHandler}/>
                                    </Col>
                                    <Col md={4} xs={4}>
                                        <TextField
                                            label={'%'}
                                            variant="outlined"
                                            type="number"
                                            InputProps={{inputProps: {min: 0, max: 100}}}
                                            name="position-5"
                                            error={errorSumCountries}
                                            required
                                            value={getValue('countryPercent', 'position-5')}
                                            onChange={(e) => changeHandler(e, 'country')}
                                            size="small"
                                            className="w-100 my-2"
                                        />
                                    </Col>
                                </div>
                            </Col>
                            <Col xs={12} md={6} className="px-0 ">
                                <h6 className="ml-3 mt-2">
                                    {t('auth.register.influencer.manual_auth.age_groups')}
                                </h6>
                                <div className="d-flex flex-wrap">
                                    {audienceMetricAge.map((item, index) => (
                                        <Col key={index} md={6} xs={6}>
                                            <TextField
                                                label={`${t(`general.age_groups.${item}`)} ${t('auth.register.influencer.manual_auth.audience')} %`}
                                                variant="outlined"
                                                name={item}
                                                type="number"
                                                error={errorSumAges}
                                                InputProps={{inputProps: {min: 0, max: 100}}}
                                                required
                                                value={getValue('metric', item)}
                                                onChange={(e) => changeHandler(e, 'age')}
                                                size="small"
                                                className={`w-100 my-2 ${(index % 2) === 0 && 'pr-2 pr-md-0'}`}
                                            />
                                        </Col>
                                    ))}
                                </div>
                            </Col>
                            <Col className="mt-2" xs={12}>
                                <h6>
                                    {t('auth.register.influencer.manual_auth.auth')}
                                </h6>
                                <p className="text-muted">
                                    {t('auth.register.influencer.manual_auth.auth_desc')}
                                </p>
                                <UploadImage saveFile={setFiles}
                                             isDeleteHidden={true}
                                             acceptedFormats="image/jpeg, image/png, image/jpg"
                                             isPreviewArray={false}
                                             error={filesError}
                                             isMultiple={false}/>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={closeModal}>
                            {t('general.button.cancel')}
                        </Button>
                        <Button type="submit" variant="primary" onSubmit={onSubmit}>
                            {t('general.button.save')}
                        </Button>
                        {filesError &&
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="invalid-tooptip">
                                        <div className="d-flex flex-column">
                      <span>
                        {t('auth.register.influencer.manual_auth.screenshots_warning')}
                      </span>
                                        </div>
                                    </Tooltip>
                                }>
                                <BiErrorCircle size={20} className="text-danger ml-2 cursor-pointer"/>
                            </OverlayTrigger>
                        }

                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
