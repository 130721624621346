import React from 'react'
import { Button, Col, Image, Row } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'

export default function HeaderPublic() {
    const history = useHistory();
  return (
    <header>
        <Row className="w-100 m-0">
            <Col lg={5} className="d-flex align-items-center">
                <Link to="/">
                    <Image
                        alt="Influence vision logo"
                        src="/tempAssets/influence-vision-logo.svg"
                        height={60}
                        width={150}
                    />
                </Link>
            </Col>
            <Col
                lg={7}
                className="d-flex align-items-center justify-content-between justify-content-lg-end ml-auto"
            >
                <Button variant='outline-primary' onClick={() => history.push('/')}>Sign in</Button>
            </Col>
        </Row>
    </header>
  )
}
