import {Button, Card} from 'react-bootstrap';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import {IOffer} from '../../../../../model/offer/IOffer';
import {useTranslation} from 'react-i18next';
import {User} from '../../../../../model/user/User';
import React from 'react';

interface IAcceptedOfferCardProps {
    offer: IOffer;
    openOfferAction: (user?: User) => void;
}

export function AcceptedOfferCard({offer, openOfferAction}: IAcceptedOfferCardProps) {
    const {t} = useTranslation();
    return (
        <>
            <Card className="mb-2">
                <Card.Body className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                        <CustomImageHandler classes="user-img-rounded mr-2"
                                            photoPath={offer?.user?.profilePhoto?.thumbnail}
                                            altTag="User Profile"/>
                        <div>
                            <h6 className="mb-0">{!!offer.user.displayName ? offer.user.displayName : (offer?.user?.firstName + ' ' + offer?.user?.lastName)}</h6>
                            <p className="mb-0 text-primary euro-symbol">{offer?.price}</p>
                        </div>
                    </div>
                    {!!offer?.declineReason?.length &&
                        <div className="d-flex align-items-center">
                            <h6 className="mx-2 text-muted">
                                <span>Declined reasons:</span> {offer?.declineReason?.map((item, index) => (
                                <span key={index}>{t(`general.decline_reasons.${item}`)}
                                    {index === offer?.declineReason?.length - 1 ? '' : ', '}
                        </span>
                            ))}
                            </h6>
                        </div>
                    }
                    <div className="d-flex align-items-center">
                        <Button onClick={() => openOfferAction(offer?.user)} variant="outline-primary">
                            {t('general.button.open')}
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};
