import React from 'react';
import {Col, Row} from 'react-bootstrap';
import AudienceDataChart from './AudienceDataChart';
import {useTranslation} from 'react-i18next';
import {IAudienceCharts} from '../../../dto/IAudienceCharts';
import { AudienceBarChart } from './AudienceBarChart';

interface IAudienceChartsProps {
  charts: IAudienceCharts;
  chartDates: string[];
}

export function AudienceCharts({charts, chartDates}: IAudienceChartsProps) {
  const {t} = useTranslation();
  
  return(
    <>
        <Row>
          {/* <Col className="p-0 mt-3" sm={12}>
            <AudienceBarChart
              title={`${t('brand.campaign.book.offer.modal.post_engagement')}`}
              description="Check followers data in the chart bellow!"
              chartData={charts.followersChart}
              chartDates={chartDates}
            />
          </Col> */}
          <Col className="p-0 mt-3" sm={12}>
            <AudienceDataChart
              title={`${t('brand.campaign.book.offer.modal.followers')}`}
              description="Check followers data in the chart bellow!"
              chartData={charts.followersChart}
              chartDates={chartDates}
            />
          </Col>
          <Col className="pl-0" xl={6}>
            <AudienceDataChart
              title={`${t('brand.campaign.book.offer.modal.average_likes')}`}
              description="Check average likes data in the chart bellow!"
              chartData={charts.likesChart}
              chartDates={chartDates}
            />
          </Col>
          <Col className="pr-0" xl={6}>
            <AudienceDataChart
              title={`${t('brand.campaign.book.offer.modal.engagement_rate')}`}
              description="Check engagement rate data in the chart bellow!"
              chartData={charts.engagementChart}
              chartDates={chartDates}
            />
          </Col>
        </Row>
    </>
  )
}
