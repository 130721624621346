import React from 'react';
import {Route, Switch} from 'react-router';
import {Impersonate} from './index';

export function ImpersonateRoutes() {
  return (
    <Switch>
      <Route path="/impersonate/:token/:id/:isAdmin/:adminActiveFirstRoute?/:adminActiveSecondRoute?/:adminActiveThirdRoute?" component={Impersonate}/>
    </Switch>
  );
}
