export enum OfferStatusEnum {
    REQUESTED = 'requested',
    REJECTED = 'rejected',
    PENDING = 'pending',
    ACCEPTED = 'accepted',
    DECLINED = 'declined',
    COMPLETED = 'completed',
    NEGOTIATE = 'negotiate',
    CANCELED = 'canceled',
    EXPIRED = 'expired',
}
