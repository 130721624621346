import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../store/rootReducer';
import {Button, Col, Row} from 'react-bootstrap';
import {IInfluencerDistribution} from '../../../dto/IInfluencerDistribution';
import {CustomAutocomplete} from '../../../../../../shared/components/Autocomplete';

interface IOptionalItemMobileProps {
    optionalItem: IInfluencerDistribution;
    createAdditionalItem: (type: string) => void;
    checkIfAdditionalAlreadyExist: boolean;
    multipleSelectHandler: (paramKey: string, paramValue: any, type: string) => void;
}

export function OptionalItemMobile({
                                       checkIfAdditionalAlreadyExist,
                                       multipleSelectHandler,
                                       optionalItem,
                                       createAdditionalItem
                                   }: IOptionalItemMobileProps) {
    const {t} = useTranslation();
    const {OfferItemDurationEnum, country} = useSelector((states: IAllStates) => states.enums);
    return (
        <>
            <div className={`${checkIfAdditionalAlreadyExist ? 'd-none' : ''}`}>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="d-flex align-items-center">
                        <h6 className="mb-1">
                            {t(`influencer.offer_management.modal.${optionalItem?.type}`)}
                        </h6>
                    </div>
                    <div>
                        <Button size="sm"
                                variant="outline-primary"
                                onClick={() => createAdditionalItem(optionalItem?.type)}>
                            {t('general.button.add')}
                        </Button>
                    </div>
                </div>

                <Row>
                    <Col xs={4}>
                        <h6 className="text-muted">{t('influencer.offer_management.modal.details')}:</h6>
                    </Col>
                    <Col xs={8}>
                        <p>{optionalItem?.typeDescription}</p>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs={4}>
                        <h6 className="text-muted">{t('brand.campaign.book.offer.modal.duration')}:</h6>
                    </Col>
                    <Col xs={8}>
                        <CustomAutocomplete dataArray={OfferItemDurationEnum}
                                            isMultiple={false}
                                            isWithImage={false}
                                            inputLabel="Publication Period"
                                            queryFilterKey="duration"
                                            defaultArray={optionalItem?.duration}
                                            inputLabelTranslation={`Duration`}
                                            handleChangeParams={(_, e) => multipleSelectHandler(_, e, optionalItem?.type)}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={4}>
                        <h6 className="text-muted">{t('brand.campaign.book.offer.modal.location')}:</h6>
                    </Col>
                    <Col xs={8}>
                        {!!optionalItem?.location ?
                            < CustomAutocomplete dataArray={country} isWithImage={true} inputLabel="country"
                                                 isMultiple={true}
                                                 defaultArray={optionalItem?.location ? optionalItem?.location : []}
                                                 photoLabel="countryFlag"
                                                 queryFilterKey="location"
                                                 inputLabelTranslation={`${t('brand.campaign.book.offer.filters.location')}`}
                                                 handleChangeParams={(_, e) => multipleSelectHandler(_, e, optionalItem.type)}/>
                            : null
                        }
                    </Col>
                </Row>
            </div>
        </>
    );
};
