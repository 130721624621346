import React, {SetStateAction, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CategoryMultiSelect} from '../../../../brand/campaign/briefing/components/shared/CategoryMultiSelect';
import {IMainPlatforms} from '../../../../../model/briefing/step1/IMainPlatforms';
import {User} from '../../../../../model/user/User';
import {BookingService} from '../../../../brand/campaign/book/service';
import {ErrorToast, WarningToast} from '../../../../../utils/toasters';
import {IProfileData} from '../../profile-view';

interface ICategory {
    name: string;
    path: string;
    isSelected: boolean;
    id: number;
}

interface IProfileCategoriesProps {
    state: User;
    setState: React.Dispatch<SetStateAction<IProfileData>>;
}

const ProfileCategories = ({state, setState}: IProfileCategoriesProps) => {
    const [categories, setCategories] = useState<ICategory[]>([]);
    const {t} = useTranslation();

    const handleSelectCategory = (catId: number) => {
        const copy = [...categories];
        const indexToReplace = copy?.findIndex(item => item?.id === catId);
        if (copy.filter(x => x.isSelected === true).length > 2) {
            WarningToast(t('general.toasts.warnSelectCategory'));
            if (!copy[indexToReplace].isSelected) {
                return;
            }
        }
        copy[indexToReplace] = {
            ...copy[indexToReplace],
            isSelected: !copy[indexToReplace]?.isSelected,
        };
        const dataToSend = copy?.filter(item => item.isSelected);
        setState(prev => ({
            ...prev, user: {
                ...prev.user,
                categoriesArray: dataToSend,
            },
        }));
        setCategories(copy as ICategory[]);
    };
    useEffect(() => {
        if (state?.id) {
            BookingService.getCategory().then(response => {
                const filtered = response?.map(item => ({
                    id: item?.id,
                    name: item?.name,
                    path: `/icons/categories/${item?.name}.svg`,
                    isSelected: !!state?.categoriesArray?.some(cat => cat.id === item.id),
                }));
                setCategories(filtered as unknown as ICategory[]);
            }).catch(error => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, [state?.id]);
    return (
        <div className="mb-4">
            <h6>
                {t('influencer.profile.categories')}
            </h6>
            <span className="text-muted">
                {t('influencer.profile.categories_desc')}
            </span>
            <div className="d-flex flex-wrap">
                {categories?.map((category) => (
                    <CategoryMultiSelect item={category as unknown as IMainPlatforms}
                                        name={t(`general.category.${category?.name}`)}
                                        clickHandler={() => handleSelectCategory(category?.id)}
                                        isActive={category?.isSelected}
                                        key={category?.name}
                />))}
            </div>
        </div>
    );
};

export default ProfileCategories;
