import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { User } from '../../../model/user/User';
import { CustomImageHandler } from '../CustomImageHandler';
import { TextField } from '@mui/material';

interface IProps {
    id?: string;
    value: string;
    possibleMentions: User[];
    changeMessageHandler: (event: any) => void;
    sendMessage: (e: any) => void;
    canMention?: boolean;
}

const mentionStyle = {
    control: {
        fontSize: '16px',
        width: '100%',
        paddingBottom: '10px',
        minHeight: '60px',
        boxSizing: 'border-box',
        border: 'none',
        outline: 'none',
    },
    highlighter: {
        boxSizing: 'border-box',
        overflow: 'hidden',
        height: '60px',
        span: {
            fontSize: '12px',
        },
        strong: {
            fontSize: '12px',
        },
        substring: {
            fontSize: '12px',
            letterSpacing: 'inherit',
            fontFamily: 'inherit',
        }
    },
    input: {
        border: 'none',
        outline: 'none',
        height: '60px',
        overflow: 'auto',
        fontSize: '12px',
        lineHeight: '24.5px',
    },
    suggestions: {
        top: 'unset',
        bottom: '50px',
        maxWidth: 'max-content',
        list: {
            backgroundColor: '#fff',
            border: '1px solid rgba(0,0,0,0.15)',
            maxHeight: '100px',
            overflowY: 'auto',
            fontSize: '12px',
        },
        item: {
            padding: '5px 15px',
            borderBottom: '1px solid rgba(0,0,0,0.15)',
            '&focused': {
                backgroundColor: '#cee4e5',
            },
        },
    }
};

const MentionsTextArea = forwardRef((props: any, ref) => {
    return (
        <MentionsInput
            {...props}
            value={props.value}
            style={mentionStyle}
            inputRef={ref}
            id={props?.id}
        />
    )
});

const MentionInputField: React.FC<IProps> = ({
    value,
    id,
    sendMessage,
    possibleMentions,
    changeMessageHandler,
    canMention = true
}) => {
    const { t } = useTranslation();

    return (
        <TextField
            fullWidth
            multiline
            minRows={3}
            maxRows={5}
            variant="outlined"
            label={canMention ? t('brand.campaign.messages.placeholder') : t('general.button.comment')}
            InputLabelProps={{
                sx: {
                  fontSize: '14px',
                },
              }}
            sx={{
                '& .MuiOutlinedInput-root': {
                    paddingBottom: '0px',
                    '& fieldset': {
                      borderColor: '#e4e6ef',
                      fontSize: '16px'
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgb(5, 187, 201)', // Hover state border color
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'rgb(5, 187, 201)', // Focused state border color
                    },
                    '& .MuiOutlinedInput-input': {
                        maxHeight: '80px',
                        fontSize: '12px',
                    },
                  },
              }}
            InputProps={{
                inputComponent: MentionsTextArea,
                inputProps: {
                    value,
                    id: id,
                    // placeholder: t('brand.campaign.messages.placeholder'),
                    onKeyDown: (e: React.KeyboardEvent) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            sendMessage(e as any);
                        }
                    },
                    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => changeMessageHandler(e),
                    children: (
                        <Mention
                            trigger="@"
                            displayTransform={(id, display) => `@${display}`}
                            style={{ backgroundColor: '#cee4e5', fontSize: '12px' }}
                            appendSpaceOnAdd
                            renderSuggestion={(suggestion) => (
                                <>
                                    <CustomImageHandler
                                        classes="user-img-rounded mr-1 chat-input"
                                        photoPath={(suggestion as User)?.profilePhoto?.path}
                                        altTag="User Profile"
                                    />
                                    {suggestion?.display}
                                </>
                            )}
                            data={possibleMentions?.map((user) => ({
                                ...user,
                                display: user?.displayName || `${user?.firstName} ${user?.lastName}`,
                            }))}
                        />
                    ),
                },
            }}
        />
    );
};

export default MentionInputField;
