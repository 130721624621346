import React, {useEffect, Fragment} from 'react';
import {withRouter} from 'react-router-dom';

function ScrollToTop({history, children}: any) {
    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        }
        // eslint-disable-next-line
    }, []);
    return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);