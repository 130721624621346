import {Country} from '../shared/Country';

export interface IBrandedContent {
    id: number;
    mediaType: string;
    duration: string;
    description: string;
    countries: Country[];
    budget: number;
    goal: string;
    countriesArray: string[];
}

export interface IBrandedContentRequest {
    id: number;
    goal: string;
    duration: string;
    description: string;
    budget: number;
    countriesArray: string[];
}

export const initialBrandedContentData = {
    budget: 0,
    countriesArray: [],
    description: '',
    duration: '',
    goal: '',
    id: 0,
}