import React from 'react';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {AdditionalItem} from '../../components/AdditionalItem';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {setActiveTab} from '../../../../../store/influencer/offerInfluencerSlice';
import {clearHtmlFromString, getFirstPreviewImage} from '../../../../../shared/functions/Functions';

export function InfluencerProfileInfo() {
    const {t} = useTranslation();
    const {campaign} = useSelector((state: IAllStates) => state.offerInfluencer);
    const dispatch = useDispatch();
    return (
        <>
            <Card className="d-none d-xl-flex">
                <Card.Body className="d-flex flex-column">
                    {getFirstPreviewImage('style_uploaded_image', campaign?.campaignImages, 'default-radius img-cover')}
                    {campaign?.offers[0]?.status &&
                        <p className={`place-top-right status ${campaign?.offers[0]?.status} d-md-none mr-3`}>
                            {campaign?.offers[0]?.status}
                        </p>
                    }
                    <h5 className="my-3">
                        {campaign?.isCharity && (
                             <>
                             <div className='d-inline-block'>(</div>
                             <div className='text-primary-dark d-inline-block'>{t('general.button.barter_deal')}</div>
                             <div className='d-inline-block'>)</div>
                             </>
                        )} {campaign?.title}
                    </h5>
                    <h6>{t('influencer.explore.contentBriefing')}</h6>
                    <div className="five-line-text">
                        {clearHtmlFromString(campaign?.adDescription ?? '-')}
                    </div>
                    <a className="w-100 text-right text-muted cursor-pointer mb-3" href="#1"
                       onClick={() => dispatch(setActiveTab(1))}>{t('general.read_more')}</a>
                    <div className="d-flex flex-wrap">
                        {campaign?.hasByLaws && <AdditionalItem itemName="bylaws"/>}
                        {!!campaign?.brandedContent && <AdditionalItem itemName="branded_content"/>}
                        {!!campaign?.usageRights && <AdditionalItem itemName="usage_right"/>}
                        {campaign?.hasExclusivity && <AdditionalItem itemName="exclusivity"/>}
                    </div>
                </Card.Body>
            </Card>
            <div className="d-xl-none mr-1 d-flex align-items-center">
                <div>
                    {getFirstPreviewImage('style_uploaded_image', campaign?.campaignImages, 'campaign-icon')}
                </div>
                <h5 className="mb-0 ml-2">
                    {campaign?.isCharity && (
                        <>
                        <div className='d-inline-block'>(</div>
                        <div className='text-primary-dark d-inline-block'>{t('general.button.barter_deal')}</div>
                        <div className='d-inline-block'>)</div>
                        </>
                    )} {campaign?.title}
                </h5>
                {/* {campaign?.isCharity &&
                    <span className="status pending text-warning ml-2">
                        {t('general.charity')}
                    </span>
                } */}
            </div>
        </>
    );
}
