interface iIcon {
    width?: string;
    height?: string;
    fill?: string;
    classes?: string;
    color?: string;
    onClick?: () => void;
}

export const Invoice = ({width, height, classes, color}: iIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill={color} width={width} height={height} className={classes}
             viewBox="0 0 18.01 18.792">
            <path id="file-invoice-dollar"
                  d="M12.745,18.009a.783.783,0,0,1-.783.783H4.915A3.92,3.92,0,0,1,1,14.877V3.915A3.92,3.92,0,0,1,4.915,0H8.45a5.448,5.448,0,0,1,3.876,1.605l2.728,2.73a5.51,5.51,0,0,1,.586.691.783.783,0,1,1-1.274.91,3.9,3.9,0,0,0-.418-.493l-2.729-2.73a3.924,3.924,0,0,0-.823-.63v3.4a.784.784,0,0,0,.783.783h2.349a.783.783,0,0,1,0,1.566H11.179A2.352,2.352,0,0,1,8.83,5.481v-3.9c-.125-.012-.252-.018-.38-.018H4.915A2.352,2.352,0,0,0,2.566,3.915V14.877a2.352,2.352,0,0,0,2.349,2.349h7.047A.783.783,0,0,1,12.745,18.009ZM17.2,12.737l-2.381-.4a.6.6,0,0,1-.5-.6.784.784,0,0,1,.783-.783H16.87a.788.788,0,0,1,.679.392A.783.783,0,1,0,18.9,10.57,2.356,2.356,0,0,0,16.869,9.4h-.21V8.612a.783.783,0,1,0-1.566,0V9.4a2.352,2.352,0,0,0-2.349,2.349,2.163,2.163,0,0,0,1.813,2.14l2.381.4a.6.6,0,0,1,.5.6.784.784,0,0,1-.783.783H14.884a.788.788,0,0,1-.679-.392.783.783,0,1,0-1.355.784,2.355,2.355,0,0,0,2.034,1.174h.21v.783a.783.783,0,0,0,1.566,0v-.783a2.352,2.352,0,0,0,2.349-2.349,2.163,2.163,0,0,0-1.813-2.14ZM6.09,11.745H10.4a.783.783,0,0,0,0-1.566H6.09a1.96,1.96,0,0,0-1.958,1.958V13.7A1.96,1.96,0,0,0,6.09,15.66H10.4a.783.783,0,0,0,0-1.566H6.09A.392.392,0,0,1,5.7,13.7V12.137a.392.392,0,0,1,.392-.392ZM4.915,8.613H6.481a.783.783,0,0,0,0-1.566H4.915a.783.783,0,0,0,0,1.566Zm0-3.132H6.481a.783.783,0,1,0,0-1.566H4.915a.783.783,0,1,0,0,1.566Z"
                  transform="translate(-1)" fill="#9ea5ab"/>
        </svg>
    );
};

export const IconToDo = ({width = '20', height = '20', classes, color, fill = '#9ea5ab'}: iIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill={color} width={width} height={height} className={classes}
             viewBox="0 0 19.888 19.908">
            <path id="booking"
                  d="M3.2,15.715a.83.83,0,0,1-1.133.3A4.158,4.158,0,0,1,0,12.429V5.8A4.149,4.149,0,0,1,4.143,1.657V.829A.829.829,0,1,1,5.8.829v.829h5.8V.829a.829.829,0,0,1,1.657,0v.829A4.148,4.148,0,0,1,17.4,5.8v6.629a.829.829,0,0,1-1.657,0V7.458h-.829a.829.829,0,1,1,0-1.657h.829a2.489,2.489,0,0,0-2.486-2.486H4.143A2.489,2.489,0,0,0,1.657,5.8H8.286a.829.829,0,0,1,0,1.657H1.657v4.972A2.5,2.5,0,0,0,2.9,14.583a.828.828,0,0,1,.3,1.132Zm14-.319-3.107-1.161v-4.18A2.554,2.554,0,0,0,12.02,7.514a2.487,2.487,0,0,0-2.9,2.452v6.267l-.841-.67c-.006,0-.013,0-.019-.01A2.482,2.482,0,0,0,4.928,19.23l.462.446a.829.829,0,0,0,1.15-1.193l-.471-.454A.829.829,0,0,1,7.2,16.815L9.435,18.6a.828.828,0,0,0,1.345-.648V9.963a.83.83,0,0,1,.974-.817.9.9,0,0,1,.684.906v4.755a.829.829,0,0,0,.539.776l3.645,1.362a2.488,2.488,0,0,1,1.61,2.164.829.829,0,0,0,.826.775c.018,0,.036,0,.055,0A.83.83,0,0,0,19.885,19,4.147,4.147,0,0,0,17.2,15.4Z"
                  transform="translate(0.001)" fill={fill}/>
        </svg>
    );
};

export const IconBook = ({width, height, classes, color}: iIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" fill={color} className={classes} viewBox="0 0 24 24"
             width={width} height={height}>
            <path
                d="M24,7v1c0,.552-.447,1-1,1s-1-.448-1-1v-1c0-1.654-1.346-3-3-3H5c-1.654,0-3,1.346-3,3v10c0,1.654,1.346,3,3,3h3c.553,0,1,.448,1,1s-.447,1-1,1h-3c-2.757,0-5-2.243-5-5V7C0,4.243,2.243,2,5,2h14c2.757,0,5,2.243,5,5Zm-9,2h4c.553,0,1-.448,1-1s-.447-1-1-1h-4c-.553,0-1,.448-1,1s.447,1,1,1Zm8.121,2.879c.566,.566,.879,1.32,.879,2.121s-.313,1.555-.879,2.122l-6.707,6.707c-.755,.755-1.76,1.172-2.828,1.172h-1.586c-.553,0-1-.448-1-1v-1.586c0-1.068,.416-2.073,1.172-2.828l6.707-6.707c1.17-1.17,3.072-1.17,4.242,0Zm-1.121,2.121c0-.267-.104-.518-.293-.707-.391-.391-1.023-.39-1.414,0l-6.707,6.707c-.372,.373-.586,.888-.586,1.414v.586h.586c.534,0,1.036-.208,1.414-.586l6.707-6.707c.189-.189,.293-.44,.293-.707Zm-15,3h2c1.654,0,3-1.346,3-3,0-.552-.447-1-1-1s-1,.448-1,1-.448,1-1,1h-2c-.552,0-1-.449-1-1v-1h2c.553,0,1-.448,1-1s-.447-1-1-1h-2v-1c0-.551,.448-1,1-1h2c.552,0,1,.449,1,1s.447,1,1,1,1-.448,1-1c0-1.654-1.346-3-3-3h-2c-1.654,0-3,1.346-3,3v1c-.553,0-1,.448-1,1s.447,1,1,1v1c0,1.654,1.346,3,3,3Zm7-5c0,.552,.448,1,1,1s1-.448,1-1-.448-1-1-1-1,.448-1,1Z"/>
        </svg>
    );
};

export const IconManagement = ({width, height, classes, color}: iIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" fill={color} className={classes} viewBox="0 0 24 24"
             width={width} height={height}>
            <path id="users-alt"
                  d="M12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,12,10Zm6,13A6,6,0,0,0,6,23a1,1,0,0,0,2,0,4,4,0,0,1,8,0,1,1,0,0,0,2,0ZM18,8a4,4,0,1,1,4-4A4,4,0,0,1,18,8Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,18,2Zm6,13a6.006,6.006,0,0,0-6-6,1,1,0,0,0,0,2,4,4,0,0,1,4,4,1,1,0,0,0,2,0ZM6,8a4,4,0,1,1,4-4A4,4,0,0,1,6,8ZM6,2A2,2,0,1,0,8,4,2,2,0,0,0,6,2ZM2,15a4,4,0,0,1,4-4A1,1,0,0,0,6,9a6.006,6.006,0,0,0-6,6,1,1,0,0,0,2,0Z"/>
        </svg>
    );
};
export const IconBundle = ({width, height, classes, color}: iIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={classes} fill={color}
             viewBox="0 0 17.219 20.662">
            <path id="ballot-check"
                  d="M10.348,10.088l-1.6,1.554a1.412,1.412,0,0,1-1,.412,1.44,1.44,0,0,1-1.018-.421l-.586-.563A.861.861,0,0,1,7.332,9.828l.407.392,1.41-1.367a.861.861,0,1,1,1.2,1.236Zm3.705,4.547H11.47a.861.861,0,1,0,0,1.722h2.583a.861.861,0,1,0,0-1.722Zm0-10.331H11.47a.861.861,0,1,0,0,1.722h2.583a.861.861,0,1,0,0-1.722Zm0,5.166H12.331a.861.861,0,1,0,0,1.722h1.722a.861.861,0,1,0,0-1.722ZM7.6,14.205A1.291,1.291,0,1,0,8.888,15.5,1.292,1.292,0,0,0,7.6,14.205Zm0-7.748A1.291,1.291,0,1,0,6.3,5.166,1.292,1.292,0,0,0,7.6,6.457ZM19.219,4.3V16.358a4.31,4.31,0,0,1-4.3,4.3H6.3a4.31,4.31,0,0,1-4.3-4.3V4.3A4.31,4.31,0,0,1,6.3,0h8.609A4.31,4.31,0,0,1,19.219,4.3ZM17.5,4.3a2.586,2.586,0,0,0-2.583-2.583H6.3A2.586,2.586,0,0,0,3.722,4.3V16.358A2.586,2.586,0,0,0,6.3,18.941h8.609A2.586,2.586,0,0,0,17.5,16.358Z"
                  transform="translate(-2)"/>
        </svg>
    );
};

export const IconManage = ({width, height, classes, color}: iIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" fill={color} className={classes} viewBox="0 0 24 24"
             width={width} height={height}>
            <path
                d="M17,10c-3.859,0-7,3.14-7,7s3.141,7,7,7,7-3.14,7-7-3.141-7-7-7Zm0,12c-2.757,0-5-2.243-5-5s2.243-5,5-5,5,2.243,5,5-2.243,5-5,5Zm1.707-4.707c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-1-1c-.188-.188-.293-.442-.293-.707v-2c0-.552,.447-1,1-1s1,.448,1,1v1.586l.707,.707Zm-9.707,4.707H5c-1.654,0-3-1.346-3-3v-5h6c.553,0,1-.448,1-1s-.447-1-1-1H2v-3c0-1.654,1.346-3,3-3h14c1.654,0,3,1.346,3,3,0,.552,.447,1,1,1s1-.448,1-1c0-2.757-2.243-5-5-5h-1.101c-.465-2.279-2.485-4-4.899-4h-2c-2.414,0-4.435,1.721-4.899,4h-1.101C2.243,4,0,6.243,0,9v10c0,2.757,2.243,5,5,5h4c.553,0,1-.448,1-1s-.447-1-1-1ZM11,2h2c1.304,0,2.415,.836,2.828,2h-7.656c.413-1.164,1.524-2,2.828-2Z"/>
        </svg>
    );
};

export const IconMeasure = ({width, height, classes, color}: iIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" fill={color} className={classes} viewBox="0 0 24 24"
             width={width} height={height}>
            <path
                d="M22.5,3.976H19.414a1,1,0,0,0-.707,1.707l1.086,1.086h0l-3.586,3.585a1.024,1.024,0,0,1-1.415,0l-.17-.171a3.071,3.071,0,0,0-4.243,0l-5.1,5.1A1,1,0,1,0,6.7,16.7l5.1-5.1a1.023,1.023,0,0,1,1.415,0l.17.171a3.073,3.073,0,0,0,4.243,0l3.586-3.586h0l1.086,1.086A1,1,0,0,0,24,8.562V5.476A1.5,1.5,0,0,0,22.5,3.976Z"/>
            <path d="M23,21.976H5a3,3,0,0,1-3-3V1A1,1,0,0,0,0,1V18.976a5.006,5.006,0,0,0,5,5H23a1,1,0,0,0,0-2Z"/>
        </svg>
    );
};

export const IconMessage = ({width, height, classes, color}: iIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" fill={color} className={classes} viewBox="0 0 24 24"
             width={width} height={height}>
            <path
                d="M24,16v8H16a8,8,0,0,1-6.92-4,10.968,10.968,0,0,0,2.242-.248A5.988,5.988,0,0,0,16,22h6V16a5.988,5.988,0,0,0-2.252-4.678A10.968,10.968,0,0,0,20,9.08,8,8,0,0,1,24,16ZM18,9A9,9,0,0,0,0,9v9H9A9.01,9.01,0,0,0,18,9ZM2,9a7,7,0,1,1,7,7H2Z"/>
        </svg>
    );
};

export const BestPicks = ({width, height, classes, color}: iIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill={color} width={width} className={classes} height={height}
             viewBox="0 0 24 24">
            <g id="Icon_ionic-md-ribbon" data-name="Icon ionic-md-ribbon" transform="translate(-4.5 -2.25)">
                <path id="Path_1430" data-name="Path 1430"
                      d="M14.773,2.25a5.773,5.773,0,1,0,5.773,5.773A5.772,5.772,0,0,0,14.773,2.25Zm0,9.381a3.608,3.608,0,1,1,3.608-3.608A3.607,3.607,0,0,1,14.773,11.631Z"
                      transform="translate(-1.614)"/>
                <g id="Group_2842" data-name="Group 2842" transform="translate(4.5 12.93)">
                    <path id="Path_1431" data-name="Path 1431"
                          d="M10.35,20.641a7.138,7.138,0,0,1-2.291-1.547c-.063-.063-.126-.126-.185-.194L4.5,24.817H8.83l2.165,3.608,2.165-4.772,1.15-2.535a7.51,7.51,0,0,1-1.15.09A7.143,7.143,0,0,1,10.35,20.641Z"
                          transform="translate(-4.5 -18.9)"/>
                    <path id="Path_1432" data-name="Path 1432"
                          d="M23.7,18.907c-.059.063-.122.131-.185.194a7.156,7.156,0,0,1-2.287,1.542l-1.1,2.426-.988,2.183,1.439,3.175,2.165-3.608h4.33Z"
                          transform="translate(-9.747 -18.903)"/>
                </g>
            </g>
        </svg>

    );
};
export const ExploreIcon = ({width, height, classes, color}: iIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill={color} className={classes} width={width} height={height}
             viewBox="0 0 16.616 18.127">
            <path id="search-alt"
                  d="M12.574,4.532a.755.755,0,1,1,0,1.511H6.532a.755.755,0,0,1,0-1.511ZM18.4,17.906a.755.755,0,0,1-1.068,0l-1.818-1.818a3.425,3.425,0,1,1,1.068-1.068L18.4,16.838A.755.755,0,0,1,18.4,17.906Zm-4.688-2.8a1.888,1.888,0,1,0-1.888-1.888A1.888,1.888,0,0,0,13.707,15.106Zm-3.4,1.511H5.776a2.266,2.266,0,0,1-2.266-2.266V3.776A2.266,2.266,0,0,1,5.776,1.511H14.84a.755.755,0,0,1,.755.755V8.308a.755.755,0,0,0,1.511,0V2.266A2.266,2.266,0,0,0,14.84,0H5.776A3.781,3.781,0,0,0,2,3.776V14.351a3.781,3.781,0,0,0,3.776,3.776h4.532a.755.755,0,1,0,0-1.511Z"
                  transform="translate(-2)" fill="#9ea5ab"/>
        </svg>
    );
};
export const DashboardIcon = ({width, height, classes, color}: iIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} className={classes}
             viewBox="0 0 19.064 19.064">
            <g id="apps" transform="translate(0)">
                <path id="Path_2130" data-name="Path 2130"
                      d="M5.56,0H3.177A3.177,3.177,0,0,0,0,3.177V5.56A3.177,3.177,0,0,0,3.177,8.738H5.56A3.177,3.177,0,0,0,8.738,5.56V3.177A3.177,3.177,0,0,0,5.56,0ZM7.149,5.56A1.589,1.589,0,0,1,5.56,7.149H3.177A1.589,1.589,0,0,1,1.589,5.56V3.177A1.589,1.589,0,0,1,3.177,1.589H5.56A1.589,1.589,0,0,1,7.149,3.177Z"
                      fill="#fff"/>
                <path id="Path_2131" data-name="Path 2131"
                      d="M18.56,0H16.177A3.177,3.177,0,0,0,13,3.177V5.56a3.177,3.177,0,0,0,3.177,3.177H18.56A3.177,3.177,0,0,0,21.738,5.56V3.177A3.177,3.177,0,0,0,18.56,0Zm1.589,5.56A1.589,1.589,0,0,1,18.56,7.149H16.177A1.589,1.589,0,0,1,14.589,5.56V3.177a1.589,1.589,0,0,1,1.589-1.589H18.56a1.589,1.589,0,0,1,1.589,1.589Z"
                      transform="translate(-2.673)" fill="#fff"/>
                <path id="Path_2132" data-name="Path 2132"
                      d="M5.56,13H3.177A3.177,3.177,0,0,0,0,16.177V18.56a3.177,3.177,0,0,0,3.177,3.177H5.56A3.177,3.177,0,0,0,8.738,18.56V16.177A3.177,3.177,0,0,0,5.56,13Zm1.589,5.56A1.589,1.589,0,0,1,5.56,20.149H3.177A1.589,1.589,0,0,1,1.589,18.56V16.177a1.589,1.589,0,0,1,1.589-1.589H5.56a1.589,1.589,0,0,1,1.589,1.589Z"
                      transform="translate(0 -2.673)" fill="#fff"/>
                <path id="Path_2133" data-name="Path 2133"
                      d="M18.56,13H16.177A3.177,3.177,0,0,0,13,16.177V18.56a3.177,3.177,0,0,0,3.177,3.177H18.56a3.177,3.177,0,0,0,3.177-3.177V16.177A3.177,3.177,0,0,0,18.56,13Zm1.589,5.56a1.589,1.589,0,0,1-1.589,1.589H16.177a1.589,1.589,0,0,1-1.589-1.589V16.177a1.589,1.589,0,0,1,1.589-1.589H18.56a1.589,1.589,0,0,1,1.589,1.589Z"
                      transform="translate(-2.673 -2.673)" fill="#fff"/>
            </g>
        </svg>

    );
};
export const MessagesIcon = ({width, height, classes, color}: iIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} className={classes}
             viewBox="0 0 19.569 19.57">
            <path id="comments"
                  d="M19.569,13.046v4.077a2.446,2.446,0,0,1-2.446,2.446H13.046A6.523,6.523,0,0,1,7.4,16.307a8.943,8.943,0,0,0,1.828-.2,4.883,4.883,0,0,0,3.814,1.833h4.077a.815.815,0,0,0,.815-.815V13.046A4.882,4.882,0,0,0,16.1,9.232,8.943,8.943,0,0,0,16.308,7.4,6.523,6.523,0,0,1,19.569,13.046ZM14.658,7.869A7.338,7.338,0,0,0,6.808.019,7.679,7.679,0,0,0,0,7.578v4.11c0,2.065,1.229,2.989,2.446,2.989H7.094a7.68,7.68,0,0,0,7.564-6.808ZM11.375,3.3a5.722,5.722,0,0,1,1.657,4.452,6,6,0,0,1-5.938,5.291H2.446c-.757,0-.815-1.04-.815-1.358V7.578A6,6,0,0,1,6.923,1.645q.2-.015.406-.015A5.717,5.717,0,0,1,11.375,3.3Z"
                  transform="translate(0 0.001)" fill="#9ea5ab"/>
        </svg>
    );
};
export const OffersIcon = ({width, height, classes, color}: iIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} className={classes}
             viewBox="0 0 22.118 20.301">
            <path id="book"
                  d="M21.818,6.545v.909a.909.909,0,1,1-1.818,0V6.545a2.731,2.731,0,0,0-2.727-2.727H4.545A2.731,2.731,0,0,0,1.818,6.545v9.091a2.731,2.731,0,0,0,2.727,2.727H7.273a.909.909,0,1,1,0,1.818H4.545A4.551,4.551,0,0,1,0,15.636V6.545A4.551,4.551,0,0,1,4.545,2H17.273A4.551,4.551,0,0,1,21.818,6.545ZM13.636,8.364h3.636a.909.909,0,1,0,0-1.818H13.636a.909.909,0,0,0,0,1.818Zm7.383,2.617a2.727,2.727,0,0,1,0,3.857l-6.1,6.1A3.614,3.614,0,0,1,12.351,22H10.909A.909.909,0,0,1,10,21.092V19.65a3.61,3.61,0,0,1,1.065-2.571L13.1,15.047l4.066-4.066a2.729,2.729,0,0,1,3.856,0ZM20,12.909a.909.909,0,0,0-1.552-.643l-6.1,6.1a1.834,1.834,0,0,0-.533,1.285v.533h.533a1.806,1.806,0,0,0,1.285-.533l6.1-6.1A.9.9,0,0,0,20,12.909ZM6.364,15.636H8.182a2.731,2.731,0,0,0,2.727-2.727.909.909,0,1,0-1.818,0,.909.909,0,0,1-.909.909H6.364a.91.91,0,0,1-.909-.909V12H7.273a.909.909,0,1,0,0-1.818H5.455V9.273a.91.91,0,0,1,.909-.909H8.182a.91.91,0,0,1,.909.909.909.909,0,0,0,1.818,0A2.731,2.731,0,0,0,8.182,6.545H6.364A2.731,2.731,0,0,0,3.636,9.273v.909a.909.909,0,0,0,0,1.818v.909A2.731,2.731,0,0,0,6.364,15.636Zm6.364-4.545a.909.909,0,1,0,.909-.909A.909.909,0,0,0,12.727,11.091Z"
                  transform="translate(0.15 -1.85)" fill="#9ea5ab" stroke="#fff" strokeWidth="0.3"/>
        </svg>

    );
};
export const PlannerIcon = ({width, height, classes, color}: iIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} className={classes}
             viewBox="0 0 19.232 19.232">
            <path id="calendar-clock"
                  d="M13.623,8.045a5.594,5.594,0,1,0,5.609,5.578A5.616,5.616,0,0,0,13.623,8.045Zm0,9.585a3.991,3.991,0,1,1,4.007-4.007A4,4,0,0,1,13.623,17.63Zm1.368-3.772a.8.8,0,1,1-1.133,1.133l-.8-.8a.8.8,0,0,1-.235-.567v-1.6a.8.8,0,0,1,1.6,0v1.271Zm4.242-8.248v1.6a.8.8,0,0,1-1.6,0v-1.6a2.407,2.407,0,0,0-2.4-2.4H4.007a2.407,2.407,0,0,0-2.4,2.4v.8H8.815a.8.8,0,1,1,0,1.6H1.6v7.212a2.407,2.407,0,0,0,2.4,2.4H7.212a.8.8,0,0,1,0,1.6H4.007A4.011,4.011,0,0,1,0,15.226V5.609A4.011,4.011,0,0,1,4.007,1.6h.8V.8a.8.8,0,1,1,1.6,0v.8h6.411V.8a.8.8,0,0,1,1.6,0v.8h.8a4.011,4.011,0,0,1,4.007,4.007Z"
                  fill="#9ea5ab"/>
        </svg>
    );
};

// export const MessagesIcon = ({width, height, classes, color}: iIcon) => {
//     return (
//         <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 21.345 21.345">
//             <path id="comments"
//                   d="M21.345,14.23v4.447a2.668,2.668,0,0,1-2.668,2.668H14.23a7.115,7.115,0,0,1-6.154-3.557,9.754,9.754,0,0,0,1.994-.221,5.326,5.326,0,0,0,4.16,2h4.447a.889.889,0,0,0,.889-.889V14.23a5.325,5.325,0,0,0-2-4.16,9.754,9.754,0,0,0,.224-1.994A7.115,7.115,0,0,1,21.345,14.23ZM15.988,8.583A8,8,0,0,0,7.425.02,8.376,8.376,0,0,0,0,8.266v4.482c0,2.252,1.34,3.26,2.668,3.26H7.737a8.377,8.377,0,0,0,8.251-7.425ZM12.407,3.6a6.242,6.242,0,0,1,1.807,4.856A6.549,6.549,0,0,1,7.737,14.23H2.668c-.825,0-.889-1.134-.889-1.482V8.266A6.547,6.547,0,0,1,7.551,1.795q.221-.016.443-.016A6.236,6.236,0,0,1,12.407,3.6Z"
//                   transform="translate(0 0.001)" fill="#9ea5ab"/>
//         </svg>
//
//     )
// }

export const ReportingIcon = ({width, height, classes, color}: iIcon) => {
    return (
        <svg id="chat-arrow-grow" xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color}
             className={classes} viewBox="0 0 18.009 17.991">
            <path id="Path_1542" data-name="Path 1542"
                  d="M18.128,3.976H15.813a.75.75,0,0,0-.531,1.281l.815.815h0l-2.691,2.69a.768.768,0,0,1-1.062,0l-.128-.128a2.3,2.3,0,0,0-3.184,0L5.206,12.461a.753.753,0,1,0,1.066,1.063L10.1,9.7a.768.768,0,0,1,1.062,0l.128.128a2.306,2.306,0,0,0,3.184,0l2.691-2.691h0l.815.815a.75.75,0,0,0,1.276-.532V5.1A1.126,1.126,0,0,0,18.128,3.976Z"
                  transform="translate(-1.245 -0.992)" fill="#9ea5ab"/>
            <path id="Path_1543" data-name="Path 1543"
                  d="M17.259,16.491H3.752A2.251,2.251,0,0,1,1.5,14.239V.75A.75.75,0,1,0,0,.75V14.239a3.756,3.756,0,0,0,3.752,3.752H17.259a.75.75,0,1,0,0-1.5Z"
                  fill="#9ea5ab"/>
        </svg>

    );
};
export const ReplaceInfluencerIcon = ({width, height, classes, color, onClick}: iIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 36.689 24.763"
             fill={color ?? '#05bbc9'}
             className={classes} onClick={onClick}>
            <g id="Group_4920" data-name="Group 4920" transform="translate(-335.633 -84.627)">
                <g id="Group_4904" data-name="Group 4904" transform="translate(-825.406 -14.688)">
                    <g id="refresh" transform="translate(1161.039 103.501)">
                        <path id="Path_2518" data-name="Path 2518"
                              d="M9.145,1.366a6.851,6.851,0,0,1,4.863,2.049H11.877a.683.683,0,1,0,0,1.366h2.829a1.269,1.269,0,0,0,1.268-1.268V.683a.683.683,0,0,0-1.366,0V2.1A8.182,8.182,0,0,0,.985,7.444a.688.688,0,0,0,.621.748l.062,0a.671.671,0,0,0,.675-.6A6.838,6.838,0,0,1,9.145,1.366Z"
                              transform="translate(-0.982 0)" fill={color ?? '#05bbc9'}/>
                        <path id="Path_2519" data-name="Path 2519"
                              d="M56.982,256.006a.671.671,0,0,0-.675.6,6.819,6.819,0,0,1-11.666,4.181h2.132a.683.683,0,0,0,0-1.366H43.943a1.267,1.267,0,0,0-1.268,1.267h0v2.829a.683.683,0,0,0,1.366,0V262.1a8.182,8.182,0,0,0,13.623-5.341.688.688,0,0,0-.621-.748Z"
                              transform="translate(-41.34 -247.811)" fill={color ?? '#05bbc9'}/>
                    </g>
                </g>
                <path id="delete-user"
                      d="M9.286,12.382A6.191,6.191,0,1,0,3.1,6.191a6.191,6.191,0,0,0,6.191,6.191Zm0-10.318A4.127,4.127,0,1,1,5.159,6.191,4.127,4.127,0,0,1,9.286,2.064Zm9.286,21.668a1.032,1.032,0,0,1-2.064,0,7.223,7.223,0,1,0-14.445,0,1.032,1.032,0,0,1-2.064,0,9.286,9.286,0,0,1,18.572,0Z"
                      transform="translate(353.75 84.627)" fill={color}/>
            </g>
        </svg>
    );
};
export const TrashIcon = ({width, height, classes, color, onClick}: iIcon) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} onClick={onClick} fill={color}
             className={classes}
             viewBox="0 0 14.059 16.87">
            <g id="trash" transform="translate(-2)">
                <path id="Path_2191" data-name="Path 2191"
                      d="M15.356,2.812H13.177A3.521,3.521,0,0,0,9.732,0H8.326A3.521,3.521,0,0,0,4.882,2.812H2.7a.7.7,0,0,0,0,1.406h.7v9.138A3.519,3.519,0,0,0,6.921,16.87h4.218a3.519,3.519,0,0,0,3.515-3.515V4.218h.7a.7.7,0,0,0,0-1.406ZM8.326,1.406H9.732a2.113,2.113,0,0,1,1.989,1.406H6.338A2.113,2.113,0,0,1,8.326,1.406Zm4.921,11.95a2.109,2.109,0,0,1-2.109,2.109H6.921a2.109,2.109,0,0,1-2.109-2.109V4.218h8.435Z"
                      fill="#9ea5ab"/>
                <path id="Path_2192" data-name="Path 2192"
                      d="M9.7,15.623a.7.7,0,0,0,.7-.7V10.7A.7.7,0,1,0,9,10.7v4.218A.7.7,0,0,0,9.7,15.623Z"
                      transform="translate(-2.079 -2.971)" fill="#9ea5ab"/>
                <path id="Path_2193" data-name="Path 2193"
                      d="M13.7,15.623a.7.7,0,0,0,.7-.7V10.7A.7.7,0,1,0,13,10.7v4.218A.7.7,0,0,0,13.7,15.623Z"
                      transform="translate(-3.268 -2.971)" fill="#9ea5ab"/>
            </g>
        </svg>
    );
};

export const StarIcon = ({width = '29', height = '32', classes, color}: iIcon) => {
    return (
        <svg fill={color} className={classes} xmlns="http://www.w3.org/2000/svg" width={width} height={height}
             viewBox="0 0 28.952 32.096">
            <g id="Group_4554" data-name="Group 4554" transform="translate(-847.897 -359.08)">
                <g id="bg" transform="translate(847.896 359.08)">
                    <g id="Group_4527" data-name="Group 4527" transform="translate(0)">
                        <path id="Path_2460" data-name="Path 2460"
                              d="M7.9,3.886c6.374,0,10.75-3.3,13.822-3.3s9.521,1.765,9.521,15.894-8.677,16.2-12.055,16.2C3.368,32.681-3.389,3.886,7.9,3.886Z"
                              transform="translate(-2.29 -0.584)" fill="#efefef"/>
                    </g>
                </g>
                <g id="color" transform="translate(849.334 362.371)">
                    <g id="Group_4528" data-name="Group 4528" transform="translate(2.672 2.851)">
                        <path id="Path_2461" data-name="Path 2461"
                              d="M16.868,23.636l-5.611,3.355a.485.485,0,0,1-.721-.524l1.449-6.386a.485.485,0,0,0-.153-.473L6.916,15.3a.486.486,0,0,1,.276-.849l6.507-.592a.484.484,0,0,0,.4-.292l2.572-6.02a.484.484,0,0,1,.89,0l2.572,6.02a.483.483,0,0,0,.4.292l6.507.592a.486.486,0,0,1,.276.849L22.4,19.607a.486.486,0,0,0-.153.473L23.7,26.466a.485.485,0,0,1-.721.524l-5.611-3.355a.489.489,0,0,0-.5,0Z"
                              transform="translate(-6.75 -7.25)" fill="#c3e9ec"/>
                    </g>
                    <g id="Group_4531" data-name="Group 4531" transform="translate(2.671 2.851)">
                        <g id="Group_4529" data-name="Group 4529">
                            <path id="Path_2462" data-name="Path 2462"
                                  d="M14.06,20.081a.485.485,0,0,0-.153-.473L8.989,15.3a.486.486,0,0,1,.276-.849l6.269-.056a.484.484,0,0,0,.4-.292l2.218-5.17-.592-1.385a.484.484,0,0,0-.89,0L14.1,13.564a.483.483,0,0,1-.4.292l-6.507.592a.486.486,0,0,0-.276.849l4.918,4.311a.486.486,0,0,1,.153.473l-1.449,6.386a.485.485,0,0,0,.721.524l1.428-.854Z"
                                  transform="translate(-6.749 -7.249)" fill="#05bbc9"/>
                        </g>
                        <g id="Group_4530" data-name="Group 4530" transform="translate(11.403 16.318)">
                            <path id="Path_2463" data-name="Path 2463"
                                  d="M20.409,25.027a.483.483,0,0,0-.5,0l-.789.472,4.822,2.884a.485.485,0,0,0,.721-.524l-.075-.33Z"
                                  transform="translate(-19.125 -24.959)" fill="#05bbc9"/>
                        </g>
                    </g>
                    <g id="Group_4536" data-name="Group 4536" transform="translate(0 0)">
                        <g id="Group_4532" data-name="Group 4532" transform="translate(0.163)">
                            <path id="Path_2464" data-name="Path 2464" d="M0,0H.921V1.843H0Z"
                                  transform="translate(0 0.651) rotate(-45)" fill="#a4afc1"/>
                        </g>
                        <g id="Group_4533" data-name="Group 4533" transform="translate(3.908 3.746)">
                            <path id="Path_2465" data-name="Path 2465" d="M0,0H.921V1.843H0Z"
                                  transform="translate(0 0.651) rotate(-45)" fill="#a4afc1"/>
                        </g>
                        <g id="Group_4534" data-name="Group 4534" transform="translate(0 3.747)">
                            <path id="Path_2466" data-name="Path 2466" d="M0,0H1.843V.921H0Z"
                                  transform="translate(0 1.303) rotate(-45)" fill="#a4afc1"/>
                        </g>
                        <g id="Group_4535" data-name="Group 4535" transform="translate(3.745 0.002)">
                            <path id="Path_2467" data-name="Path 2467" d="M0,0H1.843V.921H0Z"
                                  transform="translate(0 1.303) rotate(-45)" fill="#a4afc1"/>
                        </g>
                    </g>
                    <g id="Group_4537" data-name="Group 4537" transform="translate(23.516 4.464)">
                        <path id="Path_2468" data-name="Path 2468"
                              d="M30.753,11.764a1.382,1.382,0,1,1,1.382-1.382,1.384,1.384,0,0,1-1.382,1.382Zm0-1.843a.461.461,0,1,0,.461.461A.461.461,0,0,0,30.753,9.921Z"
                              transform="translate(-29.371 -9)" fill="#a4afc1"/>
                    </g>
                </g>
                <g id="regular" transform="translate(851.316 364.531)">
                    <g id="Group_4538" data-name="Group 4538" transform="translate(0)">
                        <path id="Path_2469" data-name="Path 2469"
                              d="M10.95,27.692A1.177,1.177,0,0,1,9.8,26.255l1.423-6.267L6.4,15.757A1.177,1.177,0,0,1,7.07,13.7l6.384-.58,2.524-5.906a1.175,1.175,0,0,1,2.161,0l2.524,5.907,1.064.1L21.6,14.594l-1.189-.108a1.174,1.174,0,0,1-.977-.712L17.057,8.2l-2.382,5.575a1.171,1.171,0,0,1-.974.71l-6.028.547,4.556,4a1.173,1.173,0,0,1,.372,1.146L11.259,26.09l5.2-3.106a1.175,1.175,0,0,1,1.206,0l5.2,3.107-1.343-5.915a1.175,1.175,0,0,1,.372-1.145l4.556-4-3-.272.126-1.377,3.475.316a1.177,1.177,0,0,1,.667,2.057l-4.825,4.23,1.423,6.267a1.175,1.175,0,0,1-1.749,1.27l-5.5-3.292-5.5,3.291a1.177,1.177,0,0,1-.6.167ZM13.4,13.235v0Zm7.305,0v0Z"
                              transform="translate(-6.001 -6.5)" fill="#2e3033"/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export const StarProIcon = ({width = '120', height = '60', classes, color}: iIcon) => {
    return (
        <svg fill={color} className={classes} xmlns="http://www.w3.org/2000/svg" width={width} height={height}
             viewBox="0 0 122.763 62.281">
            <g id="star" transform="translate(-20.567 -0.584)">
                <g id="bg" transform="translate(20.567 0.584)">
                    <g id="Group_4527" data-name="Group 4527" transform="translate(0)">
                        <path id="Path_2460" data-name="Path 2460"
                              d="M13.174,6.991C25.542,6.991,34.035.584,39.995.584S58.471,4.01,58.471,31.426,41.632,62.865,35.077,62.865c-30.7,0-43.807-55.875-21.9-55.875Z"
                              transform="translate(-2.29 -0.584)" fill="#efefef"/>
                    </g>
                </g>
                <g id="color" transform="translate(23.357 6.969)">
                    <g id="Group_4528" data-name="Group 4528" transform="translate(5.184 5.533)">
                        <path id="Path_2461" data-name="Path 2461"
                              d="M26.385,39.047,15.5,45.558a.94.94,0,0,1-1.4-1.017l2.813-12.391a.942.942,0,0,0-.3-.917L7.072,22.864a.942.942,0,0,1,.535-1.647l12.627-1.148a.939.939,0,0,0,.78-.567L26,7.821a.938.938,0,0,1,1.727,0L32.722,19.5a.937.937,0,0,0,.78.567l12.627,1.148a.943.943,0,0,1,.535,1.647L37.12,31.23a.943.943,0,0,0-.3.917l2.813,12.391a.94.94,0,0,1-1.4,1.017L27.35,39.046a.948.948,0,0,0-.966,0Z"
                              transform="translate(-6.75 -7.25)" fill="#c3e9ec"/>
                    </g>
                    <g id="Group_4531" data-name="Group 4531" transform="translate(5.184 5.533)">
                        <g id="Group_4529" data-name="Group 4529">
                            <path id="Path_2462" data-name="Path 2462"
                                  d="M20.935,32.149a.942.942,0,0,0-.3-.917L11.1,22.864a.942.942,0,0,1,.535-1.647l12.164-.109a.939.939,0,0,0,.78-.567l4.3-10.033L27.73,7.821a.938.938,0,0,0-1.727,0L21.012,19.5a.937.937,0,0,1-.78.567L7.605,21.217a.943.943,0,0,0-.535,1.647l9.543,8.366a.943.943,0,0,1,.3.917L14.1,44.538a.94.94,0,0,0,1.4,1.017L18.267,43.9Z"
                                  transform="translate(-6.749 -7.249)" fill="#05bbc9"/>
                        </g>
                        <g id="Group_4530" data-name="Group 4530" transform="translate(22.128 31.665)">
                            <path id="Path_2463" data-name="Path 2463"
                                  d="M21.618,25.092a.938.938,0,0,0-.962,0l-1.531.915,9.357,5.6a.94.94,0,0,0,1.4-1.017l-.145-.64Z"
                                  transform="translate(-19.125 -24.959)" fill="#05bbc9"/>
                        </g>
                    </g>
                    <g id="Group_4536" data-name="Group 4536" transform="translate(0 0)">
                        <g id="Group_4532" data-name="Group 4532" transform="translate(0.316)">
                            <path id="Path_2464" data-name="Path 2464" d="M0,0H1.788V3.576H0Z"
                                  transform="translate(0 1.264) rotate(-45)" fill="#a4afc1"/>
                        </g>
                        <g id="Group_4533" data-name="Group 4533" transform="translate(7.583 7.27)">
                            <path id="Path_2465" data-name="Path 2465" d="M0,0H1.788V3.576H0Z"
                                  transform="translate(0 1.264) rotate(-45)" fill="#a4afc1"/>
                        </g>
                        <g id="Group_4534" data-name="Group 4534" transform="translate(0 7.272)">
                            <path id="Path_2466" data-name="Path 2466" d="M0,0H3.576V1.788H0Z"
                                  transform="translate(0 2.528) rotate(-45)" fill="#a4afc1"/>
                        </g>
                        <g id="Group_4535" data-name="Group 4535" transform="translate(7.267 0.004)">
                            <path id="Path_2467" data-name="Path 2467" d="M0,0H3.576V1.788H0Z"
                                  transform="translate(0 2.528) rotate(-45)" fill="#a4afc1"/>
                        </g>
                    </g>
                    <g id="Group_4537" data-name="Group 4537" transform="translate(45.632 8.663)">
                        <path id="Path_2468" data-name="Path 2468"
                              d="M32.053,14.364a2.682,2.682,0,1,1,2.682-2.682,2.685,2.685,0,0,1-2.682,2.682Zm0-3.576a.894.894,0,1,0,.894.894A.9.9,0,0,0,32.053,10.788Z"
                              transform="translate(-29.371 -9)" fill="#a4afc1"/>
                    </g>
                </g>
                <g id="regular" transform="translate(27.203 11.162)">
                    <g id="Group_4538" data-name="Group 4538" transform="translate(0)">
                        <path id="Path_2469" data-name="Path 2469"
                              d="M15.6,47.623a2.284,2.284,0,0,1-2.223-2.789l2.761-12.161L6.78,24.463a2.283,2.283,0,0,1,1.295-3.991l12.388-1.125,4.9-11.461a2.279,2.279,0,0,1,4.193,0l4.9,11.463,2.065.188-.241,2.671L33.968,22a2.278,2.278,0,0,1-1.9-1.382L27.456,9.8,22.834,20.62A2.271,2.271,0,0,1,20.944,22l-11.7,1.062,8.84,7.753a2.276,2.276,0,0,1,.722,2.224L16.2,44.514l10.081-6.028a2.28,2.28,0,0,1,2.341,0l10.081,6.029L36.1,33.038a2.281,2.281,0,0,1,.722-2.223l8.84-7.753-5.815-.527.245-2.671,6.743.613a2.283,2.283,0,0,1,1.295,3.991l-9.362,8.209,2.761,12.161A2.28,2.28,0,0,1,38.136,47.3l-10.68-6.389L16.774,47.3a2.285,2.285,0,0,1-1.169.324Zm4.762-28.055a.006.006,0,0,1,0,0Zm14.176-.005,0,0S34.543,19.565,34.542,19.563Z"
                              transform="translate(-6.001 -6.5)" fill="#2e3033"/>
                    </g>
                </g>
                <path id="Path_2470" data-name="Path 2470"
                      d="M2.609,26.765a.734.734,0,0,1-.737-.737V6.035a.78.78,0,0,1,.2-.553.7.7,0,0,1,.537-.215h8.23A8.9,8.9,0,0,1,16.644,7a6.187,6.187,0,0,1,2.119,5.083,6.118,6.118,0,0,1-2.1,5.052,9,9,0,0,1-5.82,1.7H6.264v7.186a.739.739,0,0,1-.2.522.7.7,0,0,1-.537.215Zm8.077-11.332a3.99,3.99,0,0,0,2.733-.845,3.171,3.171,0,0,0,.952-2.5,3.35,3.35,0,0,0-.921-2.5,3.793,3.793,0,0,0-2.764-.906H6.2v6.756ZM25.857,26.765a.734.734,0,0,1-.737-.737V6.035a.78.78,0,0,1,.2-.553.7.7,0,0,1,.537-.215h7.954a9.014,9.014,0,0,1,5.881,1.751,6.069,6.069,0,0,1,2.134,4.975,6.288,6.288,0,0,1-1.09,3.762,6.066,6.066,0,0,1-3.025,2.2L42.2,25.782a.764.764,0,0,1,.092.338.6.6,0,0,1-.2.461.641.641,0,0,1-.445.184H38.848a1.156,1.156,0,0,1-.752-.215,2.03,2.03,0,0,1-.476-.614l-3.962-7.278H29.389v7.371a.739.739,0,0,1-.2.522.7.7,0,0,1-.537.215Zm7.862-11.578a4.08,4.08,0,0,0,2.779-.829,3.03,3.03,0,0,0,.937-2.4A3.128,3.128,0,0,0,36.5,9.536a3.981,3.981,0,0,0-2.779-.86h-4.33v6.511ZM57,27.072a9.336,9.336,0,0,1-6.511-2.119q-2.334-2.119-2.457-6.3Q48,17.766,48,16.047q0-1.751.031-2.641A8.4,8.4,0,0,1,50.549,7.14,9.215,9.215,0,0,1,57,4.96a9.272,9.272,0,0,1,6.465,2.18A8.376,8.376,0,0,1,66,13.405q.061,1.781.061,2.641,0,.829-.061,2.61-.154,4.177-2.488,6.3A9.336,9.336,0,0,1,57,27.072Zm0-3.532A4.41,4.41,0,0,0,60.284,22.3,5.5,5.5,0,0,0,61.6,18.5q.061-1.843.061-2.488,0-.706-.061-2.488a5.5,5.5,0,0,0-1.321-3.793,4.922,4.922,0,0,0-6.541,0,5.5,5.5,0,0,0-1.321,3.793q-.031.891-.031,2.488,0,1.566.031,2.488A5.545,5.545,0,0,0,53.727,22.3,4.359,4.359,0,0,0,57,23.54Z"
                      transform="translate(77.273 18.014)" fill="#2e3033"/>
            </g>
        </svg>
    );
};
