import React from 'react';
import {useTranslation} from 'react-i18next';
import {Card, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {FiInfo} from 'react-icons/fi';
import {IPerformanceSummary} from '../dto/IReporting';
import {numberFormatter} from '../../../../utils/helpers/numberFormater';
import {priceSplitter} from '../../../../utils/helpers/priceSplitter';

interface Props {
    performance: IPerformanceSummary;
}

const ReportingPerformance = ({performance}: Props) => {
    const {t} = useTranslation();
    return (
        <>
            <h4>{t('influencer.reporting.ivPerformance')}</h4>
            <Card>
                <Card.Body>
                    <div className="row">
                        <div className="col-4 px-md-0">
                            <h5 className="mb-0 text-primary">
                                {numberFormatter(performance?.touchPoints ?? 0)}
                            </h5>
                            <div className="d-flex align-items-center">
                                {t('influencer.reporting.touchpoints')}
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="touchpoints-tooltip">
                                                    {t(`influencer.reporting.touchpointsTooltip`)}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="col-4 px-md-0">
                            <h5 className="mb-0 text-primary euro-symbol">{priceSplitter(performance?.cpm ?? 0)}</h5>
                            <div className="d-flex align-items-center">
                                {t('influencer.reporting.cpm')}
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="cpm-tooltip">
                                                    {t(`influencer.reporting.cpmTooltip`)}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="col-4 px-md-0">
                            <h5 className="mb-0 text-primary">{priceSplitter(performance?.impressions) ?? 0}</h5>
                            <div className="d-flex align-items-center">
                                {t('influencer.reporting.impressions')}
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="impressions-tooltip">
                                                    {t(`influencer.reporting.impressionsTooltip`)}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="col-4 px-md-0 mt-3">
                            <h5 className="mb-0 text-primary">{priceSplitter(performance?.clicks) ?? 0}</h5>
                            <div className="d-flex align-items-center">
                                {t('influencer.reporting.clicks_total')}
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="clicks-tooltip">
                                                    {t(`influencer.reporting.clicksTooltip`)}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="col-4 px-md-0 mt-3">
                            <h5 className="mb-0 text-primary">{priceSplitter(performance?.contentPieces) ?? 0}</h5>
                            <div className="d-flex align-items-center">
                                {t('influencer.reporting.contentPieces')}
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="contentPieces-tooltip">
                                                    {t(`influencer.reporting.contentPiecesTooltip`)}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="col-4 px-md-0 mt-3">
                            <h5 className="mb-0 text-primary euro-symbol">{priceSplitter(Math.round(performance?.totalEarnings) ?? 0)}</h5>
                            <div className="d-flex align-items-center">
                                {t('influencer.reporting.totalEarnings')}
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="totalEarnings-tooltip">
                                                    {t(`influencer.reporting.totalEarningsTooltip`)}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="col-6 px-md-0 mt-3">
                            <h5 className="mb-0 text-primary euro-symbol">{priceSplitter(performance?.reachCpm)}</h5>
                            <div className="d-flex align-items-center">
                                {t('influencer.reporting.reachCpm')}
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="totalEarnings-tooltip">
                                                    {t(`influencer.reporting.reachCpmTooltip`)}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
};

export default ReportingPerformance;
