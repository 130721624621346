import React, {ChangeEvent, useState} from 'react';
import {TextField} from '@material-ui/core';
import {Button, Col, FormControl, InputGroup, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {IInfluencerDistribution} from '../../../dto/IInfluencerDistribution';
import {useDispatch, useSelector} from 'react-redux';
import {addAdditionalItem} from '../../../../../../store/influencer/offerInfluencerSlice';
import {IAllStates} from '../../../../../../store/rootReducer';

interface IAddAdditionalItemProps {
    setVisibility:  React.Dispatch<React.SetStateAction<boolean>>;
}
export function AddAdditionalItemForm({setVisibility} : IAddAdditionalItemProps) {
    const {additionalItemId} = useSelector((state: IAllStates) => state.offerInfluencer);
    const [validationError, setValidationError] = useState(false);
    const [addAdditional, setAddAdditional] = useState<IInfluencerDistribution>({
        description: '',
        duration: '',
        id: additionalItemId,
        location: [],
        typeDescription: '',
        price: 0,
        type: 'additional',
    });
    const {t} = useTranslation();
    const dispatch = useDispatch();

    function handleAdditionalCosts(event: ChangeEvent<HTMLInputElement>) {
        setAddAdditional({
            ...addAdditional,
            [event.target.name]: event.target.name === 'price' ? +event.target.value : event.target.value,
        });
    }

    function createNewAdditional() {
        if (addAdditional.typeDescription !== '') {
            setValidationError(false);
            dispatch(addAdditionalItem(addAdditional));
            setAddAdditional(prevState => ({...prevState, id: additionalItemId + 1, duration: '', location: [], typeDescription: '', price: 0, description: '', type: 'additional'}));
        } else {
            setValidationError(true);
        }
    }

    return(
        <>
                <div>
                    <div className="mb-2 d-flex justify-content-between pr-1 mt-md-0 mt-2">
                        <div className="w-100 mr-2">
                            <TextField value={addAdditional.typeDescription}
                                       onChange={handleAdditionalCosts}
                                       name="typeDescription"
                                       label="Custom text"
                                       variant="outlined"
                                       error={validationError}
                                       size="small"
                                       className="w-100"
                            />
                        </div>
                          <div>
                              <InputGroup size="sm" id="price-input">
                                  <InputGroup.Text>€</InputGroup.Text>
                                  <FormControl
                                    min={0}
                                    onChange={handleAdditionalCosts}
                                    size="sm"
                                    type="number"
                                    placeholder="0"
                                    value={addAdditional.price === 0 ? '' : addAdditional.price}
                                    name="price"
                                    id="price-input"
                                    onKeyPress={(event: any) => (event?.key === '-' || event?.key === '+') && event.preventDefault()}
                                  />
                              </InputGroup>
                          </div>
                    </div>
                    <Row>
                        <Col className="pl-0 mb-2 text-right">
                            <Button onClick={() => setVisibility(false)} className="mr-2" size="sm"
                                    variant="outline-primary">{t('general.button.cancel')}</Button>
                            <Button onClick={createNewAdditional} size="sm"
                                    variant="primary">{t('general.button.save')}</Button>
                        </Col>
                    </Row>
                </div>
        </>
    )
}
