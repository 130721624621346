import {Card, Col, ProgressBar, Row} from 'react-bootstrap';
import React from 'react';
import {IoStarSharp, IoStarOutline, IoStarHalfSharp} from 'react-icons/io5';
import {useTranslation} from 'react-i18next';
import {IReviewsCount} from '../../../../../../../../model/book/Book';

export const RatingCalculation = (
    rating: number,
    maxStarLength: number = 5,
) => {
    const tempArray = [
        Math.floor(rating),
        rating * 10 - Math.floor(rating) * 10 >= 5 ? 5 : 0,
    ];
    const stars = new Array(5).fill(0).map((_, i) => {
        if (i + 1 <= tempArray[0]) {
            return 1;
            // eslint-disable-next-line
        } else if (i == tempArray[0] && tempArray[1]) {
            return 5;
        } else {
            return 0;
        }
    });
    const MapStart = {
        0: () => (
            <div>
                <IoStarOutline className="rating-icon-bg"/>
            </div>
        ),
        1: () => (
            <div>
                <IoStarSharp className="rating-icon-bg"/>
            </div>
        ),
        5: () => (
            <div>
                <IoStarHalfSharp className="rating-icon-bg"/>
            </div>
        ),
    };
    return stars.map((star, index) => <React.Fragment key={`${index}-star`}>{MapStart[star]()}</React.Fragment>);
};

interface IReviewDataProps {
    reviewNumber: number;
    overallScore: number;
    communicationScore: number;
    deliveryScore: number;
    qualityScore: number;
    reviewCount: IReviewsCount;
}

const ReviewData = ({
                        reviewNumber,
                        overallScore,
                        communicationScore,
                        deliveryScore,
                        qualityScore,
                        reviewCount,
                    }: IReviewDataProps) => {
    const {t} = useTranslation();

    const reviewScore = [
        {item: reviewCount?.oneStar, index: 1},
        {item: reviewCount?.twoStars, index: 2},
        {item: reviewCount?.threeStars, index: 3},
        {item: reviewCount?.fourStars, index: 4},
        {item: reviewCount?.fiveStars, index: 5},
    ];

    return (
        <Card>
            <Card.Body>
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <h4>
                            {reviewNumber} {t('brand.campaign.book.offer.modal.reviews')}
                        </h4>
                    </div>
                    <div className="d-flex align-items-center">
                        <h4 className="mb-0 pr-3">{overallScore}</h4>
                        {RatingCalculation(overallScore)}
                    </div>
                </div>
                <Row>
                    <Col md={8} className="pt-3">
                        {reviewScore?.sort((a, b) => b.index - a.index)?.map((score) => {
                            return (
                                <Row key={score?.index} className="my-2 my-md-0">
                                    <Col md={1} className="d-flex pl-0">
                                        <h6 className="d-flex align-items-center mb-0">
                                            {score?.index}
                                            <IoStarSharp className="text-primary ml-1"/>
                                        </h6>
                                        <span className="d-md-none mb-0 ml-2">
                                            ({score?.item})
                                        </span>
                                    </Col>
                                    <Col md={10} className="d-flex align-items-center px-0 my-1 my-md-0">
                                        <ProgressBar
                                            variant="primary"
                                            className="progress-sm w-100 bg-primary-lighter"
                                            now={score?.item}
                                            max={reviewNumber}
                                        />
                                    </Col>
                                    <Col md={1}
                                         className="d-md-flex justify-content-md-end d-none pl-0">
                                        ({score?.item})
                                    </Col>
                                </Row>
                            );
                        })}
                    </Col>
                    <Col md={4} className="d-flex flex-column justify-content-center pt-3 mt-3 mt-md-0">
                        <h6>{t('brand.campaign.book.offer.modal.rating_scores')}</h6>
                        <div className="d-flex justify-content-between">
                            <span>{t('brand.campaign.book.offer.modal.communication')}</span>
                            <h6>
                                {communicationScore}
                                <IoStarSharp className="text-primary ml-2"/>
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between py-2">
                            <span>
                                {t('brand.campaign.book.offer.modal.time_of_delivery')}
                            </span>
                            <h6>
                                {deliveryScore}
                                <IoStarSharp className="text-primary ml-2"/>
                            </h6>
                        </div>
                        <div className="d-flex justify-content-between">
                            <span>
                                {t('brand.campaign.book.offer.modal.content_quality')}
                            </span>
                            <h6>
                                {qualityScore}
                                <IoStarSharp className="text-primary ml-2"/>
                            </h6>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ReviewData;
