import {createSlice} from '@reduxjs/toolkit';

const initialEmployee = {
    id: 0,
    verificationUUID: '',
    email: '',
    company: {},
};

const employeeSlice = createSlice({
    name: 'employeeSlice',
    initialState: initialEmployee,

    reducers: {
        setRegisterUser: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export default employeeSlice;
