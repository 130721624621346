import { ICampaignOverview } from "../../../../../model/briefing/overview/ICampaignOverview";
import { User } from "../../../../../model/user/User";

const briefingCampaignTitle = (campaignOverview: ICampaignOverview, user: User) => {
    return (`
        <div class="avoid-brake">
            <div>
                <h2 class="">${campaignOverview?.title}</h2>
                <h5 class="text-primary">by ${campaignOverview?.company?.displayName}</h5>
            </div>
        </div>
        `
    );
};

export default briefingCampaignTitle;