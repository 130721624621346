import React, {SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import {BiRefresh} from 'react-icons/bi';
import {BsExclamationOctagon} from 'react-icons/bs';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import EmptyState from '../../../../shared/components/placeholder/EmptyState';
import {Pagination} from '../../../../shared/components/Pagination';
import {
    formatDate,
    getCorrectColor,
    getCorrectFilterTitle,
    truncateString
} from '../../../../shared/functions/Functions';
import {iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {IPlannerPlatforms} from '../../../influencer/campaign/dto/IMyCampaigns';
import {AgencyPlannerService} from '../service';

interface Props {
    setSelectedEvent: React.Dispatch<SetStateAction<iManageList | undefined>>;
    setIsProposalOpened: React.Dispatch<SetStateAction<boolean>>;
    plannerPlatforms: IPlannerPlatforms[];
    filterData: { page: number };
    totalPages: number;
    handlePages: (page: number) => void;
    setIsEditModalOpened: React.Dispatch<SetStateAction<boolean>>;
}

const AgencyMobileCalendar = ({
                                  plannerPlatforms,
                                  totalPages,
                                  handlePages,
                                  filterData,
                                  setIsProposalOpened,
                                  setSelectedEvent,
                                  setIsEditModalOpened
                              }: Props) => {
    const {t} = useTranslation();

    const handleOpenModal = async (eventId: string) => {
        const data = await AgencyPlannerService.getPlannerModalData(eventId);
        setSelectedEvent(data);
        if (data.status === 'proposed') {
            setIsProposalOpened(true);
        } else {
            setIsEditModalOpened(true);
        }
    };

    return (
        <div className="mt-2 row">
            {!!plannerPlatforms?.length ? plannerPlatforms?.map((platform, index) => {
                    const {acceptedUser, campaigns} = platform;
                    return (
                        <React.Fragment key={acceptedUser?.id}>
                            {campaigns?.map(campaign => {
                                return (
                                    <React.Fragment key={campaign?.id}>
                                        <div className={`col-12 mt-${index !== 0 ? 4 : 2}`}>
                                            <div className="bg-light rounded">
                                                <div className="d-flex align-items-center cursor-pointer text-dark"
                                                >
                                                    <CustomImageHandler
                                                        photoPath={campaign?.campaignImages?.[0]?.path as string}
                                                        altTag="Campaign logo" classes="campaign-icon"/>
                                                    <div
                                                        className="d-flex align-items-center w-100 justify-content-between">
                                                        <span className="font-weight-bold w-75">
                                                        {truncateString(campaign?.title ?? '-', 40)}
                                                        </span>
                                                        <span className="font-weight-bold">
                                                            {truncateString(acceptedUser?.displayName, 40)}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {campaign?.offers?.[0]?.offerItems?.map((offerItem) => {
                                            const statusesWithIcon = ['proposed', 'feedback_loop'];
                                            return (
                                                <React.Fragment key={offerItem?.id}>
                                                    {offerItem?.offerItemEvents?.filter(event => !!event.id)?.map((event) => {
                                                        return (
                                                            <React.Fragment key={event?.id}>
                                                                <div onClick={() => handleOpenModal(String(event?.id))}
                                                                     className="col-6 border-bottom d-flex align-items-center justify-content-between">
                                                                    <div className="d-flex align-items-center">
                                                                        <img src={offerItem?.socialPlatform?.path}
                                                                             alt={offerItem?.socialPlatform?.name}/>
                                                                        <span
                                                                            className="font-weight-semi-bold text-dark ml-1 word-break">
                                                            {t(`general.platforms.${offerItem?.socialPlatform?.name}`)}
                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div onClick={() => handleOpenModal(String(event?.id))}
                                                                     className="col-6 border-bottom d-flex justify-content-end">
                                                                    <div className="d-flex align-items-center my-1 pl-3">
                                                                        {!statusesWithIcon?.includes(event?.status) && <span
                                                                            className={`d-flex align-items-center justify-content-center calendar-button-dot ${getCorrectColor(event?.status)}`}/>}

                                                                        <div className="d-flex flex-column align-items-end">
                                                                            {statusesWithIcon?.includes(event?.status) ?
                                                                                <div
                                                                                    className="m-1 d-flex align-items-center">
                                                                                    {event?.status === 'proposed' ?
                                                                                        <BsExclamationOctagon
                                                                                            className="proposed-icon"/> :
                                                                                        <BiRefresh
                                                                                            className="proposed-icon text-dark"/>}
                                                                                    <span className="ml-1">
                                                                        {getCorrectFilterTitle(event?.status, t)}
                                                                    </span>
                                                                                </div>
                                                                                : <span
                                                                                    className={`d-flex align-items-center justify-content-start`}>
                                                                    {getCorrectFilterTitle(event?.status, t)}
                                                                </span>
                                                                            }
                                                                            <span className="text-muted">
                                                                {formatDate(event?.date)}
                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })}
                                                </React.Fragment>
                                            )
                                        })}
                                    </React.Fragment>
                                )
                            })}
                        </React.Fragment>
                    )
                }) :
                <EmptyState btnText={t('brand.campaign.dashboard.manage.planner')} iconPath="/icons/clock.png"
                            desc={t('brand.campaign.dashboard.manage.empty_manage')}/>}

            {totalPages > 1 && <div className="d-flex justify-content-end">
                <Pagination
                    page={filterData.page}
                    totalPages={totalPages}
                    handlePagination={handlePages}
                />
            </div>}
        </div>
    );
};
export default AgencyMobileCalendar;
