import React, {useState} from 'react';
import {Button, FormControl, InputGroup, OverlayTrigger, Table, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {priceSplitter} from '../../../../../../../../utils/helpers/priceSplitter';
import {Totals} from './Totals';
import {
    countriesTooltip,
    getCountriesArray,
    getSubtotalAdditionalItems
} from '../../../../../../../../shared/functions/Functions';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../../store/rootReducer';
import {deleteAdditionItem, setAdditionalItems} from '../../../../../../../../store/brand/offerBrandSlice';
import {AddAdditionalItemFormBrand} from './AddAdditionalItemFormBrand';
import {TrashIcon} from '../../../../../../../../shared/Icons';
import {FiInfo} from 'react-icons/fi';
import {TotalTypes} from '../../../../../../../../enum/TotalTypes';

export const AdditionalItemsTable = () => {
    const [visibleAddAdditional, setVisibleAddAdditional] = useState(false);
    const {initialOffer, offerModalStatus, isTransparentPricing} = useSelector((state: IAllStates) => state.offerBrand);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    function changeHandler(event: any, type: string, id: number) {
        const value = event.target.value;
        const additionalItemsCopy = [...initialOffer.distribution];
        const selectedItem = type === 'additional' ?
            additionalItemsCopy.find(item => item.id === id) :
            additionalItemsCopy.find(item => item.type === type);
        const index = selectedItem ? additionalItemsCopy.indexOf(selectedItem) : 0;
        additionalItemsCopy[index] = {
            ...additionalItemsCopy[index],
            price: +value,
        };
        dispatch(setAdditionalItems(additionalItemsCopy));
    }

    const deleteAdditionalItem = (index: number) => {
        dispatch(deleteAdditionItem(index));
    };

    return (
        <div className="table-offer-items">
            <Table responsive
                   className={`table-head-bg mt-3 ${(initialOffer.distribution?.length ?? 0) > 0 && 'mb-0'}`}>
                <thead className="table-head-bg">
                <tr>
                    <th>{t('brand.campaign.book.offer.modal.additional_items')}</th>
                    <th>{t('influencer.offer_management.modal.details')}</th>
                    {initialOffer.distribution.every((item) => item.type === 'additional') ?
                        <>
                            <th></th>
                            <th></th>
                        </> :
                        <>
                            <th>{t('brand.campaign.book.offer.modal.duration')}</th>
                            {initialOffer.distribution.every((item) => item.type === 'exclusivity') ?
                                <th></th>
                                :
                                <th>{t('brand.campaign.book.offer.modal.location')}</th>
                            }
                        </>
                    }
                    <th className="text-right"
                        style={{width: `${offerModalStatus === 'negotiate' ? '200px' : 'auto'}`}}>
                        {t('brand.campaign.book.offer.modal.price')}
                    </th>
                </tr>
                </thead>
                <tbody>
                {initialOffer.distribution?.map((item, index) => (
                    <tr key={item?.typeDescription}>
                        <td className="pl-3">
                            {offerModalStatus === 'negotiate' && (
                                <span onClick={() => deleteAdditionalItem(index)}>
                                    <TrashIcon width="15" height="16" classes="cursor-pointer mr-2"/>
                                </span>
                            )}
                                {item.type === 'additional' ? item.typeDescription : t(`influencer.offer_management.modal.${item?.type}`)}
                            {item.type !== 'additional' &&
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="impressions-tooltip">
                                                    {item.type === 'exclusivity' ?
                                                        t(`brand.campaign.book.offer.modal.legal_tooltip`) :
                                                        t(`brand.campaign.book.offer.modal.distribution_tooltip`)
                                                    }
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            }
                        </td>
                        <td>{item.type === 'additional' ? '' : (item?.typeDescription ? item?.typeDescription : t(`brand.campaign.book.offer.modal.${item?.type}`))}</td>
                        <td>{(item?.duration === '' || !item?.duration) ? '-' : t(`brand.campaign.briefing.step8.${item?.duration}`)} </td>
                        <td>
                            {getCountriesArray(item?.location, t, 2)}
                            {item?.location?.length > 2 ? countriesTooltip(item.location?.slice(2), t) : ''}
                        </td>
                        <td className="font-weight-bold d-flex align-items-center justify-content-end">
                            {offerModalStatus === 'negotiate' ?
                                <InputGroup size="sm" id="price-input">
                                    <InputGroup.Text>€</InputGroup.Text>
                                    <FormControl
                                        min={0}
                                        onChange={(e: any) => changeHandler && changeHandler(e, item.type, item.id ?? 0)}
                                        type="number"
                                        defaultValue={Math.round(item?.price ?? 0)}
                                        name="price"
                                        placeholder="0"
                                        id="price-input"
                                        onKeyPress={(event: any) => (event?.key === '-' || event?.key === '+') && event.preventDefault()}
                                    />
                                </InputGroup>
                                :
                                <p className={`${(item?.price > 0) ? 'euro-symbol' : ''}`}>{`${priceSplitter(Math.round(item?.price))}`}</p>
                            }
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {(offerModalStatus === 'negotiate' && !visibleAddAdditional) &&
                <Button className="mb-2 mt-2 mt-md-0" onClick={() => setVisibleAddAdditional(true)} size="sm"
                        variant="outline-primary">{t('general.button.add_item')}</Button>
            }
            {(visibleAddAdditional && offerModalStatus === 'negotiate') &&
                <AddAdditionalItemFormBrand setVisibility={setVisibleAddAdditional}/>}

            <div className="w-100 my-2">
                <Totals
                    title={TotalTypes.ADDITIONAL_ITEMS}
                    price={getSubtotalAdditionalItems(initialOffer.distribution)}
                    displayPlus={isTransparentPricing}
                />
            </div>
        </div>
    );
};
