import {CancelTokenSource} from 'axios';
import {requests} from '../../../utils';
import {AGENCY_REPORTING, INFLUENCER_PLANNER, INFLUENCER_REPORTING,} from '../../../utils/endpoints';
import {IPlannerEventsQuery} from '../../influencer/planner/dto/IPlanner';
import {ICampaignsFilter} from '../../influencer/reporting/dto/IReporting';

export const AgencyReportingService = {
    getReportingCampaigns: (params: ICampaignsFilter) => requests.get(`${AGENCY_REPORTING}/dropdown/campaigns`, params),
    getCampaignReports: (id: number) => requests.get(`${AGENCY_REPORTING}/campaigns/reports/${id}`),
    getReports: (params: any) => requests.get(`${AGENCY_REPORTING}/campaigns/reports`,
        params),
    getSummary: (influencerId: number | undefined) => requests.get(`${AGENCY_REPORTING}/summary`, {influencerId}),
    manipulateInfluencerReporting: (data: FormData, method: 'put' | 'post', cancelToken?: undefined | CancelTokenSource, onUploadProgress?: any) => requests[method](AGENCY_REPORTING, data,
        {
            cancelToken: cancelToken,
            onUploadProgress: onUploadProgress
        }),
    deleteReportingImage: (id: number) => requests.delete(`${AGENCY_REPORTING}/screenshot/${id}`),
    deleteAttachment: (id: number) => requests.delete(`${AGENCY_REPORTING}/attachment/${id}`),
    updateExternalLink: (fileId: number, externalLink: string) => requests.put(`${AGENCY_REPORTING}/attachment/link/${fileId}`, {
        externalLink: externalLink
    }),
    getAdditionalReporting: (offerId: number) => requests.get(`${AGENCY_REPORTING}/campaigns/additional/reports/${offerId}`),
    updateAdditionalReporting: (data: FormData, offerId: number) => requests.put(`${AGENCY_REPORTING}/additional/${offerId}`, data),
    addSingleReporting: (data: FormData, influencerId: number) => requests.post(`${AGENCY_REPORTING}/add/reporting/${influencerId}`, data),
    addAdditionalReporting: (data: FormData, offerId: number) => requests.post(`${AGENCY_REPORTING}/additional/${offerId}`, data),
    createExternalLink: (eventId: number, externalLink: string) => requests.post(`${AGENCY_REPORTING}/attachment/link/${eventId}`, {
        externalLink: externalLink
    }),
}