import {useTranslation} from 'react-i18next';
import {formatDate} from '../../../../../../../../shared/functions/Functions';
import React from 'react';

interface IHistoryOfferHeaderProps {
    personalOffer?: string;
    createdAt: string;
    userId?: number;
    counterOfferById?: number;
}

export function HistoryOfferHeader({personalOffer, createdAt, userId, counterOfferById}: IHistoryOfferHeaderProps) {

    const {t} = useTranslation();
    return (
        <>
            <div className="d-flex justify-content-between px-3">
                <div className="">
                    {personalOffer &&
                        <div>
                            <h5>{t('influencer.offer_management.modal.add_personal_offer')}</h5>
                        </div>
                    }
                    <p className="text-muted mb-0">{personalOffer}</p>
                </div>
                <p className="text-muted text-right mb-0">
                    {(userId === counterOfferById || counterOfferById === undefined) ?
                        t('brand.campaign.book.offer.modal.sent_at') :
                        t('brand.campaign.book.offer.modal.received_at')}
                    - {formatDate(createdAt)} - {createdAt.substring(11, 16)}
                </p>
            </div>
        </>
    );
};
