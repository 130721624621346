import React, {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {InfluencerService} from '../../../../app/influencer/service';
import {ErrorToast} from '../../../../utils/toasters';
import {priceSplitter} from '../../../../utils/helpers/priceSplitter';
import {CustomImageHandler} from '../../../components/CustomImageHandler';
import { InfluencerMyCampaignsApi } from '../../../../app/influencer/campaign/service';

const InfluencerHeader = () => {
    const {t} = useTranslation();
    const {user} = useSelector((state: IAllStates) => state.auth);
    const influencerHeaderData = user?.agencyCompany;
    const [revenue, setRevenue] = useState({
        totalEarnings: 0,
    });
    const [totalCampaigns, setTotalCampaigns] = useState<number>(0);

    useEffect(() => {
        InfluencerService.getInfluencerRevenue().then(response => {
            setRevenue(response);
        }).catch(error => ErrorToast(error));

        InfluencerMyCampaignsApi.getMyCampaigns({perPage: 10, page:1}).then(response => {
            setTotalCampaigns(response.count)
        }).catch(error => ErrorToast(error));
    }, []);
    return (
        <Row id="header2" className="campaign-information tabs-container bg-white d-none d-md-flex">
            <Col md={6} lg={8} className="d-flex justify-content-center flex-column">
                <h4>{t('influencer.header.welcome_back')} {user?.firstName}!</h4>
                <Link to="/influencer/campaigns"
                      className="text-uppercase text-muted text-underline ">
                    {t('influencer.header.myCampaigns')} ({totalCampaigns})
                </Link>
            </Col>
            <Col md={6} lg={4} className="d-flex align-items-center justify-content-end">
                {!!revenue?.totalEarnings &&
                <div className="pr-2 text-end">
                    <h5 className={`text-primary mb-0 euro-symbol`}>
                        {priceSplitter(Math.round(revenue?.totalEarnings ?? 0))}
                    </h5>
                    <span className="text-muted">{t('influencer.header.earningThisMonth')}</span>
                </div>
                }
                {influencerHeaderData?.id && <>
                    <div className="d-flex flex-column align-items-end px-3">
                        <span className="mr-1 text-muted">{t('influencer.header.managedBy')}:</span>
                        <span className="font-weight-semi-bold">{influencerHeaderData?.displayName ?? '-'}</span>
                    </div>
                    <CustomImageHandler photoPath={influencerHeaderData?.profilePhoto?.thumbnail as string}
                                        classes="campaign-icon" altTag="User Profile"/>
                </>}
            </Col>
        </Row>
    );
};

export default InfluencerHeader;
