import {Card} from 'react-bootstrap';
import React from 'react';
import {CampaignCategory} from '../../../../model/campaign/CampaignCategory';
import {useTranslation} from 'react-i18next';

interface ICampaignMultiSelectProps {
    item: CampaignCategory;
    clickHandler: (id: number) => void;
}

export const CategoryCard = ({item, clickHandler}: ICampaignMultiSelectProps) => {
    const {t} = useTranslation();
    return (
        <div className={`my-2 mr-2`} onClick={() => clickHandler(item?.id ?? 0)}>
            <Card
                className={`py-2 px-4 campaign-multiselect ${item?.isSelected ? 'active' : ''}`}>
                <div className="d-flex align-items-center justify-content-center">
                    <img width={25} src={`/icons/categories/${item?.name}.svg`} alt={item?.name}/>
                    <span
                        className={`ml-2 mb-0 word-break font-weight-semi-bold ${item?.isSelected ? 'activeText' : ''}`}>{t(`general.category.${item?.name}`)}</span>
                </div>
            </Card>
        </div>
    );
};
