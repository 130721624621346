import i18n from 'i18next';
import en from '../assets/locales/i18n/en-EN.json';
import de from '../assets/locales/i18n/de-DE.json';
import {initReactI18next} from 'react-i18next';

const resources = {
    en: {
        translation: en,
    },
    de: {
        translation: de,
    },
};

export const availableLanguages = Object.keys(resources);

i18n.use(initReactI18next)
    .init({
        compatibilityJSON: 'v3',//issue on older versions of phone
        resources,
        defaultNS: 'translation',
        fallbackLng: 'en',
    });
