import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {actions} from '../../../store/authentication/authRedux';
import {IAllStates} from '../../../store/rootReducer';
import {ErrorToast, SuccessToast} from '../../../utils/toasters';
import {ImpersonateService} from './service';
import {InternalRoutes} from '../../../enum/InternalRoutes';

interface ImpersonateParams {
    id: string;
    token: string;
    isAdmin: string;
    adminActiveFirstRoute: string;
    adminActiveSecondRoute: string;
    adminActiveThirdRoute: string;
}

export function Impersonate() {
    const dispatch = useDispatch();
    const {
        id,
        token,
        isAdmin,
        adminActiveFirstRoute,
        adminActiveSecondRoute,
        adminActiveThirdRoute
    } = useParams<ImpersonateParams>();
    const {user} = useSelector((states: IAllStates) => states.auth);
    const {t} = useTranslation();
    const impersonateActiveRoute = (adminActiveFirstRoute  ? adminActiveFirstRoute : '') + (adminActiveSecondRoute ? `/${adminActiveSecondRoute}` : '') + (adminActiveThirdRoute ? `/${adminActiveThirdRoute}` : '')
    const isInvalidRoute = (adminActiveFirstRoute === 'user' && adminActiveSecondRoute === 'platforms')
    const history = useHistory();
    useEffect(() => {
        ImpersonateService.impersonateUser(token, id)
            .then((data) => {
                localStorage.setItem('token', JSON.stringify(data.token));
                localStorage.setItem('impersonateActiveRoute', isInvalidRoute ? 'user' : impersonateActiveRoute);
                if (isAdmin !== 'true') {
                    dispatch(actions.setOldUser());
                    dispatch(actions.setOldToken(data.oldToken));
                }
                localStorage.setItem('whoImpersonate', isAdmin === 'true' ? 'admin' : 'agency');
                localStorage.setItem('appVersion', data?.userData?.appVersion);
                dispatch(actions.login(data.token));
                dispatch(actions.setUser(data.userData));
                dispatch(actions.setIsVerified({isVerified: true}));
                SuccessToast(t(`general.toasts.success_impersonated_user`));
                if (user?.userType === data?.userData?.userType && isAdmin === 'true') {
                    window.location.reload();
                }
            }).catch(err => ErrorToast(err));
        // eslint-disable-next-line
    }, []);
    return (
        <>
        </>
    );
}
