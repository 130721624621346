import React, {useEffect, useState} from 'react';
import {FaCheckCircle, FaDotCircle} from 'react-icons/fa';
import {useTranslation} from 'react-i18next';

interface IPasswordVerifyStepsProps {
    password: string;
}

export function PasswordVerifySteps({password}: IPasswordVerifyStepsProps) {
    const {t} = useTranslation();
    const [verifySteps, setVerifySteps] = useState({
        hasCapitalLetter: false,
        hasEnoughCharacters: false,
        hasOneNumber: false,
        hasSpecialSymbols: false,
    });

    useEffect(() => {
        const validatePassword = (newPw: string) => {
            const capitalLetterRegex = /[A-Z]/;
            const numberRegex = /[0-9]/;
            const specialSymbolRegex = /[^A-Za-z0-9]/;

            const hasCapitalLetter = capitalLetterRegex.test(password);
            const hasEnoughCharacters = password.length >= 8;
            const hasOneNumber = numberRegex.test(password);
            const hasSpecialSymbols = specialSymbolRegex.test(password);

            setVerifySteps({
                hasCapitalLetter,
                hasEnoughCharacters,
                hasOneNumber,
                hasSpecialSymbols,
            });
        };

        validatePassword(password);
    }, [password]);

    return (
        <>
            {!!password.length &&
                <div className="p-3 default-border border-2 default-radius">
                    <h6 className="text-left">
                        {t('general.password_validation.pw_requirements')}
                    </h6>
                    <div className="d-flex align-items-center mb-2">
                        {verifySteps.hasEnoughCharacters ? <FaCheckCircle className="text-success"/> :
                            <FaDotCircle className="text-dark"/>
                        }
                        <p className={`mb-0 ml-2 text-left ${verifySteps.hasEnoughCharacters ? 'text-success' : 'text-dark'}`}>
                            {t('general.password_validation.minimum_length')}
                        </p>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        {verifySteps.hasCapitalLetter ? <FaCheckCircle className="text-success"/> :
                            <FaDotCircle className="text-dark"/>
                        }
                        <p className={`mb-0 ml-2 text-left ${verifySteps.hasCapitalLetter ? 'text-success' : 'text-dark'}`}>
                            {t('general.password_validation.has_capital')}
                        </p>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        {verifySteps.hasOneNumber ? <FaCheckCircle className="text-success"/> :
                            <FaDotCircle className="text-dark"/>
                        }
                        <p className={`mb-0 ml-2 text-left ${verifySteps.hasOneNumber ? 'text-success' : 'text-dark'}`}>
                            {t('general.password_validation.has_number')}
                        </p>
                    </div>
                    <div className="d-flex align-items-center mb-2">
                        {verifySteps.hasSpecialSymbols ? <FaCheckCircle className="text-success"/> :
                            <FaDotCircle className="text-dark"/>
                        }
                        <p className={`mb-0 ml-2 text-left ${verifySteps.hasSpecialSymbols ? 'text-success' : 'text-dark'}`}>
                            {t('general.password_validation.has_symbol')}
                        </p>
                    </div>
                </div>
            }
        </>
    );
}
