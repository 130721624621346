import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {IReviewsData} from '../../../../model/book/Book';
import {Platforms} from '../../../../model/platform/Platforms';
import {IParams} from '../../../../model/shared/IParams';
import {User} from '../../../../model/user/User';
import {ErrorToast} from '../../../../utils/toasters';
import {IChartData, IPlatformDataArray} from '../../../brand/campaign/book/offer/dto/IAudienceDataModal';
import {IInfo} from '../../../brand/campaign/briefing/dto/ICreateCampaign';
import {BrandDashboardService} from '../../../brand/dashboard/service';
import {InfluencerService} from '../../service';
import ProfileInfo from '../profile-form/components/ProfileInfo';
import AudienceTab from './components/AudienceTab';
import HistoryTab, {ICollaborationHistory} from './components/HistoryTab';
import ProfileTab from './components/ProfileTab';
import ReviewsTab from './components/ReviewsTab';
import {InternalRoutes} from '../../../../enum/InternalRoutes';
import CooperationTab from './components/CooperationTab';
import { IOffer } from '../../../../model/offer/IOffer';
import { ICampaignOverview } from '../../../../model/briefing/overview/ICampaignOverview';
import { UserService } from '../../../shared/user/service';
import { useDispatch } from 'react-redux';
import { offerInfluencer } from '../../../../store/influencer/offerInfluencerSlice';

interface IAudience {
    chartData: IChartData[],
    chartDates: string[];
    id: number;
    platformDataArray: IPlatformDataArray[];
}
export interface IOfferWithCampaign extends IOffer {
    campaign: ICampaignOverview;
  }
export interface IProfileData {
    user: User;
    audience: IAudience;
    reviews: {
        data: IReviewsData,
    };
    cooperation: {
        data: IInfo[],
    };
    social: Platforms[];
    offers: IOfferWithCampaign[];
    collaborationHistory: ICollaborationHistory;
}

type TKeyTab = 'profile' | 'audienceData' | 'reviews' | 'history' | 'cooperation';

const InfluencerProfile = () => {
    const [key, setKey] = useState<TKeyTab>('profile');
    const [profileData, setProfileData] = useState<IProfileData>();
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [triggerComment, setTriggerUpdateComment] = useState(false);
    const [idOffer, setIdOffer] = useState(0);
    
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {id} = useParams<IParams>();
    const history = useHistory();
    const location = useLocation();
    const isPublicProfile = location.pathname.includes('/brand/');

    const tabs = [
        `${t('influencer.profile.profile')}-profile`,
        `${t('influencer.profile.audienceData')}-audienceData`,
        `${t('influencer.profile.reviews')}-reviews`,
        `${t('influencer.profile.history')}-history`,
        `${t('influencer.profile.cooperationWithYou')}-cooperation`,
    ];
    const handleChange = (event: React.SyntheticEvent, newValue: TKeyTab) => {
        setKey(newValue);
    };

    const step = {
        'profile': <ProfileTab state={profileData?.user as User} isPublicProfile={isPublicProfile}/>,
        'audienceData': <AudienceTab state={profileData?.audience as IAudience}/>,
        'reviews': <ReviewsTab state={profileData?.reviews?.data as IReviewsData}/>,
        'history': <HistoryTab state={profileData?.cooperation?.data as IInfo[]}
                               triggerUpdate={() => setTriggerUpdate(prev => !prev)}
                               collaborationHistory={
                                   profileData?.collaborationHistory as ICollaborationHistory}/>,
        'cooperation': <CooperationTab profileData={profileData!} 
        setProfileData={setProfileData!} 
        setIdOffer={setIdOffer} setTriggerUpdateComment={setTriggerUpdateComment}/>
    };
    const CurrentTab = useCallback(() => {
        return step[key];
        // eslint-disable-next-line
    }, [key, profileData]);

    useEffect(() => {
        if (id) {
            const service = isPublicProfile ? BrandDashboardService.getInfluencerProfile(id) : InfluencerService.getInfluencerProfile(id);

            service.then(response => {
                setProfileData(response);
            }).catch(error => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, [id, triggerUpdate]);

    useEffect(() => {
        if (!!idOffer) {
            UserService.getPrivateComment(idOffer).then(response => {
                dispatch(offerInfluencer.actions.setBundle(response));
            }).catch(err => ErrorToast(err));
        }
    }, [idOffer, triggerComment]);
    
    return (
        <Row>
            <Col lg={3}>
                <ProfileInfo defaultState={profileData?.user}/>
            </Col>
            <Col lg={9} className="mui-scroll-tab">
                <TabContext value={key}>
                    <Box sx={{borderBottom: 0}}
                         className="d-flex align-items-md-center justify-content-between flex-column-reverse flex-md-row mt-3 mt-md-0">
                        <TabList
                            TabIndicatorProps={{style: {background: 'var(--primary)'}}}
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                            className='border-bottom'
                        >
                            {tabs?.map((tab: string, i) => {
                                return (
                                    <Tab
                                        className={`${(!isPublicProfile && (tab.split('-')[1] === 'audienceData' || tab.split('-')[1] === 'cooperation')) ? 'd-none' : ''}`}
                                        key={i}
                                        label={tab?.split('-')[0]}
                                        value={tab?.split('-')[1]}
                                    />
                                );
                            })}
                        </TabList>
                        {!isPublicProfile &&
                            <Button variant="primary"
                                    onClick={() => history.push(`${InternalRoutes.INFLUENCER_PROFILE_EDIT}/${id}`)}>
                                {t('general.editProfile')}
                            </Button>
                        }
                    </Box>
                </TabContext>
                <div className="mt-3">
                    <CurrentTab/>
                </div>
            </Col>
        </Row>
    );
};

export default InfluencerProfile;
