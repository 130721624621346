import React from 'react';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {StarIcon} from '../../../../shared/Icons';
import {ButtonUpgradePro} from './ButtonUpgradePro';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';

export function UserAndRolesBasicPack() {
    const {
        company,
    } = useSelector((state: IAllStates) => state.auth.user);
    const {t} = useTranslation();
    return (
        <Card>
            <Card.Body className="text-center">
                <StarIcon width="100" height="100"/>
                {company?.package?.isShownToUser ?
                    <>
                        <h5 className="mt-4">{t('brand.package.availableInPro')}</h5>
                        <p className="my-4 text-muted">{t('brand.package.availableInProDesc')}</p>
                        <ButtonUpgradePro isOnlyUpgrade={true}/>
                    </>
                    :
                    <h5 className="mt-4">{t('brand.package.info_about_feature')}</h5>
                }
            </Card.Body>
        </Card>
    );
};
