import {useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import {
    IMessageContent,
    IMessageOpenedChat, IMessageQuery,
    IMessageThread,
    IMessageThreadQuery
} from '../../brand/campaign/messages/dto/IMessage';
import {ILocation, MessageFilters} from '../../brand/campaign/messages/components/filters/MessageFilters';
import {MessageService} from '../../brand/campaign/messages/service';
import {ErrorToast} from '../../../utils/toasters';
import {useIsInViewport} from '../../../shared/hooks/useIsInViewport';
import {Col, Row} from 'react-bootstrap';
import {SideChat} from '../../brand/campaign/messages/components/chat/SideChat';
import {MainChat} from '../../brand/campaign/messages/components/chat/MainChat';
import Box from '@mui/material/Box';
import {Tab, Tabs} from '@material-ui/core';
import {getCorrectFilterKeys} from '../../../shared/functions/Functions';
import {a11yProps} from '../../../shared/components/MaterialTabsComponents';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../store/rootReducer';

let messageInterval: any;

const getMessageId = (arrList: string[]) => {
    return Number(arrList.find(Number)) || '';
}

export const AgencyMessages = () => {
    const isMobile = useMediaQuery('(max-width: 768px)');
    const location = useLocation();
    const {state} = useLocation();
    const {selectedInfluencer} = useSelector((reduxState: IAllStates) => reduxState.agency);
    const {t} = useTranslation();
    const [campaignFilter, setCampaignFilter] = useState<IMessageQuery>({
        page: 1,
        perPage: 20,
        types: getCorrectFilterKeys((state as ILocation)?.isAgencyCreator ? 1 : 0, false),
        participantId: selectedInfluencer?.id ?? undefined,
    });
    const [count, setCount] = useState(0);
    const [unreadCount, setUnreadCount] = useState(0);
    const [selectedThread, setSelectedThread] = useState<IMessageThread>();
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [inboxMessages, setInboxMessages] = useState<IMessageContent[]>();
    const [selectedChatId, setSelectedChatId] = useState<number>();
    const [selectedChatContent, setSelectedChatContent] = useState<IMessageOpenedChat[]>([]);
    const [chatParams, setChatParams] = useState<IMessageThreadQuery>({
        page: 1,
        perPage: 15,
    });
    const [openedChatCount, setOpenedChatCount] = useState(0);
    const [currTab, setCurrTab] = useState((state as ILocation)?.isAgencyCreator ? 1 : 0);

    useEffect(() => {
        // Accessing query parameters from location.search
        if (!!(state as ILocation)?.campaignId || getMessageId(location?.pathname?.split('/'))) {
            setCampaignFilter(prev => ({
                ...prev,
                campaignId: getMessageId(location?.pathname?.split('/')) || (state as ILocation)?.campaignId
            }))
        }
    }, [(state as ILocation)?.campaignId, JSON.stringify(location?.pathname?.split('/'))])
    const shouldShowSideChat = (isMobileParams: boolean) => {
        return isMobileParams ? !selectedChatId && isMobile : true;
    };

    const shouldShowMainChat = (isMobileParams: boolean) => {
        return isMobileParams ? selectedChatId && isMobile : true;
    };

    useEffect(() => {
        getInitialMessages(selectedChatId);
        // eslint-disable-next-line
    }, [chatParams?.page]);

    useEffect(() => {
        getInitialMessages(selectedChatId, true);
        // eslint-disable-next-line
    }, [selectedChatId]);

    useEffect(() => {
        MessageService.getInboxMessages({...campaignFilter, participantId: selectedInfluencer?.id}).then(response => {
            console.log(campaignFilter);
            const {data, nbUnreadThreads, count: nbCount} = response;
            if (data) {
                setUnreadCount(nbUnreadThreads)
                setCount(nbCount);
                setInboxMessages(data);
                if (!selectedChatId && !isMobile) {
                    // @ts-ignore
                    setSelectedChatId(state?.threadId ? state?.threadId : data[0]?.id);
                }
            }
        }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, [triggerUpdate, JSON.stringify(campaignFilter), isMobile, selectedChatId, selectedInfluencer]);
    useEffect(() => {
        clearInterval(messageInterval);
        if (selectedChatId) {
            // @ts-ignore
            messageInterval = setInterval(() => {
                getMessagesPolling(selectedChatId);
            }, 5000);
        }

        return () => {
            clearInterval(messageInterval);
        };
        // eslint-disable-next-line
    }, [selectedChatId, triggerUpdate, JSON.stringify(selectedChatContent)]);

    function getInitialMessages(selectedChatId?: number, isInitial?: boolean) {
        if (selectedChatId) {
            MessageService.getInboxMessageById({
                ...chatParams,
                id: selectedChatId,
            }).then(response => {
                setSelectedThread(response?.thread);
                setOpenedChatCount(response?.count);
                const concated = isInitial ? response?.data : selectedChatContent?.concat(response.data);
                setSelectedChatContent(concated);
            }).catch(error => ErrorToast(error));
        }
    }

    function getMessagesPolling(selectedChatId?: number) {
        if (!!selectedChatContent?.length) {
            MessageService.getInboxMessageById({
                page: 1,
                perPage: 15,
                id: selectedChatId,
            }).then(response => {
                const hasNewMessage = (selectedChatContent?.[0] as any)?.id !== response?.data[0]?.id;
                if (hasNewMessage) {
                    const myLastMessageIndex = response?.data?.findIndex((message: { id: number }) => {
                        return message.id === (selectedChatContent?.[0] as any)?.id;
                    });
                    setTriggerUpdate(!triggerUpdate);
                    setSelectedChatContent((prev) => [...response?.data.slice(0, myLastMessageIndex), ...prev]);
                }
            }).catch(error => ErrorToast(error));
        }
    }

    const lastSideChatRef = useRef<HTMLDivElement>(null);
    const isInViewport1 = useIsInViewport(lastSideChatRef);

    useEffect(() => {
        // don't send api request if array is empty and don't send it if we have all messages
        if (isInViewport1 && !!inboxMessages?.length && campaignFilter?.perPage <= count) {
            setCampaignFilter(prev => ({...prev, perPage: prev.perPage + 10}));
        }
        // eslint-disable-next-line
    }, [isInViewport1]);
    return (
        <div>
            <Row className="w-100 mx-0 shadow border default-radius">
                {shouldShowSideChat(isMobile) && (
                    <Col md={3}
                         className="px-0"
                    >
                        <MessageFilters count={{count: count, nbUnreadThreads: unreadCount}}
                                        filterParams={campaignFilter}
                                        setFilterParams={setCampaignFilter}/>
                        <div className="bg-white responsive-chat-body-height default-radius-bottom">
                            <Box className="bg-light w-100">
                                <Tabs variant='fullWidth' centered={true} value={currTab} onChange={(_, value) => {
                                    setCurrTab(value);
                                    setCampaignFilter(prev => ({...prev, campaignId: undefined , types: getCorrectFilterKeys(value, false)}));
                                }}
                                      id="agencyMessageTabs"
                                      TabIndicatorProps={{style: {background: 'var(--primary)'}}}>
                                    <Tab label={t('brand.campaign.manage.campaigns')} {...a11yProps(0)} />
                                    <Tab label={t('brand.campaign.manage.contentCreators')} {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            {!!inboxMessages?.length ? inboxMessages?.map((message) => (
                                <SideChat
                                    key={message?.id}
                                    isInfluencer
                                    isDeletedFilter={campaignFilter?.isDeleted as boolean}
                                    setTriggerUpdate={setTriggerUpdate}
                                    clickHandler={() => {
                                        setChatParams((prev) => ({...prev, page: 1}))
                                        setSelectedChatId(message?.id);
                                    }}
                                    selectedChatId={selectedChatId as number}
                                    message={message}
                                />)) : (
                                <p className="p-3">{t('influencer.messages.noConversation')}</p>
                            )}
                            <div ref={lastSideChatRef} className="py-1"/>
                        </div>
                    </Col>
                )}
                <Col md={9} className="h-100 px-0">
                    {shouldShowMainChat(isMobile) &&
                        <MainChat selectedChatContent={selectedChatContent} selectedChatId={selectedChatId as number}
                                  setSelectedChatId={setSelectedChatId} getMessagesPolling={getMessagesPolling}
                                  selectedThread={selectedThread!} count={openedChatCount}
                                  setChatParams={setChatParams} chatParams={chatParams}
                        />}
                </Col>
            </Row>
        </div>
    );
};
