import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {IOffer} from '../../../../../model/offer/IOffer';
import {IOfferItems} from '../../../../../model/offer/IOfferItems';
import {Platforms} from '../../../../../model/platform/Platforms';
import {CustomAutocomplete} from '../../../../../shared/components/Autocomplete';
import CustomDatePicker from '../../../../../shared/components/DatePicker';
import {calculateUploadUntil, removeTimeZoneFromDate} from '../../../../../shared/functions/Functions';
import {ErrorToast} from '../../../../../utils/toasters';
import {InfluencerPlannerService} from '../../../planner/service';
import {ITempData} from '../AddReportingView';
import {IReportingUpdate, ISelectedCampaign} from '../ReportingCampaignCard';

interface IReportPostingModal {
    isModalOpened: boolean;
    isAdditional: boolean;
    clearAdditional: () => void;
    createPosting: IReportingUpdate;
    setCreatePosting: React.Dispatch<SetStateAction<IReportingUpdate>>;
    setIsStep2Opened: React.Dispatch<SetStateAction<boolean>>;
    platformDropdown: Platforms[];
    setIsNewReporting: React.Dispatch<SetStateAction<boolean>>;
    setTempData: React.Dispatch<SetStateAction<ITempData>>;
    selectedCampaign: ISelectedCampaign;

}

const AddReportingModal = ({
                               clearAdditional,
                               platformDropdown,
                               isModalOpened,
                               createPosting,
                               setTempData,
                               setCreatePosting,
                               setIsNewReporting,
                               setIsStep2Opened,
                               isAdditional,
                               selectedCampaign,
                           }: IReportPostingModal) => {
    const {t} = useTranslation();
    const [selectedOffer, setSelectedOffer] = useState<IOffer>();
    const isOfferSelected = !!selectedOffer?.offerItems?.length || isAdditional;
    const [selectedPlatform, setSelectedPlatform] = useState<IOfferItems>();
    const numReviewsDays = selectedCampaign?.campaign?.isReviewed ? selectedCampaign?.campaign?.reviewDays : 0;
    const handleChangeTimepicker = (newValue: Date | null, name: string) => {
        setCreatePosting(prev => ({...prev, [name]: newValue}));
    };

    const changeOfferHandler = (paramKey: string, paramValue: string | number) => {
        setCreatePosting(prev => ({...prev, [paramKey]: +paramValue}));
    };
    const changeCampaignHandler = (paramKey: string, paramValue: string | number) => {
        setCreatePosting(prev => ({...prev, [paramKey]: +paramValue}));
    };
    useEffect(() => {
        if (selectedCampaign?.campaign?.id && !isAdditional) {
            InfluencerPlannerService.getPlannerData({
                page: 1,
                perPage: 10,
                campaignId: selectedCampaign?.campaign?.id as unknown as string,
                isReporting: selectedCampaign?.campaign?.offers?.[0]?.status === 'completed'
            }).then(response => {
                const {data} = response;
                setSelectedOffer(data?.[0]?.offers?.[0] ?? []);
                setTempData((prev: any) => ({
                    ...prev,
                    offers: data?.[0]?.offers,
                    company: data?.[0]?.company,
                }));
            }).catch(error => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, [selectedCampaign?.campaign?.id]);
    useEffect(() => {
        if (!!selectedCampaign?.offerItemId && selectedOffer?.offerItems) {
            setCreatePosting(prev => ({...prev, offerItemId: selectedCampaign?.offerItemId}))
            setSelectedPlatform(selectedOffer?.offerItems?.find(offer => offer?.id === (selectedCampaign?.offerItemId as number)))
        }
        // eslint-disable-next-line
    }, [selectedCampaign?.offerItemId, selectedOffer?.offerItems]);
    return (
        <Modal
            show={isModalOpened}
            centered
            onHide={() => setIsNewReporting(false)}
            keyboard={false}
        >
            <Modal.Body>
                <div className="border-bottom mb-3">
                    <h5>
                        {t('influencer.reporting.reportPosting')}
                    </h5>
                </div>
                {createPosting?.campaignId && <div className="my-4">
                    <CustomAutocomplete dataArray={[]} isWithImage={false} inputLabel="title"
                                        defaultArray={selectedCampaign?.campaign as any} isDisabled
                                        changeEventKey="title" queryFilterKey="campaignId"
                                        inputLabelTranslation={t('influencer.planner.selectCampaign')}
                                        handleChangeParams={changeCampaignHandler}/>
                </div>}
                {isOfferSelected && <>
                    <div className="my-4">
                        <CustomAutocomplete
                            dataArray={isAdditional ? platformDropdown : selectedOffer?.offerItems?.filter(offer => offer.nbPosts > (offer?.eventNbPosts ?? 0)) ?? []}
                            isWithImage={true} inputLabel={isAdditional ? 'name' : 'socialPlatform.name'}
                            photoLabel={isAdditional ? 'path' : 'socialPlatform.path'}
                            defaultArray={selectedOffer?.offerItems?.find(offer => offer.id === selectedCampaign?.offerItemId) as unknown as []}
                            selectedStateImage={selectedPlatform?.socialPlatform?.path as string}
                            isDisabled={!isAdditional}
                            queryFilterKey={isAdditional ? 'socialPlatformId' : 'offerItemId'}
                            inputLabelTranslation={t('brand.campaign.manage.postingType')}
                            handleChangeParams={changeOfferHandler}/>
                        {selectedPlatform?.id && <span className="text-warning d-flex mt-2 justify-content-end">
                            {t('general.posts')} {selectedPlatform?.eventNbPosts ?? 0} / {selectedPlatform?.nbPosts}
                        </span>}

                    </div>
                    <div className="my-3">
                        <CustomDatePicker label={t('brand.campaign.manage.postingDate')}
                                          disableFrom={removeTimeZoneFromDate(selectedCampaign?.campaign?.publishFrom ?? '')}
                                          disableTo={removeTimeZoneFromDate(selectedCampaign?.campaign?.publishTo ?? '')}
                                          value={createPosting?.postingDate}
                                          onChange={(event: Date | null) => handleChangeTimepicker(event, 'postingDate')}/>
                    </div>
                    <p>
                        {t('influencer.planner.uploadContentUntil')}:
                        {calculateUploadUntil(numReviewsDays, createPosting?.postingDate as string)}
                    </p>
                </>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary"
                        onClick={() => {
                            setIsNewReporting(false);
                            clearAdditional();
                        }}>{t('general.button.cancel')}</Button>
                <Button variant="primary"
                        disabled={!(createPosting?.campaignId && (createPosting?.offerItemId || createPosting?.socialPlatformId))}
                        onClick={() => {
                            setIsStep2Opened(true);
                            setIsNewReporting(false);
                        }
                        }>{t('general.button.next')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddReportingModal;
