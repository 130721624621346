import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import {MenuItem, TextField} from '@material-ui/core';
import {UserService} from '../service';
import {Contact} from '../../../../model/shared/Contact';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import {multipartFormData} from '../../../../utils';
import {PERSONAL_INFO_URL} from '../../../../utils/endpoints';
import {IPersonalInfo, IPersonalInfoModalProps} from '../dto/IPersonalInfo';
import {actions} from '../../../../store/authentication/authRedux';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {useTranslation} from 'react-i18next';
import {UploadImage} from '../../../../shared/components/UploadImage';
import {UserRoles} from '../../../../enum/UserRoles';
import CustomDatePicker from '../../../../shared/components/DatePicker';
import {ConfirmationModal} from '../../../../shared/components/alert/ConfirmationModal';
import {useHistory} from 'react-router';
import {setUpdateSelectedInfluencer, setUpdateInfluencerList} from '../../../../store/agency/agencySlice';

const initialContact: Contact = {
    country: '',
    phone: '',
};
const initialInfo: IPersonalInfo = {
    displayName: '',
    firstName: '',
    cityOfResidence: '',
    id: 0,
    lastName: '',
    email: '',
    birthdate: '',
    contact: initialContact,
};

function PersonalInfoModal({
                               show,
                               closeModal,
                               userType,
                               id,
                               updateList,
                               updateLoggedUser = true
                           }: IPersonalInfoModalProps) {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [state, setState] = useState<IPersonalInfo>(initialInfo);
    const {gender, country} = useSelector((states: IAllStates) => states.enums);
    const {selectedInfluencer} = useSelector((states: IAllStates)  => states.agency);
    const [files, setFiles] = useState<Blob[] | MediaSource[]>();
    const [updateInitialData, setUpdateInitialData] = useState(false);
    const [emailExist, setEmailExist] = useState();
    const [displayNameExist, setDisplayNameExist] = useState();
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        if (show) {
            UserService.getPersonalInfo(id)
                .then((data) => {
                    setState(data.data);
                    setUpdateInitialData(true);
                }).catch(error => ErrorToast(error));
        }
    }, [show]);

    function onSubmit() {
        multipartFormData(files, `${PERSONAL_INFO_URL}/${id}`, JSON.stringify({
            ...state,
            birthdate: moment(state.birthdate).format('YYYY-MM-DD'),
        }))
            .then((res) => {
                if (updateLoggedUser) {
                    dispatch(actions.updateUser(res.data.data));
                    dispatch(actions.updateComletionInfo(res.data.completionInfo));
                }
                SuccessToast(t('general.toasts.successUpdatedPersonalInfo'));
                if (updateList) {
                    res?.data?.data?.id === selectedInfluencer?.id && dispatch(setUpdateSelectedInfluencer(res?.data?.data));
                    updateList(prevState => !prevState);
                    dispatch(setUpdateInfluencerList({}));
                }
                if (closeModal) {
                    closeModal();
                }
            }).catch(error => ErrorToast(error));
    }

    const deleteProfile = () => {
        UserService.deleteProfile(state.id)
            .then((res) => {
                SuccessToast(t('general.toasts.successfullyDeletedUser'));
                dispatch(actions.logout());
                localStorage.clear();
                history.push('/auth/login');
            }).catch(err => ErrorToast(err));
    };

    const checkIfNameExist = (value: string, inputName: string) => {
        if (inputName === 'displayName' && value.length > 1) {
            UserService.checkDisplayName(value)
                .then(response => setDisplayNameExist(response));
        } else if (inputName === 'email' && value.length > 1) {
            UserService.checkEmail(value)
                .then(response => setEmailExist(response));
        }
    };

    const handleChange = (evt: any, isContact = false) => {
        const value = evt.target.value?.trim();
        checkIfNameExist(value, evt.target.name);
        if (isContact) {
            setState({
                ...state,
                contact: {
                    ...state.contact,
                    [evt.target.name]: value,
                },
            });
        } else {
            setState({
                ...state,
                [evt.target.name]: value,
            });
        }
    };

    return (
        <>
            <ConfirmationModal title={t('brand.account.personal_info_modal.delete_profile')}
                               description={t('brand.account.personal_info_modal.delete_profile_desc')}
                               show={showConfirmationModal}
                               closeModal={() => setShowConfirmationModal(false)}
                               action={deleteProfile}
            />
            {updateInitialData &&
                <Modal show={show} onHide={closeModal} size="lg">
                    <Modal.Header>
                        <Modal.Title>
                            {t('brand.account.personal_info')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className={`${userType !== UserRoles.INFLUENCER ? '' : 'mb-lg-4 mb-2'}`}>
                            <Col lg="4" className="mb-md-0 p-0">
                                <UploadImage saveFile={setFiles} isDeleteHidden={true}
                                             classes="p-0 px-md-3"
                                             previewImage={state?.profilePhoto?.path ?? '/icon/img-missImg.png'}
                                             isMultiple={false} isPreviewArray={false}/>
                                <p className="text-muted mt-3 text-center">{t('brand.account.personal_info_modal.img_desc')}</p>
                            </Col>
                            <Col lg="8" className={`${userType !== UserRoles.INFLUENCER ? 'px-0 px-md-3' : ''}`}>

                                <Row
                                    className={userType !== UserRoles.INFLUENCER ? 'mt-4 mt-lg-0' : ''}>
                                    {userType === UserRoles.INFLUENCER && (
                                        <Col className="px-0" md={12}>
                                            <Form.Group className="mb-4 mt-lg-0 mt-4">
                                                <TextField
                                                    onChange={handleChange}
                                                    error={displayNameExist}
                                                    value={state.displayName}
                                                    fullWidth
                                                    label={t('general.influencer_name')}
                                                    variant="outlined"
                                                    size="small"
                                                    name="displayName"
                                                    helperText={displayNameExist ? t('influencer.influencer_name_helper_text') : null}
                                                />
                                            </Form.Group>
                                        </Col>
                                    )}
                                    <Col className="px-0 pr-md-2" md={6}>
                                        <Form.Group className="mb-4">
                                            <TextField
                                                onChange={handleChange}
                                                fullWidth
                                                value={state.firstName}
                                                label={t('general.first_name')}
                                                variant="outlined"
                                                size="small"
                                                name="firstName"
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col className="px-0 pl-md-2" md={6}>
                                        <Form.Group className="mb-4">
                                            <TextField
                                                onChange={handleChange}
                                                fullWidth
                                                value={state.lastName}
                                                label={t('general.last_name')}
                                                variant="outlined"
                                                size="small"
                                                name="lastName"
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {userType === UserRoles.INFLUENCER ?
                                    <Row>
                                        <Col md={6} className="pl-0">
                                            <Form.Group>
                                                <TextField
                                                    defaultValue={state?.contact?.country}
                                                    onChange={e => (handleChange(e, true))}
                                                    size="small"
                                                    name="country"
                                                    label={t('general.address.country')}
                                                    select
                                                    variant="outlined"
                                                    fullWidth
                                                >
                                                    {country.map((countryName) => (
                                                        <MenuItem key={countryName}
                                                                  value={countryName}>{t(`general.countries.${countryName}`)}</MenuItem>
                                                    ))
                                                    }
                                                </TextField>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className="px-0">
                                            <Form.Group className="mb-4 mt-lg-0 mt-4">
                                                <TextField
                                                    onChange={(e) => setState(prev => ({
                                                        ...prev,
                                                        cityOfResidence: e.target.value,
                                                    }))}
                                                    value={state.cityOfResidence}
                                                    fullWidth
                                                    label={t('general.cityOfResidence')}
                                                    variant="outlined"
                                                    size="small"
                                                    name="cityOfResidence"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    : <>
                                        <Form.Group className="mb-4">
                                            <TextField
                                                fullWidth
                                                onChange={e => (handleChange(e, true))}
                                                value={state.contact?.phone}
                                                label={t('general.phone_number')}
                                                variant="outlined"
                                                size="small"
                                                name="phone"
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <TextField
                                                error={emailExist}
                                                fullWidth
                                                onChange={handleChange}
                                                value={state.email}
                                                label={t('general.email')}
                                                variant="outlined"
                                                size="small"
                                                name="email"
                                                helperText={emailExist ? t('influencer.email_helper_text') : null}
                                            />
                                        </Form.Group>
                                    </>
                                }
                            </Col>
                        </Row>

                        {userType === UserRoles.INFLUENCER && (<>
                            <Row>
                                <Col md={3}>
                                    <Form.Group className="mb-4">
                                        <TextField
                                            fullWidth
                                            onChange={e => (handleChange(e, true))}
                                            value={state.contact?.phone}
                                            label={t('general.phone_number')}
                                            variant="outlined"
                                            size="small"
                                            name="phone"
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={3}>
                                    <Form.Group className="mb-4">
                                        <TextField
                                            error={emailExist}
                                            fullWidth
                                            onChange={handleChange}
                                            value={state.email}
                                            label={t('general.email')}
                                            variant="outlined"
                                            size="small"
                                            name="email"
                                            helperText={emailExist ? t('influencer.email_helper_text') : null}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="mb-4 mb-md-0">
                                    <Form.Group className="custom-datepicker mb-0 pr-md-0">
                                        <CustomDatePicker label={t('general.birthdate')}
                                                          value={state?.birthdate} onChange={(newValue) => {
                                            setState({
                                                ...state,
                                                birthdate: newValue?.toISOString(),
                                            });
                                        }}/>
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="my-4 my-md-0">
                                    <TextField
                                        fullWidth
                                        defaultValue={state?.gender}
                                        onChange={(e) => handleChange(e, false)}
                                        size="small"
                                        name="gender"
                                        label={t('general.gender_')}
                                        select
                                        variant="outlined"
                                        className="pl-md-0"
                                    >
                                        {gender?.map((item) => (
                                            <MenuItem key={item} value={item}
                                                      className={`${item === 'undefined' && 'd-none'}`}>
                                                {t(`general.gender.${item}`)}</MenuItem>
                                        ))
                                        }
                                    </TextField>

                                </Col>
                            </Row>
                        </>)}
                    </Modal.Body>
                    <Modal.Footer className={userType === 'influencer' ? 'justify-content-between' : ''}>
                        {userType === 'influencer' && (
                            <p onClick={() => setShowConfirmationModal(true)}
                               className="mb-0 cursor-pointer text-danger-hover">
                                <i className="fi fi-rr-delete-user mr-2"/>
                                {t('influencer.profile.deleteInfluenceProfile')}
                            </p>
                        )}
                        <div>
                            <Button className="mr-2" variant="outline-primary" onClick={closeModal}>
                                {t('general.button.close')}
                            </Button>
                            <Button variant="primary" onClick={onSubmit}>
                                {t('general.button.save')}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}

export default PersonalInfoModal;
