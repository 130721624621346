import React, {SetStateAction, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {AuthSocialPlatformCard} from '../../../../../authentication/social/components/AuthSocialPlatformCard';
import {ManualAuthorisationModal} from '../../../../../authentication/social/modal/ManualAuthorisation';
import {Platforms} from '../../../../../model/platform/Platforms';
import {IAllStates} from '../../../../../store/rootReducer';
import {ErrorToast} from '../../../../../utils/toasters';
import {InfluencerService} from '../../../service';

interface IProfileSocialProps {
    platforms: Platforms[];
    setUpdatePlatforms: React.Dispatch<SetStateAction<boolean>>;
}

const ProfileSocial = ({platforms, setUpdatePlatforms}: IProfileSocialProps) => {
    const {t} = useTranslation();
    const [isAuthorisationOpened, setIsAuthorisationOpened] = useState(false);
    const [platform, setPlatform] = useState<Platforms>();
    const [enabledPlatformData, setEnabledPlatformData] = useState<Platforms | undefined>(undefined);
    const {user: {id}} = useSelector((state: IAllStates) => state.auth);
    const selectedInfluencerId = localStorage.getItem('selectedAgencyCreator');

    const closeModal = () => {
        setIsAuthorisationOpened(false);
        setUpdatePlatforms(prev => !prev);
    };
    const openModal = (selectedPlatform?: Platforms) => {
        setIsAuthorisationOpened(true);
        setPlatform(selectedPlatform);
        InfluencerService.getPlatformData(Number(selectedPlatform?.id)).then(response => {
            setEnabledPlatformData(response);
        }).catch(error => ErrorToast(error));
    };

    return (
        <div className="mb-4">
            <ManualAuthorisationModal show={isAuthorisationOpened} closeModal={closeModal}
                                      enabledPlatformData={enabledPlatformData}
                                      platform={platform}
                                      id={selectedInfluencerId ? +selectedInfluencerId : id}
            />
            <h6>
                {t('influencer.profile.socialAccounts')}
            </h6>
            <span className="text-muted">
                {t('influencer.profile.socialAccountsDescription')}
            </span>
            <Row>
                {platforms?.map((item) => (
                    <Col key={item?.id} md={4} className="pl-0 mt-3">
                        <AuthSocialPlatformCard setUpdatePlatforms={setUpdatePlatforms} openManualAuthModal={openModal}
                                                platform={item}/>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default ProfileSocial;
