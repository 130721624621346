import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import moment from 'moment/moment';
import React, {SetStateAction, useEffect, useState} from 'react';
import {BsExclamationOctagon,} from 'react-icons/bs';
import {FaDotCircle} from 'react-icons/fa';
import {iManageList} from '../../../app/brand/campaign/manage/calendar/dto/IManageCalendar';
import {Campaign} from '../../../model/campaign/Campaign';
import {getCorrectStatusBackgroundColor} from '../../functions/Functions';

interface iCustomCalendarProps {
    setShowModal: React.Dispatch<SetStateAction<boolean>>;
    setShowUpdateModal: React.Dispatch<SetStateAction<boolean>>;
    setSelectedEventId: React.Dispatch<SetStateAction<number>>;
    setNewEvent: React.Dispatch<SetStateAction<any>>;
    selectedCalendarView: string;
    setIsProposalOpened: React.Dispatch<SetStateAction<boolean>>;
    allManages: iManageList[];
    campaign?: Campaign;
}

export const CustomCalendar = ({
                                   setShowModal,
                                   allManages,
                                   setIsProposalOpened,
                                   campaign,
                                   setShowUpdateModal,
                                   setSelectedEventId,
                                   selectedCalendarView,
                                   setNewEvent,
                               }: iCustomCalendarProps) => {
    const [maxDaysEvents, setMaxDaysEvents] = useState(2);
    const [currentCalendarDate, setCurrentCalendarDate] = useState<Date>(new Date());
    const handleEventClick = (info: any) => {
        const eventId = +info.event?._def?.publicId;
        const eventStatus = info?.event?._def?.extendedProps?.status;
        if (eventStatus === 'proposed') {
            setIsProposalOpened(true)
        } else {
            setShowUpdateModal(true);
        }
        setSelectedEventId(eventId);
    };

    const handleDateClick = (event: any) => {
        setCurrentCalendarDate(new Date(event?.date));
        // const current = new Date();
        // // const isDateInPast = moment(event?.date);
        // //
        // // if (isDateInPast?.diff(current, 'days') < 0) {
        // //     return WarningToast(t('general.toasts.warnEventPast'));
        // // }
        // @ts-ignore
        setNewEvent((prev) => ({...prev, postingDate: `${event?.dateStr}T05:00:00.000Z`}));
        setShowModal(true);
    };

    const handleDatesRender = (event: any) => {
        // Destructure required properties from the event object
        const {view} = event;
        const {calendarApi} = view.getCurrentData();
        const selectedCalendarView = calendarApi?.currentDataManager?.data?.currentDate?.setHours(12);
        // Check if the calendar data matches the current date, if so, return early
        if (calendarApi.currentDataManager.data.currentDate === currentCalendarDate) {
            return;
        }
        // Extract the selected calendar date and set it to noon to avoid timezone issues
        let selectedCalendarDate = new Date(calendarApi.currentDataManager.data.currentDate);
        selectedCalendarDate.setHours(12);
        // Update the current calendar date based on the selected view
        if (selectedCalendarView === 'dayGridWeek') {
            setCurrentCalendarDate(selectedCalendarDate);
        } else {
            // Get the month of the current calendar date
            const currentMonth = new Date(currentCalendarDate).getMonth();

            // Get the month of the selected calendar date
            const selectedMonth = selectedCalendarDate.getMonth();

            // If the months are different, set the calendar date to the beginning of the selected month
            if (currentMonth !== selectedMonth) {
                setCurrentCalendarDate(new Date(selectedCalendarDate.getFullYear(), selectedMonth, 1));
            }
        }
    };
    const CustomCalendarJSX = (props: any) => {

        return (
            <FullCalendar
                plugins={[dayGridPlugin, bootstrap5Plugin, listPlugin, interactionPlugin]}
                initialView={props.selectedCalendarView}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
                validRange={{
                    start: campaign?.publishFrom,
                    end: moment(campaign?.publishTo).add(1, 'days').toISOString(),
                }}
                themeSystem="bootstrap5"
                headerToolbar={{
                    left: 'prev today next',
                    center: 'title',
                    right: '',
                }}
                firstDay={1}
                timeZone="local"
                dayMaxEvents={props.maxDaysEvents}
                datesSet={(event) => {
                    props.handleDatesRender(event);
                }}
                initialDate={props.currentCalendarDate}
                events={allManages as any}
                eventContent={renderEventContent}
            />
        );
    };

    useEffect(() => {
        if (selectedCalendarView === 'dayGridWeek') {
            setMaxDaysEvents(20);
        } else {
            setMaxDaysEvents(3);
        }
    }, [selectedCalendarView]);


    return (
        <CustomCalendarJSX selectedCalendarView={selectedCalendarView} handleDatesRender={handleDatesRender}
                           setCurrentCalendarDate={setCurrentCalendarDate} maxDaysEvents={maxDaysEvents}
                           currentCalendarDate={currentCalendarDate}/>
    );
};

function renderEventContent(eventInfo: any) {

    const iconProps = eventInfo?.event?._def?.extendedProps;
    const hasNotifications = !!iconProps?.eventNotifications?.[0]?.isUnread;
    const getCorrectIcon = (getStatus: string) => {
        if (getStatus === 'proposed') {
            return <BsExclamationOctagon className="proposed-icon"/>;
        }
        return <span
            className={`font-weight-extra-bold date-in-range`}
            style={{
                color: `${getCorrectStatusBackgroundColor(
                    iconProps?.status,
                )}`,
            }}
        >
            <FaDotCircle/>
        </span>;
    };
    return (
        <div className="white-space">
            {getCorrectIcon(iconProps?.status)}
            <span className="ml-1">
                {iconProps?.offerItem?.offer?.user?.displayName}
            </span>
            {hasNotifications && <div className="notification-bullet calendar" style={{right: 0, top: 0}}/>}
        </div>
    );
}
