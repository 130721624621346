import {requests} from '../../utils';
import {BASE_URL, INFLUENCER_SOCIAL_AUTH, INFLUENCER_SOCIAL_DELETE} from '../../utils/endpoints';
import {IAuthAutomatic} from './dto/IAuthAutomatical';
import {Platforms} from '../../model/platform/Platforms';

export const SocialService = {
    socialAuthorize: (body: IAuthAutomatic) => requests.post(INFLUENCER_SOCIAL_AUTH, body),
    disconnectSocial: (platformId?: number, userId?: number) => requests.delete(`${INFLUENCER_SOCIAL_DELETE}/${userId}/${platformId}`),
    getSocialPlatforms: (verificationId?: string): Promise<Platforms[]> => requests.get(`${BASE_URL}auth/influencer/social/${verificationId}`),
    reAuthorizePlatform : (userId: number, platformId: number) => requests.post(`${BASE_URL}social/command/0/1/${userId}/${platformId}/0/0`, {}),
    getCurrentAudienceData: (id?: number, userId?: number) => requests.get(`${BASE_URL}current/audience/data/${id}/user/${userId}`),
};
