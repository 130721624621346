import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Modal} from 'react-bootstrap';
import {Checkbox} from '@material-ui/core';
import {User} from '../../../../../model/user/User';

interface IConfirmationModalProps {
    action?: any;
    grandTotal: string | 0;
    show: boolean;
    closeModal: () => void;
    disableButton: boolean;
    nbPosts?: number;
    user?: User;
    isInfluencer?: boolean;

}

export function ConfirmSendingOfferModal({
                                             action,
                                             show,
                                             closeModal,
                                             grandTotal,
                                             nbPosts,
                                             user,
                                             disableButton,
                                             isInfluencer = false,
                                         }: IConfirmationModalProps) {
    const {t} = useTranslation();
    const [isRequired, setIsRequired] = useState(false);

    const confirmSending = () => {
        if (isRequired) {
            action();
            closeModal();
        }
    };
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {checked} = event.target;
        setIsRequired(checked);
    };

    return (
        <>

            <Modal show={show}
                   onHide={closeModal}
                   className="modal-dark"
                   size={undefined}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered={true}
            >
                <Modal.Body>
                    <div className="mt-4 d-flex align-items-center justify-content-center flex-column">
                        <h4 className="text-primary text-center">{t('brand.campaign.book.offer.send_offer')}</h4>
                    </div>
                    <div className="text-center">
                        <p className="text-muted">
                            {t('brand.campaign.book.offer.confirm_submit_offer')}
                        </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                        <h6 className="mr-1">
                            {isInfluencer ? '' : (user?.firstName + ' ' + user?.lastName + ' ,')}
                        </h6>
                        <div className="d-flex align-items-center mr-1">
                            <h6 className="text-primary mr-1">
                                {nbPosts}
                            </h6>
                            <h6>{t('brand.campaign.book.offer.content_pieces')}</h6>
                        </div>
                        <h6 className="euro-symbol euro-symbol text-primary">
                            {grandTotal}
                        </h6>
                    </div>
                    <p className="mt-2 text-center">
                        {isInfluencer ?
                            t('brand.campaign.book.offer.confirm_submit_offer_desc_inf') :
                            t('brand.campaign.book.offer.confirm_submit_offer_desc')
                        }
                    </p>
                    <div className="d-flex align-items-center mt-3">
                        <Checkbox className="mb-0" required id="checkbox" onChange={changeHandler} color="primary"
                                  checked={isRequired}/>
                        <label htmlFor="checkbox" className={`text-left cursor-pointer mb-0 text-muted`}
                               dangerouslySetInnerHTML={{__html: t('brand.campaign.book.offer.terms')}}/>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button onClick={closeModal} variant="outline-primary" className="btn-sm mx-2">
                        {t('general.button.close')}
                    </Button>
                    <Button disabled={!isRequired || disableButton} variant="primary" className="btn-sm mx-2"
                            onClick={confirmSending}>
                        {t('general.button.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
