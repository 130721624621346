import {MenuItem, TextField} from '@material-ui/core';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Col, Form} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import PhoneInput, {CountryData} from 'react-phone-input-2';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {InternalRoutes} from '../../../../enum/InternalRoutes';
import CustomDatePicker from '../../../../shared/components/DatePicker';
import {UploadImage} from '../../../../shared/components/UploadImage';
import {calculatePastYears} from '../../../../shared/functions/Functions';
import {useSearchQuery} from '../../../../shared/hooks/useSearchQuery';
import {setRegisterUser} from '../../../../store/register/influencer/influencerRegistrationSlice';
import {IAllStates} from '../../../../store/rootReducer';
import {multipartFormData} from '../../../../utils';
import {AUTH_INFLUENCER} from '../../../../utils/endpoints';
import {ErrorToast, SuccessToast, WarningToast} from '../../../../utils/toasters';
import {AuthService} from '../../../service';
import {StepperButtons} from '../../brand/components/StepperButtons';
import {IInfluencerPersonalDetails} from '../dto/IInfluencerPersonalDetails';
import {IRegisterStepperProps} from '../dto/IRegisterStepperProps';

export function InfluencerStep4({stepIdNumber, handleBackStep}: IRegisterStepperProps) {
    const influencerData = useSelector((state: IAllStates) => state.influencerRegistration.user);
    const [personalDetails, setPersonalDetails] = useState<IInfluencerPersonalDetails>(
        {
            cityOfResidence: '',
            birthdate: '',
            contact: {
                phone: '',
                country: '',
            },
            firstName: '',
            gender: '',
            id: 0,
            lastName: '',
        },
    );

    const [files, setFiles] = useState<Blob[] | MediaSource[]>();
    const {gender, country} = useSelector((states: IAllStates) => states.enums);
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const token = useSearchQuery('token') as string;
    const [isPhoneValid, setIsPhoneValid] = useState(false);

    useEffect(() => {
        if (!!token.length) {
            AuthService.getInfluencerData(token).then(response => {
                dispatch(setRegisterUser(response));
                setPersonalDetails({
                    ...response, birthdate: response.birthdate ? response.birthdate : String(new Date()),
                });
            }).catch(error => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, []);

    const handleNextStep = () => {
        if (calculatePastYears(personalDetails?.birthdate) < 15) {
            return WarningToast(t('general.toasts.minimumYears'));
        }
        if (!isPhoneValid) {
            return WarningToast(t('general.toasts.warn_phone_validation'));
        }
        multipartFormData(files, `${AUTH_INFLUENCER}/personal-details`, JSON.stringify({
            ...personalDetails,
            birthdate: moment(personalDetails?.birthdate).format('YYYY-MM-DD')
        })).then(response => {
            if (response) {
                history.push(`${InternalRoutes.INFLUENCER_REGISTRATION}/${stepIdNumber + 1}?token=${response?.data?.verificationUUID}`);
                SuccessToast(t('auth.register.brand.step3.success'));
            }

        }).catch(error => ErrorToast(error));
    };
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isContact = false) => {
        const {name, value} = event.target;
        if (isContact) {
            setPersonalDetails(prev => ({
                ...prev, contact: {
                    ...prev?.contact,
                    [name]: value,
                },
            }));
        } else {
            setPersonalDetails(prev => ({...prev, [name]: value}));
        }
    };
    const changePhoneHandler = (value: string, data: CountryData) => {
        setIsPhoneValid(value.slice(data.dialCode.length).length > 3);
        setPersonalDetails(prev => ({
            ...prev, contact: {
                ...prev?.contact,
                phone: value,
            },
        }));
    };

    return (
        <>
            <Form onSubmit={(e) => {
                e.preventDefault();
                handleNextStep();
            }}>
                <div className="row justify-content-center">
                    <div className="col-md-7">
                        <UploadImage saveFile={setFiles}
                                     isDeleteHidden={true}
                                     previewImage={influencerData?.profilePhoto?.path}
                                     acceptedFormats="image/jpeg, image/png, image/jpg"
                                     isPreviewArray={false}
                                     isMultiple={false}/>
                    </div>

                    <Col md={6}>
                        <TextField
                            label={t('auth.register.influencer.step4.firstName')}
                            variant="outlined"
                            name="firstName"
                            value={personalDetails.firstName}
                            onChange={changeHandler}
                            size="small"
                            required
                            className="w-100 my-2"
                        />
                    </Col>
                    <Col md={6}>
                        <TextField
                            label={t('auth.register.influencer.step4.lastName')}
                            variant="outlined"
                            name="lastName"
                            value={personalDetails.lastName}
                            onChange={changeHandler}
                            size="small"
                            required
                            className="w-100 my-2"
                        />
                    </Col>
                    <Col md={6}>
                        <PhoneInput
                            country="de"
                            value={personalDetails.contact.phone ?? ''}
                            onChange={changePhoneHandler}
                            inputClass="w-100 my-2"
                        />
                    </Col>
                    <Col md={6} className="d-flex align-items-center my-2 ">
                        <TextField
                            fullWidth
                            value={personalDetails.gender}
                            onChange={(e) => changeHandler(e)}
                            size="small"
                            name="gender"
                            label={t('general.gender_')}
                            select
                            variant="outlined"
                            className="text-left"
                        >
                            {gender?.map((item) => (
                                <MenuItem key={item} value={item}
                                          className={`${item === 'undefined' && 'd-none'}`}>{t(`general.gender.${item}`)}</MenuItem>
                            ))
                            }
                        </TextField>
                    </Col>
                    <Col md={6} className="my-2 ">
                        <CustomDatePicker labelClass="mt-2 mt-md-0" label={t('auth.register.influencer.step4.birthday')}
                                          value={personalDetails?.birthdate}
                                          disableTo={new Date()}
                                          isRequired={true}
                                          onChange={(newValue) => {
                                              setPersonalDetails({
                                                  ...personalDetails,
                                                  birthdate: newValue?.toISOString() ?? '',
                                              });
                                          }}/>
                    </Col>
                    <Col md={6}>
                        <TextField
                            label={t('general.cityOfResidence')}
                            variant="outlined"
                            name="cityOfResidence"
                            value={personalDetails.cityOfResidence}
                            onChange={changeHandler}
                            size="small"
                            key={personalDetails?.id}
                            className="w-100 my-2"
                        />
                    </Col>
                    <Col md={12} className="d-flex align-items-end my-2 my-md-0 mt-md-2">
                        <TextField
                            value={personalDetails?.contact?.country}
                            onChange={e => (changeHandler(e, true))}
                            size="small"
                            name="country"
                            required
                            label={t('general.address.country')}
                            select
                            variant="outlined"
                            className="w-100 text-left"
                        >
                            {country.map((countryName) => (
                                <MenuItem key={countryName}
                                          value={countryName}>{t(`general.countries.${countryName}`)}</MenuItem>
                            ))
                            }
                        </TextField>
                    </Col>
                    <div className="col-md-6 my-2">
                    </div>
                    <div className="col-12 mt-4">
                        <StepperButtons currentStep={stepIdNumber - 1} handleNextStep={handleNextStep}
                                        isBackHidden={false}
                                        handleBackStep={handleBackStep}/>
                    </div>
                </div>
            </Form>
        </>
    );
}
;
