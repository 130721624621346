import React, {SetStateAction} from 'react';
import {Button, Modal, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {ITempData} from './AddReportingView';
import ReportingBody from './modals/components/ReportingBody';
import ReportingHeader from './modals/components/ReportingHeader';
import ReportingScreenshots from './modals/components/ReportingScreenshots';
import YourContent from './modals/components/YourContent';
import {IReportingUpdate} from './ReportingCampaignCard';

interface Props {
    isAdditional: boolean;
    isModalOpened: boolean;
    handleCloseModal: () => void;
    handleSubmitData: () => void;
    createPosting: IReportingUpdate;
    setCreatePosting: React.Dispatch<SetStateAction<IReportingUpdate>>;
    tempData: ITempData;
}

const AddReportingStep2Modal = ({
                                    isAdditional,
                                    isModalOpened,
                                    handleSubmitData,
                                    handleCloseModal,
                                    createPosting,
                                    setCreatePosting,
                                    tempData,
                                }: Props) => {
    const {t} = useTranslation();
    const selectedOfferItem = {
        ...tempData,
        offerItem: isAdditional ? (tempData as any)?.offerItem : tempData?.offers?.[0]?.offerItems?.find(offerItem => offerItem?.id === createPosting?.offerItemId),
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, valueAsNumber} = event.target;
        setCreatePosting(prev => ({...prev, [name]: valueAsNumber}));
    };
    return (
        <>
            <Modal
                show={isModalOpened}
                centered
                size="xl"
                onHide={handleCloseModal}
                keyboard={false}
            >
                <Modal.Body>
                    <Row>
                        <ReportingHeader updateData={createPosting}
                                         selectedOfferItem={selectedOfferItem as any}/>
                        <YourContent setUpdateData={setCreatePosting} 
                                     externalLinks={createPosting?.externalLinks ?? []}
                                     isAdditional 
                                     setSelectedOfferItem={() => {}} 
                                     selectedAttachments={[]} 
                                     isUploadPossible
                        />
                        <ReportingBody
                            socialPlatform={isAdditional ? selectedOfferItem?.offerItem?.socialPlatform : selectedOfferItem?.campaign?.offers?.[0]?.offerItems
                                ?.find(offer => offer.id === createPosting?.offerItemId)?.socialPlatform}
                            updateData={createPosting} handleChange={handleChange}/>
                        <ReportingScreenshots
                            status=""
                            setUpdateData={setCreatePosting}
                            // fileKey="file"
                            reportItems={[]}/>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleCloseModal}>
                        {t('general.button.cancel')}
                    </Button>

                    <Button variant="primary" onClick={handleSubmitData}>
                        {t(`general.button.submit`)}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddReportingStep2Modal;
