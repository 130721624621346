import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {Checkbox} from '@material-ui/core';
import {IOffer} from '../../../../../model/offer/IOffer';
import {priceSplitter} from '../../../../../utils/helpers/priceSplitter';
import {User} from '../../../../../model/user/User';

interface IConfirmationModalProps {
    show: boolean;
    closeModal: () => void;
    grandTotal?: string | 0;
    nbPosts?: number;
    user?: User;
    offerId?: number;
    action?: any;
    offers?: IOffer[];
}

export function BookingAcceptOfferModal({
                                            action,
                                            show,
                                            offerId,
                                            closeModal,
                                            offers,
                                            user,
                                            grandTotal,
                                            nbPosts,
                                        }: IConfirmationModalProps) {
    const {t} = useTranslation();
    const [isRequired, setIsRequired] = useState(false);
    // const totalPlatformSum = offer?.totalPlatforms?.reduce((acc, item) => acc + (item.nbPostsTotal), 0);
    const isSingleOffer = !!user?.id;
    const confirmSending = () => {
        if (isRequired) {
            action(offerId ?? 0);
            closeModal();
        }
    };
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {checked} = event.target;
        setIsRequired(checked);
    };

    return (
        <>
            <Modal show={show}
                   onHide={closeModal}
                   className="modal-dark"
                   size={isSingleOffer ? undefined : 'lg'}
                   centered={true}
            >
                <Modal.Body>
                    <div className="mt-4 d-flex align-items-center justify-content-center flex-column">
                        <h4 className="text-primary text-center">{isSingleOffer ? t('brand.campaign.book.offer.accept_offer_title') : t('brand.campaign.book.offer.accept_selected_offer_title')}</h4>
                    </div>
                    <div className="text-center">
                        <p className="text-muted">
                            {isSingleOffer ? t('brand.campaign.book.offer.accept_offer_desc') : t('brand.campaign.book.offer.accept_selected_offer_desc')}
                        </p>
                        {/*DISPLAY ONE OFFER OR MULTIPLE*/}
                        {isSingleOffer &&
                            <div className="d-flex align-items-center justify-content-center">
                                <h6 className="mr-1">
                                    {user?.firstName + ' ' + user?.lastName},
                                </h6>
                                <div className="d-flex align-items-center mr-1">
                                    <h6 className="text-primary mr-1"> {nbPosts}</h6>
                                    <h6>{t('brand.campaign.book.offer.content_pieces')}</h6>
                                </div>
                                <h6 className="euro-symbol euro-symbol text-primary">
                                    {grandTotal}
                                </h6>
                            </div>
                        }
                        {!isSingleOffer &&
                            <>
                                {offers?.map(item => (
                                    <Row>
                                        <Col xs={4}>
                                            <h6 className="mr-1">
                                                {item?.user?.firstName + ' ' + item?.user?.lastName}
                                            </h6>
                                        </Col>
                                        <Col xs={4}>
                                            <h6>
                                                {t('brand.campaign.book.offer.content_units', {total: item?.totalPlatforms?.reduce((acc, total) => acc + (total.nbPostsTotal), 0)})}
                                            </h6>
                                        </Col>
                                        <Col xs={4}>
                                            <h6 className="euro-symbol text-primary">
                                                {priceSplitter(Math.round(item?.price ?? 0))}
                                            </h6>
                                        </Col>
                                    </Row>
                                ))}
                                <hr/>
                                <Row>
                                    <Col xs={4}><h6>{t('general.total')}</h6></Col>
                                    <Col xs={4}>
                                        <h6>
                                            {offers?.reduce((acc, item) => acc + (item.totalPlatforms?.reduce((acc2, total) => acc2 + (total.nbPostsTotal), 0)), 0)}
                                        </h6>
                                    </Col>
                                    <Col xs={4}>
                                        <h6 className="euro-symbol text-primary">
                                            {priceSplitter(offers?.reduce((acc, item) => acc + (item?.price), 0) ?? 0)}
                                        </h6>
                                    </Col>
                                </Row>
                            </>
                        }
                        <p className="mt-2">
                            {isSingleOffer ? t('brand.campaign.book.offer.accept_offer_reminder') : t('brand.campaign.book.offer.accept_selected_offer_reminder')}
                        </p>
                    </div>
                    <div className="d-flex align-items-center mt-3">
                        <Checkbox className="mb-0" required id="checkbox" onChange={changeHandler} color="primary"
                                  checked={isRequired}/>
                        <label htmlFor="checkbox" className={`text-left cursor-pointer mb-0 text-muted`}
                               dangerouslySetInnerHTML={{__html: isSingleOffer ? t('brand.campaign.book.offer.accept_offer_terms') : t('brand.campaign.book.offer.accept_selected_offer_terms')}}/>
                    </div>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between">
                    <Button onClick={closeModal} variant="outline-primary" className="btn-sm mx-2">
                        {t('general.button.cancel')}
                    </Button>
                    <Button disabled={!isRequired} variant="primary" className="btn-sm mx-2" onClick={confirmSending}>
                        {t('general.button.accept')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
