import {requests} from '../../../utils';
import {BaseModel} from '../../../model/shared/BaseModel';
import {
    ASSIGN_USER_URL,
    BRAND_DASHBOARD_BRANDS_URL,
    BRAND_DASHBOARD_URL,
    FAVORITE_URL, INFLUENCER_PROFILE,
    UN_ASSIGN_USER_URL,
} from '../../../utils/endpoints';
import {CampaignFilterParams, IBrandDashboard} from './dto/IBrandDashboard';
import {Brand} from '../../../model/user/Brand';
import {FavoriteCampaign} from '../../../model/campaign/FavoriteCampaign';
import {IAssignUserRequest} from './dto/IAssignUserRequest';
import {Campaign} from '../../../model/campaign/Campaign';
import {IProfileData} from '../../influencer/profile/profile-view';

export const BrandDashboardService = {
    getCampaigns: (params: CampaignFilterParams): Promise<BaseModel<IBrandDashboard>> => requests.get(BRAND_DASHBOARD_URL, params),
    getBrands: (): Promise<Brand[]> => requests.get(BRAND_DASHBOARD_BRANDS_URL),
    addToShortcuts: (id: number): Promise<FavoriteCampaign[]> => requests.put(`${FAVORITE_URL}/${id}`, {}),
    assignUserToCampaign: (body: IAssignUserRequest): Promise<Campaign> => requests.post(ASSIGN_USER_URL, body),
    deleteUserFromCampaign: (body: IAssignUserRequest): Promise<Campaign> => requests.post(UN_ASSIGN_USER_URL, body),
    getInfluencerProfile: (id: string): Promise<IProfileData> => requests.get(`${INFLUENCER_PROFILE}/public/${id}`),
};
