import React, {SetStateAction, useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import ProfileInfo from './components/ProfileInfo';
import ProfileForm from './components/ProfileForm';
import {InfluencerService} from '../../service';
import {ErrorToast} from '../../../../utils/toasters';
import {IProfileData} from '../profile-view';
import {useParams} from 'react-router-dom';
import {IParams} from '../../../../model/shared/IParams';
import {Prompt} from 'react-router';
import {useTranslation} from 'react-i18next';

const InfluencerProfileEdit = () => {
    const [profileData, setProfileData] = useState<IProfileData>();
    const [updatePlatforms, setUpdatePlatforms] = useState(false);
    const {id} = useParams<IParams>();
    const [initialAboutText, setInitialAboutText] = useState('');
    const {t} = useTranslation();

    useEffect(() => {
        if (id) {
            InfluencerService.getInfluencerProfile(id as unknown as string).then(response => {
                setProfileData(response);
                setInitialAboutText(response.user.about);
            }).catch(error => ErrorToast(error));
        }
    }, [id, updatePlatforms]);

    return (
        <Row className="mb-5 pb-5 mb-md-0 pb-md-0">
            <Prompt
                when={initialAboutText !== profileData?.user?.about}
                message={t('influencer.profile.unsaved_changes')}
            />
            <Col md={3}>
                <ProfileInfo defaultState={profileData?.user} setUpdatePlatforms={setUpdatePlatforms} isEdit/>
            </Col>
            <Col md={9}>
                <ProfileForm userState={profileData as IProfileData}
                             setState={setProfileData as React.Dispatch<SetStateAction<IProfileData>>}
                             setUpdatePlatforms={setUpdatePlatforms}
                />
            </Col>
        </Row>
    );
};

export default InfluencerProfileEdit;
