import React from 'react';
import {useTranslation} from 'react-i18next';
import {Card} from 'react-bootstrap';

interface IDeclineOfferProps {
  declineComment: string;
  declineReason: string[];
  status: string;
}

export function DeclineOffer({declineComment, declineReason, status}: Partial<IDeclineOfferProps>) {
  const {t} = useTranslation();
  return (
    <>
      {(declineComment && status === 'declined') &&
    <div className="px-3 mb-3">
          <Card className="border-danger">
              <Card.Body>
                <h5 className="ml-2 mb-3">{t('brand.campaign.book.offer.decline_reason')}</h5>
                  <div className="d-flex align-items-center">
                      <h6 className="ml-2 text-muted">{declineReason?.map((item, index) => (
                        <span key={index}>{t(`general.decline_reasons.${item}`)}
                          {index === declineReason?.length - 1 ? '' : ', '}
                        </span>

                      ))}
                      </h6>
                  </div>
                {declineComment &&
                    <div className="default-border-lighter default-radius">
                        <p className="p-2 mb-0">{declineComment}</p>
                    </div>
                }
              </Card.Body>
          </Card>
    </div>
      }
    </>
  );
}
