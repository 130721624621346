import React, {useState} from 'react';
import {Helmet} from 'react-helmet';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import {Button, Form} from 'react-bootstrap';
import {TextField} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import {useSearchQuery} from '../../shared/hooks/useSearchQuery';
import {actions} from '../../store/authentication/authRedux';
import {AiFillEye, AiOutlineEyeInvisible} from 'react-icons/ai';
import {AuthService} from '../service';
import {ErrorToast} from '../../utils/toasters';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import {RegistrationTypeModal} from '../RegistrationTypeModal';
import {gtmService} from '../../utils/gtm.service';
import {InternalRoutes} from '../../enum/InternalRoutes';

interface Values {
    username: string;
    password: string;
}

export function Login() {
    const openRegisterModal = useSearchQuery('openRegisterModal');
    const [isSelectRegisterOpened, setIsSelectRegisterOpened] = useState(!!openRegisterModal);
    const initialValues = {
        username: '',
        password: '',
    };
    const [isTypePassword, setIsTypePassword] = useState(true);
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const history = useHistory();

    const LoginSchema = Yup.object().shape({
        username: Yup.string()
            .required(
                'Required field',
            ),
        password: Yup.string()
            .min(8, 'Minimum 8 symbols')
            .max(50, 'Maximum 50 symbols')
            .required(
                'Required field',
            ),
    });
    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values: Values, {setSubmitting, setStatus}) => {
            AuthService.login(values.username, values.password)
                .then((data) => {
                    if (data.userData.userType === 'internal') { // if someone try to login with office acc / internal on app
                        ErrorToast({response: {data: {message: 'This is internal user, please use Admin panel!'}}})
                    } else {
                        localStorage.setItem('token', JSON.stringify(data.token));
                        localStorage.setItem('refreshToken', data.refreshToken);
                        localStorage.setItem('appVersion', data?.userData.appVersion);
                        gtmService('login');
                        dispatch(actions.login(data.token));
                        dispatch(actions.setUser(data.userData));
                        !data?.userData?.is2FARequested ?
                            dispatch(actions.setIsVerified({isVerified: true}))
                            :
                            history.push(InternalRoutes.VERIFICATION);
                    }
                })
                .catch((e) => {
                    setSubmitting(false);
                    setStatus('The login detail is incorrect');
                    ErrorToast(e);
                });
        },
    });
    const closeModal = () => {
        setIsSelectRegisterOpened(false);
    };
    return (
        <>
            <Helmet>
                <title>Login | influence.vision</title>
                <meta name="description"
                      content="Your #1 software with integrated services for successful influencer campaigns. Book, manage, and measure your campaigns all in one place."/>
            </Helmet>
            <RegistrationTypeModal show={isSelectRegisterOpened} closeModal={closeModal}/>
            <div className="row justify-content-center">
                <div className="col-12 mb-3">
                    <img src="/icons/profile/id-card.png" alt="id-card icon"/>
                </div>
                <div className="col-12">
                    <h3 className="font-weight-bold">
                        {t('auth.signin')}
                    </h3>
                    <p className="font-weight-normal text-black-50 mb-4">
                        {t('auth.enter_username_password')}
                    </p>
                </div>
                <div className="col-md-8">
                    <form onSubmit={formik.handleSubmit}>
                        {formik.status ? (
                            <div className="mb-10 alert alert-danger">
                                <div className="font-weight-normal">{formik.status}</div>
                            </div>
                        ) : (
                            <>
                            </>
                        )}
                        <Form.Group>
                            <TextField
                                fullWidth
                                id="username"
                                name="username"
                                label="Email"
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                error={formik.touched.username && Boolean(formik.errors.username)}
                                helperText={formik.touched.username && formik.errors.username}
                                variant="outlined"
                                size="small"
                            />
                        </Form.Group>
                        <Form.Group>
                            <div className="d-flex">
                                <TextField
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type={isTypePassword ? 'password' : 'text'}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    variant="outlined"
                                    size="small"
                                />
                                <div style={{marginLeft: '-1.5rem', zIndex: 1}} className="cursor-pointer pt-2"
                                     onClick={() => setIsTypePassword(prev => !prev)}>
                                    {isTypePassword ? <AiFillEye size={20}/> :
                                        <AiOutlineEyeInvisible size={20}/>}
                                </div>
                            </div>
                            <div className="d-flex justify-content-end mt-2">
                                <Link to="/auth/password/reset">{t('general.forgot')}</Link>
                            </div>
                        </Form.Group>
                        <Button className="w-100" disabled={formik.isSubmitting}
                                type="submit">
                            {t('general.button.login')}
                        </Button>
                        <Button variant="outline-primary" className="w-100 mt-3 d-md-none"
                                onClick={() => setIsSelectRegisterOpened(true)}>Sign up</Button>
                    </form>
                </div>
            </div>

        </>
    );
}
