import {CustomImageHandler} from '../../../../../../shared/components/CustomImageHandler';
import {IMessageThread, IMessageThreadMetadata} from '../../dto/IMessage';
import {IoMdArrowRoundBack} from 'react-icons/io';
import {Button, OverlayTrigger} from 'react-bootstrap';
import React, {SetStateAction, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Tooltip as BootstrapTooltip} from 'react-bootstrap';
import {Tooltip as MuiTooltip} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';

interface IMainChatHeaderProps {
    threadMetaData: IMessageThreadMetadata[];
    selectedThread: IMessageThread;
    setSelectedChatId: React.Dispatch<SetStateAction<number | undefined>>;
}

export const MainChatHeader = ({threadMetaData, selectedThread, setSelectedChatId}: IMainChatHeaderProps) => {
    const [openTooltip, setOpenTooltip] = useState(false);
    const {t} = useTranslation();
    const numberChatMembers = threadMetaData.length;
    const isGroupChat = threadMetaData?.length > 1;
    const isMobile = window.innerWidth <= 600;

    return (
        <div className="chat-header d-flex align-items-center justify-content-between p-2">
            <div className="d-flex align-items-center">
                {isGroupChat ?
                    <>
                        {isMobile ?
                            <div className="d-flex align-items-center">
                                {threadMetaData?.slice(0, 3)?.map((user, i) => (
                                    <OverlayTrigger
                                        key={user?.id}
                                        overlay={
                                            <BootstrapTooltip placement="top-end" id="tooltip-top"
                                                              className="cursor-pointer">
                                                {user?.participant?.firstName} {' '} {user?.participant?.lastName}
                                            </BootstrapTooltip>
                                        }
                                    >
                                        <img
                                            src={user?.participant?.profilePhoto?.thumbnail ?? '/icons/UserPlaceholder.png'}
                                            className="campaign-card-img mr-1"
                                            alt="Usar Profile"
                                            onError={(e: any) => (
                                                // eslint-disable-next-line
                                                (e.target.onerror = null),
                                                    (e.target.src = '/icons/UserPlaceholder.png')
                                            )}
                                        />
                                    </OverlayTrigger>
                                ))}
                                {(threadMetaData?.length ?? 0) > 3 &&
                                    <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                                        <MuiTooltip id="white-tooltip"
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onClose={() => setOpenTooltip(false)}
                                                    open={openTooltip}
                                                    title={<>
                                                        {threadMetaData?.slice(3)?.map(user => (
                                                            <div className="mx-1 my-2" key={user?.id}>
                                                                <div className="d-flex align-items-center">
                                                                    <CustomImageHandler
                                                                        classes="user-img-rounded"
                                                                        photoPath={user?.participant?.profilePhoto?.thumbnail ?? '/icons/UserPlaceholder.png'}
                                                                        altTag={`${user?.participant?.profilePhoto?.name}`}/>
                                                                    <h6 className="ml-1 text-dark mb-0">{user?.participant?.firstName}</h6>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>}
                                                    placement="right-end"
                                                    className="cursor-pointer ">
                                                <span
                                                    onClick={() => setOpenTooltip(prevState => !prevState)}
                                                    className="icon-round bg-dark font-weight-semi-bold"
                                                    style={{width: 33}}>
                                                    + {(threadMetaData?.length ?? 0) - 3}
                                                </span>
                                        </MuiTooltip>
                                    </ClickAwayListener>
                                }

                            </div> :
                            <div className="d-flex align-items-center campaign-user-list">
                                {threadMetaData.map((user, i) => (
                                    <OverlayTrigger
                                        key={user?.id}
                                        overlay={
                                            <BootstrapTooltip id="tooltip-top" className="cursor-pointer">
                                                {user?.participant?.firstName} {' '} {user?.participant?.lastName}
                                            </BootstrapTooltip>
                                        }
                                    >
                                        <img
                                            src={user?.participant?.profilePhoto?.thumbnail ?? '/icons/UserPlaceholder.png'}
                                            style={{marginLeft: `${i * 1.3}rem`}}
                                            className="campaign-card-img "
                                            alt="Usar Profile"
                                            onError={(e: any) => (
                                                // eslint-disable-next-line
                                                (e.target.onerror = null),
                                                    (e.target.src = '/icons/UserPlaceholder.png')
                                            )}
                                        />
                                    </OverlayTrigger>
                                ))}
                            </div>}
                    </>
                    :
                    <>
                        {isMobile ?
                            <OverlayTrigger
                                key={threadMetaData?.[0]?.participant?.id}
                                overlay={
                                    <BootstrapTooltip placement="top-end" id="tooltip-top" className="cursor-pointer">
                                        {threadMetaData?.[0]?.participant?.firstName} {' '} {threadMetaData?.[0]?.participant?.lastName}
                                    </BootstrapTooltip>
                                }
                            >
                                <img
                                    src={threadMetaData?.[0]?.participant?.profilePhoto?.thumbnail ?? '/icons/UserPlaceholder.png'}
                                    className="campaign-card-img mr-1"
                                    alt="Usar Profile"
                                    onError={(e: any) => (
                                        // eslint-disable-next-line
                                        (e.target.onerror = null),
                                            (e.target.src = '/icons/UserPlaceholder.png')
                                    )}
                                />
                            </OverlayTrigger>
                            :
                            < CustomImageHandler
                                altTag="User Profile"
                                classes="middle-rounded-logo"
                                photoPath={threadMetaData?.[0]?.participant?.profilePhoto?.thumbnail as string}
                            />
                        }
                    </>
                }
                {/*<div className="chat-status-info"/>*/}
                <div className={`d-flex flex-column ml-4`}>
                    {!!threadMetaData.length && !isMobile ? (
                        <h6
                            style={{marginLeft: `${isGroupChat ? numberChatMembers * 1.3 : 0}rem`}}
                            className="font-weight-semi-bold mb-0">
                            {isGroupChat ? threadMetaData?.map((item) => item.participant.firstName).join(', ')
                                : (threadMetaData?.[0]?.participant?.displayName ? threadMetaData?.[0]?.participant?.displayName
                                    : (threadMetaData?.[0]?.participant?.firstName + ' ' + threadMetaData?.[0]?.participant?.lastName))}
                        </h6>
                    ) : ' '}
                </div>
            </div>
            <h5 className="d-none d-md-flex mb-0 px-2 text-right">
                {selectedThread?.subject ?? '-'}
            </h5>
            <Button variant="outline-primary" className="ml-2 d-md-none d-flex align-items-center" onClick={() => {
                setSelectedChatId(undefined);
            }}>
                <IoMdArrowRoundBack className="mr-2"/>
                {t('general.button.back')}
            </Button>

        </div>
    );
};
