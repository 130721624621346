import {Col, Row} from 'react-bootstrap';
import React from 'react';
import {CustomImageHandler} from '../../../../../../../../shared/components/CustomImageHandler';

interface IReviewCommentProps {
    influencerName: string;
    influencerFeedback: string;
    influencerPhoto: string;
    influencerComment: string;
}

export const ReviewComment = ({
                                  influencerName,
                                  influencerFeedback,
                                  influencerPhoto,
                                  influencerComment,
                              }: IReviewCommentProps) => {
    return (
        <Row className="my-3">
            <Col xs={3} md={1}>
                <CustomImageHandler
                    altTag="Influencer profile logo"
                    classes="rounded-img-sm comment-img"
                    photoPath={influencerPhoto}
                />
            </Col>
            <Col xs={9} md={11}>
                <h6>{influencerName}</h6>
                <span className="text-primary pr-3">{influencerComment}</span>
                <p className="my-3">{influencerFeedback}</p>
            </Col>
        </Row>
    );
};
