import React from 'react';

interface ISwitch {
    id: string;
    name?: string;
    checked?: boolean;
    onChange: void | any;
    small?: boolean;
    disabled?: boolean;
    defaultChecked?: boolean;
    cssId?: string
}

const ToggleSwitch = ({id, name, checked, onChange, small, disabled, defaultChecked, cssId}: ISwitch) => {
    function handleKeyPress(e: any) {
        if (e.keyCode !== 32) {
            return;
        }

        e.preventDefault();
        onChange(!checked);
    }

    return (
        <div className={'toggle-switch' + (small ? ' small-switch' : '')}>
            <input
                type="checkbox"
                name={name}
                className="toggle-switch-checkbox"
                id={id}
                defaultChecked={defaultChecked}
                checked={checked}
                onChange={(e) => onChange(e.target.checked)}
                disabled={disabled}
            />
            {id ? (
                <label
                    className={`toggle-switch-label }`}
                    id={cssId}
                    tabIndex={disabled ? -1 : 1}
                    onKeyDown={(e) => handleKeyPress(e)}
                    htmlFor={id}
                >
          <span
              className={
                  disabled
                      ? 'toggle-switch-inner toggle-switch-disabled'
                      : 'toggle-switch-inner'
              }
              tabIndex={-1}
          ></span>
                    <span
                        className={
                            disabled
                                ? 'toggle-switch-switch toggle-switch-disabled'
                                : 'toggle-switch-switch'
                        }
                        tabIndex={-1}
                    >

                    </span>
                </label>
            ) : null}
        </div>
    );
};

export default ToggleSwitch;
