import React, {useEffect, useState} from 'react';
import {IRegisterStepperProps} from '../dto/IRegisterStepperProps';
import {Col, Form, Row} from 'react-bootstrap';
import {Platforms} from '../../../../model/platform/Platforms';
import {StepperButtons} from '../../brand/components/StepperButtons';
import {InternalRoutes} from '../../../../enum/InternalRoutes';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import {useSearchQuery} from '../../../../shared/hooks/useSearchQuery';
import {SocialService} from '../../../social/service';
import {AuthSocialPlatformCard} from '../../../social/components/AuthSocialPlatformCard';
import {ManualAuthorisationModal} from '../../../social/modal/ManualAuthorisation';
import {AuthService} from '../../../service';
import {setRegisterUser} from '../../../../store/register/influencer/influencerRegistrationSlice';
import {ErrorToast, WarningToast} from '../../../../utils/toasters';
import {useTranslation} from 'react-i18next';
import {InfluencerService} from '../../../../app/influencer/service';

export function InfluencerStep3({handleBackStep, stepIdNumber}: IRegisterStepperProps) {
    const [authPlatforms, setAuthPlatforms] = useState<Platforms[]>([]);
    const [isAuthorisationModalOpened, setIsAuthorisationModalOpened] = useState(false);
    const [platform, setPlatform] = useState<Platforms>();
    const [enabledPlatformData, setEnabledPlatformData] = useState<Platforms | undefined>(undefined);
    const influencerData = useSelector((state: IAllStates) => state.influencerRegistration.user);
    const history = useHistory();
    const token = useSearchQuery('token') as string;
    const [updatePlatforms, setUpdatePlatforms] = useState(false);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        AuthService.getInfluencerData(token).then(response => {
            dispatch(setRegisterUser(response));
        }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        SocialService.getSocialPlatforms(token).then((response) => {
            setAuthPlatforms(response);
        });
        // eslint-disable-next-line
    }, [updatePlatforms]);

    const handleNextStep = () => {
        // history.push(`${InternalRoutes.INFLUENCER_REGISTRATION}/${stepIdNumber + 1}?token=${influencerData?.verificationUUID}`);
        if (authPlatforms.some(item => ['approved', 'pending'].includes(item.status))) {
            history.push(`${InternalRoutes.INFLUENCER_REGISTRATION}/${stepIdNumber + 1}?token=${token}`);
        } else {
            WarningToast(t('general.toasts.authorize_platform'));
        }
    };
    const closeModal = () => {
        setIsAuthorisationModalOpened(false);
        setUpdatePlatforms(prev => !prev);
    };
    const openModal = (selectedPlatform?: Platforms) => {
        setIsAuthorisationModalOpened(true);
        setPlatform(selectedPlatform);
        InfluencerService.getPlatformData(Number(selectedPlatform?.id)).then(response => {
            setEnabledPlatformData(response);
        }).catch(error => ErrorToast(error));
    };

    return (
        <>
            <ManualAuthorisationModal show={isAuthorisationModalOpened} closeModal={closeModal} platform={platform}
                                      id={influencerData?.id} enabledPlatformData={enabledPlatformData}/>
            <Form onSubmit={(e) => {
                e.preventDefault();
                handleNextStep();
            }}>

                <Row>
                    {authPlatforms.map((item) => (
                        <Col key={item?.id} md={6} className="pl-0 mt-3">
                            <AuthSocialPlatformCard setUpdatePlatforms={setUpdatePlatforms}
                                                    openManualAuthModal={openModal} platform={item}/>
                        </Col>
                    ))}
                </Row>

                <div className="col-12 mt-4">
                    <StepperButtons currentStep={stepIdNumber - 1} handleNextStep={handleNextStep} isBackHidden={true}
                                    handleBackStep={handleBackStep}/>
                </div>
            </Form>
        </>
    );
};
