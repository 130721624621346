import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Fancybox } from '../../../../../../../../shared/components/Fancybox'
import { formatDate } from '../../../../../../../../shared/functions/Functions'
import { CustomImageHandler } from '../../../../../../../../shared/components/CustomImageHandler'
import { useTranslation } from 'react-i18next'
import { User } from '../../../../../../../../model/user/User'

export default function AudienceKpiScreenshots({user}: {user: User}) {
    const {t} = useTranslation();

  return (
    <Card>
        <Card.Body>
            <Col xs={12} className='px-0'>
                <h6>
                    {t('influencer.profile.audienceKPIScreenshots')}
                </h6>
                <Row>
                    {user?.userKpiScreenshots?.map((screenshot: any) => (
                        <Col md={2} key={screenshot?.id} className="pl-md-0 my-1">
                            <Fancybox options={{infinite: false}}>
                                <div
                                    data-fancybox="gallery-feedItems"
                                    className="cursor-pointer w-100"
                                    data-src={screenshot?.path ?? '/icons/filePlaceholder.png'}
                                >
                                    <CustomImageHandler
                                        classes="highlighted-post"
                                        thumbnailPath={screenshot.thumbnail}
                                        placeholder={screenshot?.path ?? '/icons/filePlaceholder.png'}
                                        photoPath={screenshot?.path ?? '/icons/filePlaceholder.png'}
                                        altTag={screenshot?.originalName!}/>
                                </div>
                            </Fancybox>
                            <div className='text-center text-primary'>{formatDate(screenshot?.createdAt)}</div>
                        </Col>
                    ))}
                </Row>
            </Col>
        </Card.Body>
    </Card>
  )
}
