import {getCorrectRegisterImage} from '../../../../shared/functions/Functions';
import {Link, useLocation} from 'react-router-dom';
import {Image} from 'react-bootstrap';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchQuery} from '../../../../shared/hooks/useSearchQuery';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import {AuthService} from '../../../service';
import {ErrorToast} from '../../../../utils/toasters';
import {
    setAgencyCompany,
} from '../../../../store/register/influencer/influencerRegistrationSlice';

export function AgencyInfluencerInfo() {
    const {t} = useTranslation();
    const {pathname} = useLocation();
    const agencyToken = useSearchQuery('agencyToken');
    const currentStepId = String(pathname)?.split('/').at(-1) ?? '';
    const {agencyCompany} = useSelector((state: IAllStates) => state.influencerRegistration);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!!agencyToken?.length) {
            AuthService.getAgencyCompany(agencyToken as string).then(response => {
                dispatch(setAgencyCompany(response));
            }).catch(error => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, [agencyToken]);


    return (
        <>
            <div
                className={`d-none max-height-100vh d-md-flex col-md-4 col-lg-6 flex-column justify-content-between auth-bg-image`}
                style={{backgroundImage: `url(${getCorrectRegisterImage(currentStepId, true)})`}}
            >
                <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                    <Image
                        alt="Logo"
                        className="max-h-70px"
                        src="/tempAssets/img-white-logo.png"
                    />
                </Link>
                <div className="flex-column d-flex justify-content-top align-items-center text-lg-center h-50">
                    <div className=" w-50 blur-background">
                        <div className="auth-bg-text">
                            <div className="row">
                                <div className="col-md-5">
                                    <CustomImageHandler
                                        photoPath={agencyCompany?.agency?.profilePhoto?.path ?? ''}
                                        classes="w-100 default-radius img-cover" altTag="Company logo"/>
                                </div>
                                <div className="col-md-7 px-0">
                                    <h4 className="mb-2">
                                        {agencyCompany?.agency?.displayName}
                                    </h4>
                                    <p className="font-weight-normal">
                                        {t('auth.register.agency.info.invitedBy', {
                                            agencyName: agencyCompany?.agency?.displayName,
                                        })}
                                    </p>
                                </div>
                                <div className="col-md-12 mt-2">
                                    <p className="font-weight-normal">
                                        {t('auth.register.agency.info.collaboration_workspace')}
                                        <Image className="ml-1" width="22" src="/tempAssets/rocket.svg"/>
                                    </p>
                                </div>
                                <div className="col-12">
                                    <div className="hr"></div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex justify-content-between mt-2">
                                        <p className="font-weight-normal">
                                            {t('auth.already_register')}
                                        </p>
                                        <Link to={'/'}>
                                            <p className="text-white">{t('auth.signin')}</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};