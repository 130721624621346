import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import './utils/i18n';
import {MaterialThemeProvider, theme} from './provider/MaterialThemeProvider';
import App from './app/App';
import {Provider} from 'react-redux';
import {store} from './store/store';
import {Router} from 'react-router-dom';
import '@fancyapps/ui/dist/fancybox.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import history from './history';
import ScrollToTop from './shared/hooks/ScrollToTop';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import {setupInterceptors} from './utils'; // theme css file
import TagManager from 'react-gtm-module'

setupInterceptors();

TagManager.initialize({
    gtmId: process.env.REACT_APP_TAG_MANAGER_ID ?? ''
})


ReactDOM.render(
    <React.StrictMode>
        <MaterialThemeProvider theme={theme}>
            <Router history={history}>
                <Provider store={store}>
                    <ScrollToTop>
                        <App/>
                    </ScrollToTop>
                </Provider>
            </Router>
        </MaterialThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
