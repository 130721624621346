import React from 'react';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {priceSplitter} from '../../../../utils/helpers/priceSplitter';

interface IOriginalOfferCardProps {
    image: string;
    date: string;
    displayName: string;
    price: number;
    isCharity: boolean;
}

export function OriginalOfferCard({image, date, price, displayName, isCharity}: IOriginalOfferCardProps) {
    const {t} = useTranslation();
    return (
        <Card>
            <div className="d-flex align-items-center justify-content-between img-cover p-2">
                <div className="d-flex align-items-center">
                    <CustomImageHandler photoPath={image} altTag="User Profile" classes="middle-rounded-logo"/>
                    <div className="d-flex flex-column ml-3">
                        <h6 className="mb-0">{date}</h6>
                        <span>{t('brand.campaign.book.offer.filters.from')} {displayName}</span>
                    </div>
                </div>
                {!!isCharity ? (
                    <>
                        {+priceSplitter(Math.round(price)) > 0 ? (
                            <h5 className="text-primary mb-0 euro-symbol d-flex">{priceSplitter(Math.round(price))}</h5>
                        ) : (
                            <h5 className='text-primary-dark d-inline-block'>{t('general.button.barter_deal')}</h5>
                        ) }
                    </>
                ) : (
                    <h5 className="text-primary mb-0 euro-symbol d-flex">{priceSplitter(Math.round(price))}</h5>
                )}
            </div>
        </Card>
    );
}
