import {Campaign} from '../../../model/campaign/Campaign';
import {requests} from '../../../utils';
import {BRAND_CAMPAIGN, BRAND_CAMPAIGNS, BRAND_SUPERVISORS, FAVORITE_URL} from '../../../utils/endpoints';
import {BaseModel} from '../../../model/shared/BaseModel';
import {FavoriteCampaign} from '../../../model/campaign/FavoriteCampaign';
import {User} from '../../../model/user/User';

export const CampaignService = {
    getCampaign: (id: number): Promise<Campaign> => requests.get(`${BRAND_CAMPAIGN}/${id}`),
    getCampaigns: (body: any): Promise<BaseModel<Campaign[]>> => requests.get(`${BRAND_CAMPAIGNS}`, body),
    addToShortcuts: (id: number): Promise<FavoriteCampaign[]> => requests.put(`${FAVORITE_URL}/${id}`, {}),
    getSupervisors: (id: number): Promise<User[]> => requests.get(`${BRAND_SUPERVISORS}/${id}`),
};
