import React from 'react';
import {actions} from '../../../../store/authentication/authRedux';
import {SuccessToast} from '../../../../utils/toasters';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import {useTranslation} from 'react-i18next';
import {Button} from 'react-bootstrap';
import {IMPERSONATION_LINK} from '../../../../utils/endpoints';

export function ExitImpersonation() {
  const {
    oldAuthToken,
    oldImpersonatedUser,
  } = useSelector((states: IAllStates) => states.auth);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const whoImpersonate = localStorage.getItem('whoImpersonate');
  const adminActiveRoute = localStorage.getItem('impersonateActiveRoute');

  const exitImpersonationAction = () => {
    if (whoImpersonate === 'admin') {
      window.location.replace(`${IMPERSONATION_LINK}/${adminActiveRoute}`);
      dispatch(actions.logout());
      localStorage.clear();
    } else {
      dispatch(actions.login(oldAuthToken));
      dispatch(actions.setUser(oldImpersonatedUser));
      localStorage.setItem('token', JSON.stringify(oldAuthToken));
      dispatch(actions.deleteImpersonation());
      localStorage.removeItem('whoImpersonate')
    }
    SuccessToast(t(`general.toasts.success_exit_impersonation`));
  };
  return(
    <>
      {oldAuthToken || whoImpersonate === 'admin' && (
        <Button variant="danger"
            className={`ml-4 ${oldAuthToken || whoImpersonate === 'admin' ? '' : 'd-none'}`}
            onClick={exitImpersonationAction}
            size="sm"
          >
          {t('general.exit_impersonation')}
        </Button>
      )}
    </>
    )
}
