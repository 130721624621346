import {createSlice} from '@reduxjs/toolkit';

export interface IEnums {
    ambassadorInvoiceStatus: string[];
    ambassadorStatus: string[];
    OfferFilteringEnum: string[];
    audienceDimension: string[];
    audienceMetricAge: string[];
    audienceMetricGender: string[];
    blogCategory: string[];
    brandType: string[];
    campaignAudience: string[];
    CampaignSharingTypesEnum: string[];
    AgencyCreatorsEnum: string[];
    campaignCategory: string[];
    campaignPackage: string[];
    campaignStatus: string[];
    UserPositionEnum: string[];
    campaignType: string[];
    CompanyEmployeesEnum: string[];
    CompanyIndustryEnum: string[];
    OfferItemDurationEnum: string[];
    cookieCategory: string[];
    cookieName: string[];
    cookieTheme: string[];
    country: string[];
    creditRequestStatus: string[];
    demoStatus: string[];
    externalSearchPlatform: string[];
    gender: string[];
    imagePurpose: string[];
    invoicingDeliveryStatus: string[];
    invoicingPaymentStatus: string[];
    invoicingPaymentType: string[];
    locale: string[];
    offerStatus: string[];
    percentage: number[];
    platform: string[];
    roles: string[];
    superBlogCategory: string[];
    useOfContent: string[];
    userType: string[];
    offerItemEventsEnum: string[];
    BrandedContentGoalsEnum: string[];
    UsageRightsTypesEnum: string[];
    OfferDeclineReasonEnum: string[];
    OfferDeclineReasonInfluencerEnum: string[];
    PackagesEnum: string[];
    CampaignUserTypeEnum: string[];
    ThreadTypeEnum: ThreadTypeEnum;
    OfferActiveStatusEnum: string[];
    OfferNegativeStatusEnum: string[];
}

export enum ThreadTypeEnum {
    BRIEFING = 'briefing',
    CUSTOMER_SUCCESS = 'customer_success',
    CONTENT_CREATORS = 'content_creators',
    AGENCY_CREATORS = 'agency_creators',
    CLIENT = 'client',
}

const enumsSlice = createSlice({
    name: 'enums',
    initialState: {
        ambassadorInvoiceStatus: [],
        ambassadorStatus: [],
        audienceDimension: [],
        audienceMetricAge: [],
        audienceMetricGender: [],
        blogCategory: [],
        brandType: [],
        campaignAudience: [],
        campaignCategory: [],
        campaignPackage: [],
        campaignStatus: [],
        campaignType: [],
        cookieCategory: [],
        cookieName: [],
        cookieTheme: [],
        country: [],
        creditRequestStatus: [],
        demoStatus: [],
        externalSearchPlatform: [],
        gender: [],
        imagePurpose: [],
        invoicingDeliveryStatus: [],
        invoicingPaymentStatus: [],
        invoicingPaymentType: [],
        locale: [],
        offerStatus: [],
        percentage: [],
        platform: [],
        roles: [],
        superBlogCategory: [],
        useOfContent: [],
        ThreadTypeEnum: [],
        userType: [],
        OfferActiveStatusEnum: [],
        OfferNegativeStatusEnum: [],

    },

    reducers: {
        setEnums: (state, action) => {
            state = action.payload;
            return state;
        },
    },
});

export default enumsSlice;
