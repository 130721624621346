import React, {useState} from 'react';
import {Button, Col, Form, Row, Spinner} from 'react-bootstrap';
import {TextField} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {Link, useHistory} from 'react-router-dom';
import {AuthService} from '../service';
import {ErrorToast, SuccessToast} from '../../utils/toasters';
import {useSearchQuery} from '../../shared/hooks/useSearchQuery';
import {AiFillEye, AiOutlineEyeInvisible} from 'react-icons/ai';

const ForgotPassword = () => {
    const {t} = useTranslation();
    const [isTypePassword, setIsTypePassword] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [forgotData, setForgotData] = useState({
        email: '',
        password: '',
        repeatPassword: '',
    });
    const token = useSearchQuery('token');
    const history = useHistory();
    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value, name} = event.target;
        setForgotData(prev => ({...prev, [name]: value}));
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        if (!!token?.length) {
            AuthService.validatePassword({
                ...forgotData,
                passwordToken: token,
            }).then(response => {
                if (response) {
                    SuccessToast(t('auth.forgot.pleaseLogin'));
                    history.push('/');
                }
            }).catch(error => ErrorToast(error)).finally(() => setIsLoading(false));
        } else {
            AuthService.resetPassword(forgotData).then(response => {
                if (response) {
                    SuccessToast(t('auth.forgot.checkEmail'));
                    history.push('/');
                }
            }).catch(error => ErrorToast(error)).finally(() => setIsLoading(false));
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Row className="justify-content-center">
                <Col xs={12} className="mb-3">
                    <img src="/icons/profile/id-card.png" alt="id-card icon"/>
                </Col>
                <Col xs={12}>
                    <h3 className="font-weight-bold">
                        {!token?.length ? t('general.forgot') : t('general.newPassword')}
                    </h3>
                    <p className="font-weight-normal text-black-50 mb-4">
                        {t('auth.forgot.enterData')}
                    </p>
                </Col>
                <Col md={8}>
                    <Form.Group>
                        {!token?.length ? <TextField
                                fullWidth
                                name="email"
                                label="Email"
                                type="email"
                                variant="outlined"
                                value={forgotData?.email ?? ''}
                                onChange={changeHandler}
                                size="small"
                            /> :
                            <>
                                <div className="d-flex align-items-center">
                                    <TextField
                                        fullWidth
                                        name="password"
                                        label={t('general.password')}
                                        type={!isTypePassword ? 'text' : 'password'}
                                        variant="outlined"
                                        value={forgotData?.password ?? ''}
                                        onChange={changeHandler}
                                        size="small"
                                    />
                                    <div style={{marginLeft: '-2rem', zIndex: 1}} className="cursor-pointer"
                                         onClick={() => setIsTypePassword(prev => !prev)}>
                                        {isTypePassword ? <AiFillEye size={20}/> :
                                            <AiOutlineEyeInvisible size={20}/>}
                                    </div>
                                </div>

                                <TextField
                                    fullWidth
                                    name="repeatPassword"
                                    label={t('general.repeatPassword')}
                                    type="password"
                                    variant="outlined"
                                    value={forgotData?.repeatPassword ?? ''}
                                    onChange={changeHandler}
                                    size="small"
                                    className="mt-3"
                                />
                            </>}
                        <div className="d-flex justify-content-end mt-2">
                            <Link to="/">{t('auth.forgot.back')}</Link>
                        </div>
                    </Form.Group>
                </Col>
                <Col xs={8}>
                    <Button className="w-100 d-flex align-items-center justify-content-center" disabled={isLoading}
                            type="submit">
                        {isLoading && <Spinner animation="border" className="mr-2" role="status" size="sm"/>}
                        {t('general.button.save')}</Button>
                </Col>
            </Row>
        </Form>
    );
};

export default ForgotPassword;
