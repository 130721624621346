import React, {SetStateAction} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Campaign} from '../../../model/campaign/Campaign';
import {Pagination} from '../../../shared/components/Pagination';
import {IAllStates} from '../../../store/rootReducer';
import {IMyCampaignsFilterProps} from '../campaign/dto/IMyCampaigns';
import MyCampaigns from './components/mobile/MyCampaigns';
import MyOffers from './components/mobile/MyOffers';
import {IMyOffers} from './dto/IDashboard';
import {TermsOfServiceAlert} from "../../../shared/components/alert/TermsOfServiceAlert";


interface Props {
    campaigns: Campaign[];
    myOffers: IMyOffers;
    filterParams: IMyCampaignsFilterProps;
    setFilterParams: React.Dispatch<SetStateAction<IMyCampaignsFilterProps>>;
    totalPages: number;
    handlePages: (updatePage: number) => void;
}

const MobileDashboard = ({campaigns, myOffers, setFilterParams, filterParams, handlePages, totalPages}: Props) => {
    const {t} = useTranslation();
    const {user} = useSelector((state: IAllStates) => state.auth);
    return (
        <div className="d-md-none">
            <h4>{t('influencer.header.welcome_back')} {user?.firstName}!</h4>
            <TermsOfServiceAlert/>
            <MyOffers myOffers={myOffers} filterParams={filterParams} setFilterParams={setFilterParams}/>
            <MyCampaigns campaigns={campaigns}/>
            {totalPages <= 1 ? (
                ''
            ) : (
                <Pagination
                    page={filterParams.page}
                    totalPages={totalPages}
                    handlePagination={handlePages}
                />
            )}
        </div>
    );
};

export default MobileDashboard;
