import React, {useState} from 'react';
import {Button, Card, Dropdown, OverlayTrigger} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {User} from '../../../../model/user/User';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {MessagesIcon, PlannerIcon, ReportingIcon} from '../../../../shared/Icons';
import {TotalPlatformsFooter} from './TotalPlatformsFooter';
import {FaEllipsisV} from 'react-icons/fa';
import {ICompanyUser} from '../../../shared/user/dto/ICompanyUser';
import Tooltip from 'react-bootstrap/Tooltip';
import Flag from 'react-world-flags';
import {UserRoles} from '../../../../enum/UserRoles';
import {useHistory} from 'react-router-dom';
import {InternalRoutes} from '../../../../enum/InternalRoutes';
import {setSelectetInfluencer} from '../../../../store/agency/agencySlice';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {Tooltip as MuiTooltip} from '@mui/material';
import {useScreenResize} from '../../../../shared/hooks/useScreenResize';

interface IAgencyDashboardProps {
    influencer?: User;
    impersonateUser: (id: number) => void;
    removeUser: (id: number) => void;
    showPriceListModal: (id: number) => void;
    showModalAndSetId: (id: number, agencyUsers: ICompanyUser[]) => void;
    showPersonalInfoModal: (id: number, userType: UserRoles) => void;
}

export function AgencyCardItem({
                                   influencer,
                                   showModalAndSetId,
                                   removeUser,
                                   showPriceListModal,
                                   showPersonalInfoModal,
                               }: IAgencyDashboardProps) {
    const [openTooltip, setOpenTooltip] = useState(false);
    const {width} = useScreenResize();
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const isBigScreen = width > 1600;
    const selectUserAction = () => {
        dispatch(setSelectetInfluencer(influencer));
    };
    const {user} = useSelector((state: IAllStates) => state.auth);

    const navigateToInfluencerProfile = () => {
        history.push(`${InternalRoutes.AGENCY_PROFILE}/${influencer?.id}`);
        localStorage.setItem('selectedAgencyCreator', `${influencer?.id}`);
    };
    const getExactRoute = (route: string = '') => {
        switch (route) {
            case 'planner':
                selectUserAction();
                return history.push(`${InternalRoutes.AGENCY_ROUTES}/planner`);
            case 'reporting':
                selectUserAction();
                return history.push(`${InternalRoutes.AGENCY_ROUTES}/reporting`);
            case 'messages':
                selectUserAction();
                return history.push(`${InternalRoutes.AGENCY_ROUTES}/messages`);
            case '':
                selectUserAction();
                return history.push(`${InternalRoutes.AGENCY_ROUTES}/dashboard`);
        }
    };
    return (
        <>
            <div key={influencer?.id} className={`col-md-6 col-lg-4 ${isBigScreen && 'col-xl-3'} d-flex`}>
                <Card className="brand-card">
                    <div className="position-relative agency-img-overlay">
                        <CustomImageHandler classes="w-100 img-cover default-radius-top gradient-from-bottom cursor-pointer"
                                            onClick={() => getExactRoute()}
                                            photoPath={influencer?.profilePhoto?.path as string}
                                            thumbnailPath={influencer?.profilePhoto?.thumbnail as string}
                                            style={{height: isBigScreen ? 280 : 250}}
                                            altTag={'User Profile'}/>
                        <div className="country-flag place-top-right">
                            <Flag code={influencer?.contact.country}/>
                        </div>
                        <TotalPlatformsFooter totalPlatforms={influencer?.totalPlatforms}/>
                    </div>

                    <div className="p-3">
                        <h5 className="mb-3">
                            {!!influencer?.displayName ? influencer?.displayName : (influencer?.firstName + ' ' + influencer?.lastName)}
                        </h5>
                        <div className="d-flex align-items-center justify-content-between mt-2">
                            <div
                                onClick={() => getExactRoute('planner')}
                                className="card py-3 d-flex align-items-center flex-column justify-content-center position-relative cursor-pointer">
                                <PlannerIcon width="20" height="20" color="#9ea5ab"/>
                                <span className="font-10">
                                    {t('influencer.campaign.tabs.planner')}
                                </span>
                                {influencer?.myOffersSummary?.myOffers?.plannerDot &&
                                    <div id="bullet" className="offer-bullet dashboard-dot"/>}
                            </div>
                            <div
                                onClick={() => getExactRoute('reporting')}
                                className="card py-3 mx-2 d-flex align-items-center flex-column justify-content-center position-relative cursor-pointer">
                                <ReportingIcon width="20" height="20" color="#9ea5ab"/>
                                <span className="font-10">
                                    {t('influencer.campaign.tabs.reporting')}
                                </span>
                                {influencer?.myOffersSummary?.myOffers?.reportingDot &&
                                    <div id="bullet" className="offer-bullet dashboard-dot"/>}

                            </div>
                            <div
                                onClick={() => getExactRoute('messages')}
                                className="card py-3 d-flex align-items-center flex-column justify-content-center position-relative cursor-pointer">
                                <MessagesIcon width="20" height="20" color="#9ea5ab"/>
                                <span className="font-10">
                                    {t('influencer.campaign.tabs.messages')}
                                </span>
                                {influencer?.myOffersSummary?.myOffers?.messagesDot &&
                                    <div id="bullet" className="offer-bullet dashboard-dot"/>}
                            </div>
                        </div>

                        <div className="mt-4">
                            <div className="d-flex">
                                <p className="text-muted">
                                    {t('agency.pending_offers')}:
                                </p>
                                <h6 className="text-primary ml-1">{influencer?.pendingOffers}</h6>
                            </div>
                            <div className="d-flex">
                                <p className="text-muted">
                                    {t('agency.accepted_postings')}:
                                </p>
                                <h6 className="text-primary ml-1">{influencer?.acceptedPostings}/{influencer?.totalPostings}</h6>
                            </div>
                            <div className="d-flex">
                                <p className="text-muted">
                                    {t('agency.due_reportings')}:
                                </p>
                                <h6 className="text-primary ml-1">{influencer?.dueReporting}</h6>
                            </div>
                        </div>
                    </div>
                    <Card.Footer>
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center campaign-user-list">
                                {influencer?.assignedManagers?.slice(0, 3).map((item, i) => (
                                    <OverlayTrigger
                                        key={item?.id}
                                        overlay={
                                            <Tooltip id="tooltip-top" className="cursor-pointer">
                                                {item.firstName} {''} {item.lastName}
                                            </Tooltip>
                                        }
                                    >
                                        <img
                                            src={item.profilePhoto?.path ?? '/icons/UserPlaceholder.png'}
                                            style={{marginLeft: `${i * 1.3}rem`}}
                                            className="campaign-card-img "
                                            alt="Campaign logo"
                                            onError={(e: any) => (
                                                // eslint-disable-next-line
                                                (e.target.onerror = null),
                                                    (e.target.src = '/icons/UserPlaceholder.png')
                                            )}
                                        />
                                    </OverlayTrigger>
                                ))}
                                <div className="position-absolute z-index-1" style={{marginLeft: `3.7rem`}}>
                                {(influencer?.assignedManagers?.length ?? 0) > (3) &&
                                    <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                                        <MuiTooltip id="white-tooltip"
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onClose={() => setOpenTooltip(false)}
                                                    open={openTooltip}
                                                    title={<>
                                                        {influencer?.assignedManagers?.slice(3)?.map(item => (
                                                            <div className="mx-1 my-2" key={item?.id}>
                                                                <div className="d-flex align-items-center">
                                                                    <CustomImageHandler
                                                                        classes="user-img-rounded"
                                                                        photoPath={item?.profilePhoto?.thumbnail ?? '/icons/UserPlaceholder.png'}
                                                                        altTag={`${item?.profilePhoto?.name}`}/>
                                                                    <h6 className="ml-1 text-dark mb-0">{item?.firstName}</h6>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </>}
                                                    placement="right-end"
                                                    className="cursor-pointer ">
                                                <span
                                                    onClick={() => setOpenTooltip(prevState => !prevState)}
                                                    className="icon-round bg-dark font-weight-semi-bold"
                                                    style={{width: 33}}>
                                                    + {(influencer?.assignedManagers?.length ?? 0) - 3}
                                                </span>
                                        </MuiTooltip>
                                    </ClickAwayListener>
                                }
                                </div>
                            </div>

                            <div>
                                <div className="d-flex">
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            className="mr-2"
                                            variant="light"
                                            id="dropdown-basic"
                                        >
                                            <FaEllipsisV className="text-secondary"/>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                                    <Dropdown.Item
                                                        onClick={navigateToInfluencerProfile}>
                                                        {t('agency.influencers.edit_profile')}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                        onClick={() => showPersonalInfoModal(influencer?.id ?? 0,
                                                            influencer?.userType as UserRoles)}>
                                                        {t('agency.influencers.edit_personal_info')}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item onClick={() => showPriceListModal(influencer?.id ?? 0)}>
                                                        {t('agency.influencers.edit_pricelist')}
                                                    </Dropdown.Item>
                                                    {user.userType === UserRoles.AGENCY_MASTER && <Dropdown.Item
                                                        onClick={() => {
                                                            showModalAndSetId(
                                                                influencer?.id ?? 0,
                                                                influencer?.assignedManagers ?? [],
                                                            );
                                                        }}
                                                    >
                                                        {t('agency.assign_to_user')}
                                                    </Dropdown.Item>}
                                                    <Dropdown.Item onClick={() => removeUser(influencer?.id ?? 0)}>
                                                        {t('agency.influencers.remove')}
                                                    </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Button
                                        size="sm"
                                        onClick={() => getExactRoute()}
                                    >
                                        {t('agency.manage')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Card.Footer>
                </Card>
            </div>

        </>
    );
}
