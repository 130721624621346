import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Dropdown, Modal, OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {ENotificationType} from '../../../../model/campaign/Campaign';
import {useSearchQuery} from '../../../../shared/hooks/useSearchQuery';
import {gtmService} from '../../../../utils/gtm.service';
import {InfluencerProfileInfo} from './components/InfluencerProfileInfo';
import {OfferModalTabs} from './tabs';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {formatDate, getGrandTotal, getNumberContentPieces, getStatusIcon} from '../../../../shared/functions/Functions';
import {
    setActiveTab,
    setCampaignId,
    setInitialState,
    setOfferModalDetails, setOfferModalStatus,
} from '../../../../store/influencer/offerInfluencerSlice';
import {useDispatch, useSelector} from 'react-redux';
import {ManagementOfferService} from '../service';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {IAllStates} from '../../../../store/rootReducer';
import {priceSplitter} from '../../../../utils/helpers/priceSplitter';
import {scrollToTopRef} from '../../../../utils/helpers/ScrollToTop';
import {scrollToBottomRef} from '../../../../utils/helpers/scrollToBottomRef';
import {ConfirmSendingOfferModal} from './components/ConfirmSendingOfferModal';
import {DeclineOfferModal} from './components/DeclineOfferModal';
import {IDeclineOffer} from '../dto/IDeclineOffer';
import {FiInfo} from 'react-icons/fi';
import {InfluencerAcceptOfferModal} from './components/InfluencerAcceptOfferModal';
import HtmlToPdf from '../../../../shared/components/generate-pdf';

interface InfluencerOfferModalProps {
    show: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    campaignId: string;
    updateList?: React.Dispatch<React.SetStateAction<boolean>>;
    setIsRocketShown?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function InfluencerOfferModal({
                                         show,
                                         setShowModal,
                                         campaignId,
                                         setIsRocketShown,
                                         updateList,
                                     }: InfluencerOfferModalProps) {
    const dispatch = useDispatch();
    const [showAcceptModal, setShowAcceptModal] = useState(false);
    const [showSubmitModal, setShowSubmitModal] = useState(false);
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [declineReasons, setDeclineReasons] = useState<IDeclineOffer>({declineReason: '', declineComment: ''});
    const offerModalRef = useRef<HTMLDivElement>(null);

    const {t} = useTranslation();
    const {
        campaign,
        activeTabModal,
        initialOffer,
        isEditable,
        canCounter,
        offerModalStatus,
        hasLegal,
        isCharity, isLegalChecked, isDistributionChecked, requiredFieldChangeColor,
    } = useSelector((state: IAllStates) => state.offerInfluencer);
    const history = useHistory();
    const notificationQueryType = useSearchQuery('notificationType');
    const grandTotalSum = priceSplitter(getGrandTotal(initialOffer.offerItems, initialOffer.distribution));

    useEffect(() => {
        show && ManagementOfferService.getOfferModalDetail(campaignId)
            .then((response) => {
                notificationQueryType?.length && history.push('/influencer/offers');
                dispatch(setOfferModalDetails({
                    ...response,
                    activeTabModal: notificationQueryType === ENotificationType.OFFER_COMMENT ? 5 : undefined
                }));
                dispatch(setCampaignId(campaignId));
            }).catch((err) => {
                closeModal();
                ErrorToast(err);
            });
        // eslint-disable-next-line
    }, [show]);

    const sendInitialOfferRequest = () => {
        setDisableButton(true);
        let initialOfferCopy = initialOffer;
        const offerItems = isCharity ? initialOfferCopy.offerItems : initialOfferCopy.offerItems.filter(item => (item.nbPosts > 0 && item.price > 0));
        const price = getGrandTotal(initialOfferCopy.offerItems, initialOfferCopy.distribution);
        initialOfferCopy = {...initialOfferCopy, offerItems, price};
        ManagementOfferService.sendInitialOffer(campaignId, initialOfferCopy)
            .then((response) => {
                gtmService('influencer_offer_submitted', grandTotalSum);
                SuccessToast(t('influencer.offer_management.modal.success_initial_message'));
                setDisableButton(false);
                closeModal();
            })
            .catch(err => {
                ErrorToast(err);
                setDisableButton(false);
            });
    };

    const sendCounterOfferRequest = () => {
        setDisableButton(true);
        let initialOfferCopy = initialOffer;
        const offerItems = isCharity ? initialOfferCopy.offerItems : initialOfferCopy.offerItems.filter(item => (item.nbPosts > 0 && item.price > 0));
        const price = getGrandTotal(initialOfferCopy.offerItems, initialOfferCopy.distribution);
        initialOfferCopy = {...initialOfferCopy, offerItems, price};
        ManagementOfferService.sendCounterOffer(campaignId, initialOfferCopy)
            .then((response) => {
                SuccessToast(t('influencer.offer_management.modal.success_update_message'));
                gtmService('influencer_offer_submitted', grandTotalSum);
                setDisableButton(false);
                closeModal();
            })
            .catch(err => {
                ErrorToast(err);
                setDisableButton(false);
            });
    };

    const setNextTab = () => {
        let currentStep;
        if (!hasLegal && activeTabModal === 2) {
            currentStep = activeTabModal + 2;
        } else {
            currentStep = activeTabModal + 1;
        }
        if (requiredFieldChangeColor && activeTabModal === 1) {
            scrollToTopRef(offerModalRef);
        } else if (!isLegalChecked && activeTabModal === 3) {
            scrollToBottomRef(offerModalRef);
        } else if (!isDistributionChecked && activeTabModal === 2) {
            scrollToBottomRef(offerModalRef);
        } else {
            scrollToTopRef(offerModalRef);
        }
        dispatch(setActiveTab(currentStep));
    };

    const acceptOffer = () => {
        ManagementOfferService.acceptOffer(campaignId)
            .then((response) => {
                gtmService('influencer_offer_accepted');
                setIsRocketShown && setIsRocketShown(true);
                SuccessToast(t('influencer.offer_management.modal.success_accepted_message'));
                closeModal();
            }).catch(err => ErrorToast(err));
    };
    const withdrawOffer = () => {
        ManagementOfferService.withdrawOffer(campaignId)
            .then((response) => {
                SuccessToast(t('influencer.offer_management.modal.success_withdraw_message'));
                closeModal();
            }).catch(err => ErrorToast(err));
    };
    const declineOffer = () => {
        ManagementOfferService.declineOffer(campaignId, declineReasons)
            .then((response) => {
                SuccessToast(t('influencer.offer_management.modal.success_decline_message'));
                closeModal();
            }).catch(err => ErrorToast(err));
    };

    const changeStatus = () => {
        dispatch(setActiveTab(4));
        dispatch(setOfferModalStatus('negotiate'));
    };
    const closeAcceptModal = () => {
        setShowAcceptModal(false);
    };
    const closeSubmitModal = () => {
        setShowSubmitModal(false);
    };
    const closeDeclineModal = () => {
        setShowDeclineModal(false);
    };

    const closeModal = () => {
        dispatch(setInitialState({}));
        setShowModal(false);
        if (updateList) {
            updateList((prevState => !prevState));
        }
    };

    const openSubmitConfirmModal = (event: any) => {
        event.preventDefault();
        setShowSubmitModal(true);
    };

    return (
        <>
            <Modal show={show}
                   onHide={closeModal}
                   size="xl"
                   id="offer-modal"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <form
                    onSubmit={(e) => openSubmitConfirmModal(e)}>
                    <Modal.Body
                        className={`${canCounter || (campaign?.offers[0]?.status === 'negotiate') ? 'negotiate-status-mobile' : `border border-2 border-${campaign?.offers[0]?.status}-color-mobile border-bottom-0`}`}>
                        {campaign?.offers[0]?.id ?
                            <div className={`offer-status-badge ${campaign?.offers[0]?.status} d-none d-md-flex`}>
                                {getStatusIcon(campaign?.offers[0]?.status ?? '')}
                                <p>{t(`general.offerStatus.${campaign?.offers[0]?.status}`)}</p>
                            </div> : null
                        }
                        <Row className="pb-4">
                            <Col xl={3} lg={12} className="profile-info px-0 px-md-3">
                                <InfluencerProfileInfo/>
                            </Col>
                            <Col xl={9} lg={12} className="px-0 px-md-3">
                                <OfferModalTabs offerModalRef={offerModalRef}/>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer className="position-relative bg-white shadow-top">
                        <Row className="justify-content-between align-items-center">
                            <Col xl={6} className="col-auto d-flex justify-content-md-between justify-content-end">
                                <div className="d-md-flex d-none align-items-center">
                                    <CustomImageHandler photoPath={campaign?.company?.profilePhoto?.path ?? ''}
                                                        altTag="User Profile"
                                                        classes="middle-rounded-logo"/>
                                    <h6 className="mb-0 ml-2">{campaign?.company?.displayName}</h6>
                                </div>
                                {activeTabModal === 4 ?
                                    <div className="d-block text-uppercase d-md-none mb-2">
                                        <div className="d-flex align-items-top">
                                            {campaign?.isCharity ? (
                                                <>
                                                    {+grandTotalSum > 0 ? (
                                                        <h5 className="text-primary mb-0 euro-symbol">
                                                            {grandTotalSum}
                                                        </h5>
                                                    ) : (
                                                        <h5 className="text-primary-dark m-0 font-14">{t('general.button.barter_deal')}</h5>
                                                    )}
                                                </>
                                            ) : (
                                                <h5 className="text-primary mb-0 euro-symbol">
                                                    {grandTotalSum}
                                                </h5>
                                            )}
                                            {isCharity && (
                                                <OverlayTrigger placement="top"
                                                                overlay={<Tooltip
                                                                    id="impressions-tooltip">
                                                                    {t(`influencer.explore.barterTooltip`)}
                                                                </Tooltip>}>
                                                    <FiInfo size={14} className="cursor-pointer ml-2"/>
                                                </OverlayTrigger>
                                            )}
                                        </div>
                                        <h6 className="mb-0 mt-1 font-11">{t('brand.campaign.book.offer.modal.grand_total')}</h6>
                                    </div> : null
                                }
                            </Col>
                            <Col md={12} xl={6}
                                 className="d-flex align-items-center justify-content-between justify-content-md-end col-auto">
                                {(campaign?.offers[0]?.status === 'accepted' || campaign?.offers[0]?.status === 'completed') && (
                                    <HtmlToPdf styleContent="mr-4"/>
                                )}
                                {activeTabModal === 4 &&
                                    <div className="align-items-center mr-md-4 mr-2 d-none d-md-flex text-uppercase">
                                        {isCharity && (
                                            <OverlayTrigger placement="top"
                                                            overlay={<Tooltip
                                                                id="impressions-tooltip">
                                                                {t(`influencer.explore.barterTooltip`)}
                                                            </Tooltip>}>
                                                <FiInfo size={14} className="cursor-pointer ml-2"/>
                                            </OverlayTrigger>
                                        )}
                                        <h6 className="mb-0 mx-1">{t('brand.campaign.book.offer.modal.grand_total')}:</h6>
                                        <div className="d-flex align-items-top">
                                            {campaign?.isCharity ? (
                                                <>
                                                    {+grandTotalSum > 0 ? (
                                                        <h5 className="text-primary mb-0 euro-symbol">
                                                            {grandTotalSum}
                                                        </h5>
                                                    ) : (
                                                        <h5 className="text-primary-dark m-0 font-14">{t('general.button.barter_deal')}</h5>
                                                    )}
                                                </>
                                            ) : (
                                                <h5 className="text-primary mb-0 euro-symbol">
                                                    {grandTotalSum}
                                                </h5>
                                            )}
                                        </div>
                                    </div>
                                }
                                <Button onClick={closeModal} className="mr-3 text-uppercase" variant="outline-secondary"
                                        size="sm">
                                    {t('general.button.close')}
                                </Button>
                                <div className="d-flex">
                                    {((!campaign?.offerPlaceholder?.flags?.canWithdraw && !canCounter) || offerModalStatus === 'negotiate') ? null :
                                        <Dropdown>
                                            <Dropdown.Toggle className="mr-2 btn-outline-primary btn-sm" variant="light"
                                                             id="dropdown-basic">
                                                {t('general.button.respond')}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {isEditable &&
                                                    <Dropdown.Item id="negotiate"
                                                                   onClick={changeStatus}> {t('general.button.negotiate')} </Dropdown.Item>
                                                }
                                                {canCounter &&
                                                    <Dropdown.Item id="decline"
                                                                   onClick={() => setShowDeclineModal(true)}>
                                                        {t('general.button.decline')}
                                                    </Dropdown.Item>
                                                }
                                                {campaign?.offerPlaceholder?.flags?.canWithdraw &&
                                                    <Dropdown.Item id="withdraw" onClick={withdrawOffer}>
                                                        {t('brand.campaign.book.offer.modal.withdraw_offer')}
                                                    </Dropdown.Item>
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>}
                                    <Button onClick={setNextTab} className={`${activeTabModal >= 4 ? 'd-none' : ''}`}
                                            variant="primary" size="sm">
                                        {t('general.button.next')}
                                    </Button>
                                    <Button onSubmit={(e) => openSubmitConfirmModal(e)}
                                            type="submit"
                                            className={`${(campaign?.offers?.length || activeTabModal !== 4) ? 'd-none' : ''}`}
                                            variant={isCharity ? 'primary-dark' : 'primary'} size="sm">
                                        {t('general.button.submit_offer')}
                                    </Button>
                                    <Button type="submit"
                                            className={`${(canCounter && isEditable && activeTabModal === 4 && offerModalStatus === 'negotiate') ? '' : 'd-none'}`}
                                            variant="primary" size="sm">
                                        {t('general.button.submit')}
                                    </Button>
                                    <Button
                                        className={`${(canCounter && activeTabModal === 4 && offerModalStatus === 'preview' && !campaign?.offers[0]?.isRequested) ? '' : 'd-none'} ml-2`}
                                        variant="primary" size="sm"
                                        onClick={() => setShowAcceptModal(true)}
                                    >
                                        {t('general.button.accept')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </form>
                <InfluencerAcceptOfferModal
                    show={showAcceptModal}
                    closeModal={closeAcceptModal}
                    nbPosts={getNumberContentPieces(initialOffer.offerItems)}
                    grandTotal={grandTotalSum}
                    action={acceptOffer}
                />
                <DeclineOfferModal date={formatDate(campaign?.offers[0]?.createdAt ?? '')} declineOffer={declineOffer}
                                   show={showDeclineModal} closeModal={closeDeclineModal}
                                   setDeclineReason={setDeclineReasons}/>
                <ConfirmSendingOfferModal
                    action={campaign?.offers?.length ? sendCounterOfferRequest : sendInitialOfferRequest}
                    grandTotal={grandTotalSum}
                    disableButton={disableButton}
                    show={showSubmitModal}
                    nbPosts={getNumberContentPieces(initialOffer.offerItems)}
                    isInfluencer={true}
                    closeModal={closeSubmitModal}/>
            </Modal>
        </>
    );
}
