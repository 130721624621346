import React from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {IParams} from '../../../../model/shared/IParams';

interface IEditProfileErrorProps {
    id: number;
}
const EditProfileError = ({id}: IEditProfileErrorProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    return (
        <div className="row mb-3">
            <div className="col-12">
                <div className="card border border-danger">
                    <div className="card-body d-flex align-items-center">
                        <img src="/icons/errorProfile.svg" alt="Error profile icon"/>
                        <div className="d-flex align-items-center justify-content-between w-100 ml-2">
                            <div className="d-flex flex-column">
                                    <span className="font-weight-semi-bold">
                                    {t('influencer.dashboard.errorProfile')}
                                </span>
                                <span className="text-muted">
                                    {t('influencer.dashboard.errorProfileDesc')}
                                </span>
                            </div>
                            <Button variant="danger" onClick={() => history.push(`/settings/edit/${id}`)}>
                                {t('general.editProfile')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProfileError;
