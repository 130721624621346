import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {MenuItem, TextField} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import ToggleSwitch from '../../../../shared/components/ToggleSwitch';
import {useHistory} from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import {IRegisterBillingInfos} from '../../brand/dto/IRegister';
import {IRegisterStepperProps} from '../../brand/components/Step1';
import {StepperButtons} from '../../brand/components/StepperButtons';
import {AgencyService} from '../service';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {Form} from 'react-bootstrap';
import {InternalRoutes} from '../../../../enum/InternalRoutes';
import {validateVAT} from '../../../../shared/functions/Functions';

const initialState: IRegisterBillingInfos = {
    userId: 0,
    contact: {
        invoiceFirstName: '',
        invoiceLastName: '',
        companyName: '',
        phone: '',
        street: '',
        city: '',
        zip: '',
        province: '',
        country: '',
        smallBusiness: true,
        uidNumber: '',
        companyRegister: '',
        email: '',
    },
    bank: {
        accountCountry: '',
        accountPhone: '',
        accountBankName: '',
        accountIban: '',
        accountSwift: '',
        accountPaymentType: '',
    },
};

export const Step5 = ({stepIdNumber, handleBackStep}: IRegisterStepperProps) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [currentBillings, setCurrentBillings] = useState<IRegisterBillingInfos>(initialState);
    const {country} = useSelector((states: IAllStates) => states.enums);
    const savedUser = useSelector((state: IAllStates) => state.registration);
    const [vatError, setVatError] = useState(false);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, isBank = false) => {
        const {name, value} = event.target;
        setCurrentBillings(prev => (
            isBank ? {
                ...prev, bank: {
                    ...prev.bank,
                    [name]: value,
                },
            } : {
                ...prev, contact: {
                    ...prev.contact,
                    [name]: value,
                },
            }));
        setVatError(false);

    };

    const changePhoneHandler = (value: string) => {
        setCurrentBillings(prev => ({
            ...prev, bank: {
                ...prev?.bank,
                accountPhone: value,
            },
        }));
        setVatError(false);

    };

    const handleNextStep = () => {
        AgencyService.updateUserBillingInfos(currentBillings).then(response => {
            history.push(`${InternalRoutes.AGENCY_REGISTRATION}/${stepIdNumber + 1}?token=${response?.verificationUUID}`);
            SuccessToast(t('auth.register.brand.step5.success'));
        }).catch(error => ErrorToast(error));
    };

    useEffect(() => {
        if (savedUser?.id) {
            const dataToSave = {
                userId: savedUser?.id,
                contact: {
                    ...savedUser?.agencyCompany?.contact,
                    invoiceFirstName: savedUser?.agencyCompany?.contact?.invoiceFirstName ?? savedUser?.firstName ?? '',
                    invoiceLastName: savedUser?.agencyCompany?.contact?.invoiceLastName ?? savedUser?.lastName ?? '',
                    companyName: savedUser?.agencyCompany?.contact?.companyName ?? savedUser?.agencyCompany?.displayName ?? '',
                    email: savedUser?.agencyCompany?.contact?.email ?? savedUser?.email ?? '',
                },
                bank: {
                    ...savedUser?.agencyCompany?.bank,
                    accountPhone: savedUser?.agencyCompany?.bank?.accountPhone ?? savedUser?.contact?.phone ?? ''
                },
            };
            setCurrentBillings(dataToSave as IRegisterBillingInfos);
        }
        // eslint-disable-next-line
    }, [savedUser?.id]);

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if ((!currentBillings.contact.smallBusiness)
            && !validateVAT(currentBillings.contact.uidNumber as unknown as string)) {
            setVatError(true);
            return;
        }
        handleNextStep();
    }

    return (
        <Form className="w-100" onSubmit={(e) => onSubmit(e)}>
            <div className="row text-left">
                <div className="col-md-6">
                    <h5>{t('auth.register.brand.step4.receiver')}</h5>
                    <TextField
                        label={t('auth.register.brand.step2.firstName')}
                        variant="outlined"
                        name="invoiceFirstName"
                        value={currentBillings?.contact?.invoiceFirstName ?? ''}
                        onChange={changeHandler}
                        size="small"
                        required
                        className="w-100 my-2"
                    />
                    <TextField
                        label={t('auth.register.brand.step2.lastName')}
                        variant="outlined"
                        size="small"
                        value={currentBillings?.contact?.invoiceLastName ?? ''}
                        onChange={changeHandler}
                        name="invoiceLastName"
                        required
                        className="w-100 my-2"
                    />
                    <TextField
                        label={t('auth.register.brand.step4.companyName')}
                        variant="outlined"
                        size="small"
                        required
                        value={currentBillings?.contact?.companyName ?? ''}
                        onChange={changeHandler}
                        name="companyName"
                        className="w-100 my-2"
                    />
                    <PhoneInput
                        country="de"
                        placeholder={t('auth.register.brand.step2.phoneNumber')}
                        value={currentBillings?.bank?.accountPhone ?? ''}
                        onChange={changePhoneHandler}
                        inputClass="w-100 my-2"
                    />
                    <TextField
                        label="E-mail"
                        variant="outlined"
                        value={currentBillings?.contact?.email ?? ''}
                        onChange={changeHandler}
                        size="small"
                        name="email"
                        required
                        type="email"
                        className="w-100 my-2"
                    />
                    <h5 className="mt-3">{t('brand.account.billing_information_modal.billing_details')}</h5>
                    <TextField
                        label={t('auth.register.brand.step4.vatNumber')}
                        variant="outlined"
                        name="uidNumber"
                        value={currentBillings?.contact?.uidNumber ?? ''}
                        required={!currentBillings?.contact?.smallBusiness}
                        onChange={changeHandler}
                        size="small"
                        className="w-100 my-2"
                        error={vatError}
                        helperText={vatError ? 'Incorrect input' : ''}
                    />
                    <div className="d-flex justify-content-between align-items-center">
                        <label className="mb-0" htmlFor="smallBusiness">
                            {t('auth.register.brand.step4.smallBusinessOwner')}
                        </label>
                        <ToggleSwitch onChange={() => setCurrentBillings(prev => ({
                            ...prev, contact: {
                                ...prev.contact,
                                smallBusiness: !prev.contact?.smallBusiness,
                            },
                        }))} small id="smallBusiness" checked={!!currentBillings?.contact?.smallBusiness}/>
                    </div>
                    <span className="text-muted">
                       {t('auth.register.brand.step4.smallBusinessOwnerDescription')}
                    </span>
                </div>
                <div className="col-md-6">
                    <h5>{t('auth.register.brand.step4.address')}</h5>
                    <TextField
                        label={t('auth.register.brand.step4.street')}
                        name="street"
                        variant="outlined"
                        required
                        size="small"
                        value={currentBillings?.contact?.street ?? ''}
                        onChange={changeHandler}
                        className="w-100 my-2"
                    />
                    <TextField
                        label={t('auth.register.brand.step4.city')}
                        name="city"
                        value={currentBillings?.contact?.city ?? ''}
                        onChange={changeHandler}
                        variant="outlined"
                        required
                        size="small"
                        className="w-100 my-2"
                    />
                    <TextField
                        label={t('auth.register.brand.step4.postalCode')}
                        variant="outlined"
                        name="zip"
                        value={currentBillings?.contact?.zip ?? ''}
                        onChange={changeHandler}
                        size="small"
                        required
                        className="w-100 my-2"
                    />
                    <TextField
                        label={t('auth.register.brand.step4.state')}
                        value={currentBillings?.contact?.province ?? ''}
                        onChange={changeHandler}
                        name="province"
                        variant="outlined"
                        required
                        size="small"
                        className="w-100 my-2"
                    />
                    <div className="my-2">
                        <TextField
                            value={currentBillings?.bank?.accountCountry}
                            onChange={e => (changeHandler(e, true))}
                            size="small"
                            name="accountCountry"
                            required
                            label={t('general.address.country')}
                            select
                            variant="outlined"
                            fullWidth
                        >
                            {country.map((countryName) => (
                                <MenuItem key={countryName}
                                          value={countryName}>{t(`general.countries.${countryName}`)}</MenuItem>
                            ))
                            }
                        </TextField>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <StepperButtons currentStep={stepIdNumber - 1} handleNextStep={handleNextStep}
                                    handleBackStep={handleBackStep}/>
                </div>
            </div>
        </Form>
    );
};
