import React from 'react';
import {Helmet} from 'react-helmet';

const BrandMetaTags = () => {
    return (
        <Helmet>
            {/*Title tag*/}
            <title>Register Advertiser | influence.vision</title>
            {/*Meta Description*/}
            <meta name="description"
                  content="Your #1 software with integrated services for successful influencer campaigns. Book, manage, and measure your campaigns all in one place."/>
            {/*Robots Meta Tags*/}
            <meta name="robots" content="index"/>
            <meta name="robots" content="follow"/>
            {/*Responsive Design Meta Tag*/}
            <meta name="viewport" content="width=device-width,initial-scale=1"/>
            {/*Social Media Tags / Open Graph*/}
            <meta property="og:locale" content="{language}"/>
            <meta property="og:type" content="website"/>
            <meta property="og:title" content="Register Advertiser | influence.vision"/>
            <meta property="og:description"
                  content="Your #1 software with integrated services for successful influencer campaigns. Book, manage, and measure your campaigns all in one place."/>
            <meta property="og:url" content="{url}"/>
            <meta property="og:site_name" content="influence.vision"/>
            <meta property="og:image"
                  content="https://app.influencevision.com/tempAssets/registerImages/register1.png"/>
            <meta property="og:image:secure_url"
                  content="https://app.influencevision.com/tempAssets/registerImages/register1.png"/>
            <meta property="og:image:width" content="1200"/>
            <meta property="og:image:height" content="630"/>
            {/*(Optional) Twitter Tags*/}
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content="Register Advertiser | influence.vision"/>
            <meta name="twitter:description"
                  content="Your #1 software with integrated services for successful influencer campaigns. Book, manage, and measure your campaigns all in one place."/>
            <meta name="twitter:site" content="@"/>
            <meta name="twitter:image"
                  content="Your #1 software with integrated services for successful influencer campaigns. Book, manage, and measure your campaigns all in one place."/>
            <meta name="twitter:creator" content="@"/>
            {/*(Optional) Verifcation Tags Optional*/}
            <meta name="msvalidate.01" content=""/>
            <meta name="google-site-verification" content=""/>
            <meta name="yandex-verification" content=""/>
            <meta property="fb:app_id" content="https://www.facebook.com/influence.vision"/>
            {/*(Optional) Canonical Tag in case of identical pages*/}
            <link rel="canonical" href=""/>
            {/*(Optional) Language Tags*/}
            <link rel="alternate" hrefLang="x-default" href=""/>
            <link rel="alternate" hrefLang="en" href=""/>
            <link rel="alternate" hrefLang="de" href=""/>

        </Helmet>
    );
};

export default BrandMetaTags;
