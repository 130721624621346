import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Modal, Row} from 'react-bootstrap';
import {TextField} from '@material-ui/core';
import {IProfileModal} from '../dto/IProfileModal';
import {UserService} from '../service';
import {multipartFormData} from '../../../../utils';
import {AGENCY_COMPANY_URL, PROFILE_COMPANY_URL} from '../../../../utils/endpoints';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';
import {ICompany} from '../dto/ICompany';
import {Bank} from '../../../../model/shared/Bank';
import {Contact} from '../../../../model/shared/Contact';
import {actions} from '../../../../store/authentication/authRedux';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {useTranslation} from 'react-i18next';
import {UploadImage} from '../../../../shared/components/UploadImage';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import {UserRoles} from '../../../../enum/UserRoles';

const initialBank: Bank = {
    accountCountry: '',
    accountPhone: '',
    accountBankName: '',
    accountIban: '',
    accountSwift: '',
    accountPaymentType: '',
};

const initialContact: Contact = {
    country: '',
    phone: '',
};
const initialCompany: ICompany = {
    contact: initialContact,
    bank: initialBank,
};

export function CompanyInfoModal({show, closeModal}: IProfileModal) {

    const [state, setState] = useState<ICompany>(initialCompany);
    const [updateInitialData, setUpdateInitialData] = useState(false);
    const [files, setFiles] = useState<Blob[] | MediaSource[]>();
    const {
        country,
        CompanyIndustryEnum,
        CompanyEmployeesEnum,
        AgencyCreatorsEnum
    } = useSelector((states: IAllStates) => states.enums);
    const {user: {userType}} = useSelector((reduxState: IAllStates) => reduxState.auth);
    const isAgency = [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(userType as UserRoles);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        if (isAgency) {
            show && UserService.getAgencyBilling()
                .then((data) => {
                    setState(data.data);
                    setUpdateInitialData(true);
                });
        } else {
            show && UserService.getCompanyInfo()
                .then((data) => {
                    setState(data.data);
                    setUpdateInitialData(true);
                });
        }
        // eslint-disable-next-line
    }, [show]);

    function updateCompany() {
        multipartFormData(files, PROFILE_COMPANY_URL, JSON.stringify(state))
            .then((res) => {
                dispatch(actions.updateCompany(res.data.data));
                dispatch(actions.updateComletionInfo(res.data.completionInfo));
                SuccessToast(t('general.toasts.successUpdatedCompany'));
                if (closeModal) {
                    closeModal();
                }
            }).catch(error => ErrorToast(error));
    }

    function updateAgencyCompany() {
        multipartFormData(files, AGENCY_COMPANY_URL, JSON.stringify(state))
            .then((res) => {
                dispatch(actions.updateCompany(res.data.data));
                dispatch(actions.updateComletionInfo(res.data.completionInfo));
                SuccessToast(t('general.toasts.successUpdatedCompany'));
                if (closeModal) {
                    closeModal();
                }
            }).catch(error => ErrorToast(error));
    }

    function handleChange(evt: any, isContact = false) {
        const value = evt.target.value?.trim();
        if (isContact) {
            setState({
                ...state,
                contact: {
                    ...state.contact,
                    [evt.target.name]: value,
                },
            });
        } else {
            setState({
                ...state,
                [evt.target.name]: value,
            });
        }
    }

    const changeAutoCompleteHandler = (paramKey: string, paramValue: string | number, type?: string) => {
        if (type === 'country') {
            setState((prev: any) => ({
                ...prev, contact: {
                    [type]: paramValue === '' ? null : paramValue,
                },
            }));
        } else {
            setState((prev: any) => ({...prev, [type as string]: paramValue === '' ? null : paramValue}));
        }
    };

    return (
        <>
            {updateInitialData &&
                <Modal show={show} onHide={closeModal} size="lg">
                    <Modal.Header>
                        <Modal.Title>
                            <h4 className="m-0">{t('brand.account.company_info')}</h4></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col lg="4" className="p-0">
                                <UploadImage saveFile={setFiles} isDeleteHidden={true}
                                             previewImage={state?.profilePhoto?.path}
                                             isMultiple={false} isPreviewArray={false}/>
                                <p className="text-muted mt-3 text-center">{t('brand.account.company_info_modal.img_desc')}</p>
                            </Col>
                            <Col lg="8">
                                <Form.Group className="mt-4 mt-lg-0">
                                    <TextField
                                        onChange={handleChange}
                                        defaultValue={state.displayName}
                                        fullWidth
                                        label={t('brand.account.billing_information_modal.company_name')}
                                        variant="outlined"
                                        size="small"
                                        name="displayName"
                                    />
                                </Form.Group>
                                {!isAgency &&
                                    <Form.Group>
                                        <CustomAutocomplete dataArray={CompanyIndustryEnum ?? []}
                                                            defaultArray={state?.industry as string}
                                                            isWithImage={false} inputLabel="industry"
                                                            isMultiple={false}
                                                            photoLabel=""
                                                            queryFilterKey=""
                                                            inputLabelTranslation={t('auth.register.brand.step4.industry')}
                                                            handleChangeParams={(_, e) => changeAutoCompleteHandler(_, e, 'industry')}/>
                                    </Form.Group>
                                }
                                <Form.Group>
                                    <CustomAutocomplete dataArray={country ?? []}
                                                        defaultArray={state?.contact?.country as string}
                                                        isWithImage={true} inputLabel="country"
                                                        isMultiple={false}
                                                        photoLabel="countryFlag"
                                                        queryFilterKey="country"
                                                        inputLabelTranslation={t('general.address.country')}
                                                        handleChangeParams={(_, e) => changeAutoCompleteHandler(_, e, 'country')}/>

                                </Form.Group>
                                {!isAgency &&
                                    <Form.Group>
                                        <CustomAutocomplete dataArray={CompanyEmployeesEnum ?? []}
                                                            defaultArray={state?.nbEmployees as string}
                                                            isWithImage={false}
                                                            inputLabel="nbEmployees"
                                                            isMultiple={false}
                                                            photoLabel=""
                                                            queryFilterKey=""
                                                            inputLabelTranslation={t('auth.register.brand.step4.employees')}
                                                            handleChangeParams={(_, e) => changeAutoCompleteHandler(_, e, 'nbEmployees')}/>
                                    </Form.Group>
                                }
                                {isAgency &&
                                    <Form.Group>
                                        <CustomAutocomplete dataArray={AgencyCreatorsEnum ?? []}
                                                            defaultArray={state?.nbCreators as string}
                                                            isWithImage={false} inputLabel="nbCreators"
                                                            isMultiple={false}
                                                            photoLabel=""
                                                            isRequired={true}
                                                            queryFilterKey=""
                                                            inputLabelTranslation={t('auth.register.agency.step4.creators')}
                                                            handleChangeParams={(_, e) => changeAutoCompleteHandler(_, e, 'nbCreators')}/>
                                    </Form.Group>
                                }
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={closeModal}>
                            {t('general.button.close')}
                        </Button>
                        <Button variant="primary" onClick={isAgency ? updateAgencyCompany : updateCompany}>
                            {t('general.button.save')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}
