import {FaTrashAlt} from 'react-icons/fa';
import {MdMarkAsUnread} from 'react-icons/md';
import {IMessageContent} from '../../dto/IMessage';
import IconButton from '@mui/material/IconButton';
import {BsThreeDotsVertical} from 'react-icons/bs';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {SetStateAction, useState} from 'react';
import {MessageService} from '../../service';
import {ErrorToast} from '../../../../../../utils/toasters';
import {SideChatBody} from './SideChatBody';

interface ISideChatProps {
    message: IMessageContent;
    selectedChatId: number;
    isDeletedFilter: boolean;
    clickHandler: () => void;
    setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>;
    isInfluencer?: boolean;
}

const options = ['status', 'Delete'];
const ITEM_HEIGHT = 48;

export const SideChat = ({
                             message,
                             isInfluencer = false,
                             clickHandler,
                             selectedChatId,
                             setTriggerUpdate,
                             isDeletedFilter,
                         }: ISideChatProps) => {

    const {messages} = message;
    const lastMessage = messages[messages?.length - 1];
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (id: number) => {
        // console.log(id, 'id')
        return (event: any) => {
            const value = event.target?.innerText?.toLowerCase();
            if (value === 'delete' || value === 'undelete') {
                MessageService.deleteMessageThread(id).then(response => {
                    response === '' && setTriggerUpdate(prev => !prev);
                }).catch(error => ErrorToast(error));
            } else if (value === 'read' || value === 'unread') {
                MessageService.changeMessageReadStatus(id).then(response => {
                    !!response?.length && setTriggerUpdate(prev => !prev);
                }).catch(error => ErrorToast(error));
            }
            setAnchorEl(null);
        };
    };
    return (
        <div className={`user-chat d-flex align-items-center ${selectedChatId === message?.id}`}>
            <div onClick={clickHandler} className="d-flex w-100 align-items-center">
                <SideChatBody message={message}/>
            </div>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <BsThreeDotsVertical className="font-16"/>
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose(message?.id)}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 100,
                    },
                }}
            >
                {options.map((option) => {
                    return (
                        <MenuItem
                            key={option}
                            onClick={handleClose(message?.id)}
                        >
                            {option === 'status' ? <MdMarkAsUnread className="text-muted"/> :
                                <FaTrashAlt className="text-danger"/>}
                            <span
                                className="ml-1">{getCorrectStatus(option, lastMessage?.messageMetadata[0]?.isRead, isDeletedFilter)}</span>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
};

function getCorrectStatus(status: string, currentStatus: boolean, isDeletedFilter: boolean) {
    return status === 'status' ? !currentStatus ? 'Read' : 'Unread' : isDeletedFilter ? 'Undelete' : status;
}
