import React, {useEffect, useState} from 'react';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {MdSend} from 'react-icons/md';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {IBundle} from '../../../../../model/options/IBundle';
import {IParams} from '../../../../../model/shared/IParams';
import {User} from '../../../../../model/user/User';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import MentionInputField from '../../../../../shared/components/message/MentionInputField';
import {MessageBody} from '../../../../../shared/components/message/MessageBody';
import {IAllStates} from '../../../../../store/rootReducer';
import {ErrorToast} from '../../../../../utils/toasters';
import {ManageService} from '../../manage/service';

interface IProps {
    bundle: IBundle;
    lastMessageRef: React.RefObject<HTMLElement>;
    styleCard?: string;
    sendMessageHandler: (...args: any) => void;
    setNewMessage: (...args: any) => void;
    newMessage: string;
    isSendDisabled: boolean;
    canMention?: boolean;
}

const OptionComments = ({
    bundle, 
    lastMessageRef, 
    styleCard, 
    sendMessageHandler, 
    setNewMessage, 
    newMessage, 
    isSendDisabled,
    canMention = true,
}: IProps) => {
    const {t} = useTranslation();
    const {user} = useSelector((state: IAllStates) => state.auth);
    const {id} = useParams<IParams>();
    const [possibleMentions, setPossibleMentions] = useState<User[]>([])

    useEffect(() => {
        !!id && canMention && ManageService.getMentionParticipants(+id, true).then(response => {
            setPossibleMentions(response.data)
        }).catch(err => ErrorToast(err))
    }, [id])

    return (
        <Card className={styleCard}>
            <Card.Body>
                <div className=" border-bottom">
                    <h6>
                        {t('influencer.reporting.comments')}
                    </h6>
                </div>
                <div style={{height: '300px'}} className="overflow-auto pr-2">
                    {!!bundle?.comments?.length ? bundle?.comments?.map((comment) => (
                        <MessageBody comment={comment} key={comment?.id} haveFiles={false}
                                     senderData={comment?.sentBy}
                                     isMyMessage={+comment?.sentBy?.id === +user?.id}/>
                    )) : <p>No comments yet.</p>}
                    <span ref={lastMessageRef}/>
                </div>
                <div className="border-top d-flex align-items-center pb-2 mt-2 pt-2 w-100">
                    <CustomImageHandler
                        altTag="My profile logo"
                        thumbnailPath={user?.profilePhoto?.thumbnail as string}
                        photoPath={user?.profilePhoto?.path as string}
                        classes="user-img rounded-circle"
                    />
                    <div className="w-90 pl-2">
                        <MentionInputField canMention={canMention}
                                            possibleMentions={possibleMentions}
                                           changeMessageHandler={(e) => {
                                               setNewMessage(e.target.value === '\n' ? '' : e.target.value)
                                           }}
                                           sendMessage={sendMessageHandler} value={newMessage}/>
                    </div>
                    <MdSend className={`chat-input-icon ml-2 send ${isSendDisabled ? 'opacity-25' : ''}`}
                            onClick={() => !isSendDisabled && sendMessageHandler()}/>
                </div>
            </Card.Body>
        </Card>
    );
};

export default OptionComments;
