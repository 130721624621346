import {BaseModel} from '../../../../../model/shared/BaseModel';
import {IPicture} from '../../../../../model/shared/IPicture';
import {ICompany} from '../../../../shared/user/dto/ICompany';
import {Company} from "../../../../../model/user/Company";
import {User} from "../../../../../model/user/User";

export interface ICreateCampaign {
    info: IInfo;
    hasSuppliers: boolean;
    hasClients: boolean;
}

export interface ICampaignGoals {
    id: number;
    createdAt?: string;
    updatedAt?: string;
    title: string;
    story: string;
    isActive: boolean;
    goalImage: IPicture;
    isSelected?: boolean;
    summary?: string;
}

export interface IInfo {
    id: number;
    userReviewRating: number;
    company: ICompany;
    title: string;
    url: string;
    publishFrom: string;
    publishTo: string;
    package: string;
    netBudget: number;
    campaignImages: IPicture[];
    campaignGoal: ICampaignGoals;

};

export interface ICreateCampaignRequest {
    title: string;
    campaignSharingType?: string;
    partner?: User;
}

export const createCampaignInitialData: ICreateCampaignRequest = {
    title: '',
    campaignSharingType: 'own_management',
};
