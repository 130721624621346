import {Accordion, AccordionDetails} from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, {useEffect, useState} from 'react';
import {Badge} from 'react-bootstrap';
import {IoMdClose} from 'react-icons/io';
import {MdOutlineExpandMore} from 'react-icons/md';
import {RiInboxFill} from 'react-icons/ri';
import {shallowEqual, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {MainChatInputFields} from '../../../app/brand/campaign/messages/components/chat/MainChatInputFields';
import {
    IMessageContent,
    IMessageOpenedChat,
    IMessageThreadMetadata,
    IMessageThreadQuery,
} from '../../../app/brand/campaign/messages/dto/IMessage';
import {MessageService} from '../../../app/brand/campaign/messages/service';
import {IAllStates} from '../../../store/rootReducer';
import {ErrorToast} from '../../../utils/toasters';
import {truncateString} from '../../functions/Functions';
import {CustomImageHandler} from '../CustomImageHandler';
import {LoadMoreMessages} from './LoadMoreMessages';
import {ThreadTypeEnum} from '../../../store/enums/enumsRedux';

interface IMessageSelectedBubble {
    message: IMessageContent;
    closeOpenedChat: (id: number) => void;
    isMessageBubble?: boolean;
    onChatClick: () => void;
    index: number;
    isUnread?: boolean;
    isExpanded?: boolean;
    setOpenedChats: React.Dispatch<React.SetStateAction<IMessageContent[]>>;

}

export const MessageSelectedBubble = ({
                                          onChatClick,
                                          isUnread,
                                          isExpanded,
                                          message,
                                          closeOpenedChat,
                                          setOpenedChats,
                                          isMessageBubble,
                                          index,
                                      }: IMessageSelectedBubble) => {
    const history = useHistory();
    const [messageData, setMessageData] = useState<IMessageOpenedChat[]>([]);
    const [senderData, setSenderData] = useState<IMessageThreadMetadata[]>([]);
    const [subject, setSubject] = useState('');
    const [filterParams, setFilterParams] = useState<IMessageThreadQuery>({
        page: 1,
        perPage: 15,
    });
    const {user} = useSelector((state: IAllStates) => state.auth, shallowEqual);
    useEffect(() => {

        let pollingInterval: NodeJS.Timeout;

        const fetchMessages = () => {
            if (message?.id && isExpanded) {
                getMessagesPolling(message?.id);
            }
        };

        const startPolling = () => {
            pollingInterval = setInterval(() => {
                fetchMessages();
            }, 5000); // Adjust the interval time (in milliseconds) as needed
        };

        const stopPolling = () => {
            clearInterval(pollingInterval);
        };

        fetchMessages(); // Fetch messages initially

        startPolling(); // Start polling

        return () => {
            stopPolling(); // Clean up by stopping polling when the component unmounts or when selectedChatId changes
        };

        // eslint-disable-next-line
    }, [message?.id, isExpanded]);

    useEffect(() => {
        getInitialMessages(message?.id);
        // eslint-disable-next-line
    }, [filterParams?.page]);

    function getInitialMessages(selectedChatId?: number, isInitial?: boolean) {
        selectedChatId && MessageService.getInboxMessageById({
            ...filterParams,
            id: selectedChatId,
        }).then(response => {
            if (response?.thread) {
                setSubject(response?.thread?.subject)
                setSenderData(response?.thread?.threadMetaData);
                setFilterParams(prev => ({...prev, count: response.count}));
                const concated = isInitial ? response?.data : messageData?.concat(response.data);
                setMessageData(concated);
            } else { // user is removed from chat or chat is deleted
                localStorage.removeItem('openedChats');
                setOpenedChats([]);
            }
        }).catch(error => ErrorToast(error));
    }


    function getMessagesPolling(selectedChatId?: number) {
        MessageService.getInboxMessageById({
            page: 1,
            perPage: 15,
            id: selectedChatId,
        }).then(response => {
            const hasNewMessage = (messageData?.[0] as any)?.id !== response?.data[0]?.id;
            if (hasNewMessage) {
                const myLastMessageIndex = response?.data?.findIndex((message: { id: number }) =>
                    message.id === (messageData?.[0] as any)?.id);
                if (response.data.length === 1) {
                    setMessageData(response.data);
                    setFilterParams(prev => ({...prev, count: (prev?.count ?? 0) + 1}))
                } else {
                    setMessageData((prev) => [...response?.data.slice(0, myLastMessageIndex), ...prev]);
                }
            }
        }).catch(error => ErrorToast(error));
    }

    return (
        <Accordion className={`chat-accordion chat-item-${index} position-absolute`} key={message?.id}
                   expanded={!!isExpanded}
        >
            <AccordionSummary
                expandIcon={<MdOutlineExpandMore className="rotate-180" size={25}/>}
                className="border-bottom position-relative"
                onClick={onChatClick}

                style={{
                    minHeight: 50,
                    height: 50,
                }}
                aria-controls="panel1a-content"
                id={`panel1a-header1-${message?.id}`}
            >
                <div className="d-flex align-items-center justify-content-between ">
                    <div className="d-flex align-items-center">
                        {senderData?.length > 1 ?
                            <CustomImageHandler
                                altTag="My logo"
                                classes="user-img-rounded mr-2 box-shadow-angle"
                                photoPath={'/icons/img-group-chat.svg'}
                            /> :
                            <CustomImageHandler
                                altTag="My logo"
                                classes="user-img-rounded mr-2 box-shadow-angle"
                                thumbnailPath={senderData?.[0]?.participant?.profilePhoto?.thumbnail as string}
                                photoPath={senderData?.[0]?.participant?.profilePhoto?.path as string}
                            />
                        }
                        <span className="font-weight-semi-bold mr-2">
                            {truncateString(subject ?? '-', 20)}
                        </span>

                    </div>
                    {(message?.campaignId || message.type === ThreadTypeEnum.AGENCY_CREATORS) && <div>
                        <RiInboxFill size={15}
                                     className="mr-2"
                                     fill="#6e6e6e"
                                     onClick={() => history.push(user?.userType.includes('brand') ? `/brand/campaign/messages/${message?.campaignId}` : (user?.userType.includes('agency') ? `/agency/messages/${message?.campaignId}` : `/influencer/messages/${message?.campaignId}`),
                                         {threadId: message?.id, isAgencyCreator: message.type === ThreadTypeEnum.AGENCY_CREATORS},
                                     )}
                        />
                    </div>}
                    <IoMdClose size={20} fill="#6e6e6e" onClick={() => closeOpenedChat(message?.id)}/>
                    {isUnread && <div className="new-message-badge">
                        <Badge variant="primary">
                            NEW
                        </Badge>
                    </div>}
                </div>
            </AccordionSummary>
            <AccordionDetails className={'py-0 px-0 d-flex flex-column h-100'}>
                {filterParams?.count ? <div className="px-2 py-3 selected-bubble">
                    <LoadMoreMessages messageData={messageData} filterParams={filterParams}
                                      isMessageBubble={isMessageBubble}
                                      setFilterParams={setFilterParams} keyId={message?.id}/>
                </div> : <span style={{height: 400}}
                               className="d-flex align-items-center justify-content-center font-weight-semi-bold">
                    No new messages!
                </span>}
                <MainChatInputFields key={message?.id} isChatBubble={true} selectedChatId={message?.id}
                                     getMessagesPolling={getMessagesPolling}/>
            </AccordionDetails>
        </Accordion>
    );
};

