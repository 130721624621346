import {Col, Image, Row} from 'react-bootstrap';
import {GiAirplaneDeparture} from 'react-icons/gi';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IOffer} from '../../../../../../../../model/offer/IOffer';
import {CustomImageHandler} from '../../../../../../../../shared/components/CustomImageHandler';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import {Totals} from '../offer/Totals';
import {formatDate, getTotals} from '../../../../../../../../shared/functions/Functions';
import {HistoryAdditionalItemsTable} from './HistoryAdditionalItemsTable';
import {HistoryOfferPlatformTable} from './HistoryOfferPlatformTable';
import {HistoryOfferHeader} from './HistoryOfferHeader';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../../store/rootReducer';
import {TotalTypes} from '../../../../../../../../enum/TotalTypes';

interface INegotiationHistoryDetailProps {
    offer?: IOffer;
}

const HistoryDetail = ({offer}: INegotiationHistoryDetailProps) => {
    const [expended, setExpended] = useState(false);
    const {t} = useTranslation();
    const {
        isTransparentPricing, campaign, isCharity,
    } = useSelector((state: IAllStates) => state.offerBrand);
    const feePercentage = Math.round((1 - Number(campaign?.feePercentage ?? 0.0)) * 100);
    const subTotalSum = getTotals(offer?.additionalOfferItems, offer?.totalPlatforms);
    const grandTotalForDisplay = isTransparentPricing ? offer?.totalWithFee : subTotalSum;
    return (
        <Accordion className="mt-1" expanded={expended}>
            <AccordionSummary className="p-0 border-0" onClick={() => setExpended(!expended)}>
                <Row className="default-radius bg-light py-2 px-4">
                    <Col lg={6} className="d-flex align-items-center px-0">
                        {offer?.counterOfferBy ?
                            <CustomImageHandler
                                altTag={offer?.counterOfferBy?.profilePhoto?.name ?? ''}
                                classes="rounded-img-sm"
                                thumbnailPath={offer?.counterOfferBy.isSupervisor ? '' : offer?.counterOfferBy?.profilePhoto?.thumbnail}
                                photoPath={offer?.counterOfferBy.isSupervisor ? '/icons/logoSmall.svg' : offer?.counterOfferBy?.profilePhoto?.thumbnail ?? ''}
                            /> :
                            <CustomImageHandler
                                altTag={offer?.user?.profilePhoto?.name ?? ''}
                                classes="rounded-img-sm"
                                thumbnailPath={offer?.user?.profilePhoto?.thumbnail}
                                photoPath={offer?.user?.profilePhoto?.thumbnail ?? ''}
                            />
                        }
                        <div>
                            {offer?.counterOfferBy ?
                                <h6 className="ml-3 mb-1">
                                    {offer?.counterOfferBy.isSupervisor ? 'Customer Success by i.v.' : !!offer?.counterOfferBy?.displayName ? offer?.counterOfferBy?.displayName : (offer?.counterOfferBy?.firstName + ' ' + offer?.counterOfferBy?.lastName)}
                                </h6> :
                                <h6 className="ml-3 mb-1">
                                    {!!offer?.user.displayName ? offer?.user.displayName : (offer?.user?.firstName + ' ' + offer?.user?.lastName)}
                                </h6>
                            }
                            <h6 className="ml-3 mb-0">{formatDate(offer?.createdAt ?? '')} - {offer?.createdAt.substring(11, 16)}</h6>
                        </div>
                    </Col>
                    <Col lg={6} className="d-flex align-items-center justify-content-between py-3 px-0 p-lg-0 ">
                        <div>
                            <div className="d-flex align-items-center">
                                <h5 className="mb-0 text-primary euro-symbol">{grandTotalForDisplay}</h5>
                            </div>
                            {!!offer?.additionalOfferItems?.length &&
                                <span className="text-muted font-10">
                                    <GiAirplaneDeparture className="text-primary mr-1 font-14"/>
                                    {t('brand.campaign.book.offer.additional_costs_included')}
                                </span>
                            }
                        </div>
                        {expended ?
                            <div className="text-uppercase text-muted d-flex align-items-center">
                                <p className="mb-0">{t('general.button.hide_details')}</p>
                                <Image className="ml-2" alt="image-up" src="/icons/img_arrow_up.svg"/>
                            </div>
                            :
                            <div className="text-uppercase text-muted d-flex align-items-center">
                                <p className="mb-0">{t('general.button.see_details')}</p>
                                <Image className="ml-2" alt="image-down" src="/icons/img_arrow_down.svg"/>
                            </div>
                        }
                    </Col>
                </Row>
            </AccordionSummary>
            <AccordionDetails className="p-0 ">
                <HistoryOfferHeader personalOffer={offer?.personalOffer} createdAt={offer?.createdAt ?? ''}
                                    userId={offer?.user?.id} counterOfferById={offer?.counterOfferBy?.id}/>
                <HistoryOfferPlatformTable totalPlatforms={offer?.totalPlatforms}/>
                {(offer?.additionalOfferItems?.length !== 0) &&
                    <>
                        <HistoryAdditionalItemsTable additionalItems={offer?.additionalOfferItems}/>
                        <Totals title={TotalTypes.ADDITIONAL_ITEMS}
                                price={getTotals(offer?.additionalOfferItems)}
                                displayPlus={true}
                        />
                    </>
                }
                {(isTransparentPricing && !isCharity) &&
                    <div className="w-100 mt-3">
                        <Totals
                            title={TotalTypes.SUBTOTAL}
                            price={subTotalSum}
                            feePercentage={100 - feePercentage}
                        />
                    </div>
                }
                {(isTransparentPricing) &&
                    <div className="w-100 mt-3">
                        <Totals
                            title={TotalTypes.FEE}
                            price={offer?.feeAmount ?? 0}
                            feePercentage={feePercentage}
                            displayPlus={true}
                        />
                    </div>
                }
                <div className="mt-3">
                    <Totals title={TotalTypes.GRAND}
                            price={grandTotalForDisplay}
                            feePercentage={isTransparentPricing ? 100 : undefined}
                    />
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

export default HistoryDetail;
