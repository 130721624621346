import React, {useState} from 'react';
import {Button, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {BiErrorCircle} from 'react-icons/bi';
import {ConfirmationModal} from '../../../../../../../shared/components/alert/ConfirmationModal';
import {iManageAttachments} from '../../dto/IManageCalendar';
import {iCalendarModalProps} from './CalendarCreateModal';
import {CalendarCreateModalHeader} from './CalendarCreateModalHeader';
import {CalendarModalBody} from './CalendarModalBody';

export const statusesToHideApproveButton = ['approved', 'reported'];

export const CalendarUpdateModal = ({
                                        show,
                                        closeModal,
                                        t,
                                        state,
                                        setState,
                                        onSend,
                                        setSelectedEventId,
                                    }: iCalendarModalProps) => {
    const isFieldDisabled = state?.status === 'proposed';
    const [isConfirmationOpened, setIsConfirmationOpened] = useState(false);
    const [hasTextInsideField, setHasTextInsideField] = useState(false);
    const isAllFilesApproved = (state?.attachments as iManageAttachments[])?.every(file => file.isApproved);
    const hasAttachments = !!(state?.attachments as iManageAttachments[])?.length;
    return (
        <>
            <Modal
                show={show}
                onHide={closeModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="calendar-update-modal "
            >
                <Modal.Header className="pb-md-0">
                    <Modal.Title className="w-100">
                        <CalendarCreateModalHeader state={state} t={t} setSelectedEventId={setSelectedEventId!}/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CalendarModalBody state={state} setState={setState} setHasTextInsideField={setHasTextInsideField}/>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between align-items-end">
                    <Button
                        variant="outline-primary"
                        className="btn-sm"
                        onClick={closeModal}
                    >
                        {t('general.button.close')}
                    </Button>
                    <div>
                        <button
                            disabled={statusesToHideApproveButton?.includes(state?.status) || !isAllFilesApproved || hasTextInsideField || !hasAttachments}
                            className={`btn btn-primary ${isFieldDisabled ? 'invisible' : ''} `}
                            onClick={() => {
                                setIsConfirmationOpened(true);
                                closeModal();
                            }}
                        >
                            {t(statusesToHideApproveButton?.includes(state?.status) ? 'brand.campaign.manage.approved' : 'brand.campaign.manage.modal.approvePosting')}
                        </button>
                        {(hasTextInsideField || (!isAllFilesApproved && !statusesToHideApproveButton?.includes(state?.status)) || !hasAttachments) &&
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="invalid-tooptip">
                                        <div className="d-flex flex-column">
                                            {hasTextInsideField && <span>
                                    {t('general.deleteSend')}
                                    </span>}
                                            {!isAllFilesApproved && <span>
                                        {t('brand.campaign.manage.approveAllFiles')}
                                    </span>}
                                            {!hasAttachments && <span>
                                        {t('brand.campaign.manage.noAttachments')}
                                    </span>}
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <BiErrorCircle size={20} className="text-danger ml-2 cursor-pointer"/>
                            </OverlayTrigger>}
                    </div>
                </Modal.Footer>
            </Modal>
            <ConfirmationModal title={t('brand.campaign.book.offer.modal.are_you_sure')}
                               description={t('brand.campaign.manage.confirmApproving')} show={isConfirmationOpened}
                               closeModal={() => setIsConfirmationOpened(false)} action={onSend}/>
        </>
    );
};
