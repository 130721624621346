import React from 'react';

interface ILightBulbNotifyProps {
    title: string;
    desc?: string;
}

export function LightBulbNotify({title, desc}: ILightBulbNotifyProps) {
    return (
        <>
            <div className="my-3">
                <div className="d-flex align-items-start">
                    <img src="/tempAssets/img-lamp.png" alt="Icon lamp"/>
                    <p className="ml-2 mb-0">{title}</p>
                </div>
                {desc ?
                    <p className="py-2 text-muted font-12">{desc}</p> : null
                }
            </div>
        </>
    )
}
