import React, {useEffect} from 'react';
//@ts-ignore - not supported for TS
import {Fancybox as NativeFancybox} from '@fancyapps/ui/dist/fancybox.esm';


export function Fancybox(props: any) {
    const delegate = props.delegate || '[data-fancybox]';

    useEffect(() => {
        const opts = props.options || {};

        NativeFancybox.bind(delegate, opts);

        return () => {
            // NativeFancybox.destroy(delegate);
        };
        // eslint-disable-next-line
    }, [props.children, props.options]);

    return <>{props.children}</>;

}

