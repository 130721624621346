import React, {SetStateAction, useEffect, useRef, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {MdSend} from 'react-icons/md';
import {useSelector} from 'react-redux';
import {AccessRightEnum} from '../../../../../../../enum/AccessRightEnum';
import {User} from '../../../../../../../model/user/User';
import {CustomImageHandler} from '../../../../../../../shared/components/CustomImageHandler';
import MentionInputField from '../../../../../../../shared/components/message/MentionInputField';
import {hasAccessRights} from '../../../../../../../shared/functions/Functions';
import {useSearchQuery} from '../../../../../../../shared/hooks/useSearchQuery';
import {IAllStates} from '../../../../../../../store/rootReducer';
import {ErrorToast, SuccessToast, WarningToast} from '../../../../../../../utils/toasters';
import {ManageService} from '../../../service';
import {iManageList} from '../../dto/IManageCalendar';
import CalendarManageLeftCard from './CalendarManageLeftCard';
import CalendarManageRightCard from './CalendarManageRightCard';

interface iCalendarModalBodyProps {
    state: iManageList;
    setState: React.Dispatch<SetStateAction<iManageList>>;
    setHasTextInsideField: React.Dispatch<SetStateAction<boolean>>;
}

export const CalendarModalBody = ({state, setState, setHasTextInsideField}: iCalendarModalBodyProps) => {
    const [possibleMentions, setPossibleMentions] = useState<User[]>([])
    const {t} = useTranslation();
    const hasClients = useSearchQuery('hasClients');
    const [currTab, setCurrTab] = useState<number>(0);
    const lastMessageRef = useRef<HTMLInputElement>(null);
    const [isSendDisabled, setIsSendDisabled] = useState(false)
    const textInput = document.getElementById('chat-input') as HTMLInputElement;
    const {user} = useSelector((states: IAllStates) => states.auth);
    const [newMessage, setNewMessage] = useState({
        message: '',
    });
    const showSendInput = !hasAccessRights(AccessRightEnum.INFLUENCER_FEEDBACK_HIDE) || hasAccessRights(AccessRightEnum.MANAGE_CAN_VIEW_CLIENT_COMMENTS);
    const changeHandler = (name: string, value: string) => {
        setNewMessage({...newMessage, [name]: value});
    };

    // useEffect(() => {
    //     setCurrTab(!hasAccessRights(AccessRightEnum.INFLUENCER_FEEDBACK_HIDE) ? 1 : 0);
    // }, []);
    const sendMessageHandler = () => {
        if (!newMessage?.message?.trim()?.length) {
            return WarningToast(t('general.toasts.warnEmptyMessage'));
        }
        setIsSendDisabled(true);
        currTab === 0 ?
            ManageService.createNewComment({
                ...newMessage,
                eventId: state?.id,
                message: newMessage.message,
            }).then(response => {
                setState(response);
                textInput.value = '';
                setNewMessage({message: ''});
            }).catch(error => ErrorToast(error)).finally(() => setIsSendDisabled(false))
            :
            ManageService.createClientComment({
                ...newMessage,
                eventId: state?.id,
                message: newMessage.message,
            }).then(response => {
                setState(response);
                textInput.value = '';
                setNewMessage({message: ''});
            }).catch(error => ErrorToast(error)).finally(() => setIsSendDisabled(false));
    };
    const sendMessagePressingEnter = (
        event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        if (event.key === 'Enter') {
            sendMessageHandler();
        }
    };

    useEffect(() => {
        !!state?.comments?.length && lastMessageRef?.current?.scrollIntoView({
            behavior: 'smooth',
        });
        !!state?.clientComments?.length && lastMessageRef?.current?.scrollIntoView({
            behavior: 'smooth',
        });
    }, [state?.comments?.length, state?.clientComments?.length]);

    useEffect(() => {
        setHasTextInsideField(!!newMessage?.message?.length);
        // eslint-disable-next-line
    }, [newMessage?.message?.length]);

    useEffect(() => {
        !!state?.offerItem?.offer?.campaign?.id && !!state?.id && ManageService.getMentionParticipants(
            state?.offerItem?.offer?.campaign?.id, !!currTab, false, true, state?.id).then(response => {
            setPossibleMentions(response?.data);
        }).catch(err => ErrorToast(err));
    }, [state?.offerItem?.offer?.campaign?.id, state?.id, currTab]);

    const handleCopy = (value: string) => {
        SuccessToast(t('general.button.copied'));
        navigator.clipboard.writeText(value);
    };
    useEffect(() => {
        setCurrTab(!hasAccessRights(AccessRightEnum.INFLUENCER_FEEDBACK_HIDE) ? (Number(hasClients) || 0) : 1)
    }, [hasClients, JSON.stringify(user?.accessRights)])
    return (
        <>
            <Row className="m-0 w-100">
                <Col md={4} className="px-0 px-md-3">
                    <CalendarManageLeftCard state={state} setState={setState} handleCopy={handleCopy}/>
                </Col>
                <Col md={8} className="pl-0 pr-0 pr-md-3">
                    <CalendarManageRightCard state={state} setState={setState} lastMessageRef={lastMessageRef}
                                             handleCopy={handleCopy} currTab={currTab} setCurrTab={setCurrTab}/>
                </Col>
                <Col md={4} className="d-flex align-items-end">
                </Col>
                <Col md={8}>
                    {showSendInput && <div className="border-bottom d-flex align-items-center pb-2 w-100">
                        <CustomImageHandler
                            altTag="User Profile"
                            thumbnailPath={user?.profilePhoto?.thumbnail as string}
                            photoPath={user?.profilePhoto?.path as string}
                            classes="user-img rounded-circle"
                        />
                        <div className="w-90 ml-2">
                            <MentionInputField value={newMessage?.message} possibleMentions={possibleMentions}
                                               id={'chat-input'}
                                               changeMessageHandler={(val) => changeHandler('message', val.target.value)}
                                               sendMessage={sendMessagePressingEnter}/>
                        </div>
                        <MdSend className={`chat-input-icon ml-2 send ${isSendDisabled ? 'opacity-25' : ''}`}
                                onClick={() => !isSendDisabled && sendMessageHandler()}/>
                    </div>}
                </Col>
            </Row>
        </>
    );
};
