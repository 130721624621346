import {createSlice} from '@reduxjs/toolkit';

const initialMessageSlice: IMessageSlice = {
    unreadIDs: [],
}

export interface IMessageSlice {
    unreadIDs: number[];
}

export const messageSlice = createSlice({
    name: 'messageSlice',
    initialState: initialMessageSlice,
    reducers: {
        setUnreadIDs: (state, action) => {
            state.unreadIDs = action.payload;
            return state;
        },
    },
});
