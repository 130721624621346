import {createSlice} from '@reduxjs/toolkit';
import {ICampaignOverview} from '../../model/briefing/overview/ICampaignOverview';
import {IInviteInfluencer} from '../../model/book/Invites';
import {IOfferItemsRequest} from '../../model/offer/IOfferItems';
import {IInfluencerDistribution} from '../../app/influencer/offer/dto/IInfluencerDistribution';
import {WarningToast} from '../../utils/toasters';
import {IBundle} from '../../model/options/IBundle';
import {OfferStatusEnum} from '../../enum/OfferStatusEnum';

export interface IOfferInfluencer {
    activeTabModal: number;
    campaignId: string;
    campaign?: ICampaignOverview;
    isDistributionChecked: boolean;
    isLegalChecked: boolean;
    isStepperFinished: boolean;
    isEditable?: boolean;
    canCounter?: boolean;
    offerModalStatus: string;
    initialOffer: IInviteInfluencer;
    additionalItemId: number;
    distributionArr: IInfluencerDistribution[];
    hasLegal: boolean;
    hasDistribution: boolean;
    requiredFieldChangeColor?: boolean;
    canWriteComment?: boolean;
    isCharity: boolean;
    bundle: IBundle;
    hasUnreadComments: boolean;
}

const initialOfferState: IOfferInfluencer = {
    activeTabModal: 0,
    campaignId: '',
    bundle: {
        comments: [],
        updatedAt: '',
        feedback: '',
        files: [],
        id: 0,
        isEnabled: false,
        sharedAt: null,
        budget: 0,
    },
    canCounter: undefined,
    campaign: undefined,
    distributionArr: [],
    offerModalStatus: 'preview',
    isDistributionChecked: false,
    isLegalChecked: false,
    isStepperFinished: false,
    additionalItemId: 1,
    isEditable: undefined,
    initialOffer: {
        description: '',
        personalOffer: '',
        price: 0,
        offerItems: [],
        distribution: [],
    },
    hasLegal: false,
    hasDistribution: false,
    requiredFieldChangeColor: false,
    canWriteComment: false,
    isCharity: false,
    hasUnreadComments: false,
};

export const offerInfluencer = createSlice({
    name: 'offer',
    initialState: initialOfferState,
    reducers: {
        setActiveTab: (state, action) => {
            const currentStep = action.payload;
            if (!state.isDistributionChecked && currentStep > 2) {
                WarningToast('Please check the required fields');
                return {...state, activeTabModal: 2, requiredFieldChangeColor: true};
            } else if (!state.isLegalChecked && currentStep > 3 && state.hasLegal) {
                WarningToast('Please check the required fields');
                return {...state, activeTabModal: 3, requiredFieldChangeColor: true};
            } else if (!state.isStepperFinished && currentStep > 3) {
                return {
                    ...state,
                    isStepperFinished: true,
                    activeTabModal: 4,
                };
            }
            if (currentStep === 5) {
                return {
                    ...state,
                    requiredFieldChangeColor: false,
                    activeTabModal: action.payload,
                    hasUnreadComments: false
                };
            }
            return {...state, requiredFieldChangeColor: false, activeTabModal: action.payload};
        },
        setOfferModalStatus: (state, action) => {
            return {...state, offerModalStatus: action.payload};
        },
        setOfferModalDetails: (state, action) => {
            const campaign: ICampaignOverview = action.payload;
            const offerItems: IOfferItemsRequest[] = [];
            let additionalItems: IInfluencerDistribution[] = [];
            campaign.offerPlaceholder?.totalPlatforms?.map((platform => (
                platform.offerItems.map((item) => (
                    offerItems.push(
                        {
                            mainPlatformId: platform.id,
                            socialPlatformId: item.socialPlatform.id,
                            nbPosts: item.nbPosts ?? 0,
                            price: item.price,
                            oldPrice: item.oldPrice,
                        })
                ))
            )));
            if (campaign?.offerPlaceholder?.additionalOfferItems?.length) {
                campaign?.offerPlaceholder?.additionalOfferItems.map((item) => additionalItems.push(item));
            }
            if (!campaign.offers.length) {
                additionalItems = [...campaign?.offerPlaceholder?.distribution];
            }
            return {
                ...state,
                campaign: action.payload,
                activeTabModal: action.payload?.activeTabModal ?? (campaign?.offers?.length && 4),
                offerModalStatus: campaign?.offers?.length ? 'preview' : 'negotiate',
                distributionArr: campaign?.offerPlaceholder?.distribution,
                isStepperFinished: !!campaign?.offers?.length,
                isDistributionChecked: !!campaign?.offers?.length,
                isLegalChecked: !!campaign?.offers?.length,
                isEditable: campaign?.offerPlaceholder?.flags?.isEditable,
                canCounter: campaign?.offerPlaceholder?.flags?.canCounter,
                initialOffer: {
                    ...state.initialOffer,
                    offerItems,
                    distribution: additionalItems,
                    description: campaign?.offers[0]?.description ?? '',
                },
                hasLegal: (!campaign.hasExclusivity && !campaign.hasByLaws) ? false : true,
                hasDistribution: !campaign.brandedContent && !campaign.usageRights ? false : true,
                canWriteComment: [OfferStatusEnum.NEGOTIATE, OfferStatusEnum.PENDING].includes(campaign?.offers[0]?.status),
                isCharity: campaign?.isCharity,
                hasUnreadComments: campaign?.offers[0]?.hasUnreadComments,
            };
        },
        setInitialState: (state, action) => {
            return initialOfferState;
        },
        setDistributionItems: (state, action) => {
            const distributionArr = action.payload;
            return {...state, distributionArr};
        },
        setDistributionCheck: (state, action) => {
            return {...state, isDistributionChecked: action.payload};
        },
        setLegalCheck: (state, action) => {
            return {...state, isLegalChecked: action.payload};
        },
        setFinishStepper: (state, action) => {
            return {...state, isStepperFinished: true};
        },
        setOfferItems: (state, action) => {
            const offerItems = action.payload;
            return {...state, initialOffer: {...state.initialOffer, offerItems}};
        },
        setAdditionalItems: (state, action) => {
            const distribution = action.payload;
            return {...state, initialOffer: {...state.initialOffer, distribution}};
        },
        addAdditionalItem: (state, action) => {
            const distributionItem = action.payload;
            const distributionArr: IInfluencerDistribution[] = [...state?.initialOffer?.distribution];
            distributionArr.push({...distributionItem});
            if (distributionItem?.type === 'additional') {
                return {
                    ...state,
                    additionalItemId: state.additionalItemId + 1,
                    initialOffer: {...state.initialOffer, distribution: distributionArr},
                };
            }
            return {...state, initialOffer: {...state.initialOffer, distribution: distributionArr}};
        },
        deleteAdditionItem: (state, action) => {
            const index = action.payload;
            const distributionArr = [...state?.initialOffer?.distribution];
            distributionArr.splice(index, 1);
            return {...state, initialOffer: {...state.initialOffer, distribution: distributionArr}};
        },
        setPersonalOfferDescription: (state, action) => {
            return {...state, initialOffer: {...state.initialOffer, personalOffer: action.payload}};
        },
        setDescription: (state, action) => {
            return {
                ...state,
                initialOffer: {...state.initialOffer, description: action.payload},
                requiredFieldChangeColor: false
            };
        },
        setCampaignId: (state, action) => {
            return {...state, campaignId: action.payload};
        },
        setBundle: (state, action) => {
            return {...state, bundle: action.payload}; //set bundles
        },
    },
});

export const {
    setActiveTab,
    setOfferModalStatus,
    setOfferModalDetails,
    setInitialState,
    setLegalCheck,
    setDistributionCheck,
    setFinishStepper,
    setOfferItems,
    addAdditionalItem,
    deleteAdditionItem,
    setDistributionItems,
    setAdditionalItems,
    setDescription,
    setPersonalOfferDescription,
    setCampaignId,
    setBundle,
} = offerInfluencer.actions;
