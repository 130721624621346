import React from 'react';

interface OverlayProps {
    isOpen: boolean;
    side: string;
    component: JSX.Element;
}

export function SideOverlay({isOpen, side, component}: OverlayProps) {

    return (
        <div className={`side-overlay ${side} ${isOpen ? 'opened' : 'closed'}`}>
            {component}
        </div>
    );
}
