import React, {useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {IoCloseSharp} from 'react-icons/io5';
import {User} from '../../../../../model/user/User';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import {Fancybox} from '../../../../../shared/components/Fancybox';
import {UploadImage} from '../../../../../shared/components/UploadImage';
import {getCorrectImageThumbnail, isBetween} from '../../../../../shared/functions/Functions';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {UserRoles} from '../../../../../enum/UserRoles';
import {InternalRoutes} from '../../../../../enum/InternalRoutes';

interface IProfileHighlightedProps {
    user: User;
    isEditable?: boolean;
    handleUploadFiles?: (files: Blob[] | MediaSource[]) => void;
    handleDeleteFile: (postId: number) => void;
}

const ProfileHighlighted = ({
                                user,
                                isEditable = false,
                                handleUploadFiles,
                                handleDeleteFile,
                            }: IProfileHighlightedProps) => {
    const [files, setFiles] = useState<Blob[] | MediaSource[]>();
    const {user: {userType}} = useSelector((reduxState: IAllStates) => reduxState.auth);
    const isAgency = [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(userType as UserRoles);
    const history = useHistory();
    const {t} = useTranslation();
    return (
        <div className="mb-4">
            <h6>
                {t('influencer.profile.highlightedPosts')}
            </h6>
            <span className="text-muted">
                {t('influencer.profile.highlightedPosts_desc')}
            </span>
            <Row className="mt-2">
                {user?.feedItems?.map((feed) => <Col md={2} key={feed?.id} className="pl-md-0 my-1">
                    <div className="position-relative h-100">
                        {(isEditable && user?.feedItems?.length > 3 && feed.isHighlighted) &&
                            <div className="highlighted-post-cancel scale-size-hover" onClick={() => handleDeleteFile(feed?.id)}>
                                <IoCloseSharp/>
                            </div>
                        }
                        <Fancybox options={{infinite: false, buttons: ["close", "zoom", "download"]}}>
                            <div
                                data-fancybox="gallery-feedItems"
                                className="cursor-pointer w-100"
                                data-src={feed?.postImages?.[0]?.externalLink ?? feed?.postImages?.[0]?.path ?? '/icons/filePlaceholder.png'}
                            >
                                <CustomImageHandler
                                    classes="highlighted-post"
                                    thumbnailPath={feed?.postImages?.[0]?.thumbnail}
                                    placeholder={getCorrectImageThumbnail(!!feed?.postImages?.[0]?.externalLink, feed?.postImages?.[0]?.name ?? '', feed?.postImages?.[0]?.thumbPath ?? '', feed?.postImages?.[0]?.path)}
                                    photoPath={getCorrectImageThumbnail(!!feed?.postImages?.[0]?.externalLink, feed?.postImages?.[0]?.name ?? '', feed?.postImages?.[0]?.thumbPath ?? '', feed?.postImages?.[0]?.path)}
                                    altTag={feed?.platform}/>
                            </div>
                        </Fancybox>

                    </div>
                </Col>)}
                {isEditable && <>
                    <Col xs={12} className="px-md-0 mt-3">
                        <UploadImage numberOfFiles={files?.length} saveFile={setFiles} isDeleteHidden={true}
                                     classes="px-0" isSmall acceptedFormats="video/*, image/jpeg, image/png, image/jpg"
                                     isMultiple maxSize={20971520} />
                        {isBetween(user?.feedItems?.length, 3, 5) &&
                            <p className="text-danger text-end">
                                {t('influencer.profile.maxHighlightedPost')}
                            </p>}
                    </Col>
                    <Col xs={12} className="px-md-0 mt-3 mb-5 d-flex justify-content-end">
                        <Button variant="outline-primary" className="mr-2"
                                onClick={() => history.push(isAgency ? `${InternalRoutes.AGENCY_PROFILE}/${user.id}` : `${InternalRoutes.INFLUENCER_PROFILE}/${user.id}`)}>{t('general.button.cancel')}</Button>
                        <Button variant="primary"
                                onClick={() => {
                                    if (handleUploadFiles) {
                                        handleUploadFiles(files as MediaSource[] | Blob[])
                                        setFiles([]);
                                    }
                                }}>
                            {t('general.button.saveChanges')}
                        </Button>
                    </Col>
                </>}
            </Row>
        </div>
    );
};

export default ProfileHighlighted;
