import React from 'react';
import {getAdditionalItemIcon} from '../../../../shared/functions/Functions';
import {useTranslation} from 'react-i18next';

interface IAdditionalItemProps {
    itemName: string;
}

export function AdditionalItem({itemName}: IAdditionalItemProps) {
    const {t} = useTranslation();
    return (
        <div className="d-flex align-items-center my-1 mr-2">
            {getAdditionalItemIcon(itemName)}
            <p className="d-none d-md-inline-block text-muted mb-0 ml-2 text-nowrap">{t(`influencer.additional_items.${itemName}`)}</p>
        </div>
    );
};