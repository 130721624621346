import React from 'react';
import {ITotalPlatforms} from '../../../../model/platform/ITotalPlatforms';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import Tooltip from '@mui/material/Tooltip';

interface ITotalPlatformsFooterProps {
  totalPlatforms?: ITotalPlatforms[];
}

export function TotalPlatformsFooter({totalPlatforms}: ITotalPlatformsFooterProps) {
  return (
    <>
      <div className="d-flex align-items-center place-bottom-right z-index-1">
        {totalPlatforms?.length ? totalPlatforms?.slice(0, 3)?.map(platform => (
          <div className="mx-1" key={platform?.id}>
            <CustomImageHandler
              photoPath={platform?.path as string}
              altTag={`${platform?.id}`}/>
          </div>
        )) : ''}
        {(totalPlatforms?.length ?? 0) > 3 &&
            <Tooltip id="white-tooltip" title={<>
              {totalPlatforms?.slice(3)?.map(platform => (
                <div className="mx-1 my-2" key={platform?.id}>
                  <CustomImageHandler
                    photoPath={platform?.path as string}
                    altTag={`${platform?.id}`}/>
                </div>
              ))}
            </>
            } placement="right" className="cursor-pointer">
             <span className="icon-round bg-dark font-weight-semi-bold"
                   style={{width: 33}}>
             + {(totalPlatforms?.length ?? 0) - 3}
             </span>
            </Tooltip>
        }
      </div>
    </>
  );
}
