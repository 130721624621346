import React from 'react';
import {Button, Col, Image, Modal, Row} from 'react-bootstrap';
import {TextField} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {getNotificationNumber} from '../../../../shared/functions/Functions';
import {IOfferManagementFilter} from '../dto/IOfferManagementFilter';
import {InternalRoutes} from '../../../../enum/InternalRoutes';
import {BiGift} from 'react-icons/bi';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../store/rootReducer';

interface IFilterModalProps {
    show: boolean;
    closeModal: () => void;
    changeHandler: (event: any) => void;
    statusBarterHandle: () => void;
    offerFilter: IOfferManagementFilter;
    isAgency?: boolean;
}

export function OffersFilterModal({
                                      show,
                                      closeModal,
                                      statusBarterHandle,
                                      changeHandler,
                                      offerFilter,
                                      isAgency,
                                  }: IFilterModalProps) {
    const history = useHistory();
    const {t} = useTranslation();
    const {redDots} = useSelector((state: IAllStates) => state.redDots);
    return (
        <>
            <Modal centered show={show} onHide={closeModal}>
                <Modal.Body>
                    <Row>
                        <Col className="px-0 px-md-3">
                            <div className="d-flex align-items-center mb-3">
                                <Image src="/icons/settings-sliders-gray.svg" alt="filter-icon"/>
                                <h6 className="mb-0 ml-2">{t('influencer.offer_management.filters')}</h6>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <TextField
                                name="title"
                                onChange={changeHandler}
                                id="outlined-basic"
                                label={`${t('general.button.search')}`}
                                variant="outlined"
                                className="w-100 my-2"
                                size="small"
                            />
                        </Col>
                        <Col md={6}>
                            <div
                                onClick={() => statusBarterHandle()}
                                className={`influencer-tab-filter primary-dark my-3 ml-md-3
                    my-md-0 position-relative
                    ${offerFilter?.isCharity ? 'active' : ''}`}
                            >
                                <BiGift size={18}/>
                                <span className="ml-1">{!offerFilter?.isCharity ? t('influencer.explore.barter')
                                    : t('influencer.explore.paid')}</span>
                                {!offerFilter?.isCharity ? !!getNotificationNumber('barterOffers', redDots) &&
                                    <span className="sidebar-bullet">
                    {getNotificationNumber('barterOffers', redDots)}</span> : !!getNotificationNumber('paidOffers', redDots)
                                    && <span className="sidebar-bullet">
                    {getNotificationNumber('paidOffers', redDots)}</span>}
                            </div>

                            {/*<Button*/}
                            {/*    onClick={() => statusHandler()}*/}
                            {/*    name="negotiate"*/}
                            {/*    variant="outline-info"*/}
                            {/*    className={`my-2 w-100 mb-2 ${*/}
                            {/*        offerFilter.status === 'negotiate' && 'active'*/}
                            {/*    }`}*/}
                            {/*>*/}
                            {/*    {getStatusIcon('negotiate', 'text-info mr-2')}*/}
                            {/*    {t('brand.campaign.book.offer.filters.negotiations')}*/}
                            {/*</Button>*/}
                            {/*{newNegotiate &&*/}
                            {/*    <span className="notification-bullet"></span>*/}
                            {/*}*/}
                        </Col>
                        <Col md={6}>
                            <Button
                                onClick={() => history.push(isAgency ? InternalRoutes.AGENCY_DECLINED_OFFER : '/influencer/offer/declined')}
                                name="negotiate"
                                variant="outline-danger"
                                className={`w-100 my-1 px-3 `}
                            >
                                {t('brand.campaign.book.offer.filters.declined')}
                            </Button>
                        </Col>
                        {/*Froze logic for new comment filtering*/}
                        {/*<Col md={6}>*/}
                        {/*    <Button*/}
                        {/*        onClick={() => statusHandler(true)}*/}
                        {/*        name="new_comments"*/}
                        {/*        variant="outline-secondary"*/}
                        {/*        className={`w-100 my-1 ${*/}
                        {/*            offerFilter.newComments && 'active'*/}
                        {/*        }`}*/}
                        {/*    >*/}
                        {/*        <MessagesIcon width="12" height="12" classes="mr-2"/>*/}
                        {/*        {t('influencer.offer_management.new_comments')}*/}
                        {/*    </Button>*/}
                        {/*    {newMessages &&*/}
                        {/*        <span className="notification-bullet"></span>*/}
                        {/*    }*/}
                        {/*</Col>*/}
                        <Col xs={12} className="d-flex justify-content-between mt-5">
                            <Button variant="outline-primary" size="sm" onClick={closeModal}>
                                {t('general.button.cancel')}
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    );
}
