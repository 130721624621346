import FullCalendar from '@fullcalendar/react';
import moment from 'moment';
import React, {SetStateAction, useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {useSearchQuery} from '../../../../shared/hooks/useSearchQuery';
import {plannerInfluencer} from '../../../../store/influencer/plannerSlice';
import {IAllStates} from '../../../../store/rootReducer';
import {CustomCalendarStyle} from '../../../brand/campaign/manage/calendar/components/CustomCalendarStyle';
import {iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {ISelectedYearMonth} from '../index';
import {InfluencerPlannerService} from '../service';
import CreateEventModal, {INewEvent} from './CreateEventModal';
import {CustomCalendarJSX, IDateClick} from './CustomCalendarJSX';
import MobileCalendar from './MobileCalendar';
import PlannerCalendarFilters from './PlannerCalendarFilters';
import PlannerCalendarListView from './PlannerCalendarListView';
import PreviewEventModal from './PreviewEventModal';
import ProposalModal from './ProposalModal';

interface IPlannerCalendarProps {
    setIsCreateModalOpened: React.Dispatch<SetStateAction<boolean>>;
    isCreateModalOpened: boolean;
    setSelectedYearMonth: React.Dispatch<SetStateAction<ISelectedYearMonth>>;
    selectedYearMonth: ISelectedYearMonth;
    selectedCalendarView: 'dayGridMonth' | 'dayGridWeek' | 'listWeek';
    setSelectedCalendarView: React.Dispatch<SetStateAction<'dayGridMonth' | 'dayGridWeek' | 'listWeek'>>;
    isToDoSelected: boolean;
    publishFrom?: string | undefined;
}

interface ILocationProps {
    eventId: number;
    eventStatus: string;
}

const PlannerCalendar = ({
                             isToDoSelected,
                             isCreateModalOpened,
                             setIsCreateModalOpened,
                             setSelectedYearMonth,
                             selectedYearMonth,
                             selectedCalendarView,
                             setSelectedCalendarView,
                             publishFrom,
                         }: IPlannerCalendarProps) => {
    const {state} = useLocation();
    const history = useHistory();
    const queryEventId = useSearchQuery('eventId');
    const dispatch = useDispatch();
    const calendarRef = useRef<FullCalendar>(null);
    const {
        filterParams,
        shouldOpenModal,
        previewEventModal: {isOpenPreviewEventModal, eventId}
    } = useSelector((state: IAllStates) => state.plannerInfluencer);
    const [isEditModalOpened, setIsEditModalOpened] = useState(false);
    const [isProposalOpened, setIsProposalOpened] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState<iManageList>();
    const [createEvent, setCreateEvent] = useState<INewEvent>({
        postingDate: new Date().toISOString(),
        caption: null,
        offerItemId: 0,
    });
    const [selectedMonth, setSelectedMonth] = useState(new Date().getTime());

    const handleCloseModal = () => setIsCreateModalOpened(false);
    const {events} = useSelector((state: IAllStates) => state.plannerInfluencer);
    const handleChangeMonth = (month: string, year: string) => {
        setSelectedYearMonth(prev => ({...prev, currentMonth: month, currentYear: year}));
    };
    const handleDateClick = (event: IDateClick) => {
        // const isEventInPast = moment(event.dateStr).diff(new Date(), 'days') <= -1;
        // if (isEventInPast) {
        //     return WarningToast(t('influencer.planner.noPastEvents'));
        // }
        setIsCreateModalOpened(true);
        setCreateEvent(prev => ({...prev, postingDate: event?.dateStr as string}));
    };
    const handleEventClick = async (event: any) => {
        const eventData = event?.event?._def;
        // if (eventData?.extendedProps?.status === 'feedback_loop') {
        //     WarningToast(t('influencer.planner.notRealEvent'))
        //     return;
        // }
        if (eventData?.extendedProps?.status === 'proposed') {
            setIsProposalOpened(true);
        } else {
            setIsEditModalOpened(true);
        }
        getPlannerDataRequest(eventData?.publicId);
        // const data = await InfluencerPlannerService.getPlannerModalData(eventData?.publicId);
        // dispatch(plannerInfluencer.actions.setFilterParams({
        //     updateCount: (filterParams?.updateCount ?? 0) + 1
        // }));
        // setSelectedEvent({
        //     ...data,
        //     caption: data?.caption?.includes('Sponsored -') ? data?.caption ?? '' : `Sponsored - ${data?.caption ?? ''}`
        // });
    };

    useEffect(() => {
        if (!!(state as ILocationProps)?.eventId) {
            const event = {
                event: {
                    _def: {
                        extendedProps: {
                            status: (state as ILocationProps)?.eventStatus
                        },
                        publicId: (state as ILocationProps)?.eventId,
                    }
                }
            }
            handleEventClick(event)
            history.replace({});
        }
        if (publishFrom) {
            setSelectedMonth(new Date(publishFrom).getTime())
        }
        // eslint-disable-next-line
    }, [state])

    useEffect(() => {
        if (shouldOpenModal) {
            dispatch(plannerInfluencer.actions.setShouldOpenModal(false));
            setIsCreateModalOpened(true);
        }
        // eslint-disable-next-line
    }, [shouldOpenModal]);

    const getPlannerDataRequest = async (idEvent?: number) => {
        const data = await InfluencerPlannerService.getPlannerModalData(String(idEvent));
        dispatch(plannerInfluencer.actions.setFilterParams({
            updateCount: (filterParams?.updateCount ?? 0) + 1
        }));
        setSelectedEvent({
            ...data,
            caption: data?.caption?.includes('Sponsored -') ? data?.caption ?? '' : `Sponsored - ${data?.caption ?? ''}`
        });
    };

    useEffect(() => {
        if (!!queryEventId) {
            setIsEditModalOpened(true);
            history.push('/influencer/planner')
            getPlannerDataRequest(Number(queryEventId));
        }
    }, [queryEventId]);

    useEffect(() => {
        if (isOpenPreviewEventModal) {
            dispatch(plannerInfluencer.actions.setOpenPreviewModal({
                    eventId: undefined,
                    isOpenPreviewEventModal: false,
                },
            ));
            setIsEditModalOpened(true);
            getPlannerDataRequest(eventId);
        }
        // eslint-disable-next-line
    }, [isOpenPreviewEventModal]);

    const CustomCalendar = useCallback(
        () => (<CustomCalendarJSX handleDateClick={handleDateClick} events={events}
                                  handleChangeMonth={handleChangeMonth} selectedMonth={selectedMonth}
                                  handleEventClick={handleEventClick}
                                  setSelectedMonth={setSelectedMonth} calendarRef={calendarRef}
                                  selectedCalendarView={selectedCalendarView}/>),
        // eslint-disable-next-line
        [selectedCalendarView, selectedMonth, events],
    );
    return (
        <div className="position-relative">
            <PreviewEventModal state={selectedEvent as iManageList} isModalOpened={isEditModalOpened}
                               selectedYearMonth={selectedYearMonth}
                               setState={setSelectedEvent as React.Dispatch<SetStateAction<iManageList>>}
                               handleCloseModal={() => setIsEditModalOpened(false)}/>
            <CreateEventModal createEvent={createEvent}
                              setSelectedEvent={setSelectedEvent as React.Dispatch<SetStateAction<iManageList>>}
                              setCreateEvent={setCreateEvent}
                              setIsEditModalOpened={setIsEditModalOpened}
                              handleCloseModal={handleCloseModal}
                              isCreateModalOpened={isCreateModalOpened}/>
            <ProposalModal setState={setSelectedEvent as React.Dispatch<SetStateAction<iManageList>>}
                           state={selectedEvent as iManageList} isModalOpened={isProposalOpened}
                           handleCloseModal={() => setIsProposalOpened(false)}/>
            <PlannerCalendarFilters selectedCalendarView={selectedCalendarView}
                                    setSelectedCalendarView={setSelectedCalendarView}/>
            <CustomCalendarStyle className="mt-3 d-none d-md-block">
                <h5>{moment().month(Number(selectedYearMonth?.currentMonth) - 1).format('MMMM')} - {selectedYearMonth?.currentYear}</h5>
                <div id="influencer-calendar"
                     className={`${selectedCalendarView === 'dayGridWeek' ? 'mixed-calendar' : ''}`}>
                    {(selectedCalendarView === 'dayGridMonth' || selectedCalendarView === 'dayGridWeek') &&
                        <CustomCalendar/>}
                </div>
            </CustomCalendarStyle>
            <div className="d-md-none">
                <MobileCalendar setIsCreateModalOpened={setIsCreateModalOpened}
                                setIsEditModalOpened={setIsEditModalOpened} setIsProposalOpened={setIsProposalOpened}
                                setSelectedEvent={setSelectedEvent}/>
            </div>
            {(selectedCalendarView === 'listWeek' || selectedCalendarView === 'dayGridWeek') &&
                <PlannerCalendarListView isEditModalOpened={isEditModalOpened} isCreateModalOpened={isCreateModalOpened}
                                         setSelectedEvent={setSelectedEvent as React.Dispatch<SetStateAction<iManageList | undefined>>}
                                         setIsEditModalOpened={setIsEditModalOpened}
                                         isToDoSelected={isToDoSelected}
                                         setCreateEvent={setCreateEvent}
                                         setIsProposalOpened={setIsProposalOpened}
                                         setIsCreateModalOpened={setIsCreateModalOpened}/>}
        </div>
    );
};

export default PlannerCalendar;
