import {requests} from '../../utils';
import {BASE_URL, INFLUENCER_PROFILE, PROFILE_INFLUENCER_URL} from '../../utils/endpoints';
import {User} from '../../model/user/User';
import {IProfileData} from './profile/profile-view';

const collaborationUrl = 'collaboration/history';

export const InfluencerService = {
    getInfluencerRevenue: () => requests.get(`${PROFILE_INFLUENCER_URL}/revenue`),
    getInfluencerProfile: (id: string): Promise<IProfileData> => requests.get(`${INFLUENCER_PROFILE}/${id}`),
    updateInfluencerProfile: (id: string, data: User) => requests.put(`${INFLUENCER_PROFILE}/${id}`, data),
    uploadHighlighted: (id: string, data: FormData) => requests.put(`${INFLUENCER_PROFILE}/posts/${id}`, data),
    deleteHighlighted: (postId: number, userId: string) => requests.delete(`${INFLUENCER_PROFILE}/post/${postId}/${userId}`),
    uploadKpiScreenshots: (id: string, data: FormData) => requests.put(`${INFLUENCER_PROFILE}/kpi/screenshots/${id}`, data),
    deleteKpiScreenshot: (postId: number, userId: string) => requests.delete(`${INFLUENCER_PROFILE}/kpi/screenshot/${postId}/${userId}`),
    createCollaborationHistory: (data: FormData) => requests.post(`${INFLUENCER_PROFILE}/${collaborationUrl}`, data),
    updateCollaborationHistory: (data: FormData, collaborationId: number) => requests.put(`${INFLUENCER_PROFILE}/${collaborationUrl}/${collaborationId}`, data),
    deleteCollaborationImage: (id: number) => requests.delete(`${INFLUENCER_PROFILE}/${collaborationUrl}/image/${id}`),
    deleteCollaborationPost: (id: number) => requests.delete(`${INFLUENCER_PROFILE}/${collaborationUrl}/${id}`),
    getPlatformData: (platformId: number) => requests.get(`${BASE_URL}admin/platform/${platformId}`),
};