import React, { useEffect, useRef, ReactNode } from 'react';

const useClickOutside = (callback: () => void) => {
    const ref = useRef<HTMLDivElement>(null);

    const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return ref;
};

interface ClickOutsideDetectorProps {
    onOutsideClick: () => void;
    children: ReactNode;
}

const ClickOutsideDetector: React.FC<ClickOutsideDetectorProps> = ({
                                                                       onOutsideClick,
                                                                       children,
                                                                   }) => {
    const wrapperRef = useClickOutside(onOutsideClick);

    return <div ref={wrapperRef}>{children}</div>;
};

export default ClickOutsideDetector;
