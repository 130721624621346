import React, {useEffect, useState} from 'react';
import {Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import {AuthService} from '../../../../authentication/service';
import {Campaign, IUserNotification} from '../../../../model/campaign/Campaign';
import NotificationItem from '../../../../shared/components/alert/NotificationItem';
import {CustomAutocomplete} from '../../../../shared/components/Autocomplete';
import usePagination from '../../../../shared/hooks/usePagination';
import {ErrorToast} from '../../../../utils/toasters';

const UserNotifications = () => {
    const {t} = useTranslation();
    const [campaignsDropdown, setCampaignsDropdown] = useState<Campaign[]>([])
    const {filterParams, setFilterParams, setCount, count,} =
        usePagination({
            perPage: 50, otherKeys: {
                isDetailed: true
            }
        });
    const [notificationList, setNotificationList] = useState<IUserNotification[]>([])
    useEffect(() => {
        AuthService.getNotifications(filterParams)
            .then(response => {
                setCount(response?.count)
                setNotificationList(response?.data)
            }).catch(err => ErrorToast(err))
    }, [filterParams]);
    useEffect(() => {
        AuthService.getNotificationCampaigns({page: 1, perPage: 0})
            .then(response => {
                setCampaignsDropdown(response?.data)
            }).catch(err => ErrorToast(err))
    }, [])
    return (
        <Card>
            <Card.Body>
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <h5 className={'mb-0'}>{t('brand.account.myNotifications')}</h5>
                    <div className="w-25">
                        <CustomAutocomplete dataArray={campaignsDropdown} isWithImage={false}
                                            handleChangeParams={(_, paramValue) => {
                                                setFilterParams((prev: any) => ({...prev, campaignId: paramValue}))
                                            }} inputLabel={'title'}
                                            inputLabelTranslation={t('influencer.planner.filterByCampaign')}
                                            queryFilterKey={'campaignId'}/>
                    </div>
                </div>

                <InfiniteScroll
                    dataLength={notificationList?.length} //This is important field to render the next data
                    next={() => {
                        setFilterParams((prev: any) => ({...prev, perPage: prev?.perPage + 50}))
                    }}
                    hasMore={notificationList?.length < count}
                    loader={<h4>Loading...</h4>}
                    endMessage={
                        <p className={`text-center ${(notificationList?.length !== count || filterParams?.perPage <= 50) ? 'd-none' : ''}`}>
                            <b>You have seen it all</b>
                        </p>
                    }
                >
                    <div className={'notification-dropdown'}>
                        {!!notificationList?.length ? notificationList.map((notification) => <NotificationItem
                            key={notification?.id} notification={notification} setFilterParams={setFilterParams}
                        />) : <div className={'py-4'}>
                            <div className="text-center">
                                <img src="/icons/empty-notification.svg" alt="Empty notification icon"/>
                                <h6 className="text-muted mb-0 mt-2">No new notifications</h6>
                            </div>
                        </div>}
                    </div>
                </InfiniteScroll>

            </Card.Body>
        </Card>
    );
};

export default UserNotifications;
