import {rootReducer} from './rootReducer';
import {persistStore} from 'redux-persist';
import {configureStore} from '@reduxjs/toolkit';

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: false,
        }),
});

export class StoreKeeper {
    public static store: any;
}


export const persistor = persistStore(store);

StoreKeeper.store = store;
