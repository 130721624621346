import React from 'react';
import {IInfluencerDistribution} from '../../../dto/IInfluencerDistribution';
import {TrashIcon} from '../../../../../../shared/Icons';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../store/rootReducer';
import {Col, FormControl, InputGroup, Row} from 'react-bootstrap';
import {priceSplitter} from '../../../../../../utils/helpers/priceSplitter';
import {countriesTooltip, getCountriesArray} from '../../../../../../shared/functions/Functions';
import {deleteAdditionItem} from '../../../../../../store/influencer/offerInfluencerSlice';

interface IAdditionalItemMobileProps {
  additionalItem: IInfluencerDistribution;
  index: number;
  changeHandler: (event: any, type: string, id: number) => void;
}

export function AdditionalItemMobile({additionalItem, index, changeHandler}: IAdditionalItemMobileProps) {
  const {t} = useTranslation();
  const {offerModalStatus, isCharity} = useSelector((state: IAllStates) => state.offerInfluencer);
  const dispatch = useDispatch();

  const deleteAdditionalItem = (additionalIndex: number) => {
    dispatch(deleteAdditionItem(index));
  };

  return (
    <>
      <div className="d-md-none border-bottom">
        <div className={`d-flex align-items-center justify-content-between ${additionalItem.type === 'additional' ? 'mt-2' : 'my-2'}`}>
          <div className="d-flex align-items-center">
            {offerModalStatus === 'negotiate' ?
              <span className="mb-0" onClick={() => deleteAdditionalItem(index)}>
            <TrashIcon width="16" height="17" classes=" mb-2 cursor-pointer mr-2"/>
          </span> : null}
            <h6 className="mb-1">
              {additionalItem.type === 'additional' ? additionalItem.typeDescription : t(`influencer.offer_management.modal.${additionalItem?.type}`)}
            </h6>
          </div>
            <div>
              {(offerModalStatus === 'negotiate' && !isCharity) ?
                <InputGroup className="mb-0" size="sm" id="price-input">
                  <InputGroup.Text>€</InputGroup.Text>
                  <FormControl
                    min={0}
                    onChange={(e: any) => changeHandler && changeHandler(e, additionalItem.type, additionalItem.id ?? 0)}
                    type="number"
                    defaultValue={Math.round(additionalItem?.price ?? 0)}
                    name="price"
                    placeholder="0"
                    id="price-input"
                    onKeyPress={(event: any) => (event?.key === '-' || event?.key === '+') && event.preventDefault()}
                  />
                </InputGroup>
                :
                <p
                  className={`${(additionalItem?.price > 0) ? 'euro-symbol' : ''}`}>{`${priceSplitter(Math.round(additionalItem?.price))}`}</p>
              }
            </div>
        </div>

        <Row className={`${additionalItem.type === 'additional' ? 'd-none' : ''}`}>
          <Col xs={4}>
            <h6 className="text-muted">{t('influencer.offer_management.modal.details')}:</h6>
          </Col>
          <Col xs={8}>
            <p>{additionalItem?.typeDescription ? additionalItem?.typeDescription : t(`brand.campaign.book.offer.modal.${additionalItem?.type}`)}</p>
          </Col>
        </Row>
        <Row className={`${additionalItem.type === 'additional' ? 'd-none' : ''}`}>
          <Col xs={4}>
            <h6 className="text-muted">{t('brand.campaign.book.offer.modal.duration')}:</h6>
          </Col>
          <Col xs={8}>
            <p>
              {(additionalItem?.duration === '' || !additionalItem?.duration) ? '-' : t(`brand.campaign.briefing.step8.${additionalItem?.duration}`)}
            </p>
          </Col>
        </Row>
        <Row className={`${additionalItem.type === 'additional' ? 'd-none' : ''}`}>
          <Col xs={4}>
            <h6 className="text-muted">{t('brand.campaign.book.offer.modal.location')}:</h6>
          </Col>
          <Col xs={8}>
            {getCountriesArray(additionalItem?.location, t)}
            {additionalItem?.location?.length > 1 ? countriesTooltip(additionalItem.location?.slice(1), t, 'top') : ''}
          </Col>
        </Row>
      </div>
    </>
  );
};
