import React from 'react';
import {Card} from 'react-bootstrap';

interface IStatisticCard {
    number: string | number;
    text: any;
    classes?: string;
}

export function StatisticCard({number, text, classes}: IStatisticCard) {
    return (
        <Card className={`${classes}`}>
            <Card.Body className="text-center p-3">
                <h6 className="mb-1">{number}</h6>
                <span className="text-primary">
                    {text}
                </span>
            </Card.Body>
        </Card>
    );
}
