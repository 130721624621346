import {Button, Spinner} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {updateRegistrationStep} from '../../../../shared/functions/Functions';
import {useSearchQuery} from '../../../../shared/hooks/useSearchQuery';
import {IRegisterStepProps} from '../dto/IRegister';

export const StepperButtons = ({
                                   handleNextStep,
                                   currentStep,
                                   handleBackStep,
                                   isLoading,
                                   isDisabled = false,
                                   isBackHidden,
                               }: IRegisterStepProps) => {
    const token = useSearchQuery('token') as string;
    const {t} = useTranslation();
    return (
        <div
            className={`d-flex align-items-center justify-content-${!!currentStep && !isBackHidden ? 'between' : 'end'} `}>
            {!!currentStep && !isBackHidden &&
                <Button variant="outline-primary" onClick={handleBackStep}>{(t('general.button.back'))}</Button>}
            <button type="submit" className="btn btn-primary d-flex align-items-center"
                    disabled={isLoading || isDisabled}
                    onClick={async () => {
                        if (currentStep > 0) {
                            await updateRegistrationStep(currentStep + 1, token);
                        }
                        // handleNextStep(currentStep);
                    }}>
                {isLoading ? 'Loading...' : 'Next'}
                {isLoading && <Spinner animation="border" size="sm" role="status" className="ml-1"/>}
            </button>
        </div>
    );
};
