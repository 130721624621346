import {Component, ErrorInfo} from 'react';
import {ErrorScreen} from './ErrorScreen';

interface PropsState {
    hasError: boolean;
    errorMessage: string;
}

class ErrorBoundary extends Component {
    constructor(props: PropsState) {
        super(props);
        this.state = {
            hasError: false,
            errorMessage: ''
        };
    }


    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return {
            hasError: true,
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({hasError: true, errorMessage: error.message})
    }


    render() {
        if ((this.state as PropsState).hasError) {
            return <ErrorScreen errorMessage={(this.state as PropsState)?.errorMessage}/>;
        }
        return this.props.children;
    }
}


export {ErrorBoundary};