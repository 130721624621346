import React from 'react';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {IMessageOpenedChat} from '../../../app/brand/campaign/messages/dto/IMessage';
import {IPicture} from '../../../model/shared/IPicture';
import {IAllStates} from '../../../store/rootReducer';
import {linkify} from '../../functions/Functions';
import {CustomImageHandler} from '../CustomImageHandler';
import {FileMessage} from './FileMessage';
import DOMPurify from 'dompurify';

interface IMessageBody {
    comment: IMessageOpenedChat;
    isMyMessage: boolean;
    haveFiles?: boolean;
    isMessageBubble?: boolean;
    senderData?: any;
    files?: IPicture[];
    isOfferComments?: boolean;
}


export function MessageBody({
                                comment,
                                isMyMessage,
                                haveFiles = false,
                                senderData,
                                files,
                                isMessageBubble = false,
                                isOfferComments,
                            }: IMessageBody) {
    const {body, createdAt} = comment;
    const {id: userId} = useSelector((state: IAllStates) => state.auth.user);
    const renderMessage = (message: string) => {
        const regex = /@\[([^\]]+)\]\((\d+)\)/g;
        return message.replace(regex, (match, username, id) => {
            if (Number(id) === userId) {
                return `<span class="message-tagged-user my-msg">@${username}</span>`;
            } else {
                return `<span class="message-tagged-user">@${username}</span>`;
            }
        });
    };

    const sanitizedHTML = DOMPurify.sanitize(linkify(body ? renderMessage(body) : renderMessage(comment?.message || '')), {
        ADD_ATTR: ['target'], // Allow 'target' attribute
    });

    return (
        <div className={`mt-3 pb-3 d-flex align-items-start`}>
            {!isMyMessage &&
                <CustomImageHandler
                    altTag="User Profile"
                    classes="small-rounded-logo mr-3"
                    thumbnailPath={senderData?.profilePhoto?.thumbnail}
                    photoPath={senderData?.profilePhoto?.thumbnail}
                />
            }
            <div
                className={`d-flex justify-content-${isMyMessage ? 'end align-items-end' : 'start '} w-100 flex-column`}>
                <div className={`text-muted font-weight-normal font-10 ${isMyMessage ? 'mb-2' : ''}`}>
                    {senderData?.firstName ?? ''} {senderData?.lastName ?? ''} - {moment(createdAt)?.format('DD.MM.YYYY. HH:mm')}
                </div>
                <div className={`chat-sent-message ${isMyMessage}`}>
                    <div className="row w-100">
                        {haveFiles &&
                            <FileMessage messageId={comment?.id} files={files} isMessageBubble={isMessageBubble} isOfferComment={isOfferComments}/>}
                        <div className="px-0 col-12 lh-normal">
                            <span
                                className="word-break-word lh-1-5"
                                dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
