import React, {useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {InfluencerReportingService} from '../../service';
import {ErrorToast, SuccessToast} from '../../../../../utils/toasters';

interface IFeedbackModal {
    isModalOpened: boolean;
    handleCloseModal: () => void;
    id: number;
}

const FeedbackModal = ({isModalOpened, handleCloseModal, id}: IFeedbackModal) => {
    const {t} = useTranslation();
    const [feedback, setFeedback] = useState({
        reply: '',
    });
    const changeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = event.target;
        setFeedback(prev => ({...prev, [name]: value}));
    }

    const submitHandler = () => {
        InfluencerReportingService.addReview(feedback, id).then(response => {
            if (response) {
                SuccessToast(t('influencer.reporting.successReview'));
                handleCloseModal();
            }
        }).catch(error => ErrorToast(error));
    };
    return (
        <Modal
            show={isModalOpened}
            centered
            onHide={handleCloseModal}
            keyboard={false}
        >
            <Modal.Body>
                <div className="border-bottom mb-3">
                    <h5>
                        {t('influencer.reporting.feedback')}
                    </h5>
                </div>
                <Form.Control
                    as="textarea"
                    name="reply"
                    rows={10}
                    onChange={changeHandler}
                    className="w-100 mb-0 h-100"
                    placeholder={t('influencer.reporting.provideFeedback')}/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={handleCloseModal}>{t('general.button.cancel')}</Button>
                <Button variant="primary" onClick={submitHandler}>{t('general.button.save')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default FeedbackModal;
