import {Country} from '../shared/Country';

export interface UsageRights {
    id: number;
    oneMonthPrice: number;
    threeMonthsPrice: number;
    sixMonthsPrice: number;
    twelveMonthsPrice: number;
}

export interface IUsageRightsRequest {
    id: number;
    mediaType: string;
    duration: string;
    description: string;
    countriesArray: string[];
    countries?: Country[];

}

export const initialUsageRightsData = {
    countriesArray: [],
    description: '',
    duration: '',
    id: 0,
    mediaType: '',
};
