import React from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {User} from '../../../../model/user/User';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {formatDate, getSocialIcons} from '../../../../shared/functions/Functions';
import Flag from 'react-world-flags';
import {useHistory} from 'react-router-dom';
import {InternalRoutes} from '../../../../enum/InternalRoutes';

interface IProps {
    user: User;
    onRemoveClick: (id: number) => void;
}

const ContentCreatorCard: React.FC<IProps> = ({user, onRemoveClick}) => {
    const {t} = useTranslation();
    const {displayName, firstName, profilePhoto, lastName, totalPlatforms, id, userType, email, createdAt} = user || {};
    const history = useHistory();
    const onEditClick = async (userId: number) => {
        history.push(`${InternalRoutes.INFLUENCER_PROFILE_EDIT}/${userId}`);
    };
    return (
        <div className="col-md-4 mb-4 pl-0">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-4 pl-0">
                            <CustomImageHandler photoPath={profilePhoto?.path} classes="user-img-rounded w-100"
                                                altTag="user profile picture"/>
                        </div>
                        {userType ?
                            <div className="col-8 pl-0">
                                <h5>{!!displayName ? displayName : (firstName + ' ' + lastName)}</h5>
                                <div className="d-flex align-items-center flex-wrap">
                                    {!!totalPlatforms?.length ? totalPlatforms?.map(platform => {
                                        return (getSocialIcons({
                                                iconPath: platform.path,
                                                classes: 'platform-icon-size mr-2 mb-1',
                                            })

                                        )
                                    }) : userType && <span className="text-muted">n.a.</span>}
                                </div>
                                <div className="country-flag place-top-right">
                                    <Flag code={user?.contact.country}/>
                                </div>

                            </div> :
                            <div className="col-8 pl-0 d-flex flex-column justify-content-center">
                                <div>
                                    <h6>{email}</h6>
                                    <div className="d-flex">
                                        <p className="mb-0 mr-3">{formatDate(createdAt)}</p>
                                        <p className="status negotiate mb-0">{t('general.campaign_status.pending')}</p>
                                    </div>
                                </div>
                            </div>
                        }
                            <div className={`col-12 pl-0 justify-content-end d-flex align-items-center ${!userType && 'invisible'}`}>
                                <Button variant="outline-secondary" className="text-uppercase"
                                        onClick={() => onRemoveClick(id)}>
                                    {t('general.button.remove')}
                                </Button>
                                <Button variant="outline-primary" className="ml-2" onClick={() => onEditClick(id)}>
                                    {t('general.button.edit')}
                                </Button>
                            </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ContentCreatorCard;
