import React, {useEffect, useState} from 'react';
import {Card} from 'react-bootstrap';
import {clearHtmlFromString, getFirstPreviewImage} from '../../../../../shared/functions/Functions';
import {AdditionalItem} from '../../../../influencer/offer/components/AdditionalItem';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import {User} from '../../../../../model/user/User';
import {ReplaceInfluencerIcon} from '../../../../../shared/Icons';
import {AgencyService} from '../../../service';
import {CustomAutocomplete} from '../../../../../shared/components/Autocomplete';
import {ErrorToast} from '../../../../../utils/toasters';
import {setActiveTab, setInitialState, setSelectMatchedUser} from '../../../../../store/agency/agencyOfferSlice';
import usePagination from '../../../../../shared/hooks/usePagination';

interface IAgencyOfferModalLeftSideProps {
    campaignId: string;
}

export function AgencyOfferModalLeftSide({campaignId}: IAgencyOfferModalLeftSideProps) {
    const {t} = useTranslation();
    const {campaign, selectedMatchedUser} = useSelector((state: IAllStates) => state.offerAgency);
    const dispatch = useDispatch();
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const [matchedUsers, setMatchedUsers] = useState<User[]>([]);
    const {filterParams, setFilterParams} = usePagination({});

    useEffect(() => {
        AgencyService.getMatchedInfluencers(campaignId, filterParams)
            .then((response) => {
                setMatchedUsers(response.data);
            }).catch((err) => ErrorToast(err));
    }, [filterParams]);

    async function handleUsers(paramKey: string, paramValue: string | number) {
        const selectedUser = await matchedUsers.find((item) => item.id === +paramValue);
        await dispatch(setInitialState({}));
        await dispatch(setSelectMatchedUser(selectedUser));
        setIsSearchOpen(false);
        setFilterParams((prev: any) => ({...prev, displayName: ''}));
    }

    const goToContentTab = () => {
        dispatch(setActiveTab('1'));
    };

    return (
        <>
            <Card className="d-none d-xl-flex">
                <Card.Body className="d-flex flex-column p-0">
                    <div className="text-center">
                        {isSearchOpen ?
                            <div className="d-flex align-items-center justify-content-center m-2">
                                <CustomAutocomplete dataArray={matchedUsers} isWithImage={true} isMultiple={false}
                                                    inputLabel="displayName"
                                                    changeEventKey="displayName"
                                                    setFilterState={setFilterParams}
                                                    queryFilterKey="assignedUserId" photoLabel="profilePhoto.path"
                                                    selectedStateImage={selectedMatchedUser?.profilePhoto?.path as string}
                                                    key={selectedMatchedUser?.id}
                                    // defaultArray={matchedUsers?.find(user => Number(user.id) === +selectedMatchedUser?.id) as unknown as []}
                                                    inputLabelTranslation={t('agency.filter.matched_users')}
                                                    handleChangeParams={handleUsers}/>
                            </div>
                            :
                            <div className="w-100 bg-primary p-2 default-top-radius mb-2">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="mb-0 text-white">Acting as: {!!selectedMatchedUser?.displayName ? selectedMatchedUser?.displayName : (selectedMatchedUser?.firstName + ' ' + selectedMatchedUser?.lastName)}</p>
                                    <ReplaceInfluencerIcon width="33"
                                                           height="22"
                                                           color="white"
                                                           onClick={() => setIsSearchOpen(prevState => !prevState)}
                                                           classes="cursor-pointer"/>
                                </div>
                            </div>
                        }
                        <CustomImageHandler photoPath={selectedMatchedUser?.profilePhoto?.thumbnail ?? ''}
                                            thumbnailPath={selectedMatchedUser?.profilePhoto?.thumbnail ?? ''}
                                            altTag="User Profile"
                                            classes="agency-offer-profile-img"/>
                        <h5 className="mt-1">{!!selectedMatchedUser?.displayName ? selectedMatchedUser?.displayName : (selectedMatchedUser?.firstName + ' ' + selectedMatchedUser?.lastName)}</h5>
                    </div>
                    <div className="p-3">
                        {getFirstPreviewImage('style_uploaded_image', campaign?.campaignImages, 'default-radius img-cover moonboard-images')}
                        {campaign?.offers[0]?.status &&
                            <p className={`place-top-right status ${campaign?.offers[0]?.status} d-md-none mr-3`}>
                                {campaign?.offers[0]?.status}
                            </p>
                        }
                        <h5 className="my-3">
                            {campaign?.isCharity && (
                                <>
                                    <div className="d-inline-block">(</div>
                                    <div
                                        className="text-primary-dark d-inline-block">{t('general.button.barter_deal')}</div>
                                    <div className="d-inline-block">)</div>
                                </>
                            )} {campaign?.title}
                        </h5>
                        <h6>{t('influencer.explore.contentBriefing')}</h6>
                        <div className="five-line-text">
                            {clearHtmlFromString(campaign?.adDescription ?? '-')}
                        </div>
                        <div className="d-flex justify-content-end">
                            <a className="text-muted cursor-pointer" href="#1"
                               onClick={goToContentTab}>{t('general.read_more')}</a>
                        </div>
                        <div className="d-flex flex-wrap">
                            {campaign?.hasByLaws && <AdditionalItem itemName="bylaws"/>}
                            {!!campaign?.brandedContent && <AdditionalItem itemName="branded_content"/>}
                            {!!campaign?.usageRights && <AdditionalItem itemName="usage_right"/>}
                            {campaign?.hasExclusivity && <AdditionalItem itemName="exclusivity"/>}
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <div
                className="d-xl-none mr-1 d-flex align-items-center justify-content-between mb-3 pb-2 border-bottom-primary">
                {isSearchOpen ?
                    <div className="d-flex align-items-center justify-content-center m-2 w-100">
                        <CustomAutocomplete dataArray={matchedUsers} isWithImage={true} isMultiple={false}
                                            inputLabel="firstName"
                                            queryFilterKey="assignedUserId" photoLabel="profilePhoto.path"
                                            selectedStateImage={selectedMatchedUser?.profilePhoto?.path as string}
                                            key={selectedMatchedUser?.id}
                            // defaultArray={matchedUsers?.find(user => Number(user.id) === +selectedMatchedUser?.id) as unknown as []}
                                            inputLabelTranslation={t('agency.filter.matched_users')}
                                            handleChangeParams={handleUsers}/>
                    </div> :
                    <>
                        <div className="d-flex align-items-center">
                            <CustomImageHandler photoPath={selectedMatchedUser?.profilePhoto?.path ?? ''}
                                                altTag="User Profile"
                                                classes="agency-offer-profile-img"/>
                            <h6 className="mb-0 ml-2">{selectedMatchedUser?.firstName} {selectedMatchedUser?.lastName}</h6>
                        </div>
                        <ReplaceInfluencerIcon width="33"
                                               height="22"
                                               onClick={() => setIsSearchOpen(prevState => !prevState)}
                                               classes="cursor-pointer"/>
                    </>

                }
            </div>
            <div className="d-xl-none mr-1 d-flex align-items-center">
                <div>
                    {getFirstPreviewImage('style_uploaded_image', campaign?.campaignImages, 'campaign-icon')}
                </div>
                <h5 className="mb-0 ml-2">
                    {campaign?.isCharity && (
                        <>
                            <div className="d-inline-block">(</div>
                            <div className="text-primary-dark d-inline-block">{t('general.button.barter_deal')}</div>
                            <div className="d-inline-block">)</div>
                        </>
                    )} {campaign?.title}
                </h5>
            </div>
        </>
    );
}
