import AlertBox from './AlertBox';
import React from 'react';
import {AuthService} from '../../../authentication/service';
import {ErrorToast} from '../../../utils/toasters';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../store/rootReducer';
import {actions} from '../../../store/authentication/authRedux';

export function TermsOfServiceAlert() {
    const {t} = useTranslation();
    const {user: {newTermsNotification}} = useSelector((state: IAllStates) => state.auth);
    const dispatch = useDispatch();
    const updateTermsNotify = () => {
        AuthService.updateTermsNotify()
            .then(() => {
                dispatch(actions.updateNewTermsService(false));
            })
            .catch(err => ErrorToast(err));
    };

    return(
        <>
            {newTermsNotification &&
            <AlertBox image="/icons/img-warning.svg"
                      hasButton
                      classCol="col-12 mb-4"
                      variant="secondary"
                      title={t('influencer.dashboard.terms_title')}
                      desc={t('influencer.dashboard.terms_description')}
                      textButton={t('general.button.close')}
                      functionBtn={updateTermsNotify}
            />
            }
        </>
    );
}
