import React, {useEffect, useState} from 'react';
import {Button, Col, Modal, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {TextField} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import {InternalRoutes} from '../../../../../enum/InternalRoutes';
import {BriefingService} from '../service';
import {ErrorToast, SuccessToast} from '../../../../../utils/toasters';
import {createCampaignInitialData, ICreateCampaign, ICreateCampaignRequest} from '../dto/ICreateCampaign';
import {multipartFormData} from '../../../../../utils';
import {BRIEFING_URL_MODAL} from '../../../../../utils/endpoints';
import {FormControl, FormControlLabel, Radio, RadioGroup} from '@mui/material';
import {UploadImage} from '../../../../../shared/components/UploadImage';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {actions} from '../../../../../store/authentication/authRedux';
import {ClientService, IClientParams} from '../../../client/service';
import {Company} from '../../../../../model/user/Company';
import {CustomAutocomplete} from '../../../../../shared/components/Autocomplete';
import {UserRoles} from '../../../../../enum/UserRoles';
import {getSharingTypeIcon} from '../../../../../shared/functions/Functions';
import {CampaignSharingType} from '../../../../../enum/CampaignSharingTypeEnum';
import {PackagesEnum} from "../../../../../enum/PackageEnum";

interface ICampaignModalProps {
    show: boolean;
    closeModal: () => void;
    isEdit?: boolean;
    campaignId?: number;
}

export function CampaignCreateModal({show, closeModal, isEdit, campaignId}: ICampaignModalProps) {
    const {t} = useTranslation();
    const history = useHistory();
    const [filterParams, setFilterParams] = useState<IClientParams>({
        page: 1,
        perPage: 10,
        type: 'supplier',
        campaignId: String(campaignId ?? 0),
        displayName: '',
    });
    const [companies, setCompanies] = useState<Company[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<Company>();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [campaignData, setCampaignData] = useState<ICreateCampaign>();
    const [createCampaign, setCreateCampaign] = useState<ICreateCampaignRequest>(createCampaignInitialData);
    const [files, setFiles] = useState<Blob[] | MediaSource[] | undefined>();
    const {user: {favoriteCampaigns, company}} = useSelector((state: IAllStates) => state.auth);
    const {CampaignSharingTypesEnum} = useSelector((state: IAllStates) => state.enums);
    const isBasic = company?.package?.name === PackagesEnum.BASIC;
    const dispatch = useDispatch();
    const isManagementHidden = !campaignData?.hasSuppliers && !campaignData?.hasClients
    useEffect(() => {
        show && BriefingService.getBriefingModalData(isEdit ? campaignId : 0)
            .then((data) => {
                setCampaignData(data);
                if (isEdit) {
                    setCreateCampaign({
                        ...createCampaign,
                        title: data?.info?.title,
                        campaignSharingType: undefined,
                    });
                }
            })
            .catch(err => ErrorToast(err));
        return () => {
            setCreateCampaign(createCampaignInitialData);
            setCampaignData(undefined);
            setFiles(undefined);
        };
        // eslint-disable-next-line
    }, [show]);

    useEffect(() => {
        show && ClientService.getClientsOrSuppliers(filterParams)
            .then((response) => {
                setCompanies(response.data);
            });
        return () => setCompanies([]);
    }, [JSON.stringify(filterParams), show]);

    const startCampaignBriefing = (id: number) => {
        history.push(`${InternalRoutes.BRAND_CAMPAIGN_CREATE}/${id}/step1`);
    };
    const editCampaign = () => {
        setIsSubmitted(true);
        if (!(!!createCampaign?.title)) {
            return;
        }
        multipartFormData(files, `${BRIEFING_URL_MODAL}/${campaignId}`, JSON.stringify(createCampaign))
            .then((res) => {
                SuccessToast(t('general.toasts.successEditCampaign'));
                if (closeModal) {
                    const avoidMutation = JSON.parse(JSON.stringify((favoriteCampaigns)));
                    const copy = [...avoidMutation ?? []];
                    const index = copy?.findIndex((campaign) => campaign.id === res.data.id);
                    if (index >= 0) {
                        copy[index].campaignImages = res?.data?.campaignImages;
                        copy[index].title = res?.data?.title;
                        dispatch(actions.updateFavoriteCampaign(copy));
                    }
                    closeModal();
                }
                setIsSubmitted(false);
                setIsLoading(false);
            }).catch(error => {
            ErrorToast(error);
            setIsLoading(false);
        });
    };

    const createNewCampaign = () => {
        setIsSubmitted(true);
        if (!(!!createCampaign?.title) || !files) {
            return;
        }
        setIsLoading(true);
        multipartFormData(files, BRIEFING_URL_MODAL, JSON.stringify(createCampaign), 'post')
            .then((res) => {
                SuccessToast(t('general.toasts.successCreatedCampaign'));
                if (closeModal) {
                    closeModal();
                }
                setIsSubmitted(false);
                setIsLoading(false);
                setCreateCampaign(createCampaignInitialData);
                setFiles(undefined);
                const {data} = res;
                startCampaignBriefing(data?.id);
            }).catch(error => {
            ErrorToast(error);
            setIsLoading(false);
        });
    };

    const changeHandler = (paramKey: string, paramValue: number | string) => {
        const selectedItem = companies?.find(company => company.id === Number(paramValue));
        setCreateCampaign({
            ...createCampaign,
            partner: selectedItem?.companyUsers?.find((item) => item.userType === UserRoles.BRAND_MASTER),
        });
        setSelectedCompany(selectedItem);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setCreateCampaign({
            ...createCampaign,
            campaignSharingType: value,
            partner: undefined,
        });
        if (value !== CampaignSharingType.OWN_MANAGEMENT) {
            setFilterParams({...filterParams, type: value === 'client' ? 'supplier' : 'client'});
        }
    };
    const functionCheckShareType = (type: string) => {
        if (type === CampaignSharingType.SUPPLIER && campaignData?.hasSuppliers && !isBasic) {
            return false;
        } else if (type === CampaignSharingType.CLIENT && campaignData?.hasClients && !isBasic) {
            return false;
        } else if (type === CampaignSharingType.OWN_MANAGEMENT) {
            return false;
        }
        return true;
    };
    return (
        <div onClick={e => e.stopPropagation()}>
            <Modal show={show}
                   onHide={closeModal}
                   size={(isEdit || isManagementHidden) ? undefined : 'xl'}
                   centered
                   id="create-campaign-modal"
            >
                <Modal.Body>
                    <h4>{t('brand.campaign.briefing.starting_new_campaign')}</h4>
                    <Row>
                        <Col className="px-0 px-md-1" xs={12}>
                            {campaignData &&
                                <TextField
                                    label={t('brand.campaign.briefing.campaign_name')}
                                    variant="outlined"
                                    size="small"
                                    defaultValue={campaignData?.info?.title}
                                    type="text"
                                    error={isSubmitted && !(!!createCampaign?.title)}
                                    name="campaignName"
                                    className="w-100 my-3"
                                    onChange={(event) => setCreateCampaign({
                                        ...createCampaign,
                                        title: event.target.value,
                                    })}
                                />
                            }
                        </Col>
                        {!isEdit &&
                            <Col md={8} className={`px-0 px-md-1 ${isManagementHidden && 'd-none' }`}>
                                <div>
                                    <h6>{t('brand.campaign.briefing.campaign_management')}</h6>
                                    <p className="text-muted">{t('brand.campaign.briefing.campaign_management_desc')}</p>
                                </div>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        onChange={handleChange}
                                        value={createCampaign.campaignSharingType}
                                    >
                                        {CampaignSharingTypesEnum?.map(shareType => (
                                            <FormControlLabel hidden={functionCheckShareType(shareType)}
                                                              value={shareType}
                                                              key={shareType} control={<Radio/>}
                                                              label={
                                                                  <div className="d-flex">
                                                                      {getSharingTypeIcon(shareType)}
                                                                      <h6 className="mb-0 pl-2">
                                                                          {t(`brand.campaign.briefing.${shareType}`)}
                                                                      </h6>
                                                                  </div>
                                                              }/>
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                                {createCampaign.campaignSharingType !== CampaignSharingType.OWN_MANAGEMENT ?
                                    <Row>
                                        <h6 className="my-3">
                                            {
                                                createCampaign.campaignSharingType === CampaignSharingType.SUPPLIER ?
                                                    t('brand.campaign.briefing.chose_agency_partner') :
                                                    t('brand.campaign.briefing.chose_client')
                                            }
                                        </h6>
                                        <CustomAutocomplete dataArray={companies ?? []} isWithImage
                                                            inputLabel="displayName"
                                                            isMultiple={false}
                                                            key={`${createCampaign.campaignSharingType === CampaignSharingType.SUPPLIER ? 'client' : 'supplier'}`}
                                                            hasError={isSubmitted && !selectedCompany}
                                                            photoLabel="profilePhoto.path"
                                                            selectedStateImage={selectedCompany?.profilePhoto?.path as string}
                                                            queryFilterKey="displayName"
                                                            changeEventKey="displayName"
                                                            setFilterState={setFilterParams}
                                                            inputLabelTranslation={createCampaign.campaignSharingType === CampaignSharingType.SUPPLIER ? t('brand.campaign.dashboard.modal.agency_partner') : t('brand.campaign.dashboard.modal.your_clients')}
                                                            handleChangeParams={changeHandler}/>
                                        {createCampaign.campaignSharingType !== CampaignSharingType.CLIENT ? (
                                            <p className="mt-2">{t('brand.campaign.briefing.campaign_transfer_text')}</p>
                                        ) : (
                                            <p className="mt-2">{t('brand.campaign.briefing.campaign_share_text')}</p>
                                        )}
                                    </Row> :
                                    <Row>
                                        <p className="text-muted mb-3">
                                            {t('brand.campaign.briefing.campaign_launched_desc')}
                                        </p>
                                        <p className="text-muted mb-3">
                                            {t('brand.campaign.briefing.campaign_transfer_desc')}
                                        </p>
                                    </Row>
                                }
                            </Col>
                        }
                        <Col md={(isEdit || isManagementHidden) ? 12 : 4} className="px-0 px-md-1">
                            <div className="d-flex flex-column mr-2">
                                <h6>{t('brand.campaign.briefing.campaign_cover')}</h6>
                                <p>
                                    {t('brand.campaign.briefing.upload_photo_description')}
                                </p>
                            </div>
                            <div className="w-100 cursor-pointer">
                                <div>
                                    <UploadImage saveFile={setFiles} isDeleteHidden={true} isFullHeight={true}
                                                 error={isSubmitted && !files && !campaignData?.info?.campaignImages[0]?.path} classes="px-0"
                                                 maxSize={Number(process.env.REACT_APP_CAMPAIGN_COVER_MAX_FILE_SIZE)}
                                                 previewImage={campaignData?.info?.campaignImages[0]?.path}
                                                 isMultiple={false} isPreviewArray={false}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={closeModal}>
                        {t('general.button.cancel')}
                    </Button>
                    {isEdit ?
                        <Button disabled={isLoading} variant="primary" onClick={editCampaign}>
                            {t('general.button.edit')}
                        </Button>
                        :
                        <Button disabled={isLoading} variant="primary" onClick={createNewCampaign}>
                            {t('general.button.continue')}
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    )
        ;
}
