import React, {SetStateAction, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {User} from '../../../../../model/user/User';
import MentionInputField from '../../../../../shared/components/message/MentionInputField';
import {iManageList} from '../../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import {MessageBody} from '../../../../../shared/components/message/MessageBody';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {CustomImageHandler} from '../../../../../shared/components/CustomImageHandler';
import {MdSend} from 'react-icons/md';
import {InfluencerPlannerService} from '../../service';
import {ErrorToast} from '../../../../../utils/toasters';

interface IPreviewFeedbackProps {
    state: iManageList;
    setState: React.Dispatch<SetStateAction<iManageList>>;
    setHasTextInsideField: React.Dispatch<SetStateAction<boolean>>;
}

const initialMessageState: IPlannerMessage = {
    message: '',
    eventId: 0,
};

export interface IPlannerMessage {
    message: string;
    eventId: number;
}

const PreviewFeedback = ({state, setState, setHasTextInsideField}: IPreviewFeedbackProps) => {
    const {t} = useTranslation();
    const {user} = useSelector((states: IAllStates) => states.auth);
    const lastMessageRef = useRef<HTMLInputElement>(null);
    const [newMessage, setNewMessage] = useState(initialMessageState);
    const [mentions, setMentions] = useState<User[]>([])
    const {comments} = state;
    useEffect(() => {
        if (!!comments?.length) {
            lastMessageRef?.current?.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [comments?.length]);
    useEffect(() => {
        setHasTextInsideField(!!newMessage?.message?.length)
        // eslint-disable-next-line
    }, [newMessage?.message?.length]);

    const changeHandler = (name: string, value: string) => {
        setNewMessage({...newMessage, [name]: value});
    };

    const sendMessageHandler = () => {
        if (!!newMessage?.message?.length) {
            InfluencerPlannerService.addPlannerComment({
                ...newMessage, eventId: state?.id, message: newMessage.message.replaceAll('\n', '</br>')
            }).then(response => {
                setNewMessage(initialMessageState);
                setState(prev => ({...prev, comments: response?.comments}));
            }).catch(error => ErrorToast(error));
        }
    };

    useEffect(() => {
        const campaignId = state?.offerItem?.offer?.campaign?.id;
        if (campaignId) {
            InfluencerPlannerService.getMentions(campaignId, {isInfluencer: true})
                .then(response => {
                    setMentions(response?.data)
                }).catch(err => ErrorToast(err))
        }
    }, [state?.offerItem?.offer?.campaign?.id])

    return (
        <>
            <div className="col-12">
                <div className="border-bottom pb-1 mb-2">
                    <span className="font-weight-semi-bold">{t('influencer.planner.feedback')}</span>
                </div>
                <div style={{maxHeight: 300, overflow: 'auto', paddingRight: 10}}>
                    {!!comments?.length ? comments?.map((comment) => (
                        <MessageBody comment={comment as any} key={comment?.id} haveFiles={false}
                                     senderData={comment?.sentBy}
                                     isMyMessage={+comment?.sentBy?.id === +user?.id}/>
                    )) : <p className="text-muted">{t('influencer.planner.noComments')}</p>}
                    <div ref={lastMessageRef}/>
                </div>
            </div>
            <div className="col-12">
                <div className="border-bottom d-flex align-items-start mt-4 w-100">
                    <CustomImageHandler
                        altTag="My profile logo"
                        thumbnailPath={user?.profilePhoto?.thumbnail as string}
                        photoPath={user?.profilePhoto?.path as string}
                        classes="user-img rounded-circle"
                    />
                    <div className="w-100 ml-1 mb-3">
                        <MentionInputField value={newMessage?.message ?? ''}
                                           possibleMentions={mentions}
                                           changeMessageHandler={(e) => {
                                               changeHandler('message', e.target.value)
                                           }}
                                           sendMessage={sendMessageHandler}/>
                    </div>
                    <MdSend className="chat-input-icon ml-2 send" opacity={!!newMessage?.message?.length ? 1 : 0.5}
                            onClick={sendMessageHandler}/>
                </div>
            </div>
        </>
    );
};

export default PreviewFeedback;
