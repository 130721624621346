import React, {useCallback, useEffect, useState} from 'react';
import HistoryDetail from './HistoryDetail';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../../store/rootReducer';
import {IOffer} from '../../../../../../../../model/offer/IOffer';
import {ErrorToast} from '../../../../../../../../utils/toasters';
import {BookingService} from '../../../../service';

export function History() {
    const {campaign} = useSelector((state: IAllStates) => state.offerBrand);
    const [historyOffers, setHistoryOffers] = useState<IOffer[]>([]);

    const historyRequest = useCallback(() => {
        BookingService.getHistoryOffer(campaign?.offers[0]?.id)
            .then(response => setHistoryOffers(response)).catch(err => ErrorToast(err));
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        historyRequest();
    }, [historyRequest]);
    return (
        <>
            {historyOffers.map(offer => (
                <HistoryDetail offer={offer}/>
            ))
            }
        </>
    );
};