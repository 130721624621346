import {TextField} from '@material-ui/core';
import {FormControlLabel, Radio, RadioGroup} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Card, Col, Form, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BiMessageAltError} from 'react-icons/bi';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {IParams} from '../../../../../model/shared/IParams';
import {IBrandedContentRequest, initialBrandedContentData} from '../../../../../model/user/IBrandedContent';
import {initialUsageRightsData, IUsageRightsRequest} from '../../../../../model/user/UsageRights';
import {CustomAutocomplete} from '../../../../../shared/components/Autocomplete';
import ToggleSwitch from '../../../../../shared/components/ToggleSwitch';
import {hasAccessRights, oneStepUp} from '../../../../../shared/functions/Functions';
import {IAllStates} from '../../../../../store/rootReducer';
import {ErrorToast, SuccessToast, WarningToast} from '../../../../../utils/toasters';
import {BriefingService} from '../service';
import {CampaignOverviewCard} from './shared/CampaignOverviewCard';
import {ProgressBarCampaign} from './shared/ProgressBarCampaign';
import {StepperRouting} from './shared/StepperRouting';
import {AccessRightEnum} from "../../../../../enum/AccessRightEnum";

export const organicReach = [
    {
        name: 'brand.campaign.briefing.step7.organic_reach_title',
        description: 'brand.campaign.briefing.step7.organic_reach_desc'
    },
];

export const usageRights = [
    {
        name: 'brand.campaign.briefing.step7.social_media_title',
        description: 'brand.campaign.briefing.step7.social_media_desc',
        key: 'social_media_buyout',
        img: '/tempAssets/img-social-media.png',
    },
    {
        name: 'brand.campaign.briefing.step7.digital_media_title',
        key: 'digital_media_buyout',
        description: 'brand.campaign.briefing.step7.digital_media_desc',
        img: '/tempAssets/img-digital-media.png',
    },
    {
        name: 'brand.campaign.briefing.step7.shared_media_title',
        description: 'brand.campaign.briefing.step7.shared_media_desc',
        key: 'shared_media',
        img: '/tempAssets/img-shared-media.png',
    },
    {
        name: 'brand.campaign.briefing.step7.owned_media_title',
        key: 'owned_media',
        description: 'brand.campaign.briefing.step7.owned_media_desc',
        img: '/tempAssets/img-owned-media.jpg',
    },
    {
        name: 'brand.campaign.briefing.step7.digital_paid_media_title',
        description: 'brand.campaign.briefing.step7.digital_paid_media_desc',
        key: 'digital_paid_media',
        img: '/tempAssets/img-digital-paid-media.jpg',
    }
];

export const brandedContent = [
    {
        key: 'reach',
        name: 'brand.campaign.briefing.step7.reach',
        description: 'brand.campaign.briefing.step7.reach_desc',
        img: '/tempAssets/img-reach.jpg',
    },
    {
        key: 'traffic',
        name: 'brand.campaign.briefing.step7.traffic',
        description: 'brand.campaign.briefing.step7.traffic_desc',
        img: '/tempAssets/img-traffic.jpg',
    },
    {
        key: 'conversions',
        name: 'brand.campaign.briefing.step7.conversions',
        description: 'brand.campaign.briefing.step7.conversions_desc',
        img: '/tempAssets/img-conversions.jpg',
    },
];

export const StepSeven = () => {
    const [brandedContentRequest, setBrandedContentRequest] = useState<IBrandedContentRequest>(initialBrandedContentData);
    const [usageRightsRequest, setUsageRightsRequest] = useState<IUsageRightsRequest>(initialUsageRightsData);
    const {country} = useSelector((states: IAllStates) => states.enums);
    const {BrandedContentGoalsEnum} = useSelector((states: IAllStates) => states.enums);
    const {UsageRightsTypesEnum} = useSelector((states: IAllStates) => states.enums);
    const {OfferItemDurationEnum} = useSelector((states: IAllStates) => states.enums);
    const [isUserRights, setIsUserRights] = useState(false);
    const [isBrandedContent, setIsBrandedContent] = useState(false);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [isNextClicked, setIsNextClicked] = useState(false);
    const [isBriefingComplete, setIsBriefingComplete] = useState(true);

    const {t} = useTranslation();
    const {id} = useParams<IParams>();

    useEffect(() => {
        BriefingService.getStepSeven(+id)
            .then(response => {
                setIsBrandedContent(!!response?.brandedContent);
                setIsUserRights(!!response?.usageRights);
                setUsageRightsRequest(response?.usageRights);
                setBrandedContentRequest({
                    ...response?.brandedContent,
                    budget: response?.brandedContent?.budget ?? 5000
                });
                setIsBriefingComplete(response?.isBriefingComplete);
            }).catch(err => ErrorToast(err));
        // eslint-disable-next-line
    }, []);
    const updateStep = () => {
        setIsSubmitClicked(true);
        const hasBrandedContentErrors = isBrandedContent &&
            (!brandedContentRequest?.budget ||
                !brandedContentRequest?.countriesArray?.length ||
                !brandedContentRequest?.description ||
                !brandedContentRequest?.duration ||
                !brandedContentRequest?.goal);
        const hasUsageRightsErrors = isUserRights &&
            (!usageRightsRequest?.mediaType ||
                !usageRightsRequest?.description ||
                !usageRightsRequest?.duration ||
                !usageRightsRequest?.countriesArray?.length);
        if (hasUsageRightsErrors || hasBrandedContentErrors) {
            return;
        }
        if (brandedContentRequest?.budget < 4999) {
            WarningToast(t('brand.campaign.briefing.step7.invalidBudget'));
            return;
        }
        setIsNextClicked(true);
        BriefingService.updateBriefingByStep(+id, {
            usageRights: isUserRights ? usageRightsRequest : null,
            brandedContent: isBrandedContent ? brandedContentRequest : null,
        }, 'seventh')
            .then(response => {
                if (response) {
                    oneStepUp(+id, response.isBriefingComplete, response.briefingStep);
                    SuccessToast(`${t('general.alert.success_updated_campaign_step')}`);
                }
            }).catch(error => ErrorToast(error)).finally(() => {
            setIsNextClicked(false);
        });
    };

    const multipleSelectHandlerUsageRights = (paramKey: string, paramValue: any) => {
        const splitCountries = paramValue?.split(',')?.filter((value: string) => !!value.length);

        if (paramKey === 'countriesArray') {
            setUsageRightsRequest({...usageRightsRequest, [paramKey]: splitCountries});
        } else {
            setUsageRightsRequest({...usageRightsRequest, [paramKey]: paramValue});
        }
    };
    const multipleSelectHandlerBrandedContent = (paramKey: string, paramValue: any) => {
        const splitCountries = paramValue?.split(',')?.filter((value: string) => !!value.length);
        if (paramKey === 'countriesArray') {
            setBrandedContentRequest({...brandedContentRequest, [paramKey]: splitCountries});
        } else {
            setBrandedContentRequest({...brandedContentRequest, [paramKey]: paramValue});
        }
    };

    function findValue(arr: any, key: string) {
        return arr?.find((item: any) => item.key === key);
    }

    const handleChange = (event: any, isUsageRights = false) => {
        const {value, name} = event.target;

        isUsageRights ?
            setUsageRightsRequest({...usageRightsRequest, [name]: value})
            :
            setBrandedContentRequest({...brandedContentRequest, [name]: name === 'budget' ? +value : value});
    };

    return (
        <Row className="mb-5 pb-5">
            <Col md={8}>
                <Row>
                    {!isBriefingComplete ?
                        <Col xs={12} className="p-0">
                            <ProgressBarCampaign title={t('general.tabs.step7')} percent={75}/>
                        </Col> : null
                    }
                    <Col xs={12} className="mt-4 p-0">
                        <Card>
                            <Card.Body>
                                {/* {organicReach?.map((reach: { name: string, description: string }) => {
                                    return (
                                        <Card
                                            className={`cursor-pointer mb-4 box-shadow-primary border-primary`}
                                            key={reach?.name}>
                                            <Row>
                                                <div className="col-md-4 px-0">
                                                    <img src="/tempAssets/img-organic-reach.png"
                                                         className="w-100 default-radius"
                                                         alt="Campaign-img"/>
                                                </div>
                                                <div className="col-md-8 d-flex align-items-center">
                                                    <Card.Body>
                                                        <h5 className="font-weight-bold">{t(reach?.name)}</h5>
                                                        <span className="text-muted">
                                                    {t(reach?.description)}
                                                </span>
                                                    </Card.Body>
                                                </div>
                                            </Row>
                                        </Card>
                                    );
                                })} */}
                                <div
                                    className={`d-flex justify-content-between align-items-center mt-3 mb-${isUserRights ? '4' : '0'}`}>
                                    <div>
                                        <h5 className="font-weight-bold mb-0 ">
                                            {t('brand.campaign.briefing.step7.usage_rights')}
                                        </h5>
                                        <span className="text-muted">
                                            {t('brand.campaign.briefing.step7.usage_rights_description')}
                                        </span>
                                    </div>
                                    <ToggleSwitch small={true} id="usage-rights"
                                                  onChange={() => setIsUserRights(!isUserRights)}
                                                  checked={isUserRights}/>
                                </div>
                                <div className="pb-4 border-bottom">
                                    {isUserRights && <>
                                        {UsageRightsTypesEnum?.map((item, index) => {
                                            return (
                                                <Card
                                                    className={`cursor-pointer ${isSubmitClicked && !usageRightsRequest?.mediaType ? 'border-danger' : ''} my-3 ${usageRightsRequest?.mediaType === findValue(usageRights, item)?.key ? 'box-shadow-primary border-primary' : ''}`}
                                                    onClick={() => setUsageRightsRequest(prevState => ({
                                                        ...prevState,
                                                        mediaType: item,
                                                    }))}
                                                    key={index}>
                                                    <Row>
                                                        <div className="col-md-4 px-0">
                                                            <img src={findValue(usageRights, item)?.img}
                                                                 height={180}
                                                                 className="w-100 img-cover b-radius-left-side"
                                                                 alt="Campaign-img"/>
                                                        </div>
                                                        <div className="col-md-8 d-flex align-items-center">
                                                            <Card.Body>
                                                                <h5 className="font-weight-bold">{t(findValue(usageRights, item)?.name)}</h5>
                                                                <span>
                                                                    {t(findValue(usageRights, item)?.description)}
                                                                </span>
                                                            </Card.Body>
                                                        </div>
                                                    </Row>
                                                </Card>
                                            );
                                        })}
                                        <div className="mb-3 d-flex align-items-start flex-column mt-4">
                                            <h6 className="font-weight-bold mb-0">
                                                {t('brand.campaign.briefing.step7.content_be_used')}
                                            </h6>
                                        </div>
                                        <Form.Control
                                            as="textarea"
                                            onChange={(event) => handleChange(event, true)}
                                            name="description"
                                            rows={5}
                                            className={`${isSubmitClicked && !usageRightsRequest?.description ? 'border-danger' : ''}`}
                                            defaultValue={usageRightsRequest?.description}
                                            placeholder="Description"/>
                                        <div className="d-flex flex-wrap align-items-end mt-3">
                                            <div className="col-12 col-sm-6 px-0 pr-sm-2">
                                                <h6 className="mt-3 mb-0 font-weight-bold">
                                                    {t('brand.campaign.briefing.step7.usage_rights_duration')}
                                                </h6>
                                                <span className="text-muted">
                                                    {t('brand.campaign.briefing.step7.usage_rights_duration_desc')}
                                                </span>
                                                <CustomAutocomplete dataArray={OfferItemDurationEnum}
                                                                    isWithImage={false}
                                                                    inputLabel="Campaign Duration"
                                                                    isMultiple={false}
                                                                    classes="mt-2"
                                                                    photoLabel=""
                                                                    hasError={isSubmitClicked && !usageRightsRequest?.duration?.length}
                                                                    defaultArray={usageRightsRequest?.duration}
                                                                    queryFilterKey="duration"
                                                                    inputLabelTranslation={`Campaign Duration`}
                                                                    handleChangeParams={multipleSelectHandlerUsageRights}/>

                                            </div>
                                            <div className="col-12 col-sm-6 px-0 pl-sm-2">
                                                <h6 className="mt-3 mb-0 font-weight-bold">
                                                    {t('brand.campaign.briefing.step7.usage_rights_location')}
                                                </h6>
                                                <span className="text-muted">
                                                    {t('brand.campaign.briefing.step7.usage_rights_location_desc')}
                                                </span>
                                                <CustomAutocomplete dataArray={country} isWithImage={true}
                                                                    classes="mt-2"
                                                                    inputLabel="country"
                                                                    defaultArray={usageRightsRequest?.countriesArray}
                                                                    hasError={isSubmitClicked &&
                                                                        !usageRightsRequest?.countriesArray?.length}
                                                                    isMultiple={true}
                                                                    photoLabel="countryFlag"
                                                                    queryFilterKey="countriesArray"
                                                                    inputLabelTranslation={`Location`}
                                                                    handleChangeParams={multipleSelectHandlerUsageRights}/>
                                            </div>
                                        </div>
                                    </>}
                                </div>

                                <div
                                    className={`d-flex justify-content-between align-items-center mt-4 mb-${isBrandedContent ? '4' : '0'}`}>
                                    <div>
                                        <h5 className="font-weight-bold mb-md-0 ">
                                            {t('brand.campaign.briefing.branded_content_ads')} by influence.vision
                                        </h5>
                                        <span className="text-muted">
                                            {t('brand.campaign.briefing.step7.branded_content_description')}
                                        </span>
                                    </div>
                                    <ToggleSwitch small={true} id="branded-content"
                                                  onChange={() => setIsBrandedContent(!isBrandedContent)}
                                                  checked={isBrandedContent}/>
                                </div>
                                <div className="pb-4 border-bottom">
                                    {isBrandedContent && <>
                                        <Card
                                            className={`cursor-pointer mb-4 `}
                                        >
                                            <Row>
                                                <div className="col-md-4 px-0">
                                                    <img src="/tempAssets/img-branded-content.jpg"
                                                         className="w-100 b-radius-left-side img-cover max-h-180px"
                                                         alt="Campaign-img"/>
                                                </div>
                                                <div className="col-md-8 d-flex align-items-center">
                                                    <Card.Body>
                                                        <h5 className="font-weight-bold">
                                                            {t('brand.campaign.briefing.step7.branded_content_title')}
                                                        </h5>
                                                        <span>
                                                        {t('brand.campaign.briefing.step7.branded_content_desc')}
                                                        </span>
                                                    </Card.Body>
                                                </div>
                                            </Row>
                                        </Card>
                                        {/* {BrandedContentGoalsEnum?.map((item, index) => {
                                            return (
                                                <Card
                                                    className={`cursor-pointer ${isSubmitClicked && !brandedContentRequest?.goal ? 'border-danger' : ''} mb-4 ${brandedContentRequest?.goal === findValue(brandedContent, item)?.key ? ' box-shadow-primary border-primary' : ''}`}
                                                    key={item}
                                                    onClick={() => setBrandedContentRequest(prevState => ({
                                                        ...prevState,
                                                        goal: item,
                                                    }))}
                                                >
                                                    <Row>
                                                        <div className="col-md-4 px-0">
                                                            <img src="/tempAssets/img-branded.png"
                                                                 className="w-100 default-radius"
                                                                 alt="Campaign-img"/>
                                                        </div>
                                                        <div className="col-md-8 d-flex align-items-center">
                                                            <Card.Body>
                                                                <h5 className="font-weight-bold">
                                                                    {t(findValue(brandedContent, item)?.name)}
                                                                </h5>
                                                                <span>
                                                                    {t(findValue(brandedContent, item)?.description)}
                                                                </span>
                                                            </Card.Body>
                                                        </div>
                                                    </Row>
                                                </Card>
                                            );
                                        })} */}
                                        <RadioGroup
                                            className={isSubmitClicked && !brandedContentRequest?.description ? 'border-danger' : ''}
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={brandedContentRequest?.goal}
                                        >
                                            {BrandedContentGoalsEnum.map(item => (
                                                <div className="col-6 col-sm-4">
                                                    <FormControlLabel
                                                        onChange={() => setBrandedContentRequest(prevState => ({
                                                            ...prevState,
                                                            goal: item,
                                                        }))}
                                                        value={item}
                                                        className="mb-0"
                                                        control={<Radio/>}
                                                        label={<h6
                                                            className="m-0">{`${t(findValue(brandedContent, item)?.name)}`}</h6>}
                                                    />
                                                    <div
                                                        className="text-muted">{t(findValue(brandedContent, item)?.description)}</div>
                                                </div>
                                            ))}
                                        </RadioGroup>
                                        {!brandedContentRequest?.goal && isSubmitClicked && <span className="text-danger">
                                                    <BiMessageAltError size={15}/>
                                            {t('brand.campaign.briefing.step7.invalidGoal')}
                                                </span>}
                                        <div
                                            className="d-flex flex-column flex-md-row justify-content-betweenmt-2 mt-3">
                                            <div className=" w-md-50">
                                                <h6 className="mt-2 mb-0 font-weight-bold">
                                                    {t('brand.campaign.briefing.step7.branded_content_duration')}
                                                </h6>
                                                <span className="text-muted">
                                                    {t('brand.campaign.briefing.step7.branded_content_duration_desc')}
                                                </span>
                                                <CustomAutocomplete dataArray={OfferItemDurationEnum}
                                                                    isWithImage={false}
                                                                    inputLabel="Campaign Duration"
                                                                    isMultiple={false}
                                                                    photoLabel=""
                                                                    classes="mt-3"
                                                                    hasError={isSubmitClicked && !brandedContentRequest?.duration?.length}
                                                                    defaultArray={brandedContentRequest?.duration}
                                                                    queryFilterKey="duration"
                                                                    inputLabelTranslation={`Campaign Duration`}
                                                                    handleChangeParams={multipleSelectHandlerBrandedContent}/>
                                            </div>
                                            <div className="w-md-50 ml-md-3">
                                                {hasAccessRights(AccessRightEnum.CAN_EXPORT_MEASURE) && <><h6 className="mt-2 mb-0 font-weight-bold">
                                                    {t('brand.campaign.briefing.step7.branded_content_budget')}
                                                </h6>
                                                <span className="text-muted">
                                                    {t('brand.campaign.briefing.step7.branded_content_budget_desc')}
                                                </span>
                                                 <TextField
                                                    label={`${t('brand.campaign.briefing.budget')}`}
                                                    variant="outlined"
                                                    onChange={handleChange}
                                                    value={brandedContentRequest?.budget ?? '5000'}
                                                    size="small"
                                                    type="number"
                                                    name="budget"
                                                    InputLabelProps={{style: {fontSize: 12, fontFamily: 'Rubik'}}}
                                                    error={isSubmitClicked && !brandedContentRequest?.budget}
                                                    className={`w-100 font-12 mt-3`}
                                                    // InputProps={{inputProps: {min: 0}}}
                                                    onKeyPress={(event) => (event?.key === '-' || event?.key === '+') && event.preventDefault()}
                                                />
                                                {brandedContentRequest?.budget < 4999 && <span className="text-danger">
                                                    <BiMessageAltError size={15}/>
                                                    {t('brand.campaign.briefing.step7.invalidBudget')}
                                                </span>}</>}
                                            </div>
                                        </div>

                                        <h6 className="mb-0 mt-4 font-weight-bold">
                                            {t('brand.campaign.briefing.step7.describe_audience')}
                                        </h6>
                                        <Form.Control
                                            as="textarea"
                                            onChange={handleChange}
                                            className={`mt-2 ${isSubmitClicked && !brandedContentRequest?.description ? 'border-danger' : ''}`}
                                            defaultValue={brandedContentRequest?.description}
                                            name="description"
                                            rows={5}
                                            placeholder="Description"/>
                                        <div className="mt-4">
                                            <h6 className="mb-3 font-weight-bold">
                                                {t('brand.campaign.briefing.step7.branded_content_audience_location')}
                                            </h6>
                                            <CustomAutocomplete dataArray={country} isWithImage={true}
                                                                inputLabel="country"
                                                                defaultArray={brandedContentRequest?.countriesArray}
                                                                isMultiple={true}
                                                                photoLabel="countryFlag"
                                                                hasError={isSubmitClicked && !brandedContentRequest?.countriesArray?.length}
                                                                queryFilterKey="countriesArray"
                                                                inputLabelTranslation={`Location`}
                                                                handleChangeParams={multipleSelectHandlerBrandedContent}/>

                                        </div>
                                    </>}
                                </div>

                                <div className="mt-4">
                                    <StepperRouting submitStepHandler={updateStep} isNextDisabled={isNextClicked}/>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col md={4} className="position-relative">
                <CampaignOverviewCard/>
            </Col>
        </Row>
    );
};
