import React, {SetStateAction} from 'react';
import {Table} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {GoDotFill} from 'react-icons/go';
import {FaSortAmountDown, FaSortAmountUp} from 'react-icons/fa';
import {CustomImageHandler} from '../../../../../../shared/components/CustomImageHandler';
import {
    formatDate,
    getCorrectFilterTitle,
    getSocialIcons,
    truncateString
} from '../../../../../../shared/functions/Functions';
import {iManageFilters, iManageList} from '../dto/IManageCalendar';

interface iCalendarListViewProps {
    listOfInfluencers: iManageList[] | undefined;
    setFilterParams: React.Dispatch<SetStateAction<iManageFilters>>;
    filterParams: iManageFilters;
    setShowUpdateModal?: React.Dispatch<SetStateAction<boolean>>;
    setIsProposalOpened: React.Dispatch<SetStateAction<boolean>>;
    setSelectedEventId?: React.Dispatch<SetStateAction<number>>;
}

export const CalendarListView = ({
                                     listOfInfluencers,
                                     filterParams,
                                     setFilterParams,
                                     setIsProposalOpened,
                                     setSelectedEventId,
                                     setShowUpdateModal,
                                 }: iCalendarListViewProps) => {
    const {t} = useTranslation();
    const filterTableView = (
        sortType: 'ASC' | 'DESC',
        sortCriteria: 'displayName' | 'postingDate' | 'socialPlatformId',
    ) => {
        setFilterParams((prev) => ({
            ...prev,
            sortType,
            sortCriteria,
        }));
    };

    const handleEventClick = (offerItemId: number, eventStatus: string) => {
        if (setSelectedEventId) {
            setSelectedEventId(offerItemId);
        }
        if (eventStatus === 'proposed') {
            setIsProposalOpened(true);
        } else {
            setShowUpdateModal && setShowUpdateModal(true);
        }
    };


    return (
        <div>
            <Table responsive className="table-vertical-center dashboard-table">
                <thead>
                <tr>
                    <th
                        className="cursor-pointer"
                        onClick={() =>
                            filterTableView(
                                `${filterParams?.sortType === 'DESC' ? 'ASC' : 'DESC'}`,
                                'displayName',
                            )
                        }
                    >
                        {t('brand.campaign.dashboard.manage.influencer')}{' '}
                        {filterParams?.sortType === 'DESC' ? (
                            <FaSortAmountDown/>
                        ) : (
                            <FaSortAmountUp/>
                        )}
                    </th>
                    <th
                        className="cursor-pointer text-center"
                        onClick={() =>
                            filterTableView(
                                `${filterParams?.sortType === 'DESC' ? 'ASC' : 'DESC'}`,
                                'postingDate',
                            )
                        }
                    >
                        {t('brand.campaign.dashboard.manage.posting_date')}{' '}
                        {filterParams?.sortType === 'DESC' ? (
                            <FaSortAmountDown/>
                        ) : (
                            <FaSortAmountUp/>
                        )}
                    </th>
                    <th
                        className="cursor-pointer"
                        onClick={() =>
                            filterTableView(
                                `${filterParams?.sortType === 'DESC' ? 'ASC' : 'DESC'}`,
                                'socialPlatformId',
                            )
                        }
                    >
                        {t('brand.campaign.dashboard.manage.platform')}{' '}
                        {filterParams?.sortType === 'DESC' ? (
                            <FaSortAmountDown/>
                        ) : (
                            <FaSortAmountUp/>
                        )}
                    </th>
                    <th className="text-center cursor-pointer">
                        {t('brand.campaign.dashboard.manage.approved_by')}
                    </th>
                    <th className="text-center cursor-pointer">
                        {t('brand.campaign.dashboard.manage.approved_date')}
                    </th>
                    <th className="text-center cursor-pointer">
                        {t('brand.campaign.dashboard.manage.status')}
                    </th>
                </tr>
                </thead>
                <tbody>
                {listOfInfluencers?.map((influencer: iManageList) => {
                    const hasNotification = !!influencer?.eventNotifications?.[0]?.isUnread;
                    return (
                        <tr className="cursor-pointer hover-effect" key={influencer?.id}
                            onClick={() => handleEventClick(influencer?.id, influencer?.status)}>
                            <td className="text-nowrap">
                                <CustomImageHandler
                                    altTag="User Profile"
                                    classes="middle-rounded-logo mr-3"
                                    thumbnailPath={influencer?.offerItem?.offer?.user?.profilePhoto?.thumbnail}
                                    photoPath={
                                        influencer?.offerItem?.offer?.user?.profilePhoto?.path ??
                                        null
                                    }
                                />
                                <span className="text-dark font-weight-semi-bold ">
                                    {truncateString(influencer?.offerItem?.offer?.user?.displayName ?? '', 23)}
                                    {hasNotification &&
                                        <GoDotFill size={15} className="text-danger"/>
                                    }
                                </span>
                            </td>
                            <td className="text-center text-nowrap ">
                                {formatDate(influencer?.postingDate)}
                            </td>
                            <td className="text-md-center">
                                {getSocialIcons(
                                    {
                                        iconPath: influencer?.offerItem?.socialPlatform?.path,
                                        dataDown: t(`general.platforms.${influencer?.offerItem?.socialPlatform?.name}`),
                                        parentClasses: 'w-100',
                                    },
                                )}
                            </td>
                            <td className="text-left text-nowrap">
                                { influencer.approvedBy && <CustomImageHandler
                                    altTag="User Profile"
                                    classes="middle-rounded-logo mr-2"
                                    thumbnailPath={influencer?.approvedBy?.profilePhoto?.thumbnail}
                                    photoPath={
                                        influencer?.approvedBy?.profilePhoto?.path ??
                                        null
                                    }
                                />}
                                { influencer.approvedBy ? <span className="mt-1 text-dark font-weight-semi-bold ">
                                    {truncateString(influencer?.approvedBy?.firstName, 13)}
                                    {hasNotification &&
                                        <GoDotFill size={15} className="text-danger"/>
                                    }
                                </span> : '-'}
                            </td>
                            <td className="text-center text-nowrap">
                                {influencer?.approvedDate ? formatDate(influencer?.approvedDate) : '-'}
                            </td>
                            <td className="text-center">
                                <span className={`status ${influencer?.status}`}>
                                    {getCorrectFilterTitle(influencer?.status, t)}
                                </span>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </Table>
        </div>
    );
};
