import axios from 'axios';
import {StoreKeeper} from '../store/store';

declare global {
    interface Window {
        dataLayer: DataLayerItem[];
    }
}

interface DataLayerItem {
    // Define the structure of your dataLayer item
    // For example:
    event: string;
    visitorIP: string;
    user_type: string;
    offer_value?: string | 0;
    // Add other properties as needed
}

export const gtmService = async (eventValue: string, offerValue?: string | 0) => {
    const res = await axios.get('https://api.ipify.org/?format=json');
    const reduxState = StoreKeeper.store.getState();
    const userType = reduxState?.auth?.user?.userType;
    (window).dataLayer = (window).dataLayer || [];
    (window).dataLayer.push({
        'event': eventValue,
        'visitorIP': res.data.ip,
        'user_type': userType,
        'offer_value': offerValue ?? undefined,
    });
}
