import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface INewFeatures {
  finishNewFeature: () => void;
}

export const NewFeatures = ({finishNewFeature}: INewFeatures) => {
  const {t} = useTranslation();
  return (
    <Card className="mb-5">
      <Card.Body>
        <div className="text-center mt-2">
          <h4>{t('agency.influencers.feature_title')}</h4>
          <p className="text-primary font-13">{t('agency.influencers.feature_subtitle')}</p>
        </div>

        <Row className="mt-4" style={{gap: '24px'}}>
          <Col className="p-0">
            <h6>{t('agency.influencers.feature_1_title')}</h6>
            <img className="w-100" src="/tempAssets/CreatorManagement-CreatorFilter-ezgif.com-speed.gif" alt="" />
            <p className="text-black-50 mt-3">{t('agency.influencers.feature_1_desc')}</p>
          </Col>
          <Col className="p-0">
            <h6>{t('agency.influencers.feature_2_title')}</h6>
            <img className="w-100" src="/tempAssets/CreatorManagement-completeprofile-ezgif.com-video-to-gif-converter.gif" alt="" />
            <p className="text-black-50 mt-3">{t('agency.influencers.feature_2_desc')}</p>
          </Col>
          <Col className="p-0">
            <h6>{t('agency.influencers.feature_3_title')}</h6>
            <img className="w-100" src="/tempAssets/Creatormanagement-Howtoaddmoreinfluencers-ezgif.com-speed.gif" alt="" />
            <p className="text-black-50 mt-3">{t('agency.influencers.feature_3_desc')}</p>
          </Col>
        </Row>

        {/* <div className="mt-4">
          <h6>The rest of the new features</h6>

          <div className="mt-3">
            <h6 className="text-primary">Feature 4</h6>
            <p className="text-black-50">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Temporibus consequuntur quos ea animi minus saepe possimus nobis?
              Suscipit, eum perspiciatis reprehenderit, non perferendis incidunt
              necessitatibus quasi nostrum deleniti repudiandae nam.
            </p>
          </div>

          <div className="mt-2">
            <h6 className="text-primary">Feature 5</h6>
            <p className="text-black-50">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Temporibus consequuntur quos ea animi minus saepe possimus nobis?
              Suscipit, eum perspiciatis reprehenderit, non perferendis incidunt
              necessitatibus quasi nostrum deleniti repudiandae nam.
            </p>
          </div>
        </div> */}
      </Card.Body>
      <Card.Footer className="border-0 d-flex justify-content-end">
        <Button variant="outline-primary" onClick={finishNewFeature}>Got it</Button>
      </Card.Footer>
    </Card>
  );
};
