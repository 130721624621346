import React, {useState} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {TextField} from '@material-ui/core';
import {InputAdornment} from '@mui/material';
import {FaEye} from 'react-icons/fa';
import {PasswordVerifySteps} from '../../../../shared/PasswordVerifySteps';
import {useTranslation} from 'react-i18next';

interface ChangePasswordTabProps {
    formik: any
}
export function ChangePasswordTab({formik}: ChangePasswordTabProps) {
    const [passwordShown, setPasswordShown] = useState(false);
    const {t} = useTranslation();

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    return(
        <>
            <Row>
                <Col xs={12}>
                    <Form.Group className="my-2">
                        <TextField
                            fullWidth
                            label={t('general.current_password')}
                            variant="outlined"
                            size="small"
                            name="currentPassword"
                            value={formik.values.currentPassword}
                            onChange={formik.handleChange}
                            error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                            helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <Form.Group className="my-2">
                        <TextField
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start" className="cursor-pointer"
                                                    onClick={togglePassword}>
                                        <FaEye color="gray"></FaEye>
                                    </InputAdornment>
                                ),
                            }}
                            label={t('general.new_password')}
                            variant="outlined"
                            size="small"
                            name="password"
                            type={passwordShown ? 'text' : 'password'}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.touched.password && Boolean(formik.errors.password)}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <Form.Group className="my-2">
                        <TextField
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start" className="cursor-pointer"
                                                    onClick={togglePassword}>
                                        <FaEye color="gray"></FaEye>
                                    </InputAdornment>
                                ),
                            }}
                            label={t('general.repeat_password')}
                            variant="outlined"
                            size="small"
                            name="repeatPassword"
                            type={passwordShown ? 'text' : 'password'}
                            value={formik.values.repeatPassword}
                            onChange={formik.handleChange}
                            error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
                            helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <PasswordVerifySteps password={formik.values.password}/>
                </Col>
            </Row>

        </>
    );
}