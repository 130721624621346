import React, {ChangeEvent, useState} from 'react';
import {TextField} from '@material-ui/core';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {Campaign} from '../../../../model/campaign/Campaign';
import CardView from '../../dashboard/components/desktop/CardView';
import {Pagination} from '../../../../shared/components/Pagination';
import _debounce from 'lodash/debounce';
import {InfluencerInvoiceService} from '../service';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {ConfirmationModal} from '../../../../shared/components/alert/ConfirmationModal';

interface IArchiveInvoice {
    campaigns: Campaign[];
    totalPages: number;
    filterParams: any;
    setFilterParams: (...args: any) => void;
    handlePages: (...args: any) => void;
    count: number;
}

export default function ArchiveInvoice({
                                           campaigns,
                                           totalPages,
                                           filterParams,
                                           setFilterParams,
                                           handlePages,
                                           count,
                                       }: IArchiveInvoice) {
    const {t} = useTranslation();
    const [showDeleteModal, setShowDeleteModal] = useState({
        show: false,
        id: 0,
        title: '',
    });

    const changeFilterHandler = _debounce(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const {name, value} = e.target;
            setFilterParams({...filterParams, [name]: value});
        },
        500,
    );

    const deleteInvoice = () => {
        InfluencerInvoiceService.deleteInvoice(showDeleteModal.id)
            .then((response) => {
                const checkPage = count - 1 === (filterParams.page - 1) * filterParams.perPage;
                SuccessToast(t('influencer.invoice.toasts.success_deleted_invoice'));
                setFilterParams({
                    ...filterParams,
                    perPage: 12,
                    page: !!checkPage ? 1 : filterParams.page,
                    updatedAt: new Date().getTime(),
                });
            })
            .catch((error) => ErrorToast(error));
    };

    return (
        <div className="mt-5">
            <ConfirmationModal
                title={t('influencer.invoice.modal.title')}
                description={`${t('influencer.invoice.modal.desc', {
                    campaignName: showDeleteModal?.title,
                })}`}
                action={() => deleteInvoice()}
                show={showDeleteModal.show}
                closeModal={() =>
                    setShowDeleteModal({...showDeleteModal, show: false})
                }
            />

            <h4>{t('influencer.invoice.archive_invoices')}</h4>
            <Col sm={6} md={4} xl={3} className="pl-0 mb-3">
                <TextField
                    label={`${t('general.button.search')}`}
                    variant="outlined"
                    name={'title'}
                    onChange={changeFilterHandler}
                    size="small"
                    fullWidth
                />
            </Col>
            <Row>
                <CardView
                    isInvoice
                    campaigns={campaigns}
                    replaceButtons={true}
                    deleteInvoice={setShowDeleteModal}
                    classes="col-12 col-xl-3 col-md-4 col-sm-6 pl-0 mt-3"
                />
            </Row>
            {totalPages > 1 && (
                <div className="d-flex justify-content-end">
                    <Pagination
                        page={filterParams.page}
                        totalPages={totalPages}
                        handlePagination={handlePages}
                    />
                </div>
            )}
        </div>
    );
}
