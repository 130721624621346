import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {AgencyCreatorHeaderList} from './AgencyCreatorHeaderList';
import {FindAgencyCreatorsModal} from './FindAgencyCreatorsModal';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../store/rootReducer';
import {setDeselectInfluencer} from '../../../store/agency/agencySlice';
import {CustomImageHandler} from '../../../shared/components/CustomImageHandler';
import {useHistory} from 'react-router-dom';
import {InternalRoutes} from '../../../enum/InternalRoutes';

export function AgencyHeader() {
    const {t} = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const {selectedInfluencer} = useSelector((state: IAllStates)  => state.agency);
    const dispatch = useDispatch();
    const history = useHistory();
    const deselectUser = () => {
        dispatch(setDeselectInfluencer({}));
    };

    const navigateTo = (path: string) => {
        history.push(path);
    };

    const navigateToInfluencerProfile = () => {
        history.push(`${InternalRoutes.INFLUENCER_PROFILE_EDIT}/${selectedInfluencer?.id}`);
        localStorage.setItem('selectedAgencyCreator', `${selectedInfluencer?.id}`);
    };
    return (
        <>
            <FindAgencyCreatorsModal show={showModal} closeModal={() => setShowModal(false)}/>
            <div className="agency-header d-flex justify-content-between align-items-center">
                {selectedInfluencer?.id ?
                <div className="d-flex align-items-center">
                    <CustomImageHandler classes="user-placeholder" photoPath={selectedInfluencer?.profilePhoto?.thumbnail ?? ''}
                                        thumbnailPath={selectedInfluencer?.profilePhoto?.thumbnail ?? ''}
                                        altTag={selectedInfluencer?.firstName ?? ''}/>
                    <div className="ml-2">
                        <h6 className="mb-1">
                            {!!selectedInfluencer.displayName ? selectedInfluencer.displayName : (selectedInfluencer.firstName + ' ' + selectedInfluencer.lastName)}
                        </h6>
                        <p onClick={navigateToInfluencerProfile} className="mb-0 text-primary cursor-pointer">
                            {t('agency.influencers.edit_profile')}
                        </p>
                    </div>
                </div> : <div/>
                }
                <div className="d-none d-md-flex">
                    <div onClick={deselectUser} className={`agency-filter-rounded-img mr-2 ${selectedInfluencer?.id ? 'bg-white' : 'bg-primary'}`}>
                        <strong className={`mb-0 ${selectedInfluencer?.id ? 'text-primary' : 'text-white'}`}>ALL</strong>
                    </div>
                    <AgencyCreatorHeaderList/>
                    <div onClick={() => setShowModal(true)} className="agency-filter-rounded-img">
                        <img src="/icons/img-search.png" alt='search'/>
                    </div>
                </div>
                <Button onClick={() => navigateTo(InternalRoutes.AGENCY_CAMPAIGNS)} className="d-none d-md-flex" variant="outline-primary" size="sm">
                    {t('agency.see_all_camp')}
                </Button>
                <img onClick={() => setShowModal(true)} src="/icons/change-agency-influencer.svg" className="d-flex d-md-none" alt="agency-influencer"/>
            </div>
        </>
    );
}
