import React, {useEffect, useState} from 'react';
import {Col, Image, Row} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import ToggleSwitch from '../../../shared/components/ToggleSwitch';
import {useTranslation} from 'react-i18next';
import UpgradePlanModal from '../../../shared/components/UpgradePlanModal';
import {CampaignPackageOverviewCard} from './components/CampaignPackageOverviewCard';
import {PackagesService} from './service';
import {PackageOverviewModal} from './components/modal/PackageOverviewModal';
import {useDispatch} from 'react-redux';
import {packagesSlice} from '../../../store/brand/packagesSlice';
import {ErrorToast} from '../../../utils/toasters';

export function PackageOverview() {
  const history = useHistory();
  const [isUpgradeModalShown, setIsUpgradeModalShown] = useState(false);
  const [isOverviewModalShown, setIsOverviewModalShown] = useState(false);
  const [isYearlySubscription, setIsYearlySubscription] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const [packages, setPackages] = useState([
    {
      id: 1,
      packageName: 'Starter',
      title: 'brand.package.basic_title',
      maxUsers: 0,
      teamMembers: 0,
      briefing: 'brand.package.basic_desc',
      fee: 0,
      campaignSuccess: '',
      isActive: false,
      isSelected: false,
      isAvailable: true,
      keyWord: 'basic'
    },
    {
      id: 2,
      packageName: 'pro',
      title: 'brand.package.pro_title',
      monthlyPrice: 0,
      annualPrice: 0,
      maxUsers: 0,
      teamMembers: 0,
      briefing: 'brand.package.advanced',
      fee: 0,
      campaignSuccess: '',
      isActive: false,
      isSelected: false,
      isAvailable: true,
      keyWord: 'pro'
    },
    {
      id: 3,
      packageName: 'Full Service',
      title: 'brand.package.enterprise_title',
      monthlyPrice: 0,
      annualPrice: 0,
      maxUsers: 0,
      teamMembers: 0,
      briefing: 'brand.package.individual',
      fee: 0,
      campaignSuccess: '',
      isActive: false,
      isSelected: false,
      isAvailable: true,
      keyWord: 'enterprise'
    }]);
  const {t} = useTranslation();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const getPackages = () => {
    PackagesService.getPackages().then((response) => {
      const data = response.data;
      const copyPackages = packages;
      copyPackages.map((pack, index) => {
        const packageFromServer = data.find(item => item.name === pack.keyWord);
        pack.annualPrice = packageFromServer?.annualPrice;
        pack.monthlyPrice = packageFromServer?.monthlyPrice;
        pack.maxUsers = packageFromServer?.nbMasters ?? 0;
        pack.isActive = packageFromServer?.isActive ?? false;
        pack.fee = packageFromServer?.fee ?? 0;
        pack.isSelected = packageFromServer?.isSelected ?? false;
        pack.campaignSuccess = packageFromServer?.successManager ?? '';
        pack.teamMembers = packageFromServer?.nbEmployees ?? 0;
        pack.briefing = packageFromServer?.briefingModule ?? '';
      });
      const selectedPackage = copyPackages.findIndex(obj => obj.isSelected);
      copyPackages.slice(0, selectedPackage).forEach(obj => obj.isAvailable = false);
      setPackages(copyPackages);
      setLoading(false);

    }).catch((err) => {
      setLoading(false);
      ErrorToast(err);
    });
  };

  useEffect(() => {
    getPackages();
  }, []);

  const showOverviewModal = (packageName: string) => {
    setIsOverviewModalShown(true);
    dispatch(packagesSlice.actions.setActiveTab(packageName));
  };

  const handleCloseUpgradeModal = () => {
    setIsUpgradeModalShown(false);
    history.push('/brand/dashboard');
  };
  const handleCloseOverviewModal = () => {
    setIsOverviewModalShown(false);
  };

  const handleSwitch = (checked: boolean) => {
    setIsYearlySubscription(checked);
  };

  return (
    <>
      {loading ? <></> :
        <>
          <UpgradePlanModal show={isUpgradeModalShown} closeModal={handleCloseUpgradeModal}/>
          <PackageOverviewModal show={isOverviewModalShown} closeModal={handleCloseOverviewModal}/>
          <div className="text-center container mb-md-5">
            <Image src="/icons/packages/img_package_overview.png" alt="pack-overview"/>
            <h6 className="my-3" dangerouslySetInnerHTML={{__html: t('brand.package.flexible_plan')}}/>
            {/*HIDE YEARLY DISCOUNT TOGGLE IV-3192*/}
            {/*<div className="d-flex justify-content-center align-items-center">*/}
            {/*  <h6 className={`mb-0 mr-2 ${!isYearlySubscription ? 'text-primary' : 'text-muted'}`}>{t('brand.package.monthly')}</h6>*/}
            {/*  <ToggleSwitch onChange={handleSwitch} id="subscription" checked={isYearlySubscription}/>*/}
            {/*  <div className={`ml-2 ${isYearlySubscription ? 'text-primary' : 'text-muted'}`}>*/}
            {/*    <h6 className="mb-0">{t('brand.package.yearly')}</h6>*/}
            {/*    <p className="mb-0">(<span className="text-primary text-uppercase">save 10%</span>)</p>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <Row className="mt-md-4 mt-2">
              {packages.map((item, index) => (
                <Col key={item.id} className="mb-3 mb-lg-0" lg={4} xs={12}>
                  <CampaignPackageOverviewCard {...item}
                                               isYearlySubscription={isYearlySubscription} openOverviewModal={showOverviewModal}/>
                </Col>
              ))}
            </Row>
          </div>
        </>
      }
    </>
  );
};
