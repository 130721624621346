import React from 'react';

interface ISupportTutorialIconProps {
    isForMobile?: boolean;
    action?: () => void;
}

export function SupportTutorialIcon({action, isForMobile = false}: ISupportTutorialIconProps) {
    return (
        <img width="40"
                height="40"
                alt="Support icon"
                className={`default-radius ${isForMobile ? 'd-md-none box-shadow default-radius' : 'd-md-block d-none'}`}
                src={`/icons/tutorial-icon.png`}
                onClick={action}
                onMouseEnter={e => (e.currentTarget.src = '/icons/tutorial-icon-hover.png')}
                onMouseOut={e => (e.currentTarget.src = '/icons/tutorial-icon.png')}
        />
    )
}
