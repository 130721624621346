import React from 'react';
import {Button, Modal, Image} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {actions} from '../../../store/authentication/authRedux';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ExitImpersonation} from '../../../app/shared/impersonate/components/ExitImpersonation';

interface ISuspendedUserModalProps {
  show: boolean;
}

export function SuspendedUserModal({show}: ISuspendedUserModalProps) {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    dispatch(actions.logout());
    localStorage.clear();
    history.push('/auth/login');
  };

  return (
    <>
      <Modal show={show}
             className="modal-dark"
             size="sm"
             aria-labelledby="contained-modal-title-vcenter"
             centered={true}
      >
        <Modal.Body>
          <div className="text-center">
            <Image src="/icons/img-warning.svg" alt="suspended-user-image"/>
            <h5 className="my-2">{t('general.suspended_account_title')}</h5>
            <p className="text-muted">{t('general.suspended_account_desc')}</p>
          </div>
          <div className="d-flex justify-content-between">
            <Button href="https://calendly.com/influencevision-support/support-influence-vision" size="sm">
              {t('general.button.support')}
            </Button>
            <Button size="sm" variant="outline-secondary" className="text-uppercase" onClick={logout}>
              {t('general.button.sign_out')}
            </Button>
          </div>
          <div className="d-flex mt-2 justify-content-center"><ExitImpersonation/></div>
        </Modal.Body>
      </Modal>
    </>
  );
};
