import React, {useEffect, useState} from 'react';
import {Button, Col, FormControl, Image, InputGroup, Modal, Row} from 'react-bootstrap';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {FaHandshake, FaStar} from 'react-icons/fa';
import {IPriceList, IPriceListModalProps} from '../../../../model/shared/IPriceList';
import {UserService} from '../service';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {initialExclusivity, initialRights, IRightsExclusivity} from '../dto/IRights&Exclusivity';
import {useTranslation} from 'react-i18next';

function PriceListModal({show, closeModal, id}: IPriceListModalProps) {
    const [tab, selectTab] = useState('postings');
    const [priceList, setPriceList] = useState<IPriceList[]>([]);
    const [rightsAndExclusivity, setRightsAndExclusivity] = useState<IRightsExclusivity>({
        exclusivity: initialRights,
        usageRights: initialExclusivity,
    });
    const {t} = useTranslation();

    useEffect(() => {
        if (show) {
            UserService.getPriceList(id)
                .then((response) => {
                    setPriceList(response);
                }).catch(err => ErrorToast(err));
        }
    }, [show]);

    useEffect(() => {
        if (show) {
            UserService.getRightsExclusivity(id)
                .then((response) => {
                    setRightsAndExclusivity(response);
                }).catch(err => ErrorToast(err));
        }
    }, [show]);

    function editPriceList() {
        UserService.updatePriceList(priceList)
            .then(res => {
                SuccessToast(t('general.toasts.successUpdated'));
                closeModal?.();
            }).catch(err => ErrorToast(err));
    }

    function editRightsExclusivity() {
        UserService.updateRightsExclusivity(id, rightsAndExclusivity)
            .then(response => {
            }).catch(err => ErrorToast(err));
    }

    const handleTab = (event: React.SyntheticEvent, newValue: string) => {
        selectTab(newValue);
    };

    const handlePriceListChange = (event: any, platformId: number, socialId: number) => {
        const value = event.target.value.replace(/[^\d]/, '');
        // tslint:disable-next-line:radix
        if (parseInt(value) !== 0) {
            const platforms = [...priceList];
            const platform = platforms.find(item => item.id === platformId);
            const social = platform?.userPlatforms.find(item => item.id === socialId);
            if (social) {
                social.priceList.price = +value;
            }
            setPriceList(platforms);
        }
    };

    const handleExclusivityChange = (event: any, isExclusivity = false) => {
        const value = event.target.value.replace(/[^\d]/, '');
        // tslint:disable-next-line:radix
        if (parseInt(value) !== 0) {
            if (isExclusivity) {
                setRightsAndExclusivity({
                    ...rightsAndExclusivity,
                    exclusivity: {
                        ...rightsAndExclusivity.exclusivity,
                        [event.target.name]: +value,
                    },
                });
            } else {
                setRightsAndExclusivity({
                    ...rightsAndExclusivity,
                    usageRights: {
                        ...rightsAndExclusivity.usageRights,
                        [event.target.name]: +value,
                    },
                });
            }
        }
    };

    function onSubmit() {
        editPriceList();
        editRightsExclusivity();
    }

    return (
        <>
            <Modal show={show} onHide={closeModal} size="lg">
                <Modal.Header>
                    <Modal.Title>
                        <h4 className="m-0">{t('brand.account.price_list_modal.price_list')}</h4>
                        <span
                            className="text-muted">{t('brand.account.price_list_modal.price_list_profile_desc')}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="price-list py-0">
                    <Box sx={{width: '100%'}}>
                        <TabContext value={tab}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <TabList onChange={handleTab} aria-label="lab API tabs example" centered>
                                    <Tab label={t('brand.account.price_list_modal.postings')} value="postings"/>
                                    <Tab label={t('brand.account.price_list_modal.usage_and_exclusivity')}
                                         value="usage-right"/>
                                </TabList>
                            </Box>
                        </TabContext>
                    </Box>
                    {tab === 'postings' ?
                        <div>
                            <Row className="mt-4">
                                {priceList?.map((platform) => (
                                    <Col key={platform.id} md={6} sm={12}>
                                        <h5 className="mb-3 ">{platform.name}</h5>
                                        {platform.userPlatforms.map((social) => (
                                            <div key={social.id} className="d-flex justify-content-between mb-2">
                                                <div className="d-flex align-items-center">
                                                    <Image alt={social.socialPlatform.name}
                                                           src={social.socialPlatform.path}/>
                                                    <p className="mb-0 ml-2 font-12">
                                                        {t(`general.platforms.${social?.socialPlatform?.name}`)}
                                                    </p>
                                                </div>
                                                <div>
                                                    <InputGroup size="sm" id="price-input">
                                                        <InputGroup.Text>€</InputGroup.Text>
                                                        <FormControl
                                                            onChange={(e) => handlePriceListChange(e, platform.id, social.id)}
                                                            size="sm"
                                                            type="number"
                                                            value={social?.priceList?.price || ''}
                                                        />
                                                    </InputGroup>
                                                </div>
                                            </div>
                                        ))}
                                    </Col>
                                ))}
                            </Row>

                            {/*BARTER DEALS FOR VERSION 2.1*/}

                            {/*<Row className="mt-3">*/}
                            {/*    <Col>*/}
                            {/*        <h5>*/}
                            {/*            {t('brand.account.price_list_modal.barter_deals')}*/}
                            {/*        </h5>*/}
                            {/*        <div className="d-flex justify-content-between">*/}
                            {/*            <p>{t('brand.account.price_list_modal.payments_in_form')}</p>*/}
                            {/*            <div>*/}
                            {/*                <ToggleSwitch id="1" checked={true} small onChange={() => {*/}
                            {/*                }}/>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                        </div> :
                        // USAGE RIGHTS & EXCLUSIVITY CONTENT
                        <div>
                            <Row className="mt-4">
                                <div className="col-12">
                                    <h5>{t('brand.account.price_list_modal.usage_rights')}</h5>
                                    <p className="font-14">{t('brand.account.price_list_modal.usage_rights_desc')}</p>
                                </div>
                                <Col md={6} sm={12}>
                                    <div className="d-flex justify-content-between mb-2">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-round">
                                                <FaHandshake/>
                                            </div>
                                            <p className="mb-0 ml-2 font-12">1 {t('general.month')}</p>
                                        </div>
                                        <div>
                                            <InputGroup id="price-input">
                                                <InputGroup.Text>€</InputGroup.Text>
                                                <FormControl type="number"
                                                             value={rightsAndExclusivity?.usageRights?.oneMonthPrice || ''}
                                                             size="sm"
                                                             name="oneMonthPrice"
                                                             onChange={(e) => handleExclusivityChange(e)}
                                                />
                                            </InputGroup>

                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} sm={12}>
                                    <div className="d-flex justify-content-between mb-2">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-round">
                                                <FaHandshake/>
                                            </div>
                                            <p className="mb-0 ml-2 font-12">3 {t('general.months')}</p>
                                        </div>
                                        <div>
                                            <InputGroup id="price-input">
                                                <InputGroup.Text>€</InputGroup.Text>
                                                <FormControl type="number"
                                                             value={rightsAndExclusivity?.usageRights?.threeMonthsPrice || ''}
                                                             size="sm"
                                                             name="threeMonthsPrice"
                                                             onChange={(e) => handleExclusivityChange(e)}
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} sm={12}>
                                    <div className="d-flex justify-content-between mb-2">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-round">
                                                <FaHandshake/>
                                            </div>
                                            <p className="mb-0 ml-2 font-12">6 {t('general.months')}</p>
                                        </div>
                                        <div>
                                            <InputGroup id="price-input">
                                                <InputGroup.Text>€</InputGroup.Text>
                                                <FormControl type="number"
                                                             value={rightsAndExclusivity?.usageRights?.sixMonthsPrice || ''}
                                                             size="sm"
                                                             name="sixMonthsPrice"
                                                             onChange={(e) => handleExclusivityChange(e)}
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} sm={12}>
                                    <div className="d-flex justify-content-between mb-2">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-round">
                                                <FaHandshake/>
                                            </div>
                                            <p className="mb-0 ml-2 font-12">12 {t('general.months')}</p>
                                        </div>
                                        <div>
                                            <InputGroup id="price-input">
                                                <InputGroup.Text>€</InputGroup.Text>
                                                <FormControl type="number"
                                                             value={rightsAndExclusivity?.usageRights?.twelveMonthsPrice || ''}
                                                             size="sm"
                                                             name="twelveMonthsPrice"
                                                             onChange={handleExclusivityChange}
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <div className="col-12">
                                    <h5>{t('brand.account.price_list_modal.exclusivity')}</h5>
                                    <p className="font-14">{t('brand.account.price_list_modal.exclusivity_desc')}</p>
                                </div>
                                <Col md={6} sm={12}>
                                    <div className="d-flex justify-content-between mb-2">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-round">
                                                <FaStar/>
                                            </div>
                                            <p className="mb-0 ml-2 font-12">1 {t('general.month')}</p>
                                        </div>
                                        <div>
                                            <InputGroup id="price-input">
                                                <InputGroup.Text>€</InputGroup.Text>
                                                <FormControl type="number"
                                                             value={rightsAndExclusivity?.exclusivity?.oneMonthPrice || ''}
                                                             size="sm"
                                                             name="oneMonthPrice"
                                                             onChange={(event => handleExclusivityChange(event, true))}
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} sm={12}>
                                    <div className="d-flex justify-content-between mb-2">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-round">
                                                <FaStar/>
                                            </div>
                                            <p className="mb-0 ml-2 font-12">3 {t('general.months')}</p>
                                        </div>
                                        <div>
                                            <InputGroup id="price-input">
                                                <InputGroup.Text>€</InputGroup.Text>
                                                <FormControl type="number"
                                                             value={rightsAndExclusivity?.exclusivity?.threeMonthsPrice || ''}
                                                             size="sm"
                                                             name="threeMonthsPrice"
                                                             onChange={(event => handleExclusivityChange(event, true))}
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} sm={12}>
                                    <div className="d-flex justify-content-between mb-2">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-round">
                                                <FaStar/>
                                            </div>
                                            <p className="mb-0 ml-2 font-12">6 {t('general.months')}</p>
                                        </div>
                                        <div>
                                            <InputGroup id="price-input">
                                                <InputGroup.Text>€</InputGroup.Text>
                                                <FormControl type="number"
                                                             value={rightsAndExclusivity?.exclusivity?.sixMonthsPrice || ''}
                                                             size="sm"
                                                             name="sixMonthsPrice"
                                                             onChange={(event => handleExclusivityChange(event, true))}
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} sm={12}>
                                    <div className="d-flex justify-content-between mb-2">
                                        <div className="d-flex align-items-center">
                                            <div className="icon-round">
                                                <FaStar/>
                                            </div>
                                            <p className="mb-0 ml-2 font-12">12 {t('general.months')}</p>
                                        </div>
                                        <div>
                                            <InputGroup id="price-input">
                                                <InputGroup.Text>€</InputGroup.Text>
                                                <FormControl type="number"
                                                             value={rightsAndExclusivity?.exclusivity?.twelveMonthsPrice || ''}
                                                             size="sm"
                                                             name="twelveMonthsPrice"
                                                             onChange={(event => handleExclusivityChange(event, true))}
                                                />
                                            </InputGroup>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={closeModal}>
                        {t('general.button.close')}
                    </Button>
                    <Button variant="primary" onClick={onSubmit}>
                        {t('general.button.save')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PriceListModal;
