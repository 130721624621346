import Lottie from 'lottie-react';
import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import rocketIcon from '../icons/rocketIcon.json';

interface Props {
    show: boolean;
    closeModal: () => void;
    campaignTitle?: string;
}

const RocketModal = ({show, closeModal, campaignTitle}: Props) => {
    const {t} = useTranslation();
    return (
        <Modal show={show}
               onHide={closeModal}
               centered
        >
            <Modal.Body className="d-flex align-items-center flex-column pb-4">
                <Lottie animationData={rocketIcon} loop={true} style={{width: 150}} className="my-2"/>
                <h3 className="text-center text-primary mb-0">{t('influencer.offer_management.modal.success_accepted_message')}</h3>
                <p className="text-center my-2">{t('influencer.offer_management.modal.success_accepted_message_desc', {campaignTitle})}</p>
                <Button onClick={closeModal} variant="primary" className="mt-2">{t('general.button.keep_it_up')}!</Button>
            </Modal.Body>
        </Modal>
    );
};

export default RocketModal;
