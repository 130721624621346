import Lottie from 'lottie-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {useHistory} from 'react-router-dom';

import errorIcon from '../icons/errorIcon.json';

interface Props {
    errorMessage: string;
}

export const ErrorScreen = ({errorMessage}: Props) => {
    const history = useHistory();
    const {t} = useTranslation();

    return (
        <div className="container d-flex align-items-center flex-column justify-content-center h-60vh ">
            <Lottie animationData={errorIcon} loop={true} style={{width: '50%'}} className="my-2"/>
            <h6>Ooops! You have an error, please contact support!</h6>
            <p className="text-muted">Error: {errorMessage}</p>
            <button className="btn btn-primary" onClick={() => history.goBack()}>{t('general.button.back')}</button>
        </div>
    );
};

