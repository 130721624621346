import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {priceSplitter} from '../../../../utils/helpers/priceSplitter';
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap';
import React from 'react';
import {IInfluencerCampaignListProps} from '../../../influencer/campaign/component/InfluencerCampaignListItem';

export function AgencyCampaignListItem({campaign}: IInfluencerCampaignListProps) {
    const {title, id, campaignImages, company, offers} = campaign;
    const {t} = useTranslation();
    const history = useHistory();
    const goToOverview = () => {
        history.push(`/agency/campaign/${id}/overview`);
    };

    return (
        <tr>
            <td className="text-left">
                <div onClick={goToOverview} className="d-flex align-items-center cursor-pointer pr-3">
                    <CustomImageHandler photoPath={campaignImages?.[0]?.thumbnail as string}
                                        altTag={campaignImages?.[0]?.title as string}
                                        classes="mr-2 default-radius img-cover"/>
                    <h6 className="font-weight-bold ">{title}</h6>
                </div>
            </td>
            <td>
                <OverlayTrigger
                    key={id}
                    placement="bottom"
                    overlay={
                        <Tooltip id={`${id}`}>
                            {offers[0]?.user?.displayName}
                        </Tooltip>
                    }
                >
                    <div>
                        <CustomImageHandler photoPath={offers[0]?.user?.profilePhoto?.thumbnail as string}
                                            altTag={offers[0]?.user?.profilePhoto?.thumbnail as string}
                                            style={{width: '40px', height: '40px'}}
                                            classes="user-img-rounded"/>
                    </div>
                </OverlayTrigger>
            </td>
            <td className="text-primary font-weight-semi-bold">{company?.displayName ?? '-'}</td>
            <td className="font-weight-semi-bold">{offers?.[0]?.totalPostings ?? 0}</td>
            <td className="font-weight-semi-bold euro-symbol"> {priceSplitter(Math.round(offers?.[0]?.price ?? 0))}</td>
            <td>
                <span className={`status ${offers?.[0]?.status}`}>
                {t(`general.offerStatus.${offers?.[0]?.status}`)}
                </span>
            </td>
            <td>
                <Button onClick={goToOverview} className="mx-3" size="sm">
                    {t('general.open')}
                </Button>
            </td>
        </tr>
    );
}
