import React from 'react';
import {CustomImageHandler} from '../CustomImageHandler';

interface ISocialPlatformPostingsProps {
    platformName: string;
    image: string;
    numberPosts?: number;
}

export function SocialPlatformPostings({platformName, image, numberPosts}: ISocialPlatformPostingsProps) {
    return (
        <>
            <div className="d-flex align-items-center my-1 mr-2">
                <CustomImageHandler photoPath={image} altTag="" classes="social-icon"/>
                <p className="mb-0 ml-2">{platformName}</p>
                <h6 className="text-primary text-uppercase mb-0 ml-2">x{numberPosts}</h6>
            </div>
        </>
    );
};
