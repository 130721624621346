import React from 'react';
import {Button} from 'react-bootstrap';

interface IInfoBox {
    variant?: string;
    title?: string;
    text?: string;
    buttonName?: string;
    disabled?: boolean;
    className?: string;
    customIcon?: string;
    action?: () => void;
}

export function InfoBox({
                            variant = 'primary',
                            title,
                            buttonName,
                            text,
                            disabled = false,
                            customIcon,
                            className,
                            action,
                        }: IInfoBox) {
    const dangerImg = '/icons/img-info-box-danger.png';
    const infoImg = '/icons/img-info-box-purple.png';
    const primaryImg = '/icons/img-info-box-primary.png';
    const warningImg = '/icons/img-info-box-warning.png';
    const succesImg = '/icons/img-info-box-success.png';

    function checkImage() {
        if (variant === 'danger') {
            return dangerImg;
        } else if (variant === 'info') {
            return infoImg;
        } else if (variant === 'primary') {
            return primaryImg;
        } else if (variant === 'warning') {
            return warningImg;
        } else {
            return succesImg;
        }
    }

    return (
        <div className={`infobox ${variant} ${className}`}>
            <div className="d-flex align-items-center">
                {customIcon ? <img src={customIcon} width="20" alt="Icon" className={customIcon}/> :
                    <img alt="Icon" src={checkImage()} className="user-img"/>}
                <h6 className="ml-2 mb-0">{title}</h6>
            </div>
            <p className="py-2 mb-0 text-muted">{text}</p>
            {!!buttonName?.length &&
                <Button onClick={action} size="sm"
                        className={`w-100 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                        variant={variant}>{buttonName}</Button>}
        </div>
    );
}
