import {ProgressBar} from 'react-bootstrap';
import {getCorrectRegisterIcon} from '../../../../shared/functions/Functions';
import React from 'react';
import {useTranslation} from 'react-i18next';

export interface IStepperHeaderProps {
    currentStepId: string;
    maxSteps: number;
    currentScreen: string;
}

export const StepperHeader = ({currentStepId, maxSteps, currentScreen}: IStepperHeaderProps) => {
    const {t} = useTranslation();
    return (
        <>
            <div className={`col-12 mb-3 ${+currentStepId > maxSteps && 'invisible'}`}>
                <div className="d-flex align-items-center justify-content-between">
                    <h5>{t('auth.signupProcess')}</h5>
                    <p className="text-primary">{t('auth.step')} {currentStepId}/{maxSteps}</p>
                </div>
                <ProgressBar
                    variant="primary"
                    className="progress-sm w-100 bg-gray"
                    now={+currentStepId}
                    max={maxSteps}
                />
            </div>
            <div className="col-12 my-4">
                <img src={getCorrectRegisterIcon(currentStepId, currentScreen) as string}
                     className="register-stepper-icon"
                     alt="register step icon"/>
            </div>
        </>
    );
};
