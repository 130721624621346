import { UserRoles } from "../../../../enum/UserRoles";
import { IOffer } from "../../../../model/offer/IOffer";
import { ICompany } from "../../../../app/shared/user/dto/ICompany";

const detailsBlock = (offer: IOffer, company: ICompany, t: any, userType: string) => {  
    const {user, handles} = offer;
    return `
        <div class="d-flex flex-column ${userType === UserRoles.INFLUENCER || userType === UserRoles.AGENCY || userType === UserRoles.AGENCY_MASTER ? 'flex-column-reverse' : ''}" style="gap: 32px">
          <div>
            <h4 class="m-0 mb-3">Influencer ${user?.agencyCompany ? 'Management ' : ''}Details:</h3>
            <p>${user?.agencyCompany ? `${user?.agencyCompany.contact.companyName}` : `${user?.firstName} ${user?.lastName}`}</p>
            <p>${user?.agencyCompany ? `${user?.agencyCompany.contact?.street} ${user.agencyCompany?.contact?.city} ${user.agencyCompany?.contact?.province}` : `${user?.displayName}`}</p>
            <p>${user?.agencyCompany ? `${user?.agencyCompany.contact?.email}` : `${user?.email}`}</p>
            <p>${user?.agencyCompany ? `${user?.agencyCompany.contact?.uidNumber}` : `${user?.contact?.uidNumber ?? '-'}`}</p>
            ${user?.agencyCompany ? (`
              <div class="d-flex">
                <p class="text-black-50 mr-1">${t('general.influencer_name')}: </p><p>${user?.firstName} ${user?.lastName}</p>
              </div>
              <div class="d-flex">
                <p class="text-black-50 mr-1">Influencer Channel: </p><p>${handles?.map((item: string) => item).join(', ') ?? '-'}</p>
              </div>`
            ) : ''}
          </div>
      
          <div class="">
            <h4 class="mb-3">Company Details:</h4>
            <p>${company?.displayName}</p>
            <p>${company?.contact?.street} ${company?.contact?.city} ${company?.contact?.province}</p>
            <p>${company?.contact?.email}</p>
            <p>${company?.contact?.uidNumber}</p>
          </div>
        </div>
      `;
  };

  export default detailsBlock;