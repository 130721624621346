import React, {ChangeEvent, useEffect, useState} from 'react';
import {CustomImageHandler} from '../../../../../../shared/components/CustomImageHandler';
import {FormControl, Image, InputGroup, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {numberFormatter} from '../../../../../../utils/helpers/numberFormater';
import Flag from 'react-world-flags';
import Counter from '../../../../../../shared/components/Counter';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../store/rootReducer';
import {
    calculateAvgReach,
    checkNumberPosts, checkPrice,
    getCpmValue, getOldPricePerPlatform,
    getPostsPerPlatform,
    getPriceOneContent,
    getPricePerPlatform,
} from '../../../../../../shared/functions/Functions';
import {TrashIcon} from '../../../../../../shared/Icons';
import {setOfferItems} from '../../../../../../store/influencer/offerInfluencerSlice';
import {priceSplitter} from '../../../../../../utils/helpers/priceSplitter';
import {ITotalPlatforms} from '../../../../../../model/platform/ITotalPlatforms';

interface IInitialPlatformProps {
    platform: ITotalPlatforms;
}

export function InitialOfferPlatform({platform}: IInitialPlatformProps) {
    const {initialOffer, offerModalStatus, isCharity} = useSelector((state: IAllStates) => state.offerInfluencer);
    const [expended, setExpended] = useState(true);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        setExpended(offerModalStatus === 'negotiate' || window.innerWidth <= 600);
    }, [offerModalStatus]);

    function deleteOfferItem(socialPlatformId: number) {
        const initialOfferItemCopy = [...initialOffer.offerItems];
        const selectedOfferItem = initialOfferItemCopy.find((item) => item.socialPlatformId === socialPlatformId);
        const index = selectedOfferItem ? initialOfferItemCopy.indexOf(selectedOfferItem) : 0;
        initialOfferItemCopy[index] = {...initialOfferItemCopy[index], nbPosts: 0, price: 0};
        dispatch(setOfferItems(initialOfferItemCopy));
    }

    function changeHandlerOfferItem(socialPlatformId: number, event?: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, nbPosts?: number, mainPlatformId?: number) {
        const value = event && Number(event.target.value);
        const initialOfferItemCopy = [...initialOffer.offerItems];
        const selectedOfferItem = initialOfferItemCopy.find(item => item.socialPlatformId === socialPlatformId);
        const index = selectedOfferItem ? initialOfferItemCopy.indexOf(selectedOfferItem) : 0;
        if (selectedOfferItem) {
            if (nbPosts === 0) {
                deleteOfferItem(socialPlatformId);
            } else {
                initialOfferItemCopy[index] = {
                    ...initialOfferItemCopy[index],
                    price: (value === undefined) ? initialOfferItemCopy[index].price : value,
                    nbPosts: (nbPosts === undefined) ? initialOfferItemCopy[index].nbPosts : nbPosts,
                };
                dispatch(setOfferItems(initialOfferItemCopy));
            }
        }
    }
    
    const isStrikeTrough = getPostsPerPlatform(initialOffer.offerItems, platform.id) === 0 || +platform.nbPostsTotal === 0;
    return (
        <>
            <tr onClick={() => setExpended(!expended)} className={`${isStrikeTrough ? 'text-line-through' : ''}`}>
                <td>
                    <div className="d-flex align-items-center">
                        <CustomImageHandler
                            altTag={platform?.name ?? ''}
                            photoPath={platform?.path ?? null}
                        />
                        <span className={`text-primary font-weight-bold pl-1 mb-0 ${isStrikeTrough ? 'text-line-through' : ''}`}>
                            x{offerModalStatus === 'preview' ? platform.nbPostsTotal : getPostsPerPlatform(initialOffer.offerItems, platform.id)}
                        </span>
                        {expended ?
                            <Image className="ml-2" alt="image-down" src="/icons/img_arrow_up.svg"/>
                            :
                            <Image className="ml-2" alt="image-down" src="/icons/img_arrow_down.svg"/>
                        }
                    </div>
                </td>
                <td className="d-none d-md-table-cell text-center">
                    <p>{numberFormatter(platform.totalFollowers)}</p>
                </td>
                <td className="d-none d-md-table-cell text-center">
                    <p>
                        {numberFormatter(calculateAvgReach(initialOffer.offerItems, platform))}
                    </p>
                </td>
                <td className="d-none d-md-table-cell">
                    <div className="d-flex justify-content-center">
                        {platform?.audiences.map((country) => (
                            <div key={country.id} className="d-flex pl-2 align-items-center">
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="country-tooltip">
                                            {t(`general.countries.${country?.metric}`)}
                                        </Tooltip>
                                    }
                                >
                                    <div className="country-flag">
                                        <Flag code={country?.metric}/>
                                    </div>
                                </OverlayTrigger>
                                <div className="d-flex flex-column pl-2">
                                    <p className="text-primary font-weight-bold mb-0">
                                        {Math.round(country.value)}%
                                    </p>
                                    <p className="text-secondary mb-0">
                                        {numberFormatter(country.followers)}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </td>
                <td className={`${isCharity ? 'd-none' : 'd-none d-md-table-cell text-center'}`}>
                    <p className="euro-symbol">{getCpmValue(initialOffer.offerItems, platform)} </p>
                </td>
                <td className={`${isCharity ? 'd-none' : 'text-right d-none d-md-table-cell'}`}>
                    <div className="d-flex align-items-center justify-content-end">
                        {getOldPricePerPlatform(initialOffer.offerItems, platform.id) > 0 &&
                            <p className="euro-symbol text-line-through mr-2">{priceSplitter(Math.round(Math.round(getOldPricePerPlatform(initialOffer.offerItems, platform.id))))}</p>
                        }
                        <p className="euro-symbol font-weight-semi-bold">{priceSplitter(Math.round(getPricePerPlatform(initialOffer.offerItems, platform.id)))}</p>
                    </div>
                </td>
            </tr>
            {platform?.offerItems?.map((item) => (
                <tr key={item.id}
                    className={`position-relative
                    ${(checkNumberPosts(item?.socialPlatform?.id, item?.nbPosts ?? 0, initialOffer?.offerItems) === 0 || (!item.id && offerModalStatus === 'preview')) ? 'text-line-through' : ''}
                    ${expended ? '' : 'd-none'}
                `}>
                    <td className="vertical-align-td" colSpan={2}>
                        <div className="d-flex align-items-center pl-md-2">
                            {offerModalStatus === 'negotiate' && (
                                <span className="mb-0" onClick={() => deleteOfferItem(item.socialPlatform.id)}>
                                    <TrashIcon width="15" height="16" classes="cursor-pointer mr-2"/>
                                </span>
                            )}
                            <CustomImageHandler
                                altTag={item?.socialPlatform?.name ?? ''}
                                photoPath={item?.socialPlatform?.path ?? ''}
                                classes="d-none d-md-flex"
                            />
                            <span className="px-md-2 mb-0">
                                {t(`general.platforms.${item?.socialPlatform?.name}`)}
                            </span>
                        </div>
                    </td>
                    <td>
                        <div className="mr-md-3">
                            {offerModalStatus === 'negotiate' ? (
                                <Counter
                                    defaultCount={checkNumberPosts(item?.socialPlatform?.id, item?.nbPosts ?? 0, initialOffer?.offerItems)}
                                    setNumberItems={changeHandlerOfferItem}
                                    id={item?.socialPlatform?.id}
                                    itemId={item?.socialPlatform?.id}/>
                            ) : (
                                <span className="text-muted font-weight-bold mb-0">
                                    x {item?.nbPosts}
                                </span>
                            )}
                        </div>
                    </td>
                    <td className={`${isCharity ? 'd-none' : 'd-none d-md-table-cell text-center'}`}>
                        <p className="text-muted mb-0 ">
                            {t('influencer.offer_management.modal.price_of_one_content')}
                            <span className="euro-symbol">
                                {getPriceOneContent(initialOffer.offerItems, item.socialPlatform.id)}
                            </span>
                        </p>
                    </td>
                    <td className={`${isCharity ? 'd-none' : 'd-none d-md-table-cell'}`}></td>
                    <td className={`${isCharity ? 'd-none' : 'd-flex justify-content-end'}`}>
                        {offerModalStatus === 'negotiate' ? (
                            <InputGroup id="price-input">
                                <InputGroup.Text>€</InputGroup.Text>
                                <FormControl
                                    min={0}
                                    onChange={(event: any) => changeHandlerOfferItem(item.socialPlatform.id, event, undefined, platform.id)}
                                    type="number"
                                    placeholder="0"
                                    required={isCharity ? false : checkNumberPosts(item?.socialPlatform?.id, item?.nbPosts ?? 0, initialOffer?.offerItems) > 0}
                                    value={checkPrice(item?.socialPlatform?.id, item?.price, initialOffer?.offerItems) === 0 ? '' : checkPrice(item?.socialPlatform?.id, item?.price, initialOffer?.offerItems)}
                                    name="price"
                                    id="price-input"
                                    onKeyPress={(event: any) => (event?.key === '-' || event?.key === '+') && event.preventDefault()}
                                />
                            </InputGroup> 
                        ) : (
                            <p className="text-muted mb-0 mt-md-2 euro-symbol font-weight-semi-bold">
                                {priceSplitter(Math.round(item?.price ?? 0))}
                            </p>
                        )}
                    </td>
                </tr>
            ))}
        </>
    );
}
