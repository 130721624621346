export enum AccessRightEnum {
    CAN_SET_ADDITIONAL_KPI = 'can_set_additional_kpi',
    CAN_ADD_BEST_PICK = 'can_add_best_pick',
    CAN_CREATE_OPTIONS = 'can_create_options',
    CAN_VIEW_OPTIONS = 'can_view_options',
    CAN_VIEW_BOOKING = 'can_view_booking',
    CAN_VIEW_MANAGE = 'can_view_manage',
    CAN_VIEW_MEASURE = 'can_view_measure',
    CAN_UPLOAD_PRESENTATION = 'can_upload_presentation',
    CAN_VIEW_DASHBOARD = 'can_view_dashboard',
    MEASURE_HIDE_COSTS = 'measure_hide_costs',
    MEASURE_HIDE_INFLUENCER_DATA = 'measure_hide_influencer_data',
    CAN_VIEW_MESSAGE = 'can_view_message',
    CAN_SHARE_CAMPAIGN = 'can_share_campaign',
    INFLUENCER_FEEDBACK_HIDE = 'influencer_feedback_hide',
    MANAGE_CAN_VIEW_CLIENT_COMMENTS = 'manage_can_view_client_comments',
    CAN_EXPORT_MEASURE = 'can_export_measure',
    CAN_VIEW_BRIEFING_CHAT = 'can_view_briefing_chat',
    MEASURE_HIDE = 'measure_hide',
    MEASURE_HIDE_DASHBOARD = 'measure_hide_dashboard',
}
