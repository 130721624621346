import {Button} from 'react-bootstrap';
import React, {SetStateAction} from 'react';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../store/rootReducer';
import {iManageFilters} from '../dto/IManageCalendar';
import {TFunction} from 'react-i18next';
import {CustomAutocomplete} from '../../../../../../shared/components/Autocomplete';

interface iCalendarViewsProps {
    view: string,
    icon: string
}

interface iCalendarHeaderFiltersProps {
    setFilterParams: React.Dispatch<SetStateAction<iManageFilters>>;
    calendarViews: iCalendarViewsProps[];
    changeBtnFormat: (props: string) => void;
    selectedCalendarView: string;
    t: TFunction<'translation'>;
}

export const CalendarHeaderFilters = ({
                                          setFilterParams,
                                          calendarViews,
                                          changeBtnFormat,
                                          selectedCalendarView,
                                          t,
                                      }: iCalendarHeaderFiltersProps) => {

    const {offerItemEventsEnum} = useSelector(
        (states: IAllStates) => states.enums,
    );

    const changeCreatorsHandler = (paramKey: string, paramValue: string | number) => {
        setFilterParams(prev => ({...prev, [paramKey]: paramValue}));
    };

    return (
        <div className="row flex-column flex-xl-row w-100 justify-content-between mb-2 mx-0 align-items-center">
            <div className="col-12 col-xl-8 px-0 d-flex justify-content-center justify-content-xl-start ">
                <div className="w-md-50">
                    <CustomAutocomplete dataArray={offerItemEventsEnum} inputLabel="plannerStatus" isWithImage={false}
                                        queryFilterKey="status" disableClearable={false}
                                        classes="my-2 my-md-0" isOfferEnum
                                        inputLabelTranslation={t('influencer.planner.filterByStatus')}
                                        handleChangeParams={changeCreatorsHandler}/>
                </div>
            </div>
            <div
                className="col-12 col-xl-4 pr-0 d-none d-sm-flex justify-content-center justify-content-xl-end pr-0 align-items-center mt-3 mt-xl-0">
                {calendarViews?.map((view) => {
                    return (
                        <Button
                            key={view?.view}
                            variant="icon"
                            className="mr-2"
                            onClick={() => changeBtnFormat(view?.view)}
                        >
                            <img
                                src={`/icons/${
                                    view?.view === selectedCalendarView
                                        ? `${view?.icon}-active`
                                        : view?.icon
                                }.svg`}
                                style={{width: 20, height: 20}}
                                alt={`${view?.view} view icon`}
                            />
                        </Button>
                    );
                })}
            </div>
        </div>
    )
}