import React from 'react';
import {useTranslation} from 'react-i18next';
import {BsBarChartLineFill} from 'react-icons/bs';
import {Col, Row} from 'react-bootstrap';
import {SocialPlatformPostings} from '../../../../../shared/components/social/SocialPlatformPostings';
import {formatDate} from '../../../../../shared/functions/Functions';
import {ICampaignOverview} from '../../../../../model/briefing/overview/ICampaignOverview';
import DOMPurify from 'dompurify';

interface IOverviewTabProps {
    campaign?: ICampaignOverview;
}

export function OverviewTab({campaign}: IOverviewTabProps) {
    const {t} = useTranslation();

    return (
        <>
            <Row>
                <Col xs={12}>
                    <h6 className="text-muted">{t('influencer.offer_management.modal.campaign_goal')}</h6>
                </Col>
                <Col xs={12}>
                    <div className="d-flex align-items-center mb-3 text-muted">
                        <BsBarChartLineFill
                            className="text-dark"
                            size={32}/>
                        <h4 className="mb-0 ml-2 text-primary">{campaign?.campaignGoal ? campaign?.campaignGoal?.title : '--'}</h4>
                    </div>
                </Col>
                <Col className="my-2" xs={6} md={3}>
                    <div>
                        <h6 className="text-muted">{t('influencer.offer_management.modal.publication_period')}</h6>
                        <h6 className="text-primary">
                            {campaign?.publishFrom ? formatDate(campaign?.publishFrom as string) : '-'}
                            -
                            {campaign?.publishTo ? formatDate(campaign?.publishTo as string) : ' -'}
                        </h6>
                    </div>
                </Col>
                <Col className="my-2" xs={6} md={3}>
                    <div>
                        <h6 className="text-muted">{t('influencer.offer_management.modal.content_approval_deadline')}</h6>
                        <h6 className="text-primary">{campaign?.isReviewed ? campaign.reviewDays : '-'}</h6>
                    </div>
                </Col>
                <Col className="my-2" xs={6} md={3}>
                    <div>
                        <h6 className="text-muted">{t('influencer.offer_management.modal.application_deadline')}</h6>
                        <h6 className="text-primary">{campaign?.date ? formatDate(campaign?.date as string) : '-'}</h6>
                    </div>
                </Col>
                <Col className="my-2" xs={6} md={3}>
                    <div>
                        <h6 className="text-muted">{t('influencer.offer_management.modal.reporting_deadline')}</h6>
                        <h6 className="text-primary">{campaign?.postingDeadline ? formatDate(campaign?.postingDeadline as string) : '-'}</h6>
                    </div>
                </Col>
                <Col className="my-2" xs={12}>
                    <h6 className="text-muted">{t('influencer.offer_management.modal.placements')}</h6>
                </Col>
                {!!campaign?.platforms?.length ?
                    campaign?.platforms?.map(platform => (
                        <Col key={platform?.id} className="mb-2" xs={6} md={3}>
                            <SocialPlatformPostings key={platform.id}
                                                    platformName={t(`general.platforms.${platform?.socialPlatform?.name}`)}
                                                    image={platform.socialPlatform.path ?? ''}
                                                    numberPosts={platform.nbPosts}/>
                        </Col>
                    ))
                    : '-'
                }
                <Col className="my-2" xs={12}>
                    <h6 className="text-muted">{t('influencer.offer_management.modal.product_description')}</h6>
                    <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(campaign?.details ?? '-')}}/>
                </Col>
            </Row>
        </>
    );
}
