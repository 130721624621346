import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {User} from '../../../model/user/User';
import {IInvitedInfluencerState} from '../../../authentication/register/agency/dto/IRegisterAgency';
export interface IRegistrationInfluencerState {
    user?: User;
    agencyCompany?: IInvitedInfluencerState;
}
const initialAuthState: IRegistrationInfluencerState = {
    user: undefined,
    agencyCompany: undefined,
};

const influencerRegistrationSlice = createSlice({
    name: 'registerInfluencer',
    initialState: initialAuthState,
    reducers: {
        setRegisterUser: (state: IRegistrationInfluencerState, {payload: user}: PayloadAction<User>) => {
            return {...state, user};
        },
        setAgencyCompany: (state: IRegistrationInfluencerState, {payload: agencyCompany}: PayloadAction<IInvitedInfluencerState>) => {
            return {...state, agencyCompany};
        },
    },
});

export const {
    setRegisterUser,
    setAgencyCompany,
} = influencerRegistrationSlice.actions;

export default influencerRegistrationSlice;
