import React from 'react';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import {useTranslation} from 'react-i18next';

interface IMobileTabsProps {
    handleTab: (event: React.SyntheticEvent, newValue: string) => void;
    tab: string;
    countOffer: {
        toDo: number;
        inProgress: number;
    }
}

export function OffersMobileTabs({handleTab, tab, countOffer}: IMobileTabsProps) {
    const {t} = useTranslation();
    return (
        <>
            <Box>
                <TabContext value={tab}>
                    <TabList variant="fullWidth" onChange={handleTab} aria-label="lab API tabs example" centered>
                        <Tab className="text-uppercase"
                             label={t('influencer.offer_management.to_do', {counter: countOffer?.toDo})}
                             value="to-do"/>
                        <Tab className="text-uppercase"
                             label={t('influencer.offer_management.in_progress', {counter: countOffer?.inProgress})}
                             value="in-progress"/>
                    </TabList>
                </TabContext>
            </Box>
        </>
    );
};