import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../../../store/rootReducer';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IInfluencerDistribution} from '../../../../../../influencer/offer/dto/IInfluencerDistribution';
import {Button, Image, Table} from 'react-bootstrap';
import {CustomAutocomplete} from '../../../../../../../shared/components/Autocomplete';
import {OptionalItemMobile} from '../../../../../../influencer/offer/modal/tabs/offer/OptionalItemMobile';
import {addAdditionalItem, setDistributionItems} from '../../../../../../../store/agency/agencyOfferSlice';
import {useMediaQuery} from '@mui/material';

export function AgencyOptionalItemsTable() {
    const {campaign, initialOffer, distributionArr} = useSelector((state: IAllStates) => state.offerAgency);
    const [expended, setExpended] = useState(true);
    const {OfferItemDurationEnum, country} = useSelector((states: IAllStates) => states.enums);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const isMobile = window.innerWidth <= 600;
    const isDesktop = useMediaQuery('(min-width: 1200px)');

    const multipleSelectHandler = (paramKey: string, paramValue: any, type: string) => {
        const splitString = paramValue?.split(',');
        let initialDistributionCopy: IInfluencerDistribution[];
        if (campaign) {
            initialDistributionCopy = [...distributionArr];
            const selectedItem = initialDistributionCopy.find(item => item.type === type);
            const index = selectedItem ? initialDistributionCopy.indexOf(selectedItem) : 0;
            initialDistributionCopy[index] = {
                ...initialDistributionCopy[index],
                [paramKey]: paramKey === 'location' ? splitString : paramValue,
            };
            dispatch(setDistributionItems(initialDistributionCopy));
        }
    };

    const createAdditionalItem = (type: string) => {
        if (campaign) {
            const initialDistributionCopy = [...distributionArr];
            const selectedAdditionalItem = initialDistributionCopy.find((item) => item.type === type);
            dispatch(addAdditionalItem(selectedAdditionalItem));
        }
    };

    const checkIfAdditionalAlreadyExist = (type: string) => {
        const additionalItemsCopy = [...initialOffer?.distribution];
        if (additionalItemsCopy.find(item => item.type === type)) {
            return true;
        }
        return false;
    };

    return (
        <div className="table-offer-items">
            <Table responsive className={`table-head-bg mt-3 d-none d-md-table`}>
                <thead className="table-head-bg cursor-pointer" onClick={() => setExpended(!expended)}>
                <tr>
                    <th>{t('influencer.offer_management.modal.optional_items')}</th>
                    <th>{t('influencer.offer_management.modal.details')}</th>
                    <th>{t('brand.campaign.book.offer.modal.duration')}</th>
                    <th>{t('brand.campaign.book.offer.modal.location')}</th>
                    <th className="text-right">
                        {t('brand.campaign.book.offer.modal.action')}
                        {expended ?
                            <Image className="ml-3" alt="image-up" src="/icons/img_arrow_up.svg"/> :
                            <Image className="ml-3" alt="image-down" src="/icons/img_arrow_down.svg"/>
                        }
                    </th>
                </tr>
                </thead>
                <tbody className={`${expended ? '' : 'd-none'} `}>
                {!isMobile && distributionArr.map((item, index) => (
                    <tr key={index} className={`${checkIfAdditionalAlreadyExist(item?.type) ? 'd-none' : ''}`}>
                        <td>{t(`influencer.offer_management.modal.${item?.type}`)}</td>
                        <td>{item?.typeDescription}</td>
                        <td width={130}>
                            <CustomAutocomplete dataArray={OfferItemDurationEnum}
                                                isMultiple={false}
                                                isWithImage={false}
                                                inputLabel="Publication Period"
                                                queryFilterKey="duration"
                                                defaultArray={item?.duration}
                                                inputLabelTranslation={`Duration`}
                                                handleChangeParams={(_, e) => multipleSelectHandler(_, e, item?.type)}/>
                        </td>
                        <td>
                            {!!item?.location ?
                                < CustomAutocomplete dataArray={country} isWithImage={true} inputLabel="country"
                                                     isMultiple={true}
                                                     defaultArray={item?.location ? item?.location : []}
                                                     photoLabel="countryFlag"
                                                     queryFilterKey="location"
                                                     limitTags={isDesktop ? 3 : 1}
                                                     inputLabelTranslation={`${t('brand.campaign.book.offer.filters.location')}`}
                                                     handleChangeParams={(_, e) => multipleSelectHandler(_, e, item.type)}/>
                                : null
                            }
                        </td>
                        <td className="text-right">
                            <Button size="sm"
                                    variant="outline-primary"
                                    onClick={() => createAdditionalItem(item?.type)}>
                                {t('general.button.add')}
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <h6 className="my-3 d-md-none">{t('influencer.offer_management.modal.optional_items')}</h6>
            {isMobile && distributionArr.map((item, index) => (
                <OptionalItemMobile checkIfAdditionalAlreadyExist={checkIfAdditionalAlreadyExist(item?.type)} optionalItem={item} createAdditionalItem={createAdditionalItem} multipleSelectHandler={multipleSelectHandler}/>
            ))}
        </div>
    );
}