import {getCorrectRegisterImage} from '../../../../shared/functions/Functions';
import {Link, useLocation} from 'react-router-dom';
import {Image} from 'react-bootstrap';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {AuthService} from '../../../service';
import {useSearchQuery} from '../../../../shared/hooks/useSearchQuery';
import {ErrorToast} from '../../../../utils/toasters';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {useDispatch, useSelector} from 'react-redux';
import employeeSlice from '../../../../store/register/employee/employeeSlice';
import {IAllStates} from '../../../../store/rootReducer';

export const EmployeeInfo = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {pathname} = useLocation();
    const token = useSearchQuery('token');
    const currentStepId = String(pathname)?.split('/').at(-1) ?? '';
    const savedData = useSelector((state: IAllStates) => state.employee);

    useEffect(() => {
        if (!!token?.length) {
            AuthService.getEmployeeCompany(token as string).then(response => {
                dispatch(employeeSlice.actions.setRegisterUser(response));
            }).catch(error => ErrorToast(error));
        }
        // eslint-disable-next-line
    }, [token]);
    return (
        <>
            <div
                className={`d-none max-height-100vh d-md-flex col-md-4 col-lg-6 flex-column justify-content-between auth-bg-image`}
                style={{backgroundImage: `url(${getCorrectRegisterImage(currentStepId, true)})`}}
            >
                <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                    <Image
                        alt="Logo"
                        className="max-h-70px"
                        src="/tempAssets/img-white-logo.png"
                    />
                </Link>
                <div className="flex-column d-flex justify-content-top align-items-center text-lg-center h-50">
                    <div className=" w-50 blur-background">
                        <div className="auth-bg-text">
                            <div className="row">
                                <div className="col-md-4">
                                    <CustomImageHandler
                                        thumbnailPath={savedData?.company?.profilePhoto?.thumbnail as string}
                                        photoPath={savedData?.company?.profilePhoto?.path as string}
                                        classes="w-100 default-radius" altTag="Company logo"/>
                                </div>
                                <div className="col-md-8">
                                    <h4 className="mb-2">
                                        {savedData?.company?.displayName}
                                    </h4>
                                    <p className="font-weight-normal">
                                        {t('auth.register.employee.invitedBy', {
                                            campaignName: savedData?.company?.displayName,
                                        })}
                                    </p>
                                </div>
                                <div className="col-12">
                                    <div className="hr"></div>
                                </div>
                                <div className="col-12">
                                    <div className="d-flex justify-content-between mt-2">
                                        <p className="font-weight-normal">
                                            {t('auth.already_register')}
                                        </p>
                                        <Link to={'/'}>
                                            <p className="text-white">{t('auth.signin')}</p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
