import React from 'react';
import {FaSortAmountDown, FaSortAmountUp} from 'react-icons/fa';
import {Dropdown, DropdownButton} from 'react-bootstrap';
import {IOfferManagementFilter} from '../dto/IOfferManagementFilter';
import {useTranslation} from 'react-i18next';

interface IOfferFilterSortProps {
    offerFilter: IOfferManagementFilter;
    setOfferFilter: React.Dispatch<React.SetStateAction<IOfferManagementFilter>>;
}

export function OfferFilterSort({offerFilter, setOfferFilter}: IOfferFilterSortProps) {
    const {t} = useTranslation();

    const handleSortBy = (by: 'date' | 'publishFrom' | 'publishTo' | 'nbPosts') => {
        setOfferFilter(prev => ({...prev, sortBy: by}));
    };
    const handleSortType = (type: 'ASC' | 'DESC') => {
        setOfferFilter(prev => ({...prev, sortType: type}));
    };

    return (
        <>
            <div className="d-flex align-items-center justify-content-end">
                {offerFilter?.sortType === 'ASC' ?
                    <FaSortAmountDown className="cursor-pointer" onClick={() => handleSortType('DESC')}/> :
                    <FaSortAmountUp className="cursor-pointer" onClick={() => handleSortType('ASC')}/>}
                <DropdownButton
                    className="mr-2 pl-1 font-weight-normal"
                    variant="transparent"
                    title={offerFilter?.sortBy ? t(`influencer.explore.${offerFilter?.sortBy}`) : t('general.button.sort_by')}
                >
                    <Dropdown.Item className={`text-${offerFilter?.sortBy === 'date' ? 'primary' : ''}`}
                                   onClick={() => handleSortBy('date')}>
                        {t('influencer.explore.date')}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleSortBy('nbPosts')}
                                   className={`text-${offerFilter?.sortBy === 'nbPosts' ? 'primary' : ''}`}>
                        {t('influencer.explore.nbPosts')}
                    </Dropdown.Item>
                </DropdownButton>
            </div>

        </>
    );
};