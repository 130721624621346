import React from 'react';

interface IPlayButtonOverlayProps {
    children: JSX.Element;
    classParent?: string;
    classBtn?: string;
}
const PlayButtonOverlay = ({children, classParent, classBtn}: IPlayButtonOverlayProps) => {
    return (
        <div className={`image-container ${classParent ?? ''}`}>
            {children}
            <div className={`${classBtn ?? 'font-48'} play-button`}>
            {/* &#9658; */}
            </div>
        </div>
    );
};

export default PlayButtonOverlay;
