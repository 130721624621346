import {useTranslation} from 'react-i18next';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import {StarProIcon} from '../../../../../shared/Icons';
import {packagesSlice} from '../../../../../store/brand/packagesSlice';
import {PackageOverviewContent} from './PackageOverviewContent';

export function PackageOverviewTabs() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const isMobile = window.innerWidth <= 700;
  const {activeTabModal} = useSelector((reduxState: IAllStates) => reduxState.packages);

  const handleTab = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(packagesSlice.actions.setActiveTab(newValue));
  };

  return(
    <>
      <TabContext value={activeTabModal ?? 'basic'}>
        <Box className="mb-3">
          <TabList centered onChange={handleTab} allowScrollButtonsMobile={isMobile} variant={isMobile ? 'scrollable' : 'standard'}>
            <Tab label="Basic" value="basic"/>
            <Tab value="pro" icon={<><StarProIcon height="30" width="50"/> <p className="status accepted mb-0 ml-2">{t('brand.package.most_popular')}</p>
            </>} iconPosition="start"/>
            <Tab label="Enterprise" value="enterprise"/>
          </TabList>
        </Box>
        <Box className="scrollable-content ">
          <PackageOverviewContent/>
        </Box>
        </TabContext>
    </>
  );
}
