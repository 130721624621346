import React, {SetStateAction} from 'react';
import {Dropdown} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BiBell} from 'react-icons/bi';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {AuthService} from '../../authentication/service';
import {IUserNotification} from '../../model/campaign/Campaign';
import {redDotsSlice} from '../../store/influencer/redDotsSlice';
import {IAllStates} from '../../store/rootReducer';
import {ErrorToast, SuccessToast} from '../../utils/toasters';
import NotificationItem from './alert/NotificationItem';

interface IProps {
    setIsNotificationsOpened: React.Dispatch<SetStateAction<boolean>>,
    isMobile?: boolean;
    isNotificationsOpened: boolean;
    notificationList: IUserNotification[];
    setFilterParams: (value: unknown) => void
}

const NotificationBell = ({
                              setIsNotificationsOpened,
                              isNotificationsOpened,
                              notificationList,
                              isMobile = false,
                              setFilterParams
                          }: IProps) => {
    const history = useHistory();
    const {t} = useTranslation();
    const {redDots} = useSelector((state: IAllStates) => state.redDots);
    const dispatch = useDispatch();
    const markAllAsRead = async () => {
        await AuthService.markAllNotification()
            .then(response => {
                if (response) {
                    SuccessToast(t('general.toasts.successUpdated'));
                    dispatch(redDotsSlice.actions.setRedDots({
                        ...redDots,
                        notifications: 0
                    }))
                }
            }).catch(err => ErrorToast(err));
    }
    return (
        <Dropdown onToggle={(isOpen) => {
            setIsNotificationsOpened(isOpen);
        }}>
            <Dropdown.Toggle id="notification-dropdown" size={'sm'}
                             className={'bg-transparent p-0 m-0 border-0 mr-4 d-flex'}>
                <div className="card w-fit-content cursor-pointer">
                    <div className="card-body p-2">
                        <BiBell className={isNotificationsOpened ? 'text-primary' : 'text-muted'}
                                size={20}/>
                    </div>
                    {Number(redDots?.notifications) > 0 && <span className={'sidebar-bullet'}>
                                    {redDots?.notifications}
                                </span>}
                </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <div className={`card notification-dropdown bell-list ${isMobile ? 'mobile' : ''}`}>
                    <div className="card-body p-0 py-2">
                        {!!notificationList?.length ? notificationList?.map((notification) =>
                            <Dropdown.Item href={`#`} className={'p-0'}
                                           key={notification?.id}>
                                <NotificationItem
                                    notification={notification}
                                    isHeader
                                    setIsNotificationsOpened={setIsNotificationsOpened}
                                    setFilterParams={setFilterParams}
                                />
                            </Dropdown.Item>
                        ) : <div className={'py-4'}>
                            <div className="text-center">
                                <img src="/icons/empty-notification.svg" alt="Empty notification icon"/>
                                <h6 className="text-muted mb-0 mt-2">No new notifications</h6>
                            </div>
                        </div>}
                        <div
                            className="d-flex justify-content-between p-2 position-sticky bottom-0 bg-white z-index-1">
                            <Dropdown.Item href={`#/action-markAsRead`}
                                           onClick={() => markAllAsRead()}
                                           className={'p-0 fw-bold'}>
                                                <span className={'font-weight-bold'}>
                                                {t('general.clearAll')}
                                               </span>
                            </Dropdown.Item>
                            <Dropdown.Item href={`#/action-seeAll`}

                                           onClick={() => {
                                               history.push('/settings/notifications');
                                               setIsNotificationsOpened(false);
                                           }}
                                           className={'p-0 text-right'}>
                                               <span className={'font-weight-bold'}>
                                                    {t('general.seeAllNotifications')}
                                               </span>
                            </Dropdown.Item>
                        </div>
                    </div>
                </div>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default NotificationBell;
