import React, {useState} from 'react';
import {Image} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {getCorrectRegisterImage} from '../shared/functions/Functions';
import {RegistrationTypeModal} from './RegistrationTypeModal';

interface IAuthInfoProps {
    isRegister: boolean;
}

export const AuthInfo = ({isRegister}: IAuthInfoProps) => {
    const [isSelectRegisterOpened, setIsSelectRegisterOpened] = useState(false);
    const history = useHistory();
    const {t} = useTranslation();
    const {pathname} = useLocation();
    const currentStepId = isRegister ? (String(pathname)?.split('/').at(-1) ?? '') : '';
    const closeModal = () => {
        setIsSelectRegisterOpened(false);
    };
    const getCorrectText = () => {
        if (pathname.includes('/influencer/register')) {
            return {
                title: t('auth.influencer.title'),
                desc: t('auth.influencer.desc')
            };
        }
        return {
            title: t('auth.general.title'),
            desc: t('auth.general.desc')
        };
    }

    return (
        <div
            className={`d-none max-height-100vh d-md-flex col-md-4 col-lg-6 flex-column justify-content-between auth-bg-image`}
            style={{backgroundImage: `url(${getCorrectRegisterImage(currentStepId, isRegister)})`}}
        >

            <div onClick={() => window.location.href = 'https://www.influencevision.com/en/'}
                 className="flex-column-auto auth-logo">
                <Image
                    alt="Logo"
                    className="w-250px"
                    src="/tempAssets/influence-vision-logo.svg"
                />
            </div>
            <RegistrationTypeModal show={isSelectRegisterOpened} closeModal={closeModal}/>
            <div className="flex-column d-flex justify-content-top align-items-center text-lg-center h-50">
                <div className=" w-50 blur-background">
                    <div className="auth-bg-text">
                        <h4 className="mb-2">
                            {getCorrectText()?.title}

                        </h4>
                        <p className="font-weight-normal">
                            {getCorrectText()?.desc}
                        </p>
                        <div className="hr"></div>
                        <div className="d-flex justify-content-between mt-2">
                            <p className="font-weight-normal">{isRegister ? t('auth.already_register') :
                                t('auth.dont_have_account')}</p>
                            <div className="cursor-pointer"
                                 onClick={() => {
                                     if (isRegister) {
                                         history.push('/');
                                     } else {
                                         setIsSelectRegisterOpened(true);
                                     }
                                 }
                                 }>
                                <p className="text-white">{!isRegister ? t('auth.signup') : t('auth.signin')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
