import React from 'react';
import {Button, Card} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {ExploreIcon, OffersIcon} from '../../../../../shared/Icons';
import {IMyOffers} from '../../dto/IDashboard';
import {useSelector} from 'react-redux';
import {IAllStates} from '../../../../../store/rootReducer';
import {UserRoles} from '../../../../../enum/UserRoles';

interface Props {
    myOffers: IMyOffers;
}

const ExploreOffersCard = ({myOffers}: Props) => {
    const history = useHistory();
    const {t} = useTranslation();
    const {
        user: {
            userType,
        }
    } = useSelector((reduxState: IAllStates) => reduxState.auth);

    const navigateAction = (route: string) => {
        [UserRoles.AGENCY, UserRoles.AGENCY_MASTER].includes(userType as UserRoles) ?
          history.push(`/agency/${route}`) :
            history.push(`/influencer/${route}`);
    };

    return (
        <Card>
            <Card.Body>
                <div className="d-flex align-items-center h-100">
                    <div className="border-right pl-0 col-6">
                        <h6 className="d-flex align-items-center text-muted">
                            <ExploreIcon width="20" height="20" color="#9ea5ab" classes="mr-2"/>
                            <div>{t('influencer.dashboard.explore')}</div>
                        </h6>
                        <div className="mt-3 d-flex align-items-center my-3">
                            <div className="col-12 pl-0">
                                <h4 className="text-primary mb-0">{myOffers?.availableCampaigns ?? 0}</h4>
                                <span>
                                    {t('influencer.dashboard.availableCampaigns')}
                                </span>
                            </div>
                            {/*<div className="col-5 p-0">*/}
                            {/*    <h4 className="text-primary mb-0">0</h4>*/}
                            {/*    <span>*/}
                            {/*        {t('influencer.dashboard.newCampaigns')}*/}
                            {/*    </span>*/}
                            {/*</div>*/}
                        </div>
                        <Button variant="primary" onClick={() => navigateAction('explore')}>
                            {t('general.button.goToExplore')}
                        </Button>
                    </div>
                    <div className="col-6 pr-0">
                        <h6 className="text-muted d-flex align-items-center">
                            <OffersIcon width="20" height="20" color="#9ea5ab" classes="mr-2"/>
                            <div>{t('influencer.explore.offerManagement')}</div>
                        </h6>
                        <div className="mt-3 d-flex align-items-center my-3">
                            <div className="col-6 pl-0">
                                <h4 className="text-primary mb-0">{myOffers?.received ?? 0}</h4>
                                <span>
                                    {t('brand.campaign.book.offer.filters.received')}
                                </span>
                            </div>
                            <div className="pr-0 col-6">
                                <h4 className="text-primary mb-0">{myOffers?.sent ?? 0}</h4>
                                <span>
                                    {t('brand.campaign.book.offer.filters.sent')}
                                </span>
                            </div>
                        </div>
                        <div className="text-end">
                            <Button onClick={() => navigateAction('offers')} variant="outline-primary">
                                {t('general.button.goToOffers')}
                            </Button>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default ExploreOffersCard;
