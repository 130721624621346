import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from 'react-bootstrap';
import AudienceData from '../../../../brand/campaign/book/offer/modal/tabs/audience/AudienceData';
import {IChartData, IPlatformDataArray} from '../../../../brand/campaign/book/offer/dto/IAudienceDataModal';
import AudienceDataChart from '../../../../brand/campaign/book/offer/modal/tabs/audience/AudienceDataChart';
import {useTranslation} from 'react-i18next';
import {chartColors} from '../../../../brand/campaign/book/offer/modal/tabs/audience/EnhancedAudienceData';
import {IChart} from '../../../../../model/shared/IChart';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

interface IAudienceTabProps {
    state: {
        chartData: IChartData[],
        chartDates: string[];
        id: number;
        platformDataArray: IPlatformDataArray[];
    };
}

const AudienceTab = ({state}: IAudienceTabProps) => {
    const {t} = useTranslation();
    const [value, setValue] = React.useState('0');
    const [engagement, setEngagement] = React.useState<IChart[]>([]);
    const [following, setFollowing] = useState<IChart[]>([]);
    const [followers, setFollowers] = useState<IChart[]>([]);
    const [likes, setLikes] = useState<IChart[]>([]);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    useEffect(() => {
        if (!!state?.chartData?.length) {
            const followingArr: IChart[] = [];
            const followersArr: IChart[] = [];
            const likesArr: IChart[] = [];
            const engagementArr: IChart[] = [];
            state?.chartData?.forEach((item, index) => {
                followingArr.push({
                    data: item.following,
                    label: item.label,
                    borderColor: chartColors[index],
                    backgroundColor: chartColors[index],
                });
                followersArr.push({
                    data: item.followers,
                    label: item.label,
                    borderColor: chartColors[index],
                    backgroundColor: chartColors[index],
                });
                likesArr.push({
                    data: item.likes,
                    label: item.label,
                    borderColor: chartColors[index],
                    backgroundColor: chartColors[index],
                });
                engagementArr.push({
                    data: item.engagementRate,
                    label: item.label,
                    borderColor: chartColors[index],
                    backgroundColor: chartColors[index],
                });
            });
            setEngagement(engagementArr);
            setFollowing(followingArr);
            setFollowers(followersArr);
            setLikes(likesArr);
        }
    }, [state?.chartData]);
    return (
        <Row>
            <Col xs={12} className="pl-md-0">
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Card className="mb-4 ">
                        <Card.Body>
                            <TabContext
                                value={value}>
                                <TabList onChange={handleChange}>
                                    {state?.platformDataArray.map((platformData, index) => {
                                        return (
                                            <Tab
                                                label={`${platformData?.mainPlatform?.name}`}
                                                value={String(index)}
                                            />
                                        );
                                    })}
                                </TabList>
                                {state?.platformDataArray?.map((platformData, index) => {
                                    return (
                                        <TabPanel className="pb-0" value={String(index)}>
                                            <AudienceData key={index} audienceData={platformData}/>
                                        </TabPanel>
                                    );
                                })}
                            </TabContext>
                        </Card.Body>
                    </Card>
                </Box>
            </Col>

            <Col className="p-0 mt-md-3" sm={12}>
                <AudienceDataChart
                    title={t('brand.campaign.book.offer.modal.followers')}
                    description="Check followers data in the chart bellow!"
                    chartData={followers}
                    chartDates={state?.chartDates}
                />
            </Col>
            <Col className="pl-0" xl={6}>
                <AudienceDataChart
                    title={t('brand.campaign.book.offer.modal.average_likes')}
                    description="Check average likes data in the chart bellow!"
                    chartData={likes}
                    chartDates={state?.chartDates}
                />
            </Col>
            <Col className="pr-0" xl={6}>
                <AudienceDataChart
                    title={t('brand.campaign.book.offer.modal.engagement_rate')}
                    description="Check engagement rate data in the chart bellow!"
                    chartData={engagement}
                    chartDates={state?.chartDates}
                />
            </Col>
        </Row>
    );
};

export default AudienceTab;
