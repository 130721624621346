import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BsCardChecklist, BsSpeedometer2} from 'react-icons/bs';
import {useHistory, useLocation} from 'react-router-dom';
import {Campaign} from '../../../model/campaign/Campaign';
import {CustomAutocomplete} from '../../../shared/components/Autocomplete';
import usePagination from '../../../shared/hooks/usePagination';
import {ErrorToast} from '../../../utils/toasters';
import ReportingPerCampaign from './components/ReportingPerCampaign';
import ReportingPerformance from './components/ReportingPerformance';
import ReportingTasks from './components/ReportingTasks';
import {IPerformanceSummary, IReportingSummary, IReportingTasksSummary} from './dto/IReporting';
import {InfluencerReportingService} from './service';
import AlertBox from '../../../shared/components/alert/AlertBox';

interface ILocation {
    campaignId: number;
}

const InfluencerReporting = () => {
    const history = useHistory();
    const {state} = useLocation();
    const {t} = useTranslation();
    const {filterParams, setFilterParams} = usePagination({
        perPage: 10, otherKeys: {
            campaignId: (state as ILocation)?.campaignId ?? '',
        },
    });
    const [updateReports, setUpdateReports] = useState(false);
    const [summary, setSummary] = useState<IReportingSummary>();
    const [showItems, setShowItems] = useState({
        showReporting: window.innerWidth > 600,
        showPerformance: window.innerWidth > 600,
    });
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);

    const [selectedFilterCampaign, setSelectedFilterCampaign] = useState<Campaign>();
    const changeCampaignFilterHandler = (paramKey: string, paramValue: string | number) => {
        setFilterParams((prev: any) => ({...prev, [paramKey]: paramValue}));
        setSelectedFilterCampaign(campaigns?.find(campaign => campaign.id === +paramValue))
    };
    const handleShowFields = (key: 'showReporting' | 'showPerformance') => {
        setShowItems(prev => ({...prev, [key]: !prev[key]}));
    }
    useEffect(() => {
        InfluencerReportingService.getSummary().then(response => {
            setSummary(response);
        }).catch(error => ErrorToast(error));
    }, [updateReports]);


    useEffect(() => {
        InfluencerReportingService.getReportingCampaigns(filterParams)
            .then(response => {
                if ((state as ILocation)?.campaignId) {
                    const campaignId = (state as ILocation)?.campaignId;
                    setSelectedFilterCampaign(response?.data?.find((campaign: Campaign) => campaign.id === campaignId))
                }
                setCampaigns(response.data);
            }).catch(error => ErrorToast(error));
        // eslint-disable-next-line
    }, [filterParams]);
    return (
        <Row className="pb-5">
            <AlertBox image="/icons/img-warning.svg"
                      hasButton
                      classCol="col-12 mb-4"
                      variant="primary"
                      title={t('influencer.invoice.invoice_alert_title')}
                      desc={t('influencer.invoice.invoice_alert_desc')}
                      textButton={t('influencer.invoice.go_to')}
                      functionBtn={() => history.push('/influencer/invoices')}
            />
            {showItems?.showReporting && <Col md={6} className="my-3 my-md-0 order-2 order-md-1">
                <ReportingTasks reportingTasks={summary?.reportingTasksSummary as IReportingTasksSummary}/>
            </Col>}
            {showItems?.showPerformance && <Col md={6} className="my-3 my-md-0 order-3 order-md-2">
                <ReportingPerformance performance={summary?.performanceSummary as IPerformanceSummary}/>
            </Col>}
            <Col xs={12} className="order-1 order-md-3 mt-md-3">
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="d-flex d-md-none align-items-center">
                        <Button variant={`${!showItems?.showReporting ? 'outline-secondary' : 'primary'}`}
                                onClick={() => handleShowFields('showReporting')}>
                            <BsCardChecklist size={25}/>
                        </Button>
                        <Button variant={`${!showItems?.showPerformance ? 'outline-secondary' : 'primary'}`}
                                className="mx-2"
                                onClick={() => handleShowFields('showPerformance')}>
                            <BsSpeedometer2 size={25}/>
                        </Button>
                    </div>
                    <CustomAutocomplete dataArray={campaigns} isWithImage={false} inputLabel="title"
                                        setFilterState={setFilterParams} disableClearable={false}
                                        changeEventKey="name" queryFilterKey="campaignId"
                                        key={selectedFilterCampaign?.id}
                                        classes="w-md-25" defaultArray={selectedFilterCampaign as unknown as Campaign[]}
                                        inputLabelTranslation={t('influencer.planner.selectCampaign')}
                                        handleChangeParams={changeCampaignFilterHandler}/>
                </div>
            </Col>
            <Col xs={12} className="order-4">
                <ReportingPerCampaign handleShowFields={handleShowFields} filterParams={filterParams}
                                      setFilterParams={setFilterParams}
                                      setUpdateReports={setUpdateReports as React.Dispatch<SetStateAction<boolean>>}
                                      updateReports={updateReports}/>
            </Col>
        </Row>
    );
};

export default InfluencerReporting;
