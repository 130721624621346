export let initialDataManualAuth = {
  title: '',
  externalLink: '',
  platformId: 0,
  followers: 0,
  userId: 0,
  totalPosts: 0,
  reach: 0,
  averageComments: 0,
  averageLikes: 0,
  averageViews: 0,
  audiences: [
    {
      dimension: 'gender',
      metric: 'male',
      value: 0,
    },
    {
      dimension: 'gender',
      metric: 'female',
      value: 0,
    },
    {
      dimension: 'country',
      position: 'position-1',
      metric: '',
      value: 0,
    },
    {
      dimension: 'country',
      position: 'position-2',
      metric: '',
      value: 0,
    },
    {
      dimension: 'country',
      metric: '',
      position: 'position-3',
      value: 0,
    },
    {
      dimension: 'country',
      metric: '',
      position: 'position-4',
      value: 0,
    },
    {
      dimension: 'country',
      metric: '',
      position: 'position-5',
      value: 0,
    },
    {
      dimension: 'age',
      metric: 'age_13_17',
      value: 0,
    },
    {
      dimension: 'age',
      metric: 'age_18_24',
      value: 0,
    },
    {
      dimension: 'age',
      metric: 'age_25_34',
      value: 0,
    },
    {
      dimension: 'age',
      metric: 'age_35_44',
      value: 0,
    },
    {
      dimension: 'age',
      metric: 'age_45_or_more',
      value: 0,
    },
  ],
};
