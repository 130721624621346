export const supportTutorials = [
    {
        route: '/brand/dashboard',
        title: 'Dashboard',
        video: 'https://www.loom.com/embed/5c0247e1b5064ddd818203d2a52382b3',
        translation: {
            title: 'brand_dashboard_title',
            desc: 'brand_dashboard_desc'
        }
    },
    {
        route: '/brand/dashboard',
        title: 'Dashboard',
        video: 'https://www.loom.com/embed/f875e9f4e31a470189d4df57300f0599',
        translation: {
            title: 'brand_dashboard_create_campaign_title',
            desc: 'brand_dashboard_create_campaign_desc'
        }
    },
    {
        route: '/brand/create/campaign/step1',
        title: 'Campaign Creation',
        video: 'https://www.loom.com/embed/f875e9f4e31a470189d4df57300f0599?t=60',
        translation: {
            title: 'brand_briefing_strategy_title',
            desc: 'brand_briefing_strategy_desc'
        }
    },
    {
        route: '/brand/create/campaign/step2',
        title: 'Campaign Creation',
        video: 'https://www.loom.com/embed/f875e9f4e31a470189d4df57300f0599?t=90',
        translation: {
            title: 'brand_briefing_content_title',
            desc: 'brand_briefing_content_desc'
        }
    },
    {
        route: '/brand/create/campaign/step3',
        title: 'Campaign Creation',

        video: 'https://www.loom.com/embed/f875e9f4e31a470189d4df57300f0599?t=312',
        translation: {
            title: 'brand_briefing_timeline_title',
            desc: 'brand_briefing_timeline_desc'
        }
    },
    {
        route: '/brand/create/campaign/step4',
        title: 'Campaign Creation',

        video: 'https://www.loom.com/embed/f875e9f4e31a470189d4df57300f0599?t=387',
        translation: {
            title: 'brand_briefing_placements_title',
            desc: 'brand_briefing_placements_desc'
        }
    },
    {
        route: '/brand/create/campaign/step5',
        title: 'Campaign Creation',

        video: 'https://www.loom.com/embed/f875e9f4e31a470189d4df57300f0599?t=442',
        translation: {
            title: 'brand_briefing_audience_title',
            desc: 'brand_briefing_audience_desc'
        }
    },
    {
        route: '/brand/create/campaign/step6',
        title: 'Campaign Creation',

        video: 'https://www.loom.com/embed/f875e9f4e31a470189d4df57300f0599?t=488',
        translation: {
            title: 'brand_briefing_influencer_detail_title',
            desc: 'brand_briefing_influencer_detail_desc'
        }
    },
    {
        route: '/brand/create/campaign/step7',
        title: 'Campaign Creation',

        video: 'https://www.loom.com/embed/f875e9f4e31a470189d4df57300f0599?t=509',
        translation: {
            title: 'brand_briefing_distribution_usage_title',
            desc: 'brand_briefing_distribution_usage_desc'
        }
    },
    {
        route: '/brand/create/campaign/step8',
        title: 'Campaign Creation',

        video: 'https://www.loom.com/embed/f875e9f4e31a470189d4df57300f0599?t=610',
        translation: {
            title: 'brand_briefing_distribution_exclusivity_title',
            desc: 'brand_briefing_distribution_exclusivity_desc'
        }
    },
    {
        route: '/brand/create/campaign/overview',
        title: 'Campaign Creation',

        video: 'https://www.loom.com/embed/f875e9f4e31a470189d4df57300f0599?t=673',
        translation: {
            title: 'brand_briefing_overview_title',
            desc: 'brand_briefing_overview_desc'
        }
    },
    {
        route: '/brand/campaign/dashboard/',
        title: 'Campaign Dashboard',

        video: 'https://www.loom.com/embed/f3e48b0e992a4a168035cf385cec484e',
        translation: {
            title: 'brand_campaign_dashboard_title',
            desc: 'brand_campaign_dashboard_desc'
        }
    },
    {
        route: '/brand/campaign/book',
        title: 'Booking',
        video: 'https://www.loom.com/embed/37e0ba6d243f4a8fa10a96eecc584441',
        translation: {
            title: 'brand_campaign_book_title',
            desc: 'brand_campaign_book_desc'
        }
    },
    {
        route: '/brand/campaign/book',
        title: 'Booking',

        video: 'https://www.loom.com/embed/6b3e7d463ab64a9d9cbe66e9513e8e00',
        translation: {
            title: 'brand_campaign_offer_modal_title',
            desc: 'brand_campaign_offer_modal_desc'
        }
    },
    {
        route: '/brand/campaign/book/checkout',
        title: 'Booking',

        video: 'https://www.loom.com/embed/4ff58ad5f73e4237bc7bdb98b6662879',
        translation: {
            title: 'brand_campaign_checkout_title',
            desc: 'brand_campaign_checkout_desc'
        }
    },
    {
        route: '/brand/campaign/manage',
        title: 'Manage',

        video: 'https://www.loom.com/embed/a0c60b20827240b0aed3585564e8a194',
        translation: {
            title: 'brand_manage_title',
            desc: 'brand_manage_desc'
        }
    },
    {
        route: '/brand/campaign/manage',
        title: 'Manage',

        video: 'https://www.loom.com/embed/8452ebaf3d3d44a6878738a691e43c63',
        translation: {
            title: 'brand_manage_posting_title',
            desc: 'brand_manage_posting_desc'
        }
    },
    {
        route: '/brand/campaign/measure',
        title: 'Measure',

        video: 'https://www.loom.com/embed/371741fc197943d4b1d086d095a46d0d',
        translation: {
            title: 'brand_measure_title',
            desc: 'brand_measure_desc'
        }
    }, {
        route: '/settings/edit',
        title: 'Profile Settings',

        video: 'https://www.loom.com/embed/658119d2e59a4350ab2ffd3ad165a611',
        translation: {
            title: 'settings_edit_title',
            desc: 'settings_edit_desc'
        }
    },
    {
        route: '/influencer/dashboard',
        video: 'https://www.loom.com/embed/0b5f12ca6b774fc8be8bcc27df286934',
        translation: {
            title: 'influencer_dashboard_title',
            desc: 'influencer_dashboard_desc'
        }
    },
    {
        route: '/influencer/campaigns',
        video: 'https://www.loom.com/embed/eed8e13c808548b7a166d4972cf4dfcd',
        translation: {
            title: 'influencer_dashboard_title',
            desc: 'influencer_dashboard_desc'
        }
    },
    {
        route: '/influencer/explore',
        video: 'https://www.loom.com/embed/36d9ce5c6a9d4425a4a21856b0537e4a',
        translation: {
            title: 'influencer_explore_title',
            desc: 'influencer_explore_desc'
        }
    },
    {
        route: '/influencer/campaign/overview',
        video: 'https://www.loom.com/embed/deeaa8eedf654bc79d9a0090cacd6556',
        translation: {
            title: 'influencer_briefing_title',
            desc: 'influencer_briefing_desc'
        }
    },
    {
        route: '/influencer/offers',
        video: 'https://www.loom.com/embed/2e398340e9fe44f7b14af0fd5d78204b',
        translation: {
            title: 'influencer_offers_title',
            desc: 'influencer_offers_desc'
        }
    },
    {
        route: '/influencer/offers',
        video: 'https://www.loom.com/embed/15e8cb1ec5e34248abdba39d00252401',
        translation: {
            title: 'influencer_offers_modal_title',
            desc: 'influencer_offers_modal_desc'
        }
    },
    {
        route: '/influencer/planner',
        video: 'https://www.loom.com/embed/a6dc078d203e4b818e29cf8e0e6cbf88',
        translation: {
            title: 'influencer_planner_title',
            desc: 'influencer_planner_desc'
        }
    },
    {
        route: '/influencer/planner',
        video: 'https://www.loom.com/embed/bb9da1df51a943bfa7193cac53fad145',
        translation: {
            title: 'influencer_planner_modal_title',
            desc: 'influencer_planner_modal_desc'
        }
    },
    {
        route: '/influencer/reporting',
        video: 'https://www.loom.com/embed/195fc6b3518746df82fb8a8646393d1e',
        translation: {
            title: 'influencer_reporting_title',
            desc: 'influencer_reporting_desc'
        }
    },
    {
        route: '/influencer/reporting',
        video: 'https://www.loom.com/embed/84e9018f83284dbcba055403a9993222',
        translation: {
            title: 'influencer_reporting_modal_title',
            desc: 'influencer_reporting_modal_desc'
        }
    },
    {
        route: '/agency/dashboard',
        video: 'https://www.loom.com/embed/1b18c6d0900c4fc2a1c1c4223310aa5c',
        translation: {
            title: 'agency_dashboard_title',
            desc: 'agency_dashboard_desc'
        }
    }
]