import React from 'react';
import {OverlayTrigger, Row, Tooltip} from 'react-bootstrap';
import {FiInfo} from 'react-icons/fi';
import ToggleSwitch from '../../../../shared/components/ToggleSwitch';
import {useTranslation} from 'react-i18next';
import {IGlobalPreference} from '../dto/IGlobalPreference';

interface ITwoFactorAuthTabProps {
    state: IGlobalPreference;
    changeHandler: (event: React.ChangeEvent<HTMLInputElement>, name: string) => void;
}
export function TwoFactorAuthTab({state, changeHandler}: ITwoFactorAuthTabProps) {
    const {t} = useTranslation();


    return(
        <>
            <Row className="justify-content-between">
                    <div className="d-flex align-items-center">
                        {t('brand.account.global_preferences_modal.two_fa')}
                        <OverlayTrigger placement="top"
                                        overlay={<Tooltip
                                            id="email-auth">
                                            {t('brand.account.global_preferences_modal.two_fa_tooltip')}
                                        </Tooltip>}>
                            <FiInfo size={14} className="cursor-pointer ml-1"/>
                        </OverlayTrigger>
                    </div >
                    <div>
                        <ToggleSwitch
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => changeHandler(e, 'is2FARequested')}
                            name="is2FARequested"
                            id="two"
                            checked={state.is2FARequested}
                        />
                    </div>
            </Row>
        </>
    );
}