import React from 'react';
import {Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {BiCopy} from 'react-icons/bi';
import {User} from '../../../../model/user/User';
import {CustomImageHandler} from '../../../../shared/components/CustomImageHandler';
import {copyToClipboard, formatDate, gePlatformIcon} from '../../../../shared/functions/Functions';
import {ISelectedEvent} from '../../../influencer/reporting/components/ReportingCampaignCard';

interface IProps {
    selectedOfferItem: ISelectedEvent;
    postingDate: string;
    postingLink?: string | undefined;
    user: User;
}

const LeftReportingCard: React.FC<IProps> = ({selectedOfferItem, user, postingDate, postingLink = undefined}) => {
    const {t} = useTranslation();
    // const {user, postingDate, postingLink} = selectedOfferItem?.event;
    const {offerItem} = selectedOfferItem;
    return (
        <Col md={4}>
            <div className="card border-primary">
                <div className="card-body p-3 d-flex align-items-center">
                    <CustomImageHandler photoPath={user?.profilePhoto?.thumbnail}
                                        classes="middle-rounded-logo"
                                        altTag={'User Profile'}/>
                    <h6 className="mb-0 ml-2">
                        {user?.displayName ?? '-'}
                    </h6>
                </div>
            </div>
            <div className="card my-3">
                <div className="card-body p-3">
                    {gePlatformIcon({
                        iconPath: offerItem?.socialPlatform?.path,
                        platformName: t(`general.platforms.${offerItem?.socialPlatform?.name}`)
                    })}
                    <div className={'mt-1'}>{t('brand.campaign.manage.postingType')}</div>
                </div>
            </div>
            <div className="card my-3">
                <div className="card-body p-3">
                    <h6 className="mb-0 text-primary">
                        {formatDate(postingDate)}
                    </h6>
                    <div>{t('brand.campaign.manage.postingDate')}</div>
                    {!!postingLink && <div className="mt-2">
                        <div className="d-flex align-items-center justify-content-between">
                            <h6 className="mb-0 text-primary">
                                {postingLink}
                            </h6>
                            <BiCopy className="copy-icon ml-1 text-muted"
                                    onClick={() => copyToClipboard(postingLink)}/>
                        </div>
                        <div>{t('influencer.reporting.postingLink')}</div>
                    </div>}
                </div>
            </div>
        </Col>
    );
};

export default LeftReportingCard;
