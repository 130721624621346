import axios, {CancelTokenSource} from 'axios';
import React, {SetStateAction, useEffect, useState} from 'react';
import {Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {ErrorToast, SuccessToast} from '../../../../utils/toasters';
import {iManageAttachments, iManageList} from '../../../brand/campaign/manage/calendar/dto/IManageCalendar';
import PreviewCopy from '../../../influencer/planner/components/preview/PreviewCopy';
import {AgencyPlannerService} from '../service';
import AgencyPlannerChat from './AgencyPlannerChat';
import PreviewContentFiles from './PreviewContentFiels';

interface IProps {
    state: iManageList;
    setState: React.Dispatch<SetStateAction<iManageList>>;
    setHasTextInsideField: React.Dispatch<React.SetStateAction<boolean>>

    changeHandler(event: React.ChangeEvent<HTMLInputElement>): void;
}

const RightPlannerCard = ({state, setState, changeHandler, setHasTextInsideField}: IProps) => {
    const {t} = useTranslation();
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [filesToUpload, setFilesToUpload] = useState<Blob[] | MediaSource[]>();
    const [selectedFile, setSelectedFile] = useState<iManageAttachments>();
    const [uploadData, setUploadData] = useState({
        loaded: 0,
        from: 0,
    });
    const [source, setSource] = useState<undefined | CancelTokenSource>(axios.CancelToken.source());

    const progressHandler = (e: ProgressEvent) => {
        setUploadData({
            loaded: e.loaded,
            from: e.total
        })
    }

    const cancelApiCall = () => {
        source?.cancel('API request cancelled');
        setSource(undefined);
    }

    const handleUploadFiles = async () => {
        const formData = new FormData();

        if (!!filesToUpload?.length) {
            for (const item of filesToUpload) {
                formData.append('files', (item as string | Blob));
            }
        }
        setIsFileUploading(true);
        await AgencyPlannerService.uploadAttachment(formData, state?.id, source?.token as any, (e: ProgressEvent) => progressHandler(e)).then(response => {
            setState(prev => ({...prev, attachments: response?.attachments}));
            setFilesToUpload([]);
            setSelectedFile(undefined);
            setUploadData({
                loaded: 0,
                from: 0
            })
            SuccessToast(t('influencer.planner.successUploadedFile'));
        }).catch(error => ErrorToast(error)).finally(() => setIsFileUploading(false));
    };

    useEffect(() => {
        if (!!filesToUpload?.length) {
            handleUploadFiles();
        }
        // eslint-disable-next-line
    }, [filesToUpload?.length])

    return (
        <Row>
            <PreviewCopy state={state} changeHandler={changeHandler}/>
            <PreviewContentFiles uploadData={uploadData} filesToUpload={filesToUpload as Blob[] | MediaSource[]}
                                 selectedFile={selectedFile as iManageAttachments}
                                 setFilesToUpload={setFilesToUpload as React.Dispatch<SetStateAction<Blob[] | MediaSource[]>>}
                                 setSelectedFile={setSelectedFile}
                                 setIsFileUploading={setIsFileUploading} cancelApiCall={cancelApiCall}
                                 isFileUploading={isFileUploading}
                                 state={state} setState={setState}/>
            <AgencyPlannerChat state={state} setState={setState} setHasTextInsideField={setHasTextInsideField}/>
        </Row>
    );
};

export default RightPlannerCard;
